import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { ACCEPTED_FILE_TYPES } from '@components/fileUploader/data'
import { FILE_NOT_ACCEPTED } from '@global/constants'
import { EXPORT_PROFILE_ACTIONS } from './actions'
import { EXPORT_PROFILE } from './types'

const apiCalls = {
  getExportProfile: ({ jobId, token }) => (
    Axios(`AsyncJob/${jobId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAsyncJobStatuses: token => (
    Axios('AsyncJob/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFile: ({ fileId, token }) => (
    Axios(`File/${fileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  downloadFile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getLogs: ({ jobId, data, token }) => (
    Axios.post(`AsyncJob/${jobId}/logs`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getExportData ({ payload }) {
  try {
    const { token } = payload
    const { data: exportData } = yield call(apiCalls.getExportProfile, payload)
    const { data: statuses } = yield call(apiCalls.getAsyncJobStatuses, token)
    let exportProfile = {
      ...exportData,
      fileType: '',
      fileName: ''
    }
    if (exportData.fileId) {
      const { data: file } = yield call(apiCalls.getFile, { fileId: exportData.fileId, token })
      const fileName = file?.name

      const extention = `.${fileName.split('.')[1]}`
      const fileType = ACCEPTED_FILE_TYPES.find(t => t.name === extention)?.type || FILE_NOT_ACCEPTED

      yield put(EXPORT_PROFILE_ACTIONS.CLEAR_PENDING_PROCESS_INFO())

      exportProfile = {
        ...exportProfile,
        fileType,
        fileName
      }
    }

    const dataObj = {
      exportProfile,
      statuses
    }
    yield put(EXPORT_PROFILE_ACTIONS.GET_EXPORT_SUCCESS(dataObj))
  } catch (error) {
    yield put(EXPORT_PROFILE_ACTIONS.GET_EXPORT_ERROR(error))
  }
}

export function* downloadExportFile ({ payload }) {
  try {
    const res = yield call(apiCalls.downloadFile, payload)
    const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]

    const data = {
      status: res.status,
      data: res.data,
      contentType: res.headers['content-type'],
      fileName
    }

    yield put(EXPORT_PROFILE_ACTIONS.FILE_DOWNLOADED(data))
  } catch (error) {
    yield put(EXPORT_PROFILE_ACTIONS.FILE_DOWNLOADED(error))
  }
}

export function* getLogs ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getLogs, payload)

    yield put(EXPORT_PROFILE_ACTIONS.GET_LOGS_SUCCESS(data))
  } catch (error) {
    yield put(EXPORT_PROFILE_ACTIONS.GET_LOGS_ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(EXPORT_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(EXPORT_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(EXPORT_PROFILE.GET_EXPORT, getExportData)
  yield takeLatest(EXPORT_PROFILE.DOWNLOAD_FILE, downloadExportFile)
  yield takeLatest(EXPORT_PROFILE.GET_LOGS, getLogs)
  yield takeLatest(EXPORT_PROFILE.GET_FILTER_DATA, FilterData)
}
