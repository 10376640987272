import { AFFILIATE_CATEGORIES } from './types'

export const getAffiliateCategories = payload => ({
  type: AFFILIATE_CATEGORIES.GET,
  payload
})

export const getAffiliateCategoriesSuccess = payload => ({
  type: AFFILIATE_CATEGORIES.SUCCESS,
  payload
})

export const getAffiliateCategoriesError = payload => ({
  type: AFFILIATE_CATEGORIES.ERROR,
  payload
})

export const ClearAffiliateCategoriesList = payload => ({
  type: AFFILIATE_CATEGORIES.CLEAR,
  payload
})

export const setAffiliateCategory = payload => ({
  type: AFFILIATE_CATEGORIES.SET_AFFILIATE_CATEGORY,
  payload
})

export const affiliateCategoryInformation = payload => ({
  type: AFFILIATE_CATEGORIES.AFFILIATE_CATEGORY_INFORMATION,
  payload
})

export const affiliateCategoryInformationClear = payload => ({
  type: AFFILIATE_CATEGORIES.AFFILIATE_CATEGORY_INFORMATION_CLEAR,
  payload
})

export const AFFILIATE_CATEGORIES_ACTIONS = {
  GET_AFFILIATE_CATEGORIES: getAffiliateCategories,
  SUCCESS: getAffiliateCategoriesSuccess,
  ERROR: getAffiliateCategoriesError,
  CLEAR: ClearAffiliateCategoriesList,

  SET_AFFILIATE_CATEGORY: setAffiliateCategory,
  SET_AFFILIATE_CATEGORY_INFORMATION: affiliateCategoryInformation,
  AFFILIATE_CATEGORY_INFORMATION_CLEAR: affiliateCategoryInformationClear
}
