import React, { useMemo, createRef } from 'react'
import PropTypes from 'prop-types'

import { Line } from 'react-chartjs-2'

import {
  Chart,
  LineElement,
  PointElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js'

Chart.register(
  ArcElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

const LineChart = ({ values }) => {
  const ref = createRef()

  const data = useMemo(() => ({
    labels: values.labels,
    datasets: values.datasets,
    options: values.options
  }), [])

  return (
    <>
      <Line ref={ref} data={data} />
    </>
  )
}

LineChart.propTypes = {
  values: PropTypes.object
}

export default LineChart
