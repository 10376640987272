import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectAffiliateRecord = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectAffiliateRecord, state => get(state, 'loading', false)
)

const selectPersonSucces = createSelector(
  selectAffiliateRecord, state => get(state, 'person', null)
)

const selectErrorPerson = createSelector(
  selectAffiliateRecord, state => get(state, 'errorPerson', null)
)

const selectAffiliateRecordSucces = createSelector(
  selectAffiliateRecord, state => get(state, 'affiliateRecord', null)
)

const selectBenefitsSuccess = createSelector(
  selectAffiliateRecord, state => get(state, 'benefits', null)
)

const selectContributionsSuccess = createSelector(
  selectAffiliateRecord, state => get(state, 'contributions', null)
)

const selectError = createSelector(
  selectAffiliateRecord, state => get(state, 'error', null)
)

const selectBenefitsLoading = createSelector(
  selectAffiliateRecord, state => get(state, 'benefitsLoading', false)
)

const selectContributionsLoading = createSelector(
  selectAffiliateRecord, state => get(state, 'contributionsLoading', false)
)

const selectColumns = createSelector(
  selectAffiliateRecord, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectAffiliateRecord, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectAffiliateRecord, state => get(state, 'totalItems', 0)
)

const selectContributionsError = createSelector(
  selectAffiliateRecord, state => get(state, 'contributionError', null)
)

const selectDataFilters = createSelector(
  selectAffiliateRecord, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectAffiliateRecord, state => get(state, 'dataFiltersError', null)
)

const selectModalDataResponse = createSelector(
  selectAffiliateRecord, state => get(state, 'modalData', null)
)

const selectModalResponse = createSelector(
  selectAffiliateRecord, state => get(state, 'modalResponse', null)
)

const selectProfileLoading = createSelector(
  selectAffiliateRecord, state => get(state, 'profileLoading', null)
)

const selectContributionPaymentsLoading = createSelector(
  selectAffiliateRecord, state => get(state, 'contributionPaymentsLoading', null)
)

const selectContributionPaymentsSuccess = createSelector(
  selectAffiliateRecord, state => get(state, 'contributionPayments', null)
)

const selectContributionPaymentsError = createSelector(
  selectAffiliateRecord, state => get(state, 'contributionPaymentsError', null)
)

const selectInboundPaymentsOrigins = createSelector(
  selectAffiliateRecord, state => get(state, 'inboundPaymentsOrigins', null)
)

export default {
  PERSON_SUCCESS: selectPersonSucces,
  PERSON_ERROR: selectErrorPerson,
  AFFILIATE_RECORD_SUCCESS: selectAffiliateRecordSucces,
  BENEFITS_SUCCESS: selectBenefitsSuccess,
  ERROR: selectError,

  LOADING: selectLoading,
  BENEFITS_LOADING: selectBenefitsLoading,
  CONTRIBUTIONS_LOADING: selectContributionsLoading,

  CONTRIBUTIONS_SUCCESS: selectContributionsSuccess,
  CONTRIBUTIONS_ERROR: selectContributionsError,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  MODAL_DATA_RESPONSE: selectModalDataResponse,
  MODAL_RESPONSE: selectModalResponse,

  PROFILE_LOADING: selectProfileLoading,

  CONTRIBUTION_PAYMENTS_LOADING: selectContributionPaymentsLoading,
  CONTRIBUTION_PAYMENTS_SUCCESS: selectContributionPaymentsSuccess,
  CONTRIBUTION_PAYMENTS_ERROR: selectContributionPaymentsError,

  ORIGINS: selectInboundPaymentsOrigins
}
