import { DEBT_PROFILE } from './types'

export const getDebtProfile = payload => ({
  type: DEBT_PROFILE.GET_DEBT_PROFILE,
  payload
})

export const getDebtProfileSuccess = payload => ({
  type: DEBT_PROFILE.SUCCESS,
  payload
})

export const getDebtProfileError = payload => ({
  type: DEBT_PROFILE.ERROR,
  payload
})

export const editDebtProfile = payload => ({
  type: DEBT_PROFILE.EDIT_DEBT_PROFILE,
  payload
})

export const editDebtProfileInformation = payload => ({
  type: DEBT_PROFILE.EDIT_DEBT_PROFILE_INFORMATION,
  payload
})

export const debtProfileUpdated = payload => ({
  type: DEBT_PROFILE.DEBT_PROFILE_UPDATED,
  payload
})

export const getDebtProfileModalData = payload => ({
  type: DEBT_PROFILE.GET_DEBT_PROFILE_MODAL_DATA,
  payload
})

export const DebtProfileModalDataInformation = payload => ({
  type: DEBT_PROFILE.DEBT_PROFILE_MODAL_DATA_INFORMATION,
  payload
})

export const debtProfileClear = () => ({
  type: DEBT_PROFILE.CLEAR
})

export const DEBT_PROFILE_ACTIONS = {
  GET_DEBT_PROFILE: getDebtProfile,
  SUCCESS: getDebtProfileSuccess,
  ERROR: getDebtProfileError,
  CLEAR: debtProfileClear,

  EDIT_DEBT_PROFILE: editDebtProfile,
  EDIT_DEBT_PROFILE_INFORMATION: editDebtProfileInformation,
  DEBT_PROFILE_UPDATED: debtProfileUpdated,

  GET_DEBT_PROFILE_MODAL_DATA: getDebtProfileModalData,
  DEBT_PROFILE_MODAL_DATA_INFORMATION: DebtProfileModalDataInformation
}
