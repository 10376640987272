import { PAYMENT_PROFILE } from './types'

export const getPaymentProfile = payload => ({
  type: PAYMENT_PROFILE.GET_PAYMENT_PROFILE,
  payload
})

export const getPaymentProfileSuccess = payload => ({
  type: PAYMENT_PROFILE.SUCCESS,
  payload
})

export const getPaymentProfileError = payload => ({
  type: PAYMENT_PROFILE.ERROR,
  payload
})

export const editPaymentProfile = payload => ({
  type: PAYMENT_PROFILE.EDIT_PAYMENT_PROFILE,
  payload
})

export const editPaymentProfileInformation = payload => ({
  type: PAYMENT_PROFILE.EDIT_PAYMENT_PROFILE_INFORMATION,
  payload
})

export const paymentProfileUpdated = payload => ({
  type: PAYMENT_PROFILE.PAYMENT_PROFILE_UPDATED,
  payload
})

export const getPaymentDetails = payload => ({
  type: PAYMENT_PROFILE.GET_PAYMENT_DETAILS,
  payload
})

export const getPaymentDetailsSuccess = payload => ({
  type: PAYMENT_PROFILE.PAYMENT_DETAILS_SUCCESS,
  payload
})

export const getPaymentDetailsError = payload => ({
  type: PAYMENT_PROFILE.PAYMENT_DETAILS_ERROR,
  payload
})

export const paymentDetailsUpdate = () => ({
  type: PAYMENT_PROFILE.PAYMENT_DETAILS_UPDATE
})

export const ClearPaymentDetailsList = payload => ({
  type: PAYMENT_PROFILE.PAYMENT_DETAILS_CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: PAYMENT_PROFILE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: PAYMENT_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PAYMENT_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PAYMENT_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PAYMENT_PROFILE.FILTER_DATA_CLEAR
})

export const addPaymentDetail = payload => ({
  type: PAYMENT_PROFILE.ADD_PAYMENT_DETAIL,
  payload
})

export const messageInformation = payload => ({
  type: PAYMENT_PROFILE.MESSAGE_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: PAYMENT_PROFILE.MESSAGE_INFORMATION_CLEAR
})

export const getOutboundSettlementDetail = payload => ({
  type: PAYMENT_PROFILE.GET_OUTBOUND_SETTLEMENT_DETAIL,
  payload
})

export const getOutboundSettlementDetailInformation = payload => ({
  type: PAYMENT_PROFILE.OUTBOUND_SETTLEMENT_DETAIL_INFORMATION,
  payload
})

export const getBankAccounts = payload => ({
  type: PAYMENT_PROFILE.GET_BANK_ACCOUNTS,
  payload
})

export const bankAccountInformation = payload => ({
  type: PAYMENT_PROFILE.BANK_ACCOUNTS_INFORMATION,
  payload
})

export const clearBankAccounts = () => ({
  type: PAYMENT_PROFILE.CLEAR_BANK_ACCOUNTS
})

export const getConcepts = payload => ({
  type: PAYMENT_PROFILE.GET_CONCEPTS,
  payload
})

export const conceptsInformation = payload => ({
  type: PAYMENT_PROFILE.CONCEPTS_INFORMATION,
  payload
})

export const calculateOutboundPaymentdetails = payload => ({
  type: PAYMENT_PROFILE.CALCULATE_OUTBOUND_PAYMENTS_DETAILS,
  payload
})

export const calculateOutboundPaymentdetailsInformation = payload => ({
  type: PAYMENT_PROFILE.CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION,
  payload
})

export const pendingProcessInfo = payload => ({
  type: PAYMENT_PROFILE.PENDING_PROCESS_INFO,
  payload
})

export const getProcessPendingInfo = payload => ({
  type: PAYMENT_PROFILE.GET_PENDING_PROCESS_INFO,
  payload
})

export const getNotBankAccounts = payload => ({
  type: PAYMENT_PROFILE.GET_NOT_BANK_ACCOUNTS,
  payload
})

export const noBankAccountsSuccess = payload => ({
  type: PAYMENT_PROFILE.NO_BANK_ACCOUNTS_SUCCESS,
  payload
})

export const noBankAccountsError = payload => ({
  type: PAYMENT_PROFILE.NO_BANK_ACCOUNTS_ERROR,
  payload
})

export const clearNoBankAccountError = () => ({
  type: PAYMENT_PROFILE.CLEAR_NO_BANK_ACCOUNT_ERROR
})

export const PAYMENT_PROFILE_ACTIONS = {
  GET_PAYMENT_PROFILE: getPaymentProfile,
  SUCCESS: getPaymentProfileSuccess,
  ERROR: getPaymentProfileError,
  EDIT_PAYMENT_PROFILE: editPaymentProfile,
  EDIT_PAYMENT_INFORMATION: editPaymentProfileInformation,
  PAYMENT_PROFILE_UPDATED: paymentProfileUpdated,

  GET_PAYMENT_DETAILS: getPaymentDetails,
  PAYMENT_DETAILS_SUCCESS: getPaymentDetailsSuccess,
  PAYMENT_DETAILS_ERROR: getPaymentDetailsError,
  PAYMENT_DETAILS_UPDATE: paymentDetailsUpdate,

  CLEAR: ClearPaymentDetailsList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  ADD_PAYMENT_DETAIL: addPaymentDetail,

  MESSAGE_INFORMATION: messageInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  GET_OUTBOUND_SETTLEMENT_DETAIL: getOutboundSettlementDetail,
  OUTBOUND_SETTLEMENT_DETAIL_INFORMATION: getOutboundSettlementDetailInformation,

  GET_BANK_ACCOUNTS: getBankAccounts,
  BANK_ACCOUNTS_INFORMATION: bankAccountInformation,
  CLEAR_BANK_ACCOUNTS: clearBankAccounts,

  GET_CONCEPTS: getConcepts,
  CONCEPTS_INFORMATION: conceptsInformation,

  CALCULATE_OUTBOUND_PAYMENTS_DETAILS: calculateOutboundPaymentdetails,
  CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION: calculateOutboundPaymentdetailsInformation,

  PENDING_PROCESS_INFO: pendingProcessInfo,
  GET_PROCESS_PENDING_INFO: getProcessPendingInfo,

  GET_NOT_BANK_ACCOUNTS: getNotBankAccounts,
  NO_BANK_ACCOUNTS_SUCCESS: noBankAccountsSuccess,
  NO_BANK_ACCOUNTS_ERROR: noBankAccountsError,
  CLEAR_NO_BANK_ACCOUNT_ERROR: clearNoBankAccountError
}
