import { spacing } from '@global/theme'

export const gapSize = {
  desktop: `${spacing.twoAndAHalf}`,
  tablet: `${spacing.seven}`,
  mobile: `${spacing.five}`
}

const CARD = {
  bigScreen: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 2,
    columnEnd: 7
  },
  desktop: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 1,
    columnEnd: 8
  },
  tablet: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 1,
    columnEnd: 12
  },
  tabletPortrait: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 1,
    columnEnd: 12
  },
  mobile: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 1,
    columnEnd: 12
  }
}

const DOUGHNUT = {
  bigScreen: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 8,
    columnEnd: 11
  },
  desktop: {
    rowStart: 1,
    rowEnd: 1,
    columnStart: 8,
    columnEnd: 12
  },
  tablet: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 1,
    columnEnd: 12
  },
  tabletPortrait: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 1,
    columnEnd: 12
  },
  mobile: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 1,
    columnEnd: 12
  }
}

const HORIZONTAL_BAR_CHART = {
  bigScreen: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 7,
    columnEnd: 11,
    margin: '16px 0'
  },
  desktop: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 7,
    columnEnd: 12,
    margin: '16px 0'
  },
  tablet: {
    rowStart: 4,
    rowEnd: 4,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  },
  tabletPortrait: {
    rowStart: 4,
    rowEnd: 4,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  },
  mobile: {
    rowStart: 4,
    rowEnd: 4,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  }
}

const LINE_CHART = {
  bigScreen: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 2,
    columnEnd: 6,
    margin: '16px 0'
  },
  desktop: {
    rowStart: 2,
    rowEnd: 2,
    columnStart: 1,
    columnEnd: 6,
    margin: '16px 0'
  },
  tablet: {
    rowStart: 3,
    rowEnd: 3,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  },
  tabletPortrait: {
    rowStart: 3,
    rowEnd: 3,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  },
  mobile: {
    rowStart: 3,
    rowEnd: 3,
    columnStart: 1,
    columnEnd: 12,
    margin: '16px 0'
  }
}

export const LAYOUT_DATA = {
  CARD,
  DOUGHNUT,
  HORIZONTAL_BAR_CHART,
  LINE_CHART
}

export const DOUGHNUT_OPTION_NAMES = {
  ACTIVES: 'actives',
  INACTIVES: 'inactives',
  RETIREES: 'retirees',
  IN_PROCESS: 'inProcess'
}

export const doughnutOptions = [
  { id: 1, text: 'Beneficiarios Activos', name: DOUGHNUT_OPTION_NAMES.ACTIVES },
  { id: 2, text: 'Beneficiarios Inactivos', name: DOUGHNUT_OPTION_NAMES.INACTIVES },
  { id: 3, text: 'Beneficiarios Retirados', name: DOUGHNUT_OPTION_NAMES.RETIREES },
  { id: 4, text: 'Retiro en proceso', name: DOUGHNUT_OPTION_NAMES.IN_PROCESS }
]
