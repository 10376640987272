import React, { useMemo, createRef } from 'react'
import PropTypes from 'prop-types'

import { Bar } from 'react-chartjs-2'

import { colors } from '@global/theme'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Legend,
  Title,
  Tooltip,
  BarElement
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 3
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    x: {
      beginAtZero: true
    }
  }
}

const HorizontalBarChart = ({ values }) => {
  const { totalPeopleCount, totalActiveAffiliates, totalActiveFrms } = values

  const ref = createRef()
  const data = useMemo(() => ({
    labels: ['Total', 'Afiliados Activos', 'FRM Activos'],
    datasets: [{
      label: 'Personas',
      data: [totalPeopleCount, totalActiveAffiliates, totalActiveFrms],
      borderColor: [colors.primary.regular, colors.primary.dark, colors.secondary.regular],
      backgroundColor: [colors.primary.regular, colors.primary.dark, colors.secondary.regular],
      borderWidth: 1,
      barPercentage: 0.7
    }]
  }), [])

  return (
    <>
      <Bar ref={ref} options={options} data={data} height='max-content' width='max-content' />
    </>
  )
}

HorizontalBarChart.propTypes = {
  values: PropTypes.object
}

export default HorizontalBarChart
