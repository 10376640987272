import { ERROR_LOG } from './types'

export const getErrorLog = payload => ({
  type: ERROR_LOG.GET,
  payload
})

export const getErrorLogSuccess = payload => ({
  type: ERROR_LOG.SUCCESS,
  payload
})

export const getErrorLogError = payload => ({
  type: ERROR_LOG.ERROR,
  payload
})

export const ClearErrorLogList = payload => ({
  type: ERROR_LOG.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: ERROR_LOG.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: ERROR_LOG.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: ERROR_LOG.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: ERROR_LOG.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: ERROR_LOG.FILTER_DATA_CLEAR
})

export const ERROR_LOG_ACTIONS = {
  GET_ERROR_LOG: getErrorLog,
  SUCCESS: getErrorLogSuccess,
  ERROR: getErrorLogError,
  CLEAR: ClearErrorLogList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData
}
