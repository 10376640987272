import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PAYMENT_SETTLEMENTS_ACTIONS } from './actions'
import { PAYMENT_SETTLEMENTS } from './types'

const apiCalls = {
  getPaymentSettlements: ({ data, token }) => (
    Axios.post('OutboundSettlement/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createPaymentSettlement: ({ data, token }) => (
    Axios.post('OutboundSettlement/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editPaymentSettlement: ({ paymentSettlementId, data, token }) => (
    Axios.post(`OutboundSettlement/${paymentSettlementId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })

}

export function* getPaymentSettlements ({ payload }) {
  try {
    const { token } = payload
    const { data: paymentSettlements } = yield call(apiCalls.getPaymentSettlements, payload)
    const { data: statuses } = yield call(apiCalls.getStatuses, token)
    const { data: currencies } = yield call(apiCalls.getCurrencies, token)
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.SUCCESS({ paymentSettlements, statuses, currencies }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getPaymentSettlementStatuses ({ payload }) {
  try {
    const res = yield call(apiCalls.getStatuses, payload)
    const statuses = res.data
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.PAYMENT_SETTLEMENTS_STATUSES_INFORMATION({ statuses, status: 200 }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.PAYMENT_SETTLEMENTS_STATUSES_INFORMATION(error))
  }
}

export function* addPaymentSettlement ({ payload }) {
  try {
    const { token, pageSize } = payload
    const res = yield call(apiCalls.createPaymentSettlement, payload)
    const { status } = res
    const data = { pageNumber: 1, pageSize }
    const { data: paymentSettlementsUpdated } = yield call(apiCalls.getPaymentSettlements, { data, token })

    yield put(PAYMENT_SETTLEMENTS_ACTIONS.ADD_PAYMENT_SETTLEMENT_INFORMATION({ status }))
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.PAYMENT_SETTLEMENTS_UPDATED(paymentSettlementsUpdated))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENTS_ACTIONS.ADD_PAYMENT_SETTLEMENT_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(PAYMENT_SETTLEMENTS.GET, getPaymentSettlements)
  yield takeLatest(PAYMENT_SETTLEMENTS.GET_FILTER_DATA, FilterData)
  yield takeLatest(PAYMENT_SETTLEMENTS.GET_PAYMENT_SETTLEMENTS_STATUSES, getPaymentSettlementStatuses)
  yield takeLatest(PAYMENT_SETTLEMENTS.ADD_PAYMENT_SETTLEMENT, addPaymentSettlement)
}
