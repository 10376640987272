export const key = 'USER'

export const USER = {
  GET: 'LOGIN/USER/GET',
  SUCCESS: 'LOGIN/USER/SUCCESS',
  ERROR: 'LOGIN/USER/ERROR',
  CLEAR: 'LOGIN/USER/CLEAR',
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY/SEND',
  PASSWORD_CONFIRM: 'PASSWORD_CONFIRM/SEND',
  MESSAGE_PASSWORD_CONFIRM: 'PASSWORD_CONFIRM/MESSAGE',
  REFRESH_USER: 'PASSWORD_CONFIRM/REFRESH_USER',
  ERROR_USER_REFRESH: 'PASSWORD_CONFIRM/ERROR_USER_REFRESH'
}

export default key
