import produce from 'immer'

import { NO_EMPLOYEE_FEE } from './types'

export const initialState = {
  loading: true,
  error: null,
  lastFee: null,
  historyFee: null,
  loadingList: null,
  messageInformation: null,
  erroMessage: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case NO_EMPLOYEE_FEE.GET_FEE_HISTORY:
      draft.loadingList = true
      break
    case NO_EMPLOYEE_FEE.FEE_HISTORY_SUCCESS:
      draft.loadingList = false
      draft.loading = false
      draft.historyFee = payload
      break
    case NO_EMPLOYEE_FEE.CREATE_NEW_FEE:
      draft.loadingList = true
      break
    case NO_EMPLOYEE_FEE.MESSAGE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingList = false
      break
    case NO_EMPLOYEE_FEE.ERROR_MESSAGE:
      draft.erroMessage = payload
      draft.loading = false
      draft.loadingList = false
      break
    case NO_EMPLOYEE_FEE.CLEAR_MESSAGE:
      draft.messageInformation = null
      break
    case NO_EMPLOYEE_FEE.CLEAR_ERROR_MESSAGE:
      draft.erroMessage = null
      break
    default:
      return initialState
  }
})

export default reducer
