import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { CONTRIBUTION_PROFILE_ACTIONS } from './actions'
import { CONTRIBUTION_PROFILE } from './types'

const apiCalls = {
  getContributionProfile: ({ contributionId, token }) => (
    Axios(`Contribution/${contributionId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributionProfileStatuses: token => (
    Axios('Contribution/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editContribution: ({ contributionId, data, token }) => (
    Axios.post(`Contribution/${contributionId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getConcepts: token => (
    Axios.get('Concept',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getContributionProfile ({ payload }) {
  try {
    const { token } = payload
    const { data: contributionProfileData } = yield call(apiCalls.getContributionProfile, payload)
    const { data: statusesData } = yield call(apiCalls.getContributionProfileStatuses, token)
    const { data: currencies } = yield call(apiCalls.getCurrencies, token)
    const { data: concepts } = yield call(apiCalls.getConcepts, token)

    const objData = { contributionProfileData, statusesData, currencies, concepts }
    yield put(CONTRIBUTION_PROFILE_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(CONTRIBUTION_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editContributionProfile ({ payload }) {
  try {
    const { contributionId, token } = payload
    const { status } = yield call(apiCalls.editContribution, payload)
    const { data } = yield call(apiCalls.getContributionProfile, { contributionId, token })

    yield put(CONTRIBUTION_PROFILE_ACTIONS.CONTRIBUTION_PROFILE_UPDATED(data))
    yield put(CONTRIBUTION_PROFILE_ACTIONS.EDIT_CONTRIBUTION_PROFILE_INFORMATION({ status }))
  } catch (error) {
    yield put(CONTRIBUTION_PROFILE_ACTIONS.EDIT_CONTRIBUTION_PROFILE_INFORMATION(error))
  }
}

export function* getModalDataInformation ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getContributionProfileStatuses, payload)

    const objData = { statuses }
    yield put(CONTRIBUTION_PROFILE_ACTIONS.CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION({ status: 200, objData }))
  } catch (error) {
    yield put(CONTRIBUTION_PROFILE_ACTIONS.CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(CONTRIBUTION_PROFILE.GET_CONTRIBUTION_PROFILE, getContributionProfile)
  yield takeLatest(CONTRIBUTION_PROFILE.EDIT_CONTRIBUTION_PROFILE, editContributionProfile)
  yield takeLatest(CONTRIBUTION_PROFILE.GET_CONTRIBUTION_PROFILE_MODAL_DATA, getModalDataInformation)
}
