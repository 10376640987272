import styled, { keyframes, css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { roboto, large, xlarge, semibold } from '@global/fonts'
import { colors, spacing } from '@global/theme'
import { column } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'
import { NAV_WIDTH } from '@routes/constants'

const fadeIn = keyframes`
    from {
      opacity: 0;
      width: 0;
    }
  
    to {
      opacity: 1;
      width: ${({ device }) => (device >= BREAKPOINTS.DESKTOP ? NAV_WIDTH.DESKTOP : NAV_WIDTH.MOBILE)};
    }
`

export const navMobileValues = css`
  position: fixed;
  left: 0;
  box-shadow: 2px 2px 2px 330px rgba(108, 108, 108, 0.6);
  height: 100vh;
  width: ${NAV_WIDTH.DESKTOP}px;
  display: flex;
`

export const MyNav = styled.nav`
  ${column};
  display: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'none' : 'flex')};
  width: ${({ device }) => (device >= BREAKPOINTS.BIG_SCREEN ? NAV_WIDTH.BIG_SCREEN : NAV_WIDTH.MOBILE)}px;
  height: auto;
  grid-area: nav;
  background-color: ${colors.background.regular};
  z-index: 4;

  & > :first-child {
    align-items: flex-start;
    padding-top: 0;
  }
  > :last-child {
    margin-top: ${spacing.four};
  }

  animation:${fadeIn} 0.3s ease;
  transition: all 250ms linear;
  overflow-y: auto;

  ${({ device, sidebar }) => ((device === BREAKPOINTS.MOBILE && sidebar) && navMobileValues)};
`

export const MenuContainer = styled.div`
  width: 100%; 

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > :last-child {
    margin-top: ${spacing.eight};
    & > span {
      margin-right: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device === BREAKPOINTS.BIG_SCREEN) return spacing.two
    return 0
  }}!important;
  }

`

export const MenuItem = styled.div`
    width: ${({ isActive }) => {
    if (isActive) return '100%'
    return '80%'
  }};

    display: flex;
    align-items: center;
    justify-content: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN || device === BREAKPOINTS.MOBILE) return 'flex-start'
    return 'center'
  }};
    
    color: ${colors.system.regular};
    border-left: ${({ isActive }) => (isActive ? `4px solid ${colors.primary.regular}` : 'none')};
    ${roboto};

    margin: ${spacing.half} 0;
    padding: ${({ isActive }) => {
    if (isActive) {
      return `${spacing.one} ${spacing.threeAndAHalf}`
    }
    return `${spacing.one} ${spacing.one}`
  }};
    
    ${large};
    ${semibold};
    cursor: pointer;

    &:hover {
          background-color: ${colors.secondary.regular};
          border-radius : ${({ isActive }) => (isActive ? 0 : spacing.one)};
          > div {
            display: ${({ device }) => (device !== BREAKPOINTS.BIG_SCREEN ? 'block' : 'none')};
            animation:${fadeIn} 0.6s ease;
            }
            
          }
    }

    > span {
          margin-right: ${({ device }) => (device === BREAKPOINTS.BIG_SCREEN ? spacing.two : 0)};
      }
    > a, span {
      color: ${({ isActive }) => (isActive ? colors.primary.regular : '#727171')};
    }
  `

export const MyLink = styled(NavLink)`
  width: 100%;
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device >= BREAKPOINTS.BIG_SCREEN) return 'flex-start'
    return 'center'
  }};

  color: #727171;

  > :first-child {
    margin-right: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE || device >= BREAKPOINTS.BIG_SCREEN) return spacing.two
    return 0
  }};
  
  }
`

export const LogoContainer = styled.div`
  height: 50px;
  width: 100%;
  padding: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN || device === BREAKPOINTS.MOBILE) {
      return `${spacing.one} ${spacing.threeAndAHalf}`
    }
    return `${spacing.one} ${spacing.one}`
  }};
  margin-top: ${spacing.six};
  margin-bottom: ${spacing.threeAndAHalf};
  display: flex;
  align-items: center;
  justify-content: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'space-between' : 'center')};
  ${xlarge};
  color: ${colors.primary.regular};

  & > span {
    cursor: pointer;
    &:hover {
      color: ${colors.primary.hover};
    }
  }

`

export const Tooltip = styled.div`
  width: 7rem;
  height: auto;
  padding: ${spacing.half};
  position: absolute;
  color: ${colors.primary.regular};
  left:  ${(NAV_WIDTH.MOBILE)}px;
  display: none;
  background-color: ${colors.secondary.regular};
  border-radius: ${spacing.threeQuarters}; 
  text-align: center;
  box-shadow: 0 ${spacing.threeQuarters} ${spacing.oneAndAQuarter} rgba(0, 0,0, 0.2);
  font-size: ${spacing.oneAndAHalf};
  ${semibold};
`

export const ImgContainer = styled.div`
  width: 100%;
  color: ${colors.system.primary};
  ${roboto};

  padding: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN || device === BREAKPOINTS.MOBILE) {
      return `${spacing.one} ${spacing.threeAndAHalf}`
    }
    return 0
  }};
  margin-bottom: ${spacing.one};

  display: flex;
  align-items: center;
  justify-content: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN || device === BREAKPOINTS.MOBILE) return 'flex-start'
    return 'center'
  }};

  border-radius: 8px;
  cursor: pointer;
  &:hover {
          transition: 0.5s all ease;

          > div {
            display: ${({ device }) => (device !== BREAKPOINTS.BIG_SCREEN ? 'block' : 'none')};
            animation:${fadeIn} 0.6s ease;
            }
            
          }
    }
`

export const Img = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin-right: ${({ device }) => {
    if (device === BREAKPOINTS.BIG_SCREEN || device === BREAKPOINTS.MOBILE) return spacing.oneAndAQuarter
    return 0
  }};
    border-radius: ${({ borderRadius }) => (borderRadius && borderRadius)};
    object-fit: cover;
`
