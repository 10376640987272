import React, { useState } from 'react'
import PropTypes from 'prop-types'

import MyPropTyes from '@global/propTypes'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { checkField } from '@components/inputs/utils/validation'

import PasswordInput from '../password'

export const PswrdRepeat = props => {
  const { name, pwRepeat, onChange, required } = props

  const [repeat, setRepeat] = useState('')

  const handleChange = ({ value }) => {
    setRepeat(value)
    const error = checkField({
      value,
      pwRepeat,
      required,
      type: INPUT_TYPES.PWREPEAT
    })
    onChange({ name, value: !error, error })
  }

  return (
    <PasswordInput
      {...props}
      value={repeat}
      onChange={handleChange}
    />
  )
}

PswrdRepeat.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  color: MyPropTyes.color,
  pwRepeat: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  bits: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      name: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  onChange: PropTypes.func
}

export default PswrdRepeat
