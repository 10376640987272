import produce from 'immer'

import { LIQUIDATIONS } from './types'

export const initialState = {
  loading: true,
  error: false,
  liquidationsLoading: false,
  liquidationsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  liquidationStatuses: null,
  messageInformation: null,
  loadingStatuses: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case LIQUIDATIONS.GET:
      draft.liquidationsLoading = true
      break
    case LIQUIDATIONS.SUCCESS: {
      const { liquidations, statuses } = payload
      const { items, fields, totalItems, totalPages, currentPage } = liquidations
      draft.loading = false
      draft.liquidationsLoading = false
      draft.liquidationsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.liquidationStatuses = statuses
    }
      break
    case LIQUIDATIONS.ERROR:
      draft.loading = false
      draft.liquidationsLoading = false
      draft.error = payload
      break
    case LIQUIDATIONS.CLEAR:
      return initialState
    // FILTER
    case LIQUIDATIONS.SEND_FILTER:
      draft.loading = true
      break
    case LIQUIDATIONS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case LIQUIDATIONS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case LIQUIDATIONS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case LIQUIDATIONS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case LIQUIDATIONS.GET_STATUSES:
      draft.loadingStatuses = true
      break
    case LIQUIDATIONS.STATUSES_INFORMATION:
      draft.liquidationStatuses = payload
      draft.loadingStatuses = false
      break
    case LIQUIDATIONS.ADD_LIQUIDATION:
      draft.liquidationsLoading = true
      break
    case LIQUIDATIONS.ADD_LIQUIDATION_INFORMATION:
      draft.messageInformation = payload
      draft.liquidationsLoading = false
      break
    case LIQUIDATIONS.CLEAR_MESSAGE_INFORMATION:
      draft.messageInformation = null
      break
    default:
      return initialState
  }
})

export default reducer
