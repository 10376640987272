import styled from 'styled-components'
import { spacing, colors } from '@global/theme'
import { BREAKPOINTS } from '@global/constants'

export const Head = styled.header`
  width: 100%;
  position: fixed;
  background-color: ${colors.background.regular};
  z-index: 3;
  height: 50px;
  padding: ${spacing.one} ${spacing.two} 0 ${spacing.two};
  display: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;

  box-shadow: ${({ device }) => (device === BREAKPOINTS.MOBILE && '2px 2px 2px 2px rgba(108, 108, 108, 0.2)')};


  > :last-child {
    margin-left: ${spacing.two};
    cursor: pointer;
  }

  & > span {
    cursor: pointer;
    &:hover {
      color: ${colors.primary.hover};
    }
  }

`

export const Img = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    margin-right: ${spacing.oneAndAQuarter};
    border-radius: ${({ borderRadius }) => (borderRadius && borderRadius)};
    object-fit: cover;
`
export const ProfileData = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
`
