import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Setting from '@containers/setting'
import ErrorLog from '@containers/errorLog'
import ActivityLog from '@containers/activityLog'
import AtaeBankAccountsManagment from '@containers/ataeBankAccounts'

import { UsersRouter } from './UsersRouter'
import { ImportsRouter } from './ImportsRouter'
import { EntityManagmentRouter } from './EntityManagmentRouter'
import { ExportRouter } from './ExportsRouter'

export const SettingRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={Setting} />
      <PrivateRoute exact path={`${url}${SITE.ERROR_LOG}`} component={ErrorLog} />
      <PrivateRoute exact path={`${url}${SITE.ACTIVITY_LOG}`} component={ActivityLog} />

      <PrivateRoute path={`${url}${SITE.USERS}`} component={UsersRouter} />
      <PrivateRoute path={`${url}${SITE.IMPORTS}`} component={ImportsRouter} />
      <PrivateRoute path={`${url}${SITE.EXPORTS}`} component={ExportRouter} />
      <PrivateRoute path={`${url}${SITE.ENTITY_MANAGMENT}`} component={EntityManagmentRouter} />
      <PrivateRoute path={`${url}${SITE.ATAE_BANK_ACCOUNTS}`} component={AtaeBankAccountsManagment} />
    </Switch>
  )
}
