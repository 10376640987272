import styled from 'styled-components'
import { spacing } from '@global/theme'
import { MyButtonLink } from '@global/styles'
import { regular, medium } from '@global/fonts'

export const TableWrapperHistory = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 4;
  margin-bottom: ${spacing.three};
`

export const PaginationWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 5;
  grid-row-end: 5;
  margin-bottom: ${spacing.three};
`

export const HistoryLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
`
