import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectServiceProvider = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectServiceProvider, state => get(state, 'loading', null)
)

const selectLoadingServiceProvider = createSelector(
  selectServiceProvider, state => get(state, 'serviceProviderLoading', null)
)

const selectSuccess = createSelector(
  selectServiceProvider, state => get(state, 'serviceProviderList', [])
)

const selectColumns = createSelector(
  selectServiceProvider, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectServiceProvider, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectServiceProvider, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectServiceProvider, state => get(state, 'error', null)
)

const selectMessageInformation = createSelector(
  selectServiceProvider, state => get(state, 'messageInformation', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingServiceProvider,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  ADD_SERVICE_PROVIDER_INFORMATION: selectMessageInformation
}
