import produce from 'immer'

import { EXPORTS } from './types'

export const initialState = {
  loading: true,
  error: false,
  listLoading: false,
  exportsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  modalDataLoding: false,
  modalData: null,
  messageInformation: null,
  exportObj: null,
  createLoading: null,
  jobTypes: null,
  outboundPayment: null,
  concepts: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case EXPORTS.GET:
      draft.listLoading = true
      break
    case EXPORTS.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage, jobTypes } = payload
      draft.loading = false
      draft.listLoading = false
      draft.exportsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.jobTypes = jobTypes
      draft.pages = { totalPages, currentPage }
    }
      break
    case EXPORTS.ERROR:
      draft.loading = false
      draft.listLoading = false
      draft.error = payload
      break
    case EXPORTS.CLEAR:
      return initialState
    case EXPORTS.SEND_FILTER:
      draft.loading = true
      break
    case EXPORTS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case EXPORTS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case EXPORTS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case EXPORTS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case EXPORTS.GET_MODAL_DATA:
      draft.modalDataLoding = true
      break
    case EXPORTS.MODAL_DATA_INFORMATION:
      draft.modalDataLoding = false
      draft.modalData = payload
      break
    case EXPORTS.GET_PAYMENTS_EXPORTS:
      draft.modalDataLoding = true
      break
    case EXPORTS.PAYMENTS_EXPORTS_INFORMATION:
      draft.modalDataLoding = false
      draft.outboundPayment = payload
      break
    case EXPORTS.CREATE_EXPORT:
      draft.createLoading = true
      break
    case EXPORTS.EXPORT_SUCCESS:
      draft.createLoading = false
      draft.exportObj = payload
      break
    case EXPORTS.MESSAGE_INFORMATION:
      draft.modalDataLoding = false
      draft.messageInformation = payload
      break
    case EXPORTS.CLEAR_MESSAGE_INFORMATION:
      draft.messageInformation = null
      break
    case EXPORTS.CLEAR_DATA:
      draft.modalData = null
      draft.outboundPayment = null
      draft.concepts = null
      break
    // CONCEPTS
    case EXPORTS.GET_CONCEPTS:
      draft.modalDataLoding = true
      break
    case EXPORTS.CONCEPTS_SUCCESS:
      draft.modalDataLoding = false
      draft.concepts = payload
      break
    default:
      return initialState
  }
})

export default reducer
