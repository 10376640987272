export const columnData = {
  Code: { name: 'Código', widthCol: '10%' },
  Concept: { name: 'Concepto', widthCol: '15%' },
  Person: { name: 'Persona', widthCol: '30%' },
  Currency: { name: 'Moneda', widthCol: '10%' },
  Amount: { name: 'Monto', widthCol: '10%' },
  Contribution: { name: 'Contribución', widthCol: '10%' },
  Status: { name: 'Estado', widthCol: '15%' },
  Description: { name: 'Descripción', widthCol: '15%' },
  Month: { name: 'Mes', widthCol: '15%' },
  Year: { name: 'Año', widthCol: '15%' },
  OriginName: { name: 'Origen', widthCol: '15%' }
}

export const COLUMN_DATA_MODAL = [
  { name: 'Código', widthCol: '5%' },
  { name: 'Liquidación', widthCol: '10%' },
  { name: 'Persona', widthCol: '20%' },
  { name: 'Concepto', widthCol: '10%' },
  { name: 'Monto', widthCol: '5%' },
  { name: 'F. Vencimiento', widthCol: '15%' },
  { name: 'F. Pago', widthCol: '15%' },
  { name: 'Monto cobrado', widthCol: '10%' }
]

export const SELECT_ROW = 'Seleccione una fila de la tabla'

export const INICIAL_STATE_SELECTED_ROW = {
  selected: false,
  contributionId: SELECT_ROW,
  settlement: SELECT_ROW,
  person: SELECT_ROW,
  concept: SELECT_ROW
}
