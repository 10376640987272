import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

import MyPropTyes from '@global/propTypes'
import Label from '@components/label'
import { SelectStyles } from './styles'

const animatedComponents = makeAnimated()

const SelectInput = props => {
  const {
    options,
    placeholder,
    color,
    value,
    onChange,
    noOptionsMessage,
    disabled,
    size,
    isMulti,
    asyncSelect,
    loadOptions,
    label,
    required,
    loadingMessage
  } = props

  const renderSelect = () => (
    <Select
      options={options}
      placeholder={placeholder}
      value={value}
      color={color}
      onChange={onChange}
      components={animatedComponents}
      styles={SelectStyles}
      isMulti={isMulti}
      isSearchable
      clereable
      isDisabled={disabled}
      noOptionsMessage={noOptionsMessage}
      size={size}
    />
  )

  const renderAsyncSelect = () => (
    <AsyncSelect
      value={value}
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={onChange}
      color={color}
      isMulti={isMulti}
      styles={SelectStyles}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
    />
  )

  return (
    <>
      {label && (
      <Label text={label} disabled={disabled} required={required} />
      )}
      {asyncSelect ? renderAsyncSelect() : renderSelect()}
    </>
  )
}

SelectInput.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  color: MyPropTyes.color,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
  isMulti: PropTypes.bool,
  size: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ]),
  asyncSelect: PropTypes.bool,
  loadOptions: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  loadingMessage: PropTypes.func
}

export default SelectInput
