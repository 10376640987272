import { ATAE_BANK_ACCOUNTS } from './types'

export const getAtaeBankAccounts = payload => ({
  type: ATAE_BANK_ACCOUNTS.GET_ATAE_BANK_ACCOUNTS,
  payload
})

export const getAtaeBankAccountsSuccess = payload => ({
  type: ATAE_BANK_ACCOUNTS.SUCCESS,
  payload
})

export const getAtaeBankAccountsError = payload => ({
  type: ATAE_BANK_ACCOUNTS.ERROR,
  payload
})

export const AtaeBankAccountsClear = payload => ({
  type: ATAE_BANK_ACCOUNTS.CLEAR,
  payload
})

export const editAtaeBankAccounts = payload => ({
  type: ATAE_BANK_ACCOUNTS.EDIT_ATAE_BANK_ACCOUNT,
  payload
})

export const createAtaeBankAccount = payload => ({
  type: ATAE_BANK_ACCOUNTS.CREATE_ATAE_BANK_ACCOUNT,
  payload
})

export const editAtaeBankAccount = payload => ({
  type: ATAE_BANK_ACCOUNTS.EDIT_ATAE_BANK_ACCOUNT,
  payload
})

export const deleteAtaeBankAccount = payload => ({
  type: ATAE_BANK_ACCOUNTS.DELETE_ATAE_BANK_ACCOUNT,
  payload
})

export const messageInformation = payload => ({
  type: ATAE_BANK_ACCOUNTS.MESSAGE_INFORMATION,
  payload
})

export const ClearMessageInformation = payload => ({
  type: ATAE_BANK_ACCOUNTS.CLEAR_MESSAGE_INFORMATION,
  payload
})

export const getAtaeModalData = payload => ({
  type: ATAE_BANK_ACCOUNTS.ATAE_GET_MODAL_DATA,
  payload
})

export const getAtaeModalDataInformation = payload => ({
  type: ATAE_BANK_ACCOUNTS.ATAE_MODAL_DATA_INFORMATION,
  payload
})

export const ATAE_BANK_ACCOUNTS_ACTIONS = {
  GET_ATAE_BANK_ACCOUNTS: getAtaeBankAccounts,
  SUCCESS: getAtaeBankAccountsSuccess,
  ERROR: getAtaeBankAccountsError,
  CLEAR: AtaeBankAccountsClear,

  CREATE_ATAE_BANK_ACCOUNT: createAtaeBankAccount,
  EDIT_ATAE_BANK_ACCOUNT: editAtaeBankAccounts,
  DELETE_ATAE_BANK_ACCOUNT: deleteAtaeBankAccount,

  ATAE_GET_MODAL_DATA: getAtaeModalData,
  ATAE_MODAL_DATA_INFORMATION: getAtaeModalDataInformation,

  MESSAGE_INFORMATION: messageInformation,
  CLEAR_MESSAGE_INFORMATION: ClearMessageInformation
}
