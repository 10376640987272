export const key = 'PEOPLE'

export const PEOPLE = {
  GET: 'PEOPLE/GET_LIST',
  SUCCESS: 'PEOPLE/GET_LIST/SUCCESS',
  ERROR: 'PEOPLE/GET_LIST/ERROR',
  CLEAR: 'PEOPLE/CLEAR_LIST',
  SEND_FILTER: 'PEOPLE/FILTER/SEND',
  GET_FILTER_DATA: 'PEOPLE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PEOPLE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PEOPLE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PEOPLE/FILTER/CLEAR',
  GET_MODAL_TYPES: 'PEOPLE/MODAL/GET_MODAL_TYPES',
  INFORMATION_MODAL_TYPES: 'PEOPLE/MODAL/INFORMATION_MODAL_TYPES',
  CREATE_PERSON: 'PEOPLE/CREATE_PERSON',
  CREATE_PERSON_INFORMATION: 'PEOPLE/CREATE_PERSON_INFORMATION',
  CLEAR_PERSON_INFORMATION: 'PEOPLE/CLEAR_PERSON_INFORMATION',
  PERSON_STATUS: 'PEOPLE/PERSON_STATUS'
}

export default key
