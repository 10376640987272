import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { COLUMN_ORDER } from '@global/constants'
import { MESSAGES } from '@global/message'
import { useSize } from '@utils/hooks'

import { Text } from '@components/texts'

import Icon from '@components/icon'
import Loading from '@components/loading'

import usePageWidth from './utils/hook'

import {
  TableStyled,
  Head,
  Body,
  Tr,
  Th,
  Td,
  ColumnTitle,
  LoadingContainer,
  TextContainer,
  TableChildrenContainer
} from './styles'

const Table = props => {
  const {
    list,
    columns,
    loading,
    handleOrder,
    rowClick,
    error,
    children,
    showRowPointer,
    timeDate,
    notConversion
  } = props
  const width = usePageWidth()

  const nav = document.querySelector('nav')
  const [target, currentSize] = useSize()
  const { width: widthElement } = currentSize

  const [tableList, setTableList] = useState([])
  const [widthTable, setWidthTable] = useState()
  const [selectedColumn, setSelectedColumn] = useState({
    order: '',
    icon: '',
    columnId: null
  })
  const [flat, setFlat] = useState(false)

  const handleColunmClick = (columnName, colId) => {
    if (!handleOrder) return
    let newSelCol = {}
    if (!selectedColumn.columnId || selectedColumn.columnId !== colId) {
      newSelCol = {
        columnId: colId,
        order: COLUMN_ORDER.DESCENDING,
        icon: 'expand_more'
      }
    } else {
      newSelCol = {
        ...selectedColumn,
        order: selectedColumn.order === COLUMN_ORDER.DESCENDING ? COLUMN_ORDER.ASCENDING : COLUMN_ORDER.DESCENDING,
        icon: selectedColumn.icon === 'expand_more' ? 'expand_less' : 'expand_more'
      }
    }
    setSelectedColumn(newSelCol)
    handleOrder(columnName, newSelCol.order === COLUMN_ORDER.DESCENDING)
  }

  const handleRowClick = rowData => {
    if (rowClick) rowClick(rowData)
  }

  useEffect(() => {
    setTableList(list)
  }, [list])

  useEffect(() => {
    setSelectedColumn({
      order: '',
      icon: '',
      columnId: null
    })
  }, [error])

  useEffect(() => {
    target(nav)
    setWidthTable(width)
  }, [])

  useEffect(() => {
    if (widthElement) {
      if (!flat) {
        setFlat(true)
      }
      setWidthTable(document.getElementById('page').clientWidth)
    }
  }, [widthElement])

  useEffect(() => {
    setWidthTable(width)
  }, [width])

  return (
    error || list.length < 1
      ? (
        <TextContainer width={widthTable}>
          <Text size='large' weight='bold' align='center'>{MESSAGES.NO_DATA}</Text>
        </TextContainer>
      )
      : (
        <TableStyled width={widthTable}>
          <Head>
            <Tr type='header'>
              {columns.length > 0
            && columns.map(({ name, widthCol }, index) => (
              <Th
                key={name}
                id={index}
                name={name}
                onClick={() => handleColunmClick(name, index + 1)}
                widthTh={widthCol}
                tableWidth={width}
              >
                {typeof name === 'string'
                  ? (
                    <ColumnTitle key={name}>
                      <Text
                        size='medium'
                        align='left'
                        weight='semibold'
                        color={(selectedColumn.columnId === index + 1 ? 'primary' : 'system')}
                      >
                        {name}
                      </Text>
                      {selectedColumn.icon
                   && (
                   <Icon
                     name={(selectedColumn.columnId === index + 1 ? selectedColumn.icon : '')}
                     color={(selectedColumn.columnId === index + 1 ? 'primary' : 'system')}
                     size='medium'
                   />
                   )}
                    </ColumnTitle>
                  )
                  : <ColumnTitle key={index}>{name}</ColumnTitle>}
              </Th>
            ))}
            </Tr>
          </Head>
          { loading
            ? (
              <LoadingContainer width={widthTable}>
                <Loading color='primary' size='xsmall' weight='bold' />
              </LoadingContainer>
            )
            : (
              <Body>
                {list.length > 0
              && tableList.map((obj, index) => (
                <Tr
                  key={obj.id}
                  id={obj.id}
                  index={index}
                  onClick={() => handleRowClick(obj)}
                  boolOnClick={showRowPointer}
                >
                  {Object.entries(obj).map(person => {
                    const key = person[0]
                    let value = person[1]

                    if (key === 'id') return
                    if (key.toLowerCase().includes('date')) {
                      const isValidDate = Date.parse(value)
                      if (isValidDate) {
                        const dt = new Date(value)
                        let options = {
                          dateStyle: 'short'
                        }
                        if (!notConversion) {
                          options = {
                            ...options,
                            timeZone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
                          }
                          if (timeDate) options = { ...options, timeStyle: 'medium' }
                          value = dt.toLocaleString(`${navigator.language}`, options)
                        } else {
                          value = new Date(dt.toUTCString().replace('GMT', '')).toLocaleDateString()
                        }
                      }
                    }
                    if (!Number.isNaN(value) && (typeof value === 'number')) value = value.toString()
                    return (
                      <Td key={key} index={index}>
                        { typeof key === 'string'
                          ? (
                            <Text
                              size='medium'
                              align='left'
                              weight='regular'
                              color={value === 'deudor' ? 'error' : 'system'}
                            >
                              {value || 'N/A'}
                            </Text>
                          )
                          : (
                            value
                          )}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
              </Body>
            )}
          {children
           && (
           <TableChildrenContainer>
             { children }
           </TableChildrenContainer>
           )}
        </TableStyled>
      )
  )
}

Table.defaultProps = {
  rowClick: null,
  timeDate: false
}

Table.propTypes = {
  list: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleOrder: PropTypes.func,
  rowClick: PropTypes.func,
  error: PropTypes.bool,
  showRowPointer: PropTypes.bool,
  children: PropTypes.element,
  timeDate: PropTypes.bool,
  notConversion: PropTypes.bool
}

export default Table
