import React, { useState, useEffect } from 'react'

import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  TOKEN_HELPERS,
  formatDateBasic,
  handleClickPaginationItem,
  filterOrderBuilder,
  filterBodyBuilder,
  currencyFormatUY,
  strMonthYear
} from '@utils/helpers'
import { SITE } from '@routes/paths'
import {
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  ButtonContainer,
  WrapperHeading,
  MyButtonLink
} from '@global/styles'
import {
  TRANSLATION_OF_ROUTES,
  FUNCTION_TYPE,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  BREAKPOINTS,
  SUBTITLES_TABLES,
  MONTHS,
  DETAIL_TYPES
} from '@global/constants'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Label from '@components/label'
import Table from '@components/table'
import { useUser } from '@components/authentication/utils/hook'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import Toast from '@components/toast'
import Modal from '@components/modal'
import Icon from '@components/icon'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import Badge from '@components/badge'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import {
  WraperBreadcrumbs,
  WrapperLoading,
  MyLink,
  BenefitsContainer,
  BenefitsTopContainer,
  ListContainer,
  BenefitItem,
  DataContainer,
  Tbody,
  Td,
  Tr,
  TextContainer,
  TableContainerAffRec,
  LinkContainer,
  CustomButtonLink,
  TableContainerAffRecCP,
  TextContainerCP
} from './styles'

import useRedux from './redux'
import { AFFILIATE_RECORD_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { columnData, COLUMN_CONTRIBUTION_PAYMENTS, FILTER_TYPE } from './data'
import AddBenefitsModal from './components/addBenefitsModal'
import EditAffiliateRecordProfile from './components/editAffiliateRecordProfileModal'

const AffiliateRecord = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { recordId } = useParams()
  const { pathname, state } = useLocation()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [person, setPerson] = useState(null)
  const [affiliateRecord, setAffiliateRecord] = useState()
  const [benefits, setBenefits] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [filterData, setFilterData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [badges, setBadges] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [open, setOpen] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [removeBenefitModal, setRemoveBenefitModal] = useState(null)
  const [addBenefitModal, setAddBenefitModal] = useState(null)
  const [editProfileModal, setEditProfileModal] = useState(null)
  const [modalTitle, setModalTitle] = useState(null)
  const [activeFilters, setActiveFilters] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [flag, setFlag] = useState(false)
  const [errorFlag, setErrorFlag] = useState({
    affiliateRecord: false,
    person: false,
    contributions: false,
    contributionPayments: false
  })

  const [parameters, setParameters] = useState()
  const [pagesCP, setPagesCP] = useState()
  const [totalItemsCP, setTotalItemsCP] = useState(0)
  const [tablelistCP, setTablelistCP] = useState([])
  const [columnsCP, setColumnsCP] = useState([])
  const [showTableCP, setShowTableCP] = useState(false)
  const [selectedOptionCP, setSelectedOptionCP] = useState(rowsPerPage[0])
  const [itemSelectedCP, setItemSelectedCP] = useState()
  const [activeFiltersCP, setActiveFiltersCP] = useState(null)
  const [showFilterCP, setShowFilterCP] = useState(false)
  const [badgesCP, setBadgesCP] = useState([])
  const [parametersCP, setParametersCP] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const personData = useSelector(SELECTORS.PERSON_SUCCESS)
  const personError = useSelector(SELECTORS.PERSON_ERROR)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const affiliateRecordData = useSelector(SELECTORS.AFFILIATE_RECORD_SUCCESS)
  const benefitsItems = useSelector(SELECTORS.BENEFITS_SUCCESS)
  const loadingBenefits = useSelector(SELECTORS.BENEFITS_LOADING)
  const modalResponse = useSelector(SELECTORS.MODAL_RESPONSE)
  const contributions = useSelector(SELECTORS.CONTRIBUTIONS_SUCCESS)
  const itemsError = useSelector(SELECTORS.CONTRIBUTIONS_ERROR)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const contributionLoading = useSelector(SELECTORS.CONTRIBUTIONS_LOADING)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterObjectError = useSelector(SELECTORS.ERROR_FILTER_OBJECT)
  const profileLoading = useSelector(SELECTORS.PROFILE_LOADING)
  const origins = useSelector(SELECTORS.ORIGINS)

  const conPayLoading = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_LOADING)
  const conPaymentsData = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_SUCCESS)
  const conPaymentsDataError = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_ERROR)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getAffiliateRecordData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.GET_AFFILIATE_RECORD({ recordId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributions = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTIONS({ recordId, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributionPayments = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataPerson = personId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.GET_PERSON_DATA({ personId, recordId, token }))
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByDesc: true,
        orderByColumn: 'Id'
      }
      getContributions(body)
      setActiveFilters(body)
      const bodyCP = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByDesc: true,
        orderByColumn: 'Id',
        affiliateRecordId: recordId
      }
      getContributionPayments(bodyCP)
      setActiveFiltersCP(bodyCP)
      setFlag(true)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const sendToLiquidationProfile = settlementId => {
    const win = window.open(`${SITE.LIQUIDATION_LOG}/${settlementId}`, '_blank')
    win.focus()
  }

  const handleContributionPaymentClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handleContributionClick = contributionId => {
    const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
    win.focus()
  }

  const handleSendToContributionPaymentList = contributionId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${contributionId}/${DETAIL_TYPES.CONTRIBUTION_PAYMENT}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { contributionPayments } = item
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => handleContributionClick(item.id)}
        >
          {`C - ${item.id}`}
        </CustomButtonLink>
      )
      const settlement = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToLiquidationProfile(item.settlementId)}
        >
          {strMonthYear(item.settlementMonth - 1, item.settlementYear)}
        </CustomButtonLink>
      )

      let link = 'N/A'
      contributionPayments = contributionPayments.filter(cp => cp?.statusId === 1)
      if (contributionPayments?.length > 0) {
        link = contributionPayments?.length === 1
          ? (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleContributionPaymentClick(contributionPayments[0].id)}
            >
              {`DI - ${contributionPayments[0].id}`}
            </CustomButtonLink>
          )
          : (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToContributionPaymentList(item.id)}
            >
              Ver lista de DI
            </CustomButtonLink>
          )
      }

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        settlement,
        total: currencyFormatUY(item.total),
        concept: item.concept,
        status: item.status,
        paymentDate: item.paymentDate,
        dueDate: item.dueDate,
        totalPaid,
        description: item.description,
        link

      }
      return newItem
    })
    return newData
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = activeFilters
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributions(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributions(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getContributions(body)
  }

  const handleClickShowFilters = type => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      let filterRequest = {}
      if (type === FILTER_TYPE.CONTRIBUTIONS) filterRequest = { id: 9, token, type }
      else filterRequest = { id: 12, token, type }
      dispatch(AFFILIATE_RECORD_ACTIONS.GET_FILTER_DATA(filterRequest))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleClickCloseFilters = type => {
    if (type === FILTER_TYPE.CONTRIBUTIONS) setShowFilter(!showFilter)
    else setShowFilterCP(!showFilterCP)
    dispatch(AFFILIATE_RECORD_ACTIONS.FILTER_DATA_CLEAR())
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleApplyFilterCP = filters => {
    setBadgesCP(filters)
    const body = filterBodyBuilder(filters, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP)
    getContributionPayments(body)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleCloseBadgeCP = filterName => {
    const updateBadgesCP = badgesCP.filter(badge => badge.name !== filterName)
    setBadgesCP(updateBadgesCP)
    const body = filterBodyBuilder(
      updateBadgesCP, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP
    )
    getContributionPayments(body)
  }

  const handleInputChangeCP = e => {
    if (!e?.value) delete activeFiltersCP.filterText

    let body = activeFiltersCP ? { ...activeFiltersCP } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersCP({
        ...activeFiltersCP,
        filterText: e.value
      })
    }
    getContributionPayments(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const handleSetBenefit = (benefit, functionType) => {
    const { id } = benefit
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.SET_BENEFIT({ functionType, recordId, benefitId: id, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const sendToContributionProfile = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const sendToContributionPaymentProfile = contributioPaymentId => {
    if (contributioPaymentId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributioPaymentId}`, '_blank')
      win.focus()
    }
  }

  const customDataTableCP = itemsTableCP => {
    const newData = itemsTableCP.map(item => {
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionPaymentProfile(item.id)}
        >
          {`DI - ${item.id}`}
        </CustomButtonLink>
      )

      const contribution = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionProfile(item.contributionId)}
        >
          {`C - ${item.contributionId}`}
        </CustomButtonLink>
      )

      const originName = item.originName || origins?.find(o => o.id === item.originId)?.name || 'N/A'

      const newItem = {
        id: item.id,
        code,
        concept: item.concept,
        originName,
        currency: item.currencyName,
        amount: currencyFormatUY(item.amount),
        contribution,
        status: item.statusName,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month)?.label : 'N/A',
        year: item.year
      }
      return newItem
    })
    return newData
  }

  const handleDropdownChangeCP = e => {
    let body = null
    if (activeFiltersCP) {
      body = activeFiltersCP
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributionPayments(body)
    setSelectedOptionCP(e.value)
    setItemSelectedCP(0)
  }

  const handleOrderChangeCP = (nameColumn, typeOrder) => {
    const colNames = COLUMN_CONTRIBUTION_PAYMENTS.find(col => col.name === nameColumn).label
    const propsForOrderBuilder = {
      filterState: activeFiltersCP,
      setFilterState: setActiveFiltersCP,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionCP.value,
      currentPageNumber: itemSelectedCP,
      setNewItemSelected: setItemSelectedCP
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributionPayments(body)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setRemoveBenefitModal(null)
    setAddBenefitModal(null)
    setEditProfileModal(null)
    dispatch(AFFILIATE_RECORD_ACTIONS.CLEAR_DATA_MODAL())
  }

  const openRemoveBenefitModal = (itemId, title, text, deleteFn, dataType = null) => {
    setModalTitle(title)
    setRemoveBenefitModal({
      close: handleCloseModal,
      text,
      itemId,
      removeItem: deleteFn,
      dataType
    })
    setOpen(true)
  }

  const openAddBenefitModal = () => {
    setModalTitle('Agregar beneficio')
    setAddBenefitModal({
      close: handleCloseModal,
      selectedData: handleSetBenefit,
      currentsBenefits: benefits,
      currentToken
    })
    setOpen(true)
  }

  const editProfile = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(AFFILIATE_RECORD_ACTIONS.EDIT_PROFILE({ recordId, data: newProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleEditProfile = () => {
    setModalTitle('Editar Perfil')
    setEditProfileModal({
      close: handleCloseModal,
      saveData: editProfile,
      oldData: affiliateRecordData,
      currentToken
    })
    setOpen(true)
  }

  const renderAffiliateRecordData = objAffiliateRecordData => (
    <>
      <Tr>
        <Td colorLight widthPerc>Fecha ingreso ATAE:</Td>
        <Td largeSize>{formatDateBasic(objAffiliateRecordData.startDate, 'short')}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Fecha egreso ATAE:</Td>
        <Td largeSize>{formatDateBasic(objAffiliateRecordData.endDate, 'short')}</Td>
      </Tr>
      <Tr><Td colorLight widthPerc>Categoría:</Td> <Td largeSize>{objAffiliateRecordData.categoryName}</Td></Tr>
      <Tr>
        <Td colorLight widthPerc>Estado:</Td>
        <Td largeSize>
          <Badge
            text={objAffiliateRecordData?.statusName || 'N/A'}
            backgroundColor='secondary'
            size='medium'
            color='contrast'
          />
        </Td>
      </Tr>
      {objAffiliateRecordData.statusId === 3
      && (
      <Tr>
        <Td colorLight widthPerc>Fecha de desafiliación en proceso:</Td>
        <Td largeSize>{formatDateBasic(objAffiliateRecordData.endInProcessDate, 'short')}</Td>
      </Tr>
      )}
    </>
  )

  const renderBenefitsList = arrBenefits => {
    const title = 'Eliminar beneficio'
    const text = '¿Estás seguro que deseas borrar este beneficio?'
    return arrBenefits.length > 0 && arrBenefits.map(benefit => (
      <BenefitItem key={benefit.id} id={benefit.id}>
        <Text size='medium' weight='regular' align='left'>{benefit.name}</Text>
        <Icon
          color='system'
          size='medium'
          name='delete'
          onClick={
            () => openRemoveBenefitModal(
              benefit, title, text, handleSetBenefit, FUNCTION_TYPE.DELETE
            )
          }
        />
      </BenefitItem>
    ))
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getContributions, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  const renderFilter = (type, arrFilters) => {
    let currentLoadBadges = null
    let currentShowFilter = null

    if (type === FILTER_TYPE.CONTRIBUTIONS) {
      currentLoadBadges = handleApplyFilter
      currentShowFilter = showFilter
    } else {
      currentLoadBadges = handleApplyFilterCP
      currentShowFilter = showFilterCP
    }

    return (
      <Filter
        close={() => handleClickCloseFilters(type)}
        loadBadges={currentLoadBadges}
        filterData={arrFilters}
        isShowing={currentShowFilter}
        device={device}
      />
    )
  }

  const renderTableCP = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pagesCP}
          itemSelected={itemSelectedCP}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFiltersCP, selectedOptionCP.value, getContributionPayments, setItemSelectedCP
          )}
          options={rowsPerPage}
          selectedOption={selectedOptionCP}
          handleDropdownChange={handleDropdownChangeCP}
          totalItems={totalItemsCP}
          itemsPerPage={tablelistCP.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    getAffiliateRecordData()
  }, [])

  useEffect(() => {
    if (affiliateRecordData) {
      setAffiliateRecord(affiliateRecordData)
      if (!flag) {
        const { personId } = affiliateRecordData
        getDataPerson(personId)
      }
    }
  }, [affiliateRecordData])

  useEffect(() => {
    if (errorMessage) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.affiliateRecord) {
        getAffiliateRecordData()
        setErrorFlag({
          ...errorFlag,
          affiliateRecord: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (personError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: personError.message || `Error ${personError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.person) {
        const { personId } = affiliateRecordData
        getDataPerson(personId)
        setErrorFlag({
          ...errorFlag,
          person: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [personError])

  useEffect(() => {
    if (conPaymentsDataError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: conPaymentsDataError.message || `Error ${conPaymentsDataError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.conontributionPayments) {
        const bodyCP = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByDesc: true,
          orderByColumn: 'Id',
          affiliateRecordId: recordId
        }
        getContributionPayments(bodyCP)
        setErrorFlag({
          ...errorFlag,
          conontributionPayments: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [conPaymentsDataError])

  useEffect(() => {
    setPerson(personData)
    setBenefits(benefitsItems)
  }, [personData])

  useEffect(() => {
    if (person) {
      if (state) {
        const lastCrumb = {
          text: TRANSLATION_OF_ROUTES.AFFILIATE_RECORD,
          link: false,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.PEOPLE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.PEOPLE,
          link: true,
          url
        }
        url += `${SITE.PERSON}/${person?.id}`
        const secondCrumb = {
          text: `${person?.billingNumber} ${person?.fullName}`,
          link: false,
          url
        }
        url += `${SITE.AFFILIATE_RECORD}/${recordId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.AFFILIATE_RECORD} - ${recordId}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }

      return () => {
        setBreadcrumbs([])
      }
    }
  }, [person])

  useEffect(() => {
    if (contributions) {
      setTablelist(customDataTable(contributions))
      setColumns(columnData)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
      if (contributions.length > 0) setShowTable(true)
    }
  }, [contributions, totalPages])

  useEffect(() => {
    if (itemsError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: itemsError.message || `Error ${itemsError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.contributions) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value
        }
        getContributions(body)
        setErrorFlag({
          ...errorFlag,
          contributions: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [itemsError])

  useEffect(() => {
    if (filterObject) {
      const { parametersData, type } = filterObject
      if (parametersData?.length > 0) {
        setFilterData(parametersData)
        const parametersMap = parametersData.map(p => p.name)
        if (type === FILTER_TYPE.CONTRIBUTIONS) {
          setParameters(parametersMap)
          setShowFilter(!showFilter)
        } else {
          setParametersCP(parametersMap)
          setShowFilterCP(!showFilterCP)
        }
      }
    }
  }, [filterObject])

  useEffect(() => {
    if (filterObjectError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: filterObjectError.message || `Error ${filterObjectError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }
  }, [filterObjectError])

  useEffect(() => {
    if (benefitsItems) {
      setBenefits(benefitsItems)
    }
  }, [benefitsItems])

  useEffect(() => {
    if (conPaymentsData) {
      setTablelistCP(customDataTableCP(conPaymentsData.items))
      setColumnsCP(COLUMN_CONTRIBUTION_PAYMENTS)
      setPagesCP(conPaymentsData.totalPages)
      setItemSelectedCP(conPaymentsData.currentPage - 1)
      setTotalItemsCP(conPaymentsData.totalItems)
      if (conPaymentsData.items.length > 0) setShowTableCP(true)
    }
  }, [conPaymentsData])

  useEffect(() => {
    if (person) {
      if (!contributionLoading && !loadingBenefits && !conPayLoading) {
        if (loading) dispatch(AFFILIATE_RECORD_ACTIONS.DATA_UPLOAD_READY())
      }
    }
  }, [contributionLoading, loadingBenefits, person, conPayLoading])

  useEffect(() => {
    if (modalResponse) {
      if (modalResponse === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalResponse.message || `Error ${modalResponse.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [modalResponse])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WraperBreadcrumbs>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WraperBreadcrumbs>
              )}
            <>
              <WrapperHeading
                flex='2'
                device={device}
                columnStart={1}
                columnEnd={device < BREAKPOINTS.TABLET ? 12 : 9}
                rowStart={2}
                rowEnd={2}
              >
                <Heading
                  weight='regular'
                  size='large'
                  text={`${TRANSLATION_OF_ROUTES.AFFILIATE_RECORD} - ${affiliateRecord?.id}`}
                  type='h2'
                  align='left'
                />
                <MyLink
                  color='primary'
                  to={{
                    pathname: `${SITE.HISTORY}${pathname}`,
                    state: { breadcrumbs }
                  }}
                  textDecoration='underline'
                >
                  Historial de Actividad
                </MyLink>
              </WrapperHeading>
              <DataContainer device={device}>
                <Tbody device={device}>
                  {profileLoading
                    ? (
                      <WrapperLoading>
                        <Loading color='primary' size='xsmall' weight='bold' />
                      </WrapperLoading>
                    )
                    : affiliateRecord && renderAffiliateRecordData(affiliateRecord)}
                </Tbody>
                <LinkContainer device={device}>
                  <MyButtonLink onClick={handleEditProfile} color='primary' underline>Editar Registro</MyButtonLink>
                </LinkContainer>
              </DataContainer>
              <ButtonContainer
                justifyContent='flex-end'
                marginTop='24px'
                marginBottom='24px'
                columnStart={device < BREAKPOINTS.TABLET ? '1' : '9'}
                columnEnd='12'
                rowStart={device < BREAKPOINTS.TABLET ? '4' : '2'}
                rowEnd={device < BREAKPOINTS.TABLET ? '4' : '2'}
              >
                <GhostButton
                  color='primary'
                  icon='add'
                  size='small'
                  text='Agregar beneficio'
                  onClick={openAddBenefitModal}
                  reverse
                  block={device === BREAKPOINTS.MOBILE}
                />
              </ButtonContainer>
              <BenefitsContainer device={device}>
                <BenefitsTopContainer>
                  <Label name='notes' size='medium' align='left' text='Beneficios:' weight='bold' />
                </BenefitsTopContainer>
                <ListContainer>
                  {loadingBenefits
                    ? (
                      <WrapperLoading>
                        <Loading color='primary' size='xsmall' weight='bold' />
                      </WrapperLoading>
                    )
                    : benefits && renderBenefitsList(benefits)}
                </ListContainer>
              </BenefitsContainer>
              { showTable
              && (
                <>
                  <TextContainer device={device}>
                    <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTIONS}</Text>
                  </TextContainer>
                  <FilterContainer
                    columnStart={1}
                    columnEnd={12}
                    rowStart={device < BREAKPOINTS.TABLET ? '7' : '5'}
                    rowEnd={device < BREAKPOINTS.TABLET ? '7' : '5'}
                    deviceName={deviceName}
                  >
                    <FilterContainerLeftSide deviceName={deviceName}>
                      <GhostButton
                        color='tertiary'
                        size='small'
                        text='Agregar Filtro'
                        icon='filter_alt'
                        onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTIONS)}
                      />
                      {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                      {showFilter && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTIONS, filterData))}
                    </FilterContainerLeftSide>
                    <FilterContainerRightSide deviceName={deviceName}>
                      <SearchBar onChange={handleInputChange} disabled={!showTable} value={activeFilters?.filterText} />
                    </FilterContainerRightSide>
                  </FilterContainer>
                  <TableContainerAffRec device={device}>
                    {renderTable({
                      currentList: tablelist,
                      columnsN: columns,
                      currentLoading: contributionLoading,
                      handleOrder: handleOrderChange,
                      error: itemsError
                    })}
                  </TableContainerAffRec>
                </>
              )}
              {showTableCP
                && (
                  <>
                    <TextContainerCP device={device}>
                      <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTION_PAYMENTS}</Text>
                    </TextContainerCP>
                    <FilterContainer
                      columnStart={1}
                      columnEnd={12}
                      rowStart={device < BREAKPOINTS.TABLET ? '10' : '8'}
                      rowEnd={device < BREAKPOINTS.TABLET ? '10' : '8'}
                      deviceName={deviceName}
                    >
                      <FilterContainerLeftSide deviceName={deviceName}>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro'
                          icon='filter_alt'
                          onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTION_PAYMENTS)}
                        />
                        {badgesCP.length > 0 && <BadgeGroup badges={badgesCP} handleCloseBadge={handleCloseBadgeCP} />}
                        {showFilterCP
                          && filterData
                          && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTION_PAYMENTS, filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide deviceName={deviceName}>
                        <SearchBar
                          onChange={handleInputChangeCP}
                          disabled={!showTableCP}
                          value={activeFiltersCP?.filterText}
                          id={1}
                        />
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableContainerAffRecCP device={device}>
                      {renderTableCP({
                        currentList: tablelistCP,
                        columnsN: columnsCP,
                        currentLoading: conPayLoading,
                        handleOrder: handleOrderChangeCP,
                        error: itemsError
                      })}
                    </TableContainerAffRecCP>
                  </>
                )}
            </>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {addBenefitModal
              && (
              <AddBenefitsModal
                {... addBenefitModal}
              />
              )}
              {removeBenefitModal
              && (
                <DeleteConfirmationModal
                  {...removeBenefitModal}
                />
              )}
              {editProfileModal
              && (
                <EditAffiliateRecordProfile
                  {...editProfileModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default AffiliateRecord
