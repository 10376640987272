export const key = 'USERS'

export const USERS = {
  GET_STATUSES: 'USERS/GET_STATUSES',
  GET_STATUSES_SUCCESS: 'USERS/GET_STATUSES_SUCCESS',
  GET_STATUSES_ERROR: 'USERS/GET_STATUSES_ERROR',
  GET: 'USERS/GET_LIST',
  SUCCESS: 'USERS/GET_LIST/SUCCESS',
  USERS_ERROR: 'USERS/GET_LIST/ERROR',
  CLEAR: 'USERS/CLEAR_LIST',
  SEND_FILTER: 'USERS/FILTER/SEND',
  GET_FILTER_DATA: 'USERS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'USERS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'USERS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'USERS/FILTER/CLEAR',
  GET_MODAL_TYPES: 'USERS/MODAL/GET_MODAL_TYPES',
  INFORMATION_MODAL_TYPES: 'USERS/MODAL/INFORMATION_MODAL_TYPES',
  ADD_USER: 'USERS/ADD_USER',
  ADD_USER_INFORMATION: 'USERS/ADD_USER_INFORMATION'
}

export default key
