import styled, { keyframes, css } from 'styled-components'
import { spacing } from '@global/theme'

const showAnimation = keyframes`
    from {
      width: 0;
      visibility: initial;
    }
  
    to {
      width: 100%;
      visibility: initial;
    }
`

const hiddenAnimation = keyframes`
    from {
      width: 100%;
      visibility: initial;
    }
  
    to {
      width: 0;
      visibility: hidden;
    }
`

export const a1 = css`
  visibility: initial;
  animation: ${showAnimation} 0.5s ease;
`

export const a2 = css`
  animation: ${hiddenAnimation} .5s ease;
  > div span { visibility: hidden };
`

export const SearchBarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div {
    width: 100%;
    flex: 10;
    padding: 0;
    > :first-child {
      visibility: hidden;
    ${({ show }) => {
    if (show === null) return
    return show ? a1 : a2
  }};
    }

  }

  & > :last-child {
      margin-left: ${spacing.half};
      flex: 1;
      max-width: ${spacing.four};
  }
`
