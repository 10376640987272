import { DEPOSIT_PROFILE } from './types'

export const getDepositProfile = payload => ({
  type: DEPOSIT_PROFILE.GET_DEPOSIT_PROFILE,
  payload
})

export const getDepositProfileSuccess = payload => ({
  type: DEPOSIT_PROFILE.SUCCESS,
  payload
})

export const getDepositProfileError = payload => ({
  type: DEPOSIT_PROFILE.ERROR,
  payload
})

export const editDepositProfile = payload => ({
  type: DEPOSIT_PROFILE.EDIT_DEPOSIT_PROFILE,
  payload
})

export const editDepositProfileInformation = payload => ({
  type: DEPOSIT_PROFILE.EDIT_DEPOSIT_PROFILE_INFORMATION,
  payload
})

export const depositProfileProfileUpdated = payload => ({
  type: DEPOSIT_PROFILE.DEPOSIT_PROFILE_UPDATED,
  payload
})

export const getContributionsPayments = payload => ({
  type: DEPOSIT_PROFILE.GET_CONTRIBUTIONS_PAYMENTS,
  payload
})

export const getContributionsPaymentsSuccess = payload => ({
  type: DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_SUCCESS,
  payload
})

export const getContributionsPaymentsError = payload => ({
  type: DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_ERROR,
  payload
})

export const contributionsPaymentsUpdate = () => ({
  type: DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_UPDATE
})

export const clearDepositProfile = payload => ({
  type: DEPOSIT_PROFILE.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: DEPOSIT_PROFILE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: DEPOSIT_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: DEPOSIT_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: DEPOSIT_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: DEPOSIT_PROFILE.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: DEPOSIT_PROFILE.GET_MODAL_DATA,
  payload
})

export const modalDataInformation = payload => ({
  type: DEPOSIT_PROFILE.MODAL_DATA_INFORMATION,
  payload
})

export const addContributionPayment = payload => ({
  type: DEPOSIT_PROFILE.ADD_CONTRIBUTION_PAYMENT,
  payload
})

export const addContributionPaymentInformation = payload => ({
  type: DEPOSIT_PROFILE.ADD_CONTRIBUTION_PAYMENT_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: DEPOSIT_PROFILE.MESSAGE_INFORMATION_CLEAR
})

export const depositProfileGetContributions = payload => ({
  type: DEPOSIT_PROFILE.DEPOSIT_PROFILE_GET_CONTRIBUTION,
  payload
})

export const depositProfileContributionsInformation = payload => ({
  type: DEPOSIT_PROFILE.DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION,
  payload
})

export const currenciesInformation = payload => ({
  type: DEPOSIT_PROFILE.CURRENCIES_INFORMATION,
  payload
})

export const pendingProcessInfo = payload => ({
  type: DEPOSIT_PROFILE.PENDING_PROCESS_INFO,
  payload
})

export const getProcessPendingInfo = payload => ({
  type: DEPOSIT_PROFILE.GET_PENDING_PROCESS_INFO,
  payload
})

export const getPersonConceptsDP = payload => ({
  type: DEPOSIT_PROFILE.GET_PERSON_CONCEPTS,
  payload
})

export const personConceptDataInformation = payload => ({
  type: DEPOSIT_PROFILE.PERSON_CONCEPT_DATA_INFORMATION,
  payload
})

export const DEPOSIT_PROFILE_ACTIONS = {
  GET_DEPOSIT_PROFILE: getDepositProfile,
  SUCCESS: getDepositProfileSuccess,
  ERROR: getDepositProfileError,
  EDIT_DEPOSIT: editDepositProfile,
  EDIT_DEPOSIT_INFORMATION: editDepositProfileInformation,
  DEPOSIT_PROFILE_UPDATED: depositProfileProfileUpdated,

  GET_CONTRIBUTIONS_PAYMENTS: getContributionsPayments,
  CONTRIBUTIONS_PAYMENTS_SUCCESS: getContributionsPaymentsSuccess,
  CONTRIBUTIONS_PAYMENTS_ERROR: getContributionsPaymentsError,
  CONTRIBUTIONS_PAYMENTS_UPDATE: contributionsPaymentsUpdate,

  CLEAR: clearDepositProfile,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_INFORMATION: modalDataInformation,

  ADD_CONTRIBUTION_PAYMENT: addContributionPayment,
  ADD_CONTRIBUTIONS_PAYMENTS_INFORMATION: addContributionPaymentInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  DEPOSIT_PROFILE_GET_CONTRIBUTION: depositProfileGetContributions,
  DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION: depositProfileContributionsInformation,

  CURRENCIES_INFORMATION: currenciesInformation,

  PENDING_PROCESS_INFO: pendingProcessInfo,
  GET_PROCESS_PENDING_INFO: getProcessPendingInfo,

  GET_PERSON_CONCEPTS: getPersonConceptsDP,
  PERSON_CONCEPT_DATA_INFORMATION: personConceptDataInformation
}
