import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import CustomDatePicker from '@components/datePicker'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { CONTRIBUTION_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const EditContributionProfile = ({ oldData, saveData, close, currentToken }) => {
  useRedux()
  const dispatch = useDispatch()
  const { Logout } = useUser()
  const { device } = useWindowDimensions()

  const [expirationDate, setExpirationDate] = useState(new Date(oldData.dueDate))
  const [amount, setAmount] = useState({
    name: '',
    value: oldData.total,
    error: ''
  })
  const [options, setOptions] = useState()
  const [optionsSelected, setOptionsSelected] = useState()
  const [showButton, setShowButton] = useState(true)
  const [flag, setFlag] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [description, setDescription] = useState({
    name: '',
    value: oldData.description,
    error: ''
  })

  const contributionProfileModalData = useSelector(SELECTORS.CONTRIBUTION_PROFILE_MODAL_DATA)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleChange = val => {
    const { name } = val
    if (name === 'amount') setAmount(val)
    else setDescription(val)
  }

  const handleDropdownChange = e => setOptionsSelected(e.value)

  const clearStates = () => {
    setExpirationDate(null)
    setAmount({
      name: '',
      value: '',
      error: ''
    })
    setDescription({
      name: '',
      value: '',
      error: ''
    })
    setOptionsSelected(null)
  }

  const handleClick = () => {
    const contributionProfiledEdited = {
      dueDate: expirationDate.toISOString(),
      total: amount.value,
      statusId: optionsSelected.id,
      currencyId: 0,
      description: description.value
    }
    saveData(contributionProfiledEdited)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (flag) {
      if (optionsSelected) setShowButton(false)
      else setShowButton(true)
    }
  }

  const handleToastClose = () => setIsShowing(false)

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CONTRIBUTION_PROFILE_ACTIONS.GET_CONTRIBUTION_PROFILE_MODAL_DATA(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (contributionProfileModalData) {
      const { status } = contributionProfileModalData
      if (status === 200) {
        const { statuses } = contributionProfileModalData.objData
        setOptions(changeNameForLabel(statuses))
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: contributionProfileModalData.message || `Error ${contributionProfileModalData.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      }
    }
  }, [contributionProfileModalData])

  useEffect(() => {
    if (options) {
      setOptionsSelected(options.find(o => o.id === oldData.statusId))
      setFlag(true)
    }
  }, [options])

  useEffect(() => {
    errorControl()
  }, [optionsSelected])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <CustomDatePicker
            name='expirationDate'
            title='Fecha de vencimiento'
            color='primary'
            onChange={setExpirationDate}
            date={expirationDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label='Monto'
            placeholder='Ingrese monto'
            name='amount'
            value={amount.value}
            error={amount.error}
            height='small'
            color='primary'
            onChange={handleChange}
            onBlur={() => {}}
            bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
            min={0}
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Estado'
            options={options}
            value={optionsSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <TextInput
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            value={description.value}
            height='small'
            color='primary'
            onChange={handleChange}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
        </ModalDataContainer>
        <ButtonsContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={handleToastClose}
        />
      </>
      )}
    </>
  )
}

export default EditContributionProfile

EditContributionProfile.propTypes = {
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func,
  currentToken: PropTypes.object
}
