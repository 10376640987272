import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectHistoryRecord = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectHistoryRecord, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectHistoryRecord, state => get(state, 'historyRecord', null)
)

const selectError = createSelector(
  selectHistoryRecord, state => get(state, 'error', null)
)

const selectLoadingFile = createSelector(
  selectHistoryRecord, state => get(state, 'loadingFile', null)
)

const selectFileMessage = createSelector(
  selectHistoryRecord, state => get(state, 'fileMessage', null)
)

export default {
  SUCCESS: selectSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  FILE_MESSAGE: selectFileMessage,
  LOADING_FILE: selectLoadingFile
}
