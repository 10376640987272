import { SITE } from '@routes/paths'

export const navItems = [
  {
    label: 'Home',
    icon: 'home',
    to: SITE.HOME
  },
  {
    label: 'Personas',
    icon: 'group',
    to: SITE.PEOPLE
  },
  {
    label: 'Finanzas',
    icon: 'attach_money',
    to: SITE.FINANCE
  },
  {
    label: 'Proveedores',
    icon: 'manage_accounts',
    to: SITE.SERVICES
  },
  {
    label: 'Administración',
    icon: 'settings',
    to: SITE.SETTING
  }
]
