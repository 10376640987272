import produce from 'immer'

import { TYPES_PERSON_DATA } from '../data'
import { PERSON } from './types'

export const initialState = {
  loading: false,
  error: false,
  person: {},
  phones: [],
  addresses: [],
  notes: [],
  emails: [],
  bankAccounts: [],
  files: [],
  types: [],
  departments: [],
  banks: [],
  currencies: [],
  employmentRecord: null,
  frmRecord: null,
  activeAffiliateRecord: null,
  employmentRecordError: null,
  employeeRecords: {},
  loadingAddresses: false,
  loadingPhones: false,
  loadingNotes: false,
  loadingEmails: false,
  loadingBanksAccounts: false,
  loadingProfile: false,
  loadingTypes: false,
  loadingFile: false,
  loadingDepartments: false,
  informationMessage: null,
  errorDepartments: null,
  errorTypes: null,
  fileMessage: null,
  profileMessage: null,

  loadingEmploymentRecord: false,

  loadingAffiliateRecord: false,
  loadingAffiliateModalData: false,
  affiliateModalData: null,

  loadingFrmeRecord: false,

  personStatus: null,

  contributionPaymentsLoading: null,
  contributionPayments: null,
  contributionPaymentsError: null,
  inboundPaymentsOrigins: null,

  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PERSON.GET_PERSON:
      draft.loading = true
      break
    case PERSON.GET_PERSON_SUCCESS: {
      const { person, phones, addresses, notes, emails, bankAccounts, employeeRecords, files, personStatus } = payload
      draft.person = person
      draft.phones = phones
      draft.addresses = addresses
      draft.notes = notes
      draft.emails = emails
      draft.bankAccounts = bankAccounts
      draft.employeeRecords = employeeRecords
      draft.files = files
      draft.personStatus = personStatus
      draft.loading = false
      break
    }
    case PERSON.GET_PERSON_ERROR:
      draft.error = payload
      draft.loading = false
      break
    case PERSON.CLEAR:
      return initialState
    // ADDRESSES
    case PERSON.ADD_ADDRESS:
      draft.loadingAddresses = true
      break
    case PERSON.REMOVE_ADDRESS:
      draft.loadingAddresses = true
      break
    case PERSON.EDIT_ADDRESS:
      draft.loadingAddresses = true
      break
    case PERSON.UPDATE_ADDRESSES:
      draft.loadingAddresses = false
      draft.addresses = payload
      break
    // PHONES
    case PERSON.ADD_PHONE:
      draft.loadingPhones = true
      break
    case PERSON.REMOVE_PHONE:
      draft.loadingPhones = true
      break
    case PERSON.EDIT_PHONE:
      draft.loadingPhones = true
      break
    case PERSON.UPDATE_PHONES:
      draft.loadingPhones = false
      draft.phones = payload
      break
    // NOTES
    case PERSON.ADD_NOTE:
      draft.loadingNotes = true
      break
    case PERSON.REMOVE_NOTE:
      draft.loadingNotes = true
      break
    case PERSON.EDIT_NOTE:
      draft.loadingNotes = true
      break
    case PERSON.UPDATE_NOTES:
      draft.loadingNotes = false
      draft.notes = payload
      break
    // EMAILS
    case PERSON.ADD_EMAIL:
      draft.loadingEmails = true
      break
    case PERSON.REMOVE_EMAIL:
      draft.loadingEmails = true
      break
    case PERSON.EDIT_EMAIL:
      draft.loadingEmails = true
      break
    case PERSON.UPDATE_EMAILS:
      draft.loadingEmails = false
      draft.emails = payload
      break
    // BANKS ACCOUNTS
    case PERSON.ADD_BANK_ACCOUNT:
      draft.loadingBanksAccounts = true
      break
    case PERSON.REMOVE_BANK_ACCOUNT:
      draft.loadingBanksAccounts = true
      break
    case PERSON.EDIT_BANK_ACCOUNT:
      draft.loadingBanksAccounts = true
      break
    case PERSON.UPDATE_BANKS_ACCOUNTS:
      draft.loadingBanksAccounts = false
      draft.bankAccounts = payload
      break
    case PERSON.INFORMATION_MESSAGE: {
      const { error, dataType } = payload
      const { status, message } = error
      if (status >= 200 && status <= 299) draft.informationMessage = { status }
      else {
        draft.informationMessage = { status, message }
        switch (dataType) {
          case TYPES_PERSON_DATA.ADDRESS:
            draft.loadingAddresses = false
            break
          case TYPES_PERSON_DATA.PHONE:
            draft.loadingPhones = false
            break
          case TYPES_PERSON_DATA.EMAIL:
            draft.loadingEmails = false
            break
          case TYPES_PERSON_DATA.NOTE:
            draft.loadingNotes = false
            break
          case TYPES_PERSON_DATA.PROFILE:
            draft.loadingProfile = false
            break
          default:
            draft.loadingBanksAccounts = false
            break
        }
      }
      break
    }
    // TYPES
    case PERSON.GET_TYPES:
      draft.loadingTypes = true
      break
    case PERSON.GET_TYPES_SUCCESS:
      draft.loadingTypes = false
      draft.types = payload
      break
    case PERSON.GET_TYPES_ERROR:
      draft.errorTypes = payload
      draft.loadingTypes = false
      break
    // DEPARTMENTS
    case PERSON.GET_DEPARTMENTS:
      draft.loadingDepartments = true
      break
    case PERSON.GET_DEPARTMENTS_SUCCESS:
      draft.loadingDepartments = false
      draft.departments = payload
      break
    case PERSON.GET_DEPARTMENTS_ERROR:
      draft.loadingDepartments = payload
      draft.errorDepartments = false
      break
    // BANKS
    case PERSON.GET_PERSON_BANKS:
      draft.loadingBanks = true
      break
    case PERSON.BANKS_INFORMATION: {
      const { status, personBanks, currenciesData } = payload
      draft.loadingBanks = false
      draft.banks = { status, personBanks }
      draft.currencies = currenciesData
      break
    }
    // FILE
    case PERSON.UPLOAD_FILE:
      draft.loadingFile = true
      break
    case PERSON.DOWNLOAD_FILE:
      draft.loadingFile = true
      break
    case PERSON.REMOVE_FILE:
      draft.loadingFile = true
      break
    case PERSON.GET_FILE_SUCCESS:
      draft.loadingFile = false
      draft.files = payload
      break
    case PERSON.GET_FILE_ERROR:
      draft.loadingFile = false
      draft.informationMessage = payload
      break
    case PERSON.FILE_MESSAGE:
      draft.fileMessage = payload
      draft.loadingTypes = false
      draft.loadingFile = false
      break
    // PROFILE
    case PERSON.EDIT_PROFILE:
      draft.loadingProfile = true
      break
    case PERSON.UPDATE_PROFILE: {
      draft.loadingProfile = false
      draft.person = payload
      break
    }
    // EMPLOYMENT RECORD
    case PERSON.GET_EMPLOYMENT_RECORD:
      draft.loadingEmploymentRecord = true
      break
    case PERSON.EMPLOYMENT_RECORD:
      draft.loadingEmploymentRecord = false
      draft.employmentRecord = payload
      break
    case PERSON.CREATE_EMPLOYMENT_RECORD:
      draft.loadingEmploymentRecord = true
      break
    case PERSON.EMPLOYMENT_RECORD_INFORMATION: {
      draft.loadingEmploymentRecord = false
      draft.employmentRecord = payload
      draft.informationMessage = payload
      break
    }
    // ACTIVE AFFILIATE RECORD
    case PERSON.GET_ACTIVE_AFFILIATE_RECORD:
      draft.loadingAffiliateRecord = true
      break
    case PERSON.ACTIVE_AFFILIATE_RECORD:
      draft.loadingAffiliateRecord = false
      draft.activeAffiliateRecord = payload
      break
    case PERSON.GET_AFFILIATE_RECORD_MODAL_DATA:
      draft.loadingAffiliateModalData = true
      break
    case PERSON.AFFILIATE_RECORD_MODAL_DATA_INFORMATION:
      draft.loadingAffiliateModalData = false
      draft.affiliateModalData = payload
      break
    case PERSON.CREATE_AFFILIATE_RECORD:
      draft.loadingAffiliateRecord = true
      break
    case PERSON.AFFILIATE_RECORD_INFORMATION: {
      draft.loadingAffiliateRecord = false
      draft.activeAffiliateRecord = payload
      draft.informationMessage = payload
      break
    }
    // FRM RECORD
    case PERSON.GET_FRM_RECORD:
      draft.loadingFrmRecord = true
      break
    case PERSON.FRM_RECORD:
      draft.loadingFrmRecord = false
      draft.frmRecord = payload
      break
    case PERSON.CREATE_FRM_RECORD:
      draft.loadingFrmRecord = true
      break
    case PERSON.FRM_RECORD_INFORMATION: {
      draft.loadingFrmRecord = false
      draft.frmRecord = payload
      draft.informationMessage = payload
      break
    }
    case PERSON.CLEAR_INFORMATION_MESSAGE:
      draft.informationMessage = null
      break
      //  CONTRIBUTION PAYMENTS
    case PERSON.GET_CONTRIBUTION_PAYMENTS:
      draft.contributionPaymentsLoading = true
      break
    case PERSON.GET_CONTRIBUTION_PAYMENTS_SUCCESS: {
      const { contributionPaymentsData, originData } = payload
      draft.contributionPaymentsLoading = false
      draft.contributionPayments = contributionPaymentsData
      draft.inboundPaymentsOrigins = originData
      break
    }
    case PERSON.GET_CONTRIBUTION_PAYMENTS_ERROR:
      draft.contributionPaymentsLoading = false
      draft.contributionPaymentsError = payload
      break
    // FILTER
    case PERSON.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PERSON.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PERSON.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PERSON.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
