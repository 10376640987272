import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import Axios from '@axios/'
import { useSelector, useDispatch } from 'react-redux'
import { changeNameForLabel, currencyFormatUY, strMonthYear, TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS, MONTHS } from '@global/constants'
import { spacing } from '@global/theme'
import {
  ModalDataContainer,
  ButtonModalContainer,
  WrapperLoading
} from '@global/styles'
import { MESSAGES } from '@global/message'
import { INPUT_TYPES } from '@components/inputs/utils/constants'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput, CheckboxInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import CustomDatePicker from '@components/datePicker'
import { Text } from '@components/texts'
import Loading from '@components/loading'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import SelectInput from '@components/select'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { EXPORTS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { CheckboxContainer, MyLabel } from '../../styles'

const AddFileExportModal = ({ close, successfulData, jobTypes, currentToken }, ref) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const fullYear = new Date().getFullYear()
  const [selectedTypeOption, setSelectedTypeOption] = useState()
  const [settlementOptions, setSettlementOptions] = useState([])
  const [selectedSettlementOption, setSelectedSettlementOption] = useState()
  const [outboundPaymentOptions, setOutboundPaymentOptions] = useState()
  const [selectedOutboundPaymentOption, setSelectedOutboundPaymentOption] = useState()
  const [benefitsOptions, setBenefitsOptions] = useState()
  const [selectedBenefitOption, setSelectedBenefitOption] = useState([])
  const [showButton, setShowButton] = useState(true)
  const [checkedIncludeEmployment, setCheckedIncludeEmployment] = useState(false)
  const [checkedIncludeAffiliate, setCheckedIncludeAffiliate] = useState(false)
  const [checkedIncludeFrm, setCheckedIncludeFrm] = useState(false)
  const [checkedIncludeBenefit, setCheckedIncludeBenefit] = useState(false)
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())
  const [fileName, setFileName] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [showForm, setShowForm] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [startDate, setStartDate] = useState(new Date())
  const [optionSelectedMonth, setOptionSelectedMonth] = useState()
  const [optionSelectedConcept, setOptionSelectedConcept] = useState()
  const [year, setYear] = useState({
    value: `${fullYear}`,
    name: '',
    error: ''
  })
  const [concepts, setConcepts] = useState([])
  const [person, setPerson] = useState(null)

  const modalDataLoading = useSelector(SELECTORS.MODAL_DATA_LOADING)
  const modalData = useSelector(SELECTORS.MODAL_DATA)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const exportObj = useSelector(SELECTORS.EXPORT_SUCCESS)
  const outboundPaymentData = useSelector(SELECTORS.OUTBOUND_PAYMENT)
  const conceptsData = useSelector(SELECTORS.CONCEPTS_SUCCESS)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = typeId => {
    if (typeId !== 6 && typeId !== 7) {
      if ((fileName.value && !fileName.error) && dateFrom <= dateTo) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 6) {
      if ((fileName.value && !fileName.error)
          && selectedSettlementOption
          && (checkedIncludeAffiliate.value
              || checkedIncludeFrm.value
              || checkedIncludeBenefit.value
              || checkedIncludeEmployment.value)) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 7) {
      if ((fileName.value && !fileName.error) && selectedOutboundPaymentOption) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 8) {
      if ((fileName.value && !fileName.error) && selectedSettlementOption) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 9) setShowButton(false)

    if (typeId === 14) {
      if ((fileName.value && !fileName.error)
           && optionSelectedMonth
           && optionSelectedConcept
           && (year.value && !year.error)) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 22 || typeId === 23) {
      if ((fileName.value && !fileName.error) && person) setShowButton(false)
      else setShowButton(true)
    }

    if (typeId === 20) {
      if ((fileName.value && !fileName.error) && person && optionSelectedConcept) setShowButton(false)
      else setShowButton(true)
    }
  }

  const cleanData = () => {
    setSelectedSettlementOption(null)
    setSelectedBenefitOption(null)
    setSelectedOutboundPaymentOption(null)
    setCheckedIncludeEmployment(false)
    setCheckedIncludeAffiliate(false)
    setCheckedIncludeFrm(false)
    setCheckedIncludeBenefit(false)
    setFileName({
      name: '',
      value: '',
      error: ''
    })
    setSelectedTypeOption(null)
    setShowForm(false)
    setPerson(null)
  }

  const closeModalFromParent = () => {
    dispatch(EXPORTS_ACTIONS.CLEAR_DATA())
    cleanData()
  }

  useImperativeHandle(ref, () => ({
    clearValues: () => closeModalFromParent()
  }))

  const handleDropdownChange = val => {
    const { name } = val
    switch (name) {
      case 'types':
        cleanData()
        setSelectedTypeOption(val.value)
        break
      case 'settlement':
        setSelectedSettlementOption(val.value)
        break
      case 'payments':
        setSelectedOutboundPaymentOption(val.value)
        break
      case 'month':
        setOptionSelectedMonth(val.value)
        break
      case 'concepts':
        setOptionSelectedConcept(val.value)
        break
      default:
        setSelectedBenefitOption(val.value)
    }
  }

  const handleCheckboxChange = val => {
    const { name } = val
    switch (name) {
      case 'checkboxEmploymentRecord':
        setCheckedIncludeEmployment(val)
        break
      case 'checkboxAffiliate':
        setCheckedIncludeAffiliate(val)
        break
      case 'checkboxFrm':
        setCheckedIncludeFrm(val)
        break
      default:
        setCheckedIncludeBenefit(val)
    }
  }

  const handleInputChange = val => {
    const { error, name } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    if (name === 'fileName') setFileName(val)
    else setYear(val)
  }

  const customDropdownData = arrSettlement => arrSettlement.map(s => ({
    id: s.id,
    label: `LiqIng: ${s.id} / Fecha: ${s.month}/${s.year} / Total: ${currencyFormatUY(s.totalAmount) || 'N/A'}`
  }))

  const customDropdownOutboundPayment = arrOutboundPayments => arrOutboundPayments.map(op => ({
    id: op.id,
    label: `Pago ${op.id}: ${strMonthYear(op?.month, op?.year)} / 
                Divisa: ${op.currencyName} / Descripción: ${op.description || 'N/A'}`
  }))

  const handleClick = () => {
    let body = {
      fileName: fileName.value
    }
    switch (selectedTypeOption.id) {
      case 6:
        body = {
          ...body,
          settlementId: selectedSettlementOption?.id,
          benefitIds: selectedBenefitOption?.length > 0 && checkedIncludeBenefit.value
            ? selectedBenefitOption.map(b => b.id)
            : [],
          includeAffiliate: checkedIncludeAffiliate.value,
          includeFRM: checkedIncludeFrm.value,
          includeBenefit: checkedIncludeBenefit.value,
          includeEmployment: checkedIncludeEmployment.value
        }
        break
      case 7:
        body = {
          ...body,
          outboundPaymentId: selectedOutboundPaymentOption?.id
        }
        break
      case 8:
        body = {
          ...body,
          settlementId: selectedSettlementOption?.id,
          startDate
        }
        break
      case 9:
        body = {}
        break
      case 14:
        body = {
          ...body,
          month: optionSelectedMonth?.id + 1,
          year: year?.value,
          conceptId: optionSelectedConcept?.id
        }
        break
      case 20:
        body = {
          ...body,
          personId: person.value,
          conceptId: optionSelectedConcept.id
        }
        break
      case 22:
        body = {
          ...body,
          personId: person.value
        }
        break
      case 23:
        body = {
          ...body,
          personId: person.value
        }
        break
      case 24:
        break
      default:
        body = {
          ...body,
          dateFrom,
          dateTo
        }
        break
    }

    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EXPORTS_ACTIONS.CREATE_EXPORT({ typeId: selectedTypeOption.id, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSelectChange = value => {
    if (selectedTypeOption?.id === 20) setOptionSelectedConcept(null)
    setPerson(value)
  }

  const debounced = debounce((value, callback) => {
    const empty = []

    if (!value) {
      callback(empty)
      return
    }

    const data = {
      filterText: value,
      pageNumber: 1
    }

    const results = Axios.post('/Person/filter', data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(currentToken.token)
      }
    })

    results
      .then(res => {
        const options = res.data.items.map(p => ({
          value: p.id,
          label: p.fullName
        }))
        callback(options)
      })
      .catch(error => {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: error.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
        callback(empty)
      })
  }, 300)

  const renderForm = typeId => {
    let restForm = null
    const baseForm = (
      <>
        <Dropdown
          name='settlement'
          label='Liquidaciones de Ingreso'
          placeholder='Seleccione'
          options={settlementOptions}
          value={selectedSettlementOption}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        {settlementOptions?.length === 0
          && (
            <Text size='medium' weight='regular' align='left' color='error'>
              * No existen Liquidaciones de Ingreso aprobadas.
            </Text>
          )}
      </>
    )
    if (typeId === 6) {
      restForm = (
        <>
          {baseForm}
          <MyLabel>
            Incluir
          </MyLabel>
          <CheckboxContainer>
            <CheckboxInput
              label='Registro Empleo'
              name='checkboxEmploymentRecord'
              color='primary'
              value={checkedIncludeEmployment.value}
              onChange={handleCheckboxChange}
            />
            <CheckboxInput
              label='Afiliaciones'
              name='checkboxAffiliate'
              color='primary'
              value={checkedIncludeAffiliate.value}
              onChange={handleCheckboxChange}
            />
            <CheckboxInput
              label='FRMs'
              name='checkboxFrm'
              color='primary'
              value={checkedIncludeFrm.value}
              onChange={handleCheckboxChange}
            />
            <CheckboxInput
              label='Beneficios'
              name='checkboxBenefit'
              color='primary'
              value={checkedIncludeBenefit.value}
              onChange={handleCheckboxChange}
            />
          </CheckboxContainer>
          {checkedIncludeBenefit.value
         && (
         <Dropdown
           name='benefits'
           label='Beneficios'
           placeholder={benefitsOptions?.length > 0 ? 'Seleccione' : 'No existen datos'}
           options={benefitsOptions}
           value={selectedBenefitOption}
           onChange={handleDropdownChange}
           color='primary'
           height='small'
           block
           multiSelect
           disabled={benefitsOptions?.length === 0}
         />
         )}
        </>
      )
    }
    if (typeId === 7) {
      restForm = (
        <>
          <Dropdown
            name='payments'
            label='Pagos'
            placeholder='Seleccione'
            options={outboundPaymentOptions}
            value={selectedOutboundPaymentOption}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
            disabled={outboundPaymentOptions?.length === 0}
          />
        </>
      )
    }
    if (typeId === 8) {
      restForm = (
        <>
          <CustomDatePicker
            name='startDate'
            title='Fecha de inicio de cobro'
            color='primary'
            date={startDate}
            onChange={setStartDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
          {baseForm}
        </>
      )
    }
    // 21 - peg people list
    if (typeId === 9) {
      restForm = (
        <>
        </>
      )
    }
    if (typeId === 14) {
      restForm = (
        <>
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            name='year'
            label='Año'
            placeholder='Ingrese año'
            color='primary'
            value={year.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={year.error}
            bits={[{ icon: 'edit_calendar', position: 'left', colorBit: 'tertiary' }]}
          />
          <Dropdown
            name='month'
            label='Mes'
            placeholder='Seleccione'
            options={MONTHS}
            value={optionSelectedMonth}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='concepts'
            label='Conceptos'
            placeholder='Seleccione'
            options={concepts}
            value={optionSelectedConcept}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
        </>
      )
    }
    // 20 - contribution payments history
    if (typeId === 20) {
      restForm = (
        <>
          <SelectInput
            label='Persona'
            placeholder='Ingrese un nombre o número de cobro'
            value={person}
            loadOptions={debounced}
            onChange={handleSelectChange}
            color='primary'
            asyncSelect
            loadingMessage={() => <Text color='primary' weight='bold'>Cargando...</Text>}
            noOptionsMessage={() => <Text color='primary' weight='bold' align='center'>Sin opciones</Text>}
          />
          {modalDataLoading
         && (
         <WrapperLoading>
           <Loading color='primary' size='xsmall' weight='bold' />
         </WrapperLoading>
         )}
          {person && concepts && !modalDataLoading
           && (
           <Dropdown
             name='concepts'
             label='Conceptos'
             placeholder={concepts?.length === 0 ? 'Conceptos no encontrados' : 'Seleccione'}
             options={concepts}
             value={optionSelectedConcept}
             onChange={handleDropdownChange}
             color='primary'
             height='small'
             block
           />
           )}
        </>
      )
    }
    // 22 - person data
    // 23 - peg recipt
    if (typeId === 22 || typeId === 23) {
      restForm = (
        <>
          <SelectInput
            label='Persona'
            placeholder='Ingrese un nombre o número de cobro'
            value={person}
            loadOptions={debounced}
            onChange={handleSelectChange}
            color='primary'
            asyncSelect
            loadingMessage={() => <Text color='primary' weight='bold'>Cargando...</Text>}
            noOptionsMessage={() => <Text color='primary' weight='bold' align='center'>Sin opciones</Text>}
          />
        </>
      )
    }
    if (typeId === 4 || typeId === 5 || typeId === 18 || typeId === 21 || typeId === 25) {
      restForm = (
        <>
          <CustomDatePicker
            name='dateFrom'
            title='Fecha desde'
            color='primary'
            date={dateFrom}
            onChange={setDateFrom}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
          <CustomDatePicker
            name='dateTo'
            title='Fecha hasta'
            color='primary'
            date={dateTo}
            onChange={setDateTo}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
        </>
      )
    }

    return (
      <>
        {selectedTypeOption?.id !== 9
         && (
         <TextInput
           name='fileName'
           label='Nombre de archivo'
           placeholder='Ingrese nombre de archivo'
           value={fileName.value}
           height='small'
           color='primary'
           onChange={handleInputChange}
           bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
         />
         )}
        {restForm}
      </>
    )
  }

  useEffect(() => {
    if (selectedTypeOption) {
      // 6 - export contributions
      // 7 - frm transfers
      // 8 - redpagos/banred
      // 14 - contribution debt
      if (selectedTypeOption.id !== 6
          && selectedTypeOption.id !== 7
          && selectedTypeOption.id !== 8
          && selectedTypeOption.id !== 14) {
        setShowForm(true)
      } else {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          switch (selectedTypeOption.id) {
            case 7: {
              const data = {
                statuses: [1],
                orderByColumn: 'Id',
                orderByDesc: true,
                pageNumber: 1
              }
              dispatch(EXPORTS_ACTIONS.GET_PAYMENTS_EXPORTS({ data, token }))
              break
            }
            case 14: {
              dispatch(EXPORTS_ACTIONS.GET_CONCEPTS({ token }))
              break
            }
            default: {
              const dataSettlement = {
                statuses: [1],
                pageNumber: 1,
                orderByColumn: 'Id',
                orderByDesc: true
              }
              const dataBenefits = {
                pageNumber: 1
              }
              dispatch(EXPORTS_ACTIONS.GET_MODAL_DATA(
                { type: selectedTypeOption.id, dataSettlement, dataBenefits, token }
              ))
              break
            }
          }
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      }
    }
  }, [selectedTypeOption])

  useEffect(() => {
    if (modalData) {
      const { settlementData, benefitsData } = modalData
      setSettlementOptions(customDropdownData(settlementData))
      setBenefitsOptions(changeNameForLabel(benefitsData))
      setShowForm(true)
    }
  }, [modalData])

  useEffect(() => {
    if (outboundPaymentData) {
      setOutboundPaymentOptions(customDropdownOutboundPayment(outboundPaymentData))
      setShowForm(true)
    }
  }, [outboundPaymentData])

  useEffect(() => {
    if (conceptsData) {
      setConcepts(changeNameForLabel(conceptsData))
      setShowForm(true)
    }
  }, [conceptsData])

  useEffect(() => {
    if (selectedTypeOption) {
      errorControl(selectedTypeOption?.id)
    }
  }, [
    fileName,
    selectedSettlementOption,
    selectedBenefitOption,
    dateFrom,
    dateTo,
    selectedOutboundPaymentOption,
    checkedIncludeAffiliate,
    checkedIncludeFrm,
    checkedIncludeBenefit,
    checkedIncludeEmployment,
    optionSelectedMonth,
    year,
    optionSelectedConcept,
    person
  ])

  useEffect(() => {
    if (person && selectedTypeOption.id === 20) {
      const { token, tokenCreationDate } = currentToken
      const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
      if (isValid) {
        dispatch(EXPORTS_ACTIONS.GET_CONCEPTS({ token, personId: person.value }))
      } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    }
  }, [person])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        dispatch(EXPORTS_ACTIONS.CLEAR_MESSAGE_INFORMATION())
        setTimeout(() => {
          setMessageToast({
            title: '',
            message: '',
            icon: ''
          })
        }, 5000)
      }
      setIsShowing(true)
    }
  }, [messageInformation])

  useEffect(() => {
    if (exportObj) {
      successfulData(exportObj)
      cleanData()
      close()
    }
  }, [exportObj])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column' marginFirstChild>
        <Dropdown
          name='types'
          label='Tipos de Exportación'
          placeholder='Seleccione'
          options={changeNameForLabel(jobTypes)}
          value={selectedTypeOption}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        {modalDataLoading && selectedTypeOption?.id !== 20
         && (
         <WrapperLoading>
           <Loading color='primary' size='xsmall' weight='bold' />
         </WrapperLoading>
         )}
        {showForm
         && renderForm(selectedTypeOption?.id)}
      </ModalDataContainer>
      {selectedTypeOption?.id !== 6 && (dateFrom > dateTo)
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La FECHA DESDE no puede ser posterior a la FECHA HASTA.
           </Text>
           )}
      <ButtonModalContainer
        marginTop={selectedTypeOption?.id !== 6 && (dateFrom > dateTo) ? spacing.two : spacing.one}
        oneButton
      >
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonModalContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default forwardRef(AddFileExportModal)

AddFileExportModal.propTypes = {
  close: PropTypes.func,
  successfulData: PropTypes.func,
  jobTypes: PropTypes.array,
  currentToken: PropTypes.object
}
