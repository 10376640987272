import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'

import { ModalDataContainer, ButtonContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'

import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PERSON_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const CreateAffiliateRecord = ({ close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [categories, setCategories] = useState()
  const [selectedOptionCategory, setSelectedOptionCategory] = useState()

  const [startDate, setStartDate] = useState(new Date())

  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const modalData = useSelector(SELECTORS.AFFILIATE_RECORD_MODAL_DATA_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if (selectedOptionCategory) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setStartDate(null)
    setSelectedOptionCategory(null)
  }

  const handleDropdownChange = e => setSelectedOptionCategory(e.value)

  const handleClick = () => {
    const newAffiliateRecord = {
      startDate,
      endDate: null,
      statusId: 1,
      categoryId: selectedOptionCategory.id
    }
    saveData(newAffiliateRecord)
    cleanFields()
    close()
  }

  useEffect(() => {
    if (currentToken) dispatch(PERSON_ACTIONS.GET_AFFILIATE_RECORD_MODAL_DATA(currentToken.token))
  }, [])

  useEffect(() => {
    if (modalData) {
      const { status } = modalData
      if (status === 200) {
        const { categoriesData } = modalData
        setCategories(changeNameForLabel(categoriesData))
        setFlag(true)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalData.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [modalData])

  useEffect(() => {
    errorControl()
  }, [selectedOptionCategory, startDate])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
     && (
     <>
       <ModalDataContainer flexDirection='column'>
         <Dropdown
           name='category'
           label='Categoría'
           placeholder='Seleccione'
           options={categories}
           value={selectedOptionCategory}
           onChange={handleDropdownChange}
           color='primary'
           height='small'
           block
         />
         <CustomDatePicker
           name='expirationDate'
           title='Fecha de ingreso'
           color='primary'
           onChange={setStartDate}
           yearsToShow={100}
           justifyContentButton='space-between'
           block
         />
       </ModalDataContainer>
       <ButtonContainer justifyContent='flex-end' modal>
         <SolidButton
           size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
           color='primary'
           text='Guardar'
           onClick={handleClick}
           disabled={showButton}
           block={device === BREAKPOINTS.MOBILE}
         />
       </ButtonContainer>
       <Toast
         title={messageToast.title}
         message={messageToast.message}
         color={messageToast.color}
         icon={messageToast.icon}
         isShowing={isShowing}
         position={TOAST_POSITION.rightTop}
         onClick={() => setIsShowing(false)}
       />
     </>
     )}
    </>
  )
}

export default CreateAffiliateRecord

CreateAffiliateRecord.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.string
}
