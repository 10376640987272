import { CONTRIBUTION_PROFILE } from './types'

export const getContributionProfile = payload => ({
  type: CONTRIBUTION_PROFILE.GET_CONTRIBUTION_PROFILE,
  payload
})

export const getContributionProfileSuccess = payload => ({
  type: CONTRIBUTION_PROFILE.SUCCESS,
  payload
})

export const getContributionProfileError = payload => ({
  type: CONTRIBUTION_PROFILE.ERROR,
  payload
})

export const editContributionProfile = payload => ({
  type: CONTRIBUTION_PROFILE.EDIT_CONTRIBUTION_PROFILE,
  payload
})

export const editContributionProfileInformation = payload => ({
  type: CONTRIBUTION_PROFILE.EDIT_CONTRIBUTION_PROFILE_INFORMATION,
  payload
})

export const contributionProfileUpdated = payload => ({
  type: CONTRIBUTION_PROFILE.CONTRIBUTION_PROFILE_UPDATED,
  payload
})

export const getContributionProfileModalData = payload => ({
  type: CONTRIBUTION_PROFILE.GET_CONTRIBUTION_PROFILE_MODAL_DATA,
  payload
})

export const ContributionProfileModalDataInformation = payload => ({
  type: CONTRIBUTION_PROFILE.CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION,
  payload
})

export const CONTRIBUTION_PROFILE_ACTIONS = {
  GET_CONTRIBUTION_PROFILE: getContributionProfile,
  SUCCESS: getContributionProfileSuccess,
  ERROR: getContributionProfileError,

  EDIT_CONTRIBUTION_PROFILE: editContributionProfile,
  EDIT_CONTRIBUTION_PROFILE_INFORMATION: editContributionProfileInformation,
  CONTRIBUTION_PROFILE_UPDATED: contributionProfileUpdated,

  GET_CONTRIBUTION_PROFILE_MODAL_DATA: getContributionProfileModalData,
  CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION: ContributionProfileModalDataInformation
}
