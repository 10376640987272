import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { TOAST_POSITION, TRANSLATION_OF_ROUTES, TOAST_PROPERTIES } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  WrapperHeading,
  ItemLink
} from '@global/styles'
import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import Toast from '@components/toast'
import { Heading } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { CURRENCIES_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapperCurrencies } from './styles'

const Currencies = () => {
  useRedux()
  const { pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const items = useSelector(SELECTORS.SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const sendToCurrencyProfile = currencyId => {
    if (currencyId) {
      history.push({
        pathname: `${pathname}${SITE.CURRENCY_PROFILE}/${currencyId}`,
        state: { breadcrumbs }
      })
    }
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const link = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => sendToCurrencyProfile(item.id)}
        >
          Ver datos
        </ItemLink>
      )
      const newItem = {
        code: item.id,
        name: item.name,
        symbol: item.symbol,
        link
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = () => {}

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CURRENCIES_ACTIONS.GET_CURRENCIES(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)

    const fisrtCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.SETTING,
      url: SITE.SETTING
    }
    const secondCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.ENTITY_MANAGMENT,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}`
    }
    const thirdCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.CURRENCIES,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.CURRENCIES}`
    }
    setBreadcrumbs([fisrtCrumb, secondCrumb, thirdCrumb])

    return () => {
      setBreadcrumbs([])
    }
  }, [])

  useEffect(() => {
    if (items) {
      setColumns(columnData)
      setTablelist(customBodyDataTable(items))
    }
  }, [items])

  useEffect(() => {
    if (errorMessage) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(CURRENCIES_ACTIONS.GET_CURRENCIES(token))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' marginBottom={spacing.twoAndAHalf}>
              <Heading
                type='h2'
                weight='bold'
                align='left'
                color='system'
              >
                {TRANSLATION_OF_ROUTES.CURRENCIES}
              </Heading>
            </WrapperHeading>
            <TableWrapperCurrencies>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loading,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapperCurrencies>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </>
        )}
    </>
  )
}

export default Currencies
