import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'

import { SolidButton, GhostButton } from '@components/button'
import { useWindowDimensions } from '@components/windowDimensions'

import { Text } from '@components/texts'
import { ModalDataContainer, ButtonContainer } from './styles'

const DeleteConfirmationModal = ({ close, text, itemId, removeItem, dataType }) => {
  const { device } = useWindowDimensions()
  const handleCancelClick = () => {
    close(false)
  }
  const handleClick = () => {
    close(false)
    removeItem(itemId, dataType)
  }

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <Text size='large' weight='bold' align='left'>
          {text}
        </Text>
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' device={device}>
        <GhostButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Cancelar'
          onClick={handleCancelClick}
          block={device === BREAKPOINTS.MOBILE}
        />
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Borrar'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
    </>

  )
}

export default DeleteConfirmationModal

DeleteConfirmationModal.propTypes = {
  close: PropTypes.func,
  text: PropTypes.string,
  itemId: PropTypes.number,
  removeItem: PropTypes.func,
  dataType: PropTypes.string
}
