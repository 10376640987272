import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { semibold } from '@global/fonts'

export const Wrapper = styled.div`
  position: relative;
  margin: ${spacing.one} 0;
  > p, span {
    color: ${colors.system.regular};
  }

  > button {
    > p, span {
      color: ${({ type }) => (type === 'solid' ? colors.background.regular : colors.system.regular)} !important;
      ${semibold};
    }

    width: ${({ block }) => (block ? '100%' : 'min-content')};
    height: ${({ height }) => {
    switch (height) {
      case 'small':
        return spacing.threeAndAHalf
      case 'large':
        return spacing.fiveAndAHalf
      default:
        return spacing.five
    }
  }};
    justify-content: space-between;
    min-width: ${({ columnHeader }) => (columnHeader ? '178px' : '144px')};
    border: 1px solid ${({ disabled }) => (disabled ? colors.system.disabled : colors.contrast.disabled)};

    &:focus {
        border: 2px solid ${({ color }) => colors[color]?.focus};
        outline: none;
        background-color: ${({ type, color }) => {
    if (type === 'solid') return colors[color]?.focus
    return colors.background.regular
  }};
    }

    &:hover {
        border: ${({ disabled, color }) => {
    if (disabled) return 'none'
    if (color) return `2px solid ${colors[color]?.hover}`
  }};
    }

    &:active {
        border: ${({ disabled, color }) => {
    if (disabled) return 'none'
    if (color) return `2px solid ${colors[color]?.selected}`
  }};
    }
  }
`

export const Options = styled.ul`
  height: min-content;
  max-height: 150px;
  overflow-y: auto;
  padding: 0px;
  position: absolute;
  top: ${({ overflowing }) => (overflowing ? 'auto' : '100%')};
  bottom: ${({ overflowing }) => (overflowing ? '100%' : 'auto')};
  margin: ${spacing.one} 0;
  z-index: 10;
  width: ${({ block, columnHeader }) => {
    if (block) return '100%'
    if (columnHeader) return '178px'
    return '144px'
  }};
  background-color: ${colors.background.regular};
  box-shadow:  4px 4px 4px -1px rgb(0 0 0 / 0.25);
  display: ${({ open }) => (open ? 'block' : 'none')};
`

export const Group = styled.li`
  padding-left: ${spacing.two};
  padding-right: ${spacing.two};
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};

  display: flex;
  justify-content: space-between;

  background-color: ${colors.background.regular};
`

export const Option = styled.li`
  padding-left: ${spacing.two};
  padding-right: ${spacing.two};
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};

  display: flex;
  justify-content: space-between;

  cursor: pointer;

  &:hover {
    background-color: ${colors.system.darker};
    > p, span {
      color: ${({ color }) => (color ? colors[color]?.regular : colors.system.regular)};
    }
  }

  > span {
   color: ${({ selected, columnHeader, color }) => (
    (selected && !columnHeader) ? colors[color]?.regular : colors.system.regular
  )
}
};
  > p {
   color: ${({ selected, columnHeader }) => (selected && !columnHeader && colors.primary.regular)};
  }

  &::marker {
    color: white;
  }
`
