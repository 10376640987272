import produce from 'immer'

import { PAYMENT_SETTLEMENTS } from './types'

export const initialState = {
  loading: true,
  error: false,
  paymentSettlementsLoading: false,
  paymentSettlementsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  paymentSettlementsStatuses: null,
  currencies: null,
  messageInformation: null,
  loadingStatuses: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PAYMENT_SETTLEMENTS.GET:
      draft.paymentSettlementsLoading = true
      break
    case PAYMENT_SETTLEMENTS.SUCCESS: {
      const { paymentSettlements, statuses, currencies } = payload
      const { items, fields, totalItems, totalPages, currentPage } = paymentSettlements
      draft.loading = false
      draft.paymentSettlementsLoading = false
      draft.paymentSettlementsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.paymentSettlementsStatuses = statuses
      draft.currencies = currencies
    }
      break
    case PAYMENT_SETTLEMENTS.ERROR:
      draft.loading = false
      draft.paymentSettlementsLoading = false
      draft.error = payload
      break
    case PAYMENT_SETTLEMENTS.CLEAR:
      return initialState
    // FILTER
    case PAYMENT_SETTLEMENTS.SEND_FILTER:
      draft.loading = true
      break
    case PAYMENT_SETTLEMENTS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PAYMENT_SETTLEMENTS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PAYMENT_SETTLEMENTS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PAYMENT_SETTLEMENTS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case PAYMENT_SETTLEMENTS.GET_PAYMENT_SETTLEMENTS_STATUSES:
      draft.loadingStatuses = true
      break
    case PAYMENT_SETTLEMENTS.PAYMENT_SETTLEMENTS_STATUSES_INFORMATION:
      draft.paymentSettlementsStatuses = payload
      draft.loadingStatuses = false
      break
    case PAYMENT_SETTLEMENTS.ADD_PAYMENT_SETTLEMENT:
      draft.paymentSettlementsLoading = true
      break
    case PAYMENT_SETTLEMENTS.ADD_PAYMENT_SETTLEMENT_INFORMATION:
      draft.messageInformation = payload
      draft.paymentSettlementsLoading = false
      break
    case PAYMENT_SETTLEMENTS.CLEAR_MESSAGE_INFORMATION:
      draft.messageInformation = null
      break
    case PAYMENT_SETTLEMENTS.PAYMENT_SETTLEMENTS_UPDATED: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.paymentSettlementsLoading = false
      draft.paymentSettlementsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    default:
      return initialState
  }
})

export default reducer
