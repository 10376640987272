import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, large, bold } from '@global/fonts'

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Wrapper = styled.div`
 ${roboto};
  height: ${({ height }) => height || '200px'}px;
  width: ${({ width }) => width || '200px'}px;
  background-color: transparent;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: ${spacing.one};
  display: grid;
  place-items: center;
`

export const CircularProgress = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;

  ${({ color, progressValue }) => (
    `background: conic-gradient(
        ${colors[color]?.regular} ${progressValue * 3.6}deg,
        ${colors.grays.regular} ${progressValue * 3.6}deg)`
  )};
  border-radius: 50%;
  display: grid;
  place-items: center;

  &::before {
    content: "";
    position: absolute;
    height: 80%;
    width: 80%;
    background-color: ${colors.background.regular};
    border-radius: 50%;
  }
`

export const ValueContainer = styled.div`
  position: relative;
  & > p {
    ${bold};
    ${large};
  }
`
