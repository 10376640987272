import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { colors, spacing } from '@global/theme'
import { semibold, roboto } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const StyledNavLink = styled(NavLink)`
  width: auto;
  text-decoration: none;
  color: ${colors.primary.regular};
  ${semibold};
  ${roboto};
  
  &:hover {
    color: ${colors.primary.hover};
  }
`

export const Flex = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Child = css`
  > :first-child {
    margin-right: ${spacing.one};
  }
`

export const Wrapper = styled.div`
  height: ${({ device, mobileHeight, desktopHeight }) => {
    if (device === BREAKPOINTS.MOBILE) return mobileHeight
    return desktopHeight
  }};

  min-width: ${({ device, mobileWidth, desktopWidth }) => (device === BREAKPOINTS.MOBILE ? mobileWidth : desktopWidth)};
  max-width: ${({ device, mobileWidth, desktopWidth }) => (device === BREAKPOINTS.MOBILE ? mobileWidth : desktopWidth)};
  box-shadow: 0px 5px 33px -21px ${colors.system.dark};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${colors.background.regular};

  padding: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return `${spacing.one} ${spacing.two}`
    return `${spacing.two} ${spacing.two}`
  }};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 1;
`

export const Header = styled.div`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
  ${Flex}
  justify-content: center;
  flex: 1;

  > img {
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.half : spacing.two)};
  }
`
export const Body = styled.div`
  width: 100%;
  flex: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '2' : '3')};
  ${Flex}
  justify-content: ${({ justifyContent }) => justifyContent ?? 'space-around'};
  padding: ${({ device, paddingLefRig, paddingTopBot }) => {
    if (device === BREAKPOINTS.MOBILE) return 0
    return `${paddingTopBot} ${paddingLefRig}`
  }};

  > button {
      margin-top: ${spacing.two};
      margin-bottom: ${spacing.oneAndAHalf};
      width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : '40%')};  
  }
`
export const Footer = styled.div`
  height: 100%;
  width: 100%;

  ${Flex}
  justify-content: space-evenly;

  flex: 1;
`

export const Img = styled.img`
    width: auto;
    height: auto;
`

export const WrapperRegister = styled.div`
  display: flex;
  align-items: center;
  ${Child}
`
export const LoadingContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
`

export const Container = styled.div`
  grid-column-start: 1;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
