import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { rowsPerPage, TOAST_POSITION, TRANSLATION_OF_ROUTES, TOAST_PROPERTIES } from '@global/constants'
import {
  TOKEN_HELPERS,
  sortByKey,
  handleClickPaginationItem,
  filterOrderBuilder
} from '@utils/helpers'
import {
  WrapperLoading,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperHeading
} from '@global/styles'
import { MESSAGES } from '@global/message'
import { SITE } from '@routes/paths'

import Loading from '@components/loading'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import { Heading } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import Modal from '@components/modal'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { SERVICE_PROVIDER_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapper, PaginationWrapper } from './styles'
import AddProviderModal from './components/addProviderModal'

const ServiceProvider = () => {
  useRedux()
  const { key } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [activeFilters, setActiveFilters] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [addProviderModal, setAddProviderModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const errorMessage = useSelector(SELECTORS.ERROR)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getServiceProvider = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_ACTIONS.GET_SERVICE_PROVIDER({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      return col.name
    })

    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }
    return customColumns
  }

  const handleRowClick = row => {
    const { id } = row
    history.push(`${SITE.SERVICES}${SITE.SERVICE_PROVIDER_PROFILE}/${id}`)
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const { bankAccounts } = item
      let bankAccount = 'N/A'
      if (bankAccounts.length > 0) {
        const primaryBankAccount = bankAccounts.find(ba => ba.isPrimary)
        bankAccount = primaryBankAccount
          ? `${primaryBankAccount?.bankName} - ${primaryBankAccount?.accountNumber}`
          : 'N/A'
      }

      const newItem = {
        id: item.id,
        code: item.id,
        name: item.name,
        description: item.description,
        bankAccount
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames.toLowerCase(),
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getServiceProvider(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = activeFilters
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getServiceProvider(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleInputChange = e => {
    let body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByDesc: false
    }
    if (e.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    } else delete activeFilters.filterText
    getServiceProvider(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const successfulResult = () => {
    setMessageToast({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message: MESSAGES.GENERIC_SUCCESSFUL_CREATION,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color
    })
    setIsShowing(true)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setAddProviderModal(null)
  }

  const handleAddProvider = () => {
    setAddProviderModal({
      close: handleCloseModal,
      successfulResult,
      currentToken
    })
    setOpen(true)
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer
      />
    )
  }

  useEffect(() => {
    const body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'year',
      orderByDesc: true
    }
    getServiceProvider(body)
  }, [key])

  useEffect(() => {
    setColumns(customHeaderDataTable(fields, columnData))
    setTablelist(customBodyDataTable(items))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
  }, [items, totalPages])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'year',
          orderByDesc: true
        }
        getServiceProvider(body)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {TRANSLATION_OF_ROUTES.SERVICES}
              </Heading>
            </WrapperHeading>
            <FilterContainer columnStart={1} columnEnd={12} rowStart={3} rowEnd={3} deviceName={deviceName}>
              <FilterContainerLeftSide />
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Proveedor +'
                  onClick={handleAddProvider}
                />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapper rowStart='4' rowEnd='4' columnStart='1' columnEnd='12'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                handleSelectedRow: handleRowClick,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapper>
            <PaginationWrapper rowStart='5' rowEnd='5' columnStart='1' columnEnd='12'>
              {tablelist.length > 0
              && (
              <PaginationGroup
                deviceName={deviceName}
                pages={pages}
                itemSelected={itemSelected}
                handleClickPaginationItem={n => handleClickPaginationItem(
                  n, activeFilters, selectedOption.value, getServiceProvider, setItemSelected
                )}
                options={rowsPerPage}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
                totalItems={totalItems}
                itemsPerPage={tablelist.length}
              />
              )}
            </PaginationWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Agregar Proveedor'
              minHeight='auto'
            >
              {addProviderModal
              && (
                <AddProviderModal
                  {...addProviderModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default ServiceProvider
