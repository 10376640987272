import { PEOPLE } from './types'

export const getPeople = payload => ({
  type: PEOPLE.GET,
  payload
})

export const getPeopleSuccess = payload => ({
  type: PEOPLE.SUCCESS,
  payload
})

export const getPeopleError = payload => ({
  type: PEOPLE.ERROR,
  payload
})
export const ClearPeopleList = payload => ({
  type: PEOPLE.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: PEOPLE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: PEOPLE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PEOPLE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PEOPLE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PEOPLE.FILTER_DATA_CLEAR
})

export const getModalTypes = payload => ({
  type: PEOPLE.GET_MODAL_TYPES,
  payload
})

export const informationModalTypes = payload => ({
  type: PEOPLE.INFORMATION_MODAL_TYPES,
  payload
})

export const createPerson = payload => ({
  type: PEOPLE.CREATE_PERSON,
  payload
})

export const createPersonInformation = payload => ({
  type: PEOPLE.CREATE_PERSON_INFORMATION,
  payload
})

export const clearPersonInformation = payload => ({
  type: PEOPLE.CLEAR_PERSON_INFORMATION,
  payload
})

export const personStatus = payload => ({
  type: PEOPLE.PERSON_STATUS,
  payload
})

export const PEOPLE_ACTIONS = {
  GET: getPeople,
  SUCCESS: getPeopleSuccess,
  ERROR: getPeopleError,
  CLEAR: ClearPeopleList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  GET_MODAL_TYPES: getModalTypes,
  INFORMATION_MODAL_TYPES: informationModalTypes,
  CREATE_PERSON: createPerson,
  CREATE_PERSON_INFORMATION: createPersonInformation,
  CLEAR_PERSON_INFORMATION: clearPersonInformation,
  PERSON_STATUS: personStatus
}
