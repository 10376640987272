import { EMPLOYMENT_RECORD_PROFILE } from './types'

export const getEmploymentRecordProfile = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_EMPLOYMENT_RECORD_PROFILE,
  payload
})

export const getEmploymentRecordProfileSuccess = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.SUCCESS,
  payload
})

export const employmentRecordProfileError = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.ERROR,
  payload
})

export const editEmploymentRecordProfile = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.EDIT_EMPLOYMENT_RECORD_PROFILE,
  payload
})

export const editEmploymentRecordProfileInformation = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION,
  payload
})

export const liquidationLogProfileUpdated = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.EMPLOYMENT_RECORD_PROFILE_UPDATED,
  payload
})

export const getEmploymentRecordProfileModalData = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_EMPLOYMENT_RECORD_PROFILE_MODAL_DATA,
  payload
})

export const EmploymentRecordProfileModalDataInformation = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION,
  payload
})

export const getContributionsEmploymentRecord = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTIONS_EMPLOYMENT_RECORD,
  payload
})

export const contributionsEmploymentRecordSuccess = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.CONTRIBUTIONS_EMPLOYMENT_RECORD_SUCCESS,
  payload
})

export const contributionsEmploymentRecordError = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.CONTRIBUTIONS_EMPLOYMENT_RECORD_ERROR,
  payload
})

export const currenciesInformation = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.CURRENCIES_INFORMATION,
  payload
})

export const getFilterData = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_CLEAR
})

export const getContributionPayments = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS,
  payload
})

export const getContributionPaymentsSuccess = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS_SUCCESS,
  payload
})

export const getContributionPaymentsError = payload => ({
  type: EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS_ERROR,
  payload
})

export const dataUploadReady = () => ({
  type: EMPLOYMENT_RECORD_PROFILE.DATA_UPLOAD_READY
})

export const EMPLOYMENT_RECORD_PROFILE_ACTIONS = {
  GET_EMPLOYMENT_RECORD_PROFILE: getEmploymentRecordProfile,
  SUCCESS: getEmploymentRecordProfileSuccess,
  ERROR: employmentRecordProfileError,

  EDIT_EMPLOYMENT_RECORD_PROFILE: editEmploymentRecordProfile,
  EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION: editEmploymentRecordProfileInformation,
  EMPLOYMENT_RECORD_PROFILE_UPDATED: liquidationLogProfileUpdated,

  GET_EMPLOYMENT_RECORD_PROFILE_MODAL_DATA: getEmploymentRecordProfileModalData,
  EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION: EmploymentRecordProfileModalDataInformation,

  GET_CONTRIBUTIONS_EMPLOYMENT_RECORD: getContributionsEmploymentRecord,
  CONTRIBUTIONS_EMPLOYMENT_RECORD_SUCCESS: contributionsEmploymentRecordSuccess,
  CONTRIBUTIONS_EMPLOYMENT_RECORD_ERROR: contributionsEmploymentRecordError,

  CURRENCIES_INFORMATION: currenciesInformation,

  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_CONTRIBUTION_PAYMENTS: getContributionPayments,
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: getContributionPaymentsSuccess,
  GET_CONTRIBUTION_PAYMENTS_ERROR: getContributionPaymentsError,

  DATA_UPLOAD_READY: dataUploadReady
}
