export const key = 'PAYMENT_SETTLEMENT_PROFILE'

export const PAYMENT_SETTLEMENT_PROFILE = {
  GET_PAYMENT_SETTLEMENT_PROFILE: 'PAYMENT_SETTLEMENT_PROFILE/GET_DATA',
  SUCCESS: 'PAYMENT_SETTLEMENT_PROFILE/GET_SUCCESS',
  ERROR: 'PAYMENT_SETTLEMENT_PROFILE/GET_ERROR',
  CLEAR: 'PAYMENT_SETTLEMENT_PROFILE/CLEAR',

  EDIT_PAYMENT_SETTLEMENT_PROFILE: 'PAYMENT_SETTLEMENT_PROFILE/EDIT',
  EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/EDIT/INFORMATION',
  PAYMENT_SETTLEMENT_PROFILE_UPDATED: 'PAYMENT_SETTLEMENT_PROFILE/EDIT/UPDATED',

  GET_PAYMENT_SETTLEMENT_PROFILE_DEBITS: 'PAYMENT_SETTLEMENT_PROFILE/DEBITS/GET_LIST',
  DEBITS_SUCCESS: 'PAYMENT_SETTLEMENT_PROFILE/GET_LIST/DEBITS_SUCCESS',
  DEBITS_ERROR: 'PAYMENT_SETTLEMENT_PROFILE/GET_LIST/DEBITS_ERROR',
  DEBITS_CLEAR: 'PAYMENT_SETTLEMENT_PROFILE/CLEAR_LIST',
  SEND_FILTER: 'PAYMENT_SETTLEMENT_PROFILE/FILTER/SEND',
  GET_FILTER_DATA: 'PAYMENT_SETTLEMENT_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PAYMENT_SETTLEMENT_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PAYMENT_SETTLEMENT_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PAYMENT_SETTLEMENT_PROFILE/FILTER/CLEAR',
  DEBITS_UPDATE: 'PAYMENT_SETTLEMENT_PROFILE/DEBITS/UPDATE',

  GET_MODAL_DATA: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_DATA',
  MODAL_DATA_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/DATA_INFORMATION',
  ADD_DEBT: 'PAYMENT_SETTLEMENT_PROFILE/DEBT/ADD',
  ADD_DEBT_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/DEBT/ADD/INFORMATION',
  MESSAGE_INFORMATION_CLEAR: 'PAYMENT_SETTLEMENT_PROFILE/MESSAGE_INFORMATION/CLEAR',

  GET_PERSON_CONCEPTS: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_PERSON_CONCEPTS',

  GET_ENTITIES_DATA: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_ENTITIES_DATA',
  ENTITIES_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/ENTITIES_INFORMATION',
  GET_SERVICES_DATA: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_SERVICES_DATA',
  SERVICES_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/SERVICES_INFORMATION',
  CLEAR_SERVICES_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/CLEAR_SERVICES_INFORMATION',
  GET_PERSON_DATA: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_PERSON_DATA',
  PERSON_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/PERSON_INFORMATION',
  GET_CURRENCIES: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/GET_CURRENCIES',
  CURRENCIES_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/MODAL/CURRENCIES_INFORMATION',

  CALCULATE_DETAILS: 'PAYMENT_SETTLEMENT_PROFILE/DETAILS/CALCULATE',
  CALCULATE_DETAILS_INFORMATION: 'PAYMENT_SETTLEMENT_PROFILE/DETAILS/CALCULATE/INFORMATION',

  PENDING_PROCESS_INFO: 'PAYMENT_SETTLEMENT_PROFILE/PENDING_PROCES/INFO',
  GET_PENDING_PROCESS_INFO: 'PAYMENT_SETTLEMENT_PROFILE/GET_PENDING_PROCESS/INFO'
}

export default key
