import produce from 'immer'

import { PAYMENT_DETAIL } from './types'

export const initialState = {
  loading: null,
  error: false,
  paymentDetail: null,
  paymentDetailStatuses: null,
  loadingEditProfile: false,
  editInformation: null,
  errorMessage: null,
  bankAccountLoading: null,
  bankAccounts: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PAYMENT_DETAIL.GET_PAYMENT_DETAIL:
      draft.loading = true
      break
    case PAYMENT_DETAIL.SUCCESS: {
      const { paymentDetailData, statusesData } = payload
      draft.loading = false
      draft.paymentDetail = paymentDetailData
      draft.paymentDetailStatuses = statusesData
      break
    }
    case PAYMENT_DETAIL.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case PAYMENT_DETAIL.EDIT_PAYMENT_DETAIL:
      draft.loadingEditProfile = true
      break
    case PAYMENT_DETAIL.EDIT_PAYMENT_DETAIL_INFORMATION:
      draft.editInformation = payload
      draft.loadingEditProfile = false
      break
    case PAYMENT_DETAIL.PAYMENT_DETAIL_UPDATED:
      draft.loadingEditProfile = false
      draft.paymentDetail = payload
      break
      // BANK ACCOUNTS
    case PAYMENT_DETAIL.GET_BANK_ACCOUNTS:
      draft.bankAccountLoading = true
      break
    case PAYMENT_DETAIL.BANK_ACCOUNTS_INFORMATION:
      draft.bankAccountLoading = false
      draft.bankAccounts = payload
      break
    case PAYMENT_DETAIL.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
