import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectCurrencyProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectCurrencyProfile, state => get(state, 'loading', null)
)

const selectCurrencyProfileSuccess = createSelector(
  selectCurrencyProfile, state => get(state, 'profile', null)
)
const selectCurrencyValuesSuccess = createSelector(
  selectCurrencyProfile, state => get(state, 'currencyValues', null)
)

const selectColumns = createSelector(
  selectCurrencyProfile, state => get(state, 'columns', null)
)

const selectPages = createSelector(
  selectCurrencyProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectCurrencyProfile, state => get(state, 'totalItems', null)
)

const selectGetError = createSelector(
  selectCurrencyProfile, state => get(state, 'error', null)
)

const selectLoadingTable = createSelector(
  selectCurrencyProfile, state => get(state, 'loadingTable', [])
)

const selectMessageInformation = createSelector(
  selectCurrencyProfile, state => get(state, 'messageInformation', null)
)

const selectDataFilters = createSelector(
  selectCurrencyProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectCurrencyProfile, state => get(state, 'dataFiltersError', null)
)

export default {
  LOADING: selectLoading,

  CURRENCY_PROFILE_SUCCESS: selectCurrencyProfileSuccess,

  CURRENCY_VALUES_SUCCESS: selectCurrencyValuesSuccess,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  LOADING_TABLE_VALUES: selectLoadingTable,

  GET_ERROR: selectGetError,
  MESSAGE_INFORMATION: selectMessageInformation,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError
}
