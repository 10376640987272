import styled from 'styled-components'
import { spacing } from '@global/theme'

export const Container = styled.div`
 position: relative;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: space-evenly;
 margin-bottom: ${spacing.one};

 > h1 {
    position: absolute;
    font-size: ${spacing.five};
    margin-top: ${spacing.oneAndAHalf};
  }

  canvas {
    width:  100% !important;
  }
`
