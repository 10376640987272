import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { ERROR_LOG_ACTIONS } from './actions'
import { ERROR_LOG } from './types'

const apiCalls = {
  getErrorLog: ({ data, token }) => (
    Axios.post('ErrorLogs/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getErrorLog ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getErrorLog, payload)
    yield put(ERROR_LOG_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(ERROR_LOG_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(ERROR_LOG_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(ERROR_LOG_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(ERROR_LOG.GET, getErrorLog)
  yield takeLatest(ERROR_LOG.GET_FILTER_DATA, FilterData)
}
