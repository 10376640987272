import { PAYMENT_DETAIL } from './types'

export const getPaymentDetail = payload => ({
  type: PAYMENT_DETAIL.GET_PAYMENT_DETAIL,
  payload
})

export const getPaymentDetailSuccess = payload => ({
  type: PAYMENT_DETAIL.SUCCESS,
  payload
})

export const getPaymentDetailError = payload => ({
  type: PAYMENT_DETAIL.ERROR,
  payload
})

export const editPaymentDetail = payload => ({
  type: PAYMENT_DETAIL.EDIT_PAYMENT_DETAIL,
  payload
})

export const editPaymentDetailInformation = payload => ({
  type: PAYMENT_DETAIL.EDIT_PAYMENT_DETAIL_INFORMATION,
  payload
})

export const lcontributionPaymentUpdated = payload => ({
  type: PAYMENT_DETAIL.PAYMENT_DETAIL_UPDATED,
  payload
})

export const getBankAccounts = payload => ({
  type: PAYMENT_DETAIL.GET_BANK_ACCOUNTS,
  payload
})

export const bankAccountInformation = payload => ({
  type: PAYMENT_DETAIL.BANK_ACCOUNTS_INFORMATION,
  payload
})

export const PAYMENT_DETAIL_ACTIONS = {
  GET_PAYMENT_DETAIL: getPaymentDetail,
  SUCCESS: getPaymentDetailSuccess,
  ERROR: getPaymentDetailError,

  EDIT_PAYMENT_DETAIL: editPaymentDetail,
  EDIT_PAYMENT_DETAIL_INFORMATION: editPaymentDetailInformation,
  PAYMENT_DETAIL_UPDATED: lcontributionPaymentUpdated,

  GET_BANK_ACCOUNTS: getBankAccounts,
  BANK_ACCOUNTS_INFORMATION: bankAccountInformation
}
