import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { CURRENCY_PROFILE_ACTIONS } from './actions'
import { CURRENCY_PROFILE } from './types'

const apiCalls = {
  getCurrencyProfile: ({ currencyId, token }) => (
    Axios(`Currency/${currencyId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  currencyValuesFilter: ({ currencyId, data, token }) => (
    Axios.post(`Currency/${currencyId}/values/filter`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createCurrencyValue: ({ currencyId, data, token }) => (
    Axios.post(`Currency/${currencyId}/Values`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editCurrencyValue: ({ currencyId, currencyValueId, data, token }) => (
    Axios.post(`Currency/${currencyId}/Values/${currencyValueId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getCurrencyProfile ({ payload }) {
  try {
    const { data: currencyProfile } = yield call(apiCalls.getCurrencyProfile, payload)
    yield put(CURRENCY_PROFILE_ACTIONS.CURRENCY_PROFILE_SUCCESS(currencyProfile))
  } catch (error) {
    yield put(CURRENCY_PROFILE_ACTIONS.GET_ERROR(error))
  }
}

export function* getCurrencyValues ({ payload }) {
  try {
    const { data: currencyValues } = yield call(apiCalls.currencyValuesFilter, payload)

    yield put(CURRENCY_PROFILE_ACTIONS.CURRENCY_VALUES_SUCCESS(currencyValues))
  } catch (error) {
    yield put(CURRENCY_PROFILE_ACTIONS.GET_ERROR(error))
  }
}

export function* setCurrencyValue ({ payload }) {
  try {
    const { type, currencyId, currencyValueId, data, body, token } = payload

    if (type === FUNCTION_TYPE.ADD) {
      yield call(apiCalls.createCurrencyValue, { currencyId, data, token })
    } else {
      yield call(apiCalls.editCurrencyValue, { currencyId, currencyValueId, data, token })
    }

    const { status, data: currencyProfileUpdated } = yield call(apiCalls.currencyValuesFilter,
      { currencyId, data: body, token })
    yield put(CURRENCY_PROFILE_ACTIONS.CURRENCY_VALUES_SUCCESS(currencyProfileUpdated))
    yield put(CURRENCY_PROFILE_ACTIONS.MESSAGE_INFORMATION({ status }))
  } catch (error) {
    yield put(CURRENCY_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(CURRENCY_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(CURRENCY_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(CURRENCY_PROFILE.GET_CURRENCY_PROFILE, getCurrencyProfile)
  yield takeLatest(CURRENCY_PROFILE.GET_CURRENCY_VALUES, getCurrencyValues)
  yield takeLatest(CURRENCY_PROFILE.SET_CURRENCY_VALUE, setCurrencyValue)
  yield takeLatest(CURRENCY_PROFILE.GET_FILTER_DATA, FilterData)
}
