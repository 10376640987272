import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { DETAIL_TYPES } from '@global/constants'
import { DETAIL_LIST_ACTIONS } from './actions'
import { DETAIL_LIST } from './types'

const apiCalls = {
  getContributionProfile: ({ contributionId, token }) => (
    Axios(`Contribution/${contributionId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDebtProfile: ({ debtId, token }) => (
    Axios(`OutboundSettlementDetail/${debtId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributionPaymentList: ({ data, token }) => (
    Axios.post('InboundPayment/contributionpayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentsDetailsList: ({ data, token }) => (
    Axios.post('OutboundPaymentDetails/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getInboundPaymentsOrigins: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getDetailListProfile ({ payload }) {
  try {
    const { type, dataRequest } = payload

    let profile = null
    if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
      const { data: contributionProfile } = yield call(apiCalls.getContributionProfile, dataRequest)
      profile = contributionProfile
    } else {
      const { data: debtProfile } = yield call(apiCalls.getDebtProfile, dataRequest)
      profile = debtProfile
    }

    yield put(DETAIL_LIST_ACTIONS.GET_DETAIL_LIST_PROFILE_SUCCESS(profile))
  } catch (error) {
    yield put(DETAIL_LIST_ACTIONS.GET_DETAIL_LIST_PROFILE_ERROR(error))
  }
}

export function* getDetailList ({ payload }) {
  try {
    const { type, dataRequest } = payload
    const { token } = dataRequest
    let details = null
    if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
      const { data: contributions } = yield call(apiCalls.getContributionPaymentList, dataRequest)
      const { data: originData } = yield call(apiCalls.getInboundPaymentsOrigins, token)
      details = { contributions, originData }
    } else {
      const { data: debits } = yield call(apiCalls.getPaymentsDetailsList, dataRequest)
      details = debits
    }

    yield put(DETAIL_LIST_ACTIONS.DETAIL_LIST_SUCCESS({ detailType: type, details }))
  } catch (error) {
    yield put(DETAIL_LIST_ACTIONS.DETAIL_LIST_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(DETAIL_LIST_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(DETAIL_LIST_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(DETAIL_LIST.GET_DETAIL_LIST_PROFILE, getDetailListProfile)
  yield takeLatest(DETAIL_LIST.GET_DETAIL_LIST, getDetailList)
  yield takeLatest(DETAIL_LIST.SEND_FILTER, getDetailList)
  yield takeLatest(DETAIL_LIST.GET_FILTER_DATA, filterData)
}
