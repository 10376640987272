import { PAYMENT_SETTLEMENTS } from './types'

export const getPaymentSettlements = payload => ({
  type: PAYMENT_SETTLEMENTS.GET,
  payload
})

export const getPaymentSettlementsSuccess = payload => ({
  type: PAYMENT_SETTLEMENTS.SUCCESS,
  payload
})

export const getPaymentSettlementsError = payload => ({
  type: PAYMENT_SETTLEMENTS.ERROR,
  payload
})

export const ClearPaymentSettlementsList = payload => ({
  type: PAYMENT_SETTLEMENTS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: PAYMENT_SETTLEMENTS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: PAYMENT_SETTLEMENTS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PAYMENT_SETTLEMENTS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PAYMENT_SETTLEMENTS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PAYMENT_SETTLEMENTS.FILTER_DATA_CLEAR
})

export const getStatuses = payload => ({
  type: PAYMENT_SETTLEMENTS.GET_STATUSES,
  payload
})

export const paymentSettlementsStatuses = payload => ({
  type: PAYMENT_SETTLEMENTS.PAYMENT_SETTLEMENTS_STATUSES,
  payload
})

export const addPaymentSettlement = payload => ({
  type: PAYMENT_SETTLEMENTS.ADD_PAYMENT_SETTLEMENT,
  payload
})

export const addPaymentSettlementInformation = payload => ({
  type: PAYMENT_SETTLEMENTS.ADD_PAYMENT_SETTLEMENT_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: PAYMENT_SETTLEMENTS.CLEAR_MESSAGE_INFORMATION
})

export const paymentSettlementUpdated = () => ({
  type: PAYMENT_SETTLEMENTS.PAYMENT_SETTLEMENTS_UPDATED
})

export const PAYMENT_SETTLEMENTS_ACTIONS = {
  GET_PAYMENT_SETTLEMENTS: getPaymentSettlements,
  SUCCESS: getPaymentSettlementsSuccess,
  ERROR: getPaymentSettlementsError,
  CLEAR: ClearPaymentSettlementsList,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_STATUSES: getStatuses,
  PAYMENT_SETTLEMENTS_STATUSES_INFORMATION: paymentSettlementsStatuses,
  ADD_PAYMENT_SETTLEMENT: addPaymentSettlement,
  ADD_PAYMENT_SETTLEMENT_INFORMATION: addPaymentSettlementInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,
  PAYMENT_SETTLEMENTS_UPDATED: paymentSettlementUpdated
}
