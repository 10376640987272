import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { ModalDataContainer, ButtonContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { SERVICE_PROVIDER_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddProviderModal = ({ close, successfulResult, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [nameProvider, setNameProvider] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [description, setDescription] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const messageInformation = useSelector(SELECTORS.ADD_SERVICE_PROVIDER_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((nameProvider.value && !nameProvider.error)
      && (description.value && !description.error)) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setNameProvider({
      value: '',
      name: '',
      error: ''
    })
    setDescription({
      value: '',
      name: '',
      error: ''
    })
  }

  const handleInputChange = val => {
    const { name } = val
    if (name === 'description') setDescription(val)
    else setNameProvider(val)
  }

  const handleClick = () => {
    const newProvider = {
      name: nameProvider.value,
      description: description.value
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_ACTIONS.ADD_PROVIDER({ data: newProvider, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    errorControl()
  }, [nameProvider, description])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      } else {
        cleanFields()
        close()
        successfulResult()
      }
      dispatch(SERVICE_PROVIDER_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <TextInput
          name='nameProvider'
          label='Proveedor'
          placeholder='Ingrese nombre de Proveedor'
          color='primary'
          value={nameProvider.value}
          onChange={handleInputChange}
          onBlur={() => {}}
          height='small'
          error={nameProvider.error}
          bits={[{ icon: 'manage_accounts', position: 'left', colorBit: 'tertiary' }]}
        />
        <TextInput
          name='description'
          label='Descripción'
          placeholder='Ingrese descripción'
          color='primary'
          value={description.value}
          onChange={handleInputChange}
          onBlur={() => {}}
          height='small'
          error={description.error}
          bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddProviderModal

AddProviderModal.propTypes = {
  close: PropTypes.func,
  successfulResult: PropTypes.func,
  currentToken: PropTypes.string
}
