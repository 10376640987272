import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import { useWindowDimensions } from '@components/windowDimensions'
import { PasswordInput, PasswordRepeatInput } from '@components/inputs/inputs'
import { SolidButton } from '@components/button'
import { Heading, Text } from '@components/texts'
import Toast from '@components/toast'

import ROUTES_SELECTOR from '@routes/redux/selectors'
import { USER_ACTIONS } from '../../redux/action'
import SELECTORS from '../../redux/selectors'
import useRedux from '../../redux'

import { Wrapper, Header, Body, Footer, WrapperRegister, StyledNavLink, Container } from '../../styles'

const PasswordConfirm = () => {
  useRedux()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const searchParams = useSelector(ROUTES_SELECTOR.QUERY)
  const [password, setPassword] = useState({
    value: '',
    error: ''
  })

  const [passwordRepeat, setPasswordRepeat] = useState({
    name: '',
    value: '',
    error: ''
  })

  const [errorMessage, setErrorMessage] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const messagePasswordConfirm = useSelector(SELECTORS.RESPONSE_PASSWORD_CONFIRM)

  const handleChangePassword = name => setPassword(name)
  const handleChangePasswordRepeat = name => setPasswordRepeat(name)
  const handleClose = () => setIsShowing(false)

  const onSubmit = () => {
    if (!password.value
        || !(passwordRepeat.value && !passwordRepeat.error)
        || (passwordRepeat.value && passwordRepeat.error)) {
      setTimeout(() => setErrorMessage(''), 2500)
      return setErrorMessage(MESSAGES.FIELD_EMPTY)
    }

    if (password.error || passwordRepeat.error) {
      setTimeout(() => setErrorMessage(''), 2500)
      return setErrorMessage(MESSAGES.BASIC_ERROR)
    }

    const userData = {
      userId: searchParams.userId,
      token: searchParams.token,
      password: password.value
    }

    dispatch(USER_ACTIONS.PASWORD_CONFIRM(userData))
  }

  useEffect(() => {
    if (messagePasswordConfirm.status !== 200 && messagePasswordConfirm.status !== 400) return
    if (messagePasswordConfirm.status === 200) {
      setMessageToast({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: MESSAGES.PASSWORD_CONFIRM_SUCCESS,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color
      })
    } else {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: MESSAGES.PASSWORD_CONFIRM_ERROR,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }

    setIsShowing(true)
    setTimeout(() => {
      setMessageToast({
        title: '',
        message: '',
        icon: ''
      })
    }, 4000)
  }, [messagePasswordConfirm])

  useEffect(() => {
    if (messageToast.title === TOAST_PROPERTIES.SUCCESS.title) {
      setTimeout(() => {
        history.push(SITE.SIGN_IN)
      }, 2000)
    }
  }, [messageToast])

  return (
    <Container>
      <Wrapper device={device} mobileHeight='540px' desktopHeight='540px' mobileWidth='312px' desktopWidth='430px'>
        <Header device={device} width='80%'>
          <Heading
            size={device === BREAKPOINTS.MOBILE ? 'medium' : 'xlarge'}
            type='h2'
            marginBottom={device === BREAKPOINTS.MOBILE ? 'half' : 'one'}
            weight='bold'
            color='primary'
            align='center'
          >
            Bienvenido a A.T.A.E.
          </Heading>
        </Header>
        <Body device={device} paddingTopBot={spacing.one} paddingLefRig={spacing.four} justifyContent='center'>
          <Text size='xlarge' weight='semibold' align='center' marginBottom='quarter'>
            Por favor ingrese una contrase&ntilde;a nueva.
          </Text>
          <Text size='small' weight='regular' align='center' marginBottom='one'>
            La contrase&ntilde;a debe tener al menos 8 carácteres, una letra mayúscula, una letra minúscula,&nbsp;
            un número y un caracter especial.`
          </Text>
          <PasswordInput
            value={password.value}
            onChange={handleChangePassword}
            name='password'
            label='Contraseña'
            placeholder='Ingrese una contraseña nueva.'
            color='primary'
            bits={[{ icon: 'mail', position: 'left', colorBit: 'primary' }]}
            error={password.error}
            required
          />
          <PasswordRepeatInput
            name='passwordRepaeat'
            label='Repetir contraseña'
            placeholder='Repita la contraseña nueva.'
            pwRepeat={password.value}
            onChange={handleChangePasswordRepeat}
            color='primary'
            error={passwordRepeat.error}
            message={passwordRepeat.error}
            bits={[{ icon: 'mail', position: 'left', colorBit: 'primary' }]}
            required
          />
          <SolidButton
            block
            color='primary'
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            text='Confirmar'
            onClick={onSubmit}
          />
        </Body>
        <Footer>
          { errorMessage
            && (
            <Text
              size={device === BREAKPOINTS.MOBILE ? 'medium' : 'large'}
              weight='bold'
              color='error'
            >
              {errorMessage.toUpperCase()}
            </Text>
            )}
          <WrapperRegister>
            <Text size='medium' weight='semibold'>¿Recordaste tu contrase&ntilde;a?</Text>
            <StyledNavLink to={SITE.SIGN_IN}>Login</StyledNavLink>
          </WrapperRegister>
        </Footer>
      </Wrapper>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={handleClose}
        timeOut={messageToast.title === TOAST_PROPERTIES.ERROR.title ? 3000 : 2000}
      />
    </Container>
  )
}

export default PasswordConfirm
