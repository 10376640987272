import produce from 'immer'

import { HOME } from './types'

export const initialState = {
  loading: null,
  error: null,
  dataHome: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case HOME.GET_DATA_HOME:
      draft.loading = true
      break
    case HOME.SUCCESS:
      draft.loading = false
      draft.dataHome = payload
      break
    case HOME.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case HOME.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
