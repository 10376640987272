import React from 'react'
import PropTypes from 'prop-types'

import MyPropTyes from '@global/propTypes'

import Badge from '@components/badge'
import { Heading, Text } from '@components/texts'
import Button from '@components/button'

import { BREAKPOINTS } from '@global/constants'
import {
  Img,
  Info,
  MyCard,
  Container,
  TextContainer,
  BadgeContainer,
  MultiButtonContainer,
  SingleButtonContainer,
  ImgContainer
} from './styles'

const Card = props => {
  const {
    overline,
    title,
    content,
    actions,
    image,
    badges,
    type,
    height,
    imgPosition,
    containerDirection,
    device
  } = props

  const renderImg = () => (
    <ImgContainer type={type} height={height} alignItems={device < BREAKPOINTS.TABLET ? 'center' : 'flex-start'}>
      {image && <Img src={image} type={type} height={height} />}
    </ImgContainer>
  )
  return (
    <MyCard type={type} height={height}>
      {badges && (
      <BadgeContainer>
        {badges.map((badge, i) => <Badge key={`badge_${i}`} {...badge} />)}
      </BadgeContainer>
      )}

      {image && imgPosition === 'before' && renderImg()}

      <Container type={type} direction={containerDirection} device={device}>
        <Info flex='4'>
          {overline && <Text size='medium' marginBottom='one'>{overline}</Text>}
          {title && (
            <Heading
              type='h4'
              size={type === 'horizontal' ? 'medium' : 'small'}
              marginBottom='one'
              weight='bold'
            >
              {title}
            </Heading>
          )}
          <TextContainer type={type}>
            {content && <Text marginBottom='two'>{content}</Text>}
          </TextContainer>

          {actions.length >= 2 && (
          <MultiButtonContainer>
            {actions.map((action, i) => <Button key={`button_${i}`} {...action} type={action.type} />)}
          </MultiButtonContainer>
          )}

        </Info>

        {actions.length === 1 && (
        <SingleButtonContainer direction={containerDirection} type={type}>
          <Button {...actions[0]} block={type === 'vertical'} />
        </SingleButtonContainer>
        )}
      </Container>

      {image && imgPosition === 'after' && renderImg()}
    </MyCard>
  )
}

Card.propTypes = {
  overline: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  height: PropTypes.string,
  containerDirection: PropTypes.oneOf(['row', 'column']),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      size: MyPropTyes.size,
      type: PropTypes.oneOf(['line', 'ghost', 'solid']),
      text: PropTypes.string,
      color: MyPropTyes.color,
      onClick: PropTypes.func
    })
  ),
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      size: PropTypes.string,
      color: MyPropTyes.color
    })
  ),
  type: PropTypes.oneOf(['horizontal', 'vertical']),
  imgPosition: PropTypes.string,
  device: PropTypes.number
}

Card.defaultProps = {
  actions: [],
  badges: []
}

export default Card
