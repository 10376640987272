import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PAYMENT_SETTLEMENT_PROFILE_ACTIONS } from './actions'
import { PAYMENT_SETTLEMENT_PROFILE } from './types'
import { mockEntities } from '../data'

const apiCalls = {
  getPaymentSettlementProfile: ({ paymentSettlementProfileId, token }) => (
    Axios.get(`OutboundSettlement/${paymentSettlementProfileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentSettlementProfileStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ paymentSettlementProfileId, data, token }) => (
    Axios.post(`OutboundSettlement/${paymentSettlementProfileId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDebits: ({ data, token }) => (
    Axios.post('OutboundSettlementDetail/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDebitsStatuses: token => (
    Axios.get('OutboundSettlementDetail/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createDebt: ({ data, token }) => (
    Axios.post('OutboundSettlementDetail/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPerson: ({ personId, token }) => (
    Axios.get(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDebitsByPerson: ({ data, token }) => (
    Axios.post('Contribution/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getEntities: () => mockEntities,
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getServicesProviders: ({ data, token }) => (
    Axios.post('ServiceProvider/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPersonConcepts: ({ personId, token }) => (
    Axios.get(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  calculateDetails: ({ outboundSettlementId, data, token }) => (
    Axios.post(`OutboundSettlement/${outboundSettlementId}/calculateDetails`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getPaymentSettlementProfile ({ payload }) {
  try {
    const { token } = payload
    const { status, data: paymentSettlementProfileObj } = yield call(apiCalls.getPaymentSettlementProfile, payload)
    const { data: statuses } = yield call(apiCalls.getPaymentSettlementProfileStatuses, token)
    const { isCalculating, isChangingStatus, progressPercentage } = paymentSettlementProfileObj

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.SUCCESS({ paymentSettlementProfileObj, statuses }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editPaymentSettlementProfile ({ payload }) {
  try {
    const { paymentSettlementProfileId, data, token } = payload
    const { statusId } = data
    const { status, data: profileUpdated } = yield call(apiCalls.editProfile, payload)
    const { isCalculating, isChangingStatus, progressPercentage } = profileUpdated

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PAYMENT_SETTLEMENT_PROFILE_UPDATED(profileUpdated))
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION(status))
    // id 1 is approved, the contribution table should be updated
    if (statusId === 1 && !isChangingStatus) {
      yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.DEBITS_UPDATE())
      const body = {
        outboundSettlementId: paymentSettlementProfileId,
        pageNumber: 1,
        pageSize: 15
      }
      const { data: debitsObj } = yield call(apiCalls.getDebits, { data: body, token })
      const { data: statuses } = yield call(apiCalls.getDebitsStatuses, token)

      yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.DEBITS_SUCCESS({ debitsObj, statuses }))
    }
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION(error))
  }
}

export function* getDebits ({ payload }) {
  try {
    const { data, token } = payload

    const { data: debitsObj } = yield call(apiCalls.getDebits, { data, token })
    const { data: statuses } = yield call(apiCalls.getDebitsStatuses, token)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.DEBITS_SUCCESS({ debitsObj, statuses }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.DEBITS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalDataPaymentSettlement ({ payload }) {
  try {
    const { personId, data, token } = payload
    const { data: conceptsData } = yield call(apiCalls.getPerson, { personId, token })
    const res = yield call(apiCalls.getDebitsByPerson, { data, token })

    const debitsData = res.data.items
    const modalDataObj = { conceptsData, debitsData }

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.MODAL_DATA_INFORMATION({ status: 200, modalDataObj }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.MODAL_DATA_INFORMATION(error))
  }
}

export function* addDebt ({ payload }) {
  try {
    const { body, data, token } = payload
    const { status } = yield call(apiCalls.createDebt, { data, token })
    const { data: debitsUpdated } = yield call(apiCalls.getDebits, { data: body, token })
    const { data: statuses } = yield call(apiCalls.getDebitsStatuses, token)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.DEBITS_SUCCESS({ debitsObj: debitsUpdated, statuses }))
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ADD_DEBT_INFORMATION(status))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ADD_DEBT_INFORMATION(error))
  }
}

export function* getEntities ({ payload }) {
  try {
    const { status, data: entitiesObj } = yield call(apiCalls.getEntities, payload)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ENTITIES_INFORMATION({ status, entitiesObj }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ENTITIES_INFORMATION(error))
  }
}

export function* getServicesData ({ payload }) {
  try {
    const { status, data: sp } = yield call(apiCalls.getServicesProviders, payload)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.SERVICES_INFORMATION(
      { status, servicesProviders: sp.items }
    ))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.SERVICES_INFORMATION(error))
  }
}

export function* getPersonData ({ payload }) {
  try {
    const { status, data: personConcepts } = yield call(apiCalls.getPersonConcepts, payload)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PERSON_INFORMATION({ status, personConcepts }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PERSON_INFORMATION(error))
  }
}

export function* getCurrencies ({ payload }) {
  try {
    const { status, data: currenciesObj } = yield call(apiCalls.getCurrencies, payload)

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CURRENCIES_INFORMATION({ status, currenciesObj }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CURRENCIES_INFORMATION(error))
  }
}

export function* calculateDetails ({ payload }) {
  try {
    const { outboundSettlementId, token } = payload

    const { status: statusCalculate } = yield call(apiCalls.calculateDetails, payload)
    const { status, data: paymentSettlementProfileObj } = yield call(apiCalls.getPaymentSettlementProfile,
      { paymentSettlementProfileId: outboundSettlementId, token })
    const { isCalculating, isChangingStatus, progressPercentage } = paymentSettlementProfileObj

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CALCULATE_DETAILS_INFORMATION({ status: statusCalculate }))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CALCULATE_DETAILS_INFORMATION(error))
  }
}

export function* getPendingProcessInfo ({ payload }) {
  try {
    const { statusId, paymentSettlementProfileId, token } = payload
    const { status, data: paymentSettlementProfileObj } = yield call(
      apiCalls.getPaymentSettlementProfile, { paymentSettlementProfileId, token }
    )
    const { isCalculating, isChangingStatus, progressPercentage } = paymentSettlementProfileObj

    if (statusId !== paymentSettlementProfileObj?.statusId && !isChangingStatus) {
      yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PAYMENT_SETTLEMENT_PROFILE_UPDATED(paymentSettlementProfileObj))
    }

    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))
  } catch (error) {
    yield put(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(error))
  }
}

export default function* saga () {
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE, getPaymentSettlementProfile)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.EDIT_PAYMENT_SETTLEMENT_PROFILE, editPaymentSettlementProfile)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE_DEBITS, getDebits)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_FILTER_DATA, filterData)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_PERSON_CONCEPTS, getModalDataPaymentSettlement)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.ADD_DEBT, addDebt)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_ENTITIES_DATA, getEntities)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_SERVICES_DATA, getServicesData)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_PERSON_DATA, getPersonData)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_CURRENCIES, getCurrencies)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.CALCULATE_DETAILS, calculateDetails)
  yield takeLatest(PAYMENT_SETTLEMENT_PROFILE.GET_PENDING_PROCESS_INFO, getPendingProcessInfo)
}
