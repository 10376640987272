import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BREAKPOINTS, TOAST_PROPERTIES, TOAST_POSITION } from '@global/constants'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import { useWindowDimensions } from '@components/windowDimensions'
import { EmailInput } from '@components/inputs/inputs'
import { SolidButton } from '@components/button'
import { Heading, Text } from '@components/texts'
import Toast from '@components/toast'

import { USER_ACTIONS } from '../../redux/action'
import useRedux from '../../redux'

import { Wrapper, Header, Body, Footer, WrapperRegister, StyledNavLink, Container } from '../../styles'

const PasswordRecovery = () => {
  useRedux()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()
  const [email, setEmail] = useState({
    email: '',
    error: ''
  })
  const [errorEmail, setErrorEmail] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [isShowing, setIsShowing] = useState(false)

  const handleChange = ({ value, error }) => {
    setEmail({
      email: value,
      error
    })
  }

  const handleBlur = () => (email.error ? setErrorEmail(email.error) : setErrorEmail(''))
  const handleClose = () => setIsShowing(false)
  const onSubmit = () => {
    if (!email.email) {
      setTimeout(() => setErrorMessage(''), 2500)
      return setErrorMessage(MESSAGES.FIELD_EMPTY)
    }

    if (email.error) {
      setTimeout(() => setErrorMessage(''), 2500)
      return setErrorMessage(MESSAGES.BASIC_ERROR)
    }
    setIsShowing(true)
    setTimeout(() => {
      dispatch(USER_ACTIONS.PASWORD_RECOVERY(email))
      history.push(SITE.SIGN_IN)
    }, 2000)
  }

  return (
    <Container>
      <Wrapper device={device} mobileHeight='540px' desktopHeight='460px' mobileWidth='310px' desktopWidth='430px'>
        <Header device={device} width='80%'>
          <Heading
            size={device === BREAKPOINTS.MOBILE ? 'medium' : 'xlarge'}
            type='h2'
            marginBottom={device === BREAKPOINTS.MOBILE ? 'half' : 'one'}
            weight='bold'
            color='primary'
            align='center'
          >
            Bienvenido a A.T.A.E.
          </Heading>
        </Header>
        <Body device={device} paddingTopBot={spacing.one} paddingLefRig={spacing.six} justifyContent='center'>
          <Text size='xlarge' weight='semibold' align='center'>
            Por favor ingrese su email para recuperar su contrase&ntilde;a
          </Text>
          <EmailInput
            value={email.value}
            onChange={handleChange}
            onBlur={handleBlur}
            name='email'
            label='Correo electrónico'
            placeholder='Ingrese su correo electrónico'
            color='primary'
            bits={[{ icon: 'mail', position: 'left', colorBit: 'primary' }]}
            error={errorEmail}
            required
          />
          <SolidButton
            block
            color='primary'
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            text='Recuperar'
            onClick={onSubmit}
          />
        </Body>
        <Footer>
          { errorMessage
            && (
            <Text
              size={device === BREAKPOINTS.MOBILE ? 'medium' : 'large'}
              weight='bold'
              color='error'
            >
              {errorMessage.toUpperCase()}
            </Text>
            )}
          <WrapperRegister>
            <Text size='medium' weight='semibold'>¿Recordaste tu contrase&ntilde;a?</Text>
            <StyledNavLink to={SITE.SIGN_IN}>Login</StyledNavLink>
          </WrapperRegister>
        </Footer>
      </Wrapper>
      <Toast
        title={TOAST_PROPERTIES.SUCCESS.title}
        message='Email enviado correctamente.'
        color={TOAST_PROPERTIES.SUCCESS.color}
        icon={TOAST_PROPERTIES.SUCCESS.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={handleClose}
      />
    </Container>
  )
}

export default PasswordRecovery
