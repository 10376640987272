import produce from 'immer'

import { AFFILIATE_RECORD } from './types'

export const initialState = {
  loading: false,
  error: false,
  person: null,
  errorPerson: null,
  personLoading: null,
  affiliateRecord: null,
  benefits: null,
  contributions: null,
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  contributionError: null,
  benefitsLoading: false,
  contributionsLoading: false,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  modalData: null,
  modalLoading: null,
  modalResponse: null,
  profileLoading: false,
  contributionPaymentsLoading: null,
  contributionPayments: null,
  contributionPaymentsError: null,
  inboundPaymentsOrigins: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case AFFILIATE_RECORD.GET_AFFILIATE_RECORD:
      draft.loading = true
      break
    case AFFILIATE_RECORD.GET_AFFILIATE_RECORD_SUCCESS:
      draft.affiliateRecord = payload
      break
    // PERSON
    case AFFILIATE_RECORD.GET_PERSON_DATA:
      draft.personLoading = true
      break
    case AFFILIATE_RECORD.GET_PERSON_DATA_SUCCESS: {
      const { person, benefits } = payload
      draft.benefits = benefits
      draft.person = person
      draft.personLoading = false
    }
      break
    case AFFILIATE_RECORD.GET_PERSON_DATA_ERROR:
      draft.errorPerson = payload
      draft.loading = false
      draft.personLoading = false
      break
    case AFFILIATE_RECORD.GET_AFFILIATE_RECORD_ERROR:
      draft.error = payload
      draft.loading = false
      draft.personLoading = false
      break
    case AFFILIATE_RECORD.CLEAR:
      return initialState
      //  CONTRIBUTIONS
    case AFFILIATE_RECORD.GET_CONTRIBUTIONS:
      draft.contributionsLoading = true
      break
    case AFFILIATE_RECORD.GET_CONTRIBUTIONS_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.contributions = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.contributionsLoading = false
      break
    }
    case AFFILIATE_RECORD.GET_CONTRIBUTIONS_ERROR:
      draft.contributionsLoading = false
      draft.contributionError = payload
      break
      // BENEFITS
    case AFFILIATE_RECORD.SET_BENEFIT:
      draft.benefitsLoading = true
      break
    case AFFILIATE_RECORD.UPDATE_BENEFITS:
      draft.benefitsLoading = false
      draft.benefits = payload
      break
    case AFFILIATE_RECORD.BENEFITS_RESPONSE:
      draft.modalResponse = payload
      draft.benefitsLoading = false
      break
    case AFFILIATE_RECORD.SEND_FILTER:
      draft.loading = true
      break
    case AFFILIATE_RECORD.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case AFFILIATE_RECORD.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case AFFILIATE_RECORD.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case AFFILIATE_RECORD.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
      // MODAL
    case AFFILIATE_RECORD.GET_MODAL_DATA:
      draft.modalLoading = true
      break
    case AFFILIATE_RECORD.GET_DATA_EDIT_PROFILE:
      draft.modalLoading = true
      break
    case AFFILIATE_RECORD.MODAL_DATA_RESPONSE:
      draft.modalData = payload
      draft.modalLoading = false
      break
    case AFFILIATE_RECORD.CLEAR_DATA_MODAL:
      draft.modalData = null
      break
    // PROFILE
    case AFFILIATE_RECORD.EDIT_PROFILE:
      draft.profileLoading = true
      break
    case AFFILIATE_RECORD.UPDATE_PROFILE:
      draft.profileLoading = false
      draft.affiliateRecord = payload
      break
    case AFFILIATE_RECORD.EDIT_PROFILE_RESPONSE:
      draft.modalResponse = payload
      draft.profileLoading = false
      break
    case AFFILIATE_RECORD.DATA_UPLOAD_READY:
      draft.loading = false
      break
    //  CONTRIBUTION PAYMENTS
    case AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS:
      draft.contributionPaymentsLoading = true
      break
    case AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS_SUCCESS: {
      const { contributionPaymentsData, originData } = payload
      draft.contributionPaymentsLoading = false
      draft.contributionPayments = contributionPaymentsData
      draft.inboundPaymentsOrigins = originData
      break
    }
    case AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS_ERROR:
      draft.contributionPaymentsLoading = false
      draft.inboundPaymentsOrigins = payload
      break
    default:
      return initialState
  }
})

export default reducer
