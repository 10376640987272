import produce from 'immer'

import { USERS } from './types'

export const initialState = {
  loading: null,
  error: false,
  statuses: null,
  usersList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  usersError: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  addUserInformation: null,
  usersLoading: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case USERS.GET_STATUSES:
      draft.loading = true
      break
    case USERS.GET_STATUSES_SUCCESS:
      draft.statuses = payload
      break
    case USERS.GET_STATUSES_ERROR:
      draft.loading = false
      draft.error = payload
      break
    case USERS.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.loading = false
      draft.usersList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case USERS.USERS_ERROR:
      draft.loading = false
      draft.usersError = payload
      break
    case USERS.CLEAR:
      return initialState
    case USERS.SEND_FILTER:
      draft.loading = true
      break
    case USERS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case USERS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case USERS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case USERS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case USERS.ADD_USER:
      draft.usersLoading = true
      break
    case USERS.ADD_USER_INFORMATION:
      draft.addUserInformation = payload
      draft.usersLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
