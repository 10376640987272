import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPeople = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPeople, state => get(state, 'loading', false)
)

const selectLoadingList = createSelector(
  selectPeople, state => get(state, 'peopleLoading', null)
)

const selectSuccess = createSelector(
  selectPeople, state => get(state, 'peopleList', [])
)

const selectColumns = createSelector(
  selectPeople, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectPeople, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectPeople, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectPeople, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectPeople, state => get(state, 'dataFilters', [])
)

const selectDataFiltersErrorError = createSelector(
  selectPeople, state => get(state, 'dataFiltersError', null)
)

const selectInformationModalTypes = createSelector(
  selectPeople, state => get(state, 'modalTypes', null)
)

const selectCreatePersonInformation = createSelector(
  selectPeople, state => get(state, 'createPersonInformation', null)
)

const selectPersonStatus = createSelector(
  selectPeople, state => get(state, 'personStatus', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingList,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersErrorError,
  INFORMATION_MODAL_TYPES: selectInformationModalTypes,
  CREATE_PERSON_INFORMATION: selectCreatePersonInformation,
  PERSON_STATUS: selectPersonStatus
}
