import { INPUT_TYPES } from './constants'

export const checkPassword = password => {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d](?=.*[-.!@#$%^&*()?><,/=+_]).{8,}$/
  return !!password.match(regex)
}

export const checkName = name => {
  if (!name) return true
  const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g
  return !!name?.trim().match(regex)
}

export const checkEmail = email => {
  // eslint-disable-next-line
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return !!email.trim().match(regex)
}

export const checkNumber = number => {
  if (!number && number !== 0 && typeof number === 'string') return false
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(number)
}

export const checkPhone = phone => {
  if (!phone) return true

  const number = phone.replace(/[^0-9a-zA-Z]/g, '')
  if (number.length !== 8 || (number[0] !== '4' && number[0] !== '2')) {
    return false
  }

  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
  return !!number.match(regex)
}

export const checkCellphone = phone => {
  if (!phone) return true

  const number = phone.replace(/[^0-9a-zA-Z]/g, '')
  if (number.length !== 9 || number[0] !== '0' || number[1] !== '9') {
    return false
  }

  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/
  return !!number.match(regex)
}

export const checkDate = date => {
  const d = new Date(date)
  // eslint-disable-next-line
  return d.getTime() === d.getTime()
}

export const checkTextArea = (text, min, max) => {
  if (!text) return ''
  if (min && text.length < min) return `${min} es el mínimo de caracteres`
  if (max && text.length > max) return `${max} es el mámximo de caracteres`
  return ''
}

export const checkPwRepeat = (pw, pwRepeat) => pw === pwRepeat

export const getErrorMessage = error => {
  if (!error) return ''

  if (error === 'required') return 'Este campo es requerido'
  if (error === INPUT_TYPES.EMAIL) return 'Por favor ingrese un email válido'
  if (error === INPUT_TYPES.NUMBER) return 'Por favor ingrese un número válido'
  if (error === INPUT_TYPES.DATE) return 'Por favor ingrese una fecha válida'
  if (error === INPUT_TYPES.PASSWORD) return 'Por favor ingrese una contraseña válida'
  if (error === INPUT_TYPES.NAME) return 'Por favor ingrese un nombre válido'
  if (error === INPUT_TYPES.TEXT) return 'Por favor ingrese un texto válido'
  if (error === INPUT_TYPES.PWREPEAT) return 'La contraseña no coincide'
  if (error === INPUT_TYPES.TEL) return 'Por favor ingrese un teléfono válido'
  if (error === INPUT_TYPES.CELLPHONE) return 'Por favor ingrese un teléfono celular válido'

  return 'El campo tiene un error'
}

export const checkField = ({ type, value, required, min, max, pwRepeat }) => {
  if (required && !value) return getErrorMessage('required')

  const valid = (() => {
    switch (type) {
      case INPUT_TYPES.NAME:
        return checkName(value)
      case INPUT_TYPES.EMAIL:
        return checkEmail(value)
      case INPUT_TYPES.TEL:
        return checkPhone(value)
      case INPUT_TYPES.CELLPHONE:
        return checkCellphone(value)
      case INPUT_TYPES.NUMBER:
        return checkNumber(value)
      case INPUT_TYPES.DATE:
        return checkDate(value)
      case INPUT_TYPES.PASSWORD:
        return checkPassword(value)
      case INPUT_TYPES.TEXTAREA:
        return checkTextArea(value, min, max)
      case INPUT_TYPES.PWREPEAT:
        return checkPwRepeat(value, pwRepeat)
      case INPUT_TYPES.TEXT:
      case INPUT_TYPES.DROPDOWN:
        return true
      default:
        return true
    }
  })()
  if (type === INPUT_TYPES.TEXTAREA) return valid
  return !valid ? getErrorMessage(type) : ''
}
