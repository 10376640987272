import { EXPORT_PROFILE } from './types'

export const getExportProfile = payload => ({
  type: EXPORT_PROFILE.GET_EXPORT,
  payload
})

export const getExportProfileSuccess = payload => ({
  type: EXPORT_PROFILE.GET_EXPORT_SUCCESS,
  payload
})

export const getExportProfileError = payload => ({
  type: EXPORT_PROFILE.GET_EXPORT_ERROR,
  payload
})

export const clearExportProfile = () => ({
  type: EXPORT_PROFILE.CLEAR
})

export const downloadFile = payload => ({
  type: EXPORT_PROFILE.DOWNLOAD_FILE,
  payload
})

export const fileDownloaded = payload => ({
  type: EXPORT_PROFILE.FILE_DOWNLOADED,
  payload
})

export const getLogs = payload => ({
  type: EXPORT_PROFILE.GET_LOGS,
  payload
})

export const getLogsSuccess = payload => ({
  type: EXPORT_PROFILE.GET_LOGS_SUCCESS,
  payload
})

export const getLogsError = payload => ({
  type: EXPORT_PROFILE.GET_LOGS_ERROR,
  payload
})

export const applyFilter = payload => ({
  type: EXPORT_PROFILE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: EXPORT_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: EXPORT_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: EXPORT_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: EXPORT_PROFILE.FILTER_DATA_CLEAR
})

export const clearPendingProcessInfo = () => ({
  type: EXPORT_PROFILE.CLEAR_PENDING_PROCESS_INFO
})

export const EXPORT_PROFILE_ACTIONS = {
  GET_EXPORT: getExportProfile,
  GET_EXPORT_SUCCESS: getExportProfileSuccess,
  GET_EXPORT_ERROR: getExportProfileError,
  CLEAR: clearExportProfile,
  DOWNLOAD_FILE: downloadFile,
  FILE_DOWNLOADED: fileDownloaded,
  GET_LOGS: getLogs,
  GET_LOGS_SUCCESS: getLogsSuccess,
  GET_LOGS_ERROR: getLogsError,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  CLEAR_PENDING_PROCESS_INFO: clearPendingProcessInfo
}
