export const COLUMN_DATA_CONTRIBUTIONS = [
  { name: 'Código', widthCol: '15%', label: 'Code' },
  { name: 'Liquidación', widthCol: '10%', label: 'Settlement' },
  { name: 'Monto', widthCol: '10%', label: 'Total' },
  { name: 'Concepto', widthCol: '10%', label: 'Concept' },
  { name: 'Estado', widthCol: '10%', label: 'Status' },
  { name: 'Fecha de pago', widthCol: '15%', label: 'PaymentDate' },
  { name: 'F. de vencimiento', widthCol: '20%', label: 'DueDate' },
  { name: 'Monto cobrado', widthCol: '15%', label: '' },
  { name: 'Descripción', widthCol: '15%', label: '' },
  { name: 'Registro de ingreso', widthCol: '20%', label: '' }
]

export const COLUMN_DATA_PAYMENTS = [
  { name: 'Código', widthCol: '15%', label: 'Code' },
  { name: 'Fecha emisión', widthCol: '15%', label: '' },
  { name: 'Liquidación de pago', widthCol: '15%', label: '' },
  { name: 'Moneda', widthCol: '10%', label: 'Currency' },
  { name: 'Monto', widthCol: '10%', label: 'Total' },
  { name: 'Estado', widthCol: '10%', label: 'Status' },
  { name: 'Fecha de Pago', widthCol: '15%', label: 'DueDate' },
  { name: 'Registro de pago', widthCol: '15%', label: '' },
  { name: 'Monto pagado', widthCol: '15%', label: '' }
]

export const COLUMN_FILE_DATA = {
  delete: { name: 'Borrar', widthCol: '5%' },
  date: { name: 'Fecha', widthCol: '15%' },
  time: { name: 'Hora', widthCol: '15%' },
  name: { name: 'Nombre de archivo', widthCol: '20%' },
  description: { name: 'Descripción', widthCol: '35%' },
  download: { name: 'Descargar', widthCol: '10%' }
}

export const FILTER_TYPE = {
  CONTRIBUTIONS: 'contributions',
  PAYMENTS: 'payments',
  CONTRIBUTION_PAYMENTS: 'contributionPayments'
}

export const typeAccept = ['.xlsx', '.docx', '.pdf', '.jpeg', '.png', '.txt']

export const COLUMN_CONTRIBUTION_PAYMENTS = [
  { name: 'Código', widthCol: '20%', label: 'Code' },
  { name: 'Concepto', widthCol: '15%', label: 'Concept' },
  { name: 'Origen', widthCol: '15%', label: 'OriginName' },
  { name: 'Divisa', widthCol: '10%', label: 'Currency' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'SBM', widthCol: '10%', label: 'BmsAmount' },
  { name: 'Contribucion', widthCol: '20%', label: 'Contribution' },
  { name: 'Estado', widthCol: '15%', label: 'Status' },
  { name: 'Mes', widthCol: '10%', label: 'Month' },
  { name: 'Año', widthCol: '10%', label: 'Year' }
]
