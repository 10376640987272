import styled from 'styled-components'
import { colors, spacing } from '@global/theme'

export const Container = styled.div`
  width: 100%;
  margin: ${({ margin }) => margin || `${spacing.one} 0`};

  > label {
      margin-left: 0;
      margin-bottom: 8px;
  }

&.react-datepicker__month-container,
 .react-datepicker__header,
 .react-datepicker__current-month,
 .react-datepicker__day-names,
 .react-datepicker__month {
  font-family: 'Roboto', sans-serif !important;
  text-transform: capitalize;
  
  > .react-datepicker__header__dropdown, .react-datepicker__header__dropdown--scroll{
    display: flex;
    justify-content: space-around;
    margin: ${spacing.one};
  }
}

&.react-datepicker__day,
 .react-datepicker__day--selected,
 .react-datepicker__day--keyboard-selected {
  background-color: ${({ color }) => colors[color]?.regular ?? colors.primary.regular};
  border-radius: 50%;
}

&.react-datepicker__day-names, 
.react-datepicker__week {
  > div {
    &:hover {
      background-color: ${({ color }) => colors[color]?.hover ?? colors.primary.hover};
    }
  }
}
.react-datepicker-wrapper {
  display: block !important;
}
&.react-datepicker-wrapper,
.react-datepicker__input-container {
  
  > :focus-visible {
    background-color: ${colors.primary.darker}!important;
    outline-color: ${({ color }) => (color ? colors[color].regular : colors.error.regular)}!important;
  }
  > :hover {
    border-color: ${({ color }) => (color ? colors[color].regular : colors.primary.regular)}!important;
  }
}
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {

   :hover {
    color: ${colors.error.regular};
    background-color: none;
   }
  }
`
