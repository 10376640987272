import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPaymentProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPaymentProfile, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectPaymentProfile, state => get(state, 'paymentProfile', null)
)

const selectPaymentProfileStatuses = createSelector(
  selectPaymentProfile, state => get(state, 'paymentProfileStatuses', null)
)

const selectBanks = createSelector(
  selectPaymentProfile, state => get(state, 'banks', null)
)

const selectError = createSelector(
  selectPaymentProfile, state => get(state, 'error', null)
)

const selectLoadingProfile = createSelector(
  selectPaymentProfile, state => get(state, 'loadingEditProfile', [])
)

const selectListLoading = createSelector(
  selectPaymentProfile, state => get(state, 'listLoading', null)
)

const selectPaymentDetailsSuccess = createSelector(
  selectPaymentProfile, state => get(state, 'paymentDetailsList', [])
)

const selectColumns = createSelector(
  selectPaymentProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectPaymentProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectPaymentProfile, state => get(state, 'totalItems', 0)
)

const selectDataFilters = createSelector(
  selectPaymentProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectPaymentProfile, state => get(state, 'dataFiltersError', null)
)

const selectShowTableLoading = createSelector(
  selectPaymentProfile, state => get(state, 'showTableLoading', null)
)

const selectMessageInformation = createSelector(
  selectPaymentProfile, state => get(state, 'messageInformation', null)
)

const selectOutboundSettlementDetailInformation = createSelector(
  selectPaymentProfile, state => get(state, 'outboundSettlementDetailInformation', null)
)

const selectOutboundSettlementDetailLoading = createSelector(
  selectPaymentProfile, state => get(state, 'outboundSettlementDetailLoading', null)
)

const selectBankAccounts = createSelector(
  selectPaymentProfile, state => get(state, 'bankAccounts', null)
)

const selectPaymentsDetailsError = createSelector(
  selectPaymentProfile, state => get(state, 'errorPaymentsDetails', null)
)

const selectConcepts = createSelector(
  selectPaymentProfile, state => get(state, 'concepts', null)
)

const selectCalculationLoading = createSelector(
  selectPaymentProfile, state => get(state, 'loadingCalculate', null)
)

const selectCalculationInformation = createSelector(
  selectPaymentProfile, state => get(state, 'calculateInformation', null)
)

const selectIsCalculatingInfo = createSelector(
  selectPaymentProfile, state => get(state, 'isCalculatingInfo', null)
)

const selectIsChangingStatusInfo = createSelector(
  selectPaymentProfile, state => get(state, 'isChangingStatusInfo', null)
)

const selectNoBankAccountLoading = createSelector(
  selectPaymentProfile, state => get(state, 'noBankAccountListLoading', false)
)

const selectNoBankAccountList = createSelector(
  selectPaymentProfile, state => get(state, 'noBankAccountList', null)
)

const selectNoBankAccountListError = createSelector(
  selectPaymentProfile, state => get(state, 'noBankAccountListError', null)
)

export default {
  SUCCESS: selectSuccess,
  PAYMENT_PROFILE_STATUSES: selectPaymentProfileStatuses,
  BANKS: selectBanks,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_PROFILE: selectLoadingProfile,

  PAYMENT_DETAILS_SUCCESS: selectPaymentDetailsSuccess,
  PAYMENT_DETAILS_ERROR: selectPaymentsDetailsError,
  COLUMNS: selectColumns,
  LIST_LOADING: selectListLoading,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  SHOW_TABLE_LOADING: selectShowTableLoading,
  MESSAGE_INFORMATION: selectMessageInformation,

  OUTBOUND_SETTLEMENT_DETAIL_INFORMATION: selectOutboundSettlementDetailInformation,
  OUTBOUND_SETTLEMENT_DETAIL_LOADING: selectOutboundSettlementDetailLoading,
  BANK_ACCOUNTS: selectBankAccounts,

  CONCEPTS_INFORMATION: selectConcepts,

  CALCULATION_LOADING: selectCalculationLoading,
  CALCULATION_INFORMATION: selectCalculationInformation,

  IS_CALCULATING_INFO: selectIsCalculatingInfo,
  IS_CHANGING_STATUS_INFO: selectIsChangingStatusInfo,

  NO_BANK_ACCOUNT_LOADING: selectNoBankAccountLoading,
  NO_BANK_ACCOUNT_LIST: selectNoBankAccountList,
  NO_BANK_ACCOUNT_ERROR: selectNoBankAccountListError
}
