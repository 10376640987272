import React from 'react'

import {
  ContainerShortcuts,
  ShortcutItem,
  ShortcutLeftSide,
  ShortcutImg
} from '@global/styles'

import Icon from '@components/icon'
import { Heading } from '@components/texts'
import { spacing } from '@global/theme'
import { useWindowDimensions } from '@components/windowDimensions'

import { dataItems } from './data'

const Finance = () => {
  const { device } = useWindowDimensions()
  return (
    <ContainerShortcuts>
      {
          dataItems.map(item => (
            <ShortcutItem key={item.id} to={item.to} device={device}>
              <ShortcutLeftSide key={item.id} device={device}>
                <ShortcutImg
                  src={item.src}
                  alt='items'
                  width={spacing.four}
                  height={spacing.fiveAndAHalf}
                />
                <Heading size='small' weight='semibold' align='left'>{item.label}</Heading>
              </ShortcutLeftSide>
              <Icon name='trending_flat' backgroundColor='background' size='xlarge' color='primary' />
            </ShortcutItem>
          ))
      }
    </ContainerShortcuts>
  )
}

export default Finance
