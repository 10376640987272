import produce from 'immer'

import { CONTRIBUTION_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  contributionProfile: null,
  contributionProfileStatuses: null,
  loadingEditProfile: false,
  editInformation: null,
  errorMessage: null,
  modalDataInformation: null,
  currencies: null,
  concepts: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case CONTRIBUTION_PROFILE.GET_CONTRIBUTION_PROFILE:
      draft.loading = true
      break
    case CONTRIBUTION_PROFILE.SUCCESS: {
      const { contributionProfileData, statusesData, currencies, concepts } = payload
      draft.loading = false
      draft.contributionProfile = contributionProfileData
      draft.contributionProfileStatuses = statusesData
      draft.currencies = currencies
      draft.concepts = concepts
      break
    }
    case CONTRIBUTION_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case CONTRIBUTION_PROFILE.EDIT_CONTRIBUTION_PROFILE:
      draft.loadingEditProfile = true
      break
    case CONTRIBUTION_PROFILE.EDIT_CONTRIBUTION_PROFILE_INFORMATION:
      draft.editInformation = payload
      draft.loadingEditProfile = false
      break
    case CONTRIBUTION_PROFILE.CONTRIBUTION_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.contributionProfile = payload
      break
    case CONTRIBUTION_PROFILE.CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION:
      draft.modalDataInformation = payload
      break
    case CONTRIBUTION_PROFILE.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
