import { SITE } from '@routes/paths'
import errorActivity from '@assets/log-actividad.png'

export const dataItems = [
  { id: 1,
    label: 'Beneficios',
    src: errorActivity,
    to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.BENEFITS}`
  },
  { id: 2,
    label: 'Categorías de Afiliación',
    src: errorActivity,
    to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.AFFILIATE_CATEGORIES}`
  },
  { id: 3,
    label: 'Categorías de FRM',
    src: errorActivity,
    to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.FRM_CATEGORIES}`
  },
  { id: 4,
    label: 'Divisas',
    src: errorActivity,
    to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.CURRENCIES}`
  },
  { id: 5,
    label: 'Aporte de no empleado',
    src: errorActivity,
    to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.NO_EMPLOYEE_FEE}`
  }
]
