import React from 'react'

import { INPUT_TYPES } from '../utils/constants'

import Input from './baseInput/baseInput'
import NumberI from './components/number'
import Password from './components/password'
import Checkbox from './components/checkbox'
import TextArea from './components/textArea'
import PasswordRepeat from './components/passwordRepeat'

export const NumberInput = props => <NumberI {...props} />
export const PasswordInput = props => <Password {...props} />
export const CheckboxInput = props => <Checkbox {...props} />
export const TextAreaInput = props => <TextArea {...props} />
export const PasswordRepeatInput = props => <PasswordRepeat {...props} />

export const TextInput = props => <Input {...props} type={INPUT_TYPES.TEXT} />
export const EmailInput = props => <Input {...props} type={INPUT_TYPES.EMAIL} />
export const NameInput = props => <Input {...props} type={INPUT_TYPES.NAME} showType={INPUT_TYPES.TEXT} />

export { default as Dropdown } from './dropdown'
