import produce from 'immer'

import { HISTORY } from './types'

export const initialState = {
  loading: true,
  error: false,
  list: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  loadingList: false,
  dataFilters: null,
  dataFiltersError: null,
  filterLoading: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case HISTORY.GET_HISTORY:
      draft.loadingList = true
      break
    case HISTORY.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.loading = false
      draft.loadingList = false
      draft.list = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case HISTORY.ERROR:
      draft.loading = false
      draft.loadingList = false
      draft.error = payload
      break
    case HISTORY.CLEAR:
      return initialState
    case HISTORY.SEND_FILTER:
      draft.loading = true
      break
    case HISTORY.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case HISTORY.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case HISTORY.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case HISTORY.FILTER_DATA_CLEAR:
      draft.dataFilters = null
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
