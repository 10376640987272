import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  TextContainer,
  ButtonContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  TRANSLATION_OF_ENTITIES,
  DEVICE_NAME,
  BREAKPOINTS,
  TIMEOUT_PENDING_PROCESS,
  DETAIL_TYPES,
  EXTRA_PAYMENT
} from '@global/constants'
import {
  TOKEN_HELPERS,
  getMonthName,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  formatDateBasic,
  currencyFormatUY
} from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Modal from '@components/modal'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'
import Filter from '@components/filter'
import Table from '@components/table'
import ProgressBar from '@components/progressBar'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import EditLiquidationStatus from './components/editLiquidationStatusModal'
import AddContributionModal from './components/addContributionModal'

import useRedux from './redux'
import { LIQUIDATION_LOG_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, LiquidationLogData, Tbody, ItemLink } from './styles'
import { columnData } from './data'

const LiquidationProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { liquidationId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { device, deviceName } = useWindowDimensions()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [liquidationLog, setLiquidationLog] = useState(null)
  const [open, setOpen] = useState(false)
  const [editStatusModal, setEditStatusModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [addContributionModal, setAddContributionModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [currencies, setCurrencies] = useState()
  const [errorFlag, setErrorFlag] = useState({
    profile: false,
    contributions: false
  })
  const [parameters, setParameters] = useState()
  const [isChangingStatusState, setIsChangingStatusState] = useState(false)
  const [isCalculatingState, setIsCalculatingState] = useState(false)
  const [progressValue, setProgressValue] = useState(0)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEditProfile = useSelector(SELECTORS.LOADING_EDIT_PROFILE)
  const liquidationLogData = useSelector(SELECTORS.SUCCESS)
  const liquidationLogStatuses = useSelector(SELECTORS.LIQUIDATION_LOG_STATUSES)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const listLoading = useSelector(SELECTORS.LIST_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.CONTRIBUTIONS_SUCCESS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const contributionsError = useSelector(SELECTORS.CONTRIBUTIONS_ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const loadingCalculationContributions = useSelector(SELECTORS.LOADING_CALCULATE_CONTRIBUTIONS)
  const calculationInformation = useSelector(SELECTORS.CALCULATE_CONTRIBUTIONS_INFORMATION)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const currenciesData = useSelector(SELECTORS.CURRENCIES_INFORMATION)
  const isCalculatingInfo = useSelector(SELECTORS.IS_CALCULATING_INFO)
  const isChangingStatusInfo = useSelector(SELECTORS.IS_CHANGING_STATUS_INFO)

  const handleToastClose = () => setIsShowing(false)

  const renderLiquidationLogData = objLiquidationRecordData => (
    <>
      <Tr>
        <Td colorLight widthPer>Mes:</Td>
        <Td largeSize>
          {getMonthName(objLiquidationRecordData.month) || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Año:</Td>
        <Td largeSize>{objLiquidationRecordData.year || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Monto total:</Td>
        <Td largeSize>{`${currencyFormatUY(objLiquidationRecordData.totalAmount)}` || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Estado:</Td>
        <Td largeSize>{
          objLiquidationRecordData.statusId && liquidationLogStatuses
            ? liquidationLogStatuses.find(s => s.id === objLiquidationRecordData.statusId).name
            : 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Fecha de vencimiento:</Td>
        <Td largeSize>{formatDateBasic(objLiquidationRecordData.dueDate, 'short') || 'N/A'}</Td>
      </Tr>
      {objLiquidationRecordData?.isExtraPayment
        && (
        <Tr>
          <Td colorLight widthPer>Tipo:</Td>
          <Td largeSize>{EXTRA_PAYMENT}</Td>
        </Tr>
        )}
      <Tr>
        <Td colorLight widthPer>Descripción:</Td>
        <Td largeSize>{objLiquidationRecordData?.description || 'N/A'}</Td>
      </Tr>
      {isChangingStatusState
            && (
              <Tr>
                <ProgressBar value={progressValue} max={100} marginLeft={spacing.quarter} />
              </Tr>
            )}
    </>
  )

  const getSettlementData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATION_LOG_ACTIONS.GET_LIQUIDATION_LOG({ liquidationId, token }))
      const data = {
        settlementId: liquidationId,
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      setActiveFilters(data)
      dispatch(LIQUIDATION_LOG_ACTIONS.GET_CURRENCIES(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataInProcess = statusId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATION_LOG_ACTIONS.GET_PROCESS_PENDING_INFO({ statusId, liquidationId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributions = body => {
    body = {
      ...body,
      settlementId: liquidationId
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATION_LOG_ACTIONS.GET_LIQUIDATION_CONTRIBUTIONS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getContributions(body)
  }

  const setValueInput = text => {
    const inputSearch = document.getElementById('text_search')
    if (inputSearch) {
      inputSearch.value = text || ''
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditStatusModal(null)
    setAddContributionModal(null)
  }

  const handleSaveLiquidationProfile = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATION_LOG_ACTIONS.EDIT_LIQUIDATION({ liquidationId, data: newProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleEditStatus = () => {
    setModalTitle('Editar Liquidación')
    setEditStatusModal({
      statuses: liquidationLogStatuses,
      oldData: liquidationLog,
      saveData: handleSaveLiquidationProfile,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 8, token }
      dispatch(LIQUIDATION_LOG_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = columnData?.find(col => col.name === nameColumn)?.label
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributions(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributions(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickCloseFilters = () => {
    dispatch(LIQUIDATION_LOG_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleCalculateContributions = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATION_LOG_ACTIONS.CALCULATE_CONTRIBUTIONS({ liquidationId, data: {}, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSaveNewContribution = newContribution => {
    newContribution.settlementId = liquidationId
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const bodyContributions = {
        settlementId: liquidationId,
        pageNumber: 1,
        pageSize: selectedOption.value
      }
      dispatch(LIQUIDATION_LOG_ACTIONS.ADD_CONTRIBUTION({ bodyContributions, data: newContribution, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleAddContribution = () => {
    setModalTitle('Agregar contribución')
    setAddContributionModal({
      saveData: handleSaveNewContribution,
      close: handleCloseModal,
      settlementId: liquidationId,
      currentToken
    })
    setOpen(true)
  }

  const handleContributionClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleContributionPaymentClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handleEntityClick = (entity, entityId, personId) => {
    let url = ''
    switch (entity) {
      case TRANSLATION_OF_ENTITIES.FRMRecord.name:
        url = `${SITE.FRM_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
        url = `${SITE.AFFILIATE_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.Benefit.name:
        url = `${SITE.AFFILIATE_RECORD}/${personId}/${entityId}`
        break
      default:
        url = `${SITE.EMPLOYMENT_RECORD}/${entityId}`
    }
    const win = window.open(url, '_blank')
    win.focus()
  }

  const handleSendToContributionPaymentList = contributionId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${contributionId}/${DETAIL_TYPES.CONTRIBUTION_PAYMENT}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { contributionPayments } = item
      const statusName = item.statusId === 3
        ? (<Text color='error'>{item.status}</Text>)
        : item.status

      const code = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleContributionClick(item.id)}
        >
          {`C - ${item.id}`}
        </ItemLink>
      )

      const person = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handlePersonClick(item.personId)}
        >
          {`${item.billingNumber} - ${item.person}`}
        </ItemLink>
      )

      let link = 'N/A'
      contributionPayments = contributionPayments.filter(cp => cp.statusId === 1)
      if (contributionPayments?.length > 0) {
        link = contributionPayments?.length === 1
          ? (
            <ItemLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleContributionPaymentClick(contributionPayments[0].id)}
            >
              {`DI - ${contributionPayments[0].id}`}
            </ItemLink>
          )
          : (
            <ItemLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToContributionPaymentList(item.id)}
            >
              Ver lista de DI
            </ItemLink>
          )
      }
      let entityId = null
      switch (item.entity) {
        case TRANSLATION_OF_ENTITIES.FRMRecord.name:
          entityId = item.frmRecordId
          break
        case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
          entityId = item.affiliateRecordId
          break
        case TRANSLATION_OF_ENTITIES.EmploymentRecord.name:
          entityId = item.employmentRecordId
          break
        case TRANSLATION_OF_ENTITIES.Benefit.name:
          entityId = item.affiliateRecordId
          break
        default:
          entityId = 0
      }

      const entity = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleEntityClick(item.entity, entityId, item.personId)}
        >
          {`${TRANSLATION_OF_ENTITIES[item.entity]?.label} - ${entityId}`}
        </ItemLink>
      )

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        person,
        concep: item.concept,
        entity,
        currency: item.currencyName || currencies.find(c => c.id === item.currencyId).name,
        total: currencyFormatUY(item.total),
        statusName,
        dueDate: item.dueDate,
        description: item.description,
        link,
        totalPaid
      }
      return newItem
    })
    return newData
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getContributions, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    getSettlementData()
  }, [])

  useEffect(() => {
    setTablelist(customDataTable(items))
    setColumns(columnData)
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
    setShowTable(true)
  }, [items, totalPages])

  useEffect(() => {
    if (liquidationLogData) {
      setLiquidationLog(liquidationLogData)
      if (liquidationLog) {
        if (liquidationLogData.statusId === 1) setValueInput()
      }

      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.LIQUIDATION_LOG} - ${liquidationLogData.id}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url: `${SITE.FINANCE}`
        }
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.LIQUIDATIONS}`,
          link: true,
          url: `${SITE.FINANCE}${SITE.LIQUIDATIONS}`
        }
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.LIQUIDATION_LOG} - ${liquidationLogData.id}`,
          link: false,
          url: `${SITE.FINANCE}${SITE.LIQUIDATIONS}${SITE.LIQUIDATION_LOG}/${liquidationLogData.id}`
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }
    }
  }, [liquidationLogData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.profile) {
        getSettlementData()
        setErrorFlag({
          ...errorFlag,
          profile: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (calculationInformation) {
      const { status } = calculationInformation
      if (status === 200) {
        if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: calculationInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [calculationInformation])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (currenciesData && currenciesData.status === 200) {
      setCurrencies(currenciesData.currenciesObj)
    }
  }, [currenciesData])

  useEffect(() => {
    if (messageInformation) {
      if (messageInformation === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value
        }
        getContributions(body)
      }
      handleCloseModal()
      setIsShowing(true)
      dispatch(LIQUIDATION_LOG_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    if (contributionsError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: contributionsError.message || `Error ${contributionsError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.contributions) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value
        }
        getContributions(body)
        setErrorFlag({
          ...errorFlag,
          contributions: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [contributionsError])

  useEffect(() => {
    let calculatingTimeOut = null
    if (isCalculatingInfo) {
      const { status, isCalculating, progressPercentage } = isCalculatingInfo
      if (status === 200) {
        setIsCalculatingState(isCalculating)
        setProgressValue(progressPercentage * 100)

        if (isCalculating) {
          calculatingTimeOut = setTimeout(() => {
            getDataInProcess(liquidationLog?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }
        if (!isCalculating && !calculatingTimeOut) {
          clearTimeout(calculatingTimeOut)
          const data = activeFilters
            ? { ...activeFilters } : { settlementId: liquidationId, pageNumber: 1, pageSize: selectedOption.value }
          dispatch(LIQUIDATION_LOG_ACTIONS.GET_LIQUIDATION_CONTRIBUTIONS(
            { data, token: currentToken.token }
          ))
        }
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: isCalculatingInfo?.message || `Error ${isCalculating?.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }

    return () => {
      clearTimeout(calculatingTimeOut)
    }
  }, [isCalculatingInfo])

  useEffect(() => {
    let changingStatusTimeOut = null
    if (isChangingStatusInfo) {
      const { status, isChangingStatus, progressPercentage } = isChangingStatusInfo
      if (status === 200) {
        setIsChangingStatusState(isChangingStatus)
        setProgressValue(progressPercentage * 100)
        if (isChangingStatus) {
          changingStatusTimeOut = setTimeout(() => {
            getDataInProcess(liquidationLog?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }

        if (!isChangingStatus && !changingStatusTimeOut) {
          clearTimeout(changingStatusTimeOut)
          if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
        }
      }
    } else {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: isChangingStatusInfo?.message || `Error ${isChangingStatusInfo?.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }

    return () => {
      clearTimeout(changingStatusTimeOut)
    }
  }, [isChangingStatusInfo])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading device={device} columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              {liquidationLog
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`${TRANSLATION_OF_ROUTES.LIQUIDATION_LOG} - ${liquidationLog.id}`}
               </Heading>
               )}
              {breadcrumbs
                && (
                <MyLink
                  color='primary'
                  to={{
                    pathname: `${SITE.HISTORY}${pathname}`,
                    state: { breadcrumbs }
                  }}
                  textDecoration='underline'
                >
                  Historial de Actividad
                </MyLink>
                )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEditProfile
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <LiquidationLogData>
                      <Tbody>
                        {liquidationLog && renderLiquidationLogData(liquidationLog)}
                      </Tbody>
                    </LiquidationLogData>
                    <LinkContainer flex={2}>
                      <MyButtonLink onClick={handleEditStatus} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}

            </DataContainer>
            {loadingCalculationContributions
              ? (
                <WrapperLoading>
                  <Loading color='primary' size='xsmall' weight='bold' />
                </WrapperLoading>
              )
              : (
                <>
                  {!showTable
                    ? (
                      <WrapperLoading>
                        <Loading color='primary' size='xsmall' weight='bold' />
                      </WrapperLoading>
                    ) : (
                      <>
                        <TextContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
                          <Text size='large' weight='bold' align='left'>Contribuciones</Text>
                        </TextContainer>
                        <ButtonContainer
                          columnStart={1}
                          columnEnd={12}
                          rowStart={5}
                          rowEnd={5}
                          justifyContent='flex-start'
                          marginTop={spacing.two}
                          flexDirection={device === BREAKPOINTS.MOBILE ? 'column-reverse' : 'row'}
                          alignItems='flex-start'
                          device={device}
                        >
                          <GhostButton
                            color='primary'
                            size='small'
                            text='Calcular contribuciones'
                            icon='format_list_numbered'
                            onClick={handleCalculateContributions}
                            block={deviceName === DEVICE_NAME.MOBILE}
                          />
                          {isCalculatingState
                     && (
                       <ProgressBar value={progressValue} max={100} />
                     )}
                        </ButtonContainer>
                        <FilterContainer columnStart={1} columnEnd={12} rowStart={6} rowEnd={6} deviceName={deviceName}>
                          <FilterContainerLeftSide deviceName={deviceName}>
                            {showTable
                              && (
                              <GhostButton
                                color='tertiary'
                                size='small'
                                text='Agregar Filtro'
                                icon='filter_alt'
                                onClick={handleClickShowFilters}
                              />
                              )}
                            {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                            {showFilter && (filterData.length > 0 && renderFilter(filterData))}
                          </FilterContainerLeftSide>
                          <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                            {showTable && (
                            <SearchBar
                              onChange={handleInputChange}
                              visible={activeFilters?.filterText}
                              value={activeFilters?.filterText}
                            />
                            )}
                            <GhostButton
                              color='tertiary'
                              size='small'
                              text='Agregar Contribución +'
                              onClick={handleAddContribution}
                            />
                          </FilterContainerRightSide>
                        </FilterContainer>
                        <TableWrapper columnStart={1} columnEnd={12} rowStart={7} rowEnd={7}>
                          { renderTable({
                            currentList: tablelist,
                            columnsN: columns,
                            currentLoading: listLoading,
                            handleOrder: handleOrderChange,
                            error: !!errorMessage,
                            showRowPointer: true
                          })}
                        </TableWrapper>
                      </>
                    )}
                </>
              )}
          </>
        )}
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={handleToastClose}
      />
      <Modal
        isShowing={open}
        close={handleCloseModal}
        title={modalTitle}
        minHeight='auto'
      >
        {editStatusModal && (<EditLiquidationStatus {...editStatusModal} />)}
        {addContributionModal && (<AddContributionModal {...addContributionModal} />)}
      </Modal>
    </>
  )
}

export default LiquidationProfile
