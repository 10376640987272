import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectExports = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectExports, state => get(state, 'loading', null)
)

const selectListLoading = createSelector(
  selectExports, state => get(state, 'listLoading', null)
)

const selectSuccess = createSelector(
  selectExports, state => get(state, 'exportsList', [])
)

const selectColumns = createSelector(
  selectExports, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectExports, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectExports, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectExports, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectExports, state => get(state, 'dataFilters', [])
)

const selectDataFiltersErrorError = createSelector(
  selectExports, state => get(state, 'dataFiltersError', null)
)

const selectModalDataLoding = createSelector(
  selectExports, state => get(state, 'modalDataLoding', null)
)

const selectModalData = createSelector(
  selectExports, state => get(state, 'modalData', null)
)

const selectMessageInformation = createSelector(
  selectExports, state => get(state, 'messageInformation', null)
)

const selectExportSuccess = createSelector(
  selectExports, state => get(state, 'exportObj', null)
)

const selectJobTypes = createSelector(
  selectExports, state => get(state, 'jobTypes', null)
)

const selectOutboundPayment = createSelector(
  selectExports, state => get(state, 'outboundPayment', null)
)

const selectConcepts = createSelector(
  selectExports, state => get(state, 'concepts', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LIST_LOADING: selectListLoading,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersErrorError,
  MODAL_DATA_LOADING: selectModalDataLoding,
  MODAL_DATA: selectModalData,
  MESSAGE_INFORMATION: selectMessageInformation,
  EXPORT_SUCCESS: selectExportSuccess,
  JOBS_TYPES: selectJobTypes,
  OUTBOUND_PAYMENT: selectOutboundPayment,
  CONCEPTS_SUCCESS: selectConcepts
}
