import axios from 'axios'
import { MESSAGES } from '@global/message'

const Axios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

Axios.interceptors.response.use(
  response => {
    const { data, status, headers } = response
    return { data, status, headers }
  },
  error => {
    const { data, status } = error.response
    let message = ''
    if (status === 500) {
      message = MESSAGES.SERVER_ERROR
    } else {
      const { detail } = data
      message = detail || data?.title
    }
    const errorObj = { status, message }
    throw errorObj
  }
)

export default Axios
