import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectLiquidations = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectLiquidations, state => get(state, 'loading', null)
)

const selectLoadingLiquidations = createSelector(
  selectLiquidations, state => get(state, 'liquidationsLoading', null)
)

const selectSuccess = createSelector(
  selectLiquidations, state => get(state, 'liquidationsList', [])
)

const selectColumns = createSelector(
  selectLiquidations, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectLiquidations, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectLiquidations, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectLiquidations, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectLiquidations, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectLiquidations, state => get(state, 'dataFiltersError', null)
)

const selectLiquidationStatuses = createSelector(
  selectLiquidations, state => get(state, 'liquidationStatuses', [])
)

const selectMessageInformation = createSelector(
  selectLiquidations, state => get(state, 'messageInformation', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingLiquidations,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  LIQUIDATIONS_STATUSES: selectLiquidationStatuses,
  ADD_LIQUIDATION_INFORMATION: selectMessageInformation
}
