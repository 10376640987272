export const key = 'AFFILIATE_CATEGORIES'

export const AFFILIATE_CATEGORIES = {
  GET: 'AFFILIATE_CATEGORIES/GET_LIST',
  SUCCESS: 'AFFILIATE_CATEGORIES/GET_LIST/SUCCESS',
  ERROR: 'AFFILIATE_CATEGORIES/GET_LIST/ERROR',
  CLEAR: 'AFFILIATE_CATEGORIES/CLEAR_LIST',

  SEND_FILTER: 'AFFILIATE_CATEGORIES/FILTER/SEND',
  GET_FILTER_DATA: 'AFFILIATE_CATEGORIES/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'AFFILIATE_CATEGORIES/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'AFFILIATE_CATEGORIES/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'AFFILIATE_CATEGORIES/FILTER/CLEAR',

  SET_AFFILIATE_CATEGORY: 'AFFILIATE_CATEGORIES/SET_AFFILIATE_CATEGORY',
  AFFILIATE_CATEGORY_INFORMATION: 'AFFILIATE_CATEGORIES/AFFILIATE_CATEGORY_INFORMATION',
  AFFILIATE_CATEGORY_INFORMATION_CLEAR: 'AFFILIATE_CATEGORIES/AFFILIATE_CATEGORY_INFORMATION_CLEAR'
}

export default key
