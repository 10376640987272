import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectExportProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectExportProfile, state => get(state, 'loading', false)
)

const selectExportSuccess = createSelector(
  selectExportProfile, state => get(state, 'exportProfile', null)
)

const selectStausesSuccess = createSelector(
  selectExportProfile, state => get(state, 'statuses', null)
)

const selectError = createSelector(
  selectExportProfile, state => get(state, 'error', null)
)

const selectFileDownloaded = createSelector(
  selectExportProfile, state => get(state, 'fileDownloaded', null)
)

const selectLoadingDownload = createSelector(
  selectExportProfile, state => get(state, 'loadingDownload', null)
)

const selectLoadingTable = createSelector(
  selectExportProfile, state => get(state, 'loadingTableLogs', null)
)

const selectSuccess = createSelector(
  selectExportProfile, state => get(state, 'logsList', [])
)

const selectColumns = createSelector(
  selectExportProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectExportProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectExportProfile, state => get(state, 'totalItems', 0)
)

const selectDataFilters = createSelector(
  selectExportProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectExportProfile, state => get(state, 'dataFiltersError', null)
)

const selectExportError = createSelector(
  selectExportProfile, state => get(state, 'errorLog', null)
)

const selectExportProfileLoading = createSelector(
  selectExportProfile, state => get(state, 'exportProfileLoading', null)
)

const selectPendingProcessInfo = createSelector(
  selectExportProfile, state => get(state, 'pendingProcessInfo', null)
)

export default {
  EXPORT_SUCCESS: selectExportSuccess,
  ERROR: selectError,
  STATUSES: selectStausesSuccess,
  LOADING: selectLoading,
  FILE_DOWNLOADED: selectFileDownloaded,
  LOADING_DOWNLOAD: selectLoadingDownload,
  LOADING_TABLE_LOGS: selectLoadingTable,

  GET_LOGS_SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  EXPORT_ERROR: selectExportError,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  EXPORT_PROFILE_LOADING: selectExportProfileLoading,
  PENDING_PROCESS_INFO: selectPendingProcessInfo
}
