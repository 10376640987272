import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Benefits from '@containers/benefits'
import EntityManagment from '@containers/entityManagement'
import FrmCategories from '@containers/frmCategories'
import AffiliateCategories from '@containers/affiliateCategories'
import Currencies from '@containers/currencies'
import CurrencyProfile from '@containers/currencyProfile'
import NoEmployeeFeePage from '@containers/noEmployeeFeePage'

export const EntityManagmentRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${url}`}
        component={EntityManagment}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.BENEFITS}`}
        component={Benefits}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.FRM_CATEGORIES}`}
        component={FrmCategories}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.AFFILIATE_CATEGORIES}`}
        component={AffiliateCategories}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.CURRENCIES}`}
        component={Currencies}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.CURRENCIES}${SITE.CURRENCY_PROFILE}/:currencyId`}
        component={CurrencyProfile}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.NO_EMPLOYEE_FEE}`}
        component={NoEmployeeFeePage}
      />
    </Switch>
  )
}
