import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { spacing } from '@global/theme'
import { BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import CustomDatePicker from '@components/datePicker'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { useWindowDimensions } from '@components/windowDimensions'

const AddAndEditCurrencyValue = ({ functionType, oldData, saveData }) => {
  const { device } = useWindowDimensions()

  const [currencyValue, setCurrencyValue] = useState({
    name: '',
    value: oldData?.value || '',
    error: ''
  })
  const [date, setDate] = useState(oldData?.date || new Date())
  const [showButton, setShowButton] = useState(true)

  const handleChange = val => setCurrencyValue(val)

  const clearStates = () => {
    setCurrencyValue({
      name: '',
      value: '',
      error: ''
    })
  }

  const handleClick = () => {
    let dateNotHours = oldData ? new Date(oldData?.date) : date
    dateNotHours = new Date(dateNotHours.setHours(0, 0, 0, 0))

    const newCurrencyValue = {
      id: oldData?.id,
      value: currencyValue.value,
      date: dateNotHours
    }
    saveData(newCurrencyValue, functionType)
    clearStates()
  }

  const errorControl = () => {
    if ((currencyValue.value && !currencyValue.error)
        && date) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(() => {
    errorControl()
  }, [currencyValue, date])

  return (
    <ModalDataContainer flexDirection='column'>
      <CustomDatePicker
        name='startDate'
        title='Fecha de ingreso'
        color='primary'
        date={date}
        onChange={setDate}
        yearsToShow={100}
        justifyContentButton='space-between'
        block
      />
      <Input
        type={INPUT_TYPES.NUMBER}
        showType={INPUT_TYPES.TEXT}
        label='Valor'
        placeholder='Ingrese valor'
        name='contributionPerc'
        value={currencyValue.value}
        error={currencyValue.error}
        height='small'
        color='primary'
        onChange={handleChange}
        onBlur={() => {}}
        bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
      />
      <ButtonsContainer justifyContent='flex-end' marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonsContainer>
    </ModalDataContainer>
  )
}

export default AddAndEditCurrencyValue

AddAndEditCurrencyValue.propTypes = {
  functionType: PropTypes.string,
  oldData: PropTypes.object,
  saveData: PropTypes.func
}
