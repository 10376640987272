export const key = 'DEPOSIT_PROFILE'

export const DEPOSIT_PROFILE = {
  GET_DEPOSIT_PROFILE: 'DEPOSIT_PROFILE/GET_DATA',
  SUCCESS: 'DEPOSIT_PROFILE/GET_SUCCESS',
  ERROR: 'DEPOSIT_PROFILE/GET_ERROR',
  CLEAR: 'DEPOSIT_PROFILE/CLEAR',

  EDIT_DEPOSIT_PROFILE: 'DEPOSIT_PROFILE/PROFILE/EDIT',
  EDIT_DEPOSIT_PROFILE_INFORMATION: 'DEPOSIT_PROFILE/PROFILE/EDIT/INFORMATION',
  DEPOSIT_PROFILE_UPDATED: 'DEPOSIT_PROFILE/PROFILE/EDIT/UPDATED',

  GET_CONTRIBUTIONS_PAYMENTS: 'DEPOSIT_PROFILE/CONTRIBUTIONS_PAYMENTS/GET_LIST',
  CONTRIBUTIONS_PAYMENTS_SUCCESS: 'DEPOSIT_PROFILE/GET_LIST/CONTRIBUTIONS_PAYMENTS_SUCCESS',
  CONTRIBUTIONS_PAYMENTS_ERROR: 'DEPOSIT_PROFILE/GET_LIST/CONTRIBUTIONS_PAYMENTS_ERROR',
  CONTRIBUTIONS_PAYMENTS_UPDATE: 'DEPOSIT_PROFILE/CONTRIBUTIONS_PAYMENTS/UPDATE',

  SEND_FILTER: 'DEPOSIT_PROFILE/FILTER/SEND',
  GET_FILTER_DATA: 'DEPOSIT_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'DEPOSIT_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'DEPOSIT_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'DEPOSIT_PROFILE/FILTER/CLEAR',

  GET_MODAL_DATA: 'DEPOSIT_PROFILE/MODAL/GET_DATA',
  MODAL_DATA_INFORMATION: 'DEPOSIT_PROFILE/MODAL/DATA_INFORMATION',
  ADD_CONTRIBUTION_PAYMENT: 'DEPOSIT_PROFILE/CONTRIBUTION_PAYMENT/ADD',
  ADD_CONTRIBUTION_PAYMENT_INFORMATION: 'DEPOSIT_PROFILE/CONTRIBUTION_PAYMENT/ADD/INFORMATION',
  MESSAGE_INFORMATION_CLEAR: 'DEPOSIT_PROFILE/MESSAGE_INFORMATION/CLEAR',

  DEPOSIT_PROFILE_GET_CONTRIBUTION: 'DEPOSIT_PROFILE/CONTRIBUTION/GET_LIST',
  DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION: 'DEPOSIT_PROFILE/CONTRIBUTION/GET_LIST/INFORMATION',

  CURRENCIES_INFORMATION: 'DEPOSIT_PROFILE/CURRENCIES/INFORMATION',

  PENDING_PROCESS_INFO: 'DEPOSIT_PROFILE/PENDING_PROCES/INFO',
  GET_PENDING_PROCESS_INFO: 'DEPOSIT_PROFILE/GET_PENDING_PROCESS/INFO',

  GET_PERSON_CONCEPTS: 'DEPOSIT_PROFILE/GET_PERSON_CONCEPTS',
  PERSON_CONCEPT_DATA_INFORMATION: 'DEPOSIT_PROFILE/PERSON_CONCEPT_DATA_INFORMATION'

}

export default key
