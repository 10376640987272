export const MESSAGES = {
  FIELD_EMPTY: 'El campo de texto esta vacio.',
  BASIC_ERROR: 'Hubo un error. Por favor intente nuevamente.',
  INVALID_CREDENTIALS: 'Credenciales invalidas.',
  PASSWORD_CONFIRM_SUCCESS: 'La contraseña se ha modificado con éxito.',
  PASSWORD_CONFIRM_ERROR: 'Hubo un error en el proceso. Intente nuevamente.',
  EXPIRED_TOKEN: 'Su ha Token expirado.',
  ERROR_MINOR_FROM_DATE: 'La fecha de inicio no puede ser posterior a la fecha fin.',
  NO_DATA: 'No hemos podido encontrar registros.',
  SUCCESSFUL_ACTION: 'Acción éxitosa.',
  FAILED_ACTION: 'Acción fallida.',
  SUCCESSFULLY_CREATED_PERSON: 'Persona creada con éxito.',
  SUCCESSFULLY_CREATED_USER: 'Usuario creado con éxito.',
  PASSWORD_MODIFIED_SUCCESSFULLY: 'Contraseña modificada con éxito.',
  USER_PROFILE_MODIFIED_SUCCESSFULLY: 'Perfil modificado con éxito.',
  UPLOAD_FILE_ERROR: 'Hubo un error en la carga del archivo.',
  UPLOAD_FILE_SUCCESS: 'Archivo cargado con éxito.',
  SUCCESSFULLY_CREATED_BENEFIT: 'Beneficio creado con éxito.',
  GENERIC_ERROR: 'Hubo un problema. Intente mas tarde.',
  SETTLEMENT_ALREADY_EXISTS: 'Ya existe una liquidación con esos datos.',
  GENERIC_SUCCESSFUL_CREATION: 'Creación exitosa.',
  NO_DATA_FOUND: 'No se encontraron datos.',
  NO_ACTIVE_RECORDS: 'No existen registros de Afiliación y FRM activos',
  ALL_CONTRIBTION_MADE: 'Ya se crearon todas las contribuciones para esta liquidación',
  NO_ADDRESSES: 'No existe ningún registro de dirección',
  NO_PHONES: 'No existe ningún registro de teléfono',
  NO_EMAILS: 'No existe ningún registro de email',
  NO_BANK_ACCOUNT: 'No existe ningún registro de cuenta bancaria',
  SERVER_ERROR: 'Hubo un problema en el servidor. Intente nuevamente.',
  ENTITY_NO_EXIST: 'La entidad no existe.',
  ERROR_DUE_DATE_MINOR: 'La fecha de vencimiento no puede ser menor a la seleccionada',
  IS_PROCESSING: 'Procesando datos',
  FEE_CREATED: 'Nuevo aporte creado con éxito.'
}
