import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPaymentSettlementProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'paymentSettlementProfile', null)
)

const selectPaymentSettlementProfileStatuses = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'paymentSettlementProfileStatuses', null)
)

const selectError = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'error', null)
)

const selectLoadingProfile = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'loadingEditProfile', [])
)

const selectListLoading = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'listLoading', null)
)

const selectDebitsSuccess = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'debitsList', [])
)

const selectColumns = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'totalItems', 0)
)

const selectDataFilters = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'dataFiltersError', null)
)

const selectShowTableLoading = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'showTableLoading', null)
)

const selectModalData = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'modalData', null)
)

const selectMessageInformation = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'messageInformation', null)
)

const selectEntitiesInformation = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'entitiesInformation', null)
)

const selectServicesInformation = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'servicesInformation', null)
)

const selectPersonInformation = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'personInformation', null)
)

const selectDebitsStatuses = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'debitsStatuses', null)
)

const selectCurrencies = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'currencies', null)
)

const selectLoadingCalculateDetails = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'loadingCalculateDebits', null)
)

const selectCalculateDetailsInformation = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'calculateInformation', null)
)

const selectIsCalculatingInfo = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'isCalculatingInfo', null)
)

const selectIsChangingStatusInfo = createSelector(
  selectPaymentSettlementProfile, state => get(state, 'isChangingStatusInfo', null)
)

export default {
  SUCCESS: selectSuccess,
  PAYMENT_SETTLEMENT_PROFILE_STATUSES: selectPaymentSettlementProfileStatuses,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_PROFILE: selectLoadingProfile,

  DEBITS_SUCCESS: selectDebitsSuccess,
  COLUMNS: selectColumns,
  LIST_LOADING: selectListLoading,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  DEBITS_STATUSES: selectDebitsStatuses,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  SHOW_TABLE_LOADING: selectShowTableLoading,
  MODAL_DATA_INFORMATION: selectModalData,
  MESSAGE_INFORMATION: selectMessageInformation,

  ENTITIES_INFORMATION: selectEntitiesInformation,
  SERVICES_INFORMATION: selectServicesInformation,
  PERSON_INFORMATION: selectPersonInformation,
  CURRENCIES_INFORMATION: selectCurrencies,

  LOADING_CALCULATE_DETAILS: selectLoadingCalculateDetails,
  CALCULATE_DETAILS_INFORMATION: selectCalculateDetailsInformation,

  IS_CALCULATING_INFO: selectIsCalculatingInfo,
  IS_CHANGING_STATUS_INFO: selectIsChangingStatusInfo
}
