import produce from 'immer'

import { CURRENCIES } from './types'

export const initialState = {
  loading: null,
  error: null,
  currencies: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case CURRENCIES.GET_CURRENCIES:
      draft.loading = true
      break
    case CURRENCIES.SUCCESS:
      draft.loading = false
      draft.currencies = payload
      break
    case CURRENCIES.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case CURRENCIES.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
