import { CURRENCY_PROFILE } from './types'

export const getCurrencyProfile = payload => ({
  type: CURRENCY_PROFILE.GET_CURRENCY_PROFILE,
  payload
})

export const getCurrencyProfileSuccess = payload => ({
  type: CURRENCY_PROFILE.CURRENCY_PROFILE_SUCCESS,
  payload
})

export const getCurrencyValues = payload => ({
  type: CURRENCY_PROFILE.GET_CURRENCY_VALUES,
  payload
})

export const getCurrencyValuesSuccess = payload => ({
  type: CURRENCY_PROFILE.CURRENCY_VALUES_SUCCESS,
  payload
})

export const dataReady = () => ({
  type: CURRENCY_PROFILE.DATA_READY
})

export const getError = payload => ({
  type: CURRENCY_PROFILE.ERROR,
  payload
})

export const setCurrencyValue = payload => ({
  type: CURRENCY_PROFILE.SET_CURRENCY_VALUE,
  payload
})

export const messageInformation = payload => ({
  type: CURRENCY_PROFILE.MESSAGE_INFORMATION,
  payload
})

export const messageInformationClear = () => ({
  type: CURRENCY_PROFILE.MESSAGE_INFORMATION_CLEAR
})

export const getFilterData = payload => ({
  type: CURRENCY_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: CURRENCY_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: CURRENCY_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: CURRENCY_PROFILE.FILTER_DATA_CLEAR
})

export const CURRENCY_PROFILE_ACTIONS = {
  GET_CURRENCY_PROFILE: getCurrencyProfile,
  CURRENCY_PROFILE_SUCCESS: getCurrencyProfileSuccess,

  GET_CURRENCY_VALUES: getCurrencyValues,
  CURRENCY_VALUES_SUCCESS: getCurrencyValuesSuccess,

  DATA_READY: dataReady,
  GET_ERROR: getError,

  SET_CURRENCY_VALUE: setCurrencyValue,

  MESSAGE_INFORMATION: messageInformation,
  MESSAGE_INFORMATION_CLEAR: messageInformationClear,

  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData

}
