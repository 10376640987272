import React from 'react'
import PropTypes from 'prop-types'

import { TYPE_CHARTS } from '@global/constants'

import { Text } from '@components/texts'

import { Container, MenuOptions } from './styles'
import { DoughnutChart, LineChart, HorizontalBarChart } from './components'

const Chart = ({ title, chartType, values, minHeight, children }) => {
  const renderSwitch = type => {
    switch (type) {
      case TYPE_CHARTS.DOUGHNUT:
        return <DoughnutChart values={values} />
      case TYPE_CHARTS.LINE:
        return <LineChart values={values} />
      case TYPE_CHARTS.HORIZONTAL_BAR_CHART:
        return <HorizontalBarChart values={values} />
      default:
        break
    }
  }

  return (
    <Container minHeight={minHeight}>
      <Text size='medium' weight='bold'>{title}</Text>
      {renderSwitch(chartType)}
      <MenuOptions>
        {children}
      </MenuOptions>
    </Container>
  )
}

Chart.propTypes = {
  title: PropTypes.string,
  chartType: PropTypes.string,
  values: PropTypes.any,
  minHeight: PropTypes.string,
  children: PropTypes.node
}

export default Chart
