import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { LIQUIDATION_LOG_ACTIONS } from './actions'
import { LIQUIDATION_LOG } from './types'

const apiCalls = {
  getLiquidationProfile: ({ liquidationId, token }) => (
    Axios.get(`Settlement/${liquidationId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getLiquidationProfileStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ liquidationId, data, token }) => (
    Axios.post(`Settlement/${liquidationId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  calculateContributions: ({ liquidationId, data, token }) => (
    Axios.post(`Settlement/${liquidationId}/calculate`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributions: ({ data, token }) => (
    Axios.post('Contribution/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createContribution: ({ data, token }) => (
    Axios.post('Contribution/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPerson: ({ personId, token }) => (
    Axios.get(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributionByPerson: ({ data, token }) => (
    Axios.post('Contribution/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    })
}

export function* getLiquidationProfile ({ payload }) {
  try {
    const { token } = payload
    const { status, data: liquidationLogObj } = yield call(apiCalls.getLiquidationProfile, payload)
    const { isCalculating, isChangingStatus, progressPercentage } = liquidationLogObj

    yield put(LIQUIDATION_LOG_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    const { data: statuses } = yield call(apiCalls.getLiquidationProfileStatuses, token)
    yield put(LIQUIDATION_LOG_ACTIONS.SUCCESS({ liquidationLogObj, statuses }))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.ERROR(error))
  }
}

export function* editLiquidationProfile ({ payload }) {
  try {
    const { liquidationId, data, token } = payload
    const { statusId } = data

    const { status: statusEditProfile } = yield call(apiCalls.editProfile, payload)
    const { status, data: liquidationProfileUpdated } = yield call(
      apiCalls.getLiquidationProfile, { liquidationId, token }
    )
    const { isCalculating, isChangingStatus, progressPercentage } = liquidationProfileUpdated

    yield put(LIQUIDATION_LOG_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))
    yield put(LIQUIDATION_LOG_ACTIONS.EDIT_LIQUIDATION_INFORMATION(statusEditProfile))
    // id 1 is approved, the contribution table should be updated

    yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_PROFILE_UPDATED(liquidationProfileUpdated))
    if (statusId === 1 && !isChangingStatus) {
      yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_CONTRIBUTIONS_UPDATE())
      const body = {
        pageNumber: 1,
        pageSize: 15,
        settlementId: liquidationId
      }
      const { data: contributionsUpdated } = yield call(apiCalls.getContributions, { data: body, token })
      yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_CONTRIBUTIONS_SUCCESS(contributionsUpdated))
    }
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.EDIT_LIQUIDATION_INFORMATION(error))
  }
}

export function* calculateContributions ({ payload }) {
  try {
    const { status: statusCalculate } = yield call(apiCalls.calculateContributions, payload)
    const { status, data: liquidationLogObj } = yield call(apiCalls.getLiquidationProfile, payload)
    const { isCalculating, isChangingStatus, progressPercentage } = liquidationLogObj

    yield put(LIQUIDATION_LOG_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(LIQUIDATION_LOG_ACTIONS.CALCULATE_CONTRIBUTIONS_INFORMATION({ status: statusCalculate }))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.CALCULATE_CONTRIBUTIONS_INFORMATION(error))
  }
}

export function* getContributions ({ payload }) {
  try {
    const { data: contributions } = yield call(apiCalls.getContributions, payload)

    yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_CONTRIBUTIONS_SUCCESS(contributions))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_CONTRIBUTIONS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(LIQUIDATION_LOG_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalDataLiquidationLog ({ payload }) {
  try {
    const { personId, data, token } = payload
    const { data: conceptsData } = yield call(apiCalls.getPerson, { personId, token })
    const res = yield call(apiCalls.getContributionByPerson, { data, token })

    const contributionsData = res.data.items
    const modalDataObj = { conceptsData, contributionsData }

    yield put(LIQUIDATION_LOG_ACTIONS.MODAL_DATA_INFORMATION({ status: 200, modalDataObj }))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.MODAL_DATA_INFORMATION(error))
  }
}

export function* addContribution ({ payload }) {
  try {
    const { bodyContributions, data, token } = payload
    const { status } = yield call(apiCalls.createContribution, { data, token })
    const { data: contributionsUpdated } = yield call(apiCalls.getContributions, { data: bodyContributions, token })

    yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_CONTRIBUTIONS_SUCCESS(contributionsUpdated))
    yield put(LIQUIDATION_LOG_ACTIONS.ADD_CONTRIBUTION_INFORMATION(status))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.ADD_CONTRIBUTION_INFORMATION(error))
  }
}

export function* getCurrencies ({ payload }) {
  try {
    const { status, data: currenciesObj } = yield call(apiCalls.getCurrencies, payload)

    yield put(LIQUIDATION_LOG_ACTIONS.CURRENCIES_INFORMATION({ status, currenciesObj }))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.CURRENCIES_INFORMATION(error))
  }
}

export function* getPendingProcessInfo ({ payload }) {
  try {
    const { statusId } = payload
    const { status, data: liquidationLogObj } = yield call(apiCalls.getLiquidationProfile, payload)
    const { isCalculating, isChangingStatus, progressPercentage } = liquidationLogObj

    if (statusId !== liquidationLogObj?.statusId && !isChangingStatus) {
      yield put(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_PROFILE_UPDATED(liquidationLogObj))
    }
    yield put(LIQUIDATION_LOG_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))
  } catch (error) {
    yield put(LIQUIDATION_LOG_ACTIONS.PENDING_PROCESS_INFO(error))
  }
}

export default function* saga () {
  yield takeLatest(LIQUIDATION_LOG.GET_LIQUIDATION_LOG, getLiquidationProfile)
  yield takeLatest(LIQUIDATION_LOG.EDIT_LIQUIDATION_PROFILE, editLiquidationProfile)
  yield takeLatest(LIQUIDATION_LOG.CALCULATE_CONTRIBUTIONS, calculateContributions)
  yield takeLatest(LIQUIDATION_LOG.GET_LIQUIDATION_LOG_CONTRIBUTIONS, getContributions)
  yield takeLatest(LIQUIDATION_LOG.GET_FILTER_DATA, filterData)
  yield takeLatest(LIQUIDATION_LOG.GET_PERSON_CONCEPTS, getModalDataLiquidationLog)
  yield takeLatest(LIQUIDATION_LOG.ADD_CONTRIBUTION, addContribution)
  yield takeLatest(LIQUIDATION_LOG.GET_CURRENCIES, getCurrencies)
  yield takeLatest(LIQUIDATION_LOG.GET_PENDING_PROCESS_INFO, getPendingProcessInfo)
}
