import produce from 'immer'

import { HISTORY_RECORD } from './types'

export const initialState = {
  loading: null,
  error: null,
  historyRecord: null,
  loadingFile: null,
  fileMessage: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case HISTORY_RECORD.GET_HISTORY_RECORD:
      draft.loading = true
      break
    case HISTORY_RECORD.SUCCESS:
      draft.loading = false
      draft.historyRecord = payload
      break
    case HISTORY_RECORD.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case HISTORY_RECORD.CLEAR:
      return initialState
    case HISTORY_RECORD.DOWNLOAD_FILE:
      draft.loadingFile = true
      break
    case HISTORY_RECORD.FILE_MESSAGE:
      draft.fileMessage = payload
      draft.loadingFile = false
      break
    default:
      return initialState
  }
})

export default reducer
