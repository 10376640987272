export const layoutProps = {
  rows: ['1fr'],
  columns: ['1fr'],
  gap: '24px',
  alignItems: 'start',
  height: 'min-content',
  columnStart: 1,
  columnEnd: 12
}

export const columnData = {
  Id: { name: 'Código', widthCol: '10%' },
  JobType: { name: 'Tipo de importacion', widthCol: '30%' },
  DateStarted: { name: 'Fecha de inicio', widthCol: '30%' },
  DateFinished: { name: 'Fecha de fin', widthCol: '30%' },
  Status: { name: 'Estado', widthCol: '20%' }
}

export const options = [
  { id: 1, label: 'Filas por páginas: 15', value: 15 },
  { id: 2, label: 'Filas por páginas: 30', value: 30 },
  { id: 3, label: 'Filas por páginas: 45', value: 45 }
]

export const typeAccept = ['.xlsx']
