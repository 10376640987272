import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPerson = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPerson, state => get(state, 'loading', false)
)

const selectSuccess = createSelector(
  selectPerson, state => get(state, 'person', {})
)

const selectError = createSelector(
  selectPerson, state => get(state, 'error', null)
)

const selectPhonesSuccess = createSelector(
  selectPerson, state => get(state, 'phones', null)
)

const selectAddressesSuccess = createSelector(
  selectPerson, state => get(state, 'addresses', null)
)

const selectNotesSuccess = createSelector(
  selectPerson, state => get(state, 'notes', null)
)

const selectEmailsSuccess = createSelector(
  selectPerson, state => get(state, 'emails', null)
)

const selectBanksAccountsSuccess = createSelector(
  selectPerson, state => get(state, 'bankAccounts', null)
)

const selectFilesSuccess = createSelector(
  selectPerson, state => get(state, 'files', null)
)

const selectFileMessage = createSelector(
  selectPerson, state => get(state, 'fileMessage', null)
)

const selectEmployeeRecordsSuccess = createSelector(
  selectPerson, state => get(state, 'employeeRecords', null)
)

const selectInformationMessage = createSelector(
  selectPerson, state => get(state, 'informationMessage', null)
)

const selectTypes = createSelector(
  selectPerson, state => get(state, 'types', null)
)

const selectDepartments = createSelector(
  selectPerson, state => get(state, 'departments', null)
)

const selectBanks = createSelector(
  selectPerson, state => get(state, 'banks', null)
)

const selectCurrencies = createSelector(
  selectPerson, state => get(state, 'currencies', null)
)

const selectLoadingAddresses = createSelector(
  selectPerson, state => get(state, 'loadingAddresses', null)
)

const selectLoadingPhones = createSelector(
  selectPerson, state => get(state, 'loadingPhones', null)
)

const selectLoadingEmails = createSelector(
  selectPerson, state => get(state, 'loadingEmails', null)
)

const selectLoadingBanksAccounts = createSelector(
  selectPerson, state => get(state, 'loadingBanksAccounts', null)
)

const selectLoadingNotes = createSelector(
  selectPerson, state => get(state, 'loadingNotes', null)
)

const selectLoadingProfile = createSelector(
  selectPerson, state => get(state, 'loadingProfile', null)
)

const selectLoadingFile = createSelector(
  selectPerson, state => get(state, 'loadingFile', null)
)

const selectEmploymentRecord = createSelector(
  selectPerson, state => get(state, 'employmentRecord', null)
)

const selectActiveAffiliateRecord = createSelector(
  selectPerson, state => get(state, 'activeAffiliateRecord', null)
)

const selectFrmRecord = createSelector(
  selectPerson, state => get(state, 'frmRecord', null)
)

const selectLoadingEmploymentRecord = createSelector(
  selectPerson, state => get(state, 'loadingEmploymentRecord', null)
)

const selectLoadingAffiiateRecord = createSelector(
  selectPerson, state => get(state, 'loadingAffiliateRecord', null)
)

const selectAffiiateRecordModalDataInformation = createSelector(
  selectPerson, state => get(state, 'affiliateModalData', null)
)

const selectLoadingFrmRecord = createSelector(
  selectPerson, state => get(state, 'loadingFrmRecord', null)
)

const selectPersonStatus = createSelector(
  selectPerson, state => get(state, 'personStatus', null)
)

const selectContributionPaymentsLoading = createSelector(
  selectPerson, state => get(state, 'contributionPaymentsLoading', null)
)

const selectContributionPaymentsSuccess = createSelector(
  selectPerson, state => get(state, 'contributionPayments', null)
)

const selectContributionPaymentsError = createSelector(
  selectPerson, state => get(state, 'contributionPaymentsError', null)
)

const selectDataFilters = createSelector(
  selectPerson, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectPerson, state => get(state, 'dataFiltersError', null)
)

const selectInboundPaymentsOrigins = createSelector(
  selectPerson, state => get(state, 'inboundPaymentsOrigins', null)
)

export default {
  LOADING: selectLoading,
  SUCCESS: selectSuccess,
  ERROR: selectError,

  LOADING_PHONES: selectLoadingPhones,
  PHONES_SUCCESS: selectPhonesSuccess,

  LOADING_ADDRESSES: selectLoadingAddresses,
  ADDRESSES_SUCCESS: selectAddressesSuccess,

  LOADING_NOTES: selectLoadingNotes,
  NOTES_SUCCESS: selectNotesSuccess,

  LOADING_EMAILS: selectLoadingEmails,
  EMAILS_SUCCESS: selectEmailsSuccess,

  LOADING_BANKS_ACCOUNTS: selectLoadingBanksAccounts,
  BANKS_ACCOUNTS_SUCCESS: selectBanksAccountsSuccess,

  LOADING_FILE: selectLoadingFile,
  FILES_SUCCESS: selectFilesSuccess,
  FILE_MESSAGE: selectFileMessage,
  EMPLOYEE_RECORDS_SUCCESS: selectEmployeeRecordsSuccess,
  INFORMATION_MESSAGE: selectInformationMessage,
  TYPES: selectTypes,
  DEPARTMENTS: selectDepartments,
  BANKS: selectBanks,
  CURRENCIES: selectCurrencies,

  LOADING_PROFILE: selectLoadingProfile,

  EMPLOYMENT_RECORD: selectEmploymentRecord,
  ACTIVE_AFFILIATE_RECORD: selectActiveAffiliateRecord,
  FRM_RECORD: selectFrmRecord,

  LOADING_EMPLOYMENT_RECORD: selectLoadingEmploymentRecord,

  LOADING_AFFILIATE_RECORD: selectLoadingAffiiateRecord,
  AFFILIATE_RECORD_MODAL_DATA_INFORMATION: selectAffiiateRecordModalDataInformation,

  LOADING_FRM_RECORD: selectLoadingFrmRecord,

  PERSON_STATUS: selectPersonStatus,

  CONTRIBUTION_PAYMENTS_LOADING: selectContributionPaymentsLoading,
  CONTRIBUTION_PAYMENTS_SUCCESS: selectContributionPaymentsSuccess,
  CONTRIBUTION_PAYMENTS_ERROR: selectContributionPaymentsError,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  ORIGINS: selectInboundPaymentsOrigins
}
