import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectImportProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectImportProfile, state => get(state, 'loading', false)
)

const selectImportSuccess = createSelector(
  selectImportProfile, state => get(state, 'importProfile', null)
)

const selectStausesSuccess = createSelector(
  selectImportProfile, state => get(state, 'statuses', null)
)

const selectImportProfileError = createSelector(
  selectImportProfile, state => get(state, 'error', null)
)

const selectFileDownloaded = createSelector(
  selectImportProfile, state => get(state, 'fileDownloaded', null)
)

const selectLoadingDownload = createSelector(
  selectImportProfile, state => get(state, 'loadingDownload', null)
)

const selectLoadingTable = createSelector(
  selectImportProfile, state => get(state, 'loadingTableLogs', null)
)

const selectSuccess = createSelector(
  selectImportProfile, state => get(state, 'logsList', [])
)

const selectColumns = createSelector(
  selectImportProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectImportProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectImportProfile, state => get(state, 'totalItems', 0)
)

const selectImportError = createSelector(
  selectImportProfile, state => get(state, 'errorLog', null)
)

const selectDataFilters = createSelector(
  selectImportProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectImportProfile, state => get(state, 'dataFiltersError', null)
)

const selectImportProfileLoading = createSelector(
  selectImportProfile, state => get(state, 'importProfileLoading', null)
)

const selectPendingProcessInfo = createSelector(
  selectImportProfile, state => get(state, 'pendingProcessInfo', null)
)

export default {
  IMPORT_SUCCESS: selectImportSuccess,
  ERROR: selectImportProfileError,
  IMPORT_ERROR: selectImportError,
  STATUSES: selectStausesSuccess,
  LOADING: selectLoading,
  FILE_DOWNLOADED: selectFileDownloaded,
  LOADING_DOWNLOAD: selectLoadingDownload,
  LOADING_TABLE_LOGS: selectLoadingTable,

  GET_LOGS_SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  IMPORT_PROFILE_LOADING: selectImportProfileLoading,
  PENDING_PROCESS_INFO: selectPendingProcessInfo
}
