import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { HOME_ACTIONS } from './actions'
import { HOME } from './types'

const apiCalls = {
  getPeopleDataHome: token => (
    Axios.get('Statistics/people',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentDataHome: ({ year, token }) => (
    Axios.get(`Statistics/payments?year=${year}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getDataHome ({ payload }) {
  try {
    const { year, token } = payload

    const { data: peopleData } = yield call(apiCalls.getPeopleDataHome, token)
    const { data } = yield call(apiCalls.getPaymentDataHome, { year, token })

    const { inboundPaymentsList, outboundPaymentsList } = data

    yield put(HOME_ACTIONS.SUCCESS({ status: 200, peopleData, inboundPaymentsList, outboundPaymentsList }))
  } catch (error) {
    yield put(HOME_ACTIONS.ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(HOME.GET_DATA_HOME, getDataHome)
}
