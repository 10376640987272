import produce from 'immer'

import { USER_PROFILE } from './types'

export const initialState = {
  loading: false,
  error: false,
  userProfile: null,
  statuses: null,
  passwordInformation: null,
  profileInformation: null,
  loadingProfile: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case USER_PROFILE.GET_USER:
      draft.loading = true
      break
    case USER_PROFILE.GET_USER_SUCCESS: {
      const { userProfile, statuses } = payload
      draft.loading = false
      draft.userProfile = userProfile
      draft.statuses = statuses
      break
    }
    case USER_PROFILE.GET_USER_ERROR:
      draft.loading = false
      draft.error = payload
      break
    case USER_PROFILE.SET_PASSWORD_INFORMATION:
      draft.passwordInformation = payload
      break
    case USER_PROFILE.CLEAR_PASSWORD_INFORMATION:
      draft.passwordInformation = null
      break
    case USER_PROFILE.EDIT_USER_PROFILE:
      draft.loadingProfile = true
      break
    case USER_PROFILE.SET_PROFILE_INFORMATION:
      draft.profileInformation = payload
      draft.loadingProfile = false
      break
    case USER_PROFILE.SET_NEW_DATA_PROFILE: {
      const { userProfile, statuses } = payload
      draft.userProfile = userProfile
      draft.statuses = statuses
      break
    }
    case USER_PROFILE.CLEAR_PROFILE_INFORMATION:
      draft.profileInformation = null
      break
    case USER_PROFILE.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
