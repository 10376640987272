import { DETAIL_LIST } from './types'

export const getDetailListProfile = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST_PROFILE,
  payload
})

export const getDetailListProfileSuccess = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST_PROFILE_SUCCESS,
  payload
})

export const getDetailListProfileError = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST_PROFILE_ERROR,
  payload
})

export const getDetailList = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST,
  payload
})

export const getDetailListsSuccess = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST_SUCCESS,
  payload
})

export const getDetailListsError = payload => ({
  type: DETAIL_LIST.GET_DETAIL_LIST_ERROR,
  payload
})

export const getFilterData = payload => ({
  type: DETAIL_LIST.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: DETAIL_LIST.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: DETAIL_LIST.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: DETAIL_LIST.FILTER_DATA_CLEAR
})

export const DETAIL_LIST_ACTIONS = {
  GET_DETAIL_LIST_PROFILE: getDetailListProfile,
  GET_DETAIL_LIST_PROFILE_SUCCESS: getDetailListProfileSuccess,
  GET_DETAIL_LIST_PROFILE_ERROR: getDetailListProfileError,

  GET_DETAIL_LIST: getDetailList,
  DETAIL_LIST_SUCCESS: getDetailListsSuccess,
  DETAIL_LIST_ERROR: getDetailListsError,

  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData

}
