import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { ModalDataContainer, ButtonsContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { TextInput, EmailInput, PasswordInput, PasswordRepeatInput } from '@components/inputs/inputs'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { USERS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddUserModal = ({ close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [showButton, setShowButton] = useState(true)
  const [email, setEmail] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [password, setPassword] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [fullName, setFullName] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [passwordRepeat, setPasswordRepeat] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const confirmation = useSelector(SELECTORS.ADD_PERSON_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((email.value && !email.error)
      && (password.value && !password.error)
      && (fullName.value && !fullName.error)
      && (passwordRepeat.value && !passwordRepeat.error)) setShowButton(false)
    else setShowButton(true)
  }

  const handleChangePasswordRepeat = name => setPasswordRepeat(name)

  const handleInputChange = val => {
    const { name, error } = val
    if (name === 'password' && error) {
      val = {
        ...val,
        error: `Ingrese una contraseña válida. 
        Debe tener mínimo 8 carácteres, una letra mayúscula, una letra minúscula, un número y un caracter especial.`
      }
    }
    switch (name) {
      case 'email':
        setEmail(val)
        break
      case 'password':
        setPassword(val)
        break
      default: setFullName(val)
    }
  }

  const cleanFields = () => {
    setEmail({
      value: '',
      name: '',
      error: ''
    })
    setFullName({
      value: '',
      name: '',
      error: ''
    })
    setPassword({
      value: '',
      name: '',
      error: ''
    })
    setPasswordRepeat({
      value: '',
      name: '',
      error: ''
    })
  }

  const handleClick = () => {
    const newUser = {
      email: email.value,
      password: password.value,
      fullName: fullName.value
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(USERS_ACTIONS.ADD_USER({ data: newUser, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    if (confirmation) {
      const { status } = confirmation
      if (status >= 200 && status <= 299) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFULLY_CREATED_USER,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
        setIsShowing(true)
        cleanFields()
        saveData()
        close()
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: confirmation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [confirmation])

  useEffect(() => {
    errorControl()
  }, [email, password, fullName, passwordRepeat])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <TextInput
          name='fullName'
          label='Nombre y apellido'
          placeholder='Ingrese nombre y apellido'
          color='primary'
          value={fullName.value}
          onChange={handleInputChange}
          height='small'
          bits={[{ icon: 'person', position: 'left', colorBit: 'tertiary' }]}
          required
        />
        <EmailInput
          value={email.value}
          onChange={handleInputChange}
          onBlur={() => {}}
          name='email'
          label='Correo electrónico'
          placeholder='Ingrese su electrónico'
          color='primary'
          bits={[{ icon: 'mail', position: 'left', colorBit: 'tertiary' }]}
          error={email.error}
          height='small'
          required
        />
        <PasswordInput
          name='password'
          label='Contraseña'
          placeholder='Ingrese su contraseña'
          value={password.value}
          onChange={handleInputChange}
          error={password.error}
          color='primary'
          height='small'
          bits={[{ icon: 'password', position: 'left', colorBit: 'tertiary' }]}
          required
        />
        <PasswordRepeatInput
          name='passwordRepaeat'
          label='Repetir contraseña'
          placeholder='Repita la contraseña.'
          pwRepeat={password.value}
          onChange={handleChangePasswordRepeat}
          color='primary'
          error={passwordRepeat.error}
          message={passwordRepeat.error}
          bits={[{ icon: 'password', position: 'left', colorBit: 'tertiary' }]}
          height='small'
          required
        />
      </ModalDataContainer>
      <ButtonsContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonsContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
        timeOut={2000}
      />
    </>
  )
}

export default AddUserModal

AddUserModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.string
}
