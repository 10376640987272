import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import {
  WrapperLoading,
  WrapperBreadcrumbs,
  WrapperHeading,
  TextContainer,
  TableWrapper,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  ItemLink,
  WrapperErrorTextColumn
} from '@global/styles'
import {
  TOKEN_HELPERS,
  handleClickPaginationItem,
  filterBodyBuilder,
  currencyFormatUY,
  filterOrderBuilder
} from '@utils/helpers'
import {
  TOAST_POSITION,
  TRANSLATION_OF_ROUTES,
  rowsPerPage,
  TOAST_PROPERTIES,
  MONTHS,
  DETAIL_TYPES,
  COLUMN_ERROR,
  NO_ORDER_COLUMN_BY
} from '@global/constants'
import { SITE } from '@routes/paths'

import Loading from '@components/loading'
import Breadcrumbs from '@components/breadcrums'
import Toast from '@components/toast'
import { Heading, Text } from '@components/texts'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import { GhostButton } from '@components/button'
import SearchBar from '@components/searchBar'
import BadgeGroup from '@components/badgeGroup'
import Filter from '@components/filter'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { DETAIL_LIST_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { COLUMN_DATA_CONTRIBUTIONS_PAYMENTS, COLUMN_DATA_PAYMENTS_DETAILS } from './data'

const DetailsList = () => {
  useRedux()
  const { detailId, type } = useParams()
  const dispatch = useDispatch()
  const { device, deviceName } = useWindowDimensions()
  const history = useHistory()

  const { GetToken, Logout } = useUser()
  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [detailProfile, setDetailProfile] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [itemSelected, setItemSelected] = useState()
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [activeFilters, setActiveFilters] = useState(null)
  const [errorFlag, setErrorFlag] = useState({
    profile: false,
    contributionPayments: false
  })
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [parameters, setParameters] = useState()
  const [filterData, setFilterData] = useState([])
  const [showTable, setShowTable] = useState(false)
  const [errorColumnMessage, setErrorColumnMessage] = useState(COLUMN_ERROR)

  const loading = useSelector(SELECTORS.LOADING)
  const detailProfileData = useSelector(SELECTORS.DETAIL_LIST_PROFILE_SUCCESS)
  const detailProfileDataError = useSelector(SELECTORS.DETAIL_LIST_PROFILE_ERROR)
  const detailsLoading = useSelector(SELECTORS.DETAILS_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const items = useSelector(SELECTORS.DETAILS_SUCCESS)
  const itemsError = useSelector(SELECTORS.DETAILS_ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterObjectError = useSelector(SELECTORS.FILTER_OBJECT_ERROR)
  const origins = useSelector(SELECTORS.ORIGINS)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getDetailList = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) body.contributionId = detailId
      else body.detailId = detailId
      const dataRequest = { data: body, token }
      dispatch(DETAIL_LIST_ACTIONS.GET_DETAIL_LIST({ dataRequest, type }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getProfile = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const dataRequest = { token }
      if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
        dataRequest.contributionId = detailId
      } else {
        dataRequest.debtId = detailId
      }
      dispatch(DETAIL_LIST_ACTIONS.GET_DETAIL_LIST_PROFILE({ dataRequest, type }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  // HANDLERS
  const handleToastClose = () => setIsShowing(false)

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getDetailList(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const COLUMNS = type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
      ? COLUMN_DATA_CONTRIBUTIONS_PAYMENTS
      : COLUMN_DATA_PAYMENTS_DETAILS
    const colNames = COLUMNS.find(col => col.name === nameColumn)
    if (!colNames.label) {
      setErrorColumnMessage({
        columnName: colNames.name,
        error: true
      })
      return
    }
    setErrorColumnMessage(COLUMN_ERROR)

    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames.label,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getDetailList(body)
  }

  const sendToDetailProfile = id => {
    if (id) {
      const path = type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
        ? SITE.CONTRIBUTION_PAYMENT
        : SITE.PAYMENT_DETAIL
      const win = window.open(`${path}/${id}`, '_blank')
      win.focus()
    }
  }

  const customDataTable = itemsTableCP => {
    const newData = itemsTableCP.map(item => {
      const code = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => sendToDetailProfile(item.id)}
        >
          {`${type === DETAIL_TYPES.CONTRIBUTION_PAYMENT ? 'DI' : 'DP'} - ${item.id}`}
        </ItemLink>
      )
      const originName = item.originName || origins?.find(o => o.id === item.originId)?.name || 'N/A'

      const newItem = type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
        ? {
          id: item.id,
          code,
          concept: item.concept,
          originName,
          currency: item.currencyName,
          amount: currencyFormatUY(item.amount),
          bms: item.bmsAmount?.toFixed(2) || 'N/A',
          status: item.statusName,
          description: item.description,
          month: item.month ? MONTHS?.find(m => m.id + 1 === item.month)?.label : 'N/A',
          year: item.year
        }
        : {
          id: item.id,
          code,
          concept: item.concept,
          amount: currencyFormatUY(item.amount),
          status: item.statusName,
          description: item.description
        }
      return newItem
    })
    return newData
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getDetailList, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  // FILTER
  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      const filterRequest = { id: 12, token }
      dispatch(DETAIL_LIST_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDetailList(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(DETAIL_LIST_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDetailList(body)
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
      device={device}
    />
  )

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getDetailList(body)
  }

  // USE EFFECTS
  useEffect(() => {
    getProfile()
  }, [])

  useEffect(() => {
    if (detailProfileData) {
      setDetailProfile(detailProfileData)
      const { tokenCreationDate } = currentToken
      const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
      if (isValid) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        setActiveFilters(body)
        getDetailList(body)
      } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    }
  }, [detailProfileData])

  useEffect(() => {
    if (detailProfile) {
      let url = `${SITE.FINANCE}`
      const firstCrumb = {
        link: true,
        text: `${TRANSLATION_OF_ROUTES.FINANCE}`,
        url
      }

      let textSecondBC = ''
      if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
        url += `${SITE.LIQUIDATIONS}`
        textSecondBC = `${TRANSLATION_OF_ROUTES.LIQUIDATIONS}`
      } else {
        url += `${SITE.PAYMENT_SETTLEMENTS}`
        textSecondBC = `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENTS}`
      }

      const secondCrumb = {
        link: true,
        text: textSecondBC,
        url
      }

      let textThirdBC = ''
      if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
        url += `${SITE.LIQUIDATION_LOG}/${detailProfile?.settlementId}`
        textThirdBC = `${TRANSLATION_OF_ROUTES.LIQUIDATIONS} - ${detailProfile?.settlementId}`
      } else {
        url += `${SITE.PAYMENT_SETTLEMENT_PROFILE}/${detailProfile?.outboundSettlementId}`
        textThirdBC = `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENT_PROFILE} - ${detailProfile?.outboundSettlementId}`
      }

      const thirdCrumb = {
        link: true,
        text: textThirdBC,
        url
      }

      let textFourthBC = ''
      if (type === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
        url += `${SITE.CONTRIBUTION_PROFILE}/${detailId}`
        textFourthBC = `${TRANSLATION_OF_ROUTES.CONTRIBUTION_PROFILE} - ${detailId}`
      } else {
        url += `${SITE.DEBT_PROFILE}/${detailId}`
        textFourthBC = `${TRANSLATION_OF_ROUTES.DEBT_PROFILE} - ${detailId}`
      }

      const fourthCrumb = {
        link: true,
        text: textFourthBC,
        url
      }

      const textLastBC = type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
        ? TRANSLATION_OF_ROUTES.CONTRIBUTION_PAYMENT_LIST
        : TRANSLATION_OF_ROUTES.PAYMENT_DETAIL_LIST

      const lastCrumb = {
        link: false,
        text: textLastBC,
        url: null
      }
      setBreadcrumbs([firstCrumb, secondCrumb, thirdCrumb, fourthCrumb, lastCrumb])
    }
  }, [detailProfile])

  useEffect(() => {
    if (items) {
      setTablelist(customDataTable(items))
      setColumns(type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
        ? COLUMN_DATA_CONTRIBUTIONS_PAYMENTS
        : COLUMN_DATA_PAYMENTS_DETAILS)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
      setShowTable(true)
    }
  }, [items, totalPages])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (detailProfileDataError) {
      const { status, message } = detailProfileDataError
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || `Error ${status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.profile) {
        getProfile()
        setErrorFlag({
          ...errorFlag,
          profile: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [detailProfileDataError])

  useEffect(() => {
    if (itemsError) {
      const { status, message } = itemsError
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || `Error ${status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.contributionPayments) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        setActiveFilters(body)
        getDetailList(body)
        setErrorFlag({
          ...errorFlag,
          contributions: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [itemsError])

  useEffect(() => {
    if (filterObjectError) {
      const { status, message } = filterObjectError
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || `Error ${status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      handleClickCloseFilters()
    }
  }, [filterObjectError])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])
  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
                <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                  <Breadcrumbs crumbs={breadcrumbs} />
                </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' device={device}>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {
                  `${type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
                    ? TRANSLATION_OF_ROUTES.CONTRIBUTION_PROFILE
                    : TRANSLATION_OF_ROUTES.DEBT_PROFILE} - ${detailId}`
                }
              </Heading>
            </WrapperHeading>
            <TextContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3'>
              <Text size='large' weight='bold' align='left'>{
                type === DETAIL_TYPES.CONTRIBUTION_PAYMENT
                  ? TRANSLATION_OF_ROUTES.CONTRIBUTION_PAYMENT
                  : TRANSLATION_OF_ROUTES.PAYMENT_DETAIL
              }
              </Text>
            </TextContainer>
            <FilterContainer columnStart='1' columnEnd='12' rowStart='4' rowEnd='4' deviceName={deviceName}>
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                  disabled={tablelist.length === 0}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                {showTable && (
                  <SearchBar
                    onChange={handleInputChange}
                    visible={activeFilters?.filterText}
                    value={activeFilters?.filterText}
                  />
                )}
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapper columnStart='1' columnEnd='12' rowStart='5' rowEnd='5' marginBottom='24px'>
              {errorColumnMessage.error
                && (
                  <WrapperErrorTextColumn>
                    <Text align='left' weight='bold' size='medium' color='error'>
                      {`${NO_ORDER_COLUMN_BY} "${errorColumnMessage.columnName}"`}
                    </Text>
                  </WrapperErrorTextColumn>
                )}
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: detailsLoading,
                handleOrder: handleOrderChange,
                error: itemsError
              })}
            </TableWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </>
        )}
    </>
  )
}

export default DetailsList
