import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { AFFILIATE_CATEGORIES_ACTIONS } from './actions'
import { AFFILIATE_CATEGORIES } from './types'

const apiCalls = {
  getAffiliateCategories: token => (
    Axios.get('AffiliateRecord/categories',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addAffiliateCategories: ({ data, token }) => (
    Axios.post('AffiliateRecord/categories/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteAffiliateCategories: ({ affiliateCategoryId, token }) => (
    Axios.delete(`AffiliateRecord/categories/${affiliateCategoryId}/delete`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editAffiliateCategories: ({ affiliateCategoryId, data, token }) => (
    Axios.post(`AffiliateRecord/categories/${affiliateCategoryId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getAllAffiliateCategories ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getAffiliateCategories, payload)
    yield put(AFFILIATE_CATEGORIES_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(AFFILIATE_CATEGORIES_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(AFFILIATE_CATEGORIES_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(AFFILIATE_CATEGORIES_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* setAffiliateCategories ({ payload }) {
  try {
    const { type, affiliateCategoryId, data, token } = payload
    let status = null
    switch (type) {
      case FUNCTION_TYPE.ADD: {
        const res = yield call(apiCalls.addAffiliateCategories, { data, token })
        status = res.status
        break
      }
      case FUNCTION_TYPE.DELETE: {
        const res = yield call(apiCalls.deleteAffiliateCategories, { affiliateCategoryId, token })
        status = res.status
        break
      }
      default: {
        const res = yield call(apiCalls.editAffiliateCategories, { affiliateCategoryId, data, token })
        status = res.status
        break
      }
    }
    const { data: afiiliateCategorysUpdated } = yield call(apiCalls.getAffiliateCategories, token)

    yield put(AFFILIATE_CATEGORIES_ACTIONS.SUCCESS(afiiliateCategorysUpdated))
    yield put(AFFILIATE_CATEGORIES_ACTIONS.SET_AFFILIATE_CATEGORY_INFORMATION(status))
  } catch (error) {
    const { status, message } = error
    yield put(AFFILIATE_CATEGORIES_ACTIONS.SET_AFFILIATE_CATEGORY_INFORMATION({ status, message }))
  }
}

export default function* saga () {
  yield takeLatest(AFFILIATE_CATEGORIES.GET, getAllAffiliateCategories)
  yield takeLatest(AFFILIATE_CATEGORIES.GET_FILTER_DATA, FilterData)
  yield takeLatest(AFFILIATE_CATEGORIES.SET_AFFILIATE_CATEGORY, setAffiliateCategories)
}
