import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  MyButtonLink,
  LinkContainer,
  WrapperHeading,
  ItemLink,
  MyLink
} from '@global/styles'

import { TRANSLATION_OF_ROUTES, TOAST_POSITION, TOAST_PROPERTIES, MONTHS } from '@global/constants'
import { TOKEN_HELPERS, currencyFormatUY } from '@utils/helpers'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import Modal from '@components/modal'
import { Heading } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import AddDepositDetailModal from '@containers/depositProfile/components/addDepositDetailModal'

import useRedux from './redux'
import { CONTRIBUTION_PAYMENT_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, ContributionPaymentData, Tbody } from './styles'

const INITIAL_STATE_MESSAGE_TOAST = {
  title: '',
  message: '',
  icon: '',
  color: ''
}

const ContributionPayment = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { contributionPaymentId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState(INITIAL_STATE_MESSAGE_TOAST)
  const [contributionPayment, setContributionPayment] = useState(null)
  const [open, setOpen] = useState(false)
  const [editContributionPaymentModal, setEditContributionPaymentModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [statuses, setStatuses] = useState([])
  const [concepts, setConcepts] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_CONTRIBUTION_PAYMENT)
  const editInformation = useSelector(SELECTORS.EDIT_INFORMATION)
  const contributionPaymentData = useSelector(SELECTORS.CONTRIBUTION_PAYMENT_SUCCESS)
  const statusesData = useSelector(SELECTORS.CONTRIBUTION_PAYMENT_STATUSES_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const currencies = useSelector(SELECTORS.CURRENCIES)
  const conceptsData = useSelector(SELECTORS.CONCEPTS)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const handleSentToContributionClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const renderContributionPaymentData = objContributionPaymentData => (
    <>
      <Tr>
        <Td colorLight widthPerc>Código:</Td>
        <Td largeSize>{objContributionPaymentData?.id || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Persona:</Td>
        <Td largeSize>
          {objContributionPaymentData.personId
            ? (
              <ItemLink
                color='tertiary'
                underline
                onClick={() => handlePersonClick(objContributionPaymentData.personId)}
              >
                {`${objContributionPaymentData?.personId} - ${objContributionPaymentData?.personFullName}`}
              </ItemLink>
            )
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Concepto:</Td>
        <Td largeSize>{
          objContributionPaymentData?.conceptId
            ? concepts?.find(c => c.id === objContributionPaymentData?.conceptId)?.name
            : 'N/A'
          }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Moneda:</Td>
        <Td largeSize>{
          objContributionPaymentData.currencyName
          || currencies.find(c => c.id === objContributionPaymentData?.currencyId)?.name
          || 'N/A'
          }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Monto:</Td>
        <Td largeSize>{currencyFormatUY(objContributionPaymentData?.amount) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Mes Liquidación:</Td>
        <Td largeSize>{MONTHS?.find(m => m.id + 1 === objContributionPaymentData?.month)?.label || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Año Liquidación:</Td>
        <Td largeSize>{objContributionPaymentData?.year || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Estado:</Td>
        <Td largeSize>{
          objContributionPaymentData.statusName
          || statusesData.find(s => s.id === objContributionPaymentData?.statusId)
          || 'N/A'
          }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Contribución:</Td>
        <Td largeSize>
          {objContributionPaymentData?.contributionId
            ? (
              <ItemLink
                color='tertiary'
                underline
                onClick={() => handleSentToContributionClick(objContributionPaymentData?.contributionId)}
              >
                {`C - ${objContributionPaymentData?.contributionId}`}
              </ItemLink>
            )
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Descripción:</Td>
        <Td largeSize>{objContributionPaymentData?.description || 'N/A'}</Td>
      </Tr>
    </>
  )

  const handleSaveContributionPayment = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CONTRIBUTION_PAYMENT_ACTIONS.EDIT_CONTRIBUTION_PAYMENT({
        inboundPaymentId: contributionPayment?.inboundPaymentId,
        contributionPaymentId,
        data: newProfile,
        token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setEditContributionPaymentModal(null)
    setOpen(false)
  }

  const handleEditContributionPayment = () => {
    setModalTitle('Editar Detalle de Ingreso')
    const oldData = {
      statusesData: statuses,
      contributionPayment
    }
    const edit = true
    setEditContributionPaymentModal({
      saveData: handleSaveContributionPayment,
      close: handleCloseModal,
      currentToken,
      oldData,
      edit,
      setMessageToastExternal: setMessageToast
    })
    setOpen(true)
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CONTRIBUTION_PAYMENT_ACTIONS.GET_CONTRIBUTION_PAYMENT(
        { contributionPaymentId, token }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (contributionPaymentData) {
      setContributionPayment(contributionPaymentData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.CONTRIBUTION_PAYMENT} - ${contributionPaymentId}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = SITE.FINANCE
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url
        }
        url += `${SITE.INCOME}`
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.INCOME}`,
          link: true,
          url
        }
        url += `${SITE.DEPOSIT_PROFILE}/${contributionPaymentData?.inboundPaymentId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.DEPOSIT_PROFILE} - ${contributionPaymentData?.inboundPaymentId}`,
          link: false,
          url
        }
        url += `${SITE.CONTRIBUTION_PAYMENT}/${contributionPaymentId}`
        const lastCrumb = {
          text: `${TRANSLATION_OF_ROUTES.CONTRIBUTION_PAYMENT} - ${contributionPaymentId}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb, lastCrumb])
      }
    }
  }, [contributionPaymentData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        dispatch(CONTRIBUTION_PAYMENT_ACTIONS.CLEAR())
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(CONTRIBUTION_PAYMENT_ACTIONS.GET_CONTRIBUTION_PAYMENT(
            { contributionPaymentId, token }
          ))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (editInformation) {
      if (editInformation.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: editInformation.message || `Error ${editInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [editInformation])

  useEffect(() => {
    if (statusesData) setStatuses(statusesData)
  }, [statusesData])

  useEffect(() => {
    if (conceptsData) setConcepts(conceptsData)
  }, [conceptsData])

  useEffect(() => {
    if (messageToast.title) {
      setIsShowing(true)
      setTimeout(() => {
        setMessageToast(INITIAL_STATE_MESSAGE_TOAST)
      }, 6000)
    }
  }, [messageToast])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
                <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                  <Breadcrumbs crumbs={breadcrumbs} />
                </WrapperBreadcrumbs>
              )}
            <WrapperHeading
              device={device}
              columnStart='1'
              columnEnd='12'
              rowStart='2'
              rowEnd='2'
              marginBottom={spacing.two}
            >
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {`${TRANSLATION_OF_ROUTES.CONTRIBUTION_PAYMENT} - ${contributionPaymentId}`}
              </Heading>
              {breadcrumbs
              && (
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
              )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <ContributionPaymentData>
                      <Tbody>
                        {contributionPayment && renderContributionPaymentData(contributionPayment)}
                      </Tbody>
                    </ContributionPaymentData>
                    <LinkContainer flex={1}>
                      <MyButtonLink onClick={handleEditContributionPayment} color='primary' underline>
                        Editar
                      </MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
              top='10%'
              widthWrapper='95%'
              widthArea={editContributionPaymentModal && 'auto'}
              left='5%'
              padding={spacing.one}
            >
              {editContributionPaymentModal
                && <AddDepositDetailModal {...editContributionPaymentModal} />}
            </Modal>
          </>
        )}
    </>
  )
}

export default ContributionPayment
