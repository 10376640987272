export const columnDataEmployment = {
  BillingNumber: { name: 'Nro. Facturación', widthCol: '15%' },
  InitialPosition: { name: 'Cargo', widthCol: '25%' },
  StatusName: { name: 'Estado', widthCol: '15%' },
  StartDate: { name: 'Fecha de incio', widthCol: '20%' },
  EndDate: { name: 'Fecha finalización', widthCol: '20%' },
  Data: { name: 'Datos', widthCol: '20%' }
}

export const columnDataAffiliate = {
  CategoryName: { name: 'Categoría', widthCol: '20%' },
  StatusName: { name: 'Estado', widthCol: '20%' },
  StartDate: { name: 'Fecha de incio', widthCol: '30%' },
  EndDate: { name: 'Fecha finalización', widthCol: '30%' },
  Data: { name: 'Datos', widthCol: '45%' }
}

export const columnDataFrm = {
  CategoryName: { name: 'Categoría', widthCol: '10%' },
  StatusName: { name: 'Estado', widthCol: '10%' },
  CollectionBase: { name: 'Base de cobro', widthCol: '15%' },
  TotalPaymentsMade: { name: 'Total pagos realizados', widthCol: '15%' },
  StartDate: { name: 'Fecha de incio', widthCol: '20%' },
  EndDate: { name: 'Fecha finalización', widthCol: '20%' },
  Data: { name: 'Datos', widthCol: '15%' }
}

export const employmentRecordColumns = [
  { name: 'BillingNumber', order: 1 },
  { name: 'InitialPosition', order: 2 },
  { name: 'StatusName', order: 3 },
  { name: 'StartDate', order: 4 },
  { name: 'EndDate', order: 5 }
]

export const affiliateRecordColumns = [
  { name: 'CategoryName', order: 1 },
  { name: 'StatusName', order: 2 },
  { name: 'StartDate', order: 3 },
  { name: 'EndDate', order: 4 }
]

export const frmRecordColumns = [
  { name: 'CategoryName', order: 1 },
  { name: 'StatusName', order: 2 },
  { name: 'CollectionBase', order: 3 },
  { name: 'TotalPaymentsMade', order: 4 },
  { name: 'StartDate', order: 5 },
  { name: 'EndDate', order: 6 }
]

export const EMPLOYEE_HISTORY_DATA_TYPES = {
  employment: {
    fields: employmentRecordColumns,
    colNames: columnDataEmployment,
    breadcrumbText: 'Historial de empleo'
  },
  affiliate: {
    fields: affiliateRecordColumns,
    colNames: columnDataAffiliate,
    breadcrumbText: 'Historial de afiliación'
  },
  FRM: {
    fields: frmRecordColumns,
    colNames: columnDataFrm,
    breadcrumbText: 'Historial de F.R.M.'
  }
}
