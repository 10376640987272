import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { spacing } from '@global/theme'
import { BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import { TextInput, NumberInput } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

const AddAndEditFrmCategory = ({ functionType, oldData, saveData }) => {
  const { device } = useWindowDimensions()

  const [nameFrmCategory, setNameFrmCategory] = useState({
    name: '',
    value: oldData?.name || '',
    error: ''
  })
  const [fromAge, setFromAge] = useState({
    name: '',
    value: oldData?.fromAge || '',
    error: ''
  })
  const [toAge, setToAge] = useState({
    name: '',
    value: oldData?.toAge || '',
    error: ''
  })
  const [contributionPerc, setContributionPerc] = useState({
    name: '',
    value: oldData?.contributionPerc || '',
    error: ''
  })
  const [descriptionFrmCategory, setDescriptionFrmCategory] = useState({
    name: '',
    value: oldData?.description || '',
    error: ''
  })
  const [showButton, setShowButton] = useState(true)

  const handleChange = val => {
    const { name } = val
    switch (name) {
      case 'nameFrmCategory':
        setNameFrmCategory(val)
        break
      case 'descriptionFrmCategory':
        setDescriptionFrmCategory(val)
        break
      case 'fromAge':
        setFromAge(val)
        break
      case 'toAge':
        setToAge(val)
        break
      default:
        setContributionPerc(val)
    }
  }

  const clearStates = () => {
    setNameFrmCategory({
      name: '',
      value: '',
      error: ''
    })
    setFromAge({
      name: '',
      value: '',
      error: ''
    })
    setToAge({
      name: '',
      value: '',
      error: ''
    })
    setContributionPerc({
      name: '',
      value: '',
      error: ''
    })
    setDescriptionFrmCategory({
      name: '',
      value: '',
      error: ''
    })
  }

  const handleClick = () => {
    const frmCategory = {
      name: nameFrmCategory.value,
      description: descriptionFrmCategory.value,
      fromAge: fromAge.value,
      toAge: toAge.value,
      contributionPerc: contributionPerc.value,
      id: oldData?.id
    }
    saveData(frmCategory, functionType)
    clearStates()
  }

  const errorControl = () => {
    if ((nameFrmCategory.value && !nameFrmCategory.error)
        && (contributionPerc.value && !contributionPerc.error)
        && (fromAge.value && !fromAge.error)
        && (toAge.value && !toAge.error)
        && (Number(fromAge.value) < Number(toAge.value))) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(() => {
    errorControl()
  }, [nameFrmCategory, fromAge, toAge, contributionPerc])

  return (
    <ModalDataContainer flexDirection='column'>
      <TextInput
        name='nameFrmCategory'
        label='Categoría'
        placeholder='Ingrese nombre de categoría'
        value={nameFrmCategory.value}
        height='small'
        color='primary'
        onChange={handleChange}
        bits={[{ icon: 'category', position: 'left', colorBit: 'tertiary' }]}
      />
      <NumberInput
        label='Edad desde'
        placeholder='Ingrese edad desde'
        name='fromAge'
        value={fromAge.value}
        error={fromAge.error}
        max={100}
        min={0}
        step={1}
        height='small'
        color='primary'
        onChange={handleChange}
      />
      <NumberInput
        label='Edad hasta'
        placeholder='Ingrese edad hasta'
        name='toAge'
        value={toAge.value}
        error={toAge.error}
        max={100}
        min={0}
        step={1}
        height='small'
        color='primary'
        onChange={handleChange}
      />
      <Input
        type={INPUT_TYPES.NUMBER}
        showType={INPUT_TYPES.TEXT}
        label='Porcentaje de contribución'
        placeholder='Ingrese porcentaje de contribución'
        name='contributionPerc'
        value={contributionPerc.value}
        error={contributionPerc.error}
        height='small'
        color='primary'
        onChange={handleChange}
        onBlur={() => {}}
        bits={[{ icon: 'percent', position: 'left', colorBit: 'tertiary' }]}
      />
      <TextInput
        name='descriptionFrmCategory'
        label='Descripción'
        placeholder='Ingrese descripción de la categoría'
        size='small'
        color='primary'
        onChange={handleChange}
        value={descriptionFrmCategory.value}
        bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
      />
      {(Number(fromAge.value) > Number(toAge.value))
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La EDAD DESDE tiene que ser menor que la EDAD HASTA.
           </Text>
           )}
      <ButtonsContainer justifyContent='flex-end' marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonsContainer>
    </ModalDataContainer>
  )
}

export default AddAndEditFrmCategory

AddAndEditFrmCategory.propTypes = {
  functionType: PropTypes.string,
  oldData: PropTypes.object,
  saveData: PropTypes.func
}
