import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectContributionPayment = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectContributionPayment, state => get(state, 'loading', null)
)

const selectContributionPaymentSuccess = createSelector(
  selectContributionPayment, state => get(state, 'contributionPayment', null)
)

const selectStatusesSuccess = createSelector(
  selectContributionPayment, state => get(state, 'contributionPaymentStatuses', null)
)

const selectError = createSelector(
  selectContributionPayment, state => get(state, 'error', null)
)

const selectLoadingEditContributionPayment = createSelector(
  selectContributionPayment, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectContributionPayment, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectContributionPayment, state => get(state, 'editInformation', [])
)

const selectCurrencies = createSelector(
  selectContributionPayment, state => get(state, 'currencies', [])
)

const selectConcepts = createSelector(
  selectContributionPayment, state => get(state, 'concepts', [])
)

export default {
  CONTRIBUTION_PAYMENT_SUCCESS: selectContributionPaymentSuccess,
  CONTRIBUTION_PAYMENT_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_CONTRIBUTION_PAYMENT: selectLoadingEditContributionPayment,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  CURRENCIES: selectCurrencies,
  CONCEPTS: selectConcepts
}
