import React, { useState } from 'react'

import {
  WrapperBreadcrumbs,
  ContainerShortcuts,
  ShortcutItem,
  ShortcutLeftSide,
  ShortcutImg
} from '@global/styles'
import { SITE } from '@routes/paths'
import { TRANSLATION_OF_ROUTES } from '@global/constants'

import Icon from '@components/icon'
import { Heading } from '@components/texts'
import { spacing } from '@global/theme'
import Breadcrumbs from '@components/breadcrums'
import { useWindowDimensions } from '@components/windowDimensions'

import { dataItems } from './data'

const EntityManagment = () => {
  const { device } = useWindowDimensions()
  const [breadcrumbs] = useState([
    {
      link: true,
      text: TRANSLATION_OF_ROUTES.SETTING,
      url: SITE.SETTING
    },
    {
      link: true,
      text: TRANSLATION_OF_ROUTES.ENTITY_MANAGMENT,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}`
    }
  ])

  return (
    <ContainerShortcuts>
      {breadcrumbs
      && (
      <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
        <Breadcrumbs crumbs={breadcrumbs} />
      </WrapperBreadcrumbs>
      )}
      {
          dataItems.map(item => (
            <ShortcutItem key={item.id} to={item.to} device={device}>
              <ShortcutLeftSide key={item.id} device={device}>
                <ShortcutImg
                  src={item.src}
                  alt='entity'
                  width={spacing.four}
                  height={spacing.fiveAndAHalf}
                />
                <Heading size='small' weight='semibold' align='left'>{item.label}</Heading>
              </ShortcutLeftSide>
              <Icon name='trending_flat' backgroundColor='background' size='xlarge' color='primary' />
            </ShortcutItem>
          ))
      }
    </ContainerShortcuts>
  )
}
export default EntityManagment
