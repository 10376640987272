import { BENEFITS } from './types'

export const getBenefits = payload => ({
  type: BENEFITS.GET,
  payload
})

export const getBenefitSuccess = payload => ({
  type: BENEFITS.SUCCESS,
  payload
})

export const getBenefitsError = payload => ({
  type: BENEFITS.ERROR,
  payload
})

export const ClearBenefitsList = payload => ({
  type: BENEFITS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: BENEFITS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: BENEFITS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: BENEFITS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: BENEFITS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: BENEFITS.FILTER_DATA_CLEAR
})

export const setBenefit = payload => ({
  type: BENEFITS.SET_BENEFIT,
  payload
})

export const benefitInformation = payload => ({
  type: BENEFITS.BENEFIT_INFORMATION,
  payload
})

export const benefitInformationClear = payload => ({
  type: BENEFITS.BENEFIT_INFORMATION_CLEAR,
  payload
})

export const getBenefitTipes = payload => ({
  type: BENEFITS.GET_BENEFIT_TYPES,
  payload
})

export const benefitTypesSuccess = payload => ({
  type: BENEFITS.BENEFIT_TYPES_SUCCESS,
  payload
})

export const benefitTypesError = payload => ({
  type: BENEFITS.BENEFIT_TYPES_ERROR,
  payload
})

export const BENEFITS_ACTIONS = {
  GET_BENEFITS: getBenefits,
  SUCCESS: getBenefitSuccess,
  ERROR: getBenefitsError,
  CLEAR: ClearBenefitsList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  SET_BENEFIT: setBenefit,
  SET_BENEFIT_INFORMATION: benefitInformation,
  BENEFIT_INFORMATION_CLEAR: benefitInformationClear,
  GET_BENEFIT_TYPES: getBenefitTipes,
  BENEFIT_TYPES_SUCCESS: benefitTypesSuccess,
  BENEFIT_TYPES_ERROR: benefitTypesError
}
