import React, { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { get, set } from 'lodash'

import { FILTER_TYPES } from '@global/constants'
import { MESSAGES } from '@global/message'
import { transformArrToObj } from '@utils/helpers'
import useScreenWidth from '@utils/hooks'

import { Heading, Text } from '@components/texts'
import { GhostButton, SolidButton } from '@components/button'
import { CheckboxInput, NumberInput, TextInput } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'

import {
  Container,
  TabContainer,
  Tab,
  ContentContainer,
  Content,
  ButtonContainer,
  FilterContainer,
  Items,
  Overlay,
  Wrapper,
  ContentList
} from './styles'

const labelDatePickers = {
  DATE_FROM: 'Fecha inicio',
  DATE_TO: 'Fecha fin'
}

const Filter = ({ isShowing, device, close, loadBadges, filterData, zIndex }) => {
  const screenWidth = useScreenWidth()
  const today = new Date()
  const [toggleTab, setToggleTab] = useState(filterData[0]?.id || 1)
  const [values, setValues] = useState()
  const [initialState, setInitialState] = useState()
  const [badges, setBadges] = useState()
  const [correctDateRange, setCorrectDateRange] = useState({
    dateFrom: new Date(),
    dateTo: new Date(),
    correctState: true
  })
  const [filterControl, setfilterControl] = useState(0)

  const handleToggle = e => {
    const { id } = e.target
    setToggleTab(Number(id))
  }

  const handleChange = val => {
    const type = val.name.split('_')[0]
    const filterName = val.name.split('_')[1]
    const label = val.name.split('_')[2]
    const filter = val.name.split('_')[3]
    const id = val.name.split('_')[4]

    const newBadges = [...badges]
    const setItem = (arrBadges, typeFilter, filterN, value, item = '', idItem = 0) => arrBadges.map(badge => {
      if (badge.name === filterN) {
        if (value || typeFilter === FILTER_TYPES.CHECKBOX) {
          let arrItems = []
          switch (typeFilter) {
            case FILTER_TYPES.CHECKBOX: {
              if (badge.items.length === 0) arrItems = [{ name: item, id: idItem }]
              else if (value) {
                arrItems = [...badge.items, { name: item, id: idItem }]
              } else arrItems = [...badge.items.filter(b => b.name !== item)]
              break
            }
            case FILTER_TYPES.NUMBER_INPUT:
              arrItems = [value]
              break
            case FILTER_TYPES.TEXT_INPUT:
              arrItems = [value]
              break
            case FILTER_TYPES.DATE_PICKER: {
              const arrFilter = badge.items.filter(b => b.label !== label)
              if (label === labelDatePickers.DATE_FROM) {
                arrItems = [{ name: filter, label, value }, ...arrFilter]
              } else {
                arrItems = [...arrFilter, { name: filter, label, value }]
              }
              break
            }
            default:
              return arrItems
          }
          return { ...badge, items: arrItems }
        }
        return { ...badge, items: badge.items.filter(i => i !== item) }
      }
      return badge
    })
    setBadges(setItem(newBadges, type, filterName, val.value, label, id))
    let newO = { ...values }
    const newState = type === FILTER_TYPES.NUMBER_INPUT || type === FILTER_TYPES.TEXT_INPUT
      ? `[${filterName}].data.value`
      : `[${filterName}].data[${filter}].value`
    newO = set(newO, newState, val.value)
    let selectedItems = 0

    switch (type) {
      case FILTER_TYPES.CHECKBOX:
        selectedItems = get(newO, `[${filterName}].selectedItems`)
        selectedItems = val.value ? selectedItems + 1 : selectedItems - 1
        break
      case FILTER_TYPES.NUMBER_INPUT:
        selectedItems = val.value ? 1 : 0
        break
      case FILTER_TYPES.TEXT_INPUT:
        selectedItems = val.value ? 1 : 0
        break
      case FILTER_TYPES.DATE_PICKER: {
        selectedItems = get(values, `[${filterName}].selectedItems`)
        const countDates = get(values, `[${filterName}].data`)
        const max = Object.values(countDates).length
        selectedItems = selectedItems >= max ? selectedItems : selectedItems + 1

        if (label === labelDatePickers.DATE_FROM) {
          const dateFrom = new Date(val?.value.setHours(0, 0, 0, 0))
          setCorrectDateRange({
            ...correctDateRange,
            dateFrom: dateFrom.toISOString()
          })
        } else {
          const dateTo = new Date(val?.value.setHours(23, 59, 59, 0))
          setCorrectDateRange({
            ...correctDateRange,
            dateTo: dateTo.toISOString()
          })
        }
        break
      }
      default:
        return null
    }

    newO = set(newO, `[${filterName}].selectedItems`, selectedItems)
    setValues(newO)
  }

  const handleCancelFilter = () => {
    setValues(initialState)
    close()
  }

  const handleApplyFilter = () => {
    const activesBadges = badges.filter(filter => filter.items.length > 0)
    let filtersSelected = Object.values(values).filter(filter => filter.selectedItems !== 0)
    filtersSelected = filtersSelected.map(item => item.label)

    let filterActives = []
    for (let i = 0; i < filtersSelected.length; i++) {
      const wanted = activesBadges.filter(filter => filter.text === filtersSelected[i])
      if (wanted.length > 0) {
        filterActives = [
          ...filterActives,
          wanted[0]
        ]
      }
    }

    loadBadges(filterActives)
    close()
  }

  const renderTabs = filters => (
    filters.map(filter => (
      <Tab key={filter.id} id={filter.id} isActive={filter.id === toggleTab} onClick={e => handleToggle(e)}>
        <Text size='small' weight='bold' color='system' id={filter.id}>
          {filter.text}
        </Text>
        <Items key={filter.id} id={filter.id}>{values[filter.name].selectedItems}</Items>
      </Tab>
    ))
  )

  const filterType = (type, filterName, label = '', name = '', id) => {
    switch (type) {
      case FILTER_TYPES.CHECKBOX: {
        name = name.replace(/\./g, '')
        const state = values[filterName].data[name]
        return (
          <CheckboxInput
            key={id}
            label={label}
            name={`${type}_${filterName}_${label}_${name}_${id}`}
            value={state.value}
            color='primary'
            onChange={handleChange}
          />
        )
      }
      case FILTER_TYPES.NUMBER_INPUT: {
        const state = values[filterName].data.value
        return (
          <NumberInput
            key={id}
            label={label}
            placeholder={`Ingrese ${label}`}
            name={`${type}_${filterName}_${label}_${name}`}
            value={state}
            max={10000}
            min={0}
            step={1}
            height='small'
            color='primary'
            onChange={handleChange}
          />
        )
      }
      case FILTER_TYPES.TEXT_INPUT: {
        const state = values[filterName].data.value
        return (
          <TextInput
            key={id}
            label={label}
            placeholder={`Ingrese ${label}`}
            name={`${type}_${filterName}_${label}_${name}`}
            value={state}
            height='small'
            color='primary'
            onChange={handleChange}
          />
        )
      }
      case FILTER_TYPES.DATE_PICKER: {
        return (
          <CustomDatePicker
            key={id}
            name={`${type}_${filterName}_${label}_${name}`}
            title={label}
            color='primary'
            onChange={handleChange}
            forFilter
          />
        )
      }
      default:
        return null
    }
  }

  const renderContent = filters => (
    <Content>
      <ContentList>
        {values && filters.map(filter => {
          const { id, name, text, type } = filter
          let { content } = filter
          if (!content) {
            content = []
          }

          if (type === FILTER_TYPES.DATE_PICKER) {
            content = content.map(item => {
              const { name: nameDatePicker } = item
              let obj = {}
              if (nameDatePicker.toLowerCase().includes('from')) {
                obj = {
                  name: labelDatePickers.DATE_FROM,
                  key: nameDatePicker
                }
              } else {
                obj = {
                  name: labelDatePickers.DATE_TO,
                  key: nameDatePicker
                }
              }
              return obj
            })
          }
          return (
            <FilterContainer key={id} name={name} id={id} isActive={id === toggleTab}>
              {content.length > 0
                ? content.map(item => filterType(type, name, item.name, item.key ?? item.name, item.id))
                : filterType(type, name, text)}
            </FilterContainer>
          )
        })}
        {!correctDateRange.correctState
      && <Text size='small' weight='bold' color='error'>{MESSAGES.ERROR_MINOR_FROM_DATE}</Text>}
      </ContentList>
      <ButtonContainer device={device}>
        <GhostButton size='small' color='primary' text='Cancelar' onClick={handleCancelFilter} />
        <SolidButton
          size='small'
          color='primary'
          text='Aplicar filtro'
          onClick={handleApplyFilter}
          disabled={!correctDateRange.correctState || !filterControl}
        />
      </ButtonContainer>
    </Content>
  )

  useEffect(() => {
    let filterValues = {}
    let badgeValues = []

    for (let i = 0; i < filterData.length; i++) {
      badgeValues = [
        ...badgeValues,
        {
          type: filterData[i].type,
          name: filterData[i].name,
          text: filterData[i].text,
          items:
            filterData[i].type === FILTER_TYPES.DATE_PICKER
              ? [
                {
                  name: filterData[i].content[0].name,
                  label: labelDatePickers.DATE_FROM,
                  value: today
                },
                {
                  name: filterData[i].content[1].name,
                  label: labelDatePickers.DATE_TO,
                  value: today
                }
              ]
              : []
        }
      ]

      let data
      switch (filterData[i].type) {
        case FILTER_TYPES.CHECKBOX:
          data = filterData[i].content.map(item => {
            const { key, name } = item
            const newName = name.replace(/\./g, '')
            return {
              id: item.id,
              type: filterData[i].type,
              name: key || newName,
              label: newName,
              value: false
            }
          })

          data = transformArrToObj(data, 'name')

          break
        case FILTER_TYPES.DATE_PICKER:
          data = {
            [filterData[i].content[0].name]: {
              type: FILTER_TYPES.DATE_PICKER,
              name: filterData[i].content[0].name,
              label: labelDatePickers.DATE_FROM,
              value: today
            },
            [filterData[i].content[1].name]: {
              type: FILTER_TYPES.DATE_PICKER,
              name: filterData[i].content[1].name,
              label: labelDatePickers.DATE_TO,
              value: today
            }
          }
          break
        default:
          data = {
            type: filterData[i].type,
            name: filterData[i].name,
            label: filterData[i].text,
            value: filterData[i].type === FILTER_TYPES.TEXT_INPUT
              ? ''
              : 0
          }
      }

      filterValues = {
        ...filterValues,
        [filterData[i].name]: {
          label: filterData[i].text,
          selectedItems: 0,
          data
        }
      }
    }

    setValues(filterValues)
    setInitialState(filterValues)
    setBadges(badgeValues)
  }, [])

  useEffect(() => {
    if (correctDateRange.dateFrom && correctDateRange.dateTo) {
      const dateFrom = new Date(correctDateRange.dateFrom)
      const dateTo = new Date(correctDateRange.dateTo)
      const isValid = dateTo >= dateFrom

      setCorrectDateRange({
        ...correctDateRange,
        correctState: isValid
      })
    }
    if (values) {
      const filterCounter = Object.values(values).find(filter => filter.selectedItems !== 0)
      setfilterControl(filterCounter ? 1 : 0)
    }
  }, [values])

  return (
    createPortal(
      <Overlay
        tag='aside'
        role='dialog'
        tabIndex='-1'
        aria-modal='true'
        isShowing={isShowing}
        zIndex={zIndex}
        device={device}
      >
        <Wrapper isShowing={isShowing} screenWidth={screenWidth} device={device}>
          <Heading type='h4' weight='bold' align='left'>Filtros</Heading>
          <Container device={device} isShowing={isShowing}>
            <TabContainer device={device}>
              {values && renderTabs(filterData)}
            </TabContainer>
            <ContentContainer>
              {values && renderContent(filterData)}
            </ContentContainer>
          </Container>
        </Wrapper>
      </Overlay>,
      document.body
    )
  )
}

Filter.propTypes = {
  device: PropTypes.number,
  close: PropTypes.func,
  loadBadges: PropTypes.func,
  filterData: PropTypes.array,
  isShowing: PropTypes.bool.isRequired,
  zIndex: PropTypes.number
}

export default Filter
