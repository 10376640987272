export const key = 'SERVICE_PROVIDER_PROFILE'

export const SERVICE_PROVIDER_PROFILE = {
  GET_SERVICE_PROVIDER_PROFILE: 'SERVICE_PROVIDER_PROFILE/GET_DATA',
  SUCCESS: 'SERVICE_PROVIDER_PROFILE/GET_SUCCESS',
  ERROR: 'SERVICE_PROVIDER_PROFILE/GET_ERROR',
  CLEAR: 'SERVICE_PROVIDER_PROFILE/CLEAR',

  EDIT_SERVICE_PROVIDER_PROFILE: 'SERVICE_PROVIDER_PROFILE/PROFILE/EDIT',
  EDIT_SERVICE_PROVIDER_PROFILE_INFORMATION: 'SERVICE_PROVIDER_PROFILE/PROFILE/EDIT/INFORMATION',
  SERVICE_PROVIDER_PROFILE_UPDATED: 'SERVICE_PROVIDER_PROFILE/PROFILE/EDIT/UPDATED',

  CREATE_SERVICE_PROVIDER_BANK_ACCOUNT: 'SERVICE_PROVIDER_PROFILE/CREATE/BANK_ACCOUNT',
  EDIT_SERVICE_PROVIDER_BANK_ACCOUNT: 'SERVICE_PROVIDER_PROFILE/EDIT/BANK_ACCOUNT',
  DELETE_SERVICE_PROVIDER_BANK_ACCOUNT: 'SERVICE_PROVIDER_PROFILE/DELETE/BANK_ACCOUNT',
  BANK_ACCOUNTS_UPDATED: 'SERVICE_PROVIDER_PROFILE/UPDATE/BANK_ACCOUNT',

  SERVICE_PROVIDER_GET_MODAL_DATA: 'SERVICE_PROVIDER_PROFILE/GET_MODAL_DATA',
  SERVICE_PROVIDER_GET_MODAL_DATA_INFORMATION: 'SERVICE_PROVIDER_PROFILE/GET_MODAL_DATA/INFORMATION',

  MESSAGE_INFORMATION: 'SERVICE_PROVIDER_PROFILE/MESSAGE_INFORMATION',
  CLEAR_MESSAGE_INFORMATION: 'SERVICE_PROVIDER_PROFILE/EDIT_PROFILE/CLEAR_MESSAGE_INFORMATION',

  GET_DETAILS: 'SERVICE_PROVIDER_PROFILE/GET_DETAILS',
  GET_DETAILS_SUCCESS: 'SERVICE_PROVIDER_PROFILE/GET_DETAILS_SUCCESS',
  GET_DETAILS_ERROR: 'SERVICE_PROVIDER_PROFILE/GET_DETAILS_ERROR'
}

export default key
