import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectFrmCategories = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectFrmCategories, state => get(state, 'loading', null)
)

const selectLoadingFrmCategories = createSelector(
  selectFrmCategories, state => get(state, 'frmCategoriesLoading', null)
)

const selectSuccess = createSelector(
  selectFrmCategories, state => get(state, 'frmCategoriesList', [])
)

const selectColumns = createSelector(
  selectFrmCategories, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectFrmCategories, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectFrmCategories, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectFrmCategories, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectFrmCategories, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectFrmCategories, state => get(state, 'dataFiltersError', null)
)

const selectSetFrmCategoryInformation = createSelector(
  selectFrmCategories, state => get(state, 'frmCategoryInformation', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingFrmCategories,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,
  SET_FRM_CATEGORY_INFORMATION: selectSetFrmCategoryInformation
}
