import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { ATAE_BANK_ACCOUNTS_ACTIONS } from './actions'
import { ATAE_BANK_ACCOUNTS } from './types'

const apiCalls = {
  getBankAccounts: token => (
    Axios('BankAccount',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createBankAccount: ({ data, token }) => (
    Axios.post('BankAccount/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editBankAccount: ({ bankAccountId, data, token }) => (
    Axios.post(`BankAccount/${bankAccountId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteBankAccount: ({ bankAccountId, token }) => (
    Axios.delete(`BankAccount/${bankAccountId}/delete`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => (
    Axios.get('Currency',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBanks: token => (
    Axios.get('Banks',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getAtaeBanksAccounts ({ payload }) {
  try {
    const { data: ataeBankAccounts } = yield call(apiCalls.getBankAccounts, payload)

    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.SUCCESS(ataeBankAccounts))
  } catch (error) {
    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.ERROR(error))
  }
}

export function* getAtaBankAccountsModalData ({ payload }) {
  try {
    const { data: banksData } = yield call(apiCalls.getBanks, payload)
    const { data: currenciesData } = yield call(apiCalls.getCurrencies, payload)
    const modalDataObj = {
      banksData,
      currenciesData
    }

    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.ATAE_MODAL_DATA_INFORMATION(modalDataObj))
  } catch (error) {
    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* setAtaeBankAccounts ({ payload }) {
  try {
    const { type, bankAccountId, data, token } = payload
    switch (type) {
      case FUNCTION_TYPE.ADD:
        yield call(apiCalls.createBankAccount, { data, token })
        break
      case FUNCTION_TYPE.EDIT:
        yield call(apiCalls.editBankAccount, { bankAccountId, data, token })
        break
      default:
        yield call(apiCalls.deleteBankAccount, { bankAccountId, token })
    }
    const { status, data: bankAccountsUpdated } = yield call(apiCalls.getBankAccounts, token)

    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.SUCCESS(bankAccountsUpdated))
    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.MESSAGE_INFORMATION({ status }))
  } catch (error) {
    yield put(ATAE_BANK_ACCOUNTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(ATAE_BANK_ACCOUNTS.GET_ATAE_BANK_ACCOUNTS, getAtaeBanksAccounts)

  yield takeLatest(ATAE_BANK_ACCOUNTS.ATAE_GET_MODAL_DATA, getAtaBankAccountsModalData)
  yield takeLatest(ATAE_BANK_ACCOUNTS.CREATE_ATAE_BANK_ACCOUNT, setAtaeBankAccounts)
  yield takeLatest(ATAE_BANK_ACCOUNTS.EDIT_ATAE_BANK_ACCOUNT, setAtaeBankAccounts)
  yield takeLatest(ATAE_BANK_ACCOUNTS.DELETE_ATAE_BANK_ACCOUNT, setAtaeBankAccounts)
}
