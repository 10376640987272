import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, bold, large, medium, regular } from '@global/fonts'
import { ModalDataContainer, MyButtonLink } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

export const ModalDataContainerFrmEditProfile = styled(ModalDataContainer)`

  & > :nth-child(1) { 
    width: 100%;
  }

  & > :nth-child(2) { 
    width: 100%;
  }
`

export const DataContainer = styled.div`
  ${roboto};
  width: 100%;
  height: 100%;

  background-color: ${colors.background.regular};
  padding: ${spacing.two};
  border-radius: ${spacing.one};
  margin-bottom: ${spacing.two};
  
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;


  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 3;
`

export const Tbody = styled.tbody`
  width: 100%;
  height: fill;
`

export const Tr = styled.tr`
  width: available;
`

export const Td = styled.td` 
  width: ${({ widthPerc }) => (widthPerc ? '35%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`

export const PersonalData = styled.table`
  ${roboto};
  ${regular};
  width: 100%;
  height: auto;
  flex: 2;
  margin-bottom: ${({ device }) => (device < BREAKPOINTS.TABLET ? spacing.two : 0)};
`

export const LinkContainer = styled.div`
  height: 100%;
  width: ${({ device }) => (device < BREAKPOINTS.TABLET ? '100%' : null)};
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'row' : 'column')};
  justify-content: space-between;
  align-items: flex-end;

  flex: 1;

  & > :last-child {
    align-self: end;
    flex: 1;
  }
`
export const SalaryContainer = styled.div`
  height: fit-content!important;
  width: 100%;
  padding: ${spacing.two};
  background-color: ${colors.background.secondary};
  flex: 1;
`
export const SbmContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: ${spacing.one};
  flex: 1;
  & > :first-child {
    color: ${colors.grays.regular};
    flex: 4;
  }

  & > :last-child {
    flex: 1;
    ${bold};
  }
`
export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  margin-right: ${spacing.half};
`
