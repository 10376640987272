export const filetypeStrConstructor = (types, acceptedTypes) => {
  if (types.length === 0) return ''

  let str = ''
  const separator = ', '

  for (let i = 0; i < types.length; i++) {
    const fileExtention = types[i]
    str = acceptedTypes.find(item => item.name === fileExtention).type
    str += separator
  }
  return str
}

export const converterMbInBytes = (megaBytes = 1) => megaBytes * 1048576
