export const key = 'IMPORTS'

export const IMPORTS = {
  GET: 'IMPORTS/GET_LIST',
  SUCCESS: 'IMPORTS/GET_LIST/SUCCESS',
  ERROR: 'IMPORTS/GET_LIST/ERROR',
  CLEAR: 'IMPORTS/CLEAR_LIST',
  SEND_FILTER: 'IMPORTS/FILTER/SEND',
  GET_FILTER_DATA: 'IMPORTS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'IMPORTS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'IMPORTS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'IMPORTS/FILTER/CLEAR',
  GET_MODAL_TYPES: 'IMPORTS/MODAL/GET_MODAL_TYPES',
  ADD_IMPORT: 'IMPORTS/ADD_IMPORT',
  ADD_IMPORT_INFORMATION: 'IMPORTS/ADD_IMPORT_INFORMATION',

  UPLOAD_FILE_INFO: 'IMPORTS/UPLOAD_FILE/INFO',
  UPLOAD_FILE_INFO_CLEAR: 'IMPORTS/UPLOAD_FILE/INFO/CLEAR'
}

export default key
