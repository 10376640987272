import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { HISTORY_TYPES } from '@global/constants'

import { EMPLOYEE_HISTORY_ACTIONS } from './actions'
import { EMPLOYEE_HISTORY } from './types'

const apiCalls = {
  getEmploymentHistory: ({ personId, token }) => (
    Axios.get(`EmploymentRecord/listbyperson?personId=${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateHistory: ({ personId, token }) => (
    Axios.get(`AffiliateRecord/listbyperson?personId=${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmHistory: ({ personId, token }) => (
    Axios.get(`FRMRecord/listbyperson?personId=${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getEmployeeHistory ({ payload }) {
  try {
    const { data, token } = payload
    const { historyType, personId } = data
    let res = null
    switch (historyType) {
      case HISTORY_TYPES.EMPLOYMENT:
        res = yield call(apiCalls.getEmploymentHistory, { personId, token })
        break
      case HISTORY_TYPES.AFFILIATE:
        res = yield call(apiCalls.getAffiliateHistory, { personId, token })
        break
      default:
        res = yield call(apiCalls.getFrmHistory, { personId, token })
        break
    }

    yield put(EMPLOYEE_HISTORY_ACTIONS.SUCCESS(res.data))
  } catch (error) {
    yield put(EMPLOYEE_HISTORY_ACTIONS.ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(EMPLOYEE_HISTORY.GET_EMPLOYEE_HISTORY, getEmployeeHistory)
}
