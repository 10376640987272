import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Axios from '@axios/'
import { CancelToken } from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import { TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import TextArea from '@components/inputs/inputs/components/textArea'
import FileUploader from '@components/fileUploader'
import Icon from '@components/icon'
import CircularProgressBar from '@components/circularProgressBar'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PERSON_ACTIONS } from '../../redux/actions'

import {
  ModalDataContainer,
  ButtonContainer,
  DataFileContainer,
  FileUploaderContainer,
  LoaderContainer
} from '../../styles'
import { typeAccept } from '../../data'

const FileDataModal = ({ type, token, close, uploadFile }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [uploaded, setUploaded] = useState()
  const [description, setDescription] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [disabledButton, setDisabledButton] = useState(true)
  const [fileToSend, setFileToSend] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorFileUpload, setErrorFileUpload] = useState(false)
  const cancelFileUpload = useRef(null)
  const childRef = useRef()

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleTextArea = val => {
    setDescription(val)
  }

  const errorControl = objFile => {
    if (!objFile) setDisabledButton(true)
    else setDisabledButton(false)
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      setErrorFileUpload('LA CARGA FUE CANCELADA.')
      cancelFileUpload.current('LA CARGA FUE CANCELADA.')
      setShowLoader(false)
      childRef.current.cancelFileaUpload()
      setTimeout(() => setErrorFileUpload(false), 4000)
    }
  }

  const handleClick = () => {
    setShowLoader(true)
    Axios.post('File/upload', fileToSend, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        const percentCompleted = Math.round((loaded / total) * 100)
        setUploaded(percentCompleted)
      },
      cancelToken: new CancelToken(cancel => {
        cancelFileUpload.current = cancel
      })
    })
      .then(res => {
        const { data } = res
        uploadFile({ description: description.value, fileId: data.id })
        close()
      })
      .catch(error => {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: error.message || `Error ${error.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
        setTimeout(() => {
          close()
        }, 2500)
      })
  }

  useEffect(() => {
    errorControl(fileToSend)
  }, [fileToSend])

  useEffect(() => {
    dispatch(PERSON_ACTIONS.GET_TYPES({ type, token }))
  }, [])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer marginFirstChild file>
        <TextArea
          label='Descripción'
          size='small'
          color='primary'
          onChange={handleTextArea}
          value={description.value}
          disabled={showLoader}
          resize='none'
        />
        <DataFileContainer direction={errorFileUpload} device={device} showLoader={showLoader} name='fileContainer'>
          <FileUploaderContainer device={device} name='FileUploaderContainer'>
            {showLoader
             && (
             <Icon
               name='close'
               backgroundColor='background'
               size='medium'
               color='grays'
               onClick={cancelUpload}
             />
             )}
            {!errorFileUpload
            && (
            <FileUploader
              ref={childRef}
              text='Subir archivo o arrastrar. Tipos de archivos: .pdf, .doc, .xlsx, .jpg, .png'
              typeFile='file'
              maxSizeInMB={100}
              sendFile={setFileToSend}
              showDeleteIcon={!showLoader}
              disabled={showLoader}
              fileAccept={typeAccept}
            />
            )}
          </FileUploaderContainer>
          {showLoader && (
            <LoaderContainer>
              <CircularProgressBar progress={uploaded} width='70' height='70' />
            </LoaderContainer>
          )}
          {errorFileUpload
            && (
              <Text color='error' size='large' align='left' weight='bold'>{errorFileUpload}</Text>
            )}
        </DataFileContainer>
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' name='buttonContainer'>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={disabledButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default FileDataModal

FileDataModal.propTypes = {
  type: PropTypes.string,
  token: PropTypes.string,
  close: PropTypes.func,
  uploadFile: PropTypes.func
}
