import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS, MONTHS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { ModalDataContainer, ButtonContainer, TextContainerModal } from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput, CheckboxInput } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { INCOME_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddDepositModal = ({ close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const fullYear = new Date().getFullYear()
  const [amount, setAmount] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [source, setSource] = useState()
  const [selectedOptionSource, setSelectedOptionSource] = useState()
  const [notificationDate, setNotificationDate] = useState(new Date())
  const [paymentMadeDate, setPaymentMadeDate] = useState(new Date())
  const [paymentConfirmedDate, setPaymentConfirmedDate] = useState(new Date())
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [optionSelectedMonth, setOptionSelectedMonth] = useState()
  const [year, setYear] = useState({
    value: `${fullYear}`,
    name: '',
    error: ''
  })
  const [description, setDescription] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: false
  })

  const modalData = useSelector(SELECTORS.MODAL_DATA_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if (selectedOptionSource
       && optionSelectedMonth
       && (year.value && !year.error)
       && (paymentConfirmedDate >= paymentMadeDate)
       && (paymentMadeDate >= notificationDate)) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setNotificationDate(null)
    setPaymentMadeDate(null)
    setPaymentConfirmedDate(null)
    setSelectedOptionSource(null)
    setDescription({
      name: '',
      value: '',
      error: ''
    })
  }

  const handleDropdownChange = val => {
    const { name } = val
    if (name === 'source') setSelectedOptionSource(val.value)
    else setOptionSelectedMonth(val.value)
  }

  const handleInputChange = val => {
    const { error, name } = val
    if (error && name === 'amount') {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    switch (name) {
      case 'amount':
        setAmount(val)
        break
      case 'year':
        setYear(val)
        break
      default:
        setDescription(val)
        break
    }
  }

  const handleClick = () => {
    const newDeposit = {
      amount: +amount.value,
      originId: selectedOptionSource.id,
      notificationDate,
      paymentMadeDate,
      paymentConfirmedDate,
      month: optionSelectedMonth?.id + 1,
      year: year?.value,
      description: description?.value,
      isExtraPayment: checked?.value
    }
    saveData(newDeposit)
    cleanFields()
    close()
  }

  const handleCheckboxChange = val => setChecked(val)

  useEffect(() => {
    if (currentToken) dispatch(INCOME_ACTIONS.GET_MODAL_DATA(currentToken.token))
  }, [])

  useEffect(() => {
    if (modalData) {
      const { status } = modalData
      if (status === 200) {
        const { objData } = modalData
        setSource(changeNameForLabel(objData.source))
        setFlag(true)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalData.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [modalData])

  useEffect(() => {
    errorControl()
  }, [selectedOptionSource, amount, paymentConfirmedDate, paymentMadeDate, notificationDate, optionSelectedMonth, year])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
     && (
     <>
       <ModalDataContainer flexDirection='column'>
         <Input
           type={INPUT_TYPES.NUMBER}
           showType={INPUT_TYPES.TEXT}
           name='amount'
           label='Monto'
           placeholder='Ingrese monto'
           color='primary'
           value={amount.value}
           onChange={handleInputChange}
           onBlur={() => {}}
           height='small'
           error={amount.error}
           bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
         />
         <CustomDatePicker
           name='notificationDate'
           title='Fecha de Notificación'
           color='primary'
           onChange={setNotificationDate}
           yearsToShow={100}
           justifyContentButton='space-between'
           block
         />
         <CustomDatePicker
           name='paymentMadeDate'
           title='Fecha de Pago'
           color='primary'
           onChange={setPaymentMadeDate}
           yearsToShow={100}
           justifyContentButton='space-between'
           block
         />
         <CustomDatePicker
           name='paymentConfirmedDate'
           title='Fecha de Cobro'
           color='primary'
           onChange={setPaymentConfirmedDate}
           yearsToShow={100}
           justifyContentButton='space-between'
           block
         />
         <Input
           type={INPUT_TYPES.NUMBER}
           showType={INPUT_TYPES.TEXT}
           name='year'
           label='Año'
           placeholder='Ingrese año'
           color='primary'
           value={year.value}
           onChange={handleInputChange}
           onBlur={() => {}}
           height='small'
           error={year.error}
           bits={[{ icon: 'edit_calendar', position: 'left', colorBit: 'tertiary' }]}
         />
         <Dropdown
           name='month'
           label='Mes'
           placeholder='Seleccionar'
           options={MONTHS}
           value={optionSelectedMonth}
           onChange={handleDropdownChange}
           color='primary'
           height='small'
           block
         />
         <Dropdown
           name='source'
           label='Origen'
           placeholder='Seleccione'
           options={source}
           value={selectedOptionSource}
           onChange={handleDropdownChange}
           color='primary'
           height='small'
           block
         />
         <CheckboxInput
           label='Es aguinaldo'
           name='checkbox'
           color='primary'
           value={checked.value}
           onChange={handleCheckboxChange}
         />
         <TextInput
           name='description'
           label='Descripción'
           placeholder='Ingrese descripción'
           value={description.value}
           height='small'
           color='primary'
           onChange={handleInputChange}
           bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
         />
       </ModalDataContainer>
       <TextContainerModal>
         {paymentConfirmedDate?.setHours(0, 0, 0, 0) < paymentMadeDate?.setHours(0, 0, 0, 0)
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La fecha de cobro tiene que ser posterior a la fecha de pago.
           </Text>
           )}
         {paymentMadeDate?.setHours(0, 0, 0, 0) < notificationDate?.setHours(0, 0, 0, 0)
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La fecha de pago tiene que ser posterior a la fecha de notificación.
           </Text>
           )}
       </TextContainerModal>
       <ButtonContainer justifyContent='flex-end' modal>
         <SolidButton
           size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
           color='primary'
           text='Guardar'
           onClick={handleClick}
           disabled={showButton}
           block={device === BREAKPOINTS.MOBILE}
         />
       </ButtonContainer>
       <Toast
         title={messageToast.title}
         message={messageToast.message}
         color={messageToast.color}
         icon={messageToast.icon}
         isShowing={isShowing}
         position={TOAST_POSITION.rightTop}
         onClick={() => setIsShowing(false)}
       />
     </>
     )}

    </>
  )
}

export default AddDepositModal

AddDepositModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.object
}
