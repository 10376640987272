import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { FRM_CATEGORIES_ACTIONS } from './actions'
import { FRM_CATEGORIES } from './types'

const apiCalls = {
  getFrmCategories: token => (
    Axios.get('FRMRecordCategory?includeDeleted=false',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addFrmCategories: ({ data, token }) => (
    Axios.post('FRMRecordCategory/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteFrmCategories: ({ frmCategoryId, token }) => (
    Axios.delete(`FRMRecordCategory/${frmCategoryId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editFrmCategories: ({ frmCategoryId, data, token }) => (
    Axios.post(`FRMRecordCategory/${frmCategoryId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getAllFrmCategories ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getFrmCategories, payload)
    yield put(FRM_CATEGORIES_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(FRM_CATEGORIES_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(FRM_CATEGORIES_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(FRM_CATEGORIES_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* setFrmCategories ({ payload }) {
  try {
    const { type, frmCategoryId, data, token } = payload
    let status = null
    switch (type) {
      case FUNCTION_TYPE.ADD: {
        const res = yield call(apiCalls.addFrmCategories, { data, token })
        status = res.status
        break
      }
      case FUNCTION_TYPE.DELETE: {
        const res = yield call(apiCalls.deleteFrmCategories, { frmCategoryId, token })
        status = res.status
        break
      }
      default: {
        const res = yield call(apiCalls.editFrmCategories, { frmCategoryId, data, token })
        status = res.status
        break
      }
    }
    const { data: frmCategorysUpdated } = yield call(apiCalls.getFrmCategories, token)

    yield put(FRM_CATEGORIES_ACTIONS.SUCCESS(frmCategorysUpdated))
    yield put(FRM_CATEGORIES_ACTIONS.SET_FRM_CATEGORY_INFORMATION(status))
  } catch (error) {
    const { response, message } = error
    yield put(FRM_CATEGORIES_ACTIONS.SET_FRM_CATEGORY_INFORMATION({ status: response.status, message }))
  }
}

export default function* saga () {
  yield takeLatest(FRM_CATEGORIES.GET, getAllFrmCategories)
  yield takeLatest(FRM_CATEGORIES.GET_FILTER_DATA, FilterData)
  yield takeLatest(FRM_CATEGORIES.SET_FRM_CATEGORY, setFrmCategories)
}
