import { IMPORTS } from './types'

export const getImports = payload => ({
  type: IMPORTS.GET,
  payload
})

export const getImportsSuccess = payload => ({
  type: IMPORTS.SUCCESS,
  payload
})

export const getImportsError = payload => ({
  type: IMPORTS.ERROR,
  payload
})

export const ClearImportsList = payload => ({
  type: IMPORTS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: IMPORTS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: IMPORTS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: IMPORTS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: IMPORTS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: IMPORTS.FILTER_DATA_CLEAR
})

export const getModalTypes = payload => ({
  type: IMPORTS.GET_MODAL_TYPES,
  payload
})

export const uploadFileInfo = payload => ({
  type: IMPORTS.UPLOAD_FILE_INFO,
  payload
})

export const uploadFileInfoClear = () => ({
  type: IMPORTS.UPLOAD_FILE_INFO_CLEAR
})

export const IMPORTS_ACTIONS = {
  GET: getImports,
  SUCCESS: getImportsSuccess,
  ERROR: getImportsError,
  CLEAR: ClearImportsList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  GET_MODAL_TYPES: getModalTypes,
  UPLOAD_FILE_INFO: uploadFileInfo,
  UPLOAD_FILE_INFO_CLEAR: uploadFileInfoClear
}
