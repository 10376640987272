import { spacing } from '@global/theme'

export const PADDING_TOP_PAGE = spacing.six
export const PADDING_RIGHT_PAGE = spacing.oneAndAHalf
export const PADDING_BOTTOM_PAGE = spacing.oneAndAHalf

export const PADDING_PAGE_REGULAR = `${PADDING_TOP_PAGE} ${PADDING_RIGHT_PAGE} ${PADDING_BOTTOM_PAGE} 0`
export const PADDING_PAGE_MOBILE = `${spacing.sevenAndAHalf} ${PADDING_RIGHT_PAGE} ${PADDING_BOTTOM_PAGE}`

export const NAV_WIDTH = {
  DESKTOP: 248,
  MOBILE: 73
}
