import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectAtaeBankAccounts = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectAtaeBankAccounts, state => get(state, 'loading', null)
)

const selectAtaeBankAccountsSuccess = createSelector(
  selectAtaeBankAccounts, state => get(state, 'ataeBankAccounts', null)
)

const selectError = createSelector(
  selectAtaeBankAccounts, state => get(state, 'error', null)
)

const selectMessageInformation = createSelector(
  selectAtaeBankAccounts, state => get(state, 'messageInformation', null)
)

const selectLoadingBankAccounts = createSelector(
  selectAtaeBankAccounts, state => get(state, 'loadingBankAccounts', null)
)

const selectModalData = createSelector(
  selectAtaeBankAccounts, state => get(state, 'modalData', null)
)

export default {
  ATAE_BANK_ACCOUNTS_SUCCESS: selectAtaeBankAccountsSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  MESSAGE_INFORMATION: selectMessageInformation,
  LOADING_BANK_ACCOUNTS: selectLoadingBankAccounts,
  MODAL_DATA: selectModalData
}
