export const columnData = [
  { name: 'Código', widthCol: '10%', label: 'Code' },
  { name: 'Liquidación', widthCol: '5%', label: 'Settlement' },
  { name: 'Concepto', widthCol: '10%', label: 'Concept' },
  { name: 'Entidad', widthCol: '10%', label: 'Entity' },
  { name: 'Divisa', widthCol: '5%', label: 'Currency' },
  { name: 'Monto', widthCol: '5%', label: 'Total' },
  { name: 'Estado', widthCol: '10%', label: 'Status' },
  { name: 'F. de vencimiento', widthCol: '15%', label: 'DueDate' },
  { name: 'Monto cobrado', widthCol: '5%', label: 'TotalPaid' },
  { name: 'Descripción', widthCol: '10%', label: 'Description' },
  { name: 'Registro de ingreso', widthCol: '5%', label: 'PaymentRecord' }
]

export const FILTER_TYPE = {
  CONTRIBUTIONS: 'contributions',
  CONTRIBUTION_PAYMENTS: 'contributionPayments'
}

export const COLUMN_CONTRIBUTION_PAYMENTS = [
  { name: 'Código', widthCol: '15%', label: 'Code' },
  { name: 'Concepto', widthCol: '20%', label: 'Concept' },
  { name: 'Origen', widthCol: '15%', label: 'OriginName' },
  { name: 'Divisa', widthCol: '15%', label: 'Currency' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'Contribucion', widthCol: '20%', label: 'Contribution' },
  { name: 'Estado', widthCol: '15%', label: 'Status' },
  { name: 'Mes', widthCol: '15%', label: 'Month' },
  { name: 'Año', widthCol: '15%', label: 'Year' }
]
