import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { FUNCTION_TYPE, TYPE_PERSONAL_INFORMATION_MODAL, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput, CheckboxInput } from '@components/inputs/inputs'
import { Input } from '@components/inputs/inputs/baseInput/baseInput'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PERSON_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { ModalDataContainer, RigthModalDataContainer, ButtonContainer, MyLabel } from '../../styles'

const PersonalDataModal = ({ type, functionType, oldData, saveData, token }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [options, setOptions] = useState([])
  const [selectedType, setSelectedType] = useState()
  const [optionsDepartments, setOptionsDepartments] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState()
  const [optionsBanks, setOptionsBanks] = useState()
  const [selectedBanks, setSelectedBank] = useState()
  const [optionsCurrencies, setOptionsCurrencies] = useState()
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [firstInput, setFirstInput] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [branch, setBranch] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [description, setDescription] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [checked, setChecked] = useState(false)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)
  const typesOptions = useSelector(SELECTORS.TYPES)
  const departments = useSelector(SELECTORS.DEPARTMENTS)
  const banksData = useSelector(SELECTORS.BANKS)
  const currenciesData = useSelector(SELECTORS.CURRENCIES)

  const handleFirstInputChange = val => setFirstInput(val)

  const handleThirdInputChange = val => {
    const { name } = val
    if (name === 'branch') setBranch(val)
    else setDescription(val)
  }

  const errorControl = dataType => {
    switch (dataType) {
      case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
        if (selectedType && (firstInput.value && !firstInput.error) && selectedDepartment) setShowButton(false)
        else setShowButton(true)
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name: {
        if (selectedBanks && (firstInput.value && !firstInput.error) && selectedCurrency) setShowButton(false)
        else setShowButton(true)
        break
      }
      default: {
        if (selectedType && (firstInput.value && !firstInput.error)) setShowButton(false)
        else setShowButton(true)
      }
    }
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    switch (name) {
      case 'deparments':
        setSelectedDepartment(value)
        break
      case 'dropdown_type':
        setSelectedType(value)
        break
      case 'banks':
        setSelectedBank(value)
        break
      default:
        setSelectedCurrency(value)
    }
  }

  const handleCheckboxChange = val => setChecked(val)

  const handleClick = () => {
    let body = {}
    switch (type) {
      case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
        body = {
          streetAddress1: firstInput.value,
          streetAddress2: '',
          postalCode: '',
          city: '',
          countryId: 1,
          addressTypeId: selectedType.id,
          departmentId: selectedDepartment.id,
          isPrimary: checked.value
        }
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
        body = {
          phoneNumber: firstInput.value,
          phoneTypeId: selectedType.id,
          isPrimary: checked.value
        }
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
        body = {
          emailAddress: firstInput.value,
          emailTypeId: selectedType.id,
          isPrimary: checked.value
        }
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name: {
        body = {
          accountNumber: firstInput.value,
          bankId: selectedBanks.id,
          branch: branch.value,
          description: description.value,
          isPrimary: checked.value,
          currencyId: selectedCurrency.id
        }
        break
      }
      default: {
        body = {
          documentNumber: firstInput.value,
          documentTypeId: selectedType.id
        }
        break
      }
    }

    const sendObj = functionType === FUNCTION_TYPE.ADD
      ? { body, type, functionType }
      : { body, type, functionType, itemId: oldData.id }
    saveData(sendObj)
  }

  const renderDataFields = dropdownType => {
    let label = null
    let firstField = null
    let secondField = null
    let thirdField = null

    switch (dropdownType) {
      case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name:
        label = TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.label
        firstField = (
          <TextInput
            name={TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name}
            label='Dirección'
            placeholder='Ingrese dirección'
            value={firstInput.value}
            height='small'
            color='primary'
            onChange={handleFirstInputChange}
            bits={[{ icon: 'home', position: 'left', colorBit: 'tertiary' }]}
            required
          />
        )
        secondField = (
          <Dropdown
            id='block'
            className='block'
            name='deparments'
            label='Departamento'
            placeholder='Seleccione'
            options={optionsDepartments}
            value={selectedDepartment}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
            required
          />
        )
        break
      case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name:
        label = TYPE_PERSONAL_INFORMATION_MODAL.PHONE.label
        firstField = (
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label='Número'
            placeholder='Ingrese télefono'
            name='phone'
            value={firstInput.value}
            error={firstInput.error}
            height='small'
            color='primary'
            onChange={handleFirstInputChange}
            onBlur={() => {}}
            bits={[{ icon: 'call', position: 'left', colorBit: 'tertiary' }]}
            required
          />
        )
        break
      case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name:
        label = TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.label
        firstField = (
          <Input
            type={INPUT_TYPES.EMAIL}
            label='Email'
            placeholder='Ingrese email'
            name='email'
            value={firstInput.value}
            error={firstInput.error}
            height='small'
            color='primary'
            onChange={handleFirstInputChange}
            onBlur={() => {}}
            bits={[{ icon: 'contact_mail', position: 'left', colorBit: 'tertiary' }]}
            required
          />
        )
        break
      default:
        label = TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.label
        firstField = (
          <Dropdown
            name='banks'
            label='Banco'
            placeholder='Seleccione'
            options={optionsBanks}
            value={selectedBanks}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
            required
          />
        )
        secondField = (
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label='Número de cuenta'
            placeholder='Ingrese número de cuenta'
            name='ci'
            value={firstInput.value}
            error={firstInput.error}
            height='small'
            color='primary'
            onChange={handleFirstInputChange}
            onBlur={() => handleFirstInputChange(firstInput)}
            bits={[{ icon: 'pin', position: 'left', colorBit: 'tertiary' }]}
            required
          />
        )
        thirdField = (
          <>
            <Dropdown
              name='currencies'
              label='Divisa'
              placeholder='Seleccione'
              options={optionsCurrencies}
              value={selectedCurrency}
              onChange={handleDropdownChange}
              color='primary'
              height='small'
              block
              required
            />
            <TextInput
              name='branch'
              label='Sucursal'
              placeholder='Ingrese sucursal'
              value={branch.value}
              height='small'
              color='primary'
              onChange={handleThirdInputChange}
              bits={[{ icon: 'home', position: 'left', colorBit: 'tertiary' }]}
            />
            <TextInput
              name='description'
              label='Descripción'
              placeholder='Ingrese descripción'
              value={description.value}
              height='small'
              color='primary'
              onChange={handleThirdInputChange}
              bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
            />
          </>
        )
        break
    }
    const isPrimaryField = (
      <>
        <MyLabel>
          {label}
        </MyLabel>
        <CheckboxInput
          label='Primario'
          name='checkbox'
          color='primary'
          value={checked.value}
          onChange={handleCheckboxChange}
        />
      </>
    )

    return (
      <>
        {label !== TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.label
        && (
        <Dropdown
          name='dropdown_type'
          label={`Tipo de ${label}`}
          placeholder='Seleccionar'
          options={options}
          value={selectedType}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
          required
        />
        )}
        <RigthModalDataContainer>
          {firstField}
          {secondField}
          {thirdField}
          {isPrimaryField}
        </RigthModalDataContainer>
      </>
    )
  }

  useEffect(() => {
    errorControl(type)
  }, [selectedType, selectedDepartment, firstInput, selectedBanks, selectedCurrency])

  useEffect(() => {
    if (type !== TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name) {
      dispatch(PERSON_ACTIONS.GET_TYPES({ type, token }))
    }
    if (type === TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name) {
      dispatch(PERSON_ACTIONS.GET_DEPARTMENTS({ countryId: 1, token }))
    }
    if (type === TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name) {
      dispatch(PERSON_ACTIONS.GET_PERSON_BANKS(token))
    }
  }, [])

  useEffect(() => {
    if (typesOptions.length > 0) {
      setOptions(changeNameForLabel(typesOptions))
    }
  }, [typesOptions])

  useEffect(() => {
    if (departments.length > 0) {
      setOptionsDepartments(changeNameForLabel(departments))
    }
  }, [departments])

  useEffect(() => {
    if (banksData.status === 200) {
      setOptionsBanks(changeNameForLabel(banksData.personBanks))
    }
  }, [banksData])

  useEffect(() => {
    if (currenciesData.length > 0) {
      // id 4 is Basic Medic Salary
      setOptionsCurrencies(changeNameForLabel(currenciesData.filter(c => (c.id !== 3 && c.id !== 4))))
    }
  }, [currenciesData])

  useEffect(() => {
    if (functionType === FUNCTION_TYPE.EDIT) {
      setChecked({ name: 'checkbox', value: oldData.isPrimary })
      switch (type) {
        case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
          if (options && optionsDepartments) {
            setSelectedType(options.find(item => item.id === oldData.addressTypeId))
            setSelectedDepartment(optionsDepartments.find(item => item.id === oldData.departmentId))
            setFirstInput({
              ...firstInput,
              value: oldData.streetAddress1
            })
          }
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
          if (options) {
            const p = options.find(item => item.id === oldData.phoneTypeId)
            setSelectedType(p)
            setFirstInput({
              ...firstInput,
              value: oldData.phoneNumber
            })
          }
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
          if (options) {
            const e = options.find(item => item.id === oldData.emailTypeId)
            setSelectedType(e)
            setFirstInput({
              ...firstInput,
              value: oldData.emailAddress
            })
          }
          break
        }
        default: {
          if (optionsBanks && optionsCurrencies) {
            setFirstInput({
              ...firstInput,
              value: oldData.accountNumber
            })
            setSelectedBank(optionsBanks.find(item => item.id === oldData.bankId))
            setSelectedCurrency(optionsCurrencies.find(item => item.id === oldData.currencyId))
            setBranch({
              name: '',
              value: oldData.branch,
              error: ''
            })
            setDescription({
              name: '',
              value: oldData.description,
              error: ''
            })
          }
          break
        }
      }
      setFlag(true)
      errorControl(type)
    } else if (type === TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name) {
      if (options && optionsDepartments) setFlag(true)
    } else if (options) setFlag(true)
  }, [options, optionsDepartments, optionsBanks, optionsCurrencies])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer marginFirstChild device={device}>
          {renderDataFields(type)}
        </ModalDataContainer>
        <ButtonContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={() => setIsShowing(false)}
        />
      </>
      )}
    </>

  )
}

export default PersonalDataModal

PersonalDataModal.defaultProps = {
  oldData: null
}

PersonalDataModal.propTypes = {
  type: PropTypes.string,
  functionType: PropTypes.string,
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  token: PropTypes.string
}
