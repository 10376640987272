export const key = 'CONTRIBUTION_PROFILE'

export const CONTRIBUTION_PROFILE = {
  GET_CONTRIBUTION_PROFILE: 'CONTRIBUTION_PROFILE/GET_DATA',
  SUCCESS: 'CONTRIBUTION_PROFILE/GET_SUCCESS',
  ERROR: 'CONTRIBUTION_PROFILE/GET_ERROR',
  CLEAR: 'CONTRIBUTION_PROFILE/CLEAR',

  EDIT_CONTRIBUTION_PROFILE: 'CONTRIBUTION_PROFILE/PROFILE/EDIT',
  EDIT_CONTRIBUTION_PROFILE_INFORMATION: 'CONTRIBUTION_PROFILE/PROFILE/EDIT/INFORMATION',
  CONTRIBUTION_PROFILE_UPDATED: 'CONTRIBUTION_PROFILE/PROFILE/EDIT/UPDATED',
  GET_CONTRIBUTION_PROFILE_MODAL_DATA: 'CONTRIBUTION_PROFILE/EDIT_PROFILE/GET_MODAL_DATA',
  CONTRIBUTION_PROFILE_MODAL_DATA_INFORMATION: 'CONTRIBUTION_PROFILE/EDIT_PROFILE/MODAL_DATA_INFORMATION'
}

export default key
