import React, { useState, useRef, useEffect } from 'react'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import Icon from '@components/icon'

import { checkField } from '../../utils/validation'

import Bit from '../components/bit'
import propTypes from '../propTypes'

import { Wrapper, Container, StyledInput, Label, Message } from '../styles'

export const Input = props => {
  const {
    name,
    label,
    color,
    type,
    showType,
    value,
    placeholder,
    message,
    error,
    errorIcon,
    required,
    disabled,
    bits,
    hideError,
    noErrorCheck,
    onChange,
    onKeyDown,
    onBlur,
    height,
    min,
    select,
    removeValue
  } = props

  const inpt = useRef(null)
  const [leftBits, setLeftBits] = useState([])
  const [rightBits, setRightBits] = useState([])
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    const left = []
    const right = []
    bits.forEach(b => {
      if (b.position === 'left') left.push(b)
      if (b.position === 'right') right.push(b)
    })

    setLeftBits(left)
    setRightBits(right)
  }, [bits])

  const handleChange = ({ target }) => {
    let errorChange = noErrorCheck ? '' : checkField({ value: target.value, type, required })
    if (type === INPUT_TYPES.NUMBER && (min || (Number(min) === 0 && !Number.isNaN(min))) && !errorChange) {
      errorChange = target.value >= min ? '' : `${min} is the minimum`
    }
    onChange({ name, value: target.value, error: errorChange })
  }

  const handleFocus = () => {
    if (!inpt.current) return
    inpt.current.focus()
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
    if (type === INPUT_TYPES.NUMBER || type === INPUT_TYPES.EMAIL) onBlur()
  }

  const handleSelectText = () => {
    if (value && select) {
      if (!inpt.current) return
      inpt.current.select()
    }
  }

  const handleRemoveValue = () => {
    inpt.current.value = ''
    const ev = {
      target: {
        name: inpt.current.name,
        value: '',
        error: ''
      }
    }
    handleChange(ev)
  }

  useEffect(() => {
    handleSelectText()
  }, [select])

  const renderBit = ({ icon, text, colorBit, onClick, image, alt }) => (
    <Bit
      key={`bit-${icon || text}`}
      icon={icon}
      text={text}
      color={error ? 'error' : colorBit}
      disabled={disabled}
      onClick={onClick}
      image={image}
      alt={alt}
    />
  )

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={`${type}_${name}`} disabled={disabled} error={error} color={focus ? color : null}>
          {label}
          {required && <span> *</span>}
        </Label>
      )}

      <Container
        onClick={handleFocus}
        onBlur={handleBlur}
        color={color}
        error={error}
        disabled={disabled}
        type={type}
        onKeyDown={onKeyDown}
        tabIndex={0}
        onFocus={handleFocus}
      >
        {leftBits.map(b => renderBit(b))}

        <StyledInput
          ref={inpt}
          id={`${type}_${name}`}
          name={name}
          type={showType || type}
          value={removeValue ? undefined : value}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
          error={error}
          height={height}
          paddingLeft={leftBits.length}
          onKeyDown={onKeyDown}
        />

        {(removeValue && value)
          && <Icon name='close' size={height} color='system' onClick={handleRemoveValue} />}

        {rightBits.map(b => renderBit(b))}
        {error && errorIcon && <Bit icon='error' color='error' />}
      </Container>

      {(message && !error) && <Message disabled={disabled} color={focus ? color : null}>{message}</Message>}
      {(error && !hideError) && <Message error={error} disabled={disabled}>{error}</Message>}
    </Wrapper>
  )
}

Input.propTypes = propTypes

Input.defaultProps = {
  bits: []
}

export default Input
