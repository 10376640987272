import React, { useEffect } from 'react'

import PropTypes from 'prop-types'

import { spacing } from '@global/theme'

import { Text } from '@components/texts'
import Icon from '@components/icon'
import { GhostButton } from '@components/button'

import { Area, TextContainer, Rectangle } from './style'

const Toast = ({ title, message, color, icon, isShowing, onClick, position, timeOut }) => {
  useEffect(() => {
    const st = setTimeout(() => onClick(), timeOut || 5000)

    return () => {
      clearTimeout(st)
    }
  }, [isShowing])

  if (!isShowing) return false

  return (
    <Area
      color={color}
      position={position}
      name='areaToast'
    >
      <Rectangle color={color} marginLeft={spacing.one} />
      <Icon name={icon} size='xxlarge' color={color} />
      <TextContainer marginLeft={spacing.oneAndAHalf}>
        <Text text={title} size='large' align='left' weight='bold' color={color} />
        <Text text={message} size='small' align='left' />
      </TextContainer>
      <GhostButton size='large' text='X' onClick={() => onClick()} />
    </Area>
  )
}

Toast.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  isShowing: PropTypes.bool,
  position: PropTypes.string,
  timeOut: PropTypes.number
}
export default Toast
