import React, { useState } from 'react'
import PropTypes from 'prop-types'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'

import { formatDateBasic } from '@utils/helpers'
import { MINIMUM_DATE } from '@global/constants'

import Label from '@components/label'
import Button from '@components/button'
import Icon from '@components/icon'

import { Container, ButtonContainer } from './styles'

const CustomDatePicker = ({
  date,
  title,
  color,
  name,
  yearsToShow,
  onChange,
  forFilter,
  block,
  margin,
  justifyContentButton,
  empty,
  isClearable,
  dateType,
  disabled,
  minDate
}) => {
  registerLocale('es', es)
  let startDate = null
  if (date) {
    if (typeof date === 'string') startDate = new Date(date)
    else startDate = date
  } else if (date !== null) startDate = new Date()

  const [currentDate, setCurrentDate] = useState(empty ? null : startDate)
  const [formatDateType] = useState(dateType || 'long')

  const dateChange = selectedDate => {
    setCurrentDate(selectedDate)
    if (forFilter) {
      const dateForFilter = {
        name,
        value: selectedDate
      }
      onChange(dateForFilter)
    } else onChange(selectedDate)
  }

  const handleRemoveDate = () => dateChange(null)

  return (
    <Container color={color} margin={margin}>
      <Label name={name} size='medium' align='left' text={title} />
      <DatePicker
        selected={currentDate}
        onChange={dateChange}
        locale='es'
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={yearsToShow}
        minDate={new Date(minDate || MINIMUM_DATE)}
        scrollableYearDropdown
        customInput={(
          <ButtonContainer>
            <Button
              size='small'
              color='tertiary'
              type='ghost'
              icon='calendar_today'
              text={formatDateBasic(currentDate, formatDateType, false, false, false)}
              block={block}
              justifyContent={justifyContentButton}
              onClick={() => { }}
              disabled={disabled}
            />
            {isClearable && currentDate
              && (
                <Icon
                  name='close'
                  size='medium'
                  color='tertiary'
                  onClick={handleRemoveDate}
                />
              )}
          </ButtonContainer>
        )}
      />
    </Container>
  )
}

CustomDatePicker.defaultProps = {
  date: '',
  yearsToShow: 50,
  justifyContentButton: 'center'
}

CustomDatePicker.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
  yearsToShow: PropTypes.number,
  onChange: PropTypes.func,
  forFilter: PropTypes.bool,
  block: PropTypes.bool,
  margin: PropTypes.string,
  justifyContentButton: PropTypes.string,
  empty: PropTypes.bool,
  isClearable: PropTypes.bool,
  dateType: PropTypes.oneOf(['short', 'long']),
  disabled: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default CustomDatePicker
