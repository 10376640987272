import { IMPORT_PROFILE } from './types'

export const getImportProfile = payload => ({
  type: IMPORT_PROFILE.GET_IMPORT,
  payload
})

export const getImportProfileSuccess = payload => ({
  type: IMPORT_PROFILE.GET_IMPORT_SUCCESS,
  payload
})

export const getImportProfileError = payload => ({
  type: IMPORT_PROFILE.GET_IMPORT_ERROR,
  payload
})

export const clearImportProfile = () => ({
  type: IMPORT_PROFILE.CLEAR
})

export const downloadFile = payload => ({
  type: IMPORT_PROFILE.DOWNLOAD_FILE,
  payload
})

export const fileDownloaded = payload => ({
  type: IMPORT_PROFILE.FILE_DOWNLOADED,
  payload
})

export const getLogs = payload => ({
  type: IMPORT_PROFILE.GET_LOGS,
  payload
})

export const getLogsSuccess = payload => ({
  type: IMPORT_PROFILE.GET_LOGS_SUCCESS,
  payload
})

export const getLogsError = payload => ({
  type: IMPORT_PROFILE.GET_LOGS_ERROR,
  payload
})

export const applyFilter = payload => ({
  type: IMPORT_PROFILE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: IMPORT_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: IMPORT_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: IMPORT_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: IMPORT_PROFILE.FILTER_DATA_CLEAR
})

export const clearPendingProcessInfo = () => ({
  type: IMPORT_PROFILE.CLEAR_PENDING_PROCESS_INFO
})

export const IMPORT_PROFILE_ACTIONS = {
  GET_IMPORT: getImportProfile,
  GET_IMPORT_SUCCESS: getImportProfileSuccess,
  GET_IMPORT_ERROR: getImportProfileError,
  CLEAR: clearImportProfile,
  DOWNLOAD_FILE: downloadFile,
  FILE_DOWNLOADED: fileDownloaded,
  GET_LOGS: getLogs,
  GET_LOGS_SUCCESS: getLogsSuccess,
  GET_LOGS_ERROR: getLogsError,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  CLEAR_PENDING_PROCESS_INFO: clearPendingProcessInfo
}
