import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { rowsPerPage, TOAST_POSITION, TRANSLATION_OF_ROUTES, FUNCTION_TYPE, TOAST_PROPERTIES } from '@global/constants'
import { TOKEN_HELPERS, sortByKey, filterBodyBuilder } from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperHeading
} from '@global/styles'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import Icon from '@components/icon'
import Modal from '@components/modal'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import { Heading } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { BENEFITS_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapperHistory, PaginationWrapper } from './styles'
import AddAndEditBenefit from './components/addAndEditBenefit'

const Benefits = () => {
  useRedux()
  const { key, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [filterData, setFilterData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [badges, setBadges] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [removeBenefitModal, setRemoveBenefitModal] = useState(null)
  const [dataBenefitModal, setDataBenefitModal] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)
  const [parameters, setParameters] = useState()
  const [benefitTypes, setBenefitTypes] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const benefitInformation = useSelector(SELECTORS.SET_BENEFIT_INFORMATION)
  const benefitTypesData = useSelector(SELECTORS.BENEFIT_TYPES_SUCCESS)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getBenefits = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(BENEFITS_ACTIONS.GET_BENEFITS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order')
    const deleteIcon = (<Icon name='delete' backgroundColor='primary' size='medium' color='grays' />)
    orderedColumns = Object.values(colData).map(item => {
      if (item.name === 'Eliminar') {
        let obj = item
        obj = {
          ...obj,
          name: deleteIcon
        }
        return obj
      }
      return item
    })
    return orderedColumns
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setRemoveBenefitModal(null)
    setDataBenefitModal(null)
  }

  const removeBenefit = benefitId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const body = { pageNumber: 1, pageSize: selectedOption.value }
      dispatch(BENEFITS_ACTIONS.SET_BENEFIT({ type: FUNCTION_TYPE.DELETE, benefitId, data: body, token }))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const addOrEditbenefit = (benefit, functionType) => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const obj = {
        type: functionType,
        benefitId: benefit.id,
        data: benefit,
        token
      }
      dispatch(BENEFITS_ACTIONS.SET_BENEFIT(obj))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    handleCloseModal()
  }

  const handleSetBenefit = (benefit, functionType) => {
    benefit = { ...benefit, typeId: 1 }
    switch (functionType) {
      case FUNCTION_TYPE.ADD: {
        setModalTitle('Agregar un beneficio')
        setDataBenefitModal({
          functionType: FUNCTION_TYPE.ADD,
          oldData: null,
          saveData: addOrEditbenefit,
          currentToken
        })
        break
      }
      case FUNCTION_TYPE.DELETE: {
        const text = '¿Estás seguro que deseas borrar este beneficio?'
        setModalTitle('Eliminar beneficio')
        setRemoveBenefitModal({
          close: handleCloseModal,
          text,
          itemId: benefit.id,
          removeItem: removeBenefit,
          dataType: functionType
        })
        break
      }
      default: {
        setModalTitle('Editar un beneficio')
        setDataBenefitModal({
          functionType: FUNCTION_TYPE.EDIT,
          oldData: benefit,
          saveData: addOrEditbenefit,
          currentToken,
          close: handleCloseModal,
          setMessageToast,
          showToast: setIsShowing
        })
        break
      }
    }
    setOpen(true)
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      let newItem = item
      const deleteIcon = (
        <Icon
          name='cancel'
          size='medium'
          color='grays'
          onClick={() => handleSetBenefit(item, FUNCTION_TYPE.DELETE)}
        />
      )
      const downloadIcon = (
        <Icon name='edit' size='medium' color='grays' onClick={() => handleSetBenefit(item, FUNCTION_TYPE.EDIT)} />
      )

      newItem = {
        id: item.id,
        deleteFile: deleteIcon,
        cost: item.cost,
        name: item.name,
        frecuencyName: item.frecuencyName || benefitTypes.find(bt => bt.id === item.frequencyId)?.name || 'N/A',
        description: item.description,
        downloadFile: downloadIcon
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)
    const body = {
      pageNumber: itemSelected + 1,
      pageSize: selectedOption.value,
      orderByColumn: colNames[0],
      orderByDesc: typeOrder
    }
    getBenefits(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = activeFilters
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getBenefits(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickPaginationItem = n => {
    const body = {
      pageNumber: (n + 1),
      pageSize: selectedOption.value
    }
    getBenefits(body)
    setItemSelected(n)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      const filterRequest = { id: 4, token }
      dispatch(BENEFITS_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getBenefits(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getBenefits(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(BENEFITS_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getBenefits(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
      device={device}
    />
  )

  useEffect(() => {
    const body = { pageNumber: 1, pageSize: selectedOption.value }
    getBenefits(body)
    const fisrtCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.SETTING,
      url: SITE.SETTING
    }
    const secondCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.ENTITY_MANAGMENT,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}`
    }
    const thirdCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.BENEFITS,
      url: pathname
    }
    setBreadcrumbs([fisrtCrumb, secondCrumb, thirdCrumb])

    return () => {
      setBreadcrumbs([])
    }
  }, [key])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        dispatch(BENEFITS_ACTIONS.CLEAR())
        const body = { pageNumber: 1, pageSize: selectedOption.value }
        setActiveFilters(body)
        getBenefits(body)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (benefitTypesData) {
      setBenefitTypes(benefitTypesData)
    }
  }, [benefitTypesData])

  useEffect(() => {
    if (benefitTypes) {
      setColumns(customHeaderDataTable(fields, columnData))
      setTablelist(customBodyDataTable(items))
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
    }
  }, [items, totalPages])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (benefitInformation) {
      if (benefitInformation?.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: benefitInformation.message || `Error ${benefitInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
      setTimeout(() => dispatch(BENEFITS_ACTIONS.BENEFIT_INFORMATION_CLEAR()), 5000)
    }
  }, [benefitInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              <Heading
                type='h2'
                weight='bold'
                align='left'
                color='system'
              >
                {TRANSLATION_OF_ROUTES.BENEFITS}
              </Heading>
            </WrapperHeading>
            <FilterContainer columnStart={1} columnEnd={12} rowStart={3} rowEnd={3} deviceName={deviceName}>
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Beneficio'
                  icon='add'
                  onClick={() => handleSetBenefit(null, FUNCTION_TYPE.ADD)}
                />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapperHistory>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapperHistory>
            <PaginationWrapper>
              {tablelist.length > 0
               && (
               <PaginationGroup
                 deviceName={deviceName}
                 pages={pages}
                 itemSelected={itemSelected}
                 handleClickPaginationItem={n => handleClickPaginationItem(
                   n, activeFilters, selectedOption.value, getBenefits, setItemSelected
                 )}
                 options={rowsPerPage}
                 selectedOption={selectedOption}
                 handleDropdownChange={handleDropdownChange}
                 totalItems={totalItems}
                 itemsPerPage={tablelist.length}
               />
               )}
            </PaginationWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {removeBenefitModal
              && (
                <DeleteConfirmationModal
                  {...removeBenefitModal}
                />
              )}
              {dataBenefitModal
              && (
                <AddAndEditBenefit
                  {...dataBenefitModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default Benefits
