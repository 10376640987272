import { HISTORY_RECORD } from './types'

export const getHistoryRecord = payload => ({
  type: HISTORY_RECORD.GET_HISTORY_RECORD,
  payload
})

export const getHistoryRecordSuccess = payload => ({
  type: HISTORY_RECORD.SUCCESS,
  payload
})

export const getHistoryRecordError = payload => ({
  type: HISTORY_RECORD.ERROR,
  payload
})

export const ClearList = payload => ({
  type: HISTORY_RECORD.CLEAR,
  payload
})

export const downloadFileHistoryLog = payload => ({
  type: HISTORY_RECORD.DOWNLOAD_FILE,
  payload
})

export const fileMessageHistoryLog = payload => ({
  type: HISTORY_RECORD.FILE_MESSAGE,
  payload
})

export const HISTORY_RECORD_ACTIONS = {
  GET_HISTORY_RECORD: getHistoryRecord,
  SUCCESS: getHistoryRecordSuccess,
  ERROR: getHistoryRecordError,
  CLEAR: ClearList,
  DOWNLOAD_FILE: downloadFileHistoryLog,
  FILE_MESSAGE: fileMessageHistoryLog
}
