import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import People from '@containers/people'
import { PersonRouter } from './PersonRouter'

export const PeopleRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={url}
        component={People}
      />
      <PrivateRoute
        path={`${url}${SITE.PERSON}`}
        component={PersonRouter}
      />
    </Switch>
  )
}
