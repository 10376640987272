export const key = 'EXPORTS'

export const EXPORTS = {
  GET: 'EXPORTS/GET_LIST',
  SUCCESS: 'EXPORTS/GET_LIST/SUCCESS',
  ERROR: 'EXPORTS/GET_LIST/ERROR',
  CLEAR: 'EXPORTS/CLEAR_LIST',

  SEND_FILTER: 'EXPORTS/FILTER/SEND',
  GET_FILTER_DATA: 'EXPORTS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'EXPORTS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'EXPORTS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'EXPORTS/FILTER/CLEAR',

  GET_MODAL_DATA: 'EXPORTS/MODAL/GET_MODAL_DATA',
  MODAL_DATA_INFORMATION: 'EXPORTS/MODAL/MODAL_DATA_INFORMATION',

  MESSAGE_INFORMATION: 'EXPORTS/MESSAGE_INFORMATION',
  CLEAR_MESSAGE_INFORMATION: 'EXPORTS/CLEAR_MESSAGE_INFORMATION',
  CREATE_EXPORT: 'EXPORTS/CREATE',
  EXPORT_SUCCESS: 'EXPORTS/CREATE/SUCCESS',

  GET_PAYMENTS_EXPORTS: 'EXPORTS/MODAL/GET_PAYMENTS_EXPORTS',
  PAYMENTS_EXPORTS_INFORMATION: 'EXPORTS/MODAL/PAYMENTS_EXPORTS_INFORMATION',

  CLEAR_DATA: 'EXPORTS/MODAL/CLEAR_DATA',
  GET_CONCEPTS: 'EXPORTS/MODAL/GET_CONCEPTS',
  CONCEPTS_SUCCESS: 'EXPORTS/MODAL/CONCEPTS_SUCCESS'
}

export default key
