export const key = 'PAYMENT_PROFILE'

export const PAYMENT_PROFILE = {
  GET_PAYMENT_PROFILE: 'PAYMENT_PROFILE/GET_DATA',
  SUCCESS: 'PAYMENT_PROFILE/GET_SUCCESS',
  ERROR: 'PAYMENT_PROFILE/GET_ERROR',
  CLEAR: 'PAYMENT_PROFILE/CLEAR',

  EDIT_PAYMENT_PROFILE: 'PAYMENT_PROFILE/PROFILE/EDIT',
  EDIT_PAYMENT_PROFILE_INFORMATION: 'PAYMENT_PROFILE/PROFILE/EDIT/INFORMATION',
  PAYMENT_PROFILE_UPDATED: 'PAYMENT_PROFILE/PROFILE/EDIT/UPDATED',

  GET_PAYMENT_DETAILS: 'PAYMENT_PROFILE/PAYMENT_DETAILS/GET_LIST',
  PAYMENT_DETAILS_SUCCESS: 'PAYMENT_PROFILE/GET_LIST/PAYMENT_DETAILS/SUCCESS',
  PAYMENT_DETAILS_ERROR: 'PAYMENT_PROFILE/GET_LIST/PAYMENT_DETAILS/ERROR',
  PAYMENT_DETAILS_CLEAR: 'PAYMENT_PROFILE/CLEAR_LIST/PAYMENT_DETAILS',
  PAYMENT_DETAILS_UPDATE: 'PAYMENT_PROFILE/PAYMENT_DETAILS/UPDATE',

  SEND_FILTER: 'PAYMENT_PROFILE/FILTER/SEND',
  GET_FILTER_DATA: 'PAYMENT_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PAYMENT_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PAYMENT_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PAYMENT_PROFILE/FILTER/CLEAR',

  ADD_PAYMENT_DETAIL: 'PAYMENT_PROFILE/PAYMENT_DETAIL/ADD',

  MESSAGE_INFORMATION: 'PAYMENT_PROFILE/MESSAGE_INFORMATION',
  MESSAGE_INFORMATION_CLEAR: 'PAYMENT_PROFILE/MESSAGE_INFORMATION/CLEAR',

  GET_OUTBOUND_SETTLEMENT_DETAIL: 'PAYMENT_PROFILE/MODAL/GET_OUTBOUND_SETTLEMENT_DETAIL',
  OUTBOUND_SETTLEMENT_DETAIL_INFORMATION: 'PAYMENT_PROFILE/MODAL/OUTBOUND_SETTLEMENT_DETAIL_INFORMATION',

  GET_BANK_ACCOUNTS: 'PAYMENT_PROFILE/MODAL/GET_BANK_ACCOUNTS',
  BANK_ACCOUNTS_INFORMATION: 'PAYMENT_PROFILE/MODAL/BANK_ACCOUNTS_INFORMATION',
  CLEAR_BANK_ACCOUNTS: 'PAYMENT_PROFILE/MODAL/CLEAR_BANK_ACCOUNTS',

  GET_CONCEPTS: 'PAYMENT_PROFILE/GET_CONCEPTS',
  CONCEPTS_INFORMATION: 'PAYMENT_PROFILE/CONCEPTS/INFORMATION',
  CALCULATE_OUTBOUND_PAYMENTS_DETAILS: 'PAYMENT_PROFILE/CALCULATE/OUTBOUND_PAYMENTS_DETAILS',
  CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION: 'PAYMENT_PROFILE/CALCULATE/OUTBOUND_PAYMENTS_DETAILS/INFORMATION',

  PENDING_PROCESS_INFO: 'PAYMENT_PROFILE/PENDING_PROCES/INFO',
  GET_PENDING_PROCESS_INFO: 'PAYMENT_PROFILE/GET_PENDING_PROCESS/INFO',

  GET_NOT_BANK_ACCOUNTS: 'PAYMENT_PROFILE/GET_NOT_BANK_ACCOUNTS',
  NO_BANK_ACCOUNTS_SUCCESS: 'PAYMENT_PROFILE/NOT_BANK_ACCOUNTS_SUCCESS',
  NO_BANK_ACCOUNTS_ERROR: 'PAYMENT_PROFILE/NO_BANK_ACCOUNTS_ERROR',
  CLEAR_NO_BANK_ACCOUNT_ERROR: 'PAYMENT_PROFILE/CLEAR_NO_BANK_ACCOUNT_ERROR'
}

export default key
