export const key = 'IMPORT_PROFILE'

export const IMPORT_PROFILE = {
  GET_IMPORT: 'IMPORT_PROFILE/GET_IMPORT',
  GET_IMPORT_SUCCESS: 'IMPORT_PROFILE/GET_IMPORT_SUCCESS',
  GET_IMPORT_ERROR: 'IMPORT_PROFILE/GET_IMPORT_ERROR',
  CLEAR: 'IMPORT_PROFILE/CLEAR',
  DOWNLOAD_FILE: 'IMPORT_PROFILE/DOWNLOAD_FILE',
  FILE_DOWNLOADED: 'IMPORT_PROFILE/FILE_DOWNLOADED',
  GET_LOGS: 'IMPORT_PROFILE/GET_LOGS',
  GET_LOGS_SUCCESS: 'IMPORT_PROFILE/GET_LOGS_SUCCESS',
  GET_LOGS_ERROR: 'IMPORT_PROFILE/GET_LOGS_ERROR',
  SEND_FILTER: 'IMPORT_PROFILE/FILTER/SEND',
  GET_FILTER_DATA: 'IMPORT_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'IMPORT_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'IMPORT_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'IMPORT_PROFILE/FILTER/CLEAR',
  CLEAR_PENDING_PROCESS_INFO: 'IMPORT_PROFILE/CLEAR_PENDING_PROCESS_INFO'
}

export default key
