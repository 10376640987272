import { HOME } from './types'

export const getDataHome = payload => ({
  type: HOME.GET_DATA_HOME,
  payload
})

export const getDataHomeSuccess = payload => ({
  type: HOME.SUCCESS,
  payload
})

export const getDataHomeError = payload => ({
  type: HOME.ERROR,
  payload
})

export const ClearList = payload => ({
  type: HOME.CLEAR,
  payload
})

export const HOME_ACTIONS = {
  GET_DATA_HOME: getDataHome,
  SUCCESS: getDataHomeSuccess,
  ERROR: getDataHomeError,
  CLEAR: ClearList
}
