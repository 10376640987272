import React from 'react'
import PropTypes from 'prop-types'

import { SITE } from '@routes/paths'
import { BREAKPOINTS } from '@global/constants'

import { SolidButton, GhostButton } from '@components/button'
import { useWindowDimensions } from '@components/windowDimensions'

import { Text } from '@components/texts'
import { ModalDataContainer, ButtonContainer, CustomButtonLink, ListWrapper } from './styles'

// eslint-disable-next-line max-len
const WARNING_MESSAGE = 'Para crear Detalles de Pagos los afiliados y/o proveedores deben tener cuenta bancaria primaria obligatoriamente y tener aprobado el Adeudo correspondiente, de lo contrario no se generaran.'
const NO_BANK_ACCOUNT = 'Sin cuenta bancaria:'
const UNAPPROVED_DETAIL = 'Adeudo no aprobado:'

const ConfirmCalculateModal = ({ onClick, list, close }) => {
  const { device } = useWindowDimensions()
  const noBankAccountList = list.filter(person => !person.hasBankAccount)
  const unapprovedDetailList = list.filter(person => person.hasBankAccount)
  const handleCancelClick = () => close()

  const handleClick = () => {
    onClick()
    close()
  }

  const sendToPersonProfile = personId => {
    const win = window.open(`${SITE.PEOPLE}${SITE.PERSON}/${personId}`, '_blank')
    win.focus()
  }

  const sendToServiceProfile = serviceId => {
    const win = window.open(`${SITE.SERVICES}${SITE.SERVICE_PROVIDER_PROFILE}/${serviceId}`, '_blank')
    win.focus()
  }

  const sendToDetailProfile = debtId => {
    const win = window.open(`${SITE.DEBT_PROFILE}/${debtId}`, '_blank')
    win.focus()
  }

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <Text size='medium' weight='regular' align='left'>
          {WARNING_MESSAGE}
        </Text>
        {noBankAccountList.length > 0
          && (
          <>
            <Text size='medium' weight='regular' align='left' text={NO_BANK_ACCOUNT} />
            <ListWrapper>
              { noBankAccountList.map(noBankAccount => {
                let sendToFunction = null
                let id = null
                let text = ''
                if (noBankAccount.personId) {
                  sendToFunction = sendToPersonProfile
                  id = noBankAccount?.personId
                  text = `${noBankAccount?.billingNumber} - ${noBankAccount?.personName}`
                } else {
                  sendToFunction = sendToServiceProfile
                  id = noBankAccount?.serviceId
                  text = `${noBankAccount?.serviceId} - ${noBankAccount?.serviceName}`
                }
                text = `${text}(${noBankAccount?.conceptName})`

                return (
                  <CustomButtonLink
                    color='primary'
                    underline
                    onClick={() => sendToFunction(id)}
                  >
                    {text}
                  </CustomButtonLink>
                )
              })}
            </ListWrapper>
          </>

          )}
        {unapprovedDetailList.length > 0
          && (
          <>
            <Text size='medium' weight='regular' align='left' text={UNAPPROVED_DETAIL} />
            <ListWrapper>
              {unapprovedDetailList.map(unapprovedDetail => {
                let text = unapprovedDetail.personId
                  ? `${unapprovedDetail?.billingNumber} - ${unapprovedDetail?.personName}`
                  : `${unapprovedDetail?.serviceId} - ${unapprovedDetail?.serviceName}`
                text = `${text}(${unapprovedDetail?.conceptName})`

                return (
                  <CustomButtonLink
                    color='primary'
                    underline
                    onClick={() => sendToDetailProfile(unapprovedDetail?.id)}
                  >
                    {text}
                  </CustomButtonLink>
                )
              })}
            </ListWrapper>
          </>

          )}
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' device={device}>
        <GhostButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Cancelar'
          onClick={handleCancelClick}
          block={device === BREAKPOINTS.MOBILE}
        />
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Cálcular'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
    </>
  )
}

export default ConfirmCalculateModal

ConfirmCalculateModal.propTypes = {
  onClick: PropTypes.func,
  list: PropTypes.array,
  close: PropTypes.func
}
