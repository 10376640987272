import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  MyButtonLink,
  LinkContainer,
  TableWrapper,
  TextContainer,
  WrapperHeading,
  MyLink,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  TRANSLATION_OF_ENTITIES,
  SUBTITLES_TABLES,
  MONTHS,
  DETAIL_TYPES
} from '@global/constants'
import {
  TOKEN_HELPERS,
  formatDateBasic,
  handleClickPaginationItem,
  filterOrderBuilder,
  filterBodyBuilder,
  currencyFormatUY,
  strMonthYear
} from '@utils/helpers'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import Modal from '@components/modal'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import { Text, Heading } from '@components/texts'
import Badge from '@components/badge'
import { GhostButton } from '@components/button'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditEmploymentRecordProfile from './components/editEmploymentRecordModal'

import useRedux from './redux'
import { EMPLOYMENT_RECORD_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, EmploymentRecordProfileData, Tbody, CustomButtonLink } from './styles'
import { columnData, FILTER_TYPE, COLUMN_CONTRIBUTION_PAYMENTS } from './data'

const EmploymentRecordProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { employmentRecordId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [employmentRecordProfile, setEmploymentRecordProfile] = useState(null)
  const [open, setOpen] = useState(false)
  const [editEmploymentRecordModal, setEditEmploymentRecordModal] = useState(false)
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [itemSelected, setItemSelected] = useState()
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [errorFlag, setErrorFlag] = useState({
    profile: false,
    contributions: false,
    contributionPayments: false
  })
  const [currencies, setCurrencies] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [parameters, setParameters] = useState()
  const [showTable, setShowTable] = useState(false)

  const [pagesCP, setPagesCP] = useState()
  const [totalItemsCP, setTotalItemsCP] = useState(0)
  const [tablelistCP, setTablelistCP] = useState([])
  const [columnsCP, setColumnsCP] = useState([])
  const [showTableCP, setShowTableCP] = useState(false)
  const [selectedOptionCP, setSelectedOptionCP] = useState(rowsPerPage[0])
  const [itemSelectedCP, setItemSelectedCP] = useState()
  const [activeFiltersCP, setActiveFiltersCP] = useState(null)
  const [showFilterCP, setShowFilterCP] = useState(false)
  const [badgesCP, setBadgesCP] = useState([])
  const [parametersCP, setParametersCP] = useState()
  const [filterData, setFilterData] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_EMPLOYMENT_RECORD_PROFILE)
  const editInformation = useSelector(SELECTORS.EDIT_INFORMATION)
  const employmentRecordProfileData = useSelector(SELECTORS.EMPLOYMENT_RECORD_PROFILE_SUCCESS)
  const statusesData = useSelector(SELECTORS.EMPLOYMENT_RECORD_PROFILE_STATUSES_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const contributionLoading = useSelector(SELECTORS.CONTRIBUTIONS_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const items = useSelector(SELECTORS.CONTRIBUTIONS_SUCCESS)
  const itemsError = useSelector(SELECTORS.CONTRIBUTIONS_ERROR)
  const currenciesData = useSelector(SELECTORS.CURRENCIES_INFORMATION)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterObjectError = useSelector(SELECTORS.ERROR_FILTER_OBJECT)

  const conPayLoading = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_LOADING)
  const conPaymentsData = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_SUCCESS)
  const conPaymentsDataError = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_ERROR)
  const origins = useSelector(SELECTORS.ORIGINS)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const renderLiquidationLogData = objEmploymentRecordProfileData => {
    const { statusName, statusId } = objEmploymentRecordProfileData
    const status = statusName || statusesData?.find(s => s.id === statusId).name

    return (
      <>
        <Tr>
          <Td colorLight widthPerc>Nro. de Facturación:</Td>
          <Td largeSize>{objEmploymentRecordProfileData.billingNumber || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Fecha de ingreso:</Td>
          <Td largeSize>
            {formatDateBasic(objEmploymentRecordProfileData.startDate, 'short') || 'N/A'}
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Fecha de egreso:</Td>
          <Td largeSize>
            {formatDateBasic(objEmploymentRecordProfileData.endDate, 'short') || 'N/A'}
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Cargo:</Td>
          <Td largeSize>{objEmploymentRecordProfileData.initialPosition || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Estado:</Td>
          <Td largeSize>
            <Badge
              text={status}
              backgroundColor='secondary'
              color='contrast'
              size='medium'
            />
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Cobro PEG:</Td>
          <Td largeSize>
            <Badge
              text={objEmploymentRecordProfileData?.pegAmountPaid > 0 ? 'Si' : 'No'}
              backgroundColor='secondary'
              color='contrast'
              size='medium'
            />
          </Td>
        </Tr>
        {objEmploymentRecordProfileData?.pegAmountPaid > 0
         && (
         <Tr>
           <Td colorLight widthPerc>Monto:</Td>
           <Td largeSize>{currencyFormatUY(objEmploymentRecordProfileData?.pegAmountPaid)}</Td>
         </Tr>
         )}
        {objEmploymentRecordProfileData?.pegAmountPaid > 0
         && (
         <Tr>
           <Td colorLight widthPerc>Fecha de pago:</Td>
           <Td largeSize>
             {formatDateBasic(objEmploymentRecordProfileData?.pegDatePaid, 'short') || 'N/A'}
           </Td>
         </Tr>
         )}
      </>
    )
  }

  const handleSaveEmploymentRecord = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EDIT_EMPLOYMENT_RECORD_PROFILE({
        recordId: employmentRecordId, data: newProfile, token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setEditEmploymentRecordModal(null)
    setOpen(false)
  }

  const handleEditEmploymentRecord = () => {
    setEditEmploymentRecordModal({
      oldData: employmentRecordProfile,
      statuses: statusesData,
      saveData: handleSaveEmploymentRecord,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const getContributions = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_CONTRIBUTIONS_EMPLOYMENT_RECORD({ data: body, token }))
      setActiveFilters(body)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributionPayments = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_CONTRIBUTION_PAYMENTS({ data: body, token }))
      setActiveFiltersCP(body)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = columnData.find(col => col.name === nameColumn).label
    const propsForOrderBuilder = {
      filterState: activeFiltersCP,
      setFilterState: setActiveFiltersCP,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionCP.value,
      currentPageNumber: itemSelectedCP,
      setNewItemSelected: setItemSelectedCP
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributions(body)
  }

  const sendToLiquidationProfile = settlementId => {
    const win = window.open(`${SITE.LIQUIDATION_LOG}/${settlementId}`, '_blank')
    win.focus()
  }

  const handleContributionPaymentClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handleEntityClick = (entity, entityId, personId) => {
    let url = ''
    switch (entity) {
      case TRANSLATION_OF_ENTITIES.FRMRecord.name:
        url = `${SITE.FRM_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
        url = `${SITE.AFFILIATE_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.Benefit.name:
        url = `${SITE.AFFILIATE_RECORD}/${personId}/${entityId}`
        break
      default:
        url = `${SITE.EMPLOYMENT_RECORD}/${entityId}`
    }
    const win = window.open(url, '_blank')
    win.focus()
  }

  const handleContributionClick = contributionId => {
    const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
    win.focus()
  }

  const handleSendToContributionPaymentList = contributionId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${contributionId}/${DETAIL_TYPES.CONTRIBUTION_PAYMENT}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { contributionPayments } = item
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => handleContributionClick(item.id)}
        >
          {`C - ${item.id}`}
        </CustomButtonLink>
      )
      const settlement = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToLiquidationProfile(item.settlementId)}
        >
          {strMonthYear(item.settlementMonth - 1, item.settlementYear)}
        </CustomButtonLink>
      )

      let link = 'N/A'
      contributionPayments = contributionPayments.filter(cp => cp.statusId === 1)
      if (contributionPayments?.length > 0) {
        link = contributionPayments?.length === 1
          ? (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleContributionPaymentClick(contributionPayments[0].id)}
            >
              {`DI - ${contributionPayments[0].id}`}
            </CustomButtonLink>
          )
          : (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToContributionPaymentList(item.id)}
            >
              Ver lista de DI
            </CustomButtonLink>
          )
      }

      let entityId = null
      switch (item.entity) {
        case TRANSLATION_OF_ENTITIES.FRMRecord.name:
          entityId = item.frmRecordId
          break
        case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
          entityId = item.affiliateRecordId
          break
        case TRANSLATION_OF_ENTITIES.EmploymentRecord.name:
          entityId = item.employmentRecordId
          break
        case TRANSLATION_OF_ENTITIES.Benefit.name:
          entityId = item.affiliateRecordId
          break
        default:
          entityId = 0
      }

      const entity = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => handleEntityClick(item.entity, entityId, item.personId)}
        >
          {`${TRANSLATION_OF_ENTITIES[item.entity].label} - ${entityId}`}
        </CustomButtonLink>
      )

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        settlement,
        concept: item.concept,
        entity,
        currency: item.currencyName || currencies.find(c => c.id === item.currencyId).name,
        total: currencyFormatUY(item.total),
        status: item.status,
        dueDate: item.dueDate,
        totalPaid,
        description: item.description,
        link
      }
      return newItem
    })
    return newData
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributions(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getContributions(body)
  }

  const handleClickShowFilters = type => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      let filterRequest = {}
      if (type === FILTER_TYPE.CONTRIBUTIONS) filterRequest = { id: 8, token, type }
      else filterRequest = { id: 12, token, type }
      dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleClickCloseFilters = type => {
    dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    if (type === FILTER_TYPE.CONTRIBUTIONS) setShowFilter(!showFilter)
    else setShowFilterCP(!showFilterCP)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleApplyFilterCP = filters => {
    setBadgesCP(filters)
    const body = filterBodyBuilder(filters, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP)
    getContributionPayments(body)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getContributions(body)
  }

  const handleCloseBadgeCP = filterName => {
    const updateBadgesCP = badgesCP.filter(badge => badge.name !== filterName)
    setBadgesCP(updateBadgesCP)
    const body = filterBodyBuilder(
      updateBadgesCP, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP
    )
    getContributionPayments(body)
  }

  const handleInputChangeCP = e => {
    if (!e?.value) delete activeFiltersCP.filterText

    let body = activeFiltersCP ? { ...activeFiltersCP } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersCP({
        ...activeFiltersCP,
        filterText: e.value
      })
    }
    getContributionPayments(body)
  }

  const sendToContributionProfile = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const sendToContributionPaymentProfile = contributioPaymentId => {
    if (contributioPaymentId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributioPaymentId}`, '_blank')
      win.focus()
    }
  }

  const customDataTableCP = itemsTableCP => {
    const newData = itemsTableCP.map(item => {
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionPaymentProfile(item.id)}
        >
          {`DI - ${item.id}`}
        </CustomButtonLink>
      )

      const contribution = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionProfile(item.contributionId)}
        >
          {`C - ${item.contributionId}`}
        </CustomButtonLink>
      )

      const originName = item.originName || origins?.find(o => o.id === item.originId)?.name || 'N/A'

      const newItem = {
        id: item.id,
        code,
        concept: item.concept,
        originName,
        currency: item.currencyName,
        amount: currencyFormatUY(item.amount),
        contribution,
        status: item.statusName,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month)?.label : 'N/A',
        year: item.year
      }
      return newItem
    })
    return newData
  }

  const handleDropdownChangeCP = e => {
    let body = null
    if (activeFiltersCP) {
      body = activeFiltersCP
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributionPayments(body)
    setSelectedOptionCP(e.value)
    setItemSelectedCP(0)
  }

  const handleOrderChangeCP = (nameColumn, typeOrder) => {
    const colNames = COLUMN_CONTRIBUTION_PAYMENTS.find(col => col.name === nameColumn).label
    const propsForOrderBuilder = {
      filterState: activeFiltersCP,
      setFilterState: setActiveFiltersCP,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionCP.value,
      currentPageNumber: itemSelectedCP,
      setNewItemSelected: setItemSelectedCP
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributionPayments(body)
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getContributions, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  const renderTableCP = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pagesCP}
          itemSelected={itemSelectedCP}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFiltersCP, selectedOptionCP.value, getContributionPayments, setItemSelectedCP
          )}
          options={rowsPerPage}
          selectedOption={selectedOptionCP}
          handleDropdownChange={handleDropdownChangeCP}
          totalItems={totalItemsCP}
          itemsPerPage={tablelistCP.length}
        />
      </Table>
    )
  }

  const renderFilter = (type, arrFilters) => {
    let currentLoadBadges = null
    let currentShowFilter = null

    if (type === FILTER_TYPE.CONTRIBUTIONS) {
      currentLoadBadges = handleApplyFilter
      currentShowFilter = showFilter
    } else {
      currentLoadBadges = handleApplyFilterCP
      currentShowFilter = showFilterCP
    }

    return (
      <Filter
        close={() => handleClickCloseFilters(type)}
        loadBadges={currentLoadBadges}
        filterData={arrFilters}
        isShowing={currentShowFilter}
        device={device}
      />
    )
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_EMPLOYMENT_RECORD_PROFILE(
        { employmentRecordId, token }
      ))
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        employmentRecordId,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      setActiveFilters(body)
      getContributions(body)
      setActiveFilters(body)
      const bodyCP = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByDesc: true,
        orderByColumn: 'Id',
        employmentRecordId
      }
      getContributionPayments(bodyCP)
      setActiveFiltersCP(bodyCP)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (currenciesData && currenciesData.status === 200) {
      setCurrencies(currenciesData.currenciesObj)
    }
  }, [currenciesData])

  useEffect(() => {
    if (employmentRecordProfileData) {
      setEmploymentRecordProfile(employmentRecordProfileData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.EMPLOYMENT_RECORD} - ${employmentRecordProfileData.id}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.PEOPLE}`
        const firstCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.PEOPLE}`,
          url
        }
        url += `${TRANSLATION_OF_ROUTES.PERSON}/${employmentRecordProfileData.personId}`
        const secondCrumb = {
          link: true,
          text: `${employmentRecordProfileData.billingNumber} ${employmentRecordProfileData.personFullName}`,
          url
        }
        const lastCrumb = {
          link: false,
          text: `${TRANSLATION_OF_ROUTES.EMPLOYMENT_RECORD} - ${employmentRecordProfileData.id}`,
          url: null
        }
        setBreadcrumbs([firstCrumb, secondCrumb, lastCrumb])
      }
    }
  }, [employmentRecordProfileData])

  useEffect(() => {
    if (errorMessage) {
      const { status, message } = errorMessage
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || `Error ${status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.profile) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_EMPLOYMENT_RECORD_PROFILE(
            { employmentRecordId, token }
          ))
          const body = {
            pageNumber: 1,
            pageSize: selectedOption.value,
            employmentRecordId
          }
          getContributions(body)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
        setErrorFlag({
          ...errorFlag,
          profile: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (itemsError) {
      const { status, message } = itemsError
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: message || `Error ${status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.contributions) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          employmentRecordId,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        getContributions(body)
        setErrorFlag({
          ...errorFlag,
          contributions: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [itemsError])

  useEffect(() => {
    if (editInformation) {
      const { status, message } = editInformation
      if (status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: message || `Error ${status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [editInformation])

  useEffect(() => {
    if (items) {
      setTablelist(customDataTable(items))
      setColumns(columnData)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
      if (items.length > 0) setShowTable(true)
    }
  }, [items, totalPages])

  useEffect(() => {
    if (conPaymentsData) {
      setTablelistCP(customDataTableCP(conPaymentsData.items))
      setColumnsCP(COLUMN_CONTRIBUTION_PAYMENTS)
      setPagesCP(conPaymentsData.totalPages)
      setItemSelectedCP(conPaymentsData.currentPage - 1)
      setTotalItemsCP(conPaymentsData.totalItems)
      if (conPaymentsData.items.length > 0) setShowTableCP(true)
    }
  }, [conPaymentsData])

  useEffect(() => {
    if (conPaymentsDataError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: conPaymentsDataError.message || `Error ${conPaymentsDataError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.conontributionPayments) {
        const bodyCP = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByDesc: true,
          orderByColumn: 'Id',
          employmentRecordId
        }
        getContributionPayments(bodyCP)
        setErrorFlag({
          ...errorFlag,
          conontributionPayments: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [conPaymentsDataError])

  useEffect(() => {
    if (filterObject) {
      const { parametersData, type } = filterObject
      if (parametersData?.length > 0) {
        setFilterData(parametersData)
        const parametersMap = parametersData.map(p => p.name)
        if (type === FILTER_TYPE.CONTRIBUTIONS) {
          setParameters(parametersMap)
          setShowFilter(!showFilter)
        } else {
          setParametersCP(parametersMap)
          setShowFilterCP(!showFilterCP)
        }
      }
    }
  }, [filterObject])

  useEffect(() => {
    if (filterObjectError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: filterObjectError.message || `Error ${filterObjectError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }
  }, [filterObjectError])

  useEffect(() => {
    if (employmentRecordProfile) {
      if (!contributionLoading && !conPayLoading) {
        if (loading) dispatch(EMPLOYMENT_RECORD_PROFILE_ACTIONS.DATA_UPLOAD_READY())
      }
    }
  }, [contributionLoading, conPayLoading])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart={1} columnEnd={12} rowStart={1} rowEnd={1}>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading columnStart={1} columnEnd={12} rowStart={2} rowEnd={2} device={device}>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {`${TRANSLATION_OF_ROUTES.EMPLOYMENT_RECORD} - ${employmentRecordId}`}
              </Heading>
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
            </WrapperHeading>
            <DataContainer columnStart={1} columnEnd={12} rowStart={3} rowEnd={3} marginBottom={spacing.four}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <EmploymentRecordProfileData>
                      <Tbody>
                        {employmentRecordProfile && renderLiquidationLogData(employmentRecordProfile)}
                      </Tbody>
                    </EmploymentRecordProfileData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditEmploymentRecord} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            { showTable
              && (
                <>
                  <TextContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
                    <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTIONS}</Text>
                  </TextContainer>
                  <FilterContainer
                    columnStart={1}
                    columnEnd={12}
                    rowStart={5}
                    rowEnd={5}
                    deviceName={deviceName}
                  >
                    <FilterContainerLeftSide deviceName={deviceName}>
                      <GhostButton
                        color='tertiary'
                        size='small'
                        text='Agregar Filtro'
                        icon='filter_alt'
                        onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTIONS)}
                      />
                      {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                      {showFilter && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTIONS, filterData))}
                    </FilterContainerLeftSide>
                    <FilterContainerRightSide deviceName={deviceName}>
                      <SearchBar onChange={handleInputChange} disabled={!showTable} value={activeFilters?.filterText} />
                    </FilterContainerRightSide>
                  </FilterContainer>
                  <TableWrapper columnStart={1} columnEnd={12} rowStart={6} rowEnd={6} marginBottom={spacing.four}>
                    {renderTable({
                      currentList: tablelist,
                      columnsN: columns,
                      currentLoading: contributionLoading,
                      handleOrder: handleOrderChange,
                      error: itemsError
                    })}
                  </TableWrapper>
                </>
              )}
            {showTableCP
                && (
                  <>
                    <TextContainer columnStart={1} columnEnd={12} rowStart={7} rowEnd={7} device={device}>
                      <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTION_PAYMENTS}</Text>
                    </TextContainer>
                    <FilterContainer
                      columnStart={1}
                      columnEnd={12}
                      rowStart={8}
                      rowEnd={8}
                      deviceName={deviceName}
                    >
                      <FilterContainerLeftSide deviceName={deviceName}>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro'
                          icon='filter_alt'
                          onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTION_PAYMENTS)}
                        />
                        {badgesCP.length > 0 && <BadgeGroup badges={badgesCP} handleCloseBadge={handleCloseBadgeCP} />}
                        {showFilterCP
                          && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTION_PAYMENTS, filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide deviceName={deviceName}>
                        <SearchBar
                          onChange={handleInputChangeCP}
                          disabled={!showTableCP}
                          value={activeFiltersCP?.filterText}
                          id={1}
                        />
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableWrapper columnStart={1} columnEnd={12} rowStart={9} rowEnd={9} marginBottom={spacing.four}>
                      {renderTableCP({
                        currentList: tablelistCP,
                        columnsN: columnsCP,
                        currentLoading: conPayLoading,
                        handleOrder: handleOrderChangeCP,
                        error: conPaymentsDataError
                      })}
                    </TableWrapper>
                  </>
                )}
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Editar Registro de Empleo'
              minHeight='auto'
            >
              {editEmploymentRecordModal
                && <EditEmploymentRecordProfile {...editEmploymentRecordModal} />}
            </Modal>
          </>
        )}
    </>
  )
}

export default EmploymentRecordProfile
