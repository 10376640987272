export const key = 'DEBT_PROFILE'

export const DEBT_PROFILE = {
  GET_DEBT_PROFILE: 'DEBT_PROFILE/GET_DATA',
  SUCCESS: 'DEBT_PROFILE/GET_SUCCESS',
  ERROR: 'DEBT_PROFILE/GET_ERROR',
  CLEAR: 'DEBT_PROFILE/CLEAR',

  EDIT_DEBT_PROFILE: 'DEBT_PROFILE/PROFILE/EDIT',
  EDIT_DEBT_PROFILE_INFORMATION: 'DEBT_PROFILE/PROFILE/EDIT/INFORMATION',
  DEBT_PROFILE_UPDATED: 'DEBT_PROFILE/PROFILE/EDIT/UPDATED',
  GET_DEBT_PROFILE_MODAL_DATA: 'DEBT_PROFILE/EDIT_PROFILE/GET_MODAL_DATA',
  DEBT_PROFILE_MODAL_DATA_INFORMATION: 'DEBT_PROFILE/EDIT_PROFILE/MODAL_DATA_INFORMATION'
}

export default key
