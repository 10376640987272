import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { TOAST_POSITION, TOAST_PROPERTIES, FUNCTION_TYPE, BREAKPOINTS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { ButtonsContainer, ModalDataContainer } from '@global/styles'
import { SITE } from '@routes/paths'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { AFFILIATE_RECORD_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { TextErrorContainer, CustomButtonLink } from '../../styles'
import { AFFILIATE_RECORD_MODAL_TYPES } from '../../data'

const AddBenefitsModal = ({ close, selectedData, currentsBenefits, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [options, setOptions] = useState()
  const [optionSelected, setOptionSelected] = useState(null)

  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [error, setError] = useState(false)

  const modalDataResponse = useSelector(SELECTORS.MODAL_DATA_RESPONSE)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleDropdownChange = e => {
    setOptionSelected(e.value)
    const wanted = currentsBenefits.find(b => b.id === e.value.id)
    if (wanted) {
      setError(true)
    } else {
      setError(false)
    }
  }
  const clearStates = () => setOptionSelected(null)

  const handleClick = () => {
    selectedData(optionSelected, FUNCTION_TYPE.ADD)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (optionSelected && !error) setShowButton(false)
    else setShowButton(true)
  }

  const sendToCreateBenefit = () => {
    const win = window.open(`${SITE.SETTING}${SITE.ENTITY_MANAGMENT}${SITE.BENEFITS}`, '_blank')
    win.focus()
    close()
  }
  useEffect(() => {
    dispatch(AFFILIATE_RECORD_ACTIONS.GET_MODAL_DATA(
      {
        type: AFFILIATE_RECORD_MODAL_TYPES.BENEFITS,
        token: currentToken.token
      }
    ))
  }, [])

  useEffect(() => {
    if (modalDataResponse) {
      if (modalDataResponse.status === 200) {
        const { benefits } = modalDataResponse
        setOptions(changeNameForLabel(benefits))
        setFlag(true)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalDataResponse.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setTimeout(() => close(), 5000)
      }
    }
  }, [modalDataResponse])

  useEffect(() => {
    errorControl()
  }, [optionSelected])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Dropdown
            name='benefits'
            label='Beneficios'
            placeholder='seleccionar'
            options={options}
            value={optionSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
            disabled={options.length < 1}
          />
          {options.length < 1
          && (
            <TextErrorContainer>
              <Text color='error' size='small' weight='bold' align='left'>
                No existen beneficios en la Base de Datos. Para crearlos haga clic&nbsp;
                <CustomButtonLink
                  color='tertiary'
                  underline
                  onClick={sendToCreateBenefit}
                >
                  aquí
                </CustomButtonLink>
                .
              </Text>
            </TextErrorContainer>
          )}
          {error
          && (
          <TextErrorContainer>
            <Text color='error' size='small' weight='bold' align='left'>
              El beneficio seleccionado ya se encuentra dentro de su lista.
            </Text>
          </TextErrorContainer>
          )}
        </ModalDataContainer>
        <ButtonsContainer justifyContent='flex-end' device={device}>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.leftTop}
          onClick={() => setIsShowing(false)}
        />
      </>
      )}
    </>
  )
}

export default AddBenefitsModal

AddBenefitsModal.propTypes = {
  close: PropTypes.func,
  selectedData: PropTypes.func,
  currentsBenefits: PropTypes.array,
  currentToken: PropTypes.object
}
