import { LIQUIDATION_LOG } from './types'

export const getLiquidationLog = payload => ({
  type: LIQUIDATION_LOG.GET_LIQUIDATION_LOG,
  payload
})

export const getLiquidationLogSuccess = payload => ({
  type: LIQUIDATION_LOG.SUCCESS,
  payload
})

export const getLiquidationLogError = payload => ({
  type: LIQUIDATION_LOG.ERROR,
  payload
})

export const editLiquidationProfile = payload => ({
  type: LIQUIDATION_LOG.EDIT_LIQUIDATION_PROFILE,
  payload
})

export const editLiquidationLogProfileInformation = payload => ({
  type: LIQUIDATION_LOG.EDIT_LIQUIDATION_PROFILE_INFORMATION,
  payload
})

export const liquidationProfileUpdated = payload => ({
  type: LIQUIDATION_LOG.LIQUIDATION_PROFILE_UPDATED,
  payload
})

export const calculateContributions = payload => ({
  type: LIQUIDATION_LOG.CALCULATE_CONTRIBUTIONS,
  payload
})

export const calculateContributionsInformation = payload => ({
  type: LIQUIDATION_LOG.CALCULATE_CONTRIBUTIONS_INFORMATION,
  payload
})

export const getContributions = payload => ({
  type: LIQUIDATION_LOG.GET_LIQUIDATION_LOG_CONTRIBUTIONS,
  payload
})

export const getContributionsSuccess = payload => ({
  type: LIQUIDATION_LOG.CONTRIBUTIONS_SUCCESS,
  payload
})

export const getContributionsError = payload => ({
  type: LIQUIDATION_LOG.CONTRIBUTIONS_ERROR,
  payload
})

export const ClearContributionsList = payload => ({
  type: LIQUIDATION_LOG.CONTRIBUTIONS_CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: LIQUIDATION_LOG.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: LIQUIDATION_LOG.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: LIQUIDATION_LOG.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: LIQUIDATION_LOG.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: LIQUIDATION_LOG.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: LIQUIDATION_LOG.GET_MODAL_DATA,
  payload
})

export const modalDataInformation = payload => ({
  type: LIQUIDATION_LOG.MODAL_DATA_INFORMATION,
  payload
})

export const addContribution = payload => ({
  type: LIQUIDATION_LOG.ADD_CONTRIBUTION,
  payload
})

export const addContributionInformation = payload => ({
  type: LIQUIDATION_LOG.ADD_CONTRIBUTION_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: LIQUIDATION_LOG.MESSAGE_INFORMATION_CLEAR
})

export const getLiquidationLogPersonConcepts = payload => ({
  type: LIQUIDATION_LOG.GET_PERSON_CONCEPTS,
  payload
})

export const getCurrencies = payload => ({
  type: LIQUIDATION_LOG.GET_CURRENCIES,
  payload
})

export const currenciesInformation = payload => ({
  type: LIQUIDATION_LOG.CURRENCIES_INFORMATION,
  payload
})

export const updateLiquidationContribution = () => ({
  type: LIQUIDATION_LOG.LIQUIDATION_CONTRIBUTIONS_UPDATE
})

export const pendingProcessInfo = payload => ({
  type: LIQUIDATION_LOG.PENDING_PROCESS_INFO,
  payload
})

export const getProcessPendingInfo = payload => ({
  type: LIQUIDATION_LOG.GET_PENDING_PROCESS_INFO,
  payload
})

export const LIQUIDATION_LOG_ACTIONS = {
  GET_LIQUIDATION_LOG: getLiquidationLog,
  SUCCESS: getLiquidationLogSuccess,
  ERROR: getLiquidationLogError,
  EDIT_LIQUIDATION: editLiquidationProfile,
  EDIT_LIQUIDATION_INFORMATION: editLiquidationLogProfileInformation,
  LIQUIDATION_PROFILE_UPDATED: liquidationProfileUpdated,

  CALCULATE_CONTRIBUTIONS: calculateContributions,
  CALCULATE_CONTRIBUTIONS_INFORMATION: calculateContributionsInformation,
  GET_LIQUIDATION_CONTRIBUTIONS: getContributions,
  LIQUIDATION_CONTRIBUTIONS_SUCCESS: getContributionsSuccess,
  LIQUIDATION_CONTRIBUTIONS_ERROR: getContributionsError,
  CLEAR: ClearContributionsList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_INFORMATION: modalDataInformation,

  ADD_CONTRIBUTION: addContribution,
  ADD_CONTRIBUTION_INFORMATION: addContributionInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  LIQUIDATION_LOG_GET_PERSON_CONCEPTS: getLiquidationLogPersonConcepts,

  GET_CURRENCIES: getCurrencies,
  CURRENCIES_INFORMATION: currenciesInformation,

  LIQUIDATION_CONTRIBUTIONS_UPDATE: updateLiquidationContribution,
  PENDING_PROCESS_INFO: pendingProcessInfo,
  GET_PROCESS_PENDING_INFO: getProcessPendingInfo
}
