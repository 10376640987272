import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectDepositProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectDepositProfile, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectDepositProfile, state => get(state, 'depositProfile', null)
)

const selectDepositProfileStatuses = createSelector(
  selectDepositProfile, state => get(state, 'depositProfileStatuses', null)
)

const selectDepositProfileOrigins = createSelector(
  selectDepositProfile, state => get(state, 'depositProfileOrigins', null)
)

const selectError = createSelector(
  selectDepositProfile, state => get(state, 'error', null)
)

const selectLoadingProfile = createSelector(
  selectDepositProfile, state => get(state, 'loadingEditProfile', [])
)

const selectListLoading = createSelector(
  selectDepositProfile, state => get(state, 'listLoading', null)
)

const selectContributionsPaymentsSuccess = createSelector(
  selectDepositProfile, state => get(state, 'contributionsPaymentsList', [])
)

const selectColumns = createSelector(
  selectDepositProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectDepositProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectDepositProfile, state => get(state, 'totalItems', 0)
)

const selectDataFilters = createSelector(
  selectDepositProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectDepositProfile, state => get(state, 'dataFiltersError', null)
)

const selectShowTableLoading = createSelector(
  selectDepositProfile, state => get(state, 'showTableLoading', null)
)

const selectModalData = createSelector(
  selectDepositProfile, state => get(state, 'modalData', null)
)

const selectMessageInformation = createSelector(
  selectDepositProfile, state => get(state, 'messageInformation', null)
)

const selectDepositProfileContributionInformation = createSelector(
  selectDepositProfile, state => get(state, 'depositProfileContributionsInformation', null)
)

const selectDepositProfileContributionLoading = createSelector(
  selectDepositProfile, state => get(state, 'depositProfileContributionsLoading', null)
)

const selectCurrencies = createSelector(
  selectDepositProfile, state => get(state, 'currencies', null)
)

const selectIsChangingStatusInfo = createSelector(
  selectDepositProfile, state => get(state, 'isChangingStatusInfo', null)
)

const selectPersonConceptDataInformation = createSelector(
  selectDepositProfile, state => get(state, 'personConceptData', null)
)

export default {
  SUCCESS: selectSuccess,
  DEPOSIT_PROFILE_STATUSES: selectDepositProfileStatuses,
  DEPOSIT_PROFILE_ORIGINS: selectDepositProfileOrigins,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_PROFILE: selectLoadingProfile,

  CONTRIBUTIONS_PAYMENTS_SUCCESS: selectContributionsPaymentsSuccess,
  COLUMNS: selectColumns,
  LIST_LOADING: selectListLoading,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  SHOW_TABLE_LOADING: selectShowTableLoading,
  MODAL_DATA_INFORMATION: selectModalData,
  MESSAGE_INFORMATION: selectMessageInformation,

  DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION: selectDepositProfileContributionInformation,
  DEPOSIT_PROFILE_CONTRIBUTION_LOADING: selectDepositProfileContributionLoading,

  CURRENCIES_INFORMATION: selectCurrencies,

  IS_CHANGING_STATUS_INFO: selectIsChangingStatusInfo,
  PERSON_CONCEPT_DATA_INFORMATION: selectPersonConceptDataInformation
}
