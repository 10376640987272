import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { DEBT_PROFILE_ACTIONS } from './actions'
import { DEBT_PROFILE } from './types'

const apiCalls = {
  getDebtProfile: ({ debtProfileId, token }) => (
    Axios(`OutboundSettlementDetail/${debtProfileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDebtProfileStatuses: token => (
    Axios('OutboundSettlementDetail/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editDebt: ({ debtProfileId, data, token }) => (
    Axios.post(`OutboundSettlementDetail/${debtProfileId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getConcepts: token => (
    Axios.get('Concept',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getDebtProfile ({ payload }) {
  try {
    const { token } = payload
    const { data: debtProfileData } = yield call(apiCalls.getDebtProfile, payload)
    const { data: statusesData } = yield call(apiCalls.getDebtProfileStatuses, token)
    const { data: currencies } = yield call(apiCalls.getCurrencies, token)
    const { data: concepts } = yield call(apiCalls.getConcepts, token)

    const objData = { debtProfileData, statusesData, currencies, concepts }
    yield put(DEBT_PROFILE_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(DEBT_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editDebtProfile ({ payload }) {
  try {
    const { debtProfileId, token } = payload
    const { status } = yield call(apiCalls.editDebt, payload)
    const { data } = yield call(apiCalls.getDebtProfile, { debtProfileId, token })

    yield put(DEBT_PROFILE_ACTIONS.DEBT_PROFILE_UPDATED(data))
    yield put(DEBT_PROFILE_ACTIONS.EDIT_DEBT_PROFILE_INFORMATION({ status }))
  } catch (error) {
    yield put(DEBT_PROFILE_ACTIONS.EDIT_DEBT_PROFILE_INFORMATION(error))
  }
}

export function* getModalDataInformation ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getDebtProfileStatuses, payload)

    const objData = { statuses }
    yield put(DEBT_PROFILE_ACTIONS.DEBT_PROFILE_MODAL_DATA_INFORMATION({ status: 200, objData }))
  } catch (error) {
    yield put(DEBT_PROFILE_ACTIONS.DEBT_PROFILE_MODAL_DATA_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(DEBT_PROFILE.GET_DEBT_PROFILE, getDebtProfile)
  yield takeLatest(DEBT_PROFILE.EDIT_DEBT_PROFILE, editDebtProfile)
  yield takeLatest(DEBT_PROFILE.GET_DEBT_PROFILE_MODAL_DATA, getModalDataInformation)
}
