import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { BENEFITS_ACTIONS } from './actions'
import { BENEFITS } from './types'

const apiCalls = {
  getBenefits: ({ data, token }) => (
    Axios.post('Benefit/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addBenefit: ({ data, token }) => (
    Axios.post('Benefit/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteBenefit: ({ benefitId, token }) => (
    Axios.delete(`Benefit/${benefitId}/delete`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editBenefit: ({ benefitId, data, token }) => (
    Axios.post(`Benefit/${benefitId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBenefitTypes: token => (
    Axios('Benefit/frequencies',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getAllBenefits ({ payload }) {
  try {
    const { token } = payload
    const { data: benefitTypes } = yield call(apiCalls.getBenefitTypes, token)
    const { data } = yield call(apiCalls.getBenefits, payload)

    yield put(BENEFITS_ACTIONS.BENEFIT_TYPES_SUCCESS(benefitTypes))
    yield put(BENEFITS_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(BENEFITS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(BENEFITS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(BENEFITS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* setBenefits ({ payload }) {
  try {
    const { type, benefitId, data, token } = payload
    let status = null
    switch (type) {
      case FUNCTION_TYPE.ADD: {
        const res = yield call(apiCalls.addBenefit, { data, token })
        status = res.status
        break
      }
      case FUNCTION_TYPE.DELETE: {
        const res = yield call(apiCalls.deleteBenefit, { benefitId, token })
        status = res.status
        break
      }
      default: {
        const res = yield call(apiCalls.editBenefit, { benefitId, data, token })
        status = res.status
        break
      }
    }
    const { data: benefitsUpdated } = yield call(apiCalls.getBenefits, payload)

    yield put(BENEFITS_ACTIONS.SUCCESS(benefitsUpdated))
    yield put(BENEFITS_ACTIONS.SET_BENEFIT_INFORMATION({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(BENEFITS_ACTIONS.SET_BENEFIT_INFORMATION({ status, message }))
  }
}

export function* getBenefitTypes ({ payload }) {
  try {
    const { data: benefitTypes } = yield call(apiCalls.getBenefitTypes, payload)

    yield put(BENEFITS_ACTIONS.BENEFIT_TYPES_SUCCESS(benefitTypes))
  } catch (error) {
    yield put(BENEFITS_ACTIONS.BENEFIT_TYPES_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(BENEFITS.GET, getAllBenefits)
  yield takeLatest(BENEFITS.GET_FILTER_DATA, FilterData)
  yield takeLatest(BENEFITS.SET_BENEFIT, setBenefits)
  yield takeLatest(BENEFITS.GET_BENEFIT_TYPES, getBenefitTypes)
}
