import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPayments = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPayments, state => get(state, 'loading', null)
)

const selectLoadingPayments = createSelector(
  selectPayments, state => get(state, 'paymentsLoading', null)
)

const selectSuccess = createSelector(
  selectPayments, state => get(state, 'paymentsList', [])
)

const selectColumns = createSelector(
  selectPayments, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectPayments, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectPayments, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectPayments, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectPayments, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectPayments, state => get(state, 'dataFiltersError', null)
)

const selectPaymentsStatuses = createSelector(
  selectPayments, state => get(state, 'paymentsStatuses', [])
)

const selectMessageInformation = createSelector(
  selectPayments, state => get(state, 'messageInformation', null)
)

const selectBankAccountsResponse = createSelector(
  selectPayments, state => get(state, 'BankAccounts', null)
)

const selectOutboundSettlementResponse = createSelector(
  selectPayments, state => get(state, 'outboundSettlement', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingPayments,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  PAYMENTS_STATUSES: selectPaymentsStatuses,
  ADD_PAYMENT_INFORMATION: selectMessageInformation,
  BANK_ACCOUNTS_RESPONSE: selectBankAccountsResponse,
  OUTBOUND_SETTLEMENT_RESPONSE: selectOutboundSettlementResponse
}
