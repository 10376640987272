import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ModalDataContainer, ButtonsContainer, TextContainerModal } from '@global/styles'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { INPUT_TYPES } from '@components/inputs/utils/constants'

import { SolidButton } from '@components/button'
import CustomDatePicker from '@components/datePicker'
import { Dropdown } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { DEPOSIT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const EditDepositProfileModal = ({ oldData, saveData, close, currentToken }) => {
  useRedux()
  const { device } = useWindowDimensions()

  const {
    notificationDate: oldNotificationDate,
    paymentMadeDate: oldPaymentMadeDate,
    paymentConfirmedDate: oldPaymentConfirmedDate
  } = oldData

  const dispatch = useDispatch()
  const { Logout } = useUser()
  const [notificationDate, setNotificationDate] = useState(
    oldNotificationDate ? new Date(oldNotificationDate).setHours(0, 0, 0, 0) : null
  )
  const [paymentMadeDate, setPaymentMadeDate] = useState(
    oldPaymentMadeDate ? new Date(oldPaymentMadeDate).setHours(0, 0, 0, 0) : null
  )
  const [paymentConfirmedDate, setPaymentConfirmedDate] = useState(
    oldPaymentConfirmedDate ? new Date(oldPaymentConfirmedDate).setHours(0, 0, 0, 0) : null
  )
  const [options, setOptions] = useState()
  const [optionsSelected, setOptionsSelected] = useState()
  const [showButton, setShowButton] = useState(true)
  const [flag, setFlag] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [optionsOrigins, setOptionsOrigins] = useState()
  const [selectedOptionOrigin, setSelectedOptionOrigin] = useState()
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [amount, setAmount] = useState({
    name: '',
    value: oldData.amount,
    error: ''
  })
  const [description, setDescription] = useState({
    value: oldData.description,
    name: '',
    error: ''
  })

  const modalData = useSelector(SELECTORS.MODAL_DATA_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleDropdownChange = e => {
    const { name } = e
    if (name === 'status') setOptionsSelected(e.value)
    else setSelectedOptionOrigin(e.value)
  }

  const clearStates = () => {
    setNotificationDate(null)
    setPaymentMadeDate(null)
    setPaymentConfirmedDate(null)
    setOptionsSelected(null)
    setAmount({
      name: '',
      value: '',
      error: ''
    })
    setDescription(null)
  }

  const handleChange = val => setAmount(val)

  const handleClick = () => {
    const depositProfiledEdited = {
      notificationDate: new Date(notificationDate),
      paymentMadeDate: paymentMadeDate ? new Date(paymentMadeDate) : null,
      paymentConfirmedDate: paymentConfirmedDate ? new Date(paymentConfirmedDate) : null,
      statusId: optionsSelected.id,
      originId: selectedOptionOrigin.id,
      amount: amount.value,
      description: description?.value
    }
    saveData(depositProfiledEdited)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (flag) {
      if (optionsSelected
        && selectedOptionOrigin
        && ((!paymentConfirmedDate && !paymentMadeDate) || (paymentConfirmedDate >= paymentMadeDate))
        && ((!paymentConfirmedDate && !paymentMadeDate) || (paymentMadeDate >= notificationDate))) setShowButton(false)
      else setShowButton(true)
    }
  }

  const handleToastClose = () => setIsShowing(false)
  const handleInputChange = val => setDescription(val)

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.GET_MODAL_DATA(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (modalData) {
      const { status } = modalData
      if (status === 200) {
        const { statuses, origins } = modalData.modalDataObj
        setOptions(changeNameForLabel(statuses))
        setOptionsOrigins(changeNameForLabel(origins))
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalData.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      }
    }
  }, [modalData])

  useEffect(() => {
    if (options) {
      setOptionsSelected(options.find(o => o.id === oldData.statusId))
      setSelectedOptionOrigin(optionsOrigins.find(c => c.id === oldData.originId))
      setFlag(true)
    }
  }, [options])

  useEffect(() => {
    errorControl()
  }, [optionsSelected, selectedOptionOrigin, paymentConfirmedDate, paymentMadeDate, notificationDate, amount])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label='Monto'
            placeholder='Ingrese monto'
            name='amount'
            value={amount.value}
            error={amount.error}
            height='small'
            color='primary'
            onChange={handleChange}
            onBlur={() => {}}
            bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
          />
          <CustomDatePicker
            name='notificationDate'
            title='Fecha de Notificación'
            color='primary'
            date={notificationDate}
            onChange={setNotificationDate}
            yearsToShow={1000}
            justifyContentButton='space-between'
            block
          />
          <CustomDatePicker
            name='paymentMadeDate'
            title='Fecha de Pago'
            color='primary'
            date={paymentMadeDate}
            onChange={setPaymentMadeDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
            isClearable
          />
          <CustomDatePicker
            name='paymentConfirmedDate'
            title='Fecha de Cobro'
            color='primary'
            date={paymentConfirmedDate}
            onChange={setPaymentConfirmedDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
            isClearable
          />
          <Dropdown
            name='source'
            label='Origen'
            placeholder='Seleccione'
            options={optionsOrigins}
            value={selectedOptionOrigin}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Estado'
            options={options}
            value={optionsSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Input
            type={INPUT_TYPES.TEXT}
            showType={INPUT_TYPES.TEXT}
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            color='primary'
            value={description.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={description.error}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
        </ModalDataContainer>
        <TextContainerModal>
          {paymentConfirmedDate && (paymentConfirmedDate < paymentMadeDate)
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La fecha de cobro tiene que ser posterior a la fecha de pago.
           </Text>
           )}
          {paymentMadeDate && (paymentMadeDate < notificationDate)
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * La fecha de pago tiene que ser posterior a la fecha de notificación.
           </Text>
           )}
          {((paymentMadeDate && !paymentConfirmedDate) || (paymentConfirmedDate && !paymentMadeDate))
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             * Debe completar todas las fechas.
           </Text>
           )}

        </TextContainerModal>
        <ButtonsContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={handleToastClose}
        />
      </>
      )}
    </>
  )
}

export default EditDepositProfileModal

EditDepositProfileModal.propTypes = {
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func,
  currentToken: PropTypes.object
}
