import produce from 'immer'

import { PAYMENTS } from './types'

export const initialState = {
  loading: true,
  error: false,
  paymentsLoading: false,
  paymentsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  paymentsStatuses: null,
  messageInformation: null,
  BankAccounts: null,
  loadingBankAccounts: false,
  loadingOutboundSettlement: false,
  outboundSettlement: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PAYMENTS.GET:
      draft.paymentsLoading = true
      break
    case PAYMENTS.SUCCESS: {
      const { payments, statuses } = payload
      const { items, fields, totalItems, totalPages, currentPage } = payments
      draft.loading = false
      draft.paymentsLoading = false
      draft.paymentsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.paymentsStatuses = statuses
    }
      break
    case PAYMENTS.ERROR:
      draft.loading = false
      draft.paymentsLoading = false
      draft.error = payload
      break
    case PAYMENTS.CLEAR:
      return initialState
    // FILTER
    case PAYMENTS.SEND_FILTER:
      draft.loading = true
      break
    case PAYMENTS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PAYMENTS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PAYMENTS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PAYMENTS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // BANK ACCOUNTS
    case PAYMENTS.GET_BANK_ACCOUNTS:
      draft.loadingBankAccounts = true
      break
    case PAYMENTS.BANK_ACCOUNTS_RESPONSE:
      draft.loadingBankAccounts = false
      draft.BankAccounts = payload
      break
    // OUTBOUND_SETTLEMENT
    case PAYMENTS.GET_OUTBOUND_SETTLEMENT:
      draft.loadingOutboundSettlement = true
      break
    case PAYMENTS.OUTBOUND_SETTLEMENT_RESPONSE:
      draft.loadingOutboundSettlement = false
      draft.outboundSettlement = payload
      break
    case PAYMENTS.ADD_PAYMENT:
      draft.paymentsLoading = true
      break
    case PAYMENTS.ADD_PAYMENT_INFORMATION:
      draft.messageInformation = payload
      draft.paymentsLoading = false
      break
    case PAYMENTS.CLEAR_MESSAGE_INFORMATION:
      draft.messageInformation = null
      break
    default:
      return initialState
  }
})

export default reducer
