import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { SITE } from '@routes/paths'
import { TOAST_POSITION, TRANSLATION_OF_ROUTES, TOAST_PROPERTIES } from '@global/constants'
import { formatDateBasic, TOKEN_HELPERS } from '@utils/helpers'

import Breadcrumbs from '@components/breadcrums'
import { Heading } from '@components/texts'
import Loading from '@components/loading'
import Badge from '@components/badge'
import Toast from '@components/toast'
import Modal from '@components/modal'
import { useUser } from '@components/authentication/utils/hook'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import EditUserPasswordModal from './components/editPasswordModal'
import EditUserProfileModal from './components/editUserProfileModal'

import {
  Wrapper,
  WrapperLoading,
  WrapperBreadcrumbs,
  Tbody,
  Td,
  Tr,
  UserData,
  DataContainer,
  LinkContainer,
  MyButtonLink
} from './styles'

import useRedux from './redux'
import { USER_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

const UserProfile = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [user, setUser] = useState()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(false)
  const [editPasswordModal, setEditPasswordModal] = useState(false)
  const [editProfileModal, setEditProfileModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingProfile = useSelector(SELECTORS.LOADING_PROFILE)
  const userData = useSelector(SELECTORS.USER_SUCCESS)
  const userError = useSelector(SELECTORS.USER_ERROR)
  const userStatuses = useSelector(SELECTORS.STATUSES)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const getUserData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(USER_PROFILE_ACTIONS.GET_USER({ userId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditPasswordModal(null)
    setEditProfileModal(null)
  }

  const handleEditPassword = () => {
    setModalTitle('Editar contraseña')
    setEditPasswordModal({
      currentToken,
      userId,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const handleEditProfile = () => {
    setModalTitle('Editar perfil')
    setEditProfileModal({
      oldData: user,
      statuses: userStatuses,
      currentToken,
      userId,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const renderUserData = objUserData => (
    <>
      <Tr><Td colorLight widthPer>Nombre de usuario:</Td> <Td largeSize>{objUserData.email}</Td></Tr>
      <Tr><Td colorLight widthPer>Nombre:</Td> <Td largeSize>{objUserData.fullName}</Td></Tr>
      <Tr>
        <Td colorLight widthPer>Fecha de Nacimiento:</Td>
        <Td largeSize>{formatDateBasic(objUserData.dateCreated, 'short')}</Td>
      </Tr>
      <Tr><Td colorLight widthPer>Estado:</Td>
        <Td largeSize>
          <Badge
            text={objUserData.statusName
              ? objUserData.statusName
              : userStatuses.find(s => s.id === objUserData.statusId).label}
            color='system'
            backgroundColor='secondary'
            size='small'
          />
        </Td>
      </Tr>
      <Tr>
        <MyButtonLink onClick={handleEditPassword} color='tertiary' underline table>Cambiar contraseña</MyButtonLink>
      </Tr>
    </>
  )

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    if (userData) {
      if (Object.keys(userData).length !== 0) {
        const firstCrubm = {
          text: TRANSLATION_OF_ROUTES.SETTING,
          link: true,
          url: SITE.SETTING
        }
        const secondCrumb = {
          text: TRANSLATION_OF_ROUTES.USERS,
          link: true,
          url: `${SITE.SETTING}${SITE.USERS}`
        }

        setBreadcrumbs([firstCrubm, secondCrumb])
        setUser(userData)
      }
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [userData])

  useEffect(() => {
    if (user) {
      const userCrumb = {
        text: user.fullName,
        link: false,
        url: ''
      }
      setBreadcrumbs([...breadcrumbs, userCrumb])
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [user])

  useEffect(() => {
    if (userError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: userError.message || `Error ${userError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        getUserData()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [userError])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <Wrapper>
            {breadcrumbs
             && (
             <WrapperBreadcrumbs>
               <Breadcrumbs crumbs={breadcrumbs} />
             </WrapperBreadcrumbs>
             )}
            {user
             && (
             <Heading
               weight='regular'
               size='large'
               text={user.fullName}
               type='h2'
               align='left'
             />
             )}
            <DataContainer>
              {loadingProfile
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <UserData>
                      <Tbody>
                        {user && renderUserData(user)}
                      </Tbody>
                    </UserData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditProfile} color='primary' underline>Editar Perfil</MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='380px'
            >
              {editPasswordModal && (<EditUserPasswordModal {...editPasswordModal} />)}
              {editProfileModal && (<EditUserProfileModal {...editProfileModal} />)}
            </Modal>
          </Wrapper>
        )}
    </>
  )
}

export default UserProfile
