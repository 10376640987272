import { SERVICE_PROVIDER_PROFILE } from './types'

export const getServiceProviderProfile = payload => ({
  type: SERVICE_PROVIDER_PROFILE.GET_SERVICE_PROVIDER_PROFILE,
  payload
})

export const getServiceProviderProfileSuccess = payload => ({
  type: SERVICE_PROVIDER_PROFILE.SUCCESS,
  payload
})

export const getServiceProviderProfileError = payload => ({
  type: SERVICE_PROVIDER_PROFILE.ERROR,
  payload
})

export const editServiceProviderProfile = payload => ({
  type: SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_PROFILE,
  payload
})

export const editServiceProviderProfileInformation = payload => ({
  type: SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_PROFILE_INFORMATION,
  payload
})

export const ServiceProviderProfileUpdated = payload => ({
  type: SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_PROFILE_UPDATED,
  payload
})

export const messageInformation = payload => ({
  type: SERVICE_PROVIDER_PROFILE.MESSAGE_INFORMATION,
  payload
})

export const clearMessageInformation = payload => ({
  type: SERVICE_PROVIDER_PROFILE.CLEAR_MESSAGE_INFORMATION,
  payload
})

export const createServiceProviderBankAccount = payload => ({
  type: SERVICE_PROVIDER_PROFILE.CREATE_SERVICE_PROVIDER_BANK_ACCOUNT,
  payload
})

export const editServiceProviderBankAccount = payload => ({
  type: SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_BANK_ACCOUNT,
  payload
})

export const deleteServiceProviderBankAccount = payload => ({
  type: SERVICE_PROVIDER_PROFILE.DELETE_SERVICE_PROVIDER_BANK_ACCOUNT,
  payload
})

export const getServiceProviderModalData = payload => ({
  type: SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_GET_MODAL_DATA,
  payload
})

export const getServiceProviderModalDataInformation = payload => ({
  type: SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_GET_MODAL_DATA_INFORMATION,
  payload
})

export const serviceProviderProfileBankAccountsUpdated = payload => ({
  type: SERVICE_PROVIDER_PROFILE.BANK_ACCOUNTS_UPDATED,
  payload
})

export const getDetails = payload => ({
  type: SERVICE_PROVIDER_PROFILE.GET_DETAILS,
  payload
})

export const getDetailsSuccess = payload => ({
  type: SERVICE_PROVIDER_PROFILE.GET_DETAILS_SUCCESS,
  payload
})

export const getDetailsError = payload => ({
  type: SERVICE_PROVIDER_PROFILE.GET_DETAILS_ERROR,
  payload
})

export const SERVICE_PROVIDER_PROFILE_ACTIONS = {
  GET_SERVICE_PROVIDER_PROFILE: getServiceProviderProfile,
  SUCCESS: getServiceProviderProfileSuccess,
  ERROR: getServiceProviderProfileError,

  EDIT_SERVICE_PROVIDER_PROFILE: editServiceProviderProfile,
  EDIT_SERVICE_PROVIDER_PROFILE_INFORMATION: editServiceProviderProfileInformation,
  SERVICE_PROVIDER_PROFILE_UPDATED: ServiceProviderProfileUpdated,

  CREATE_SERVICE_PROVIDER_BANK_ACCOUNT: createServiceProviderBankAccount,
  EDIT_SERVICE_PROVIDER_BANK_ACCOUNT: editServiceProviderBankAccount,
  DELETE_SERVICE_PROVIDER_BANK_ACCOUNT: deleteServiceProviderBankAccount,
  BANK_ACCOUNTS_UPDATED: serviceProviderProfileBankAccountsUpdated,

  SERVICE_PROVIDER_GET_MODAL_DATA: getServiceProviderModalData,
  SERVICE_PROVIDER_GET_MODAL_DATA_INFORMATION: getServiceProviderModalDataInformation,

  MESSAGE_INFORMATION: messageInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  GET_DETAILS: getDetails,
  GET_DETAILS_SUCCESS: getDetailsSuccess,
  GET_DETAILS_ERROR: getDetailsError

}
