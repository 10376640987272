import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { CheckboxInput, Dropdown, TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { DEBT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const EditDebtProfile = ({ oldData, saveData, close, currentToken }) => {
  useRedux()
  const dispatch = useDispatch()
  const { Logout } = useUser()
  const { device } = useWindowDimensions()

  const [amount, setAmount] = useState({
    name: '',
    value: oldData?.conceptId === 3 ? oldData?.totalBMS : oldData?.total,
    error: ''
  })
  const [description, setDescription] = useState({
    name: '',
    value: oldData.description,
    error: ''
  })
  const [options, setOptions] = useState()
  const [optionsSelected, setOptionsSelected] = useState()
  const [showButton, setShowButton] = useState(true)
  const [flag, setFlag] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [withholding, setWithholding] = useState({
    value: oldData.amountIASS,
    name: '',
    error: ''
  })
  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: oldData.isPEG
  })

  const debtProfileModalData = useSelector(SELECTORS.DEBT_PROFILE_MODAL_DATA)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleChange = val => {
    const { name } = val
    if (name === 'amount') setAmount(val)
    else setDescription(val)
  }
  const handleDropdownChange = e => setOptionsSelected(e.value)

  const clearStates = () => {
    setAmount({
      name: '',
      value: '',
      error: ''
    })
    setDescription({
      name: '',
      value: '',
      error: ''
    })
    setOptionsSelected(null)
  }

  const handleClick = () => {
    const debtProfiledEdited = {
      total: amount.value,
      statusId: optionsSelected.id,
      description: description.value,
      amountIASS: withholding.value,
      isPEG: checked.value
    }
    saveData(debtProfiledEdited)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (flag) {
      if ((amount.value && !amount.error)
          && optionsSelected) setShowButton(false)
      else setShowButton(true)
    }
  }

  const handleToastClose = () => setIsShowing(false)

  const handleInputWithholdingChange = val => {
    const { error } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    setWithholding(val)
  }

  const handleCheckboxChange = val => setChecked(val)

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEBT_PROFILE_ACTIONS.GET_DEBT_PROFILE_MODAL_DATA(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (debtProfileModalData) {
      const { status } = debtProfileModalData
      if (status === 200) {
        const { statuses } = debtProfileModalData.objData
        setOptions(changeNameForLabel(statuses))
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: debtProfileModalData.message || `Error ${debtProfileModalData.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      }
    }
  }, [debtProfileModalData])

  useEffect(() => {
    if (options) {
      setOptionsSelected(options.find(o => o.id === oldData.statusId))
      setFlag(true)
    }
  }, [options])

  useEffect(() => {
    errorControl()
  }, [amount, optionsSelected])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label={`Monto en ${oldData?.conceptId === 3 ? 'SBM' : oldData?.currencyName}`}
            placeholder='Ingrese monto'
            name='amount'
            value={amount.value}
            error={amount.error}
            height='small'
            color='primary'
            onChange={handleChange}
            onBlur={() => {}}
            bits={[
              { icon: oldData?.conceptId === 3 ? 'currency_exchange' : 'paid',
                position: 'left',
                colorBit: 'tertiary'
              }]}
            min={0}
          />
          {oldData?.conceptId === 1
          && (
            <>
              <CheckboxInput
                label='PEG'
                name='checkbox'
                color='primary'
                value={checked.value}
                onChange={handleCheckboxChange}
              />
              <Input
                type={INPUT_TYPES.NUMBER}
                showType={INPUT_TYPES.TEXT}
                label='Retención IASS'
                name='withholding'
                placeholder='Ingrese monto'
                color='primary'
                value={withholding.value}
                onChange={handleInputWithholdingChange}
                onBlur={() => {}}
                height='small'
                error={withholding.error}
                bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
              />
            </>
          )}
          <TextInput
            label='Descripción'
            placeholder='Ingrese descripción'
            name='description'
            value={description.value}
            height='small'
            color='primary'
            onChange={handleChange}
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Estado'
            options={options}
            value={optionsSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
        </ModalDataContainer>
        <ButtonsContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={handleToastClose}
        />
      </>
      )}
    </>
  )
}

export default EditDebtProfile

EditDebtProfile.propTypes = {
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func,
  currentToken: PropTypes.object
}
