import PropTypes from 'prop-types'
import MyPropTypes from '@global/propTypes'
import { BASIC_INPUT_TYPES } from '../utils/constants'

export const propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  color: MyPropTypes.color,
  value: PropTypes.any,
  type: PropTypes.oneOf(Object.values(BASIC_INPUT_TYPES)),
  showType: PropTypes.oneOf(Object.values(BASIC_INPUT_TYPES)),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  bits: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.oneOf(['left', 'right']),
      name: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  errorIcon: PropTypes.bool,
  hideError: PropTypes.bool,
  noErrorCheck: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.oneOf(['small', 'medium', 'large']),
  min: PropTypes.number,
  select: PropTypes.bool,
  removeValue: PropTypes.bool
}

const {
  type,
  bits,
  value,
  showType,
  errorIcon,
  ...rest
} = propTypes

export const textAreaPropTypes = {
  ...rest,
  value: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  resize: PropTypes.string
}

export const allInputPropTypes = PropTypes.shape({
  ...propTypes,
  step: PropTypes.number, // for number
  onKeyDown: PropTypes.func, // for number
  onBlur: PropTypes.func, // for number
  min: PropTypes.number, // for number & text area
  max: PropTypes.number, // for number & text area
  pwRepeat: PropTypes.string, // for password repeat, value to compate
  repeat: PropTypes.string, // for password repeat, name of the field to compare
  options: PropTypes.array // for Radios
})

export default propTypes
