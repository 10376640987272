import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import Axios from '@axios/'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import {
  TOAST_POSITION,
  TOAST_PROPERTIES,
  TRANSLATION_OF_ENTITIES,
  BREAKPOINTS,
  BMS_NAMES,
  DISAFFILIATION_REFOUND
} from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'

import { ModalDataContainer, ButtonContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { CheckboxInput, Dropdown, TextInput } from '@components/inputs/inputs'
import SelectInput from '@components/select'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import Label from '@components/label'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import Badge from '@components/badge'
import useRedux from '../../redux'
import { PAYMENT_SETTLEMENT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { StatusConatainer, StatuRecordContainer } from '../../styles'

const AddDebtModal = ({ close, saveData, currentToken }, ref) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [person, setPerson] = useState(null)
  const [selectedPersonData, setSelectedPersonData] = useState({
    affiliateRecordId: null,
    frmRecordId: null,
    employmentRecordId: null
  })
  const [frmConcepts, setFrmConcepts] = useState()
  const [entities, setEntities] = useState([])
  const [selectedOptionEntity, setSelectedOptionEntity] = useState()
  const [selectedOptionCurrency, setSelectedOptionCurrency] = useState()
  const [currencies, setCurrencies] = useState([])
  const [concepts, setConcepts] = useState([])
  const [selectedOptionConcept, setSelectedOptionConcept] = useState()
  const [total, setTotal] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [bmsDelta, setBmsDelta] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [description, setDescription] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [flag, setFlag] = useState(false)
  const [showForm, setShowForm] = useState(false)

  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [amountDisaffiliationRefund, setAmountDisaffiliationRefund] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [withholding, setWithholding] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [statusRecord, setStatusRecord] = useState({
    employment: null,
    affiliate: null,
    frm: null
  })
  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: false
  })

  const entitiesData = useSelector(SELECTORS.ENTITIES_INFORMATION)
  const servicesData = useSelector(SELECTORS.SERVICES_INFORMATION)
  const personData = useSelector(SELECTORS.PERSON_INFORMATION)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const currenciesData = useSelector(SELECTORS.CURRENCIES_INFORMATION)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if (selectedOptionConcept) {
      if (selectedOptionEntity?.id === 1) {
        if (frmConcepts.statusId === 6) {
          if ((amountDisaffiliationRefund?.value && !amountDisaffiliationRefund?.error)
               && (description?.value && !description?.error)) setShowButton(false)
          else setShowButton(true)
        } else if ((bmsDelta?.value && !bmsDelta?.error)
                && (description?.value && !description?.error)
                && !(bmsDelta?.value > frmConcepts?.bmsInbound)) setShowButton(false)
        else setShowButton(true)
      }

      if (selectedOptionEntity?.id === 2) {
        if (total?.value && !total?.error) setShowButton(false)
        else setShowButton(true)
      }

      if (selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES?.AffiliateRecord.label) {
        if ((total?.value && !total?.error) && (description?.value && !description?.error)) setShowButton(false)
        else setShowButton(true)
      }

      if (selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES?.EmploymentRecord.label) {
        if ((total?.value && !total?.error)
          && (description?.value && !description?.error)
          && (withholding?.value && !withholding?.error)) setShowButton(false)
        else setShowButton(true)
      }
    }
  }

  const cleanFields = () => {
    setTotal({
      value: '',
      name: '',
      error: ''
    })
    setDescription({
      value: '',
      name: '',
      error: ''
    })
    setPerson(null)
    setSelectedOptionEntity(null)
    setSelectedOptionConcept(null)
    setSelectedOptionCurrency(null)
    setAmountDisaffiliationRefund({
      value: '',
      name: '',
      error: ''
    })
  }

  const cleanPersonField = () => {
    setTotal({
      value: '',
      name: '',
      error: ''
    })
    setAmountDisaffiliationRefund({
      value: '',
      name: '',
      error: ''
    })
    setDescription({
      value: '',
      name: '',
      error: ''
    })
    setSelectedOptionCurrency(null)
  }

  const closeModalFromParent = () => {
    dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CLEAR_SERVICES_INFORMATION())
    cleanFields()
  }

  useImperativeHandle(ref, () => ({
    clearValues: () => closeModalFromParent()
  }))

  const handleDropdownChange = val => {
    const { value, name } = val
    if (name === 'entity') {
      cleanFields()
      setSelectedOptionEntity(value)
    } else if (selectedOptionEntity?.id === 1) {
      cleanPersonField()
      if (value?.label === TRANSLATION_OF_ENTITIES.AffiliateRecord.label) {
        setSelectedPersonData({
          affiliateRecordId: value.id,
          frmRecordId: null,
          employmentRecordId: null
        })
      }
      if (value?.label === TRANSLATION_OF_ENTITIES.FRMRecord.label) {
        setAmountDisaffiliationRefund({
          value: frmConcepts?.bmsRefundAvailable || 0,
          name: '',
          error: ''
        })
        setSelectedPersonData({
          affiliateRecordId: null,
          frmRecordId: value.id,
          employmentRecordId: null
        })
      }
      if (value?.label === TRANSLATION_OF_ENTITIES.EmploymentRecord.label) {
        setSelectedPersonData({
          affiliateRecordId: null,
          frmRecordId: null,
          employmentRecordId: value.id
        })
      }
      setSelectedOptionConcept(value)
    } else setSelectedOptionConcept(value)
  }

  const handleDropdownCurrencies = val => setSelectedOptionCurrency(val.value)

  const handleInputTotalChange = val => {
    const { error } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    setTotal(val)
  }

  const handleInputWithholdingChange = val => {
    const { error } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    setWithholding(val)
  }

  const handleInputBmsChange = val => {
    const { error, name, value } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    if (value <= 0) {
      val = {
        ...val,
        error: 'El monto debe ser mayor a 0'
      }
    }
    if (name === 'bmsDelta') setBmsDelta(val)
    else setAmountDisaffiliationRefund(val)
  }

  const handleInputDescriptionChange = val => setDescription(val)

  const handleClick = () => {
    const { affiliateRecordId, frmRecordId, employmentRecordId } = selectedPersonData
    const newPaymentDetail = {
      total: frmRecordId ? bmsDelta.value : Number(total.value),
      description: description.value,
      serviceId: selectedOptionEntity?.id === 2 ? selectedOptionConcept?.id : null,
      affiliateRecordId,
      frmRecordId,
      employmentRecordId,
      currencyId: frmRecordId ? 4 : selectedOptionCurrency?.id,
      bmsRefundAvailable: Number(amountDisaffiliationRefund.value),
      amountIASS: selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES.EmploymentRecord.label
        ? Number(withholding.value) : null,
      isPEG: checked.value
    }
    saveData(newPaymentDetail)
    cleanFields()
    close()
  }

  const handleSelectChange = value => {
    setSelectedOptionConcept(null)
    setPerson(value)
  }

  const debounced = debounce((value, callback) => {
    const empty = []

    if (!value) {
      callback(empty)
      return
    }

    const data = {
      filterText: value,
      pageNumber: 1
    }

    const results = Axios.post('/Person/filter', data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(currentToken.token)
      }
    })

    results
      .then(res => {
        const options = res.data.items.map(p => ({
          value: p.id,
          label: p.fullName
        }))
        callback(options)
      })
      .catch(error => {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: error.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
        callback(empty)
      })
  }, 300)

  const customDropdownServiceProviderData = arrServiceProvider => arrServiceProvider.map(sp => ({
    id: sp.id,
    label: sp.name
  }))

  const customDropdownPersonData = personInfo => {
    const { affiliateRecord, frmRecord, employmentRecord } = personInfo
    let dropdownData = []
    if (affiliateRecord) {
      setStatusRecord(prevState => ({
        ...prevState,
        affiliate: affiliateRecord.statusName
      }))
      dropdownData = [
        ...dropdownData,
        {
          id: affiliateRecord.id,
          label: TRANSLATION_OF_ENTITIES.AffiliateRecord.label
        }]
    }
    if (employmentRecord) {
      setStatusRecord(prevState => ({
        ...prevState,
        employment: employmentRecord.statusName
      }))
      dropdownData = [
        ...dropdownData,
        {
          id: employmentRecord.id,
          label: TRANSLATION_OF_ENTITIES.EmploymentRecord.label
        }]
    }
    // statusId = 1 > active
    if (frmRecord && frmRecord.statusId !== 1) {
      setStatusRecord(prevState => ({
        ...prevState,
        frm: frmRecord.statusName
      }))
      dropdownData = [
        ...dropdownData,
        {
          id: frmRecord.id,
          label: TRANSLATION_OF_ENTITIES.FRMRecord.label
        }]
    }
    setFrmConcepts({
      statusId: frmRecord?.statusId,
      status: frmRecord?.statusName,
      bmsInbound: Number(frmRecord?.bmsAvailable),
      bmsRefundAvailable: frmRecord?.bmsRefundAvailable
    })
    setBmsDelta({
      ...bmsDelta,
      value: frmRecord?.bmsDelta
    })
    return dropdownData
  }

  const handleCheckboxChange = val => setChecked(val)

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_ENTITIES_DATA(token))
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_CURRENCIES(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    return () => {
      setShowForm(false)
    }
  }, [])

  useEffect(() => {
    if (entitiesData && entitiesData.status === 200) {
      setEntities(changeNameForLabel(entitiesData.entitiesObj))
      setFlag(true)
    }
  }, [entitiesData])

  useEffect(() => {
    if (currenciesData && currenciesData.status === 200) {
      setCurrencies(changeNameForLabel(currenciesData.currenciesObj.filter(c => (c.id !== 3 && c.id !== 4))))
    }
  }, [currenciesData])

  useEffect(() => {
    if (selectedOptionEntity) {
      const { token, tokenCreationDate } = currentToken
      const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
      if (isValid) {
        if (selectedOptionEntity.id === 2) {
          const data = {
            pageNumber: 1,
            pageSize: 1000
          }
          dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_SERVICES_DATA({ data, token }))
        } else setShowForm(true)
      } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    }
  }, [selectedOptionEntity])

  useEffect(() => {
    if (servicesData) {
      if (servicesData?.status === 200) {
        const { servicesProviders } = servicesData
        setConcepts(customDropdownServiceProviderData(servicesProviders))
        setShowForm(true)
      }
    }
  }, [servicesData])

  useEffect(() => {
    if (person) {
      const { token, tokenCreationDate } = currentToken
      const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
      if (isValid) {
        dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_PERSON_DATA({ personId: person.value, token }))
      } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    }
  }, [person])

  useEffect(() => {
    if (personData) {
      const { status } = personData
      if (status === 200) {
        const { personConcepts } = personData
        setConcepts(customDropdownPersonData(personConcepts))
      }
    }
  }, [personData])

  useEffect(() => {
    errorControl()
  }, [person, total, selectedOptionConcept, bmsDelta, description, amountDisaffiliationRefund, withholding])

  useEffect(() => {
    if (messageInformation) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: messageInformation.message,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      setTimeout(() => {
        close()
      }, 4000)
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
       && (
       <>
         <ModalDataContainer flexDirection='column'>
           <Dropdown
             name='entity'
             label='Entidad'
             placeholder='Seleccione'
             options={entities}
             value={selectedOptionEntity}
             onChange={handleDropdownChange}
             color='primary'
             height='small'
             block
           />
           {showForm
            && (
            <>
              {selectedOptionEntity?.id === 1
              && (
                <SelectInput
                  label='Persona'
                  placeholder='Ingrese un nombre o número de cobro'
                  value={person}
                  loadOptions={debounced}
                  onChange={handleSelectChange}
                  color='primary'
                  asyncSelect
                  loadingMessage={() => <Text color='primary' weight='bold'>Cargando...</Text>}
                  noOptionsMessage={() => <Text color='primary' weight='bold' align='center'>Sin opciones</Text>}
                />
              )}
              <Dropdown
                name='concept'
                label={selectedOptionEntity?.id === 1 ? 'Concepto' : 'Proveedores'}
                placeholder={concepts.length > 0 ? 'Seleccione' : 'No existen datos'}
                options={concepts}
                value={selectedOptionConcept}
                onChange={handleDropdownChange}
                color='primary'
                height='small'
                block
                disabled={concepts.length === 0}
              />
              {(selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES.AffiliateRecord.label
                || selectedOptionEntity?.id === 2)
                && (
                  <>
                    <StatuRecordContainer device={device}>
                      <Label text='Estado' size='medium' />
                      <Badge
                        text={statusRecord?.affiliate}
                        color='system'
                        backgroundColor='secondary'
                        size='large'
                      />
                    </StatuRecordContainer>
                    <Input
                      type={INPUT_TYPES.NUMBER}
                      showType={INPUT_TYPES.TEXT}
                      label='Monto'
                      name='total'
                      placeholder='Ingrese monto'
                      color='primary'
                      value={total.value}
                      onChange={handleInputTotalChange}
                      onBlur={() => {}}
                      height='small'
                      error={total.error}
                      bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
                    />
                    <Dropdown
                      name='currency'
                      label='Divisa'
                      placeholder='Seleccione'
                      options={currencies}
                      value={selectedOptionCurrency}
                      onChange={handleDropdownCurrencies}
                      color='primary'
                      height='small'
                      block
                    />
                  </>
                )}
              {selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES.EmploymentRecord.label
                && (
                  <>
                    <StatuRecordContainer device={device}>
                      <Label text='Estado' size='medium' />
                      <Badge
                        text={statusRecord?.employment}
                        color='system'
                        backgroundColor='secondary'
                        size='large'
                      />
                    </StatuRecordContainer>
                    <CheckboxInput
                      label='PEG'
                      name='checkbox'
                      color='primary'
                      value={checked.value}
                      onChange={handleCheckboxChange}
                    />
                    <Input
                      type={INPUT_TYPES.NUMBER}
                      showType={INPUT_TYPES.TEXT}
                      label='Monto'
                      name='total'
                      placeholder='Ingrese monto'
                      color='primary'
                      value={total.value}
                      onChange={handleInputTotalChange}
                      onBlur={() => {}}
                      height='small'
                      error={total.error}
                      bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
                    />
                    <Input
                      type={INPUT_TYPES.NUMBER}
                      showType={INPUT_TYPES.TEXT}
                      label='Retención IASS'
                      name='withholding'
                      placeholder='Ingrese monto'
                      color='primary'
                      value={withholding.value}
                      onChange={handleInputWithholdingChange}
                      onBlur={() => {}}
                      height='small'
                      error={withholding.error}
                      bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
                    />
                    <Dropdown
                      name='currency'
                      label='Divisa'
                      placeholder='Seleccione'
                      options={currencies}
                      value={selectedOptionCurrency}
                      onChange={handleDropdownCurrencies}
                      color='primary'
                      height='small'
                      block
                    />
                  </>
                )}
                {(selectedOptionConcept?.label === TRANSLATION_OF_ENTITIES.FRMRecord.label
                  && frmConcepts)
                  && (
                    <>
                      <StatusConatainer device={device}>
                        <StatuRecordContainer device={device}>
                          <Label text='Estado' size='medium' />
                          <Badge
                            text={statusRecord?.frm}
                            color='system'
                            backgroundColor='secondary'
                            size='large'
                          />
                        </StatuRecordContainer>
                        {
                          frmConcepts.statusId === 6
                            ? (
                              <Input
                                type={INPUT_TYPES.NUMBER}
                                showType={INPUT_TYPES.TEXT}
                                name='amountDisaffiliationRefund'
                                label={DISAFFILIATION_REFOUND}
                                placeholder='Ingrese monto'
                                color='primary'
                                value={amountDisaffiliationRefund.value}
                                onChange={handleInputBmsChange}
                                onBlur={() => {}}
                                height='small'
                                error={amountDisaffiliationRefund.error}
                                bits={[{ icon: 'currency_exchange', position: 'left', colorBit: 'tertiary' }]}
                                select
                              />
                            )
                            : (
                              <TextInput
                                label={`Saldo de ${BMS_NAMES.BMS_INBOUND_WITH_BONUS}`}
                                name='status'
                                value={frmConcepts.bmsInbound?.toFixed(2)}
                                height='small'
                                color='primary'
                                weight='bold'
                                onChange={() => {}}
                                disabled
                                bits={[{ icon: 'currency_exchange', position: 'left', colorBit: 'tertiary' }]}
                              />
                            )
                        }
                      </StatusConatainer>
                      {frmConcepts.statusId !== 6
                      && (
                        <>
                          <Input
                            type={INPUT_TYPES.NUMBER}
                            showType={INPUT_TYPES.TEXT}
                            name='bmsDelta'
                            label={BMS_NAMES.BMS_DELTA}
                            placeholder={`Ingrese ${BMS_NAMES.BMS_DELTA}`}
                            color='primary'
                            value={bmsDelta.value}
                            onChange={handleInputBmsChange}
                            onBlur={() => {}}
                            height='small'
                            error={bmsDelta.error}
                            bits={[{ icon: 'currency_exchange', position: 'left', colorBit: 'tertiary' }]}
                          />
                          {bmsDelta.value > frmConcepts.bmsInbound
                            && (
                              <Text size='small' weight='regular' color='error'>
                                * La cantidad de SBM no puede ser mayor al saldo
                              </Text>
                            )}
                        </>
                      )}
                    </>
                  )}
              <TextInput
                label='Descripción'
                placeholder='Ingrese descripción'
                name='decription'
                value={description.value}
                height='small'
                color='primary'
                onChange={handleInputDescriptionChange}
              />
            </>
            )}
         </ModalDataContainer>
         <ButtonContainer justifyContent='flex-end' modal>
           <SolidButton
             size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
             color='primary'
             text='Guardar'
             onClick={handleClick}
             disabled={showButton}
             block={device === BREAKPOINTS.MOBILE}
           />
         </ButtonContainer>
         <Toast
           title={messageToast.title}
           message={messageToast.message}
           color={messageToast.color}
           icon={messageToast.icon}
           isShowing={isShowing}
           position={TOAST_POSITION.rightTop}
           onClick={() => setIsShowing(false)}
         />
       </>
       )}
    </>
  )
}

export default forwardRef(AddDebtModal)

AddDebtModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.object
}
