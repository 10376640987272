import produce from 'immer'

import { FRM_CATEGORIES } from './types'

export const initialState = {
  loading: true,
  error: false,
  frmCategoriesLoading: false,
  frmCategoriesList: [],
  frmCategoryInformation: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case FRM_CATEGORIES.GET:
      draft.FrmCategoriesLoading = true
      break
    case FRM_CATEGORIES.SUCCESS:
      draft.loading = false
      draft.frmCategoriesList = payload
      break
    case FRM_CATEGORIES.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case FRM_CATEGORIES.CLEAR:
      return initialState
    // FILTER
    case FRM_CATEGORIES.SEND_FILTER:
      draft.loading = true
      break
    case FRM_CATEGORIES.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case FRM_CATEGORIES.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case FRM_CATEGORIES.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case FRM_CATEGORIES.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // SET FRM_CATEGORIES
    case FRM_CATEGORIES.SET_FRM_CATEGORY:
      draft.frmCategoriesLoading = true
      break
    case FRM_CATEGORIES.FRM_CATEGORY_INFORMATION:
      draft.frmCategoriesLoading = false
      draft.frmCategoryInformation = payload
      break
    case FRM_CATEGORIES.FRM_CATEGORY_INFORMATION_CLEAR:
      draft.frmCategoryInformation = null
      break
    default:
      return initialState
  }
})

export default reducer
