import styled from 'styled-components'
import { MyButtonLink } from '@global/styles'
import { colors, spacing } from '@global/theme'
import { medium, regular } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const ModalDataContainer = styled.div`
width: 100%;
height: auto;
padding: ${spacing.three};
margin-bottom: ${spacing.three};

border: 1px solid ${colors.grays.light};
border-radius: ${spacing.one};
background: ${colors.background.regular};

& > :first-child {
  margin-bottom: ${spacing.two};
}
`

export const ButtonContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  ${({ buttonColor }) => buttonColor && (`> button { background-color: ${colors.background.regular}}`)}

  & > :first-child {
      margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)};
      margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
  }
`

export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  margin-bottom: ${spacing.one};
  text-align: left;
`
export const ListWrapper = styled.div`
  max-height: 100px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
`
