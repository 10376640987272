import { FRM_CATEGORIES } from './types'

export const getFrmCategories = payload => ({
  type: FRM_CATEGORIES.GET,
  payload
})

export const getFrmCategoriesSuccess = payload => ({
  type: FRM_CATEGORIES.SUCCESS,
  payload
})

export const getFrmCategoriesError = payload => ({
  type: FRM_CATEGORIES.ERROR,
  payload
})

export const ClearFrmCategoriesList = payload => ({
  type: FRM_CATEGORIES.CLEAR,
  payload
})

export const setFrmCategory = payload => ({
  type: FRM_CATEGORIES.SET_FRM_CATEGORY,
  payload
})

export const frmCategoryInformation = payload => ({
  type: FRM_CATEGORIES.FRM_CATEGORY_INFORMATION,
  payload
})

export const frmCategoryInformationClear = payload => ({
  type: FRM_CATEGORIES.FRM_CATEGORY_INFORMATION_CLEAR,
  payload
})

export const FRM_CATEGORIES_ACTIONS = {
  GET_FRM_CATEGORIES: getFrmCategories,
  SUCCESS: getFrmCategoriesSuccess,
  ERROR: getFrmCategoriesError,
  CLEAR: ClearFrmCategoriesList,

  SET_FRM_CATEGORY: setFrmCategory,
  SET_FRM_CATEGORY_INFORMATION: frmCategoryInformation,
  FRM_CATEGORY_INFORMATION_CLEAR: frmCategoryInformationClear
}
