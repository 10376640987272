export const typesEntities = {
  person: { id: 1, name: 'Person', label: 'Persona' },
  affiliateRecord: { id: 2, name: 'AffiliateRecord', label: 'Registro de afiliado' },
  frmRecord: { id: 3, name: 'FRMRecord', label: 'Registro de F.R.M.' },
  liquidationLog: { id: 4, name: 'LiquidationLog', label: 'Registro de liquidación de ingreso' },
  contributionProfile: { id: 5, name: 'Contribution', label: 'Perfil de contribución' },
  inboundPayment: { id: 5, name: 'InboundPayment', label: 'Ingreso' },
  paymentDetail: { id: 6, name: 'OutboundPaymentDetail', label: 'Detalle de pago' },
  paymentSettlementProfile: { id: 7, name: 'OutboundSettlement', label: 'Registro de liquidación de Pago' },
  debtProfile: { id: 8, name: 'OutboundSettlementDetail', label: 'Adeudo' },
  serviceProviderProfile: { id: 9, name: 'Service', label: 'Perfil de proveedor' },
  liquidationProfile: { id: 10, name: 'Settlement', label: 'Liquidación de ingresos' },
  address: { id: 11, name: 'Address', label: 'Dirección' },
  asyncJob: { id: 12, name: 'AsyncJob', label: 'Exportación/Importación' },
  benefit: { id: 13, name: 'Benefit', label: 'Beneficio' },
  currencyValue: { id: 14, name: 'CurrencyValue', label: 'Valor de divisa' },
  email: { id: 15, name: 'Email', label: 'Email' },
  employmentRecord: { id: 16, name: 'EmploymentRecord', label: 'Registro de empleo' },
  errorLog: { id: 17, name: 'ErrorLog', label: 'Registro de error' },
  file: { id: 18, name: 'File', label: 'Archivo' },
  contributionPayment: { id: 19, name: 'ContributionPayment', label: 'Detalle de ingreso' },
  bankAccount: { id: 20, name: 'BankAccount', label: 'Cuenta bancaria' },
  paymentProfile: { id: 21, name: 'OutboundPayment', label: 'Pago' },
  phone: { id: 22, name: 'Phone', label: 'Teléfono' },
  affiliateCategory: { id: 23, name: 'AffiliateCategory', label: 'Categoría de afiliado' },
  bank: { id: 24, name: 'Bank', label: 'Banco' },
  currency: { id: 25, name: 'Currency', label: 'Divisa' },
  frmRecordCategory: { id: 26, name: 'FRMRecordCategory', label: 'Categoría de FRM' },
  frmRecordFile: { id: 27, name: 'FRMRecordFile', label: 'Archivo de FRM' },
  note: { id: 28, name: 'Note', label: 'Nota' },
  personFile: { id: 29, name: 'PersonFile', label: 'Archivo de persona' }
}

export const columnData = {
  Code: { name: 'Código', widthCol: '5%' },
  Date: { name: 'Fecha y Hora', widthCol: '15%' },
  UserName: { name: 'Usuario', widthCol: '20%' },
  EntityType: { name: 'Tipo entidad', widthCol: '15%' },
  EntityId: { name: 'Código de entidad', widthCol: '15%' },
  Description: { name: 'Descripción', widthCol: '20%' },
  Data: { name: 'Datos', widthCol: '15%' }
}
