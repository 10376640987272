export const key = 'PERSON'

export const PERSON = {
  GET_PERSON: 'PERSON/GET_PERSON',
  GET_PERSON_SUCCESS: 'PERSON/GET_PERSON/SUCCESS',
  GET_PERSON_ERROR: 'PERSON/GET_PERSON/ERROR',
  CLEAR: 'PERSON/CLEAR',
  UPDATE_ADDRESSES: 'PERSON/UPDATE_ADDRESSES',
  ADD_ADDRESS: 'PERSON/ADD_ADDRESS',
  EDIT_ADDRESS: 'PERSON/EDIT_ADDRESS',
  REMOVE_ADDRESS: 'PERSON/REMOVE_ADDRESS',
  UPDATE_PHONES: 'PERSON/UPDATE_PHONES',
  ADD_PHONE: 'PERSON/ADD_PHONE',
  EDIT_PHONE: 'PERSON/EDIT_PHONE',
  REMOVE_PHONE: 'PERSON/REMOVE_PHONE',
  UPDATE_NOTES: 'PERSON/UPDATE_NOTES',
  ADD_NOTE: 'PERSON/ADD_NOTE',
  EDIT_NOTE: 'PERSON/EDIT_NOTE',
  REMOVE_NOTE: 'PERSON/REMOVE_NOTE',
  UPDATE_EMAILS: 'PERSON/UPDATE_EMAILS',
  ADD_EMAIL: 'PERSON/ADD_EMAIL',
  EDIT_EMAIL: 'PERSON/EDIT_EMAIL',
  REMOVE_EMAIL: 'PERSON/REMOVE_EMAIL',
  UPDATE_BANKS_ACCOUNTS: 'PERSON/UPDATE_BANKS_ACCOUNTS',
  ADD_BANK_ACCOUNT: 'PERSON/ADD_BANK_ACCOUNT',
  EDIT_BANK_ACCOUNT: 'PERSON/EDIT_BANK_ACCOUNT',
  REMOVE_BANK_ACCOUNT: 'PERSON/REMOVE_BANK_ACCOUNT',
  GET_TYPES: 'PERSON/GET_TYPES',
  GET_TYPES_SUCCESS: 'PERSON/GET_TYPES_SUCCESS',
  GET_TYPES_ERROR: 'PERSON/GET_TYPES_ERROR',
  GET_DEPARTMENTS: 'PERSON/GET_DEPARTMENTS',
  GET_DEPARTMENTS_SUCCESS: 'PERSON/GET_DEPARTMENTS_SUCCESS',
  GET_DEPARTMENTS_ERROR: 'PERSON/GET_DEPARTMENTS_ERROR',
  GET_PERSON_BANKS: 'PERSON/GET_PERSON_BANKS',
  BANKS_INFORMATION: 'PERSON/BANKS_INFORMATION',
  INFORMATION_MESSAGE: 'PERSON/INFORMATION_MESSAGE',
  UPLOAD_FILE: 'PERSON/UPLOAD_FILE',
  GET_FILE_SUCCESS: 'PERSON/GET_FILE_SUCCESS',
  GET_FILE_ERROR: 'PERSON/GET_FILE_ERROR',
  FILE_MESSAGE: 'PERSON/FILE_MESSAGE',
  DOWNLOAD_FILE: 'PERSON/DOWNLOAD_FILE',
  REMOVE_FILE: 'PERSON/REMOVE_FILE',
  EDIT_PROFILE: 'PERSON/EDIT_PROFILE',
  UPDATE_PROFILE: 'PERSON/UPDATE_PROFILE',
  GET_EMPLOYMENT_RECORD: 'PERSON/GET_EMPLOYMENT_RECORD',
  EMPLOYMENT_RECORD: 'PERSON/EMPLOYMENT_RECORD',
  GET_ACTIVE_AFFILIATE_RECORD: 'PERSON/GET_ACTIVE_AFFILIATE_RECORD',
  ACTIVE_AFFILIATE_RECORD: 'PERSON/ACTIVE_AFFILIATE_RECORD',
  GET_FRM_RECORD: 'PERSON/GET_FRM_RECORD',
  FRM_RECORD: 'PERSON/FRM_RECORD',

  CREATE_EMPLOYMENT_RECORD: 'PERSON/CREATE_EMPLOYMENT_RECORD',
  EMPLOYMENT_RECORD_INFORMATION: 'PERSON/EMPLOYMENT_RECORD_INFORMATION',

  GET_AFFILIATE_RECORD_MODAL_DATA: 'PERSON/MODAL/GET_AFFILIATE_RECORD_MODAL_DATA',
  AFFILIATE_RECORD_MODAL_DATA_INFORMATION: 'PERSON/MODAL/AFFILIATE_RECORD_MODAL_DATA_INFORMATION',
  CREATE_AFFILIATE_RECORD: 'PERSON/CREATE_AFFILIATE_RECORD',
  AFFILIATE_RECORD_INFORMATION: 'PERSON/AFFILIATE_RECORD_INFORMATION',

  CREATE_FRM_RECORD: 'PERSON/CREATE_FRM_RECORD',
  FRM_RECORD_INFORMATION: 'PERSON/FRM_RECORD_INFORMATION',

  CLEAR_INFORMATION_MESSAGE: 'PERSON/CLEAR_INFORMATION_MESSAGE',

  GET_CONTRIBUTION_PAYMENTS: 'PERSON/GET_CONTRIBUTION_PAYMENTS',
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: 'PERSON/GET_CONTRIBUTION_PAYMENTS_SUCCESS',
  GET_CONTRIBUTION_PAYMENTS_ERROR: 'PERSON/GET_CONTRIBUTION_PAYMENTS_ERROR',

  GET_FILTER_DATA: 'PERSON/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PERSON/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PERSON/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PERSON/FILTER/CLEAR'
}

export default key
