import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { roboto, large, bold } from '@global/fonts'
import { spacing } from '@global/theme'
import { DEVICE_NAME } from '@global/constants'

export const MyLink = styled(NavLink)`
  ${roboto};
  ${large};
  ${bold};

  color: #333333;
`

export const BreadcrumbsStyle = styled.div`
  ${roboto};

  display: flex;
  flex-wrap: wrap;

  & a, p {
    margin: ${({ deviceName }) => {
    if (deviceName === DEVICE_NAME.MOBILE) return 0
    return `0 ${spacing.one}`
  }};
  }

  & > :first-child {
    & > a {
      margin-left: 0;
    } 
  }
`

export const Crumb = styled.div`
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`
