export const key = 'LIQUIDATION_LOG'

export const LIQUIDATION_LOG = {
  GET_LIQUIDATION_LOG: 'LIQUIDATION_LOG/GET_DATA',
  SUCCESS: 'LIQUIDATION_LOG/GET_SUCCESS',
  ERROR: 'LIQUIDATION_LOG/GET_ERROR',
  CLEAR: 'LIQUIDATION_LOG/CLEAR',

  EDIT_LIQUIDATION_PROFILE: 'LIQUIDATION_LOG/PROFILE/EDIT',
  EDIT_LIQUIDATION_PROFILE_INFORMATION: 'LIQUIDATION_LOG/PROFILE/EDIT/INFORMATION',
  LIQUIDATION_PROFILE_UPDATED: 'LIQUIDATION_LOG/PROFILE/EDIT/UPDATED',

  CALCULATE_CONTRIBUTIONS: 'LIQUIDATION_LOG/CONTRIBUTIONS/CALCULATE',
  CALCULATE_CONTRIBUTIONS_INFORMATION: 'LIQUIDATION_LOG/CONTRIBUTIONS/CALCULATE/INFORMATION',
  GET_LIQUIDATION_LOG_CONTRIBUTIONS: 'LIQUIDATION_LOG/CONTRIBUTIONS/GET_LIST',
  CONTRIBUTIONS_SUCCESS: 'LIQUIDATION_LOG/GET_LIST/CONTRIBUTIONS_SUCCESS',
  CONTRIBUTIONS_ERROR: 'LIQUIDATION_LOG/GET_LIST/CONTRIBUTIONS_ERROR',
  CONTRIBUTIONS_CLEAR: 'LIQUIDATION_LOG/CLEAR_LIST',
  SEND_FILTER: 'LIQUIDATION_LOG/FILTER/SEND',
  GET_FILTER_DATA: 'LIQUIDATION_LOG/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'LIQUIDATION_LOG/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'LIQUIDATION_LOG/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'LIQUIDATION_LOG/FILTER/CLEAR',

  GET_MODAL_DATA: 'LIQUIDATION_LOG/MODAL/GET_DATA',
  MODAL_DATA_INFORMATION: 'LIQUIDATION_LOG/MODAL/DATA_INFORMATION',
  ADD_CONTRIBUTION: 'LIQUIDATION_LOG/CONTRIBUTION/ADD',
  ADD_CONTRIBUTION_INFORMATION: 'LIQUIDATION_LOG/CONTRIBUTION/ADD/INFORMATION',
  MESSAGE_INFORMATION_CLEAR: 'LIQUIDATION_LOG/MESSAGE_INFORMATION/CLEAR',
  GET_PERSON_CONCEPTS: 'LIQUIDATION_LOG/MODAL/GET_PERSON_CONCEPTS',
  GET_CURRENCIES: 'LIQUIDATION_LOG/CURRENCIES/GET',
  CURRENCIES_INFORMATION: 'LIQUIDATION_LOG/CURRENCIES/INFORMATION',
  LIQUIDATION_CONTRIBUTIONS_UPDATE: 'LIQUIDATION_LOG/LIQUIDATION_CONTRIBUTIONS/UPDATE',
  PENDING_PROCESS_INFO: 'LIQUIDATION_LOG/PENDING_PROCES/INFO',
  GET_PENDING_PROCESS_INFO: 'LIQUIDATION_LOG/GET_PENDING_PROCESS/INFO'

}

export default key
