import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { SITE } from '@routes/paths'
import SELECTORS from '../redux/selectors'
import { USER_ACTIONS } from '../redux/action'
import useRedux from '../redux'

export const useUser = () => {
  useRedux()
  const dispatch = useDispatch()
  const history = useHistory()

  const GetUser = () => {
    const user = useSelector(SELECTORS.SUCCESS)
    if (user) return user

    const userLocalStorage = JSON.parse(localStorage.getItem('user'))

    if (userLocalStorage) {
      const actionSended = JSON.parse(localStorage.getItem('actionSended'))
      if (!actionSended) {
        dispatch(USER_ACTIONS.REFRESH_USER(userLocalStorage.token))
        localStorage.setItem('actionSended', JSON.stringify(true))
      }
      return userLocalStorage
    }

    return null
  }

  const GetToken = () => {
    const u = useSelector(SELECTORS.SUCCESS) || JSON.parse(localStorage.getItem('user'))

    if (u) {
      const { token, tokenCreationDate } = u
      const objResponse = (typeof tokenCreationDate === 'string')
        ? { token, tokenCreationDate: new Date(tokenCreationDate) }
        : { token, tokenCreationDate }
      return objResponse
    }
  }

  const Logout = () => {
    localStorage.removeItem('user')
    const actionSended = JSON.parse(localStorage.getItem('actionSended'))
    if (actionSended) localStorage.removeItem('actionSended')
    setTimeout(() => {
      dispatch(USER_ACTIONS.CLEAR())
      history.push(SITE.SIGN_IN)
    }, 1500)
  }

  const ErrorRefreshToken = () => {
    const errorRefreshToken = useSelector(SELECTORS.ERROR_USER_REFRESH)
    return errorRefreshToken
  }

  return {
    GetUser,
    GetToken,
    ErrorRefreshToken,
    Logout
  }
}
