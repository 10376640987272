import React from 'react'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'

import Input from '../../baseInput/baseInput'
import inputPropTypes from '../../propTypes'

export const NumberI = props => {
  const { bits, onChange, name, max, min, step, value } = props

  const increment = () => onChange({
    name,
    value: value < max ? Number(value) + Number(step) : Number(value),
    error: value < max ? '' : `${max} is the maximum`
  })

  const decrement = () => onChange({
    name,
    value: value > min ? Number(value) - Number(step) : Number(value),
    error: value > min ? '' : `${min} is the minimum`
  })

  const dec = {
    icon: 'expand_more',
    colorBit: 'contrast',
    position: 'left',
    onClick: decrement
  }

  const inc = {
    position: 'right',
    icon: 'expand_less',
    colorBit: 'contrast',
    onClick: increment
  }

  const handleKeyDown = e => {
    if (e.keyCode !== 38 && e.keyCode !== 40) return
    e.preventDefault()
    if (e.keyCode === 38) increment()
    else decrement()
  }

  const handleOnBlur = () => {
    if (value <= max && value >= min) return

    const error = (value > max) ? `${max} is the maximum` : `${min} is the minimum`

    onChange({
      name,
      value,
      error
    })
  }

  return (
    <Input
      {...props}
      type={INPUT_TYPES.NUMBER}
      bits={[dec, ...bits, inc]}
      max={max}
      min={min}
      step={step}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    />
  )
}

NumberI.propTypes = {
  ...inputPropTypes,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number
}

NumberI.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  bits: [],
  step: 1
}

export default NumberI
