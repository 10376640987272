import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { HISTORY_RECORD_ACTIONS } from './actions'
import { HISTORY_RECORD } from './types'

const apiCalls = {
  getEntity: ({ historyId, token }) => (
    Axios(`History/${historyId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  downloadFile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getHistoryRecord ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getEntity, payload)

    const historyRecord = {
      id: data.id,
      user: data.user || data.userName,
      date: data.createdAt,
      entityType: data.discriminator || data.entityType,
      entityId: data.entityId,
      description: data.description,
      discriminator: data.discriminator
    }

    const historyRecordObj = {
      historyRecord,
      entityData: data.payload,
      original: data.original
    }
    yield put(HISTORY_RECORD_ACTIONS.SUCCESS(historyRecordObj))
  } catch (error) {
    yield put(HISTORY_RECORD_ACTIONS.ERROR(error))
  }
}

export function* downloadFileHistoryLog ({ payload }) {
  try {
    const res = yield call(apiCalls.downloadFile, payload)
    const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]

    const data = {
      status: res.status,
      data: res.data,
      contentType: res.headers['content-type'],
      fileName
    }

    yield put(HISTORY_RECORD_ACTIONS.FILE_MESSAGE(data))
  } catch (error) {
    yield put(HISTORY_RECORD_ACTIONS.FILE_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(HISTORY_RECORD.GET_HISTORY_RECORD, getHistoryRecord)
  yield takeLatest(HISTORY_RECORD.DOWNLOAD_FILE, downloadFileHistoryLog)
}
