export const key = 'LIQUIDATIONS'

export const LIQUIDATIONS = {
  GET: 'LIQUIDATIONS/GET_LIST',
  SUCCESS: 'LIQUIDATIONS/GET_LIST/SUCCESS',
  ERROR: 'LIQUIDATIONS/GET_LIST/ERROR',
  CLEAR: 'LIQUIDATIONS/CLEAR_LIST',

  SEND_FILTER: 'LIQUIDATIONS/FILTER/SEND',
  GET_FILTER_DATA: 'LIQUIDATIONS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'LIQUIDATIONS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'LIQUIDATIONS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'LIQUIDATIONS/FILTER/CLEAR',

  ADD_LIQUIDATION: 'LIQUIDATIONS/LIQUIDATION/ADD',
  ADD_LIQUIDATION_INFORMATION: 'LIQUIDATIONS/LIQUIDATION/ADD_INFORMATION',
  GET_STATUSES: 'LIQUIDATIONS/LIQUIDATION/GET_STATUSES',
  LIQUIDATIONS_STATUSES: 'LIQUIDATIONS/LIQUIDATION/LIQUIDATIONS_STATUSES',
  CLEAR_MESSAGE_INFORMATION: 'LIQUIDATIONS/LIQUIDATION/CLEAR_MESSAGE_INFORMATION'
}

export default key
