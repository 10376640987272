import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@components/texts'

import { LoaderContainer, Wrapper, CircularProgress, ValueContainer } from './styles'

const CircularProgressBar = ({ progress, width, height }) => (
  <LoaderContainer>
    <Wrapper width={width} height={height}>
      <CircularProgress color='primary' progressValue={progress}>
        <ValueContainer>
          <Text size='big' weight='bold' align='left'>{progress}%</Text>
        </ValueContainer>
      </CircularProgress>
    </Wrapper>
  </LoaderContainer>

)

CircularProgressBar.defaultProps = {
  progress: 0
}

CircularProgressBar.propTypes = {
  progress: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string
}

export default CircularProgressBar
