import { SITE } from '@routes/paths'
import users from '@assets/usuario.png'
import imports from '@assets/importacion.png'
import exports from '@assets/exportaciones.png'
import errorLog from '@assets/log-de-error.png'
import errorActivity from '@assets/log-actividad.png'

export const dataItems = [
  { id: 1, label: 'Usuarios', src: users, to: `${SITE.SETTING}${SITE.USERS}` },
  { id: 2, label: 'Importaciones', src: imports, to: `${SITE.SETTING}${SITE.IMPORTS}` },
  { id: 3, label: 'Exportaciones', src: exports, to: `${SITE.SETTING}${SITE.EXPORTS}` },
  { id: 4, label: 'Log de errores', src: errorLog, to: `${SITE.SETTING}${SITE.ERROR_LOG}` },
  { id: 5, label: 'Log de actividad', src: errorActivity, to: `${SITE.SETTING}${SITE.ACTIVITY_LOG}` },
  { id: 6, label: 'Gestión de entidades', src: errorActivity, to: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}` },
  { id: 6, label: 'Cuentas bancarias ATAE/FRATAE', src: errorActivity, to: `${SITE.SETTING}${SITE.ATAE_BANK_ACCOUNTS}` }
]
