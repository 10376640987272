export const key = 'CURRENCY_PROFILE'

export const CURRENCY_PROFILE = {
  GET_CURRENCY_PROFILE: 'GET_CURRENCY/PROFILE',
  CURRENCY_PROFILE_SUCCESS: 'CURRENCY_PROFILE/SUCCESS',

  GET_CURRENCY_VALUES: 'GET_CURRENCY/VALUES',
  CURRENCY_VALUES_SUCCESS: 'CURRENCY_VALUES/SUCCESS',

  DATA_READY: 'GET_CURRENCY/DATA_READY',
  ERROR: 'GET_CURRENCY/ERROR',
  CLEAR: 'GET_CURRENCY/CLEAR',

  SET_CURRENCY_VALUE: 'CURRENCY_VALUE/SET_CURRENCY_VALUE',
  MESSAGE_INFORMATION: 'CURRENCY_VALUE/MESSAGE_INFORMATION',
  MESSAGE_INFORMATION_CLEAR: 'CURRENCY_VALUE/MESSAGE_INFORMATION/CLEAR',

  GET_FILTER_DATA: 'CURRENCY_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'CURRENCY_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'CURRENCY_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'CURRENCY_PROFILE/FILTER/CLEAR'
}

export default key
