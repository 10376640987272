import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  TRANSLATION_OF_ENTITIES,
  DETAIL_TYPES
} from '@global/constants'

import { TOKEN_HELPERS, currencyFormatUY, strMonthYear } from '@utils/helpers'

import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import Modal from '@components/modal'
import Badge from '@components/badge'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditDebtProfile from './components/editDebtProfileModal'

import useRedux from './redux'
import { DEBT_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, DebtProfileData, Tbody, ItemLink } from './styles'

const DebtProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { debtProfileId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [debtProfile, setDebtProfile] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [open, setOpen] = useState(false)
  const [editDebtModal, setEditDebtModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [concepts, setConcepts] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_DEBT_PROFILE)
  const editInformation = useSelector(SELECTORS.EDIT_INFORMATION)
  const debtProfileData = useSelector(SELECTORS.DEBT_PROFILE_SUCCESS)
  const statusesData = useSelector(SELECTORS.DEBT_PROFILE_STATUSES_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const currencies = useSelector(SELECTORS.CURRENCIES)
  const conceptsList = useSelector(SELECTORS.CONCEPTS) // poner el conceptos en un estado

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleServiceClick = serviceId => {
    if (serviceId) {
      const win = window.open(`${SITE.SERVICES}${SITE.SERVICE_PROVIDER_PROFILE}/${serviceId}`, '_blank')
      win.focus()
    }
  }

  const handleOutboundSettlementClick = outboundSettlementId => {
    if (outboundSettlementId) {
      const win = window.open(`${SITE.PAYMENT_SETTLEMENT_PROFILE}/${outboundSettlementId}`, '_blank')
      win.focus()
    }
  }

  const handleEntityClick = (entity, entityId) => {
    let url = ''
    switch (entity) {
      case TRANSLATION_OF_ENTITIES.FRMRecord.name:
        url = `${SITE.FRM_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
        url = `${SITE.AFFILIATE_RECORD}/${entityId}`
        break
      default:
        url = `${SITE.SERVICE_PROVIDER_PROFILE}/${entityId}`
        break
    }
    const win = window.open(url, '_blank')
    win.focus()
  }

  const handleSendToPaymentDetailList = debtId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${debtId}/${DETAIL_TYPES.DEBT}`, '_blank')
    win.focus()
  }

  const handlePaymentDetailClick = detailId => {
    if (detailId) {
      const win = window.open(`${SITE.PAYMENT_DETAIL}/${detailId}`, '_blank')
      win.focus()
    }
  }

  const renderDebtProfileData = objDebtProfileData => {
    const { affiliateRecordId, frmRecordId, serviceId } = objDebtProfileData
    let { payments } = objDebtProfileData

    let text = null
    let entityType = null
    let entityId = null

    if (affiliateRecordId) {
      text = `${TRANSLATION_OF_ENTITIES.AffiliateRecord.label} - ${affiliateRecordId}`
      entityType = TRANSLATION_OF_ENTITIES.AffiliateRecord.name
      entityId = affiliateRecordId
    }

    if (frmRecordId) {
      text = `${TRANSLATION_OF_ENTITIES.FRMRecord.label} - ${frmRecordId}`
      entityType = TRANSLATION_OF_ENTITIES.FRMRecord.name
      entityId = frmRecordId
    }

    if (serviceId) {
      text = `${TRANSLATION_OF_ENTITIES.Service.label} - ${serviceId}`
      entityType = TRANSLATION_OF_ENTITIES.Service.name
      entityId = serviceId
    }

    const entity = (
      <ItemLink
        color='tertiary'
        underline
        onClick={
          () => handleEntityClick(
            entityType, entityId
          )
        }
      >
        {text}
      </ItemLink>
    )

    let link = 'N/A'
    payments = payments.filter(cp => cp.statusId === 1)
    if (payments?.length > 0) {
      link = payments?.length === 1
        ? (
          <ItemLink
            color='tertiary'
            underline
            marginRight
            onClick={() => handlePaymentDetailClick(payments[0].id)}
          >
            {`DP - ${payments[0].id}`}
          </ItemLink>
        )
        : (
          <ItemLink
            color='tertiary'
            underline
            marginRight
            onClick={() => handleSendToPaymentDetailList(objDebtProfileData?.id)}
          >
            Ver lista de DP
          </ItemLink>
        )
    }

    const handleProfileClick = serviceId ? handleServiceClick : handlePersonClick
    const profileId = serviceId ? objDebtProfileData?.serviceId : objDebtProfileData?.personId
    const profileLink = serviceId
      ? `${objDebtProfileData?.serviceId} - ${objDebtProfileData?.serviceName}`
      : `${objDebtProfileData?.personId} - ${objDebtProfileData?.personName}`
    const profile = (
      <ItemLink
        color='tertiary'
        underline
        onClick={() => handleProfileClick(profileId)}
      >
        {profileLink}
      </ItemLink>
    )

    return (
      <>
        <Tr>
          <Td colorLight widthPerc>Código:</Td>
          <Td largeSize>{`A - ${objDebtProfileData.id}` || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>{serviceId ? 'Proveedor' : 'Persona'}:</Td>
          <Td largeSize>{profile || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Concepto:</Td>
          <Td largeSize>{
            objDebtProfileData.concept || concepts?.find(c => c.id === objDebtProfileData.conceptId)?.name || 'N/A'
            }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Entidad:</Td>
          <Td largeSize>{entity}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Monenda:</Td>
          <Td largeSize>{
            objDebtProfileData.currencyName
            || currencies.find(c => c.id === objDebtProfileData.currencyId).name
          }
          </Td>
        </Tr>
        {objDebtProfileData?.conceptId === 3
          && (
          <Tr>
            <Td colorLight widthPerc>Monto en SBM:</Td>
            <Td largeSize>{objDebtProfileData?.totalBMS?.toFixed(2) || 'N/A'}
            </Td>
          </Tr>
          )}
        <Tr>
          <Td colorLight widthPerc>Monto:</Td>
          <Td largeSize>{
            objDebtProfileData?.currencyId === 1
              ? currencyFormatUY(objDebtProfileData?.total)
              : objDebtProfileData?.total || 'N/A'
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Total Pagado:</Td>
          <Td largeSize>
            {objDebtProfileData.totalPaid
              ? (
                <Text
                  size='large'
                  align='left'
                  color={(objDebtProfileData.totalPaid > objDebtProfileData.total) ? 'error' : 'system'}
                  weight={(objDebtProfileData.totalPaid > objDebtProfileData.total) ? 'semibold' : 'regular'}
                >
                  {currencyFormatUY(objDebtProfileData?.totalPaid)}
                </Text>
              )
              : 'N/A'}
          </Td>
        </Tr>
        {objDebtProfileData?.conceptId === 1
         && (
         <>
           <Tr>
             <Td colorLight widthPerc>PEG:</Td>
             <Td largeSize>
               <Badge
                 text={objDebtProfileData?.isPEG ? 'Si' : 'No'}
                 backgroundColor='secondary'
                 size='medium'
                 color='system'
               />
             </Td>
           </Tr>
           <Tr>
             <Td colorLight widthPerc>Retención IASS:</Td>
             <Td largeSize>{
            objDebtProfileData?.currencyId === 1
              ? currencyFormatUY(objDebtProfileData?.amountIASS)
              : objDebtProfileData?.total || 'N/A'
          }
             </Td>
           </Tr>
         </>
         )}
        <Tr>
          <Td colorLight widthPerc>Registro de pago:</Td>
          <Td largeSize>{link}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Liquidación de pago:</Td>
          <Td largeSize>{
            objDebtProfileData.outboundSettlementId
              ? (
                <ItemLink
                  color='tertiary'
                  underline
                  onClick={() => handleOutboundSettlementClick(objDebtProfileData.outboundSettlementId)}
                >
                  {`${strMonthYear(objDebtProfileData?.month - 1, objDebtProfileData?.year)}`}
                </ItemLink>
              )
              : 'N/A'
            }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Estado:</Td>
          <Td largeSize>{
          objDebtProfileData.statusName
          || statuses.find(s => s.id === objDebtProfileData.statusId).name
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPerc>Descripción:</Td>
          <Td largeSize>{objDebtProfileData.description}
          </Td>
        </Tr>
      </>
    )
  }

  const handleSaveDebt = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEBT_PROFILE_ACTIONS.EDIT_DEBT_PROFILE({
        debtProfileId, data: newProfile, token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setEditDebtModal(null)
  }

  const handleEditDebt = () => {
    setEditDebtModal({
      oldData: debtProfile,
      saveData: handleSaveDebt,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEBT_PROFILE_ACTIONS.GET_DEBT_PROFILE({ debtProfileId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (debtProfileData) {
      setDebtProfile(debtProfileData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.DEBT_PROFILE} - ${debtProfileData.id}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.FINANCE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url
        }
        url += SITE.PAYMENT_SETTLEMENTS
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENTS}`,
          link: true,
          url
        }
        url += `${SITE.PAYMENT_SETTLEMENT_PROFILE}/${debtProfileData.outboundSettlementId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENT_PROFILE} - ${debtProfileData?.outboundSettlementId}`,
          link: false,
          url
        }
        url += `${SITE.DEBT_PROFILE}/${debtProfileId}`
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.DEBT_PROFILE} - ${debtProfileId}`,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb, lastCrumb])
      }
    }
  }, [debtProfileData])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData)
    }
  }, [statusesData])

  useEffect(() => {
    if (conceptsList) {
      setConcepts(conceptsList)
    }
  }, [conceptsList])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        dispatch(DEBT_PROFILE_ACTIONS.CLEAR())
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(DEBT_PROFILE_ACTIONS.GET_DEBT_PROFILE({ debtProfileId, token }))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (editInformation) {
      if (editInformation.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: editInformation.message || `Error ${editInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [editInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading
              device={device}
              columnStart='1'
              columnEnd='12'
              rowStart='2'
              rowEnd='2'
            >
              {debtProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`Adeudo - ${debtProfile.id}`}
               </Heading>
               )}
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <DebtProfileData>
                      <Tbody>
                        {debtProfile && renderDebtProfileData(debtProfile)}
                      </Tbody>
                    </DebtProfileData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditDebt} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}

            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Editar Adeudo'
              minHeight='auto'
            >
              {editDebtModal
                && <EditDebtProfile {...editDebtModal} />}
            </Modal>
          </>
        )}
    </>
  )
}

export default DebtProfile
