import { SITE } from '@routes/paths'
import imports from '@assets/importacion.png'
import exports from '@assets/exportaciones.png'
import { TRANSLATION_OF_ROUTES } from '@global/constants'

export const dataItems = [
  {
    id: 1,
    label: `${TRANSLATION_OF_ROUTES.LIQUIDATIONS}`,
    src: imports,
    to: `${SITE.FINANCE}${SITE.LIQUIDATIONS}`
  },
  {
    id: 2,
    label: `${TRANSLATION_OF_ROUTES.INCOME}`,
    src: imports,
    to: `${SITE.FINANCE}${SITE.INCOME}`
  },
  {
    id: 3,
    label: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENTS}`,
    src: exports,
    to: `${SITE.FINANCE}${SITE.PAYMENT_SETTLEMENTS}`
  },
  {
    id: 4,
    label: `${TRANSLATION_OF_ROUTES.PAYMENTS}`,
    src: exports,
    to: `${SITE.FINANCE}${SITE.PAYMENTS}`
  }
]
