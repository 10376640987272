import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { SolidButton } from '@components/button'
import { TextInput, TextAreaInput, Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import CustomDatePicker from '@components/datePicker'
import { Text } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import { SEX } from '@containers/person/data'
import { ModalDataContainer, ButtonContainer } from '../../styles'

const ProfileModal = ({ oldData, close, saveData, personStatuses }) => {
  const today = new Date()
  const { Logout } = useUser()
  const { device } = useWindowDimensions()

  const [ci, setCi] = useState({
    value: oldData.ci,
    name: '',
    error: ''
  })
  const [fullName, setFullName] = useState({
    value: oldData.fullName,
    name: '',
    error: ''
  })
  const [birthday, setBirthday] = useState(new Date(oldData.birthday))
  const [remarks, setRemarks] = useState({
    value: oldData.remarks,
    name: '',
    error: ''
  })
  const [showButton, setShowButton] = useState(false)
  const [errorBirthday, setErrorBirthday] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [selectedSex, setSelectedSex] = useState(SEX.find(s => s.id === oldData.sexId))
  const [selectedPersonStatus, setSelectedPersonStatus] = useState(
    personStatuses.find(s => s.id === oldData.statusId)
  )

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleTextArea = val => setRemarks(val)

  const handleInputChange = val => {
    const { name, error } = val
    if (name === 'ci' && error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    if (name === 'ci') setCi(val)
    else setFullName(val)
  }

  const handleDropdownChange = e => {
    const { name, value } = e

    if (name === 'sex') setSelectedSex(value)
    else setSelectedPersonStatus(value)
  }

  const handleClick = () => {
    const newDataProfile = {
      ...oldData,
      ci: ci.value,
      fullName: fullName.value,
      birthday,
      remarks: remarks.value,
      sexId: selectedSex.id,
      statusId: selectedPersonStatus.id
    }
    saveData(newDataProfile)
    close()
  }

  const errorControl = () => {
    if (((ci.value || ci.value === 0) && !ci.error)
      && (fullName.value && !fullName.error)
      && (today > birthday)) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(() => {
    errorControl()
  }, [ci, fullName, birthday])

  useEffect(() => {
    if (birthday > today) {
      setErrorBirthday(true)
    } else setErrorBirthday(false)
  }, [birthday])
  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column' file>
        <Input
          type={INPUT_TYPES.NUMBER}
          showType={INPUT_TYPES.TEXT}
          name='ci'
          label='Número de cédula'
          placeholder='Ingrese número de cédula'
          color='primary'
          value={ci.value}
          onChange={handleInputChange}
          onBlur={() => {}}
          height='small'
          error={ci.error}
          required
        />
        <TextInput
          name='fullname'
          label='Persona'
          placeholder='Ingrese nombre y apellido'
          color='primary'
          value={fullName.value}
          onChange={handleInputChange}
          height='small'
        />
        <Dropdown
          name='sex'
          label='Género'
          placeholder='Seleccione'
          options={SEX}
          value={selectedSex}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
          required
        />
        <Dropdown
          name='personStatus'
          label='Estado persona'
          placeholder='Seleccione'
          options={personStatuses}
          value={selectedPersonStatus}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
          required
        />
        <CustomDatePicker
          name='birthdy'
          title='Fecha de nacimiento'
          color='primary'
          onChange={setBirthday}
          date={oldData.birthday}
          yearsToShow={100}
          margin='8px 0 24px 0'
          justifyContentButton='space-between'
          block
        />
        { errorBirthday
         && (
         <Text size='small' weight='bold' color='error'>
           La fecha de nacimiento no puede ser menor a la fecha actual
         </Text>
         )}
        <TextAreaInput
          label='Observaciones'
          size='small'
          color='primary'
          onChange={handleTextArea}
          value={remarks.value}
          resize='none'
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end'>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default ProfileModal

ProfileModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  oldData: PropTypes.object,
  personStatuses: PropTypes.array
}
