import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { SERVICE_PROVIDER_ACTIONS } from './actions'
import { SERVICE_PROVIDER } from './types'

const apiCalls = {
  getServicesProvider: ({ data, token }) => (
    Axios.post('ServiceProvider/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createProvider: ({ data, token }) => (
    Axios.post('ServiceProvider/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getServicesProvider ({ payload }) {
  try {
    const { data: serviceProvider } = yield call(apiCalls.getServicesProvider, payload)
    yield put(SERVICE_PROVIDER_ACTIONS.SUCCESS(serviceProvider))
  } catch (error) {
    yield put(SERVICE_PROVIDER_ACTIONS.ERROR(error))
  }
}

export function* addProvider ({ payload }) {
  try {
    const { token } = payload
    const res = yield call(apiCalls.createProvider, payload)
    const { status } = res
    const data = { pageNumber: 1, pageSize: 15 }
    const { data: serviceProviderProviderUpdated } = yield call(apiCalls.getServicesProvider, { data, token })

    yield put(SERVICE_PROVIDER_ACTIONS.ADD_PROVIDER_INFORMATION({ status }))
    yield put(SERVICE_PROVIDER_ACTIONS.SUCCESS(serviceProviderProviderUpdated))
  } catch (error) {
    yield put(SERVICE_PROVIDER_ACTIONS.ADD_PROVIDER_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(SERVICE_PROVIDER.GET, getServicesProvider)
  yield takeLatest(SERVICE_PROVIDER.ADD_PROVIDER, addProvider)
}
