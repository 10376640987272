export const key = 'NO_EMPLOYEE_FEE'

export const NO_EMPLOYEE_FEE = {
  CREATE_NEW_FEE: 'NO_EMPLOYEE_FEE/NEW_FEE/CREATE',
  CREATE_NEW_FEE_SUCCESS: 'NO_EMPLOYEE_FEE/NEW_FEE/CREATE/SUCCESS',
  GET_FEE_HISTORY: 'NO_EMPLOYEE_FEE/GET/FEE_HISTORY',
  FEE_HISTORY_SUCCESS: 'NO_EMPLOYEE_FEE/FEE_HISTORY/SUCCESS',
  MESSAGE_INFORMATION: 'NO_EMPLOYEE_FEE/MESSAGE/INFORMATION',
  ERROR_MESSAGE: 'NO_EMPLOYEE_FEE/MESSAGE/ERROR',
  CLEAR_MESSAGE: 'NO_EMPLOYEE_FEE/CLEAR/MESSAGE',
  CLEAR_ERROR_MESSAGE: 'NO_EMPLOYEE_FEE/CLEAR/MESSAGE/ERROR'
}

export default key
