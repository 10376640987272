import { PAYMENTS } from './types'

export const getPayments = payload => ({
  type: PAYMENTS.GET,
  payload
})

export const getPaymentsSuccess = payload => ({
  type: PAYMENTS.SUCCESS,
  payload
})

export const getPaymentsError = payload => ({
  type: PAYMENTS.ERROR,
  payload
})

export const ClearPaymentsList = payload => ({
  type: PAYMENTS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: PAYMENTS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: PAYMENTS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PAYMENTS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PAYMENTS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PAYMENTS.FILTER_DATA_CLEAR
})

export const getBankAccounts = payload => ({
  type: PAYMENTS.GET_BANK_ACCOUNTS,
  payload
})

export const bankAccountsResponse = payload => ({
  type: PAYMENTS.BANK_ACCOUNTS_RESPONSE,
  payload
})

export const getOutboundSettlement = payload => ({
  type: PAYMENTS.GET_OUTBOUND_SETTLEMENT,
  payload
})

export const outboundSettlementResponse = payload => ({
  type: PAYMENTS.OUTBOUND_SETTLEMENT_RESPONSE,
  payload
})

export const addPayment = payload => ({
  type: PAYMENTS.ADD_PAYMENT,
  payload
})

export const addPaymentInformation = payload => ({
  type: PAYMENTS.ADD_PAYMENT_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: PAYMENTS.CLEAR_MESSAGE_INFORMATION
})

export const PAYMENTS_ACTIONS = {
  GET_PAYMENTS: getPayments,
  SUCCESS: getPaymentsSuccess,
  ERROR: getPaymentsError,
  CLEAR: ClearPaymentsList,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_BANK_ACCOUNTS: getBankAccounts,
  BANK_ACCOUNTS_RESPONSE: bankAccountsResponse,
  GET_OUTBOUND_SETTLEMENT: getOutboundSettlement,
  OUTBOUND_SETTLEMENT_RESPONSE: outboundSettlementResponse,

  ADD_PAYMENT: addPayment,
  ADD_PAYMENT_INFORMATION: addPaymentInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation
}
