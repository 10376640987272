export const key = 'PAYMENT_DETAIL'

export const PAYMENT_DETAIL = {
  GET_PAYMENT_DETAIL: 'PAYMENT_DETAIL/GET_DATA',
  SUCCESS: 'PAYMENT_DETAIL/GET_SUCCESS',
  ERROR: 'PAYMENT_DETAIL/GET_ERROR',
  CLEAR: 'PAYMENT_DETAIL/CLEAR',

  EDIT_PAYMENT_DETAIL: 'PAYMENT_DETAIL/PROFILE/EDIT',
  EDIT_PAYMENT_DETAIL_INFORMATION: 'PAYMENT_DETAIL/PROFILE/EDIT/INFORMATION',
  PAYMENT_DETAIL_UPDATED: 'PAYMENT_DETAIL/PROFILE/EDIT/UPDATED',

  GET_BANK_ACCOUNTS: 'PAYMENT_DETAIL/MODAL/GET_BANK_ACCOUNTS',
  BANK_ACCOUNTS_INFORMATION: 'PAYMENT_DETAIL/MODAL/BANK_ACCOUNTS_INFORMATION'
}

export default key
