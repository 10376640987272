import { BMS_NAMES } from '@global/constants'

export const layoutProps = {
  rows: ['1fr'],
  columns: ['1fr'],
  gap: '24px',
  alignItems: 'start',
  height: 'min-content',
  rowStart: 1,
  rowEnd: 1,
  columnStart: 1,
  columnEnd: 12
}

export const options = [
  { id: 1, label: 'Filas por páginas: 15', value: 15 },
  { id: 2, label: 'Filas por páginas: 30', value: 30 },
  { id: 3, label: 'Filas por páginas: 45', value: 45 }
]

export const columnData = {
  BillingNumber: { name: 'Nro. de Cobro', widthCol: '15%' },
  FullName: { name: 'Persona', widthCol: '30%' },
  AffiliateStatusName: { name: 'Afiliación', widthCol: '10%' },
  EmploymentStatusName: { name: 'Empleo', widthCol: '10%' },
  AffiliateCategoryName: { name: 'Categoría', widthCol: '10%' },
  AffiliateDate: { name: 'Fecha de ing. ATAE', widthCol: '20%' },
  FRMStatusName: { name: 'FRM', widthCol: '10%' },
  CI: { name: 'Cédula identidad', widthCol: '15%' },
  PersonStatusName: { name: 'Estado', widthCol: '10%' },
  BMSInbound: { name: BMS_NAMES.BMS_INBOUND, widthCol: '10%' },
  BMSOutbound: { name: BMS_NAMES.BMS_OUTBOUND, widthCol: '10%' },
  BMSBonusCoefficient: { name: BMS_NAMES.BMS_BONUS_COEFFICIENT, widthCol: '10%' },
  BMSAvailable: { name: BMS_NAMES.BMS_INBOUND_WITH_BONUS, widthCol: '10%' }

}

export const sexId = {
  data: [
    { id: 1, name: 'Female' },
    { id: 2, name: 'Male' }
  ]
}

export const phoneTypesTranslation = {
  Work: 'Trabajo',
  Personal: 'Personal',
  Other: 'Otro'
}

export const sexTypesTranslation = {
  Female: 'Femenino',
  Male: 'Masculino'
}
