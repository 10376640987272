import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS, BMS_NAMES } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { ButtonsContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import CustomDatePicker from '@components/datePicker'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { FRM_RECORD_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { ModalDataContainerFrmEditProfile } from '../../styles'

const FrmRecordProfileModal = ({ oldData, close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [optionsCategories, setOptionsCategories] = useState()
  const [optionSelectedCategories, setOptionSelectedCategories] = useState(null)

  const [optionsStatuses, setOptionsStatuses] = useState()
  const [optionSelectedStatuses, setOptionSelectedStatuses] = useState(null)
  const [collectionBase, setCollectionBase] = useState({
    value: oldData.bmsDelta,
    name: '',
    error: ''
  })
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [nonEmployeeFee, setNonEmployeeFee] = useState({
    value: oldData.nonEmployeeFee || 0,
    name: '',
    error: ''
  })
  const [startDate, setStartDate] = useState(new Date(oldData.startDate))
  const [endDate, setEndDate] = useState(oldData.endDate ? new Date(oldData.endDate) : null)
  const [bonusCoefficient, setBonusCoefficient] = useState({
    value: oldData.bmsBonusCoefficient || 0,
    name: '',
    error: ''
  })
  const [endInProcessDate, setEndInProcessDate] = useState(
    oldData.endInProcessDate ? new Date(oldData.endInProcessDate) : new Date()
  )

  const modalDataResponse = useSelector(SELECTORS.MODAL_DATA_RESPONSE)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleInputChange = val => {
    const { name, error } = val
    if (error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }

    switch (name) {
      case 'collectionBase':
        setCollectionBase(val)
        break
      case 'nonEmployeeFee':
        setNonEmployeeFee(val)
        break
      default:
        setBonusCoefficient(val)
        break
    }
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'category') setOptionSelectedCategories(value)
    else setOptionSelectedStatuses(value)
  }

  const clearStates = () => {
    setCollectionBase({
      value: '',
      name: '',
      error: ''
    })
    setNonEmployeeFee({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelectedCategories(null)
    setOptionSelectedStatuses(null)
  }

  const handleClick = () => {
    const newDataProfile = {
      startDate,
      endDate: endDate ? endDate.toISOString() : null,
      bmsDelta: collectionBase.value,
      statusId: optionSelectedStatuses.id,
      categoryId: optionSelectedCategories.id,
      nonEmployeeFee: Number(nonEmployeeFee?.value) === 0 ? null : Number(nonEmployeeFee?.value),
      bmsBonusCoefficient: +bonusCoefficient?.value,
      endInProcessDate
    }

    saveData(newDataProfile)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (flag) {
      if ((collectionBase.value && !collectionBase.error)
        && optionSelectedCategories
        && (optionSelectedStatuses.id !== 3 || (optionSelectedStatuses.id === 3 && endDate))
        && !nonEmployeeFee.error
        && (endDate
          ? (endDate?.setHours(0, 0, 0, 0) >= startDate?.setHours(0, 0, 0, 0))
          : startDate)) setShowButton(false)
      else setShowButton(true)
    }
  }

  useEffect(() => {
    dispatch(FRM_RECORD_ACTIONS.GET_MODAL_DATA(currentToken.token))
  }, [])

  useEffect(() => {
    if (modalDataResponse) {
      if (modalDataResponse.status === 200) {
        const { statuses, categories } = modalDataResponse.modalDataObj
        setOptionsStatuses(changeNameForLabel(statuses))
        setOptionSelectedStatuses(changeNameForLabel(statuses).find(item => item.id === oldData.statusId))
        setOptionsCategories(changeNameForLabel(categories))
        setOptionSelectedCategories(changeNameForLabel(categories).find(item => item.id === oldData.categoryId))
        setFlag(true)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalDataResponse.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setTimeout(() => close(), 5000)
      }
    }
  }, [modalDataResponse])

  useEffect(() => {
    errorControl()
  }, [collectionBase, optionSelectedCategories, optionSelectedStatuses, startDate, endDate, nonEmployeeFee])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
        && (
          <>
            <ModalDataContainerFrmEditProfile flexDirection='column'>
              <CustomDatePicker
                name='startDate'
                title='Fecha de ingreso'
                color='primary'
                date={startDate}
                onChange={setStartDate}
                yearsToShow={100}
                justifyContentButton='space-between'
                block
              />
              <CustomDatePicker
                name='endDate'
                title='Fecha de egreso'
                color='primary'
                date={endDate}
                onChange={setEndDate}
                yearsToShow={100}
                justifyContentButton='space-between'
                block
                empty={!endDate}
                isClearable
              />
              <Dropdown
                name='category'
                label='Categoría'
                placeholder='seleccione una opción'
                options={optionsCategories}
                value={optionSelectedCategories}
                onChange={handleDropdownChange}
                color='primary'
                height='small'
                block
              />
              <Dropdown
                name='status'
                label='Estado'
                placeholder='seleccione una opción'
                options={optionsStatuses}
                value={optionSelectedStatuses}
                onChange={handleDropdownChange}
                color='primary'
                height='small'
                block
              />
              <Input
                type={INPUT_TYPES.NUMBER}
                showType={INPUT_TYPES.TEXT}
                name='collectionBase'
                label='Base de cobro'
                placeholder='Ingrese base de cobro'
                color='primary'
                value={collectionBase.value}
                onChange={handleInputChange}
                onBlur={() => { }}
                height='small'
                error={collectionBase.error}
                required
                bits={[{ icon: 'currency_exchange', position: 'left', colorBit: 'tertiary' }]}
              />
              <Input
                type={INPUT_TYPES.NUMBER}
                showType={INPUT_TYPES.TEXT}
                name='bmsBonusCoeficient'
                label={BMS_NAMES.BMS_BONUS_COEFFICIENT}
                placeholder='Ingrese coeficiente'
                color='primary'
                value={bonusCoefficient.value}
                onChange={handleInputChange}
                onBlur={() => { }}
                height='small'
                error={bonusCoefficient.error}
                bits={[{ icon: 'addchart', position: 'left', colorBit: 'tertiary' }]}
              />
              <Input
                type={INPUT_TYPES.NUMBER}
                showType={INPUT_TYPES.TEXT}
                name='nonEmployeeFee'
                label='Aporte de no empleado'
                placeholder='Ingrese aporte en SBM'
                color='primary'
                value={nonEmployeeFee.value?.toFixed(2)}
                onChange={handleInputChange}
                onBlur={() => { }}
                height='small'
                error={nonEmployeeFee.error}
                bits={[{ icon: 'currency_exchange', position: 'left', colorBit: 'tertiary' }]}
              />
              {(optionSelectedStatuses.id === 7 || optionSelectedStatuses.id === 4)
              && (
              <CustomDatePicker
                name='endInProcessDate'
                title={`Fecha de 
                ${optionSelectedStatuses.id === 7 ? 'desafiliación' : 'retiro'} en proceso`}
                color='primary'
                date={endInProcessDate}
                onChange={setEndInProcessDate}
                yearsToShow={100}
                justifyContentButton='space-between'
                block
              />
              )}
              {(endDate && (startDate?.setHours(0, 0, 0, 0) > endDate?.setHours(0, 0, 0, 0)))
                && (
                  <Text size='medium' weight='regular' align='left' color='error'>
                    La fecha de ingreso no puede ser posterior a la fecha de engreso.
                  </Text>
                )}
            </ModalDataContainerFrmEditProfile>
            <ButtonsContainer justifyContent='flex-end'>
              <SolidButton
                size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
                color='primary'
                text='Guardar'
                onClick={handleClick}
                disabled={showButton}
                block={device === BREAKPOINTS.MOBILE}
              />
            </ButtonsContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.leftTop}
              onClick={() => setIsShowing(false)}
            />
          </>
        )}
    </>
  )
}

export default FrmRecordProfileModal

FrmRecordProfileModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  oldData: PropTypes.object,
  currentToken: PropTypes.object
}
