export const columnData = [
  { name: 'Código', widthCol: '5%' },
  { name: 'Liquidación', widthCol: '15%' },
  { name: 'Monto', widthCol: '10%' },
  { name: 'Concepto', widthCol: '15%' },
  { name: 'Estado', widthCol: '10%' },
  { name: 'Fecha de pago', widthCol: '15%' },
  { name: 'F. de vencimiento', widthCol: '20%' },
  { name: 'Monto cobrado', widthCol: '15%' },
  { name: 'Descripción', widthCol: '15%' },
  { name: 'Registro de ingreso', widthCol: '15%' }
]

export const COLUMN_CONTRIBUTION_PAYMENTS = [
  { name: 'Código', widthCol: '15%', label: 'Code' },
  { name: 'Concepto', widthCol: '20%', label: 'Concept' },
  { name: 'Origen', widthCol: '20%', label: 'OriginName' },
  { name: 'Divisa', widthCol: '15%', label: 'Currency' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'Contribucion', widthCol: '20%', label: 'Contribution' },
  { name: 'Estado', widthCol: '15%', label: 'Status' },
  { name: 'Mes', widthCol: '15%', label: 'Month' },
  { name: 'Año', widthCol: '15%', label: 'Year' }
]

export const FILTER_TYPE = {
  CONTRIBUTIONS: 'contributions',
  CONTRIBUTION_PAYMENTS: 'contributionPayments'
}

export const AFFILIATE_RECORD_MODAL_TYPES = {
  BENEFITS: 'benefits',
  EDIT_PROFILE: 'editProfile'
}
