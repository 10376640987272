export const key = 'SERVICE_PROVIDER'

export const SERVICE_PROVIDER = {
  GET: 'SERVICE_PROVIDER/GET_LIST',
  SUCCESS: 'SERVICE_PROVIDER/GET_LIST/SUCCESS',
  ERROR: 'SERVICE_PROVIDER/GET_LIST/ERROR',
  CLEAR: 'SERVICE_PROVIDER/CLEAR_LIST',

  ADD_PROVIDER: 'SERVICE_PROVIDER/PROVIDER/ADD',
  ADD_PROVIDER_INFORMATION: 'SERVICE_PROVIDER/PROVIDER/ADD_INFORMATION',

  CLEAR_MESSAGE_INFORMATION: 'SERVICE_PROVIDER/PROVIDER/CLEAR_MESSAGE_INFORMATION'
}

export default key
