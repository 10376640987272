import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_POSITION, MONTHS, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { ModalDataContainer, ButtonContainer, TextContainerModal } from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput, CheckboxInput } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import Toast from '@components/toast'
import CustomDatePicker from '@components/datePicker'
import { Text } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { LIQUIDATIONS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddLiquidationModal = ({ close, successfulResult, currentToken, activeFilters }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const fullYear = new Date().getFullYear()
  const [optionSelectedMonth, setOptionSelectedMonth] = useState()
  const [year, setYear] = useState({
    value: `${fullYear}`,
    name: '',
    error: ''
  })
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [dueDate, setDueDate] = useState(new Date())
  const [errorMessage, setErrorMessage] = useState(false)
  const [description, setDescription] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: false
  })

  const messageInformation = useSelector(SELECTORS.ADD_LIQUIDATION_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const dateControl = (selectedMonth, selectedYear) => {
    if (!selectedMonth || !selectedYear?.value || selectedYear?.error) return false

    if (dueDate.getFullYear() > Number(year.value)) return true

    if (dueDate.getFullYear() === Number(year.value)) {
      if (dueDate.getMonth() >= selectedMonth.id) return true
    }

    return false
  }

  const errorControl = () => {
    const datesOk = dateControl(optionSelectedMonth, year)
    setErrorMessage(!datesOk)
    if (optionSelectedMonth
      && (year.value && !year.error)
      && datesOk) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setYear({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelectedMonth(null)
    setDueDate(null)
    setDescription({
      value: '',
      name: '',
      error: ''
    })
  }

  const handleDropdownChange = val => {
    setOptionSelectedMonth(val.value)
  }

  const handleInputChange = val => {
    const { error, name } = val
    if (error && name === 'year') {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    if (name === 'year') setYear(val)
    else setDescription(val)
  }

  const handleClick = () => {
    const newLiquidation = {
      month: optionSelectedMonth.id + 1,
      year: year.value,
      dueDate,
      isExtraPayment: checked?.value,
      description: description?.value
    }
    const data = { newLiquidation, activeFilters }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(LIQUIDATIONS_ACTIONS.ADD_LIQUIDATION({ data, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCheckboxChange = val => setChecked(val)

  useEffect(() => {
    errorControl()
  }, [year, optionSelectedMonth, dueDate])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: MESSAGES.SETTLEMENT_ALREADY_EXISTS,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      } else {
        cleanFields()
        close()
        successfulResult()
      }
      dispatch(LIQUIDATIONS_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <Input
          type={INPUT_TYPES.NUMBER}
          showType={INPUT_TYPES.TEXT}
          name='year'
          label='Año'
          placeholder='Ingrese año'
          color='primary'
          value={year.value}
          onChange={handleInputChange}
          onBlur={() => { }}
          height='small'
          error={year.error}
          bits={[{ icon: 'edit_calendar', position: 'left', colorBit: 'tertiary' }]}
          select={!!fullYear}
        />
        <Dropdown
          name='month'
          label='Mes'
          placeholder='Seleccionar'
          options={MONTHS}
          value={optionSelectedMonth}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        <CustomDatePicker
          name='dueDate'
          title='Fecha de vencimiento'
          color='primary'
          onChange={setDueDate}
          yearsToShow={100}
          justifyContentButton='space-between'
          block
          margin='8px 0 16px 0'
        />
        <CheckboxInput
          label='Es aguinaldo'
          name='checkbox'
          color='primary'
          value={checked.value}
          onChange={handleCheckboxChange}
        />
        <TextInput
          name='description'
          label='Descripción'
          placeholder='Ingrese descripción'
          value={description.value}
          height='small'
          color='primary'
          onChange={handleInputChange}
          bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
        />
        {errorMessage
          && (
            <TextContainerModal>
              <Text size='small' weight='bold' color='error'>{MESSAGES.ERROR_DUE_DATE_MINOR}</Text>
            </TextContainerModal>
          )}
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddLiquidationModal

AddLiquidationModal.propTypes = {
  close: PropTypes.func,
  successfulResult: PropTypes.func,
  currentToken: PropTypes.string,
  activeFilters: PropTypes.object
}
