import styled from 'styled-components'
import { colors, spacing } from '@global/theme'
import { SIZE, WEIGHT, roboto } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const StyledUploader = styled.div`
${roboto};
position: relative;
display: inline-block;
padding: ${spacing.two};
box-sizing:border-box; 

height:auto;
width:100%;

color: ${colors.system.regular};
text-align: center;

display:flex;
justify-content: center;
align-items: center;
flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};

background-color: ${colors.background.regular};
border-radius: 5px;

overflow: 'hidden';

> span {
    position: absolute;

    width:80%;
    height:auto;
    
    padding: 0;
    margin: 0;

    
    font-size:${SIZE.MEDIUM};
    font-weight:${WEIGHT.SEMIBOLD};
    opacity: ${({ opacity }) => (opacity ? 1 : 0)};
}
`
export const StyledInputFile = styled.input`
position: absolute;
opacity: 0;

height:100%;
width:100%;

top: 0;
left: 0;
right: 0;
bottom: 0;

cursor: pointer;

display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
`

export const StyledThumb = styled.div`

width:100%;
height:100%;
display:flex;
justify-content: center;
align-items: center;

/* border-radius: 5px;
overflow: hidden;
background-color: #cccccc;
background-size: cover;
position: relative;
opacity:${({ opacity }) => (!opacity ? 1 : 0)};

&::after {
    content: attr(name);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    color: ${colors.background.regular};
    background: rgba(0,0,0,0.5);
    font-size:${SIZE.MEDIUM};
    text-align: center;
} */
`
export const StyledImg = styled.img`
width: 32px;
height: 32px; 
`
export const TextContainer = styled.div`
 width: 100%;
 margin-bottom: ${spacing.two};
 display:flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

 & > span {
     margin-bottom: ${spacing.two};
 }
`
export const DataContainer = styled.div`
 width: 100%;
 display:flex;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
`
export const FileContainer = styled.div`
 width: 100%;
 display: flex;
 justify-content: flex-start;
 flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
 align-items: center;
 flex: 2;

 & > img, p {
    margin-left: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)}!important;
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)}!important;
 }
`
export const LoadingContainer = styled.div`
 width: 100%;
 display:flex;
 justify-content: center;
 align-items: center;
 flex: 1;
`
