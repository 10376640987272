import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ModalDataContainer, ButtonModalContainer } from '@global/styles'
import { changeNameForLabel } from '@utils/helpers'
import { MONTHS, BREAKPOINTS } from '@global/constants'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { useWindowDimensions } from '@components/windowDimensions'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'

const EditPaymentSettlementProfile = ({ statuses, oldData, saveData, close }) => {
  const { device } = useWindowDimensions()

  const [options] = useState(changeNameForLabel(statuses))
  const [optionSelected, setOptionSelected] = useState(null)

  const [optionSelectedMonth, setOptionSelectedMonth] = useState()
  const [year, setYear] = useState({
    value: oldData.year,
    name: '',
    error: ''
  })
  const [description, setDescription] = useState({
    value: oldData.description,
    name: '',
    error: ''
  })
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)

  const cleanFields = () => {
    setYear({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelected(null)
    setOptionSelectedMonth(null)
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'status') setOptionSelected(value)
    else setOptionSelectedMonth(value)
  }

  const handleInputChange = val => {
    const { name } = val
    if (name === 'year') setYear(val)
    else setDescription(val)
  }

  const handleClick = () => {
    const paymentSettlementProfile = {
      statusId: optionSelected?.id,
      month: optionSelectedMonth?.id + 1,
      year: year?.value,
      description: description?.value
    }
    saveData(paymentSettlementProfile)
    cleanFields()
    setFlag(false)
    close(false)
  }

  const errorControl = () => {
    if ((year.value && !year.error)) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(() => {
    setOptionSelected(options.find(s => s.id === oldData.statusId))
    setOptionSelectedMonth(MONTHS.find(m => m.id === oldData.month - 1))
    return () => {
      setOptionSelected(null)
    }
  }, [])

  useEffect(() => {
    setFlag(true)
  }, [optionSelected])

  useEffect(() => {
    errorControl()
  }, [year])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Dropdown
            name='month'
            label='Mes'
            placeholder='Seleccionar'
            options={MONTHS}
            value={optionSelectedMonth}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            name='year'
            label='Año'
            placeholder='Ingrese año'
            color='primary'
            value={year.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={year.error}
            bits={[{ icon: 'edit_calendar', position: 'left', colorBit: 'tertiary' }]}
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Seleccionar'
            options={options}
            value={optionSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            color='primary'
            value={description.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={year.error}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
        </ModalDataContainer>
        <ButtonModalContainer justifyContent='flex-end' oneButton>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonModalContainer>

      </>
      )}
    </>
  )
}

export default EditPaymentSettlementProfile

EditPaymentSettlementProfile.propTypes = {
  statuses: PropTypes.array,
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func
}
