export const key = 'FRM_RECORD'

export const FRM_RECORD = {
  GET_FRM_RECORD: 'FRM_RECORD/GET_FRM_RECORD',
  GET_FRM_RECORD_SUCCESS: 'FRM_RECORD/GET_FRM_RECORD_SUCCESS',
  GET_FRM_RECORD_ERROR: 'FRM_RECORD/GET_FRM_RECORD_ERROR',
  CLEAR: 'FRM_RECORD/CLEAR',

  GET_CONTRIBUTIONS: 'FRM_RECORD/GET_CONTRIBUTIONS',
  GET_CONTRIBUTIONS_SUCCESS: 'FRM_RECORD/GET_CONTRIBUTIONS_SUCCESS',
  GET_CONTRIBUTIONS_ERROR: 'FRM_RECORD/GET_CONTRIBUTIONS_ERROR',

  GET_PAYMENTS_MADE: 'FRM_RECORD/GET_PAYMENTS_MADE',
  GET_PAYMENTS_MADE_SUCCESS: 'FRM_RECORD/GET_PAYMENTS_MADE_SUCCESS',
  GET_PAYMENTS_MADE_ERROR: 'FRM_RECORD/GET_PAYMENTS_MADE_ERROR',

  SEND_FILTER: 'FRM_RECORD/FILTER/SEND',
  GET_FILTER_DATA: 'FRM_RECORD/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'FRM_RECORD/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'FRM_RECORD/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'FRM_RECORD/FILTER/CLEAR',

  GET_MODAL_DATA: 'FRM_RECORD/MODAL/GET_DATA',
  MODAL_DATA_RESPONSE: 'FRM_RECORD/MODAL/DATA_RESPONSE',

  EDIT_PROFILE: 'FRM_RECORD/EDIT_PROFILE',
  UPDATE_PROFILE: 'FRM_RECORD/UPDATE_PROFILE',
  EDIT_PROFILE_RESPONSE: 'FRM_RECORD/EDIT_PROFILE_RESPONSE',

  FRM_RECORD_UPLOAD_FILE: 'FRM_RECORD/UPLOAD_FILE',
  GET_FRM_RECORD_FILES: 'FRM_RECORD/GET_FRM_RECORD_FILES',
  GET_FRM_RECORD_FILE_SUCCESS: 'FRM_RECORD/GET_FRM_RECORD_FILE_SUCCESS',
  FRM_RECORD_FILE_MESSAGE: 'FRM_RECORD/FRM_RECORD_FILE_MESSAGE',
  DOWNLOAD_FRM_RECORD_FILE: 'FRM_RECORD/DOWNLOAD_FRM_RECORD_FILE',
  REMOVE_FRM_RECORD_FILE: 'FRM_RECORD/REMOVE_FRM_RECORD_FILE',
  MESSAGE_INFORMATION: 'FRM_RECORD/MESSAGE_INFORMATION',
  CLEAR_INFORMATION_MESSAGE: 'FRM_RECORD/CLEAR_INFORMATION_MESSAGE',

  GET_PERSON_DATA: 'FRM_RECORD/GET_PERSON_DATA',
  GET_PERSON_DATA_SUCCESS: 'FRM_RECORD/GET_PERSON_DATA_SUCCESS',
  DATA_UPLOAD_READY: 'FRM_RECORD/DATA_UPLOAD_READY',

  GET_CONTRIBUTION_PAYMENTS: 'FRM_RECORD/GET_CONTRIBUTION_PAYMENTS',
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: 'FRM_RECORD/GET_CONTRIBUTION_PAYMENTS_SUCCESS',
  GET_CONTRIBUTION_PAYMENTS_ERROR: 'FRM_RECORD/GET_CONTRIBUTION_PAYMENTS_ERROR'
}

export default key
