import logoExcel from '../assets/excel-logo.png'
import logoWord from '../assets/word-logo.png'
import logo404 from '../assets/logo-404.png'
import logoTxt from '../assets/txt-file-logo.png'
import logoPdf from '../assets/pdf-logo.png'

export const FILE_NOT_ACCEPTED = 'fileNoAccepted'

export const FILE_TYPES = {
  EXCEL: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  WORD: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PDF: 'application/pdf',
  TXT: 'text/plain'
}

export const fileTypeLogos = [
  { type: FILE_TYPES.EXCEL, logo: logoExcel },
  { type: FILE_TYPES.WORD, logo: logoWord },
  { type: FILE_TYPES.PDF, logo: logoPdf },
  { type: FILE_TYPES.TXT, logo: logoTxt },
  { type: FILE_NOT_ACCEPTED, logo: logo404 }
]

export const BREAKPOINTS = {
  MOBILE: 578,
  TABLET_PORTRAIT: 768,
  TABLET: 1024,
  DESKTOP: 1366,
  BIG_SCREEN: 1680
}

export const DEVICE_NAME = {
  BIG_SCREEN: 'bigScreen',
  DESKTOP: 'desktop',
  TABLET_PORTRAIT: 'tabletPortrait',
  TABLET: 'tablet',
  MOBILE: 'mobile'
}

export const LANGUAGES = {
  en: 'en',
  es: 'es',
  pt: 'pt'
}

export const TOAST_PROPERTIES = {
  INFO: {
    title: 'Información',
    color: 'info',
    icon: 'info'
  },
  WARNING: {
    title: 'Advertencia',
    color: 'warning',
    icon: 'error'
  },
  ERROR: {
    title: 'Error',
    color: 'error',
    icon: 'cancel'
  },
  SUCCESS: {
    title: 'Éxito',
    color: 'success',
    icon: 'check_circle'
  }
}

export const TOAST_POSITION = {
  rightTop: 'right-top',
  rightBottom: 'right-bottom',
  leftTop: 'left-top',
  leftBottom: 'left-bottom'
}

export const TYPE_CHARTS = {
  DOUGHNUT: 'doughnut',
  LINE: 'line',
  HORIZONTAL_BAR_CHART: 'horizontalBarChart'
}

export const COLUMN_ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'des'
}

export const FILTER_TYPES = {
  CHECKBOX: 'checkbox',
  DATE_PICKER: 'datePicker',
  TEXT_INPUT: 'textInput',
  NUMBER_INPUT: 'numberInput'
}

export const TRANSLATION_OF_ROUTES = {
  PEOPLE: 'Personas',
  PERSON: 'Persona',
  SETTING: 'Administración',
  IMPORTS: 'Importaciones',
  USERS: 'Usuarios',
  AFFILIATE_RECORD: 'Registro de afiliación',
  FRM_RECORD: 'Registro de F.R.M',
  ACTIVITY_HISTORY: 'Historial de actividad',
  HISTORY_LOG: 'Registro de historia',
  EMPLOYEE_HISTORY: 'Historial de empleo',
  AFFILIATE_HISTORY: 'Historial de afiliación',
  EMPLOYMENT_HISTORY: 'Historial de empleo',
  FRM_HISTORY: 'Historial de F.R.M.',
  BENEFITS: 'Beneficios',
  ERROR_LOG: 'Log de errores',
  EXPORTS: 'Exportaciones',
  ACTIVITY_LOG: 'Log de actividad',
  FINANCE: 'Finanzas',
  LIQUIDATIONS: 'Liquidaciones de Ingresos',
  LIQUIDATION_LOG: 'Liquidación de Ingreso',
  FRM_CATEGORIES: 'Categorías de FRM',
  ENTITY_MANAGMENT: 'Gestión de entidades',
  CONTRIBUTION_PROFILE: 'Contribución',
  INCOME: 'Ingresos',
  DEPOSIT_PROFILE: 'Ingreso',
  EMPLOYMENT_RECORD: 'Registro de empleo',
  CONTRIBUTION_PAYMENT: 'Detalle de ingreso',
  PAYMENTS: 'Pagos',
  PAYMENT_PROFILE: 'Pago',
  PAYMENT_DETAIL: 'Detalle de pago',
  PAYMENT_SETTLEMENTS: 'Liquidaciones de Pagos',
  PAYMENT_SETTLEMENT_PROFILE: 'Liquidación de Pagos',
  DEBT_PROFILE: 'Adeudo',
  SERVICES: 'Proveedores',
  SERVICE_PROVIDER_PROFILE: 'Perfil de proveedor',
  ATAE_BANK_ACCOUNTS: 'Cuentas bancarias de ATAE',
  AFFILIATE_CATEGORIES: 'Categorías de Afiliación',
  CURRENCIES: 'Divisas',
  CURRENCY_PROFILE: 'Perfil de divisa',
  CONTRIBUTION_PAYMENT_LIST: 'Listado de Detalles de Ingreso',
  PAYMENT_DETAIL_LIST: 'Listado de Detalles de Pago',
  NO_EMPLOYEE_FEE: 'Aporte de no empleado'
}

export const TYPE_PERSONAL_INFORMATION_MODAL = {
  ADDRESS: { name: 'address', label: 'Domicilio' },
  PHONE: { name: 'phones', label: 'Teléfono' },
  EMAIL: { name: 'emails', label: 'Email' },
  BANK_ACCOUNT: { name: 'bankAccounts', label: 'Cuenta' },
  CI: { name: 'ci', label: 'Cédula' },
  FILE: { name: 'file', label: 'Archivo' }
}

export const FUNCTION_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete'
}

export const rowsPerPage = [
  { id: 1, label: 'Filas por páginas: 15', value: 15 },
  { id: 2, label: 'Filas por páginas: 30', value: 30 },
  { id: 3, label: 'Filas por páginas: 45', value: 45 }
]

export const ENTITY_COLUMNS_NAMES = {
  FullName: { name: 'FullName', label: 'Nombre', order: 1 },
  BillingNumber: { name: 'BillingNumber', label: 'Numero de Cobro', order: 2 },
  Addresses: { name: 'Addresses', label: 'Direccion', order: 3 },
  Emails: { name: 'Emails', label: 'Email', order: 4 },
  Phones: { name: 'Phones', label: 'Telefono', order: 5 },
  Birthday: { name: 'Birthday', label: 'Fecha de nacimiento', order: 6 },
  CI: { name: 'CI', label: 'Documento', order: 7 },
  Age: { name: 'Age', label: 'Edad', order: 8 },
  User: { name: 'User', label: 'Usuario', order: 9 },
  Sex: { name: 'Sex', label: 'Género', order: 10 },
  MonthlyAge: { name: 'MonthlyAge', label: 'Edad mensual', order: 11 },
  Description: { name: 'Description', label: 'Descripcion', order: 12 },
  Country: { name: 'Country', label: 'País', order: 13 },
  Name: { name: 'Name', label: 'Nombre', order: 14 },
  Cost: { name: 'Cost', label: 'Costo', order: 15 },
  StreetAddress1: { name: 'StreetAddress1', label: 'Calle principal', order: 16 },
  StreetAddress2: { name: 'StreetAddress2', label: 'Calle secundaria', order: 17 },
  Department: { name: 'Department', label: 'Departamento', order: 18 },
  Person: { name: 'Person', label: 'Persona', order: 19 },
  PhoneNumber: { name: 'PhoneNumber', label: 'Teléfono', order: 20 },
  EmailAddress: { name: 'EmailAddress', label: 'Email', order: 21 },
  Amount: { name: 'Amount', label: 'Monto', order: 22 },
  AmountPaid: { name: 'AmountPaid', label: 'Monto consolidado', order: 23 },
  NotificationDate: { name: 'NotificationDate', label: 'Fecha de Notificación', order: 24 },
  PaymentMadeDate: { name: 'PaymentMadeDate', label: 'Fecha de Pago', order: 25 },
  PaymentConfirmedDate: { name: 'PaymentConfirmedDate', label: 'Fecha de Cobro', order: 26 },
  Year: { name: 'Year', label: 'Año', order: 27 },
  Month: { name: 'Month', label: 'Mes', order: 28 },
  DueDate: { name: 'DueDate', label: 'Fecha de vencimiento', order: 29 },
  TotalAmount: { name: 'TotalAmount', label: 'Monto total', order: 30 },
  StartDate: { name: 'StartDate', label: 'Fecha de ingreso', order: 31 },
  EndDate: { name: 'EndDate', label: 'Fecha de egreso', order: 32 },
  Category: { name: 'Category', label: 'Categoría', order: 33 },
  Id: { name: 'Id', label: 'Id', order: 34 },
  Status: { name: 'Status', label: 'Estado', order: 35 },
  InitialPosition: { name: 'InitialPosition', label: 'Cargo', order: 36 },
  EmploymentRecord: { name: 'EmploymentRecord', label: 'Registro de empleo', order: 37 },
  DateCreated: { name: 'DateCreated', label: 'Fecha de creación', order: 38 },
  DateUpdated: { name: 'DateUpdated', label: 'Fecha de actualización', order: 39 },
  PhoneType: { name: 'PhoneType', label: 'Tipo de teléfono', order: 40 },
  File: { name: 'File', label: 'Nombre de archivo', order: 41 },
  City: { name: 'City', label: 'Ciuadad', order: 42 },
  PostalCode: { name: 'PostalCode', label: 'Código postal', order: 43 },
  EmailType: { name: 'EmailType', label: 'Tipo de email', order: 44 },
  Origin: { name: 'Origin', label: 'Origen', order: 45 },
  OutboundSettlement: { name: 'OutboundSettlement', label: 'Liquidación de pago', order: 46 },
  OutboundSettlementId: { name: 'OutboundSettlement', label: 'Liquidación de pago', order: 46 },
  ContributionPayments: { name: 'ContributionPayments', label: 'Detalle de ingreso', order: 47 },
  Total: { name: 'Total', label: 'Total', order: 48 },
  TotalPaid: { name: 'TotalPaid', label: 'Total Pagado ', order: 49 },
  Contribution: { name: 'Contribution', label: 'Contribución', order: 50 },
  ContributionId: { name: 'Contribution', label: 'Contribución', order: 50 },
  Settlement: { name: 'Settlement', label: 'Liquidación de ingreso', order: 51 },
  BMSOutbound: { name: 'BMSOutbound', label: 'Total pagos realizados', order: 52 },
  BMSBonusCoefficient: { name: 'BMSBonusCoefficient', label: 'Coeficiente', order: 53 },
  BMSDelta: { name: 'BMSDelta', label: 'Base de cobro', order: 54 },
  BMSInbound: { name: 'BMSInbound', label: 'SBM acumulados', order: 55 },
  BMSAvailable: { name: 'BMSAvailable', label: 'SBM disponibles', order: 56 },
  OutboundPayment: { name: 'OutboundPayment', label: 'Pago', order: 57 },
  BankAccount: { name: 'BankAccount', label: 'Cuenta de banco', order: 58 },
  PaymentDate: { name: 'PaymentDate', label: 'Fecha de pago', order: 59 },
  IsPrimary: { name: 'IsPrimary', label: 'Primaria', order: 60 },
  AccountNumber: { name: 'AccountNumber', label: 'Número de cuenta', order: 61 },
  Branch: { name: 'Branch', label: 'Sucursal', order: 61 },
  Currency: { name: 'Currency', label: 'Divisa', order: 62 },
  Service: { name: 'Service', label: 'Proveedor de servicio', order: 63 },
  Bank: { name: 'Bank', label: 'Banco', order: 64 },
  RequestMethod: { name: 'RequestMethod', label: 'Método de consulta', order: 65 },
  RequestPath: { name: 'RequestPath', label: 'Ruta de consulta', order: 66 },
  ErrorMessage: { name: 'ErrorMessage', label: 'Mensaje de error', order: 67 },
  Concept: { name: 'Concept', label: 'Concepto', order: 68 },
  Entity: { name: 'Entity', label: 'Entidad', order: 69 },
  Deleted: { name: 'Deleted', label: 'Eliminado', order: 70 },
  Value: { name: 'Value', label: 'Valor', order: 71 },
  Date: { name: 'Date', label: 'Fecha', order: 72 },
  CurrencyId: { name: 'CurrencyId', label: 'Divisa', order: 73 },
  Type: { name: 'Type', label: 'Tipo', order: 74 },
  ContentType: { name: 'ContentType', label: 'Tipo de contenido', order: 75 },
  PaymentDetails: { name: 'PaymentDetails', label: 'Detalles de pago', order: 76 },
  BMSInboundWithBonus: { name: 'BMSInboundWithBonus', label: 'SBM multiplicado', order: 77 },
  NonEmployeeFee: { name: 'NonEmployeeFee', label: 'Aporte de no empleado', order: 78 },
  AddressType: { name: 'AddressType', label: 'Tipo de dirección', order: 79 },
  PersonStatus: { name: 'PersonalStatus', label: 'Estado persona', order: 80 },

  Remarks: { label: 'Observaciones', order: 100 }
}

export const ACTIONS_TYPES = {
  Create: 'Creación',
  Delete: 'Eliminación',
  Update: 'Actualización'
}

export const ENTITIES_TYPES = {
  Person: { name: 'Person', label: 'Persona' },
  AffiliateRecord: { name: 'AffiliateRecord', label: 'Registro de afiliación' },
  FRMRecord: { name: 'FRMRecord', label: 'Registro de F.R.M.' },
  Note: { name: 'Note', label: 'Nota' },
  Address: { name: 'Address', label: 'Dirección' },
  Email: { name: 'Email', label: 'Email' },
  Phone: { name: 'Phone', label: 'Teléfono' },
  Benefit: { name: 'Benefit', label: 'Beneficio' },
  PersonFile: { name: 'PersonFile', label: 'Archivo de persona' },
  Settlement: { name: 'Settlement', label: 'Liquidación de ingreso' },
  EmploymentRecord: { name: 'EmploymentRecord', label: 'Registro de empleo' },
  InboundPayment: { name: 'InboundPayment', label: 'Ingreso' },
  OutboundSettlement: { name: 'OutboundSettlement', label: 'Liquidación de pago' },
  OutboundSettlementDetail: { name: 'OutboundSettlementDetail', label: 'Adeudo' },
  Contribution: { name: 'Contribution', label: 'Contribución' },
  BankAccount: { name: 'BankAccount', label: 'Cuenta bancaria' },
  Service: { name: 'Service', label: 'Proveedor de servicio' },
  ErrorLog: { name: 'ErrorLog', label: 'Log de error' },
  OutboundPayment: { name: 'OutboundPayment', label: 'Pagos' },
  FRMRecordFile: { name: 'FRMRecordFile', label: 'Archivo en FRM' },
  ContributionPayment: { name: 'ContributionPayment', label: 'Detalle de Ingreso' },
  AsyncJob: { name: 'AsyncJob', label: 'Trabajo asíncrono' },
  OutboundPaymentDetail: { name: 'OutboundPaymentDetail', label: 'Detalle de pago' },
  CurrencyValue: { name: 'CurrencyValue', label: 'Valor de divisa' },
  File: { name: 'File', label: 'Archivo' }
}

export const HISTORY_TYPES = {
  EMPLOYMENT: 'employment',
  AFFILIATE: 'affiliate',
  FRM: 'FRM'
}

export const MONTHS = [
  { id: 0, name: 'january', label: 'Enero' },
  { id: 1, name: 'february', label: 'Febrero' },
  { id: 2, name: 'march', label: 'Marzo' },
  { id: 3, name: 'april', label: 'Abril' },
  { id: 4, name: 'may', label: 'Mayo' },
  { id: 5, name: 'june', label: 'Junio' },
  { id: 6, name: 'july', label: 'Julio' },
  { id: 7, name: 'august', label: 'Agosto' },
  { id: 8, name: 'september', label: 'Septiembre' },
  { id: 9, name: 'october', label: 'Octubre' },
  { id: 10, name: 'november', label: 'Noviembre' },
  { id: 11, name: 'december', label: 'Diciembre' }
]

export const TRANSLATION_OF_ENTITIES = {
  AffiliateRecord: {
    name: 'AffiliateRecord',
    label: 'Afiliación'
  },
  FRMRecord: {
    name: 'FRMRecord',
    label: 'F.R.M'
  },
  Benefit: {
    name: 'Benefit',
    label: 'Afiliación'
  },
  EmploymentRecord: {
    name: 'EmploymentRecord',
    label: 'Registro de empleo'
  },
  Service: {
    name: 'Service',
    label: 'Proveedor'
  }
}

export const TIMEOUT_PENDING_PROCESS = 10000

export const SUBTITLES_TABLES = {
  CONTRIBUTIONS: 'Contribuciones',
  CONTRIBUTION_PAYMENTS: 'Detalles de Ingreso',
  PAYMENTS: 'Pagos realizados',
  FILES: 'Archivos',
  CONTRIBUTION_PAYMENTS_WITHOUT_CONTRIBUTION: 'Detalles de Ingreso sin contribuciones asociadas'
}

export const DETAIL_TYPES = {
  CONTRIBUTION_PAYMENT: 'contributionPayment',
  DEBT: 'debt'
}

export const BMS_NAMES = {
  BMS_INBOUND: 'SBMs aportados',
  BMS_INBOUND_WITH_BONUS: 'SBMs a cobrar',
  BMS_OUTBOUND: 'SBMs cobrados',
  BMS_BONUS_COEFFICIENT: 'Coeficiente Multiplicador',
  BMS_DELTA: 'Base de Cobro'
}

export const DISAFFILIATION_REFOUND = 'Devolución por desafiliación'

export const GREETINGS = {
  GOOD_MORNING: 'Buenos días',
  GOOD_AFTERNOON: 'Buenas tardes',
  GOOD_EVENING: 'Buenas noches'
}

export const MINIMUM_DATE = '01-01-1900'

export const COLUMN_ERROR = {
  columnName: '',
  error: false
}

export const NO_ORDER_COLUMN_BY = 'No se puede ordernar por la columna: '

export const EXTRA_PAYMENT = 'Aguinaldo'
