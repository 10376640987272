export const columnData = {
  Code: { name: 'Código', widthCol: '5%' },
  PersonName: { name: 'Persona', widthCol: '20%' },
  ServiceName: { name: 'Proveedor', widthCol: '20%' },
  Concept: { name: 'Concepto', widthCol: '20%' },
  Entity: { name: 'Entidad', widthCol: '15%' },
  Currency: { name: 'Moneda', widthCol: '5%' },
  Total: { name: 'Monto', widthCol: '10%' },
  PaymentRecord: { name: 'Registro de pago', widthCol: '20%' },
  Status: { name: 'Estado', widthCol: '10%' },
  DueDate: { name: 'F. de vencimiento', widthCol: '25%' },
  TotalPaid: { name: 'Total pagado', widthCol: '5%' }
}

export const mockEntities = {
  status: 200,
  data: [
    { id: 1, name: 'Persona' },
    { id: 2, name: 'Servicios' }
  ]
}
