import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectCurrencies = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectCurrencies, state => get(state, 'loading', null)
)

const selectError = createSelector(
  selectCurrencies, state => get(state, 'error', null)
)

const selectSuccess = createSelector(
  selectCurrencies, state => get(state, 'currencies', null)
)

export default {
  SUCCESS: selectSuccess,
  ERROR: selectError,
  LOADING: selectLoading
}
