import styled from 'styled-components'
import { colors, spacing } from '@global/theme'
import { roboto } from '@global/fonts'
import { PADDING_RIGHT_PAGE } from '@routes/constants'

export const TableStyled = styled.table`
  ${roboto};
  width: ${({ width }) => {
    if (!width) return '100%'
    const size = width - PADDING_RIGHT_PAGE.slice(0, PADDING_RIGHT_PAGE.length - 2)
    return `${size}px`
  }};
  max-width: 100%;
  height: auto;
  padding:  0 0 ${spacing.oneAndAHalf} 0;
  border-radius: ${spacing.one};
  background-color: ${colors.background.regular};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);

  display: block;
  overflow-x: auto; 
  border-spacing: 0;
  table-layout: fixed;
`

export const Head = styled.thead`
 width: fit-content;
 position: sticky;
 top: 0;
 height: ${spacing.nine};
`
export const Body = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`

  height: ${spacing.four};
  background-color: ${({ index }) => (index % 2 === 0 ? colors.background.secondary : colors.background.regular)};
  cursor: ${({ type, boolOnClick }) => (type === 'header' || !boolOnClick ? 'default' : 'pointer')};
  
  & > :last-child {
    padding-right: ${spacing.three};
  }

  &:hover {
    background-color: ${({ type }) => type !== 'header' && colors.secondary.darker};
  }

`

export const Th = styled.th`
  
  width: ${({ widthTh, tableWidth }) => {
    const porcentage = Number(widthTh.slice(0, widthTh.length - 1))
    return widthTh ? `${(porcentage * tableWidth) / 100}px` : 'inherit'
  }};
  text-align: left;
  padding: ${spacing.one} 0 ${spacing.one} ${spacing.three};
`

export const Td = styled.td`   
  text-align: left;
  padding: ${spacing.one} 0 ${spacing.one} ${spacing.three};
`

export const ColumnTitle = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${colors.primary.disabled};
  box-sizing: border-box;
  padding: 8px;
  border-radius: 5px;
`
export const LoadingContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const TextContainer = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TableChildrenContainer = styled.div`
  width: 100%;
  height: 60px;
  margin-top: ${spacing.four};
  padding: 0 ${spacing.three};
`
