import { ACTIVITY_LOG } from './types'

export const getActivityLog = payload => ({
  type: ACTIVITY_LOG.GET,
  payload
})

export const getActivityLogSuccess = payload => ({
  type: ACTIVITY_LOG.SUCCESS,
  payload
})

export const getActivityLogError = payload => ({
  type: ACTIVITY_LOG.ERROR,
  payload
})

export const ClearActivityLogList = payload => ({
  type: ACTIVITY_LOG.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: ACTIVITY_LOG.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: ACTIVITY_LOG.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: ACTIVITY_LOG.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: ACTIVITY_LOG.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: ACTIVITY_LOG.FILTER_DATA_CLEAR
})

export const ACTIVITY_LOG_ACTIONS = {
  GET_ACTIVITY_LOG: getActivityLog,
  SUCCESS: getActivityLogSuccess,
  ERROR: getActivityLogError,
  CLEAR: ClearActivityLogList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData
}
