import styled, { keyframes, css } from 'styled-components'
import { colors, spacing } from '@global/theme'
import { roboto } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const openModal = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
`

export const closeModal = keyframes`
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
`

export const open = css`
  animation: ${openModal} .5s;
`

export const close = css`
  animation: ${closeModal} .5s;
`
export const Overlay = styled.aside`
  position: fixed;
  top: 0;
  left: ${({ isShowing }) => (isShowing ? 0 : '100%')};
  width: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? '70%' : '100%')};
  height: 100%;
  z-index: ${({ zIndex }) => zIndex || 3};

  background-color: rgba(0, 0, 0, 0.25);

  overflow: hidden;
  ${({ isShowing }) => (isShowing ? open : close)};
`

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isShowing, screenWidth, device }) => {
    if (!isShowing) return '100%'
    if (device < BREAKPOINTS.TABLET_PORTRAIT) return '30%'
    return `${screenWidth - 474}px`
  }};
  z-index: 2;
  max-width: 474px;
  width: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? '70%' : '100%')};
  height: 100%;
  padding: 16px;
  overflow: auto;
  outline: 0;
  background-color: ${colors.background.primary};

  ${({ isShowing }) => (isShowing ? open : close)};
`

export const Container = styled.div`
  height: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 'auto' : '295px')};
  width: 100%;

  position: relative;

  top: 32px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 'column' : 'row')};

  border: 1px solid #727171;
  border-radius: 8px; 
  background-color: ${colors.background.regular};
  ${roboto};
`

export const TabContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: ${spacing.oneAndAHalf} 0 ${spacing.oneAndAHalf} 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  border-right:  ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? null : '1px solid #E5E5E5')};
  border-bottom:  ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? '1px solid #E5E5E5' : null)};
  overflow: auto;
`

export const Tab = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 16px;
  border-left: ${({ isActive }) => (isActive ? `5px solid ${colors.primary.regular}` : 'none')};
  cursor: pointer;

  z-index: 2;
  > p {
    z-index: 1;
    color: ${colors.background.dark};  
  }
  > :first-child {
      border-radius: 8px;
  }
`

export const Items = styled.div`
  height: ${spacing.twoAndAHalf};
  width: ${spacing.twoAndAHalf};

  color: ${colors.background.regular};
  background-color: ${colors.background.dark};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius:  ${spacing.half};
  padding: 0 ${spacing.one};
  font-size: 10px;
`

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 2;
  overflow: auto;
`

export const Content = styled.div`
  height: 100%;
  width: 100%;

  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 2;

  padding: 16px;
`

export const ContentList = styled.div`
  height: 100%;
  width: 100%;

  display: flex; 
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 3;

  padding: 16px;
  overflow: auto;
`
export const FilterContainer = styled.div`
  height: auto;
  width: 100%;

  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 3;

  > div {
      margin-bottom: 12px;
  }
`

export const ButtonContainer = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 'column' : 'row')};
  flex: 1;

  & > :last-child {
      margin-left: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 0 : spacing.oneAndAHalf)};
  }

  & > button {
      width: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? '100%' : null)};
      margin-top: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? spacing.two : 0)};
  }

  border-top:  1px solid #E5E5E5;
`
