import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer,
  BadgeContainer,
  TableWrapper,
  TextContainer,
  ItemLink
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  FUNCTION_TYPE,
  rowsPerPage,
  BREAKPOINTS
} from '@global/constants'
import { TOKEN_HELPERS, handleClickPaginationItem, filterOrderBuilder, currencyFormatUY } from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import Modal from '@components/modal'
import Icon from '@components/icon'
import { GhostButton } from '@components/button'
import Badge from '@components/badge'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditServiceProviderProfile from './components/editEditServiceProviderProfileeModal'
import CreateAndEditBankAccountsModal from './components/createAndEditBankAccountsModal'
import DeleteConfirmationModal from '../../components/modal/components/deleteConfirmationModal'

import useRedux from './redux'
import { SERVICE_PROVIDER_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, ServiceProfileData, Tbody, DataItem, ButtonContainer } from './styles'
import { columnData } from './data'

const ServiceProviderProfile = () => {
  useRedux()
  const { pathname } = useLocation()
  const { serviceProviderProfileId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [serviceProfile, setServiceProfile] = useState(null)
  const [open, setOpen] = useState(false)
  const [editServiceModal, setEditServiceModal] = useState(false)
  const [bankAccountsModal, setBankAccountsModal] = useState(false)
  const [banksAccounts, setBanksAccounts] = useState()
  const [modalTitle, setModalTitle] = useState(null)
  const [removeItemModal, setRemoveItemModal] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [itemSelected, setItemSelected] = useState()
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [activeFilters, setActiveFilters] = useState(null)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_SERVICE_PROVIDER_PROFILE)
  const serviceProfileData = useSelector(SELECTORS.SERVICE_PROVIDER_PROFILE_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const loadingBanksAccounts = useSelector(SELECTORS.LOADING_BANK_ACCOUNTS)
  const banksAccountsData = useSelector(SELECTORS.BANK_ACCOUNTS)

  const details = useSelector(SELECTORS.DETAILS_SUCCESS)
  const itemsError = useSelector(SELECTORS.DETAILS_ERROR)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const detailsLoading = useSelector(SELECTORS.DETAILS_LOADING)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const getOutboundSettlementDetails = body => {
    body.serviceId = serviceProviderProfileId
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_PROFILE_ACTIONS.GET_DETAILS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const renderServiceProfileData = objServiceProfileData => (
    <>
      <Tr>
        <Td colorLight widthPer>Código:</Td>
        <Td largeSize>{objServiceProfileData.id || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Nombre:</Td>
        <Td largeSize>{objServiceProfileData.name || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Descripción:</Td>
        <Td largeSize>{objServiceProfileData.description || 'N/A'}</Td>
      </Tr>
    </>
  )

  const getServiceProviderProfileData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_PROFILE_ACTIONS.GET_SERVICE_PROVIDER_PROFILE({ serviceProviderProfileId, token }))
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      getOutboundSettlementDetails(body)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSaveService = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_PROFILE_ACTIONS.EDIT_SERVICE_PROVIDER_PROFILE({
        serviceProviderProfileId, data: newProfile, token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setEditServiceModal(null)
    setBankAccountsModal(null)
    setRemoveItemModal(null)
    setModalTitle(null)
    setOpen(false)
  }

  const handleEditService = () => {
    setModalTitle('Editar perfil proveedor')
    setEditServiceModal({
      oldData: serviceProfile,
      saveData: handleSaveService,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  const handleRemoveBankAccount = bankAccountId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(SERVICE_PROVIDER_PROFILE_ACTIONS.DELETE_SERVICE_PROVIDER_BANK_ACCOUNT(
        { serviceProviderProfileId, bankAccountId, token }
      ))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleBankAccountsModal = (type, id) => {
    switch (type) {
      case FUNCTION_TYPE.EDIT:
        setModalTitle('Editar cuenta bancaria')
        setBankAccountsModal({
          serviceId: Number(serviceProviderProfileId),
          oldData: banksAccounts.find(ba => ba.id === id),
          close: handleCloseModal,
          currentToken
        })
        break
      case FUNCTION_TYPE.ADD:
        setModalTitle('Agregar nueva cuenta bancaria')
        setBankAccountsModal({
          serviceId: Number(serviceProviderProfileId),
          close: handleCloseModal,
          currentToken
        })
        break
      default:
        setModalTitle('')
        setRemoveItemModal({
          close: handleCloseModal,
          text: '¿Estás seguro que deseas borrar esta cuenta bancaria?',
          itemId: id,
          removeItem: handleRemoveBankAccount
        })
    }
    setOpen(true)
  }

  const renderBanksAccounts = arrBanksAccounts => (
    arrBanksAccounts.length > 0
      ? (arrBanksAccounts.map(bankAccount => (
        <DataItem
          flexDirection='column'
          padding={spacing.two}
          width='min-content'
          minWidth='200px'
          marginRight
          id={bankAccount.id}
          key={bankAccount.id}
        >
          {bankAccount.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primaria' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Banco: ${bankAccount.bankName || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Cuenta: ${bankAccount.accountNumber || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Sucursal: ${bankAccount.branch || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Descripción: ${bankAccount.description || 'N/A'}`}
          </Text>
          <ButtonContainer
            backgroundColor
            justifyContent='flex-end'
            alignItems='strech'
            marginTop={spacing.one}
            marginRight={spacing.one}
          >
            <Icon
              color='system'
              size='small'
              name='delete_outline'
              onClick={() => handleBankAccountsModal(FUNCTION_TYPE.DELETE, bankAccount.id)}
            />
            <MyButtonLink
              onClick={() => handleBankAccountsModal(FUNCTION_TYPE.EDIT, bankAccount.id)}
              color='primary'
            >
              Editar
            </MyButtonLink>
          </ButtonContainer>
        </DataItem>
      )))
      : (
        <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_BANK_ACCOUNT}</Text>
      )
  )

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = activeFilters
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getOutboundSettlementDetails(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getOutboundSettlementDetails(body)
  }

  const handlePaymentDetailClick = paymentDetailId => {
    if (paymentDetailId) {
      const win = window.open(`${SITE.PAYMENT_DETAIL}/${paymentDetailId}`, '_blank')
      win.focus()
    }
  }

  const handleSendToOutboundSettlementDetailClick = outboundSettlementDetailId => {
    if (outboundSettlementDetailId) {
      const win = window.open(`${SITE.DEBT_PROFILE}/${outboundSettlementDetailId}`, '_blank')
      win.focus()
    }
  }

  const handleSendToOutboundSettlementClick = outboundSettlementId => {
    if (outboundSettlementId) {
      const win = window.open(`${SITE.PAYMENT_SETTLEMENT_PROFILE}/${outboundSettlementId}`, '_blank')
      win.focus()
    }
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { payments } = item
      let paymentRecord = []
      if (payments?.length > 0) {
        payments = payments.filter(p => p.statusId === 1)
        for (let i = 0; i < payments?.length; i++) {
          const newConPay = (
            <ItemLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handlePaymentDetailClick(payments[i].id)}
            >
              {`DP - ${payments[i].id}${payments?.length - 1 !== i ? ',' : ''} `}
            </ItemLink>
          )
          paymentRecord = [
            ...paymentRecord,
            newConPay
          ]
        }
        if (paymentRecord.length === 0) paymentRecord = 'N/A'
      } else paymentRecord = 'N/A'

      const code = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleSendToOutboundSettlementDetailClick(item.id)}
        >
          {`A - ${item.id}`}
        </ItemLink>
      )

      const liquidation = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleSendToOutboundSettlementClick(item.outboundSettlementId)}
        >
          {`LP - ${item.outboundSettlementId}`}
        </ItemLink>
      )

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        datecreated: item.dateCreated,
        liquidation,
        currency: item.currency,
        total: currencyFormatUY(item.total),
        status: item.statusName,
        dueDate: item.dueDate,
        paymentRecord,
        totalPaid
      }
      return newItem
    })
    return newData
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleClickPaginationItem={
            n => handleClickPaginationItem(
              n, activeFilters, selectedOption.value, getOutboundSettlementDetails, setItemSelected
            )
          }
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    getServiceProviderProfileData()
  }, [])

  useEffect(() => {
    if (serviceProfileData) {
      setServiceProfile(serviceProfileData)
      setBanksAccounts(serviceProfileData.bankAccounts)
      const firstCrumb = {
        link: true,
        text: TRANSLATION_OF_ROUTES.SERVICES,
        url: SITE.SERVICES
      }
      const lastCrumb = {
        link: true,
        text: `${TRANSLATION_OF_ROUTES.SERVICE_PROVIDER_PROFILE} - ${serviceProviderProfileId}`,
        url: `${SITE.SERVICES}${SITE.SERVICE_PROVIDER_PROFILE}/${serviceProviderProfileId}`
      }
      setBreadcrumbs([firstCrumb, lastCrumb])
    }
  }, [serviceProfileData])

  useEffect(() => {
    if (details) {
      setTablelist(customDataTable(details))
      setColumns(columnData)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
    }
  }, [details, totalPages])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        getServiceProviderProfileData()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (banksAccountsData) {
      setBanksAccounts(banksAccountsData)
    }
  }, [banksAccountsData])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200 || !status) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      }
      dispatch(SERVICE_PROVIDER_PROFILE_ACTIONS.CLEAR_MESSAGE_INFORMATION())
      setIsShowing(true)
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' device={device}>
              {serviceProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`Proveedor - ${serviceProviderProfileId}`}
               </Heading>
               )}
              {breadcrumbs
              && (
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
              )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' device={device}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <ServiceProfileData device={device}>
                      <Tbody>
                        {serviceProfile && renderServiceProfileData(serviceProfile)}
                      </Tbody>
                    </ServiceProfileData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditService} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}

            </DataContainer>
            <ButtonContainer
              justifyContent='flex-end'
              marginTop={spacing.three}
              marginBottom={spacing.three}
              buttonColor
            >
              <GhostButton
                color='primary'
                size='small'
                icon='add'
                text='Agregar Cuenta bancaria'
                reverse
                onClick={() => handleBankAccountsModal(FUNCTION_TYPE.ADD)}
                block={device < BREAKPOINTS.TABLET_PORTRAIT}
              />
            </ButtonContainer>
            <DataContainer columnStart='1' columnEnd='12' rowStart='5' rowEnd='5'>
              {loadingBanksAccounts
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : banksAccounts && renderBanksAccounts(banksAccounts)}
            </DataContainer>
            <TextContainer columnStart='1' columnEnd='12' rowStart='6' rowEnd='6' margin={`${spacing.three} 0`}>
              <Text size='large' weight='bold' align='left'>Adeudos</Text>
            </TextContainer>
            <TableWrapper columnStart='1' columnEnd='12' rowStart='7' rowEnd='7' marginBottom='24px'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: detailsLoading,
                handleOrder: handleOrderChange,
                error: itemsError
              })}
            </TableWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {editServiceModal
                && <EditServiceProviderProfile {...editServiceModal} />}
              {bankAccountsModal
                && <CreateAndEditBankAccountsModal {...bankAccountsModal} />}
              {removeItemModal
              && (
                <DeleteConfirmationModal
                  {...removeItemModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default ServiceProviderProfile
