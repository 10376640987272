import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { INCOME_ACTIONS } from './actions'
import { INCOME } from './types'

const apiCalls = {
  getIncome: ({ data, token }) => (
    Axios.post('InboundPayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getStatuses: token => (
    Axios.get('InboundPayment/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getSource: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createDeposit: ({ data, token }) => (
    Axios.post('InboundPayment/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editDeposit: ({ paymentId, data, token }) => (
    Axios.post(`Income/${paymentId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getIncome ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getIncome, payload)

    yield put(INCOME_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(INCOME_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(INCOME_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(INCOME_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalDataIncome ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getStatuses, payload)
    const { data: source } = yield call(apiCalls.getSource, payload)
    const objData = { statuses, source }
    yield put(INCOME_ACTIONS.MODAL_DATA_INFORMATION({ objData, status: 200 }))
  } catch (error) {
    yield put(INCOME_ACTIONS.MODAL_DATA_INFORMATION(error))
  }
}

export function* addDeposit ({ payload }) {
  try {
    const { data, token } = payload
    const { newIncome, body } = data
    const res = yield call(apiCalls.createDeposit, { data: newIncome, token })
    const { status } = res
    const { data: incomeUpdated } = yield call(apiCalls.getIncome, { data: body, token })

    yield put(INCOME_ACTIONS.SUCCESS(incomeUpdated))
    yield put(INCOME_ACTIONS.ADD_DEPOSIT_INFORMATION({ status }))
  } catch (error) {
    yield put(INCOME_ACTIONS.ADD_DEPOSIT_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(INCOME.GET, getIncome)
  yield takeLatest(INCOME.GET_FILTER_DATA, FilterData)
  yield takeLatest(INCOME.GET_MODAL_DATA, getModalDataIncome)
  yield takeLatest(INCOME.ADD_DEPOSIT, addDeposit)
}
