import produce from 'immer'

import { AFFILIATE_CATEGORIES } from './types'

export const initialState = {
  loading: true,
  error: false,
  affiliateCategoriesLoading: false,
  affiliateCategoriesList: [],
  affiliateCategoryInformation: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case AFFILIATE_CATEGORIES.GET:
      draft.AffiliateCategoriesLoading = true
      break
    case AFFILIATE_CATEGORIES.SUCCESS:
      draft.loading = false
      draft.affiliateCategoriesList = payload
      break
    case AFFILIATE_CATEGORIES.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case AFFILIATE_CATEGORIES.CLEAR:
      return initialState
    // FILTER
    case AFFILIATE_CATEGORIES.SEND_FILTER:
      draft.loading = true
      break
    case AFFILIATE_CATEGORIES.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case AFFILIATE_CATEGORIES.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case AFFILIATE_CATEGORIES.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case AFFILIATE_CATEGORIES.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // SET AFFILIATE_CATEGORIES
    case AFFILIATE_CATEGORIES.SET_AFFILIATE_CATEGORY:
      draft.affiliateCategoriesLoading = true
      break
    case AFFILIATE_CATEGORIES.AFFILIATE_CATEGORY_INFORMATION:
      draft.affiliateCategoriesLoading = false
      draft.affiliateCategoryInformation = payload
      break
    case AFFILIATE_CATEGORIES.AFFILIATE_CATEGORY_INFORMATION_CLEAR:
      draft.affiliateCategoryInformation = null
      break
    default:
      return initialState
  }
})

export default reducer
