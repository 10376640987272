import styled from 'styled-components'
import { spacing, colors } from '@global/theme'
import { DEVICE_NAME } from '@global/constants'

export const PaginationWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
  || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? '1fr 1fr' : '1fr')};
  grid-template-columns: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
  || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? '1fr' : '1fr 1fr 1fr')};
  grid-gap: 20px;
  justify-items: center;
  align-items: center;

  > :first-child {
    grid-row-start: 1;
    grid-column-start: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
    || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? 1 : 2)};
  }
`
export const RowsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
  || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? 'center' : 'flex-end')};
  flex: 1;

  > div {
    margin: 0 ${spacing.oneAndAHalf};
    > button {
      min-width: auto;
      padding: 0 ${spacing.one};
      & > p, span {
      color: ${colors.primary.regular}!important;
      }
    }
    > ul {
      margin-top: 0;
      > li {
        justify-content: center;
      }
    }
  }

  grid-row-start: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? 2 : 1)};
  grid-column-start: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
  || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? 1 : 3)};
`
