import React, { useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'

import Icon from '@components/icon'

import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import logo404 from '../../assets/logo-404.png'

import {
  StyledUploader,
  StyledInputFile,
  DataContainer,
  StyledImg,
  TextContainer,
  FileContainer,
  LoadingContainer
} from './style'

import { converterMbInBytes } from './helpers'
import { ACCEPTED_FILE_TYPES } from './data'

const defaultLogo = logo404

export const customFileData = (arrExtensions, arrFileTypes) => {
  let strAccept = ''
  let arrExtAccepted = []
  if (!arrExtensions) return strAccept
  for (let i = 0; i < arrExtensions.length; i++) {
    const fileType = arrFileTypes.find(t => t.name === arrExtensions[i])
    arrExtAccepted = [
      ...arrExtAccepted,
      fileType
    ]
    if (i === arrExtensions.length - 1) {
      strAccept += fileType.type
    } else {
      strAccept += `${fileType.type},`
    }
  }
  return { strAccept, arrExtAccepted }
}

export const FileUploader = forwardRef(({ text, fileAccept, maxSizeInMB, sendFile, showDeleteIcon, disabled }, ref) => {
  const { device } = useWindowDimensions()

  const [fileName, setFileName] = useState('')
  const [logo, setLogo] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [fileData] = useState(customFileData(fileAccept, ACCEPTED_FILE_TYPES))

  const cancelFileaUpload = () => {
    setFileName('')
    sendFile(null)
  }
  useImperativeHandle(ref, () => ({ cancelFileaUpload }))

  const fileHandler = currentFile => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(currentFile)

    reader.onloadend = () => {
      setLoading(false)
      if (reader.readyState === 2) {
        const blob = new Blob([reader.result])
        const formData = new FormData()
        formData.append('formFile', blob, currentFile.name)
        if (reader.result) {
          sendFile(formData)
        }
      }
    }
  }

  const dataControl = currentFile => {
    if (currentFile.size > converterMbInBytes(maxSizeInMB)) {
      return { status: 'error', value: 'El tamaño del archivo excede lo permitido.' }
    }
    const wanted = fileData.arrExtAccepted.find(fa => fa.type === currentFile.type)

    if (wanted) return { status: 'success', value: currentFile }
    return { status: 'error', value: 'Tipo de archivo inválido' }
  }

  const showError = currentError => {
    setError(currentError)
    setLoading(false)
    setTimeout(() => {
      setError('')
    }, 1500)
  }

  const setData = currentFile => {
    setLogo(fileData.arrExtAccepted.find(item => item.type === currentFile.type)?.logo || defaultLogo)
    setFileName(currentFile.name)
    fileHandler(currentFile)
  }

  const handleChange = e => {
    setLoading(true)
    const { files } = e.target
    if ((files.length > 0) && !e.dataTransfer) {
      const { status, value } = dataControl(files[0])
      if (status === 'success') setData(files[0])
      else showError(value)
    }
  }

  const handleDrop = e => {
    setLoading(true)
    const { files } = e.dataTransfer
    if (files.length > 0) {
      const { status, value } = dataControl(files[0])
      if (status === 'success') setData(files[0])
      else showError(value)
    }
  }

  return (
    <StyledUploader
      onDrop={handleDrop}
      name='dropZone'
      device={device}
    >
      {(!loading && !fileName)
        && (
          <TextContainer>
            <Icon name='upload' backgroundColor='background' size='medium' color='grays' />
            <Text
              size='medium'
              weight='regular'
              align='center'
            >
              {text}
            </Text>
          </TextContainer>
        )}
      {error && <Text color='error' size='medium' weight='regular' align='left'>{error.toUpperCase()}</Text>}
      {(loading || fileName) && !error
          && (
          <>
            <DataContainer
              name={fileName}
            >
              <FileContainer device={device}>
                {loading && <Icon name='close' backgroundColor='background' size='medium' color='grays' />}
                <StyledImg src={logo} />
                <Text
                  size='medium'
                  weight='regular'
                  align={device === BREAKPOINTS.MOBILE ? 'center' : 'left'}
                >
                  {fileName}
                </Text>
              </FileContainer>
            </DataContainer>
            <LoadingContainer>
              {loading
              && (
              <Icon
                name='sync'
                backgroundColor='background'
                size='xlarge'
                color='primary'
                spin={loading}
              />
              )}
              {!loading && showDeleteIcon
              && (
              <Icon
                name='delete'
                backgroundColor='background'
                size='medium'
                color='grays'
                onClick={cancelFileaUpload}
              />
              )}
            </LoadingContainer>
          </>
          )}
      {(!loading && !fileName)
       && (
       <StyledInputFile
         id='fileControl'
         type='file'
         onChange={handleChange}
         accept={fileData?.strAccept}
         disabled={disabled}
       />
       )}
    </StyledUploader>

  )
})

export default FileUploader

FileUploader.propTypes = {
  text: PropTypes.string,
  fileAccept: PropTypes.array,
  maxSizeInMB: PropTypes.number,
  sendFile: PropTypes.func,
  showDeleteIcon: PropTypes.bool,
  disabled: PropTypes.bool
}
