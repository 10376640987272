import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, bold, medium, large } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
`

export const WrapperLogActivity = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 2;
  margin-top: ${spacing.three};
`

export const DataContainer = styled.div`
  width: 100%;
  background-color: ${colors.background.regular};
  padding: ${spacing.two};
  border-radius: ${spacing.one};
  margin-top: ${spacing.three};
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 3;
`

export const ImportData = styled.table`
  ${roboto};
  ${regular};
  width: 100%;
  height: 100%;
  flex: 2;
  margin-bottom: ${({ device }) => (device <= BREAKPOINTS.TABLET_PORTRAIT ? spacing.three : 0)};
`
export const FileContainer = styled.div`
  height: 100%;   
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex: 1;
  padding-left: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.one : 0)};
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '35%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`

export const Tr = styled.tr`
  width: available;
`

export const ButtonDownloadFile = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
  display: flex;
  align-items: center;
  & > :first-child {
    margin-right: ${spacing.one};
  }
`

export const StyledImg = styled.img`
width: ${spacing.three};
height: ${spacing.three}; 
`
export const LogContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const LogItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing.two};
`
