export const key = 'DETAIL_LIST'

export const DETAIL_LIST = {
  GET_DETAIL_LIST_PROFILE: 'DETAIL_LIST_PROFILE/GET_DATA',
  GET_DETAIL_LIST_PROFILE_SUCCESS: 'DETAIL_LIST_PROFILE/GET_SUCCESS',
  GET_DETAIL_LIST_PROFILE_ERROR: 'DETAIL_LIST_PROFILE/GET_ERROR',
  CLEAR: 'DETAIL_LIST_PROFILE/CLEAR',

  GET_DETAIL_LIST: 'DETAIL_LIST/GET_DATA',
  GET_DETAIL_LIST_SUCCESS: 'DETAIL_LIST/GET_SUCCESS',
  GET_DETAIL_LIST_ERROR: 'DETAIL_LIST/GET_ERROR',

  SEND_FILTER: 'DETAIL_LIST/FILTER/SEND',
  GET_FILTER_DATA: 'DETAIL_LIST/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'DETAIL_LIST/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'DETAIL_LIST/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'DETAIL_LIST/FILTER/CLEAR'
}

export default key
