import produce from 'immer'

import { LIQUIDATION_LOG } from './types'

export const initialState = {
  loading: null,
  error: false,
  liquidationLog: null,
  liquidationLogStatuses: null,
  loadingEditProfile: false,
  listLoading: null,
  contributionsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  loadingCalculateContributions: null,
  calculateInformation: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  contributionsError: null,
  modalDataLoading: false,
  modalData: null,
  messageInformation: null,
  currencies: null,
  currenciesLoading: null,
  isCalculatingInfo: null,
  isChangingStatusInfo: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case LIQUIDATION_LOG.GET_LIQUIDATION_LOG:
      draft.loading = true
      break
    case LIQUIDATION_LOG.SUCCESS: {
      const { liquidationLogObj, statuses } = payload
      draft.loading = false
      draft.liquidationLog = liquidationLogObj
      draft.liquidationLogStatuses = statuses
      break
    }
    case LIQUIDATION_LOG.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case LIQUIDATION_LOG.EDIT_LIQUIDATION_PROFILE:
      draft.loadingEditProfile = true
      break
    case LIQUIDATION_LOG.EDIT_LIQUIDATION_PROFILE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingEditProfile = false
      break
    case LIQUIDATION_LOG.LIQUIDATION_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.liquidationLog = payload
      break
    case LIQUIDATION_LOG.CLEAR:
      return initialState
    case LIQUIDATION_LOG.CALCULATE_CONTRIBUTIONS:
      draft.loadingCalculateContributions = true
      break
    case LIQUIDATION_LOG.CALCULATE_CONTRIBUTIONS_INFORMATION:
      draft.calculateInformation = payload
      draft.loadingCalculateContributions = false
      break
    case LIQUIDATION_LOG.GET_LIQUIDATION_LOG_CONTRIBUTIONS:
      draft.listLoading = true
      break
    case LIQUIDATION_LOG.CONTRIBUTIONS_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.listLoading = false
      draft.loadingCalculateContributions = false
      draft.contributionsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case LIQUIDATION_LOG.CONTRIBUTIONS_ERROR:
      draft.listLoading = false
      draft.contributionsError = payload
      break
    case LIQUIDATION_LOG.LIQUIDATION_CONTRIBUTIONS_UPDATE:
      draft.listLoading = true
      break
    case LIQUIDATION_LOG.CONTRIBUTIONS_CLEAR:
      return initialState
    case LIQUIDATION_LOG.SEND_FILTER:
      draft.loading = true
      break
    case LIQUIDATION_LOG.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case LIQUIDATION_LOG.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case LIQUIDATION_LOG.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case LIQUIDATION_LOG.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // MODAL
    case LIQUIDATION_LOG.GET_PERSON_CONCEPTS:
      draft.modalDataLoading = true
      break
    case LIQUIDATION_LOG.MODAL_DATA_INFORMATION:
      draft.modalDataLoading = false
      draft.modalData = payload
      break
    // CONTRIBUTION
    case LIQUIDATION_LOG.ADD_CONTRIBUTION:
      draft.listLoading = true
      break
    case LIQUIDATION_LOG.ADD_CONTRIBUTION_INFORMATION:
      draft.messageInformation = payload
      break
    case LIQUIDATION_LOG.GET_CURRENCIES:
      draft.currenciesLoading = true
      break
    case LIQUIDATION_LOG.CURRENCIES_INFORMATION:
      draft.currenciesLoading = false
      draft.currencies = payload
      break
    case LIQUIDATION_LOG.MESSAGE_INFORMATION_CLEAR:
      draft.messageInformation = null
      break
    case LIQUIDATION_LOG.PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    case LIQUIDATION_LOG.GET_PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    default:
      return initialState
  }
})

export default reducer
