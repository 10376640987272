import styled, { css } from 'styled-components'

import { colors, spacing } from '@global/theme'
import { column, row } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

const Vertical = css`
  ${column};
  width: 100%;
`

const Horizontal = css`
  ${row};
  width: 100%;
`

export const MyCard = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: ${colors.background.regular};

  
  ${({ type }) => (type === 'horizontal' ? Horizontal : Vertical)};
  height: ${({ type, height }) => (type === 'horizontal' ? height : 'fit-content')};
  
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
`

export const Container = styled.div`
  ${({ type, direction }) => (type === 'horizontal' && direction === 'row' ? row : column)};
  padding: ${({ type }) => (
    type === 'horizontal' ? `${spacing.oneAndAHalf}` : `${spacing.two} ${spacing.twoAndAHalf}`
  )};
  align-items: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'center' : 'flex-start')};
  width: 100%;
  height: 100%;
  flex: ${({ type }) => (type === 'horizontal' ? 2 : 1)};
`

export const Info = styled.div`
  ${column};
  position: relative;
  flex: ${({ flex }) => flex ?? 1};
  height: 100%;
`

export const MultiButtonContainer = styled.div`
  ${row};
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: auto;

  > :not(:last-child) {
    margin-right: ${spacing.three};
  }
`

export const SingleButtonContainer = styled.div`
  height: 100%;
  width: ${({ type }) => (type === 'horizontal' ? 'auto' : '100%')};

  display: flex;
  flex-direction: ${({ direction }) => (direction === 'column' ? 'row' : 'column')};
  justify-content: ${({ direction }) => (direction === 'column' ? 'flex-start' : 'center')};
  align-items: ${({ direction }) => (direction === 'column' ? 'flex-end' : 'center')};
  flex: ${({ flex }) => flex ?? 1};

  margin-bottom: ${({ direction }) => (direction === 'column' ? spacing.oneAndAQuarter : 0)};
  margin-left: ${({ direction }) => (direction === 'column' ? 0 : spacing.one)};
  margin-top: ${({ type }) => (type === 'horizontal' ? 0 : spacing.three)};
`

const VerticalImg = css`
  width: 50%;
  height: max-content;
  border-radius: 5px 5px 0 0;
`

const HorizontalImg = css`
  width: 100%;
  border-radius: 5px 0 0 5px;
`

export const Img = styled.img`
  object-fit: ${({ type }) => (type === 'horizontal' ? 'contain' : 'cover')};
  ${({ type }) => (type === 'horizontal' ? HorizontalImg : VerticalImg)};
  max-height: ${({ height }) => height};
`
export const ImgContainer = styled.div`
  width: ${({ type }) => (type !== 'horizontal' && '100%')};
  max-height: ${({ height }) => height};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  flex: ${({ type }) => (type === 'horizontal' ? 1.5 : 1)};
`

export const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  margin-left: -${spacing.two};
  padding-top: ${spacing.twoAndAQuarter};

  > :not(:first-child) {
    margin-top: ${spacing.oneAndAQuarter};
  }
`

export const TextContainer = styled.div`
  text-overflow: ellipsis;
  overflow: auto;
  height: 100%;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box !important;
  -webkit-line-clamp:  ${({ type }) => (type === 'horizontal' ? 5 : 10)};
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  white-space: normal;
`
