export const key = 'PAYMENTS'

export const PAYMENTS = {
  GET: 'PAYMENTS/GET_LIST',
  SUCCESS: 'PAYMENTS/GET_LIST/SUCCESS',
  ERROR: 'PAYMENTS/GET_LIST/ERROR',
  CLEAR: 'PAYMENTS/CLEAR_LIST',

  SEND_FILTER: 'PAYMENTS/FILTER/SEND',
  GET_FILTER_DATA: 'PAYMENTS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PAYMENTS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PAYMENTS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PAYMENTS/FILTER/CLEAR',

  ADD_PAYMENT: 'PAYMENTS/PAYMENT/ADD',
  ADD_PAYMENT_INFORMATION: 'PAYMENTS/PAYMENT/ADD_INFORMATION',
  GET_BANK_ACCOUNTS: 'PAYMENTS/MODAL/GET_BANK_ACCOUNTS',
  BANK_ACCOUNTS_RESPONSE: 'PAYMENTS/MODAL/BANK_ACCOUNTS_RESPONSE',
  GET_OUTBOUND_SETTLEMENT: 'PAYMENTS/MODAL/GET_OUTBOUND_SETTLEMENT',
  OUTBOUND_SETTLEMENT_RESPONSE: 'PAYMENTS/MODAL/OUTBOUND_SETTLEMENT_RESPONSE',
  CLEAR_MESSAGE_INFORMATION: 'PAYMENTS/PAYMENT/CLEAR_MESSAGE_INFORMATION'
}

export default key
