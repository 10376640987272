import styled from 'styled-components'
import { spacing } from '@global/theme'
import { regular, medium } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'

export const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > button {
    margin-left: 12px;
  }
  flex: 1;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ device, modal }) => {
    if (modal) return 'flex-end'
    if (device === BREAKPOINTS.DESKTOP) return 'flex-start'
    return 'center'
  }};
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  margin-bottom: ${spacing.two};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }
`

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.DESKTOP ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};

  & > :first-child {
    margin-right: ${spacing.one};
    margin: ${({ device }) => (device < BREAKPOINTS.DESKTOP ? `${spacing.one} 0` : `0 ${spacing.one} 0 0`)};
    width: ${({ device }) => (device < BREAKPOINTS.DESKTOP ? '100%' : 'auto')};

    & label {
      margin: 0 0 8px 0;
    }
  }
  & > :last-child {
    padding: 0;
  }
`
