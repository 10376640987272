import { useState, useEffect } from 'react'
import { PADDING_RIGHT_PAGE } from '@routes/constants'

const usePageWidth = () => {
  const size = document.getElementById('page')?.clientWidth || 1200
  const [width, setWidth] = useState(size)
  useEffect(() => {
    const handleResize = () => {
      const page = document.getElementById('page')
      setWidth(page.clientWidth - PADDING_RIGHT_PAGE.slice(0, PADDING_RIGHT_PAGE.length - 2))
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}

export default usePageWidth
