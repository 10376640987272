import React from 'react'
import PropTypes from 'prop-types'
import MyPropTyes from '@global/propTypes'

import { Text } from '@components/texts'
import Icon from '@components/icon'

import { ContainerProgressBar, DataContainer } from './styles'

const ProgressBar = ({ value, max, text, backgroundColor, progressColor, marginLeft }) => (
  <ContainerProgressBar backgroundColor={backgroundColor} progressColor={progressColor} marginLeft={marginLeft}>
    <progress
      value={value}
      max={max}
    />
    <DataContainer>
      <Icon name='rotate_left' spin />
      <Text text={`${text || 'Procesando datos'} ${value?.toFixed(2)}%`} size='small' align='center' weight='bold' />
    </DataContainer>
  </ContainerProgressBar>
)

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  backgroundColor: MyPropTyes.allColors,
  progressColor: MyPropTyes.allColors,
  marginLeft: PropTypes.string,
  text: PropTypes.string
}

ProgressBar.propTypes = {
  value: 0,
  max: 10
}

export default ProgressBar
