export const columnData = {
  Code: { name: 'Código', widthCol: '5%' },
  NotificationDate: { name: 'Fecha Creación', widthCol: '10%' },
  PaymentMadeDate: { name: 'Fecha de Pago', widthCol: '10%' },
  PaymentConfirmedDate: { name: 'Fecha de Cobro', widthCol: '10%' },
  OriginName: { name: 'Origen', widthCol: '10%' },
  Amount: { name: 'Monto total', widthCol: '10%' },
  StatusName: { name: 'Estado', widthCol: '10%' },
  Month: { name: 'Mes Liq.', widthCol: '10%' },
  Year: { name: 'Año Liq.', widthCol: '10%' },
  Description: { name: 'Descripción', widthCol: '15%' }
}
