import React from 'react'
import PropTypes from 'prop-types'

import { Doughnut } from 'react-chartjs-2'

import { colors } from '@global/theme'

import { Heading } from '@components/texts'

import {
  Chart,
  ArcElement,
  DoughnutController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js'

import { Container } from './styles'

Chart.register(
  ArcElement,
  DoughnutController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)

const DoughnutChart = ({ values }) => {
  const data = {
    labels: [],
    datasets: [{
      data: values.length ? values : [100, 0],
      backgroundColor: [
        colors.variant.disabled,
        colors.primary.regular
      ],
      hoverOffset: 10
    }]
  }

  const options = {
    responsive: false
  }

  return (
    <Container>
      <Heading size='medium' weight='bold'>{`${values[1] || 0}%`}</Heading>
      <Doughnut data={data} height='250' width='250' options={options} />
    </Container>
  )
}

DoughnutChart.propTypes = {
  values: PropTypes.array
}

export default DoughnutChart
