import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectSingIn = state => get(state, key, initialState)

const selectSuccess = createSelector(
  selectSingIn, state => get(state, 'user', null)
)
const selectError = createSelector(
  selectSingIn, state => get(state, 'error', false)
)

const selectLoading = createSelector(
  selectSingIn, state => get(state, 'loading', false)
)

const selectReponsePasswordConfirm = createSelector(
  selectSingIn, state => get(state, 'responsePasswordConfirm', {})
)

const selectErrorUserRefresh = createSelector(
  selectSingIn, state => get(state, 'errorRefresh', null)
)

export default {
  SUCCESS: selectSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  RESPONSE_PASSWORD_CONFIRM: selectReponsePasswordConfirm,
  ERROR_USER_REFRESH: selectErrorUserRefresh
}
