export const key = 'HISTORY_RECORD'

export const HISTORY_RECORD = {
  GET_HISTORY_RECORD: 'HISTORY_RECORD/GET_LIST',
  SUCCESS: 'HISTORY_RECORD/GET_LIST/SUCCESS',
  ERROR: 'HISTORY_RECORD/GET_LIST/ERROR',
  CLEAR: 'HISTORY_RECORD/CLEAR_LIST',
  DOWNLOAD_FILE: 'HISTORY_RECORD/DOWNLOAD_FILE',
  FILE_MESSAGE: 'HISTORY_RECORD/FILE_MESSAGE'
}

export default key
