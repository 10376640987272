import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS, changeNameForLabel } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { SolidButton } from '@components/button'
import { EmailInput, TextInput, Dropdown } from '@components/inputs/inputs'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { USER_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { ModalDataContainer, ButtonContainer } from '../../styles'

const EditUserProfileModal = ({ oldData, statuses, close, userId, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [showButton, setShowButton] = useState(true)
  const [fullName, setFullName] = useState({
    value: oldData.fullName,
    name: '',
    error: ''
  })
  const [email, setEmail] = useState({
    value: oldData.email,
    name: '',
    error: ''
  })
  const [options] = useState(changeNameForLabel(statuses))
  const [optionSelected, setOptionSelected] = useState(options.find(s => s.id === oldData.statusId))
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const profileInformation = useSelector(SELECTORS.PROFILE_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((fullName.value && !fullName.error) && (email.value && !email.error)) setShowButton(false)
    else setShowButton(true)
  }

  const handleInputChange = val => {
    const { name } = val
    if (name === 'fullname') setFullName(val)
    else setEmail(val)
  }

  const handleDropdownChange = e => {
    setOptionSelected(e.value)
  }

  const cleanFields = () => {
    setFullName({
      value: '',
      name: '',
      error: ''
    })
    setEmail({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelected(null)
  }

  const handleClick = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const newDataProfile = {
        fullName: fullName.value,
        email: email.value,
        statusId: optionSelected.id
      }
      dispatch(USER_PROFILE_ACTIONS.EDIT_USER_PROFILE({ userId, data: newDataProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    if (profileInformation) {
      const { data, status } = profileInformation
      if (status >= 200 && status <= 299) {
        dispatch(USER_PROFILE_ACTIONS.SET_NEW_DATA_PROFILE({ userProfile: data, statuses }))
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.USER_PROFILE_MODIFIED_SUCCESSFULLY,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
        cleanFields()
        setTimeout(() => {
          dispatch(USER_PROFILE_ACTIONS.CLEAR_PROFILE_INFORMATION())
          close()
        }, 1000)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: profileInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [profileInformation])

  useEffect(() => {
    errorControl()
  }, [fullName, email])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <TextInput
          name='fullname'
          label='Persona'
          placeholder='Ingrese nombre y apellido'
          color='primary'
          value={fullName.value}
          onChange={handleInputChange}
          height='small'
        />
        <EmailInput
          value={email.value}
          onChange={handleInputChange}
          onBlur={() => {}}
          name='email'
          label='Correo electrónico'
          placeholder='Ingrese su electrónico'
          color='primary'
          bits={[{ icon: 'mail', position: 'left', colorBit: 'tertiary' }]}
          error={email.error}
          height='small'
          required
        />
        <Dropdown
          name='status'
          label='Estado'
          placeholder='seleccione una opción'
          options={options}
          value={optionSelected}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
        timeOut={2000}
      />
    </>
  )
}

export default EditUserProfileModal

EditUserProfileModal.propTypes = {
  oldData: PropTypes.object,
  statuses: PropTypes.array,
  close: PropTypes.func,
  userId: PropTypes.string,
  currentToken: PropTypes.string
}
