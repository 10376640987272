export const key = 'ERROR_LOG'

export const ERROR_LOG = {
  GET: 'ERROR_LOG/GET_LIST',
  SUCCESS: 'ERROR_LOG/GET_LIST/SUCCESS',
  ERROR: 'ERROR_LOG/GET_LIST/ERROR',
  CLEAR: 'ERROR_LOG/CLEAR_LIST',

  SEND_FILTER: 'ERROR_LOG/FILTER/SEND',
  GET_FILTER_DATA: 'ERROR_LOG/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'ERROR_LOG/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'ERROR_LOG/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'ERROR_LOG/FILTER/CLEAR'
}

export default key
