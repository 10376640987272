import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  TextContainer,
  ButtonContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  TRANSLATION_OF_ENTITIES,
  DEVICE_NAME,
  BREAKPOINTS,
  TIMEOUT_PENDING_PROCESS,
  DETAIL_TYPES
} from '@global/constants'
import {
  TOKEN_HELPERS,
  getMonthName,
  sortByKey,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  currencyFormatUY
} from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Modal from '@components/modal'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'
import Filter from '@components/filter'
import Table from '@components/table'
import ProgressBar from '@components/progressBar'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditPaymentSettlementProfile from './components/editPaymentSettlementProfileStatusModal'
import AddDebtModal from './components/addDebtModal'

import useRedux from './redux'
import { PAYMENT_SETTLEMENT_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, PaymentSettlementProfileData, Tbody, ItemLink } from './styles'
import { columnData } from './data'

const PaymentSettlementProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { paymentSettlementProfileId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { device, deviceName } = useWindowDimensions()
  const history = useHistory()
  const modalRef = useRef()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [paymentSettlementProfile, setPaymentSettlementProfile] = useState(null)
  const [open, setOpen] = useState(false)
  const [editProfileModal, setEditProfileModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [addDebtModal, setAddDebtModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [currencies, setCurrencies] = useState()
  const [errorFlag, setErrorFlag] = useState(false)
  const [parameters, setParameters] = useState()
  const [isChangingStatusState, setIsChangingStatusState] = useState(false)
  const [isCalculatingState, setIsCalculatingState] = useState(false)
  const [progressValue, setProgressValue] = useState(0)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEditProfile = useSelector(SELECTORS.LOADING_EDIT_PROFILE)
  const paymentSettlementProfileData = useSelector(SELECTORS.SUCCESS)
  const paymentSettlementProfileStatuses = useSelector(SELECTORS.PAYMENT_SETTLEMENT_PROFILE_STATUSES)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const listLoading = useSelector(SELECTORS.LIST_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.DEBITS_SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const debitsStatuses = useSelector(SELECTORS.DEBITS_STATUSES)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const currenciesData = useSelector(SELECTORS.CURRENCIES_INFORMATION)
  const loadingCalculationDetails = useSelector(SELECTORS.LOADING_CALCULATE_DETAILS)
  const calculationInformation = useSelector(SELECTORS.CALCULATE_DETAILS_INFORMATION)
  const isCalculatingInfo = useSelector(SELECTORS.IS_CALCULATING_INFO)
  const isChangingStatusInfo = useSelector(SELECTORS.IS_CHANGING_STATUS_INFO)

  const handleToastClose = () => setIsShowing(false)

  const setValueInput = text => {
    const inputSearch = document.getElementById('text_search')
    if (inputSearch) {
      inputSearch.value = text || ''
    }
  }

  const renderPaymentSettlementProfileData = objPaymentSettlementProfileRecordData => {
    const { totalsByCurrency } = objPaymentSettlementProfileRecordData

    return (
      <>
        <Tr>
          <Td colorLight widthPer>Mes:</Td>
          <Td largeSize>
            {getMonthName(objPaymentSettlementProfileRecordData.month) || 'N/A'}
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Año:</Td>
          <Td largeSize>{objPaymentSettlementProfileRecordData.year || 'N/A'}</Td>
        </Tr>
        {
        totalsByCurrency && totalsByCurrency.length > 0
        && totalsByCurrency.map(total => (
          <Tr>
            <Td colorLight widthPer>{
              `Monto total ${currencies.find(c => c.id === total.currencyId)?.name}:`
            }
            </Td>
            <Td largeSize>{
              total.currencyId === 1
                ? `${currencyFormatUY(total?.total)}`
                : `${currencies.find(c => c.id === total.currencyId)?.symbol} ${total.total || 'N/A'}`
            }
            </Td>
          </Tr>
        ))
      }
        <Tr>
          <Td colorLight widthPer>Estado:</Td>
          <Td largeSize>{
          objPaymentSettlementProfileRecordData.statusId && paymentSettlementProfileStatuses
            ? paymentSettlementProfileStatuses.find(s => s.id === objPaymentSettlementProfileRecordData.statusId).name
            : 'N/A'
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Descripción:</Td>
          <Td largeSize>{objPaymentSettlementProfileRecordData?.description || 'N/A'}</Td>
        </Tr>
        {isChangingStatusState
            && (
              <Tr>
                <ProgressBar value={progressValue} max={100} marginLeft={spacing.quarter} />
              </Tr>
            )}
      </>
    )
  }

  const getDebts = body => {
    body.outboundSettlementId = paymentSettlementProfileId
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_DEBITS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataInProcess = statusId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_PROCESS_PENDING_INFO(
        { statusId, paymentSettlementProfileId, token }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSavePaymentSettlementProfile = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.EDIT_PAYMENT_SETTLEMENT_PROFILE(
        { paymentSettlementProfileId, data: newProfile, token }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleClearValues = () => {
    modalRef.current?.clearValues()
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditProfileModal(null)
    setAddDebtModal(null)
    handleClearValues()
  }

  const handleEditStatus = () => {
    setModalTitle('Editar Liquidación de Pago')
    setEditProfileModal({
      statuses: paymentSettlementProfileStatuses,
      oldData: paymentSettlementProfile,
      saveData: handleSavePaymentSettlementProfile,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 13, token }
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDebts(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getDebts(body)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getDebts(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getDebts(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickCloseFilters = () => {
    dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDebts(body)
  }

  const handleSaveNewDebt = newDebt => {
    newDebt.outboundSettlementId = paymentSettlementProfileId
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        outboundSettlementId: paymentSettlementProfileId,
        orderByDesc: true,
        orderByColumn: 'Id'
      }
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.ADD_DEBT({ body, data: newDebt, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleAddDebt = () => {
    setModalTitle('Agregar adeudo')
    setAddDebtModal({
      saveData: handleSaveNewDebt,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  const handleDebtClick = debtId => {
    if (debtId) {
      const win = window.open(`${SITE.DEBT_PROFILE}/${debtId}`, '_blank')
      win.focus()
    }
  }

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleSendToPaymentDetailList = debtId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${debtId}/${DETAIL_TYPES.DEBT}`, '_blank')
    win.focus()
  }

  const handlePaymentDetailClick = detailId => {
    if (detailId) {
      const win = window.open(`${SITE.PAYMENT_DETAIL}/${detailId}`, '_blank')
      win.focus()
    }
  }

  const handleEntityClick = (entity, entityId) => {
    let url = ''
    switch (entity) {
      case TRANSLATION_OF_ENTITIES.FRMRecord.name:
        url = `${SITE.FRM_RECORD}/${entityId}`
        break
      case TRANSLATION_OF_ENTITIES.AffiliateRecord.name:
        url = `${SITE.AFFILIATE_RECORD}/${entityId}`
        break
      default:
        url = `${SITE.SERVICE_PROVIDER_PROFILE}/${entityId}`
        break
    }
    const win = window.open(url, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { payments } = item
      const status = item.statusId === 3
        ? (
          <Text color='error'>
            {debitsStatuses?.find(s => s.id === item.statusId).name || item.statusName}
          </Text>
        )
        : debitsStatuses?.find(s => s.id === item.statusId).name || item.statusName
      const code = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleDebtClick(item.id)}
        >
          {`A - ${item.id}`}
        </ItemLink>
      )

      const person = item.personId
        ? (
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handlePersonClick(item.personId)}
          >
            {`${item.billingNumber} - ${item.personName}`}
          </ItemLink>
        ) : 'N/A'

      let link = 'N/A'
      payments = payments.filter(cp => cp.statusId === 1)
      if (payments?.length > 0) {
        link = payments?.length === 1
          ? (
            <ItemLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handlePaymentDetailClick(payments[0].id)}
            >
              {`DP - ${payments[0].id}`}
            </ItemLink>
          )
          : (
            <ItemLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToPaymentDetailList(item.id)}
            >
              Ver lista de DP
            </ItemLink>
          )
      }

      const { affiliateRecordId, frmRecordId, serviceId } = item
      let text = null
      let entityType = null
      let entityId = null

      if (affiliateRecordId) {
        text = `${TRANSLATION_OF_ENTITIES.AffiliateRecord.label} - ${affiliateRecordId}`
        entityType = TRANSLATION_OF_ENTITIES.AffiliateRecord.name
        entityId = affiliateRecordId
      }

      if (frmRecordId) {
        text = `${TRANSLATION_OF_ENTITIES.FRMRecord.label} - ${frmRecordId}`
        entityType = TRANSLATION_OF_ENTITIES.FRMRecord.name
        entityId = frmRecordId
      }

      if (serviceId) {
        text = `${TRANSLATION_OF_ENTITIES.Service.label} - ${serviceId}`
        entityType = TRANSLATION_OF_ENTITIES.Service.name
        entityId = serviceId
      }

      const entity = (
        <ItemLink
          color='tertiary'
          underline
          onClick={
          () => handleEntityClick(
            entityType, entityId
          )
        }
        >
          {text}
        </ItemLink>
      )

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={(item.totalPaid > item.total) ? 'error' : 'system'}
          weight={(item.totalPaid > item.total) ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid)}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        person,
        service: item.serviceName || 'N/A',
        concep: item.concept,
        entity,
        currency: item.currency || currencies.find(c => c.id === item.currencyId).name,
        total: currencyFormatUY(item.total),
        link,
        status,
        dueDate: item.dueDate,
        totalPaid
      }
      return newItem
    })
    return newData
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order')
      .map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
        if (col.name === 'Id') return 'Code'
        if (col.name === 'Month' || col.name === 'Year') return
        return col.name
      })
      .filter(Boolean)
    orderedColumns = [...orderedColumns, 'TotalPaid']

    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  const handleCalculateDetails = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CALCULATE_DETAILS({
        outboundSettlementId: paymentSettlementProfileId, data: {}, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getDebts, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_PAYMENT_SETTLEMENT_PROFILE({ paymentSettlementProfileId, token }))
      const data = {
        outboundSettlementId: paymentSettlementProfileId,
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByDesc: true,
        orderByColumn: 'Id'
      }
      setActiveFilters(data)
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_DEBITS({ data, token }))
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_CURRENCIES(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (calculationInformation) {
      const { status } = calculationInformation
      if (status === 200) {
        if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: calculationInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [calculationInformation])

  useEffect(() => {
    setTablelist(customDataTable(items))
    setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
    if (items.length > 0) setShowTable(true)
  }, [items, totalPages])

  useEffect(() => {
    if (paymentSettlementProfileData) {
      setPaymentSettlementProfile(paymentSettlementProfileData)
      if (paymentSettlementProfile) {
        if (paymentSettlementProfile.statusId === 1) setValueInput()
      }
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENT_PROFILE} - ${paymentSettlementProfileData.id}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url: `${SITE.FINANCE}`
        }
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENTS}`,
          link: true,
          url: `${SITE.FINANCE}${SITE.PAYMENT_SETTLEMENTS}`
        }
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENT_PROFILE} - ${paymentSettlementProfileData.id}`,
          link: false,
          url: null
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }
    }
  }, [paymentSettlementProfileData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        getDebts(body)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (currenciesData && currenciesData.status === 200) {
      setCurrencies(currenciesData.currenciesObj)
    }
  }, [currenciesData])

  useEffect(() => {
    if (messageInformation) {
      if (messageInformation === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByDesc: true,
          orderByColumn: 'Id'
        }
        getDebts(body)
      }
      handleCloseModal()
      setIsShowing(true)
      dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    let calculatingTimeOut = null
    if (isCalculatingInfo) {
      const { status, isCalculating, progressPercentage } = isCalculatingInfo
      if (status === 200) {
        setIsCalculatingState(isCalculating)
        setProgressValue(progressPercentage * 100)

        if (isCalculating) {
          calculatingTimeOut = setTimeout(() => {
            getDataInProcess(paymentSettlementProfile?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }
        if (!isCalculating && !calculatingTimeOut) {
          clearTimeout(calculatingTimeOut)
          const data = activeFilters
            ? { ...activeFilters }
            : {
              outboundSettlementId: paymentSettlementProfileId,
              pageNumber: 1,
              pageSize: selectedOption.value,
              orderByDesc: true,
              orderByColumn: 'Id'
            }
          dispatch(PAYMENT_SETTLEMENT_PROFILE_ACTIONS.GET_DEBITS({ data, token: currentToken.token }))
        }
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: isCalculatingInfo?.message || `Error ${isCalculating?.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }

    return () => {
      clearTimeout(calculatingTimeOut)
    }
  }, [isCalculatingInfo])

  useEffect(() => {
    let changingStatusTimeOut = null
    if (isChangingStatusInfo) {
      const { status, isChangingStatus, progressPercentage } = isChangingStatusInfo
      if (status === 200) {
        setIsChangingStatusState(isChangingStatus)
        setProgressValue(progressPercentage * 100)
        if (isChangingStatus) {
          changingStatusTimeOut = setTimeout(() => {
            getDataInProcess(paymentSettlementProfile?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }

        if (!isChangingStatus && !changingStatusTimeOut) {
          clearTimeout(changingStatusTimeOut)
          if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
        }
      }
    } else {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: isChangingStatusInfo?.message || `Error ${isChangingStatusInfo?.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }

    return () => {
      clearTimeout(changingStatusTimeOut)
    }
  }, [isChangingStatusInfo])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' device={device}>
              {paymentSettlementProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`${TRANSLATION_OF_ROUTES.PAYMENT_SETTLEMENT_PROFILE} - ${paymentSettlementProfile.id}`}
               </Heading>
               )}
              {breadcrumbs
                && (
                <MyLink
                  color='primary'
                  to={{
                    pathname: `${SITE.HISTORY}${pathname}`,
                    state: { breadcrumbs }
                  }}
                  textDecoration='underline'
                >
                  Historial de Actividad
                </MyLink>
                )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEditProfile
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <PaymentSettlementProfileData>
                      <Tbody>
                        {paymentSettlementProfile && currencies
                         && renderPaymentSettlementProfileData(paymentSettlementProfile)}
                      </Tbody>
                    </PaymentSettlementProfileData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditStatus} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            {loadingCalculationDetails
              ? (
                <WrapperLoading>
                  <Loading color='primary' size='xsmall' weight='bold' />
                </WrapperLoading>
              )
              : (
                <>
                  <TextContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
                    <Text size='large' weight='bold' align='left'>Adeudos</Text>
                  </TextContainer>
                  <ButtonContainer
                    columnStart={1}
                    columnEnd={12}
                    rowStart={5}
                    rowEnd={5}
                    justifyContent='flex-start'
                    marginTop={spacing.two}
                    flexDirection={device === BREAKPOINTS.MOBILE ? 'column-reverse' : 'row'}
                    alignItems='flex-start'
                    device={device}
                  >
                    <GhostButton
                      color='primary'
                      size='small'
                      text='Calcular Adeudos'
                      icon='format_list_numbered'
                      onClick={handleCalculateDetails}
                      block={deviceName === DEVICE_NAME.MOBILE}
                    />
                    {isCalculatingState
                     && (
                     <ProgressBar value={progressValue} max={100} />
                     )}
                  </ButtonContainer>
                  <FilterContainer columnStart={1} columnEnd={12} rowStart={6} rowEnd={6} deviceName={deviceName}>
                    <FilterContainerLeftSide deviceName={deviceName}>
                      {showTable
                    && (
                    <GhostButton
                      color='tertiary'
                      size='small'
                      text='Agregar Filtro'
                      icon='filter_alt'
                      onClick={handleClickShowFilters}
                    />
                    )}
                      {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                      {showFilter && (filterData.length > 0 && renderFilter(filterData))}
                    </FilterContainerLeftSide>
                    <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                      {showTable && (
                      <SearchBar
                        onChange={handleInputChange}
                        visible={activeFilters?.filterText}
                        value={activeFilters?.filterText}
                      />
                      )}
                      <GhostButton
                        color='tertiary'
                        size='small'
                        text='Agregar Adeudo +'
                        onClick={handleAddDebt}
                        disabled={paymentSettlementProfile?.statusId !== 2}
                      />
                    </FilterContainerRightSide>
                  </FilterContainer>
                  <TableWrapper columnStart={1} columnEnd={12} rowStart={7} rowEnd={7}>
                    {renderTable({
                      currentList: tablelist,
                      columnsN: columns,
                      currentLoading: listLoading,
                      handleOrder: handleOrderChange,
                      error: !!errorMessage,
                      showRowPointer: true
                    })}
                  </TableWrapper>
                </>
              )}
          </>
        )}
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={handleToastClose}
      />
      <Modal
        isShowing={open}
        close={handleCloseModal}
        title={modalTitle}
        minHeight='auto'
      >
        {editProfileModal && (<EditPaymentSettlementProfile {...editProfileModal} />)}
        {addDebtModal && (<AddDebtModal {...addDebtModal} ref={modalRef} />)}
      </Modal>
    </>
  )
}

export default PaymentSettlementProfile
