import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { TOAST_POSITION, HISTORY_TYPES, TOAST_PROPERTIES } from '@global/constants'
import { TOKEN_HELPERS, sortByKey } from '@utils/helpers'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  TableWrapper
} from '@global/styles'
import { SITE } from '@routes/paths'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { EMPLOYEE_HISTORY_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { EMPLOYEE_HISTORY_DATA_TYPES } from './data'
import { ProfileLink } from './styles'

const EmployeeHistory = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [currentHistoryData, setCurrentHistoryData] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const loading = useSelector(SELECTORS.LOADING)
  const items = useSelector(SELECTORS.SUCCESS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getEmployeeHistory = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      if (currentHistoryData) {
        body = {
          ...body,
          personId: currentHistoryData.personId,
          historyType: currentHistoryData.historyType
        }
      }

      dispatch(EMPLOYEE_HISTORY_ACTIONS.GET_EMPLOYEE_HISTORY({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order').map(col => col.name)
    orderedColumns = [...orderedColumns, 'Data']
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }
    return customColumns
  }

  const handleRowClick = (objData, historyType) => {
    if (objData) {
      const { id, statusId } = objData
      const { personId } = state
      let url = ''
      let customState = null
      switch (historyType) {
        case HISTORY_TYPES.EMPLOYMENT:
          url = `${SITE.EMPLOYMENT_RECORD}/${id}`
          customState = { breadcrumbs, employmentRecordId: id }
          break
        case HISTORY_TYPES.AFFILIATE:
          url = `${SITE.AFFILIATE_RECORD}/${id}`
          customState = { breadcrumbs, affiliateRecordId: id, personId, statusId }
          break
        default:
          url = `${SITE.FRM_RECORD}/${id}`
          customState = { breadcrumbs, frmRecordId: id, personId, statusId }
      }
      history.push({
        pathname: url,
        state: customState
      })
    }
  }

  const customBodyDataTable = (historyType, bodyData) => {
    const newData = bodyData.map(item => {
      const link = (
        <ProfileLink
          color='tertiary'
          underline
          onClick={() => handleRowClick(item, historyType)}
        >
          Ver datos
        </ProfileLink>
      )

      let newItem = null
      switch (historyType) {
        case HISTORY_TYPES.EMPLOYMENT:
          newItem = {
            id: item.id,
            billingNumber: item.billingNumber,
            initialPosition: item.initialPosition,
            statusName: item.statusName,
            startDate: item.startDate,
            endDate: item.endDate,
            link
          }
          break
        case HISTORY_TYPES.AFFILIATE:
          newItem = {
            id: item.id,
            categoryName: item.categoryName,
            statusName: item.statusName,
            startDate: item.startDate,
            endDate: item.endDate,
            link
          }
          break
        default:
          newItem = {
            id: item.id,
            categoryName: item.categoryName,
            statusName: item.statusName,
            collctionBase: item.collctionBase,
            totalPaymentsMade: item.totalPaymentsMade,
            startDate: item.startDate,
            endDate: item.endDate,
            link
          }
      }
      return newItem
    })
    return newData
  }

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  useEffect(() => {
    if (state.breadcrumbs.length > 0) {
      const { id, historyType, personId } = state
      setCurrentHistoryData({ id, historyType })
      const body = { historyType, personId }
      getEmployeeHistory(body)
      const lastCrumb = {
        link: true,
        text: EMPLOYEE_HISTORY_DATA_TYPES[historyType].breadcrumbText,
        url: {
          pathname,
          state: {
            breadcrumbs: [...state.breadcrumbs],
            historyType,
            id,
            personId
          }
        }
      }
      setBreadcrumbs([...state.breadcrumbs, lastCrumb])
    }
  }, [])

  useEffect(() => {
    if (items.length > 0) {
      setColumns(customHeaderDataTable(
        EMPLOYEE_HISTORY_DATA_TYPES[currentHistoryData.historyType].fields,
        EMPLOYEE_HISTORY_DATA_TYPES[currentHistoryData.historyType].colNames
      ))
      setTablelist(customBodyDataTable(currentHistoryData.historyType, items))
    }
  }, [items])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    }
  }, [errorMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <TableWrapper rowStart='3' rowEnd='3' columnStart='1' columnEnd='12'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                error: errorMessage
              })}
            </TableWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </>
        )}
    </>
  )
}

export default EmployeeHistory
