import { MESSAGES } from '@global/message'
import {
  TOAST_PROPERTIES,
  FILTER_TYPES,
  MONTHS,
  ENTITY_COLUMNS_NAMES,
  TRANSLATION_OF_ROUTES,
  GREETINGS
} from '@global/constants'
import { HISTORY_FIELDS } from '@containers/historyLog/data'
import { SITE } from '@routes/paths'

const setTokenDate = minutes => {
  const currentDateObj = new Date()
  const numberOfMlSeconds = currentDateObj.getTime()
  const addMlSeconds = minutes * 60000
  const newDateObj = new Date(numberOfMlSeconds + addMlSeconds)
  return newDateObj
}

const setBearer = token => (`Bearer ${token}`)

const tokenTimeValidator = tokenTime => {
  const res = { validate: false, error: '' }
  if (tokenTime > new Date()) return { ...res, validate: true }
  return { ...res, validate: false, error: MESSAGES.EXPIRED_TOKEN }
}

const isValidToken = tokenCreationDate => {
  const { validate, error } = tokenTimeValidator(tokenCreationDate)
  if (validate) return { isValid: true, error: '' }
  return { isValid: false, error }
}

const expiredToken = (error, setShowToast, setToastData, logout) => {
  setShowToast(true)
  setToastData({
    title: TOAST_PROPERTIES.ERROR.title,
    message: error || MESSAGES.EXPIRED_TOKEN,
    icon: TOAST_PROPERTIES.ERROR.icon,
    color: TOAST_PROPERTIES.ERROR.color
  })
  logout()
}

export const TOKEN_HELPERS = {
  SET_TOKEN_DATE: setTokenDate,
  SET_BEARER: setBearer,
  TOKEN_TIME_VALIDATOR: tokenTimeValidator,
  IS_VALID_TOKEN: isValidToken,
  EXPIRED_TOKEN: expiredToken
}

export const capitalizeFirstLetter = str => str && str.charAt(0).toUpperCase() + str.slice(1)

export const formatDateBasic = (
  stringJSONDate, type = 'long', nameDay = true, numberMonth = true, dateTime = false
) => {
  const nameDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']

  if (!stringJSONDate) return 'N/A'

  const date = new Date(stringJSONDate)
  const time = dateTime ? date.toLocaleTimeString() : ''

  if (type === 'short') return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}${time ? ` ${time}` : ''}`

  const weekDay = nameDay ? nameDays[date.getDay() - 1] : ''
  const day = date.getDate()
  const month = numberMonth ? date.getMonth() + 1 : MONTHS[date.getMonth()]?.label
  const year = date.getFullYear()
  const strDate = `${nameDay ? weekDay : ''} ${day} 
  ${numberMonth ? '/' : ' de '}${month} ${numberMonth ? '/' : ' de '}
  ${year} 
  ${dateTime ? time : ''}`
  return strDate
}

export const transformArrToObj = (array, key) => {
  let newObj = {}
  for (let i = 0; i < array.length; i++) {
    newObj = {
      ...newObj,
      [array[i][key]]: array[i]
    }
  }
  return newObj
}

export const sortByKey = (arr, key, des) => {
  if (arr.length === 0) return arr
  const newArr = [...arr]
  const SortArray = (a, b) => {
    if (des ? a[key] > b[key] : a[key] < b[key]) return -1
    return 1
  }

  return newArr.sort(SortArray)
}

export const changeNameForLabel = arrData => {
  const newData = arrData.map(item => ({
    id: item.id,
    label: item.name
  }))
  return newData
}

const removeParameterFromActiveFilters = (parameters, activeFilters) => {
  const currentActiveFilters = { ...activeFilters }

  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const property in currentActiveFilters) {
    const propWanted = parameters.find(p => p === property)
    let wanted = ''
    if (!propWanted) {
      wanted = Object.keys(currentActiveFilters)
        .find(af => af === property)
        .toLowerCase()
        .includes('date')
    }

    if (propWanted || wanted) {
      delete currentActiveFilters[property]
    }
  }
  return currentActiveFilters
}

export const filterBodyBuilder = (filters, pageSize, setActiveFilters, currentActiveFilters, parametersFilters) => {
  currentActiveFilters = removeParameterFromActiveFilters(parametersFilters, currentActiveFilters)

  let body = currentActiveFilters ? { ...currentActiveFilters } : { pageNumber: 1, pageSize }

  for (let i = 0; i < filters.length; i++) {
    const { type, name, items: filterItems } = filters[i]
    let value = null
    if (type !== FILTER_TYPES.DATE_PICKER) {
      value = type === FILTER_TYPES.CHECKBOX
        ? filterItems.map(item => Number(item.id))
        : filterItems[0]
      body = {
        ...body,
        [name]: value
      }
    } else {
      for (let j = 0; j < filterItems.length; j++) {
        const { name: dateName, value: dateValue } = filterItems[j]
        body = {
          ...body,
          [dateName]: dateValue
        }
      }
    }
  }

  if (filters.length > 0) {
    const activesFilters = { ...body }
    delete activesFilters.pageNumber
    delete activesFilters.pageSize
    setActiveFilters(activesFilters)
  } else setActiveFilters(body)

  return body
}

export const handleClickPaginationItem = (n, filterState, pageSize, sendBody, setNewItemSelected) => {
  let body = null
  if (filterState) {
    body = filterState
    body = {
      ...body,
      pageNumber: (n + 1),
      pageSize
    }
  } else {
    body = {
      pageNumber: (n + 1),
      pageSize
    }
  }

  sendBody(body)
  setNewItemSelected(n)
}

export const filterOrderBuilder = props => {
  const {
    filterState,
    setFilterState,
    orderByDesc,
    currentPageSize,
    currentPageNumber,
    setNewItemSelected
  } = props
  let { columnName } = props
  let body = null
  let flag = false
  if (columnName.toLowerCase() === 'code') {
    columnName = 'Id'
  }
  if (filterState) {
    if (filterState.orderByColumn && columnName !== filterState.orderByColumn) {
      setNewItemSelected(0)
      flag = true
    }
    if (!filterState.orderByColumn) flag = true

    body = { ...filterState }
    body = {
      ...body,
      pageNumber: flag ? 1 : currentPageNumber + 1,
      pageSize: currentPageSize,
      orderByColumn: columnName,
      orderByDesc
    }
  } else {
    body = {
      pageNumber: 1,
      pageSize: currentPageSize,
      orderByColumn: columnName,
      orderByDesc
    }
  }
  setFilterState({
    ...filterState,
    orderByColumn: columnName,
    orderByDesc
  })
  return body
}

export const getMonthName = id => {
  const monthName = MONTHS.find(m => m.id === id - 1)
  return monthName.label
}

const customValue = (key, value) => {
  // DATES
  if ((key.toLocaleLowerCase().includes('date') || key === 'Birthday')
    && Date.parse(value)) value = formatDateBasic(value, 'short')
  if (key === 'Month' && value > 0) value = MONTHS.find(m => m.id === value - 1).label

  // OBJECT
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    switch (key) {
      case 'Person': {
        const { BillingNumber, FullName, Id } = value
        value = {
          BillingNumber,
          FullName,
          Id
        }
      }
        break
      case 'Settlement':
        value = value.Id
        break
      case 'EmploymentRecord':
        value = value.InitialPosition
        break
      case 'Status':
        value = value.Name
        break
      case 'Category':
        value = value.Name
        break
      case 'File': {
        const { Id, Name } = value
        value = {
          Id,
          Name
        }
      }
        break
      case 'Country': {
        const { Name } = value
        value = Name
      }
        break
      case 'PhoneType': {
        const { Name } = value
        value = Name
      }
        break
      case 'Currency': {
        const { Name } = value
        value = Name
      }
        break
      case 'BankAccount': {
        const { AccountNumber, Bank, Currency } = value
        value = `${Bank?.Name || 'Banco: N/A'} 
        - ${AccountNumber || 'Nro.Cuenta: N/A'} 
        - ${Currency?.Name || 'Divisa: N/A'}`
      }
        break
      default:
        value = null
    }
  }
  return value
}

const filterRelevantData = (payload, requiredField) => {
  let customData = []
  if (requiredField.length > 0) {
    for (let i = 0; i < requiredField.length; i++) {
      const wanted = Object.entries(payload).find(item => item[0] === requiredField[i])
      if (wanted) {
        const key = wanted[0]
        let value = wanted[1]

        value = customValue(key, value)

        customData = [
          ...customData,
          {
            label: ENTITY_COLUMNS_NAMES[key]?.label,
            value,
            order: ENTITY_COLUMNS_NAMES[key]?.order,
            id: ENTITY_COLUMNS_NAMES[key]?.name
          }
        ]
      }
    }

    customData = sortByKey(customData, 'order')
  }

  return customData
}

export const customEntityPayload = (objData, discriminator) => {
  let customPayloadData = null
  const hf = HISTORY_FIELDS.find(f => f.name === discriminator)
  customPayloadData = hf ? filterRelevantData(objData, hf.fields) : []
  return customPayloadData
}

export const compareHistoryArrays = (currentArr, oldArr) => {
  let arrDifference = []

  if (currentArr.length !== oldArr.length) return arrDifference

  for (let i = 0; i < currentArr.length; i++) {
    if (currentArr[i].value !== oldArr[i].value) {
      const { id, value } = oldArr[i]
      arrDifference = [
        ...arrDifference,
        {
          id,
          value
        }
      ]
    }
  }

  return arrDifference
}

export const customBankAccounts = arrBankAccounts => arrBankAccounts.map(ba => {
  const description = ba.description ? `- ${ba.description}` : ''
  const primary = ba.isPrimary ? '(Primaria)' : ''

  return {
    id: ba.id,
    label: `${ba.bankName} - ${ba.accountNumber} ${description} ${primary}`
  }
})

export const searcTraslation = (strWanted, siteObj, translationObj) => {
  let translation = 'Registro de historia'
  const urlWanted = Object.entries(siteObj).find(u => u[1] === strWanted)
  if (urlWanted) {
    translation = translationObj[urlWanted[0]]
  }
  return translation
}

export const createBreadcrumb = url => {
  let breadcrumb = []

  if (!url) return breadcrumb

  let arrUrl = url.split('/').filter(i => i !== '')
  if (arrUrl.length === 1) {
    breadcrumb = [
      ...breadcrumb,
      {
        link: true,
        text: arrUrl[0],
        url: `/${arrUrl[0]}`
      }
    ]
  } else {
    const hl = arrUrl.find(i => i === 'historyLog')
    const h = arrUrl.find(i => i === 'history')
    arrUrl = arrUrl.filter(i => (i !== 'historyLog' && i !== 'history'))

    let urlCrumb = ''
    let textCrumb = ''
    let historyId = ''
    let activityLogFlag = false

    for (let i = 0; i < arrUrl.length; i++) {
      // eslint-disable-next-line no-restricted-globals
      if (arrUrl[i + 1] && !isNaN(arrUrl[i + 1])) {
        if (`/${arrUrl[i]}` === SITE.ACTIVITY_LOG) {
          urlCrumb += `/${arrUrl[i]}`
          activityLogFlag = true
        } else {
          urlCrumb += `/${arrUrl[i]}/${arrUrl[i + 1]}`
        }
        textCrumb = searcTraslation(`/${arrUrl[i]}`, SITE, TRANSLATION_OF_ROUTES)

        breadcrumb = [
          ...breadcrumb,
          {
            link: true,
            text: activityLogFlag ? textCrumb : `${textCrumb} - ${arrUrl[i + 1]}`,
            url: urlCrumb
          }
        ]
        i++
      } else if (h && i === arrUrl.length - 1) {
        historyId = arrUrl[i]
      } else {
        urlCrumb += `/${arrUrl[i]}`
        textCrumb = searcTraslation(`/${arrUrl[i]}`, SITE, TRANSLATION_OF_ROUTES)
        breadcrumb = [
          ...breadcrumb,
          {
            link: true,
            text: textCrumb,
            url: urlCrumb
          }
        ]
      }
    }

    if (h && hl) {
      urlCrumb = `${SITE.HISTORY}${urlCrumb}`
      const historyCrumb = {
        link: true,
        text: TRANSLATION_OF_ROUTES.ACTIVITY_HISTORY,
        url: urlCrumb
      }

      const historyLog = {
        link: false,
        text: TRANSLATION_OF_ROUTES.HISTORY_LOG,
        url: `${SITE.HISTORY_LOG}${urlCrumb}/${historyId}`
      }
      breadcrumb = [
        ...breadcrumb,
        historyCrumb,
        historyLog
      ]
    }

    if (h && !hl) {
      urlCrumb = `${SITE.HISTORY}${urlCrumb}`
      const historyCrumb = {
        link: true,
        text: TRANSLATION_OF_ROUTES.ACTIVITY_HISTORY,
        url: urlCrumb
      }
      breadcrumb = [
        ...breadcrumb,
        historyCrumb
      ]
    }

    if (hl && !h) {
      urlCrumb = `${SITE.HISTORY_LOG}${urlCrumb}`
      const historyCrumb = {
        link: false,
        text: TRANSLATION_OF_ROUTES.ACTIVITY_HISTORY,
        url: urlCrumb
      }
      breadcrumb = [
        ...breadcrumb,
        historyCrumb
      ]
    }
  }

  return breadcrumb
}

export const isNumeric = number => !Number.isNaN(parseFloat(number)) && Number.isFinite(number)

export const currencyFormatUY = value => {
  if (isNumeric(value)) {
    return `${Number(value).toLocaleString('es-UY', {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'UYU'
    })}`
  }
  return 'N/A'
}

export const formatDate = (date, timeDate) => {
  const isValidDate = Date.parse(date)
  if (isValidDate) {
    const dt = new Date(date)
    let options = {
      dateStyle: 'medium',
      timeZone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    }
    if (timeDate) options = { ...options, timeStyle: 'medium' }
    const currenDate = dt.toLocaleString(`${navigator.language}`, options)
    return currenDate
  }
  return date
}

export const setGreeting = date => {
  const customDate = new Date(date)
  const hour = customDate.toLocaleTimeString('es-ES')
  let greeting = GREETINGS.GOOD_MORNING

  if (hour >= '14:00:00' && hour < '20:00:00') greeting = GREETINGS.GOOD_AFTERNOON

  if (hour >= '20:00:00' && hour < '23:59:59') greeting = GREETINGS.GOOD_EVENING

  return greeting
}

export const compareObj = (a, b) => {
  if (a && b) {
    const aKeys = Object.keys(a).sort()
    const bKeys = Object.keys(b).sort()
    if (aKeys.length !== bKeys.length) {
      return false
    }
    if (aKeys.join('') !== bKeys.join('')) {
      return false
    }
    for (let i = 0; i < aKeys.length; i++) {
      if (a[aKeys[i]] !== b[bKeys[i]]) {
        return false
      }
    }
    return true
  }
}

export const strMonthYear = (month, year) => `${MONTHS[month]?.label.substring(0, 3)}-${year}`
