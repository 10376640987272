import { USERS } from './types'

export const getStatuses = payload => ({
  type: USERS.GET_STATUSES,
  payload
})

export const getStatusesSuccess = payload => ({
  type: USERS.GET_STATUSES_SUCCESS,
  payload
})

export const getStatusesError = payload => ({
  type: USERS.GET_STATUSES_ERROR,
  payload
})

export const getUsers = payload => ({
  type: USERS.GET,
  payload
})

export const getSuccess = payload => ({
  type: USERS.SUCCESS,
  payload
})

export const getUsersError = payload => ({
  type: USERS.USERS_ERROR,
  payload
})

export const ClearList = payload => ({
  type: USERS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: USERS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: USERS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: USERS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: USERS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: USERS.FILTER_DATA_CLEAR
})

export const informationModalTypes = payload => ({
  type: USERS.INFORMATION_MODAL_TYPES,
  payload
})

export const addUser = payload => ({
  type: USERS.ADD_USER,
  payload
})

export const addUserInformation = payload => ({
  type: USERS.ADD_USER_INFORMATION,
  payload
})

export const USERS_ACTIONS = {
  GET_STATUSES: getStatuses,
  GET_STATUSES_SUCCESS: getStatusesSuccess,
  GET_STATUSES_ERROR: getStatusesError,
  GET: getUsers,
  SUCCESS: getSuccess,
  USERS_ERROR: getUsersError,
  CLEAR: ClearList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,
  INFORMATION_MODAL_TYPES: informationModalTypes,
  ADD_USER: addUser,
  ADD_USER_INFORMATION: addUserInformation
}
