import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { MONTHS, TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { ModalDataContainer, ButtonContainer, TextContainerModal, ItemLink } from '@global/styles'
import { SITE } from '@routes/paths'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { Text } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PAYMENTS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddPaymentModal = ({ close, successfulResult, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [options, setOptions] = useState()
  const [optionSelected, setOptionSelected] = useState()
  const [outboundSettlements, setOutboundSettlements] = useState()
  const [showForm, setShowForm] = useState(false)
  const [optionSelectedOutboundSettlement, setOptionSelectedOutboundSettlement] = useState()
  const [paymentDate, setPaymentDate] = useState()
  const [description, setDescription] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const bankAccountsResponse = useSelector(SELECTORS.BANK_ACCOUNTS_RESPONSE)
  const outboundSettlementsData = useSelector(SELECTORS.OUTBOUND_SETTLEMENT_RESPONSE)
  const messageInformation = useSelector(SELECTORS.ADD_PAYMENT_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if (optionSelected) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setDescription({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelected(null)
  }

  const handleDropdownChange = val => {
    const { name, value } = val
    if (name === 'outboundSettlement') setOptionSelectedOutboundSettlement(value)
    else setOptionSelected(value)
  }

  const handleInputChange = val => setDescription(val)

  const customBankAccounts = arrBankAccounts => arrBankAccounts.map(ba => ({
    id: ba.id,
    label: `${ba.bankName} - ${ba.accountNumber} - ${ba.description}`
  }))

  const customOutboundSettlementsData = arrOutboundSettlements => arrOutboundSettlements.map(os => ({
    id: os.id,
    label: `${MONTHS[os.month - 1].label} - ${os.year} - ${os.description}`
  }))

  const handleClick = () => {
    const newPayment = {
      outboundSettlementId: optionSelectedOutboundSettlement.id,
      bankAccountId: optionSelected.id,
      description: description.value,
      paymentDate
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENTS_ACTIONS.ADD_PAYMENT({ data: newPayment, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const sendToCreateAtaeBankAccounts = () => {
    const win = window.open(`${SITE.SETTING}${SITE.ATAE_BANK_ACCOUNTS}`, '_blank')
    win.focus()
    close()
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENTS_ACTIONS.GET_BANK_ACCOUNTS(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (bankAccountsResponse) {
      if (bankAccountsResponse.status === 200) {
        setOptions(customBankAccounts(bankAccountsResponse.bankAccountsData))
        setFlag(true)
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: MESSAGES.BASIC_ERROR,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
        cleanFields()
        setTimeout(() => {
          close()
        }, 4000)
      }
    }
  }, [bankAccountsResponse])

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = {
        pageNumber: 1,
        statuses: [1],
        orderByColumn: 'Id',
        orderByDesc: true
      }
      dispatch(PAYMENTS_ACTIONS.GET_OUTBOUND_SETTLEMENT({ data, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [optionSelected])

  useEffect(() => {
    if (outboundSettlementsData) {
      if (outboundSettlementsData.status === 200) {
        const { outboundSettlementsObj } = outboundSettlementsData
        setOutboundSettlements(customOutboundSettlementsData(outboundSettlementsObj))
        setShowForm(true)
      }
    }
  }, [outboundSettlementsData])

  useEffect(() => {
    if (optionSelectedOutboundSettlement) {
      errorControl()
    }
  }, [optionSelectedOutboundSettlement])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: MESSAGES.SETTLEMENT_ALREADY_EXISTS,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      } else {
        cleanFields()
        close()
        successfulResult()
      }
      dispatch(PAYMENTS_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {
      flag
      && (
        <>
          <ModalDataContainer flexDirection='column'>
            <Dropdown
              name='bankingEntity'
              label='Cuenta bancaria'
              placeholder='Seleccionar'
              options={options}
              value={optionSelected}
              onChange={handleDropdownChange}
              color='primary'
              height='small'
              block
            />
            {options.length === 0
            && (
              <TextContainerModal>
                <Text size='medium' weight='regular' align='left' color='error'>
                  * No existen cuentas bancarias por favor agregue al menos una&nbsp;
                  <ItemLink
                    color='tertiary'
                    underline
                    onClick={sendToCreateAtaeBankAccounts}
                  >
                    aquí
                  </ItemLink>
                  .
                </Text>
              </TextContainerModal>
            )}
            {
              optionSelected && showForm
              && (
                <>
                  <Dropdown
                    name='outboundSettlement'
                    label='Liquidaciones de pago aprobadas'
                    placeholder='Seleccionar'
                    options={outboundSettlements}
                    value={optionSelectedOutboundSettlement}
                    onChange={handleDropdownChange}
                    color='primary'
                    height='small'
                    block
                    disabled={outboundSettlements?.length === 0}
                  />
                  <CustomDatePicker
                    name='paymentDate'
                    title='Fecha de pago'
                    color='primary'
                    onChange={setPaymentDate}
                    justifyContentButton='space-between'
                    block
                  />
                  <TextInput
                    label='Descripción'
                    placeholder='Ingrese descripción'
                    name='decription'
                    value={description.value}
                    height='small'
                    color='primary'
                    onChange={handleInputChange}
                  />
                </>
              )
            }
          </ModalDataContainer>
          <ButtonContainer justifyContent='flex-end' modal>
            <SolidButton
              size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
              color='primary'
              text='Guardar'
              onClick={handleClick}
              disabled={showButton}
              block={device === BREAKPOINTS.MOBILE}
            />
          </ButtonContainer>
          <Toast
            title={messageToast.title}
            message={messageToast.message}
            color={messageToast.color}
            icon={messageToast.icon}
            isShowing={isShowing}
            position={TOAST_POSITION.leftTop}
            onClick={() => setIsShowing(false)}
          />
        </>
      )
     }
    </>
  )
}

export default AddPaymentModal

AddPaymentModal.propTypes = {
  close: PropTypes.func,
  successfulResult: PropTypes.func,
  currentToken: PropTypes.string
}
