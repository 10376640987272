import produce from 'immer'

import { INCOME } from './types'

export const initialState = {
  loading: true,
  error: false,
  incomeLoading: false,
  incomeList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  modalData: null,
  messageInformation: null,
  loadingModalData: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case INCOME.GET:
      draft.incomeLoading = true
      break
    case INCOME.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.loading = false
      draft.incomeLoading = false
      draft.incomeList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case INCOME.ERROR:
      draft.loading = false
      draft.incomeLoading = false
      draft.error = payload
      break
    case INCOME.CLEAR:
      return initialState
    // FILTER
    case INCOME.SEND_FILTER:
      draft.loading = true
      break
    case INCOME.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case INCOME.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case INCOME.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case INCOME.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case INCOME.GET_MODAL_DATA:
      draft.loadingModalData = true
      break
    case INCOME.MODAL_DATA_INFORMATION:
      draft.modalData = payload
      draft.loadingModalData = false
      break
    case INCOME.ADD_DEPOSIT:
      draft.incomeLoading = true
      break
    case INCOME.ADD_DEPOSIT_INFORMATION:
      draft.messageInformation = payload
      draft.incomeLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
