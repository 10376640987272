export const key = 'PAYMENT_SETTLEMENTS'

export const PAYMENT_SETTLEMENTS = {
  GET: 'PAYMENT_SETTLEMENTS/GET_LIST',
  SUCCESS: 'PAYMENT_SETTLEMENTS/GET_LIST/SUCCESS',
  ERROR: 'PAYMENT_SETTLEMENTS/GET_LIST/ERROR',
  CLEAR: 'PAYMENT_SETTLEMENTS/CLEAR_LIST',

  SEND_FILTER: 'PAYMENT_SETTLEMENTS/FILTER/SEND',
  GET_FILTER_DATA: 'PAYMENT_SETTLEMENTS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'PAYMENT_SETTLEMENTS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'PAYMENT_SETTLEMENTS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'PAYMENT_SETTLEMENTS/FILTER/CLEAR',

  ADD_PAYMENT_SETTLEMENT: 'PAYMENT_SETTLEMENTS/PAYMENT_SETTLEMENT/ADD',
  ADD_PAYMENT_SETTLEMENT_INFORMATION: 'PAYMENT_SETTLEMENTS/PAYMENT_SETTLEMENT/ADD_INFORMATION',
  GET_PAYMENT_SETTLEMENTS_STATUSES: 'PAYMENT_SETTLEMENTS/GET_STATUSES',
  PAYMENT_SETTLEMENTS_STATUSES_INFORMATION: 'PAYMENT_SETTLEMENTS/STATUSES/INFORMATION',
  CLEAR_MESSAGE_INFORMATION: 'PAYMENT_SETTLEMENTS/SETTLEMENTS/CLEAR_MESSAGE_INFORMATION',
  PAYMENT_SETTLEMENTS_UPDATED: 'PAYMENT_SETTLEMENTS/SETTLEMENTS/PAYMENT_SETTLEMENTS_UPDATED'
}

export default key
