import { semibold, bold, WEIGHT } from '@global/fonts'
import { colors, spacing } from '@global/theme'

export const handleType = (color, isFocus, isDisabled) => {
  const defaultColor = color || 'system'

  const disabledState = {
    backgroundColor: `${colors.background.disabled}`,
    color: `${colors[defaultColor].disabled}`,
    border: `${spacing.quarter} solid ${colors[defaultColor].disabled}`
  }

  const focusState = {
    backgroundColor: `${colors[defaultColor].darker}`,
    color: `${colors[defaultColor].focus}`,
    borderColor: `${colors[defaultColor].focus}`,
    ':hover': {
      borderColor: `${colors[defaultColor].hover}`
    }
  }

  const regularState = {
    backgroundColor: `${colors.background.regular}`,
    ':hover': {
      borderColor: `${colors[defaultColor].hover}`,
      backgroundColor: `${colors[defaultColor].darker}`
    }
  }

  if (isDisabled) return disabledState
  if (isFocus) return focusState
  return regularState
}

export const SelectStyles = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  control: (provided, state) => {
    const { color } = state.selectProps
    const { isFocused, isDisabled } = state

    return {
      ...provided,
      border: `${spacing.quarter} solid ${colors.system.primary}`,
      borderRadius: '5px',
      boxSizing: 'border-box',
      boxShadow: 'none',
      cursor: 'pointer',
      height: `${spacing.threeAndAHalf}`,
      color: color ? `${colors[color].regular}` : 'system',
      ...(handleType(color, isFocused, isDisabled))
    }
  },
  menu: provided => ({
    ...provided,
    minWidth: 'fit-content'
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none'
  }),
  placeholder: provided => ({
    ...provided,
    color: colors.system.regular,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: semibold
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: semibold
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${colors.tertiary.regular}`,
    padding: '8px 16px 8px 8px'
  }),
  option: (provided, { isFocused, isSelected, selectProps }) => {
    const defaultColor = selectProps.color || 'contrast'

    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'space-between',
      fontFamily: 'Roboto',
      fontSize: '14px',
      padding: `${spacing.one} ${spacing.two} ${spacing.one} ${spacing.two}`,
      backgroundColor: isFocused ? `${colors.background.hover}` : `${colors.background.regular}`,
      color: isFocused || isSelected ? `${colors[defaultColor].regular}` : `${colors.contrast.regular}`,
      fontWeight: isFocused && defaultColor === 'contrast' ? semibold : null
    }
  },
  multiValue: (provided, { selectProps }) => {
    const defaultColor = selectProps.color || 'contrast'
    return {
      ...provided,
      width: '101px',
      height: `${spacing.four}`,
      fontFamily: 'Roboto',
      fontSize: `${spacing.two}`,
      fontWeight: `${WEIGHT.REGULAR}`,
      borderRadius: '5px',
      backgroundColor: colors.background.primary,
      border: `1px solid ${colors[defaultColor].regular}`,
      boxSizing: 'border-box',
      color: colors.background.primary,
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  multiValueRemove: (provided, { selectProps }) => {
    const defaultColor = selectProps.color || 'contrast'

    return {
      ...provided,
      color: colors.system.regular,
      ':hover': {
        color:
        defaultColor === 'contrast' ? `${colors[defaultColor].regular}` : `${colors[defaultColor].hover}`
      }
    }
  },
  noOptionsMessage: (provided, { selectProps }) => {
    const defaultColor = selectProps.color || 'contrast'

    return {
      ...provided,
      color: `${colors[defaultColor].regular}`,
      fontWeight: bold,
      fontFamily: 'Roboto',
      fontSize: `${spacing.oneAndAHalf}`
    }
  }
}
