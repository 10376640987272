import React, { useRef } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { SITE } from '@routes/paths'

import Home from '@containers/home'
import Person from '@containers/person'
import AffiliateRecord from '@containers/affiliateRecord'
import FrmRecord from '@containers/frmRecord'
import HistoryContainer from '@containers/history'
import HistoryLog from '@containers/historyLog'
import EmployeeHistory from '@containers/employeeHistory'
import LiquidationProfile from '@containers/liquidationProfile'
import ContributionProfile from '@containers/contributionProfile'
import EmploymentRecordProfile from '@containers/employmentRecord'
import ContributionPayment from '@containers/contributionPayment'
import PaymentDetail from '@containers/paymentDetail'
import Services from '@containers/serviceProvider'
import ServiceProviderProfile from '@containers/serviceProviderProfile'
import DebtProfile from '@containers/debtProfile'
import PaymentSettlementProfile from '@containers/paymentSettlementProfile'
import DetailsList from '@containers/detailsList'

import { useWindowDimensions } from '@components/windowDimensions'
import Login from '@components/authentication/components/login'
import PasswordRecovery from '@components/authentication/components/passwordRecovery'
import PasswordConfirm from '@components/authentication/components/passwordConfirm'
import { useUser } from '@components/authentication/utils/hook'

import PrivateRoute from '@routes/utils/privateRoute'
import PublicRoute from './utils/publicRoute'

import { Container, Page } from './styles'
import Nav from './components/nav'

import { PeopleRouter } from './utils/customRouters/PeopleRouter'
import { SettingRouter } from './utils/customRouters/SettingRouter'
import { FinanceRouter } from './utils/customRouters/FinanceRouter'
import Header from './components/header'

export const App = () => {
  const { device } = useWindowDimensions()
  const { GetUser, ErrorRefreshToken } = useUser()
  const user = GetUser()
  const errorRefreshToken = ErrorRefreshToken()
  const navRef = useRef()

  const handleShowNav = () => {
    navRef.current?.showNavFromParent()
  }

  const background = !user && !errorRefreshToken

  return (
    <Container background={background} name='container' device={device}>
      {user && (
        <>
          <Nav user={user} ref={navRef} />
          <Header device={device} showSidebar={handleShowNav} />
        </>
      )}
      <Switch>
        <PublicRoute path={SITE.SIGN_IN} component={Login} />
        <PublicRoute path={SITE.PASSWORD_RECOVERY} component={PasswordRecovery} />
        <PublicRoute path={SITE.PASSWORD_CONFIRM} component={PasswordConfirm} />
        <Route exact path='/'>
          <Redirect to={SITE.SIGN_IN} />
        </Route>

        <Page scroll={user} id='page' device={device}>
          <PrivateRoute path={SITE.HOME} component={Home} />

          <PrivateRoute path={SITE.PEOPLE} component={PeopleRouter} />
          <PrivateRoute path={SITE.SETTING} component={SettingRouter} />
          <PrivateRoute path={SITE.FINANCE} component={FinanceRouter} />

          <PrivateRoute path={`${SITE.HISTORY}/:path`} component={HistoryContainer} />
          <PrivateRoute path={`${SITE.HISTORY_LOG}/:path`} component={HistoryLog} />
          <PrivateRoute path={`${SITE.EMPLOYEE_HISTORY}/:path`} component={EmployeeHistory} />

          <PrivateRoute exact path={SITE.SERVICES} component={Services} />
          <PrivateRoute
            exact
            path={`${SITE.SERVICES}${SITE.SERVICE_PROVIDER_PROFILE}/:serviceProviderProfileId`}
            component={ServiceProviderProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.SERVICE_PROVIDER_PROFILE}/:serviceProviderProfileId`}
            component={ServiceProviderProfile}
          />

          <PrivateRoute
            exact
            path={`${SITE.PAYMENT_DETAIL}/:paymentDetailId`}
            component={PaymentDetail}
          />
          <PrivateRoute
            exact
            path={`${SITE.EMPLOYMENT_RECORD}/:employmentRecordId`}
            component={EmploymentRecordProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.PERSON}/:personId${SITE.EMPLOYMENT_RECORD}/:employmentRecordId`}
            component={EmploymentRecordProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.AFFILIATE_RECORD}/:recordId`}
            component={AffiliateRecord}
          />
          <PrivateRoute
            exact
            path={`${SITE.FRM_RECORD}/:recordId`}
            component={FrmRecord}
          />
          <PrivateRoute exact path={`${SITE.PERSON}/:personId`} component={Person} />
          <PrivateRoute
            exact
            path={`${SITE.CONTRIBUTION_PROFILE}/:contributionProfileId`}
            component={ContributionProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.LIQUIDATION_LOG}/:liquidationId`}
            component={LiquidationProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.CONTRIBUTION_PAYMENT}/:contributionPaymentId`}
            component={ContributionPayment}
          />
          <PrivateRoute
            exact
            path={`${SITE.DEBT_PROFILE}/:debtProfileId`}
            component={DebtProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.PAYMENT_SETTLEMENT_PROFILE}/:paymentSettlementProfileId`}
            component={PaymentSettlementProfile}
          />
          <PrivateRoute
            exact
            path={`${SITE.DETAILS_LIST}/:detailId/:type`}
            component={DetailsList}
          />
        </Page>
      </Switch>
    </Container>
  )
}

export default App
