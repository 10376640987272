import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BREAKPOINTS, TOAST_POSITION, TOAST_PROPERTIES, TRANSLATION_OF_ROUTES, rowsPerPage } from '@global/constants'
import {
  FilterContainer,
  FilterContainerRightSide,
  PaginationWrapper,
  TableWrapper,
  WrapperBreadcrumbs,
  WrapperHeading,
  WrapperLoading
} from '@global/styles'
import { TOKEN_HELPERS, filterOrderBuilder, handleClickPaginationItem, sortByKey } from '@utils/helpers'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import { useUser } from '@components/authentication/utils/hook'
import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import { Heading } from '@components/texts'
import Toast from '@components/toast'
import { GhostButton } from '@components/button'
import { useWindowDimensions } from '@components/windowDimensions'
import Table from '@components/table'
import Badge from '@components/badge'
import { BASIC_INPUT_TYPES } from '@components/inputs/utils/constants'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { NO_EMPLOYEE_FEE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { FormContainer } from './styles'
import { COLUMN_DATA } from './data'

const INITIAL_STATE_INPUT = {
  name: '',
  value: '',
  error: 'error'
}

const NoEmployeeFeePage = () => {
  useRedux()
  const dispatch = useDispatch()
  const { GetToken, Logout } = useUser()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [lastFee, setLastFee] = useState('N/A')
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [newFee, setNewFee] = useState(true)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [feeValue, setFeeValue] = useState(INITIAL_STATE_INPUT)
  const [activeFilters, setActiveFilters] = useState(null)
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)

  const loading = useSelector(SELECTORS.LOADING)
  const historyFeeData = useSelector(SELECTORS.FEE_HISTORY_SUCCESS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const messageError = useSelector(SELECTORS.MESSAGE_ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getFeeHistory = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(NO_EMPLOYEE_FEE_ACTIONS.GET_FEE_HISTORY({ token, data: body }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleInputFeeChange = val => setFeeValue(val)

  const handleNewFee = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = {
        percentage: feeValue.value
      }
      dispatch(NO_EMPLOYEE_FEE_ACTIONS.CREATE_NEW_FEE({ token, data }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => col.name)
    orderedColumns.pop()
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }
    return customColumns
  }

  const customBodyDataTable = bodyData => bodyData.map(item => ({
    id: item.id,
    code: item.id,
    percentage: `${item.percentage}%`,
    date: item.date
  }))

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getFeeHistory(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getFeeHistory(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(COLUMN_DATA).find(col => col[1].name === nameColumn)

    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames[0]?.toLowerCase(),
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getFeeHistory(body)
  }

  useEffect(() => {
    const body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: true
    }
    setActiveFilters(body)
    getFeeHistory(body)
    const fisrtCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.SETTING,
      url: SITE.SETTING
    }
    const secondCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.ENTITY_MANAGMENT,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}`
    }
    const thirdCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.NO_EMPLOYEE_FEE,
      url: ''
    }
    setBreadcrumbs([fisrtCrumb, secondCrumb, thirdCrumb])

    return () => {
      setBreadcrumbs([])
    }
  }, [])

  useEffect(() => {
    if (historyFeeData) {
      if (newFee) {
        setLastFee(historyFeeData?.items[0]?.percentage ?? null)
        setNewFee(false)
      }
      setPages(historyFeeData.totalPages)
      setTotalItems(historyFeeData.totalItems)
      setItemSelected(historyFeeData.currentPage - 1)
      setTablelist(customBodyDataTable(historyFeeData.items))
      setColumns(customHeaderDataTable(historyFeeData.fields, COLUMN_DATA))
    }
  }, [historyFeeData])

  useEffect(() => {
    if (messageInformation) {
      setMessageToast({
        title: TOAST_PROPERTIES.SUCCESS.title,
        message: messageInformation.message || MESSAGES.GENERIC_SUCCESSFUL_CREATION,
        icon: TOAST_PROPERTIES.SUCCESS.icon,
        color: TOAST_PROPERTIES.SUCCESS.color
      })
      setIsShowing(true)
      setNewFee(true)
      setFeeValue(INITIAL_STATE_INPUT)
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      getFeeHistory(body)
    }
  }, [messageInformation])

  useEffect(() => {
    if (messageError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: messageInformation?.message || messageInformation?.status || MESSAGES.GENERIC_ERROR,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
    }
  }, [messageError])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    loading
      ? (
        <WrapperLoading>
          <Loading color='primary' size='xsmall' weight='bold' />
        </WrapperLoading>
      )
      : (
        <>
          {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
          <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
            <Heading
              type='h2'
              weight='bold'
              align='left'
              color='system'
            >
              {TRANSLATION_OF_ROUTES.NO_EMPLOYEE_FEE}
            </Heading>
          </WrapperHeading>
          <FilterContainer
            columnStart={1}
            columnEnd={12}
            rowStart={3}
            rowEnd={3}
            deviceName={deviceName}
            alignItems='flex-end'
          >
            <FormContainer deviceName={deviceName}>
              <Badge
                text={`Último ajuste aplicado: ${lastFee}`}
                icon='percent'
                backgroundColor='primary'
                size='medium'
              />
              <Input
                type={BASIC_INPUT_TYPES.NUMBER}
                name='nameFrmCategory'
                label='Nuevo ajuste'
                value={feeValue.value}
                height='small'
                color='primary'
                onChange={handleInputFeeChange}
                onBlur={() => {}}
                bits={[{ icon: 'percent', position: 'right', colorBit: 'tertiary' }]}
              />
              <GhostButton
                color='tertiary'
                size='small'
                text='Nuevo Aporte'
                icon='add'
                onClick={handleNewFee}
                disabled={feeValue.error}
                block={device === BREAKPOINTS.MOBILE}
              />
            </FormContainer>
            <FilterContainerRightSide deviceName={deviceName}>
              <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
            </FilterContainerRightSide>
          </FilterContainer>
          <TableWrapper columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
            {renderTable({
              currentList: tablelist,
              columnsN: columns,
              currentLoading: loadingList,
              handleOrder: handleOrderChange,
              error: false
            })}
          </TableWrapper>
          <PaginationWrapper rowStart='5' rowEnd='5' columnStart='1' columnEnd='12'>
            {tablelist.length > 0
              && (
              <PaginationGroup
                deviceName={deviceName}
                pages={pages}
                itemSelected={itemSelected}
                handleClickPaginationItem={n => handleClickPaginationItem(
                  n, activeFilters, selectedOption.value, getFeeHistory, setItemSelected
                )}
                options={rowsPerPage}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
                totalItems={totalItems}
                itemsPerPage={tablelist.length}
              />
              )}
          </PaginationWrapper>
          <Toast
            title={messageToast.title}
            message={messageToast.message}
            color={messageToast.color}
            icon={messageToast.icon}
            isShowing={isShowing}
            position={TOAST_POSITION.rightTop}
            onClick={handleToastClose}
          />
        </>
      )
  )
}

export default NoEmployeeFeePage
