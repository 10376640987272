export const columnData = [
  { name: 'Código', widthCol: '5%' },
  { name: 'Fecha emisión', widthCol: '20%' },
  { name: 'Liquidación de pago', widthCol: '20%' },
  { name: 'Moneda', widthCol: '10%' },
  { name: 'Monto', widthCol: '10%' },
  { name: 'Estado', widthCol: '10%' },
  { name: 'Fecha de Pago', widthCol: '20%' },
  { name: 'Registro de pago', widthCol: '15%' },
  { name: 'Monto pagado', widthCol: '15%' }
]
