import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { USERS_ACTIONS } from './actions'
import { USERS } from './types'

const apiCalls = {
  getStatuses: token => (
    Axios('AffiliateRecord/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getData: ({ data, token }) => (
    Axios.post('Account/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addUser: ({ data, token }) => (
    Axios.post('Account/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getStatuses ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getStatuses, payload)

    yield put(USERS_ACTIONS.GET_STATUSES_SUCCESS(statuses))
  } catch (error) {
    yield put(USERS_ACTIONS.GET_STATUSES_ERROR(error))
  }
}

export function* getUsers ({ payload }) {
  try {
    const { data: items } = yield call(apiCalls.getData, payload)

    yield put(USERS_ACTIONS.SUCCESS(items))
  } catch (error) {
    yield put(USERS_ACTIONS.USERS_ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(USERS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(USERS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* addNewUser ({ payload }) {
  try {
    const { status } = yield call(apiCalls.addUser, payload)
    yield put(USERS_ACTIONS.ADD_USER_INFORMATION({ status }))
  } catch (error) {
    yield put(USERS_ACTIONS.ADD_USER_INFORMATION(error))
  }
}
export default function* saga () {
  yield takeLatest(USERS.GET_STATUSES, getStatuses)
  yield takeLatest(USERS.GET, getUsers)
  yield takeLatest(USERS.SEND_FILTER, getUsers)
  yield takeLatest(USERS.GET_FILTER_DATA, FilterData)
  yield takeLatest(USERS.ADD_USER, addNewUser)
}
