import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'
import { MyButtonLink } from '@global/styles'

export const HeaderContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'baseline'};
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  flex: ${({ flex }) => flex || 0};
  flex-wrap: wrap;
  margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};

  grid-column-start: 1;
  grid-column-end: 9; 
  grid-row-start: 2;
  grid-row-end: 2;

  & > p {
    margin-right: 16px;
  }
`
export const BillingNumberContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: baseline;
  flex: 2;

  & > :first-child {
    color: #4F4F4F;
  }
  
  & > :last-child {
    margin-left: 8px !important;
  }
`

export const DataContainer = styled.div`
  width: 100%;
  background-color: ${colors.background.regular};
  padding: ${spacing.two};
  border-radius: ${spacing.one};

  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);

  grid-column-start: 1;
  grid-column-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 9)};
  grid-row-start: 3;
  grid-row-end: 3;

  margin-bottom: ${({ device }) => (device < BREAKPOINTS.TABLET ? spacing.three : 0)};
`

export const PhoneContainer = styled(DataContainer)`
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 8 : 7)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 8 : 7)};
  margin-bottom: 0;
`

export const AddressContainer = styled(DataContainer)`
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 6 : 5)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 6 : 5)};
  margin-bottom: 0;
`

export const EmailContainer = styled(DataContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 10 : 9)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 10 : 9)};
  margin-bottom: 0;
`

export const BankAccountContainer = styled(DataContainer)`
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 11)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 11)};
  grid-column-start: 1;
  grid-column-end: 12;
`

export const AtaeContainer = styled(DataContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 13 : 12)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 13 : 12)};
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom || 0)};
  flex-wrap: wrap; 
`

export const FrmContainer = styled(DataContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 14 : 13)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 14 : 13)};
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom || 0)};
  flex-wrap: wrap; 
  flex: 2;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
`

export const StatusDataContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'stretch')};
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  flex-wrap: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'nowrap' : 'wrap')};
  flex: 2;
  margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
`

export const StatusDataContainerFrm = styled(StatusDataContainer)`
  align-items: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'stretch')};
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  flex-wrap: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'nowrap' : 'wrap')};
  margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};

  & > :first-child {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.four)};
  }

  & > :nth-child(2) {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.one : spacing.four)};
    justify-content: flex-start;
  }

  & > :nth-child(5) {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.one : spacing.four)};
    justify-content: flex-start;
  }

  & > :last-child {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.one : spacing.four)};
    justify-content: flex-start;
  }
`

export const PersonalData = styled.table`
  ${roboto};
  ${regular};
  width: 100%;
  height: auto;
  flex: 2;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: available;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '35%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`
export const LinkContainer = styled.div`
  height: 100%;
  width: ${({ device }) => (device < BREAKPOINTS.TABLET ? '100%' : null)};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'flex-end')};
  margin-top: ${({ device }) => (device === BREAKPOINTS.TABLET_PORTRAIT ? spacing.one : 0)};

  align-self: end;
  flex: 1;
`

export const DataItem = styled.div`
  position: relative;
  width: ${({ width }) => width || 'fit-content'};
  min-width: ${({ minWidth }) => minWidth || 'min-content'};
  background-color: ${({ backgroundWhite }) => (
    backgroundWhite ? colors.background.regular : colors.background.secondary)};
  padding: ${({ padding }) => padding || 0};
  margin: ${spacing.one} 0;
  margin-right: ${({ marginRight }) => marginRight && spacing.three};
  border-radius: ${spacing.one};
  ${({ withoutBorder, backgroundWhite }) => (
    withoutBorder ? 'none' : `border: 1px solid ${backgroundWhite ? colors.background.regular : '#E8E8E8'}`)
};

  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  flex-wrap: ${({ flexWrap }) => (flexWrap || 'wrap')};

  & > p {
    margin-bottom: ${spacing.one};
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-right: ${({ marginRight }) => (marginRight || 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom || 0)};
  margin-left: ${({ marginLeft }) => (marginLeft || 0)};

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex: 1;
  grid-column-start: 1;
  grid-column-end: 9;
  ${({ buttonColor }) => buttonColor && (`> button { background-color: ${colors.background.regular}}`)}

  > span, a {
      margin-right: ${({ marginRight }) => marginRight || 0};
      cursor: pointer;
  }


  & > div {
    margin-right: ${spacing.twoAndAHalf};
    
  }

  ${({ marginRightLastChild }) => marginRightLastChild
  && (`& > :first-child {
    margin-right: ${spacing.two};
  }`
  )}
`

export const ButtonContainerStyle = styled(ButtonContainer)`
  justify-content: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'flex-end')};
`

export const ButtonContainerAddress = styled(ButtonContainer)`
  grid-column-start: 1;
  grid-column-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 9)} ;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 5 : 4)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 5 : 4)};
`

export const ButtonContainerPhones = styled(ButtonContainer)`
  grid-column-start: 1;
  grid-column-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 9)} ;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 7 : 6)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 7 : 6)};
`

export const ButtonContainerEmails = styled(ButtonContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 9 : 8)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 9 : 8)};
`

export const ButtonContainerBanksAccounts = styled(ButtonContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 11 : 10)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 11 : 10)};
`

export const ButtonContainerFile = styled(ButtonContainer)`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 19 : 17)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 19 : 17)};
`

export const ButtonContainerEmployee = styled(ButtonContainer)`
  ${({ twoBadge }) => (
    twoBadge
      ? `& > :first-child {background-color: ${colors.error.hover}; p {color: ${colors.error.regular}!important;}}`
      : null)}
  
  & > :nth-child(1) {
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
    margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE && 0)};
  }

  & > :nth-child(2) {
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.two : 0)};
    margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE && 0)};
  }
`

export const TableContainerFile = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 15;
  grid-row-end: 15;
`

export const NotesContainer = styled.div`
  width: 100%;
  background-color: ${colors.background.regular};
  border-radius: ${spacing.one};
  padding: ${spacing.three} ${spacing.two};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);

  display: flex;
  flex-direction:  ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return 'column-reverse'
    if (device < BREAKPOINTS.TABLET) return 'row'
    return 'column'
  }};
  justify-content: space-between;
  align-items: flex-start;

  align-self: stretch;

  grid-column-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 1 : 9)};
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 4 : 2)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 4 : 8)};

`

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: ${spacing.oneAndAHalf} 0;
  flex: 2;
  overflow-y: auto;
  max-height: 600px;
`

export const NoteItem = styled.div`
  width: 100%;
  background-color: ${colors.background.secondary};
  margin-bottom: ${spacing.oneAndAHalf};
  border-radius: 8px;
  border: 1px solid ${colors.background.primary};
  padding: ${spacing.oneAndAHalf};
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    &:hover {
      color: ${colors.primary.hover};
    }
  }
`

export const NoteItemHeader = styled.div`
  width: 100%;
  max-height: fit-content;
  background-color: ${colors.background.secondary};
  margin-bottom: ${spacing.one};

  display: flex;
  justify-content: space-between;

  > :last-child {
    margin-left: ${spacing.quarter};
    cursor: pointer;
  }
`

export const NoteItemBody = styled.div`
  width: 100%;
  max-height: ${spacing.nineAndAHalf};
  overflow-y: auto;
`

export const DateContainer = styled.div`
  width: 100%;
  background-color: ${colors.background.secondary};
  margin-bottom: ${spacing.oneAndAHalf};

  display: flex;
  justify-content: flex-start;

  > :last-child {
      margin-left: ${spacing.one};
  }
`

export const AddNoteContainer = styled.div`
  width: 100%;
  margin-top: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.two)};
  margin-left: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return 0
    if (device < BREAKPOINTS.TABLET) return spacing.two
    return 0
  }};

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & textarea {
    resize: none;
  }
`

export const TextContainer = styled.div`
  width: fit-content;

  display: flex;
  justify-content: flex-start;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  & > :first-child {
    color: ${colors.grays.regular};
    margin-right: ${spacing.one};
  } 
  & > :last-child {
    color: ${colors.grays.dark};
    margin-right: ${spacing.one};
  } 
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device, file }) => {
    if (file) return 'column'
    return (device < BREAKPOINTS.DESKTOP ? 'column' : 'row')
  }};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  margin-bottom: ${spacing.two};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }

  ${({ marginFirstChild, device }) => marginFirstChild
    && (`& > :first-child {
      margin-top: ${spacing.one};
      margin-bottom: ${device < BREAKPOINTS.DESKTOP ? 0 : spacing.two};
      margin-right: ${spacing.two};
      width: ${device < BREAKPOINTS.DESKTOP ? '100%' : null};
    }`)}

  & > button {
    align-self: end;
  }

  & > div {
    width: 100%;
  }
`

export const RigthModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: ${({ device }) => (device < BREAKPOINTS.DESKTOP ? `0 0 0 ${spacing.two}` : 0)};

  & > div {
    width: 100%;
  }
`

export const DataFileContainer = styled.div`
  width: 100%;
  height: ${({ showLoader }) => (showLoader ? '256px' : 'auto')};
  display: flex;
  flex-direction: ${({ device, direction }) => (device === BREAKPOINTS.MOBILE || direction ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;

  padding: ${spacing.three};
  border: 2px solid ${colors.system.disabled};

`

export const FileUploaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device, direction }) => (device === BREAKPOINTS.MOBILE || direction ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  flex: 3;
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const Top = styled.div`
  width: 100%;
`

export const MyLabel = styled.label`
  ${roboto}
  margin: ${spacing.one} 0;
  color: ${colors.contrast.regular};
`

export const TextContainerCP = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 15 : 14)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 15 : 14)};
  margin-top: ${spacing.three};
`

export const TableContainerAffRecCP = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 18 : 16)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 18 : 16)};
`

export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  margin-right: ${spacing.half};
`
