export const key = 'USER_PROFILE'

export const USER_PROFILE = {
  GET_USER: 'USER_PROFILE/GET_USER',
  GET_USER_SUCCESS: 'USER_PROFILE/GET_USER_SUCCESS',
  GET_USER_ERROR: 'USER_PROFILE/GET_USER_ERROR',
  CLEAR: 'USER_PROFILE/CLEAR',
  SET_PASSWORD: 'USER_PROFILE/SET_PASSWORD',
  SET_PASSWORD_INFORMATION: 'USER_PROFILE/SET_PASSWORD_INFORMATION',
  CLEAR_PASSWORD_INFORMATION: 'USER_PROFILE/CLEAR_PASSWORD_INFORMATION',
  EDIT_USER_PROFILE: 'USER_PROFILE/EDIT_USER_PROFILE',
  SET_EDIT_USER_PROFILE: 'USER_PROFILE/SET_EDIT_USER_PROFILE',
  SET_PROFILE_INFORMATION: 'USER_PROFILE/SET_PROFILE_INFORMATION',
  SET_NEW_DATA_PROFILE: 'USER_PROFILE/SET_NEW_DATA_PROFILE',
  CLEAR_PROFILE_INFORMATION: 'USER_PROFILE/CLEAR_PROFILE_INFORMATION'
}

export default key
