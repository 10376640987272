import produce from 'immer'

import { DEPOSIT_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  depositProfile: null,
  depositProfileStatuses: null,
  depositProfileOrigins: null,
  loadingEditProfile: false,
  contributionsPaymentsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  listLoading: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  modalDataLoading: false,
  modalData: null,
  messageInformation: null,
  depositProfileContributionsLoading: null,
  depositProfileContributionsInformation: null,
  currencies: null,
  isChangingStatusInfo: null,
  personConceptData: null,
  conceptLoading: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case DEPOSIT_PROFILE.GET_DEPOSIT_PROFILE:
      draft.loading = true
      break
    case DEPOSIT_PROFILE.SUCCESS: {
      const { depositProfileObj, statuses, sources } = payload
      draft.loading = false
      draft.depositProfile = depositProfileObj
      draft.depositProfileStatuses = statuses
      draft.depositProfileOrigins = sources
      break
    }
    case DEPOSIT_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case DEPOSIT_PROFILE.EDIT_DEPOSIT_PROFILE:
      draft.loadingEditProfile = true
      break
    case DEPOSIT_PROFILE.EDIT_DEPOSIT_PROFILE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingEditProfile = false
      break
    case DEPOSIT_PROFILE.DEPOSIT_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.depositProfile = payload
      break
    case DEPOSIT_PROFILE.CLEAR:
      return initialState
    case DEPOSIT_PROFILE.GET_CONTRIBUTIONS_PAYMENTS:
      draft.listLoading = true
      break
    case DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.listLoading = false
      draft.contributionsPaymentsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_ERROR:
      draft.listLoading = false
      draft.error = payload
      break
    case DEPOSIT_PROFILE.CONTRIBUTIONS_PAYMENTS_UPDATE:
      draft.listLoading = true
      break
    case DEPOSIT_PROFILE.SEND_FILTER:
      draft.loading = true
      break
    case DEPOSIT_PROFILE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case DEPOSIT_PROFILE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case DEPOSIT_PROFILE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case DEPOSIT_PROFILE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // MODAL
    case DEPOSIT_PROFILE.GET_MODAL_DATA:
      draft.modalDataLoading = true
      break
    case DEPOSIT_PROFILE.MODAL_DATA_INFORMATION:
      draft.modalDataLoading = false
      draft.modalData = payload
      break
    // CONTRIBUTION_PAYMENT
    case DEPOSIT_PROFILE.ADD_CONTRIBUTION_PAYMENT:
      draft.listLoading = true
      break
    case DEPOSIT_PROFILE.ADD_CONTRIBUTION_PAYMENT_INFORMATION:
      draft.messageInformation = payload
      break
    // CONTRIBUTIONS
    case DEPOSIT_PROFILE.DEPOSIT_PROFILE_GET_CONTRIBUTION:
      draft.modalData = true
      draft.depositProfileContributionsLoading = true
      break
    case DEPOSIT_PROFILE.DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION:
      draft.modalData = false
      draft.depositProfileContributionsLoading = false
      draft.depositProfileContributionsInformation = payload
      break
    case DEPOSIT_PROFILE.CURRENCIES_INFORMATION:
      draft.currencies = payload
      break
    // MESSAGE
    case DEPOSIT_PROFILE.MESSAGE_INFORMATION_CLEAR:
      draft.messageInformation = null
      draft.loadingEditProfile = false
      draft.listLoading = false
      break
    // PENDING PROCESS
    case DEPOSIT_PROFILE.PENDING_PROCESS_INFO:
      draft.isChangingStatusInfo = payload
      break
    case DEPOSIT_PROFILE.GET_PENDING_PROCESS_INFO:
      draft.isChangingStatusInfo = payload
      break
    case DEPOSIT_PROFILE.GET_PERSON_CONCEPTS:
      draft.conceptLoading = true
      break
    case DEPOSIT_PROFILE.PERSON_CONCEPT_DATA_INFORMATION:
      draft.personConceptData = payload
      draft.conceptLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
