export const key = 'BENEFITS'

export const BENEFITS = {
  GET: 'BENEFITS/GET_LIST',
  SUCCESS: 'BENEFITS/GET_LIST/SUCCESS',
  ERROR: 'BENEFITS/GET_LIST/ERROR',
  CLEAR: 'BENEFITS/CLEAR_LIST',

  SEND_FILTER: 'BENEFITS/FILTER/SEND',
  GET_FILTER_DATA: 'BENEFITS/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'BENEFITS/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'BENEFITS/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'BENEFITS/FILTER/CLEAR',

  SET_BENEFIT: 'BENEFITS/SET_BENEFIT',
  BENEFIT_INFORMATION: 'BENEFITS/BENEFIT_INFORMATION',
  BENEFIT_INFORMATION_CLEAR: 'BENEFITS/BENEFIT_INFORMATION_CLEAR',
  GET_BENEFIT_TYPES: 'BENEFITS/GET_BENEFIT_TYPES',
  BENEFIT_TYPES_SUCCESS: 'BENEFITS/BENEFIT_TYPES_SUCCESS',
  BENEFIT_TYPES_ERROR: 'BENEFITS/BENEFIT_TYPES_ERROR'
}

export default key
