import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_POSITION, TOAST_PROPERTIES, rowsPerPage, BREAKPOINTS } from '@global/constants'
import {
  TOKEN_HELPERS,
  handleClickPaginationItem,
  sortByKey,
  customBankAccounts,
  formatDateBasic,
  filterOrderBuilder,
  currencyFormatUY
} from '@utils/helpers'
import { MESSAGES } from '@global/message'
import {
  ButtonContainer,
  TextContainerModal
} from '@global/styles'
import { spacing } from '@global/theme'
import { SITE } from '@routes/paths'

import { SolidButton } from '@components/button'
import PaginationGroup from '@components/paginationGroup'
import Table from '@components/table'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { Text } from '@components/texts'
import SearchBar from '@components/searchBar'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PAYMENT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import {
  TableContainer,
  FormContainer,
  InputContainer,
  FilterContainer,
  ModalDataContainer,
  TextErrorContainer,
  CustomButtonLink
} from '../../styles'
import { columnDataModal } from '../../data'

const AddPaymentDetailModal = ({ close, saveData, currencyId, outboundSettlementId, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { deviceName, device } = useWindowDimensions()

  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [description, setDescription] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [amount, setAmount] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [optionSelectedBankAccount, setOptionSelectedBankAccount] = useState()
  const [optionsBankAccount, setOptionsBankAccount] = useState([])
  const [detail, setDetail] = useState({
    id: 0,
    value: ''
  })
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorMessage, setErrorMessage] = useState()
  const [personNumber, setPersonNumber] = useState()

  const outboundSettlementDetailLoading = useSelector(SELECTORS.OUTBOUND_SETTLEMENT_DETAIL_LOADING)
  const outboundSettlementDetailData = useSelector(SELECTORS.OUTBOUND_SETTLEMENT_DETAIL_INFORMATION)
  const bankAccountsData = useSelector(SELECTORS.BANK_ACCOUNTS)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getOutboundSettlementDetail = body => {
    body = {
      ...body,
      statuses: [1],
      currencyId,
      outboundSettlementId
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_OUTBOUND_SETTLEMENT_DETAIL({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const errorControl = () => {
    if (detail.value
        && (amount.value && !amount.error)
        && optionSelectedBankAccount) setShowButton(false)
    else setShowButton(true)
  }

  const handleInputChange = val => setDescription(val)
  const handleInputChange2 = val => setAmount(val)

  const handleRowClic = detailObj => {
    setOptionSelectedBankAccount(null)
    const detailId = detailObj.id.split('-')[0]
    const personId = detailObj.id.split('-')[1]
    const serviceId = detailObj.id.split('-')[2]
    setPersonNumber(personId)

    const data = {
      personId,
      serviceId,
      currencyId,
      token: currentToken.token
    }

    dispatch(PAYMENT_PROFILE_ACTIONS.GET_BANK_ACCOUNTS(data))

    setDetail({
      id: detailId,
      value: `${detailId} - ${detailObj.concept}`
    })
    setAmount({
      value: detailObj.total === 'N/A' ? '' : detailObj.total.replace(/[^\d,]/g, '').replace(',', '.'),
      name: '',
      error: ''
    })
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'dropdown') {
      let body = null
      if (activeFilters) {
        body = activeFilters
        body = {
          ...body,
          pageNumber: 1,
          pageSize: value.value
        }
      } else {
        body = {
          pageNumber: 1,
          pageSize: value.value
        }
      }
      getOutboundSettlementDetail(body)
      setSelectedOption(value)
      setItemSelected(0)
    } else setOptionSelectedBankAccount(value)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnDataModal).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getOutboundSettlementDetail(body)
  }

  const handleInputSearchChange = e => {
    let body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: false
    }
    if (e.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    } else delete activeFilters.filterText
    getOutboundSettlementDetail(body)
  }

  const handleClick = () => {
    const outboundPaymentDetail = {
      amount: amount.value,
      description: description.value,
      bankAccountId: optionSelectedBankAccount.id,
      detailId: detail.id
    }
    saveData(outboundPaymentDetail)
    close()
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      if (col.name === 'Entity'
          || col.name === 'PaymentRecord'
          || col.name === 'Month'
          || col.name === 'Year') return
      return col.name
    })
    orderedColumns = orderedColumns.filter(c => c !== undefined)
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      const newItem = {
        id: `${item.id}-${item.personId || ''}-${item.serviceId || ''}`,
        code: item.id,
        personName: item.personName,
        service: item.serviceName || 'N/A',
        concept: item.concept,
        currency: item.currency,
        total: currencyFormatUY(item.total),
        statusName: item.statusName,
        dueDate: formatDateBasic(item.dueDate, 'short')
      }
      return newItem
    })
    return newData
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer={showRowPointer}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getOutboundSettlementDetail, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  const sendToPersonProfile = () => {
    const win = window.open(`${SITE.PEOPLE}${SITE.PERSON}/${personNumber}`, '_blank')
    win.focus()
    close()
  }

  useEffect(() => {
    const body = {
      pageNumber: 1,
      pageSize: 15
    }
    getOutboundSettlementDetail(body)
  }, [])

  useEffect(() => {
    if (outboundSettlementDetailData) {
      const { status } = outboundSettlementDetailData
      if (status === 200) {
        const { outboundSettlementDatailObj } = outboundSettlementDetailData
        const { items, fields, totalPages, currentPage } = outboundSettlementDatailObj
        setTablelist(customDataTable(items))
        setColumns(customHeaderDataTable(fields, columnDataModal))
        setPages(totalPages)
        setItemSelected(currentPage - 1)
        setTotalItems(outboundSettlementDatailObj.totalItems)
        setFlag(true)
      } else {
        setErrorMessage(MESSAGES.BASIC_ERROR)
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: MESSAGES.BASIC_ERROR,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [outboundSettlementDetailData])

  useEffect(() => {
    if (bankAccountsData) {
      if (bankAccountsData.status === 200) {
        const { bankAccounts } = bankAccountsData
        setOptionsBankAccount(customBankAccounts(bankAccounts))
      }
    }
  }, [bankAccountsData])

  useEffect(() => {
    errorControl()
  }, [detail, amount, optionSelectedBankAccount])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
       && (
       <>
         <ModalDataContainer
           flexDirection='row'
           overflow='auto'
           padding={`0 ${spacing.one}`}
           device={device}
         >
           <TableContainer device={device}>
             <TextContainerModal>
               <Text align='left' weight='bold' size='medium' marginBottom={spacing.two}>Filtros</Text>
               <FilterContainer>
                 <SearchBar onChange={handleInputSearchChange} visible value={activeFilters?.filterText} />
               </FilterContainer>
             </TextContainerModal>
             <TextContainerModal>
               <Text align='left' weight='bold' size='medium'>Adeudos</Text>
             </TextContainerModal>
             {renderTable({
               currentList: tablelist,
               columnsN: columns,
               currentLoading: outboundSettlementDetailLoading,
               handleOrder: handleOrderChange,
               handleSelectedRow: handleRowClic,
               error: !!errorMessage,
               showRowPointer: true
             })}
           </TableContainer>
           <FormContainer device={device}>
             <InputContainer exist={detail.value}>
               <TextInput
                 name='detail'
                 label='Adeudo'
                 placeholder='Seleccione una fila de la tabla'
                 value={detail.value}
                 height='small'
                 color='primary'
                 onChange={() => {}}
                 bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
                 disabled
               />
               <Input
                 type={INPUT_TYPES.NUMBER}
                 showType={INPUT_TYPES.TEXT}
                 label='Monto'
                 placeholder='Ingrese monto'
                 name='amount'
                 value={amount.value}
                 error={amount.error}
                 height='small'
                 color='primary'
                 onChange={handleInputChange2}
                 onBlur={() => {}}
                 bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
               />
               <Dropdown
                 name='bankAccounts'
                 label='Cuentas bancarias'
                 placeholder='Seleccione'
                 options={optionsBankAccount}
                 value={optionSelectedBankAccount}
                 onChange={handleDropdownChange}
                 color='primary'
                 height='small'
                 block
                 disabled={optionsBankAccount.length === 0}
               />
               {optionsBankAccount?.length === 0 && detail?.value
                && (
                  <TextErrorContainer>
                    <Text color='error' size='small' weight='bold' align='left'>
                      No existen cuentas bancarias para esta persona. Para crear haga clic&nbsp;
                      <CustomButtonLink
                        color='tertiary'
                        underline
                        onClick={sendToPersonProfile}
                      >
                        aquí
                      </CustomButtonLink>
                      .
                    </Text>
                  </TextErrorContainer>
                )}
               <TextInput
                 name='description'
                 label='Descripción'
                 placeholder='Ingrese descripción'
                 value={description.value}
                 height='small'
                 color='primary'
                 onChange={handleInputChange}
                 bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
               />
             </InputContainer>
             <ButtonContainer justifyContent='flex-end' modal marginTop={spacing.four}>
               <SolidButton
                 size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
                 color='primary'
                 text='Guardar'
                 onClick={handleClick}
                 disabled={showButton}
                 block={device === BREAKPOINTS.MOBILE}
               />
             </ButtonContainer>
           </FormContainer>
         </ModalDataContainer>
       </>
       )}
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddPaymentDetailModal

AddPaymentDetailModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currencyId: PropTypes.number,
  outboundSettlementId: PropTypes.number,
  currentToken: PropTypes.object
}
