import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Imports from '@containers/imports'
import ImportProfile from '@containers/importProfile'

export const ImportsRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={url}
        component={Imports}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.IMPORT}/:jobId`}
        component={ImportProfile}
      />
    </Switch>
  )
}
