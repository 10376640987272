import { NO_EMPLOYEE_FEE } from './types'

export const createNewFee = payload => ({
  type: NO_EMPLOYEE_FEE.CREATE_NEW_FEE,
  payload
})

export const createNewFeeSucces = payload => ({
  type: NO_EMPLOYEE_FEE.CREATE_NEW_FEE_SUCCESS,
  payload
})

export const getFeeHistory = payload => ({
  type: NO_EMPLOYEE_FEE.GET_FEE_HISTORY,
  payload
})

export const feeHistorySuccess = payload => ({
  type: NO_EMPLOYEE_FEE.FEE_HISTORY_SUCCESS,
  payload
})

export const messageInformation = payload => ({
  type: NO_EMPLOYEE_FEE.MESSAGE_INFORMATION,
  payload
})

export const errorMessage = payload => ({
  type: NO_EMPLOYEE_FEE.ERROR_MESSAGE,
  payload
})

export const clearMessage = payload => ({
  type: NO_EMPLOYEE_FEE.CLEAR_MESSAGE,
  payload
})

export const NO_EMPLOYEE_FEE_ACTIONS = {
  CREATE_NEW_FEE: createNewFee,
  CREATE_NEW_FEE_SUCCESS: createNewFeeSucces,
  GET_FEE_HISTORY: getFeeHistory,
  FEE_HISTORY_SUCCESS: feeHistorySuccess,
  MESSAGE_INFORMATION: messageInformation,
  ERROR_MESSAGE: errorMessage,
  CLEAR: clearMessage
}
