import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, WEIGHT } from '@global/fonts'

export const Wrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};
`

export const Label = styled.label`
  ${roboto}
  font-size: ${spacing.oneAndAHalf};
  line-height: ${spacing.twoAndAHalf};
  margin-left: ${spacing.one};
  margin-bottom: ${spacing.half};
  display: block;
  font-weight: ${WEIGHT.REGULAR};
  color: ${({ color }) => colors[color]?.regular ?? colors.contrast.regular
};
  > span {
    color: ${colors.error.regular};
  }

`

export const Message = styled.small`
  ${roboto};
  font-size: ${spacing.oneAndAHalf};
  margin-left: ${spacing.one};
  padding-top: ${spacing.half};
  display: block;
  font-size: ${spacing.oneAndAHalf};
  line-height:  ${spacing.two};
  font-weight: ${WEIGHT.REGULAR};

  color: ${({ color, error, disabled }) => {
    if (disabled) return colors.system.disabled ?? colors[color]?.disabled
    if (error) return colors.error.regular
    return colors[color]?.regular ?? colors.contrast.regular
  }
};
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: ${({ error }) => (error ? spacing.quarter : '1px')} solid ${({ color, error, disabled }) => {
  if (disabled) return colors.system.disabled ?? colors[color]?.disabled
  if (error) return colors.error.regular
  return colors.contrast.disabled
}};

  border-radius: ${spacing.half};
  box-shadow: 0 1px ${spacing.half} 0 rgb(0 0 0 / 0.05);
  
  &:focus-within {
    outline: 2px solid ${({ color, error }) => {
    if (error) return colors.error.regular
    if (color) return colors[color]?.regular
    return colors.system.regular
  }
};
    outline-offset: 1px;
  }

  overflow: hidden;
  padding: 0 ${spacing.oneAndAHalf};
  background-color: ${colors.system.regular}
 
  > * {
    margin-left: ${spacing.oneAndAHalf};
  }

  cursor: ${({ disabled }) => disabled && 'default'};
  pointer-events: ${({ disabled }) => disabled && 'none'};

  & > span {
    &:hover {
      color: ${colors.primary.regular};
    }
  }
`

export const StyledInput = styled.input`
  ${roboto};
  appearance: none;
  display: block;
  width: 100%;
  height: ${({ height }) => {
    switch (height) {
      case 'small':
        return spacing.threeAndAHalf
      case 'large':
        return spacing.fiveAndAHalf
      default:
        return spacing.five
    }
  }};

  padding-top: ${spacing.one};
  padding-bottom: ${spacing.one};
  margin-left: ${({ paddingLeft }) => (paddingLeft ? spacing.one : 0)};

  border-style: none;

  user-select: ${({ type }) => type === 'number' && 'none'}; // does not work with chrome

  color: ${({ error, disabled }) => {
    if (disabled) return colors.system.disabled ?? colors.system.disabled
    if (error) return colors.error.regular
  }
};
  text-align: ${({ type }) => type === 'number' && 'center'};

  &:focus {
    outline: ${spacing.quarter} solid transparent;
    outline-offset: ${spacing.quarter};  
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
  }

  &:disabled {
    background-color: transparent;
  }
`
