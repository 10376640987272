export const COLUMN_DATA_CONTRIBUTIONS_PAYMENTS = [
  { name: 'Código', widthCol: '20%', label: 'Id' },
  { name: 'Concepto', widthCol: '15%', label: 'Concept' },
  { name: 'Origen', widthCol: '15%', label: 'OriginName' },
  { name: 'Divisa', widthCol: '10%', label: 'Currency' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'SBM', widthCol: '15%', label: '' },
  { name: 'Estado', widthCol: '10%', label: 'Status' },
  { name: 'Descripción', widthCol: '20%', label: '' },
  { name: 'Mes', widthCol: '15%', label: 'Month' },
  { name: 'Año', widthCol: '10%', label: 'Year' }
]

export const COLUMN_DATA_PAYMENTS_DETAILS = [
  { name: 'Código', widthCol: '15%', label: 'Id' },
  { name: 'Concepto', widthCol: '15%', label: 'Concept' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'Estado', widthCol: '15%', label: 'Status' },
  { name: 'Descripción', widthCol: '40%', label: '' }
]
