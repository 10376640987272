import styled, { keyframes } from 'styled-components'
import { spacing } from '@global/theme'

const fadeIn = keyframes`
  
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

`

export const StyledLabel = styled.label`
  margin: ${spacing.half} ${spacing.half} ${spacing.half} ${spacing.one};
  display:flex;
  flex-direction:row;
  animation:${fadeIn} 0.4s ease;

  & > :first-child {
    margin-right: ${spacing.quarter};
  }
`
