import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { CURRENCIES_ACTIONS } from './actions'
import { CURRENCIES } from './types'

const apiCalls = {
  getCurrencies: token => (
    Axios.get('Currency',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getAllCurrencies ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getCurrencies, payload)
    yield put(CURRENCIES_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(CURRENCIES_ACTIONS.ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(CURRENCIES.GET_CURRENCIES, getAllCurrencies)
}
