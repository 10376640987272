import { SERVICE_PROVIDER } from './types'

export const getServices = payload => ({
  type: SERVICE_PROVIDER.GET,
  payload
})

export const getServicesSuccess = payload => ({
  type: SERVICE_PROVIDER.SUCCESS,
  payload
})

export const getServicesError = payload => ({
  type: SERVICE_PROVIDER.ERROR,
  payload
})

export const ClearServicesList = payload => ({
  type: SERVICE_PROVIDER.CLEAR,
  payload
})

export const addProvider = payload => ({
  type: SERVICE_PROVIDER.ADD_PROVIDER,
  payload
})

export const addProviderInformation = payload => ({
  type: SERVICE_PROVIDER.ADD_PROVIDER_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: SERVICE_PROVIDER.CLEAR_MESSAGE_INFORMATION
})

export const SERVICE_PROVIDER_ACTIONS = {
  GET_SERVICE_PROVIDER: getServices,
  SUCCESS: getServicesSuccess,
  ERROR: getServicesError,
  CLEAR: ClearServicesList,

  ADD_PROVIDER: addProvider,
  ADD_PROVIDER_INFORMATION: addProviderInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation
}
