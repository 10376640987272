import produce from 'immer'

import { CURRENCY_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  profile: null,
  currencyValues: null,
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  loadingTable: false,
  messageInformation: null,
  messageInformationLoading: false,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case CURRENCY_PROFILE.GET_CURRENCY_PROFILE:
      draft.loading = true
      break
    case CURRENCY_PROFILE.CURRENCY_PROFILE_SUCCESS:
      draft.profile = payload
      break
    case CURRENCY_PROFILE.GET_CURRENCY_VALUES:
      draft.loadingTable = true
      break
    case CURRENCY_PROFILE.CURRENCY_VALUES_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.currencyValues = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.loadingTable = false
      break
    }
    case CURRENCY_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case CURRENCY_PROFILE.DATA_READY:
      draft.loading = false
      break
    case CURRENCY_PROFILE.SET_CURRENCY_VALUE:
      draft.loadingTable = true
      break
    case CURRENCY_PROFILE.MESSAGE_INFORMATION:
      draft.messageInformation = payload
      draft.messageInformationLoading = true
      break
    case CURRENCY_PROFILE.MESSAGE_INFORMATION_CLEAR:
      draft.messageInformation = null
      draft.messageInformationLoading = false
      draft.loadingTable = false
      break
    case CURRENCY_PROFILE.CLEAR:
      return initialState
    case CURRENCY_PROFILE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case CURRENCY_PROFILE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case CURRENCY_PROFILE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case CURRENCY_PROFILE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
