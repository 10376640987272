import produce from 'immer'

import { EMPLOYEE_HISTORY } from './types'

export const initialState = {
  loading: true,
  error: false,
  list: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  loadingList: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case EMPLOYEE_HISTORY.GET_EMPLOYEE_HISTORY:
      draft.loadingList = true
      break
    case EMPLOYEE_HISTORY.SUCCESS:
      draft.loading = false
      draft.loadingList = false
      draft.list = payload
      break
    case EMPLOYEE_HISTORY.ERROR:
      draft.loading = false
      draft.loadingList = false
      draft.error = payload
      break
    case EMPLOYEE_HISTORY.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
