import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react'
import PropTypes from 'prop-types'

import MyProptypes from '@global/propTypes'

import { useClickOutside } from '@utils/hooks'

import { Text } from '@components/texts'
import { SolidButton, LineButton } from '@components/button'

import { checkField } from '@components/inputs/utils/validation'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import Icon from '@components/icon'
import Label from '@components/label'

import { Message } from '../styles'
import { Wrapper, Options, Option, Group } from './styles'

const Dropdown = (props, ref) => {
  const {
    value,
    name,
    label,
    placeholder,
    options,
    color,
    type,
    message,
    error,
    hideError,
    required,
    block,
    disabled,
    onChange,
    icon,
    multiSelect,
    height,
    size
  } = props

  const [open, setOpen] = useState(false)
  const [baseColor] = useState(color || type === 'solid' ? color : 'system')
  const [values, setValues] = useState(value ? [value] : [])
  const [itemsSelected, setItemsSelected] = useState()
  const [overflowing, setOverflowing] = useState(false)
  const internalRef = useRef()
  useClickOutside(internalRef, () => setOpen(false))

  useImperativeHandle(ref, () => ({ clearState: () => {
    setValues([])
    setItemsSelected(null)
  } }))

  const getButton = () => {
    const Button = type === 'solid' ? SolidButton : LineButton
    const iconType = {
      expand_less: 'expand_less',
      expand_more: 'expand_more'
    }

    if (multiSelect) {
      iconType.expand_less = 'filter_list'
      iconType.expand_more = 'filter_list'
    }

    return (
      <Button
        name={multiSelect ? 'columnHeader' : 'dropdown'}
        text={multiSelect && itemsSelected > 0
          ? `Items seleccionados: ${itemsSelected}` : (value?.label || placeholder || name)}
        color={baseColor}
        icon={open ? iconType.expand_less : iconType.expand_more}
        onClick={() => setOpen(!open)}
        disabled={disabled}
        size={size}
      />
    )
  }

  const findId = (arr, id) => arr.some(e => e.id === id)

  const updateValues = (arrValues, item) => {
    if (!findId(arrValues, item.id)) return [...arrValues, item]
    return arrValues.filter(e => e.id !== item.id)
  }

  const customIcon = exists => (exists ? 'radio_button_checked' : 'radio_button_unchecked')

  const handleSelect = itemSelected => {
    if (multiSelect) {
      const newValues = updateValues(values, itemSelected)
      setValues(newValues)
      onChange({ name, value: newValues, error: '' })
      setItemsSelected(newValues.length)
    } else {
      const errorSelect = checkField({ value: itemSelected, type: INPUT_TYPES.DROPDOWN, required })
      setOpen(false)
      onChange({ name, value: itemSelected, error: errorSelect })
    }
  }

  const renderOption = o => (
    <Option
      key={o.id}
      color={color}
      selected={!multiSelect ? value?.id === o.id : findId(values, o.id)}
      onClick={() => handleSelect(o)}
    >
      <Text>{o.label}</Text>
      {multiSelect
        ? <Icon name={customIcon(findId(values, o.id))} color='system' />
        : (icon && <Icon name={icon} color='system' />)}
    </Option>
  )

  const renderOptions = (opt = []) => (
    opt.map(o => {
      if (!o.options) return renderOption(o)
      return (
        <React.Fragment key={o.id}>
          <Group key={o.id} color={color}>
            <Text weight='bold'>{o.label}</Text>
            <Icon name='expand_more' />
          </Group>
          {renderOptions(o.options)}
        </React.Fragment>
      )
    })
  )

  useEffect(() => {
    if (open) {
      const dropdownList = document.getElementsByName('dropdownList')[0]
      const dropdownRect = dropdownList.getBoundingClientRect()
      const viewportHeight = window.innerHeight
      if (dropdownRect.bottom > viewportHeight) {
        setOverflowing(true)
      }
    }
  }, [open])

  return (
    <Wrapper
      block={block}
      ref={internalRef}
      disabled={disabled}
      color={color}
      type={type}
      columnHeader={multiSelect}
      height={height}
      name='dropdown'
    >
      {label && (
        <Label text={label} disabled={disabled} required={required} />
      )}
      {getButton()}
      <Options open={open} columnHeader={multiSelect} block={block} name='dropdownList' overflowing={overflowing}>
        {renderOptions(options)}
      </Options>
      {(message && !error) && <Message>{message}</Message>}
      {(error && !hideError) && <Message error={error}>{error}</Message>}
    </Wrapper>
  )
}

const optionPT = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  value: PropTypes.number
}

Dropdown.propTypes = {
  value: PropTypes.shape(optionPT),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...optionPT,
      options: PropTypes.arrayOf(
        PropTypes.shape(optionPT)
      )
    })
  ),
  color: MyProptypes.color,
  type: PropTypes.oneOf(['solid', 'outline']),
  message: PropTypes.string,
  error: PropTypes.string,
  hideError: PropTypes.bool,
  required: PropTypes.bool,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  icon: PropTypes.string,
  multiSelect: PropTypes.bool,
  height: PropTypes.oneOf(['small', 'medium', 'large']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large'])
}

export default forwardRef(Dropdown)
