export const key = 'EMPLOYMENT_RECORD_PROFILE'

export const EMPLOYMENT_RECORD_PROFILE = {
  GET_EMPLOYMENT_RECORD_PROFILE: 'EMPLOYMENT_RECORD_PROFILE/GET_DATA',
  SUCCESS: 'EMPLOYMENT_RECORD_PROFILE/GET_SUCCESS',
  ERROR: 'EMPLOYMENT_RECORD_PROFILE/GET_ERROR',
  CLEAR: 'EMPLOYMENT_RECORD_PROFILE/CLEAR',

  EDIT_EMPLOYMENT_RECORD_PROFILE: 'EMPLOYMENT_RECORD_PROFILE/PROFILE/EDIT',
  EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION: 'EMPLOYMENT_RECORD_PROFILE/PROFILE/EDIT/INFORMATION',
  EMPLOYMENT_RECORD_PROFILE_UPDATED: 'EMPLOYMENT_RECORD_PROFILE/PROFILE/EDIT/UPDATED',
  GET_EMPLOYMENT_RECORD_PROFILE_MODAL_DATA: 'EMPLOYMENT_RECORD_PROFILE/EDIT_PROFILE/GET_MODAL_DATA',
  EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION: 'EMPLOYMENT_RECORD_PROFILE/EDIT_PROFILE/MODAL_DATA_INFORMATION',

  GET_CONTRIBUTIONS_EMPLOYMENT_RECORD: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTIONS',
  CONTRIBUTIONS_EMPLOYMENT_RECORD_SUCCESS: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTIONS/SUCCESS',
  CONTRIBUTIONS_EMPLOYMENT_RECORD_ERROR: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTIONS/ERROR',
  CURRENCIES_INFORMATION: 'EMPLOYMENT_RECORD_PROFILE/CURRENCIES/INFORMATION',

  GET_FILTER_DATA: 'EMPLOYMENT_RECORD_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'EMPLOYMENT_RECORD_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'EMPLOYMENT_RECORD_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'EMPLOYMENT_RECORD_PROFILE/FILTER/CLEAR',

  GET_CONTRIBUTION_PAYMENTS: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTION_PAYMENTS',
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTION_PAYMENTS_SUCCESS',
  GET_CONTRIBUTION_PAYMENTS_ERROR: 'EMPLOYMENT_RECORD_PROFILE/GET_CONTRIBUTION_PAYMENTS_ERROR',

  DATA_UPLOAD_READY: 'EMPLOYMENT_RECORD_PROFILE/DATA_UPLOAD_READY'
}

export default key
