import produce from 'immer'

import { SERVICE_PROVIDER } from './types'

export const initialState = {
  loading: true,
  error: false,
  serviceProviderLoading: false,
  serviceProviderList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  messageInformation: null,
  loadingStatuses: false
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case SERVICE_PROVIDER.GET:
      draft.serviceProviderLoading = true
      break
    case SERVICE_PROVIDER.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.loading = false
      draft.serviceProviderLoading = false
      draft.serviceProviderList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case SERVICE_PROVIDER.ERROR:
      draft.loading = false
      draft.serviceProviderLoading = false
      draft.error = payload
      break
    case SERVICE_PROVIDER.CLEAR:
      return initialState
    case SERVICE_PROVIDER.ADD_PROVIDER:
      draft.serviceProviderLoading = true
      break
    case SERVICE_PROVIDER.ADD_PROVIDER_INFORMATION:
      draft.messageInformation = payload
      draft.serviceProviderLoading = false
      break
    case SERVICE_PROVIDER.CLEAR_MESSAGE_INFORMATION:
      draft.messageInformation = null
      break
    default:
      return initialState
  }
})

export default reducer
