import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectDebtProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectDebtProfile, state => get(state, 'loading', null)
)

const selectDebtProfileSuccess = createSelector(
  selectDebtProfile, state => get(state, 'debtProfile', null)
)

const selectStatusesSuccess = createSelector(
  selectDebtProfile, state => get(state, 'debtProfileStatuses', null)
)

const selectError = createSelector(
  selectDebtProfile, state => get(state, 'error', null)
)

const selectLoadingEditDebtProfile = createSelector(
  selectDebtProfile, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectDebtProfile, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectDebtProfile, state => get(state, 'editInformation', [])
)

const selectDebtProfileModalData = createSelector(
  selectDebtProfile, state => get(state, 'modalDataInformation', [])
)

const selectCurrencies = createSelector(
  selectDebtProfile, state => get(state, 'currencies', [])
)

const selectConcepts = createSelector(
  selectDebtProfile, state => get(state, 'concepts', null)
)

export default {
  DEBT_PROFILE_SUCCESS: selectDebtProfileSuccess,
  DEBT_PROFILE_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_DEBT_PROFILE: selectLoadingEditDebtProfile,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  DEBT_PROFILE_MODAL_DATA: selectDebtProfileModalData,
  CURRENCIES: selectCurrencies,
  CONCEPTS: selectConcepts
}
