import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PEOPLE_ACTIONS } from './actions'
import { PEOPLE } from './types'

const apiCalls = {
  getData: ({ data, token }) => (
    Axios.post('Person/filter', data,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getEmailTypes: token => (
    Axios.get('Email/types',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getSexTypes: token => (
    Axios.get('Person/sexes',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addPerson: ({ data, token }) => (
    Axios.post('Person/create', data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPersonStatus: token => (
    Axios('Person/statuses',
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getPeople ({ payload }) {
  try {
    const { token } = payload
    const { data: personStatus } = yield call(apiCalls.getPersonStatus, token)
    const res = yield call(apiCalls.getData, payload)
    const people = res.data
    yield put(PEOPLE_ACTIONS.PERSON_STATUS(personStatus))
    yield put(PEOPLE_ACTIONS.SUCCESS(people))
  } catch (error) {
    yield put(PEOPLE_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(PEOPLE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(PEOPLE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalTypes ({ payload }) {
  try {
    const { data: sexTypes } = yield call(apiCalls.getSexTypes, payload)

    yield put(PEOPLE_ACTIONS.INFORMATION_MODAL_TYPES(sexTypes))
  } catch (error) {
    yield put(PEOPLE_ACTIONS.INFORMATION_MODAL_TYPES(error))
  }
}

export function* createPerson ({ payload }) {
  try {
    const { status } = yield call(apiCalls.addPerson, payload)
    yield put(PEOPLE_ACTIONS.CREATE_PERSON_INFORMATION({ status }))
  } catch (error) {
    yield put(PEOPLE_ACTIONS.CREATE_PERSON_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(PEOPLE.GET, getPeople)
  yield takeLatest(PEOPLE.SEND_FILTER, getPeople)
  yield takeLatest(PEOPLE.GET_FILTER_DATA, FilterData)
  yield takeLatest(PEOPLE.GET_MODAL_TYPES, getModalTypes)
  yield takeLatest(PEOPLE.CREATE_PERSON, createPerson)
}
