import React from 'react'
import PropTypes from 'prop-types'

import MyPropTyes from '@global/propTypes'

import { StyledText } from './styles'

const Text = ({ text, align, weight, color, size, children, marginBottom, disabled, id }) => (
  <StyledText
    weight={weight}
    size={size}
    color={color}
    align={align}
    marginBottom={marginBottom}
    disabled={disabled}
    id={id}
  >
    {text || children}
  </StyledText>
)

Text.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  weight: MyPropTyes.weight,
  color: MyPropTyes.allColors,
  size: MyPropTyes.size,
  children: PropTypes.node,
  marginBottom: MyPropTyes.space,
  disabled: PropTypes.bool,
  id: PropTypes.number
}

export default Text
