import React from 'react'
import PropTypes from 'prop-types'

import { spacing } from '@global/theme'

import { Text } from '@components/texts'
import Icon from '@components/icon'
import { useUser } from '@components/authentication/utils/hook'

import avatar from '@assets/male-avatar.jpg'

import { Head, Img, ProfileData } from './styles'

export const Header = ({ device, showSidebar }) => {
  const { GetUser } = useUser()
  const user = GetUser()
  const { fullName } = user

  const handleClick = () => {
    showSidebar(true)
  }

  return (
    <Head device={device}>
      <Icon name='menu' size='large' onClick={handleClick} />
      <ProfileData>
        <Img src={avatar} alt='user' width={spacing.four} height={spacing.four} borderRadius='50%' />
        <Text size='small' weight='bold' align='center'>
          {fullName}
        </Text>
      </ProfileData>

    </Head>
  )
}

Header.propTypes = {
  device: PropTypes.number.isRequired,
  showSidebar: PropTypes.func.isRequired
}

export default Header
