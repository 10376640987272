export const key = 'CONTRIBUTION_PAYMENT'

export const CONTRIBUTION_PAYMENT = {
  GET_CONTRIBUTION_PAYMENT: 'CONTRIBUTION_PAYMENT/GET_DATA',
  SUCCESS: 'CONTRIBUTION_PAYMENT/GET_SUCCESS',
  ERROR: 'CONTRIBUTION_PAYMENT/GET_ERROR',
  CLEAR: 'CONTRIBUTION_PAYMENT/CLEAR',

  EDIT_CONTRIBUTION_PAYMENT: 'CONTRIBUTION_PAYMENT/PROFILE/EDIT',
  EDIT_CONTRIBUTION_PAYMENT_INFORMATION: 'CONTRIBUTION_PAYMENT/PROFILE/EDIT/INFORMATION',
  CONTRIBUTION_PAYMENT_UPDATED: 'CONTRIBUTION_PAYMENT/PROFILE/EDIT/UPDATED'
}

export default key
