import styled from 'styled-components'

import { spacing } from '@global/theme'

export const BadgeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: ${spacing.two};

  & > div {
    margin-left: ${spacing.one};
    margin-bottom: ${spacing.one};
  }
`

export const DivBadge = styled.div`
  width: auto;

  > div span {
    cursor: pointer;
  }
`
