import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ModalDataContainer, ButtonModalContainer, TextContainerModal } from '@global/styles'
import { changeNameForLabel } from '@utils/helpers'
import { MONTHS, BREAKPOINTS } from '@global/constants'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { MESSAGES } from '@global/message'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import CustomDatePicker from '@components/datePicker'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

const EditLiquidationStatus = ({ statuses, oldData, saveData, close }) => {
  const { device } = useWindowDimensions()

  const [options] = useState(changeNameForLabel(statuses))
  const [optionSelected, setOptionSelected] = useState(null)

  const [optionSelectedMonth, setOptionSelectedMonth] = useState()
  const [year, setYear] = useState({
    value: oldData.year,
    name: '',
    error: ''
  })
  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [dueDate, setDueDate] = useState(new Date(oldData.dueDate))
  const [errorMessage, setErrorMessage] = useState(false)
  const [description, setDescription] = useState({
    value: oldData.description,
    name: '',
    error: ''
  })

  const cleanFields = () => {
    setYear({
      value: '',
      name: '',
      error: ''
    })
    setOptionSelected(null)
    setOptionSelectedMonth(null)
    setDescription(null)
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'status') setOptionSelected(value)
    else setOptionSelectedMonth(value)
  }

  const handleInputChange = val => {
    const { name } = val
    if (name === 'year') setYear(val)
    else setDescription(val)
  }

  const handleClick = () => {
    const liquidationLog = {
      statusId: optionSelected.id,
      month: optionSelectedMonth.id + 1,
      year: year.value,
      dueDate,
      description: description?.value
    }
    saveData(liquidationLog)
    cleanFields()
    setFlag(false)
    close(false)
  }

  const dateControl = (selectedMonth, selectedYear) => {
    if (!selectedMonth || !selectedYear?.value || selectedYear?.error) return false

    if (dueDate.getFullYear() > Number(year.value)) return true

    if (dueDate.getFullYear() === Number(year.value)) {
      if (dueDate.getMonth() >= selectedMonth.id) return true
    }

    return false
  }

  const errorControl = () => {
    const datesOk = dateControl(optionSelectedMonth, year)
    setErrorMessage(!datesOk)

    if ((year.value && !year.error) && datesOk) setShowButton(false)
    else setShowButton(true)
  }

  useEffect(() => {
    setOptionSelected(options.find(s => s.id === oldData.statusId))
    setOptionSelectedMonth(MONTHS.find(m => m.id === (oldData.month - 1)))
    return () => {
      setOptionSelected(null)
    }
  }, [])

  useEffect(() => {
    setFlag(true)
  }, [optionSelected])

  useEffect(() => {
    errorControl()
  }, [year, optionSelectedMonth, dueDate])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            name='year'
            label='Año'
            placeholder='Ingrese año'
            color='primary'
            value={year.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={year.error}
            bits={[{ icon: 'edit_calendar', position: 'left', colorBit: 'tertiary' }]}
          />
          <Dropdown
            name='month'
            label='Mes'
            placeholder='Seleccionar'
            options={MONTHS}
            value={optionSelectedMonth}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Seleccionar'
            options={options}
            value={optionSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <CustomDatePicker
            name='dueDate'
            title='Fecha de vencimiento'
            color='primary'
            date={dueDate}
            onChange={setDueDate}
            yearsToShow={2000}
            justifyContentButton='space-between'
            block
          />
          <Input
            type={INPUT_TYPES.TEXT}
            showType={INPUT_TYPES.TEXT}
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            color='primary'
            value={description.value}
            onChange={handleInputChange}
            onBlur={() => {}}
            height='small'
            error={description.error}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
          {errorMessage
          && (
            <TextContainerModal>
              <Text size='small' weight='bold' color='error'>{MESSAGES.ERROR_DUE_DATE_MINOR}</Text>
            </TextContainerModal>
          )}
        </ModalDataContainer>
        <ButtonModalContainer justifyContent='flex-end' oneButton>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonModalContainer>

      </>
      )}
    </>
  )
}

export default EditLiquidationStatus

EditLiquidationStatus.propTypes = {
  statuses: PropTypes.array,
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func
}
