import React, { useRef, useState } from 'react'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { checkField } from '@components/inputs/utils/validation'
import { textAreaPropTypes } from '@components/inputs/inputs/propTypes'
import { Wrapper, Label, Message } from '@components/inputs/inputs/styles'
import { Text } from '@components/texts'

import { StyledTextArea, TextContainer } from './styles'

export const TextArea = props => {
  const {
    name,
    label,
    placeholder,
    value,
    color,
    min,
    max,
    message,
    required,
    onChange,
    disabled,
    hideError,
    error,
    resize
  } = props

  const txtA = useRef(null)
  const [focus, setFocus] = useState(false)
  const [customColor, setCustomColor] = useState(color)

  const handleChange = ({ target }) => {
    const errorChange = checkField({
      type: INPUT_TYPES.TEXTAREA,
      required,
      value: target.value,
      min,
      max
    })

    onChange({ name, value: target.value, error: errorChange })
    if (errorChange) setCustomColor('error')
    else setCustomColor(color)
  }

  const handleFocus = () => {
    if (!txtA.current) return
    txtA.current.focus()
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
  }

  return (
    <Wrapper>
      {label && (
        <Label htmlFor={`textArea_${name}`} disabled={disabled} color={focus ? customColor : null}>
          {label}
          {required && <span> *</span>}
        </Label>
      )}

      <StyledTextArea
        ref={txtA}
        name={name}
        id={`textArea_${name}`}
        value={value}
        placeholder={placeholder}
        color={color}
        onChange={handleChange}
        onClick={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        error={error}
        maxlength={max}
        minlength={min}
        resize={resize}
      />

      {max && (
        <TextContainer>
          <Text
            color={focus ? customColor : null}
          >
            Cantidad de caracteres: {value.length} / {max}
          </Text>
        </TextContainer>
      )}

      {(message && !error) && <Message color={focus ? customColor : null}>{message}</Message>}
      {(error && !hideError) && <Message error={error}>{error}</Message>}
    </Wrapper>
  )
}

TextArea.propTypes = {
  ...textAreaPropTypes
}

export default TextArea
