import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { TYPE_PERSONAL_INFORMATION_MODAL } from '@global/constants'

import { PERSON_ACTIONS } from './actions'
import { PERSON } from './types'
import { TYPES_PERSON_DATA } from '../data'

const importData = {
  data: [
    { id: 1, name: 'Up' },
    { id: 2, name: 'Down' }
  ]
}

const apiCalls = {
  personCalls: {
    getPersonId: ({ personId, token }) => (
      Axios(`Person/${personId}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPersonPhones: ({ personId, token }) => (
      Axios(`Person/${personId}/phones`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPersonAddresses: ({ personId, token }) => (
      Axios(`Person/${personId}/addresses`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPersonFiles: ({ personId, token }) => (
      Axios(`Person/${personId}/files`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPersonNotes: ({ personId, token }) => Axios(`Person/${personId}/notes`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    getPersonEmails: ({ personId, token }) => Axios(`Person/${personId}/emails`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    getPersonBanks: token => Axios('Banks',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    getPersonBankAccounts: ({ personId, token }) => Axios(`BankAccount?personId=${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }),
    getActiveEmploymentRecord: ({ personId, token }) => (
      Axios(`EmploymentRecord/getactive?personId=${personId}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getActiveAffiliateRecord: ({ personId, token }) => (
      Axios(`AffiliateRecord/getactive?personId=${personId}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getActiveFrmRecord: ({ personId, token }) => (
      Axios(`FRMRecord/getactive?personId=${personId}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editProfile: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPersonStatus: token => (
      Axios('Person/statuses',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getConPay: ({ data, token }) => (
      Axios.post('InboundPayment/contributionpayment/filter', data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getFilterData: ({ id, token }) => (
      Axios.get(`Filter/${id}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getInboundPaymentsOrigins: token => (
      Axios.get('InboundPayment/origins',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  addressCalls: {
    addAddress: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/addresses/add`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editAddress: ({ addressId, data, token }) => (
      Axios.post(`Address/${addressId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removeAddress: ({ addressId, token }) => (
      Axios.delete(`Address/${addressId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getAddressesTypes: token => (
      Axios('Address/types',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getDepartments: ({ countryId, token }) => (
      Axios(`Country/${countryId}`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  phoneCalls: {
    addPhone: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/phones/add`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editPhone: ({ phoneId, data, token }) => (
      Axios.post(`Phone/${phoneId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removePhone: ({ phoneId, token }) => (
      Axios.delete(`Phone/${phoneId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getPhonesTypes: token => (
      Axios('Phone/types',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  notesCalls: {
    addNote: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/notes/add`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editNote: ({ noteId, data, token }) => (
      Axios.post(`Note/${noteId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removeNote: ({ noteId, token }) => (
      Axios.delete(`Note/${noteId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  emailsCalls: {
    addEmail: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/emails/add`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editEmail: ({ emailId, data, token }) => (
      Axios.post(`Email/${emailId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removeEmail: ({ emailId, token }) => (
      Axios.delete(`Email/${emailId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getEmailsTypes: token => (
      Axios('Email/types',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  banksAccountsCalls: {
    addBankAccount: ({ data, token }) => (
      Axios.post('BankAccount/create', data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    editBankAccount: ({ bankAccountId, data, token }) => (
      Axios.post(`BankAccount/${bankAccountId}/edit`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removeBankAccount: ({ bankAccountId, token }) => (
      Axios.delete(`BankAccount/${bankAccountId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getCurrencies: token => Axios('Currency',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })
  },
  filesCalls: {
    loadFile: ({ data, token }) => (
      Axios.post('File/upload', data,
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    downloadFile: ({ fileId, token }) => (
      Axios(`File/${fileId}/download`,
        {
          responseType: 'arraybuffer',
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    deleteFile: ({ personId, fileId, token }) => (
      Axios.delete(`Person/${personId}/files/${fileId}/delete`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getFilesTypes: () => importData,
    addFileToPerson: ({ personId, data, token }) => (
      Axios.post(`Person/${personId}/files/add`, data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  employmentCalls: {
    getEmploymentRecordStatuses: token => (
      Axios('EmploymentRecord/statuses',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    createEmploymentRecord: ({ data, token }) => (
      Axios.post('EmploymentRecord/create', data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getAffiliateRecordStatuses: token => (
      Axios('AffiliateRecord/statuses',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getAffiliateRecordCategories: token => (
      Axios('AffiliateRecord/categories',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    createAffiliateRecord: ({ data, token }) => (
      Axios.post('AffiliateRecord/create', data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    getFrmRecordStatuses: token => (
      Axios('FRMRecord/statuses',
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    createFrmRecord: ({ data, token }) => (
      Axios.post('FRMRecord/create', data,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  }
}

export function* getPerson ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const { token } = payload

    const { data: person } = yield call(personCalls.getPersonId, payload)
    const { data: phones } = yield call(personCalls.getPersonPhones, payload)
    const { data: addresses } = yield call(personCalls.getPersonAddresses, payload)
    const { data: files } = yield call(personCalls.getPersonFiles, payload)
    const { data: notes } = yield call(personCalls.getPersonNotes, payload)
    const { data: emails } = yield call(personCalls.getPersonEmails, payload)
    const { data: bankAccounts } = yield call(personCalls.getPersonBankAccounts, payload)
    const { data: personStatus } = yield call(personCalls.getPersonStatus, token)

    const personalInformation = {
      person,
      phones,
      addresses,
      bankAccounts,
      notes,
      emails,
      files,
      personStatus
    }

    yield put(PERSON_ACTIONS.GET_PERSON_SUCCESS(personalInformation))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_PERSON_ERROR(error))
  }
}

export function* setAddress ({ payload, type }) {
  try {
    const { addressCalls, personCalls } = apiCalls
    const { personId, data, token } = payload
    let res = null
    switch (type) {
      case PERSON.ADD_ADDRESS: res = yield call(addressCalls.addAddress, payload)
        break
      case PERSON.EDIT_ADDRESS: {
        const { addressId, body } = data
        res = yield call(addressCalls.editAddress, { addressId, data: body, token })
        break
      }
      default: res = yield call(addressCalls.removeAddress, { addressId: data, token })
    }
    const { data: addresses } = yield call(personCalls.getPersonAddresses, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_ADDRESSES(addresses))
    const { status } = res
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status, message, dataType: TYPES_PERSON_DATA.ADDRESS }))
  }
}

export function* setPhone ({ payload, type }) {
  try {
    const { phoneCalls, personCalls } = apiCalls
    const { personId, data, token } = payload
    let res = null
    switch (type) {
      case PERSON.ADD_PHONE: res = yield call(phoneCalls.addPhone, payload)
        break
      case PERSON.EDIT_PHONE: res = yield call(phoneCalls.editPhone, { phoneId: data.phoneId, data: data.body, token })
        break
      default: res = yield call(phoneCalls.removePhone, { phoneId: data, token })
    }
    const { data: phones } = yield call(personCalls.getPersonPhones, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_PHONES(phones))
    const { status } = res
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status, message, dataType: TYPES_PERSON_DATA.PHONE }))
  }
}

export function* setNote ({ payload, type }) {
  try {
    const { notesCalls, personCalls } = apiCalls
    const { personId, data, token } = payload
    let res = null
    switch (type) {
      case PERSON.ADD_NOTE: res = yield call(notesCalls.addNote, payload)
        break
      case PERSON.EDIT_NOTE: res = yield call(notesCalls.editNote, payload)
        break
      default: res = yield call(notesCalls.removeNote, { noteId: data, token })
    }

    const { data: notes } = yield call(personCalls.getPersonNotes, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_NOTES(notes))
    const { status } = res
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status, message, dataType: TYPES_PERSON_DATA.NOTE }))
  }
}

export function* setEmail ({ payload, type }) {
  try {
    const { emailsCalls, personCalls } = apiCalls
    const { personId, data, token } = payload
    let res = null
    switch (type) {
      case PERSON.ADD_EMAIL: res = yield call(emailsCalls.addEmail, payload)
        break
      case PERSON.EDIT_EMAIL: res = yield call(emailsCalls.editEmail, { emailId: data.emailId, data: data.body, token })
        break
      default: res = yield call(emailsCalls.removeEmail, { emailId: data, token })
    }

    const { data: emails } = yield call(personCalls.getPersonEmails, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_EMAILS(emails))
    const { status } = res
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status, message, dataType: TYPES_PERSON_DATA.EMAIL }))
  }
}

export function* setBankAccount ({ payload, type }) {
  try {
    const { banksAccountsCalls, personCalls } = apiCalls
    const { personId, data, token } = payload
    let res = null
    switch (type) {
      case PERSON.ADD_BANK_ACCOUNT:
        res = yield call(banksAccountsCalls.addBankAccount, { data, token })
        break
      case PERSON.EDIT_BANK_ACCOUNT:
        res = yield call(banksAccountsCalls.editBankAccount,
          { bankAccountId: data.bankAccountId, data: data.body, token })
        break
      default:
        res = yield call(banksAccountsCalls.removeBankAccount, { bankAccountId: data, token })
    }

    const { data: banksAccounts } = yield call(personCalls.getPersonBankAccounts, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_BANKS_ACCOUNTS(banksAccounts))
    const { status } = res
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    const { status, message } = error
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status, message, dataType: TYPES_PERSON_DATA.BANK_ACCOUNT }))
  }
}

export function* getTypes ({ payload }) {
  try {
    const { type, token } = payload
    const { addressCalls, phoneCalls, emailsCalls, filesCalls, personCalls } = apiCalls
    let res = null
    switch (type) {
      case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name:
        res = yield call(addressCalls.getAddressesTypes, token)
        break
      case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name:
        res = yield call(phoneCalls.getPhonesTypes, token)
        break
      case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name:
        res = yield call(emailsCalls.getEmailsTypes, token)
        break
      case TYPE_PERSONAL_INFORMATION_MODAL.FILE.name:
        res = yield call(filesCalls.getFilesTypes, token)
        break
      default:
        res = yield call(personCalls.getBankAccountTypes, token)
        break
    }
    const { data } = res
    yield put(PERSON_ACTIONS.GET_TYPES_SUCCESS(data))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_TYPES_ERROR(error))
  }
}

export function* getDepartments ({ payload }) {
  try {
    const { addressCalls } = apiCalls
    const { data } = yield call(addressCalls.getDepartments, payload)
    yield put(PERSON_ACTIONS.GET_DEPARTMENTS_SUCCESS(data.departments))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_DEPARTMENTS_ERROR(error))
  }
}

export function* getPersonBanks ({ payload }) {
  try {
    const { personCalls, banksAccountsCalls } = apiCalls
    const { data: personBanks } = yield call(personCalls.getPersonBanks, payload)
    const { data: currenciesData } = yield call(banksAccountsCalls.getCurrencies, payload)
    yield put(PERSON_ACTIONS.BANKS_INFORMATION({ status: 200, personBanks, currenciesData }))
  } catch (error) {
    yield put(PERSON_ACTIONS.BANKS_INFORMATION(error))
  }
}

export function* uploadFile ({ payload }) {
  try {
    const { filesCalls, personCalls } = apiCalls

    const { token, personId } = payload
    yield call(filesCalls.addFileToPerson, payload)
    const { data: updatedFileList } = yield call(personCalls.getPersonFiles, { personId, token })

    yield put(PERSON_ACTIONS.GET_FILE_SUCCESS(updatedFileList))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_FILE_ERROR(error))
  }
}

export function* downloadFile ({ payload }) {
  try {
    const { filesCalls } = apiCalls
    const res = yield call(filesCalls.downloadFile, payload)
    const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]

    const data = {
      status: res.status,
      data: res.data,
      contentType: res.headers['content-type'],
      fileName
    }

    yield put(PERSON_ACTIONS.FILE_MESSAGE(data))
  } catch (error) {
    yield put(PERSON_ACTIONS.FILE_MESSAGE(error))
  }
}

export function* removeFile ({ payload }) {
  try {
    const { personId, fileId, token } = payload
    const { filesCalls, personCalls } = apiCalls
    yield call(filesCalls.deleteFile, { personId, fileId, token })
    const { data: updatedFileList } = yield call(personCalls.getPersonFiles, { personId, token })

    yield put(PERSON_ACTIONS.GET_FILE_SUCCESS(updatedFileList))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_FILE_ERROR(error))
  }
}

export function* editProfile ({ payload }) {
  try {
    const { personId, token } = payload
    const { personCalls } = apiCalls
    const { status } = yield call(personCalls.editProfile, payload)
    const { data: updatedProfile } = yield call(personCalls.getPersonId, { personId, token })
    yield put(PERSON_ACTIONS.UPDATE_PROFILE(updatedProfile))
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ status }))
  } catch (error) {
    yield put(PERSON_ACTIONS.SET_INFORMATION_MESSAGE({ error, dataType: TYPES_PERSON_DATA.PROFILE }))
  }
}

export function* getActiveEmploymentRecord ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const res = yield call(personCalls.getActiveEmploymentRecord, payload)

    yield put(PERSON_ACTIONS.EMPLOYMENT_RECORD(res))
  } catch (error) {
    yield put(PERSON_ACTIONS.EMPLOYMENT_RECORD(error))
  }
}

export function* getActiveAffiliateRecord ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const res = yield call(personCalls.getActiveAffiliateRecord, payload)

    yield put(PERSON_ACTIONS.ACTIVE_AFFILIATE_RECORD(res))
  } catch (error) {
    yield put(PERSON_ACTIONS.ACTIVE_AFFILIATE_RECORD(error))
  }
}

export function* getActiveFrmRecord ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const res = yield call(personCalls.getActiveFrmRecord, payload)
    yield put(PERSON_ACTIONS.FRM_RECORD(res))
  } catch (error) {
    yield put(PERSON_ACTIONS.FRM_RECORD(error))
  }
}

export function* createEmploymentRecord ({ payload }) {
  try {
    const { personId, data, token } = payload
    const { employmentCalls, personCalls } = apiCalls
    const {
      status,
      data: employmentRecordCreated
    } = yield call(employmentCalls.createEmploymentRecord, { data, token })

    if (employmentRecordCreated.statusId === 1) {
      yield put(PERSON_ACTIONS.EMPLOYMENT_RECORD_INFORMATION({ status, data: employmentRecordCreated }))
    } else {
      const res = yield call(personCalls.getActiveEmploymentRecord, { personId, token })
      yield put(PERSON_ACTIONS.EMPLOYMENT_RECORD(res))
    }
  } catch (error) {
    yield put(PERSON_ACTIONS.EMPLOYMENT_RECORD_INFORMATION(error))
  }
}

export function* getAffiliateRecordModalData ({ payload }) {
  try {
    const { employmentCalls } = apiCalls
    const { data: categoriesData } = yield call(employmentCalls.getAffiliateRecordCategories, payload)

    yield put(PERSON_ACTIONS.AFFILIATE_RECORD_MODAL_DATA_INFORMATION({ status: 200, categoriesData }))
  } catch (error) {
    yield put(PERSON_ACTIONS.AFFILIATE_RECORD_MODAL_DATA_INFORMATION(error))
  }
}

export function* createAffiliateRecord ({ payload }) {
  try {
    const { personId, data, token } = payload
    const { employmentCalls, personCalls } = apiCalls

    const {
      status,
      data: affiliateRecordCreated
    } = yield call(employmentCalls.createAffiliateRecord, { data, token })

    if (affiliateRecordCreated.statusId === 1) {
      yield put(PERSON_ACTIONS.AFFILIATE_RECORD_INFORMATION({ status, data: affiliateRecordCreated }))
    } else {
      const res = yield call(personCalls.getActiveAffiliateRecord, { personId, token })
      yield put(PERSON_ACTIONS.ACTIVE_AFFILIATE_RECORD(res))
    }
  } catch (error) {
    yield put(PERSON_ACTIONS.AFFILIATE_RECORD_INFORMATION(error))
  }
}

export function* createFrmRecord ({ payload }) {
  try {
    const { personId, data, token } = payload
    const { employmentCalls, personCalls } = apiCalls

    const {
      status,
      data: frmRecordCreated
    } = yield call(employmentCalls.createFrmRecord, { data, token })

    if (frmRecordCreated.statusId === 1) {
      yield put(PERSON_ACTIONS.FRM_RECORD_INFORMATION({ status, data: frmRecordCreated }))
    } else {
      const res = yield call(personCalls.getActiveFrmRecord, { personId, token })
      yield put(PERSON_ACTIONS.FRM_RECORD(res))
    }
  } catch (error) {
    yield put(PERSON_ACTIONS.FRM_RECORD_INFORMATION(error))
  }
}

export function* getContributionPayments ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const { token } = payload
    const { data: contributionPaymentsData } = yield call(personCalls.getConPay, payload)
    const { data: originData } = yield call(personCalls.getInboundPaymentsOrigins, token)

    yield put(PERSON_ACTIONS.GET_CONTRIBUTION_PAYMENTS_SUCCESS({ contributionPaymentsData, originData }))
  } catch (error) {
    yield put(PERSON_ACTIONS.GET_CONTRIBUTION_PAYMENTS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const { personCalls } = apiCalls
    const res = yield call(personCalls.getFilterData, payload)

    yield put(PERSON_ACTIONS.FILTER_DATA_SUCCESS(res.data.parameters))
  } catch (error) {
    yield put(PERSON_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(PERSON.GET_PERSON, getPerson)
  yield takeLatest(PERSON.ADD_ADDRESS, setAddress)
  yield takeLatest(PERSON.EDIT_ADDRESS, setAddress)
  yield takeLatest(PERSON.REMOVE_ADDRESS, setAddress)
  yield takeLatest(PERSON.ADD_PHONE, setPhone)
  yield takeLatest(PERSON.EDIT_PHONE, setPhone)
  yield takeLatest(PERSON.REMOVE_PHONE, setPhone)
  yield takeLatest(PERSON.ADD_NOTE, setNote)
  yield takeLatest(PERSON.REMOVE_NOTE, setNote)
  yield takeLatest(PERSON.EDIT_NOTE, setNote)
  yield takeLatest(PERSON.ADD_EMAIL, setEmail)
  yield takeLatest(PERSON.REMOVE_EMAIL, setEmail)
  yield takeLatest(PERSON.EDIT_EMAIL, setEmail)
  yield takeLatest(PERSON.ADD_BANK_ACCOUNT, setBankAccount)
  yield takeLatest(PERSON.REMOVE_BANK_ACCOUNT, setBankAccount)
  yield takeLatest(PERSON.EDIT_BANK_ACCOUNT, setBankAccount)
  yield takeLatest(PERSON.GET_TYPES, getTypes)
  yield takeLatest(PERSON.GET_DEPARTMENTS, getDepartments)
  yield takeLatest(PERSON.GET_PERSON_BANKS, getPersonBanks)
  yield takeLatest(PERSON.UPLOAD_FILE, uploadFile)
  yield takeLatest(PERSON.DOWNLOAD_FILE, downloadFile)
  yield takeLatest(PERSON.REMOVE_FILE, removeFile)
  yield takeLatest(PERSON.EDIT_PROFILE, editProfile)
  yield takeLatest(PERSON.GET_EMPLOYMENT_RECORD, getActiveEmploymentRecord)
  yield takeLatest(PERSON.GET_ACTIVE_AFFILIATE_RECORD, getActiveAffiliateRecord)
  yield takeLatest(PERSON.GET_FRM_RECORD, getActiveFrmRecord)
  yield takeLatest(PERSON.CREATE_EMPLOYMENT_RECORD, createEmploymentRecord)
  yield takeLatest(PERSON.GET_AFFILIATE_RECORD_MODAL_DATA, getAffiliateRecordModalData)
  yield takeLatest(PERSON.CREATE_AFFILIATE_RECORD, createAffiliateRecord)
  yield takeLatest(PERSON.CREATE_FRM_RECORD, createFrmRecord)
  yield takeLatest(PERSON.GET_CONTRIBUTION_PAYMENTS, getContributionPayments)
  yield takeLatest(PERSON.GET_FILTER_DATA, filterData)
}
