import { CURRENCIES } from './types'

export const getCurrencies = payload => ({
  type: CURRENCIES.GET_CURRENCIES,
  payload
})

export const getCurrenciesSuccess = payload => ({
  type: CURRENCIES.SUCCESS,
  payload
})

export const getCurrenciesError = payload => ({
  type: CURRENCIES.ERROR,
  payload
})

export const ClearCurrenciesList = payload => ({
  type: CURRENCIES.CLEAR,
  payload
})

export const CURRENCIES_ACTIONS = {
  GET_CURRENCIES: getCurrencies,
  SUCCESS: getCurrenciesSuccess,
  ERROR: getCurrenciesError,
  CLEAR: ClearCurrenciesList
}
