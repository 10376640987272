import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectFrmRecord = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectFrmRecord, state => get(state, 'loading', false)
)

const selectPersonSucces = createSelector(
  selectFrmRecord, state => get(state, 'person', null)
)

const selectFrmRecordSucces = createSelector(
  selectFrmRecord, state => get(state, 'activeFrmRecord', null)
)

const selectContributionsSuccess = createSelector(
  selectFrmRecord, state => get(state, 'contributions', null)
)

const selectError = createSelector(
  selectFrmRecord, state => get(state, 'error', null)
)

const selectContributionsLoading = createSelector(
  selectFrmRecord, state => get(state, 'contributionsLoading', false)
)

const selectColumns = createSelector(
  selectFrmRecord, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectFrmRecord, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectFrmRecord, state => get(state, 'totalItems', 0)
)

const selectContributionsError = createSelector(
  selectFrmRecord, state => get(state, 'contributionError', null)
)

const selectDataFilters = createSelector(
  selectFrmRecord, state => get(state, 'dataFilters', [])
)

const selectDataFiltersErrorError = createSelector(
  selectFrmRecord, state => get(state, 'dataFiltersError', null)
)

const selectPaymentsMadeSuccess = createSelector(
  selectFrmRecord, state => get(state, 'paymentsMade', [])
)

const selectPaymentsMadeError = createSelector(
  selectFrmRecord, state => get(state, 'errorPaymentsMade', null)
)

const selectPaymentsMadeLoading = createSelector(
  selectFrmRecord, state => get(state, 'paymentsMadeLoading', null)
)

const selectModalDataResponse = createSelector(
  selectFrmRecord, state => get(state, 'modalData', null)
)

const selectLoadingProfile = createSelector(
  selectFrmRecord, state => get(state, 'loadingProfile', null)
)

const selectMessageInformation = createSelector(
  selectFrmRecord, state => get(state, 'messageInformation', null)
)

const selectFileLoading = createSelector(
  selectFrmRecord, state => get(state, 'loadingFile', null)
)

const selectFilesSuccess = createSelector(
  selectFrmRecord, state => get(state, 'files', null)
)

const selectFileMessage = createSelector(
  selectFrmRecord, state => get(state, 'fileMessage', null)
)

const selectContributionPaymentsLoading = createSelector(
  selectFrmRecord, state => get(state, 'contributionPaymentsLoading', null)
)

const selectContributionPaymentsSuccess = createSelector(
  selectFrmRecord, state => get(state, 'contributionPayments', null)
)

const selectContributionPaymentsError = createSelector(
  selectFrmRecord, state => get(state, 'contributionPaymentsError', null)
)

const selectInboundPaymentsOrigins = createSelector(
  selectFrmRecord, state => get(state, 'inboundPaymentsOrigins', null)
)

export default {
  PERSON_SUCCESS: selectPersonSucces,
  FRM_RECORD_SUCCESS: selectFrmRecordSucces,
  ERROR: selectError,

  LOADING: selectLoading,
  CONTRIBUTIONS_LOADING: selectContributionsLoading,

  CONTRIBUTIONS_SUCCESS: selectContributionsSuccess,
  CONTRIBUTIONS_ERROR: selectContributionsError,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  PAYMENTS_MADE_SUCCESS: selectPaymentsMadeSuccess,
  PAYMENTS_MADE_ERROR: selectPaymentsMadeError,
  PAYMENTS_MADE_LOADING: selectPaymentsMadeLoading,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersErrorError,

  MODAL_DATA_RESPONSE: selectModalDataResponse,
  LOADING_PROFILE: selectLoadingProfile,
  MESSAGE_INFORMATION: selectMessageInformation,

  FILE_LOADING: selectFileLoading,
  FILES_SUCCESS: selectFilesSuccess,
  FILE_MESSAGE: selectFileMessage,

  CONTRIBUTION_PAYMENTS_LOADING: selectContributionPaymentsLoading,
  CONTRIBUTION_PAYMENTS_SUCCESS: selectContributionPaymentsSuccess,
  CONTRIBUTION_PAYMENTS_ERROR: selectContributionPaymentsError,

  ORIGINS: selectInboundPaymentsOrigins
}
