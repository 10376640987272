export const columnData = {
  Code: { name: 'Código', widthCol: '5%' },
  Description: { name: 'Descripcion', widthCol: '10%' },
  BankAccountName: { name: 'Entidad bancaria', widthCol: '10%' },
  TotalAmount: { name: 'Monto total', widthCol: '10%' },
  StatusName: { name: 'Estado', widthCol: '10%' },
  CreationDate: { name: 'Fecha de Creación', widthCol: '15%' },
  PaymentDate: { name: 'Fecha de Pago', widthCol: '15%' },
  OutboundSettlement: { name: 'Liquidación Pago', widthCol: '15%' },
  Currency: { name: 'Divisa', widthCol: '10%' }
}
