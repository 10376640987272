export const BASIC_INPUT_TYPES = {
  TEXT: 'text',
  EMAIL: 'email',
  TEL: 'tel',
  CELLPHONE: 'cellphone',
  PASSWORD: 'password',
  NUMBER: 'number',
  NAME: 'name',
  TEXTAREA: 'textarea',
  RADIO: 'radio'
}

export const INPUT_TYPES = {
  ...BASIC_INPUT_TYPES,
  DATE: 'date',
  DROPDOWN: 'dropdown',
  PWREPEAT: 'passwordRepeat'
}
