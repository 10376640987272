import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectBenefits = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectBenefits, state => get(state, 'loading', null)
)

const selectLoadingBenefits = createSelector(
  selectBenefits, state => get(state, 'benefitsLoading', null)
)

const selectSuccess = createSelector(
  selectBenefits, state => get(state, 'benefitsList', [])
)

const selectColumns = createSelector(
  selectBenefits, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectBenefits, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectBenefits, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectBenefits, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectBenefits, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectBenefits, state => get(state, 'dataFiltersError', null)
)

const selectSetBenefitInformation = createSelector(
  selectBenefits, state => get(state, 'benefitInformation', null)
)

const selectBenefitTypesSuccess = createSelector(
  selectBenefits, state => get(state, 'benefitTypes', null)
)

const selectBenefitTypesError = createSelector(
  selectBenefits, state => get(state, 'benefitTypesError', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingBenefits,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,
  SET_BENEFIT_INFORMATION: selectSetBenefitInformation,
  BENEFIT_TYPES_SUCCESS: selectBenefitTypesSuccess,
  BENEFIT_TYPES_ERROR: selectBenefitTypesError
}
