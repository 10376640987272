import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Exports from '@containers/exports'
import ExportProfile from '@containers/exportProfile'

export const ExportRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={url}
        component={Exports}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.EXPORT_PROFILE}/:jobId`}
        component={ExportProfile}
      />
    </Switch>
  )
}
