import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { FRM_RECORD_ACTIONS } from './actions'
import { FRM_RECORD } from './types'

const apiCalls = {
  getPersonData: ({ personId, token }) => (
    Axios(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecordsContributions: ({ recordId, data, token }) => (
    Axios.post(`FRMRecord/${recordId}/contribution`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecordsPaymentsMade: ({ data, token }) => (
    Axios.post('OutboundSettlementDetail/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecord: ({ recordId, token }) => (
    Axios(`FRMRecord/${recordId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecordStatuses: token => (
    Axios('FRMRecord/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecordCategories: token => (
    Axios('FRMRecordCategory',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ recordId, data, token }) => (
    Axios.post(`FRMRecord/${recordId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFrmRecordFiles: ({ recordId, token }) => (
    Axios.get(`FRMRecord/${recordId}/files`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  downloadFrmRecordFile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteFrmRecordFile: ({ fileId, token }) => (
    Axios.delete(`FRMRecord/files/${fileId}/delete`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addFileToFrmRecord: ({ recordId, data, token }) => (
    Axios.post(`FRMRecord/${recordId}/files/add`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordsConPay: ({ data, token }) => (
    Axios.post('InboundPayment/contributionpayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getInboundPaymentsOrigins: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getAffiliateRecordData ({ payload }) {
  try {
    const { data: frmRecord } = yield call(apiCalls.getFrmRecord, payload)
    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_SUCCESS(frmRecord))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_ERROR(error))
  }
}

export function* getPersonInfo ({ payload }) {
  try {
    const { data: person } = yield call(apiCalls.getPersonData, payload)

    yield put(FRM_RECORD_ACTIONS.GET_PERSON_DATA_SUCCESS(person))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_ERROR(error))
  }
}

export function* getContributions ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getFrmRecordsContributions, payload)

    yield put(FRM_RECORD_ACTIONS.GET_CONTRIBUTIONS_SUCCESS(data))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_CONTRIBUTIONS_ERROR(error))
  }
}

export function* getPaymentsMade ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getFrmRecordsPaymentsMade, payload)

    yield put(FRM_RECORD_ACTIONS.GET_PAYMENTS_MADE_SUCCESS(data))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_PAYMENTS_MADE_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const { type } = payload
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(FRM_RECORD_ACTIONS.FILTER_DATA_SUCCESS({ type, parametersData: filtersObj.parameters }))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* modalData ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getFrmRecordStatuses, payload)
    const { data: categories } = yield call(apiCalls.getFrmRecordCategories, payload)
    const modalDataObj = {
      statuses,
      categories
    }
    yield put(FRM_RECORD_ACTIONS.MODAL_DATA_RESPONSE({ status: 200, modalDataObj }))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.MODAL_DATA_RESPONSE(error))
  }
}

export function* editProfile ({ payload }) {
  try {
    const { status, data: updatedFrmRecord } = yield call(apiCalls.editProfile, payload)

    yield put(FRM_RECORD_ACTIONS.UPDATE_PROFILE(updatedFrmRecord))
    yield put(FRM_RECORD_ACTIONS.EDIT_PROFILE_RESPONSE(status))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.EDIT_PROFILE_RESPONSE(error))
  }
}

export function* getFrmRecordFiles ({ payload }) {
  try {
    const { data: files } = yield call(apiCalls.getFrmRecordFiles, payload)

    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_FILES_SUCCESS(files))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_FILES_ERROR(error))
  }
}

export function* downloadFrmRecordFile ({ payload }) {
  try {
    const res = yield call(apiCalls.downloadFrmRecordFile, payload)
    const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]

    const data = {
      status: res.status,
      data: res.data,
      contentType: res.headers['content-type'],
      fileName
    }

    yield put(FRM_RECORD_ACTIONS.FRM_RECORD_FILE_MESSAGE(data))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.FRM_RECORD_FILE_MESSAGE(error))
  }
}

export function* removeFrmRecordFile ({ payload }) {
  try {
    const { recordId, fileId, token } = payload

    yield call(apiCalls.deleteFrmRecordFile, { fileId, token })
    const { data: updatedFileList } = yield call(apiCalls.getFrmRecordFiles, { recordId, token })

    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_FILES_SUCCESS(updatedFileList))
    yield put(FRM_RECORD_ACTIONS.MESSAGE_INFORMATION(200))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* uploadFrmRecordFile ({ payload }) {
  try {
    const { token, recordId } = payload
    yield call(apiCalls.addFileToFrmRecord, payload)
    const { status, data: updatedFileList } = yield call(apiCalls.getFrmRecordFiles, { recordId, token })

    yield put(FRM_RECORD_ACTIONS.GET_FRM_RECORD_FILES_SUCCESS(updatedFileList))
    yield put(FRM_RECORD_ACTIONS.MESSAGE_INFORMATION(status))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getContributionPayments ({ payload }) {
  try {
    const { token } = payload
    const { data: contributionPaymentsData } = yield call(apiCalls.getAffiliateRecordsConPay, payload)
    const { data: originData } = yield call(apiCalls.getInboundPaymentsOrigins, token)

    yield put(FRM_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS_SUCCESS({ contributionPaymentsData, originData }))
  } catch (error) {
    yield put(FRM_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(FRM_RECORD.GET_FRM_RECORD, getAffiliateRecordData)
  yield takeLatest(FRM_RECORD.GET_CONTRIBUTIONS, getContributions)
  yield takeLatest(FRM_RECORD.GET_PAYMENTS_MADE, getPaymentsMade)
  yield takeLatest(FRM_RECORD.GET_FILTER_DATA, filterData)
  yield takeLatest(FRM_RECORD.GET_MODAL_DATA, modalData)
  yield takeLatest(FRM_RECORD.EDIT_PROFILE, editProfile)
  yield takeLatest(FRM_RECORD.GET_FRM_RECORD_FILES, getFrmRecordFiles)
  yield takeLatest(FRM_RECORD.DOWNLOAD_FRM_RECORD_FILE, downloadFrmRecordFile)
  yield takeLatest(FRM_RECORD.REMOVE_FRM_RECORD_FILE, removeFrmRecordFile)
  yield takeLatest(FRM_RECORD.FRM_RECORD_UPLOAD_FILE, uploadFrmRecordFile)
  yield takeLatest(FRM_RECORD.GET_PERSON_DATA, getPersonInfo)
  yield takeLatest(FRM_RECORD.GET_CONTRIBUTION_PAYMENTS, getContributionPayments)
}
