import { LIQUIDATIONS } from './types'

export const getLiquidations = payload => ({
  type: LIQUIDATIONS.GET,
  payload
})

export const getLiquidationsSuccess = payload => ({
  type: LIQUIDATIONS.SUCCESS,
  payload
})

export const getLiquidationsError = payload => ({
  type: LIQUIDATIONS.ERROR,
  payload
})

export const ClearLiquidationsList = payload => ({
  type: LIQUIDATIONS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: LIQUIDATIONS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: LIQUIDATIONS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: LIQUIDATIONS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: LIQUIDATIONS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: LIQUIDATIONS.FILTER_DATA_CLEAR
})

export const getStatuses = payload => ({
  type: LIQUIDATIONS.GET_STATUSES,
  payload
})

export const liquidationsStatuses = payload => ({
  type: LIQUIDATIONS.LIQUIDATIONS_STATUSES,
  payload
})

export const addLiquidation = payload => ({
  type: LIQUIDATIONS.ADD_LIQUIDATION,
  payload
})

export const addLiquidationInformation = payload => ({
  type: LIQUIDATIONS.ADD_LIQUIDATION_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: LIQUIDATIONS.CLEAR_MESSAGE_INFORMATION
})

export const LIQUIDATIONS_ACTIONS = {
  GET_LIQUIDATIONS: getLiquidations,
  SUCCESS: getLiquidationsSuccess,
  ERROR: getLiquidationsError,
  CLEAR: ClearLiquidationsList,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_STATUSES: getStatuses,
  LIQUIDATIONS_STATUSES: liquidationsStatuses,
  ADD_LIQUIDATION: addLiquidation,
  ADD_LIQUIDATION_INFORMATION: addLiquidationInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation
}
