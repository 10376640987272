import { roboto } from '@global/fonts'
import { colors, spacing } from '@global/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const MyLabel = styled.label`
  ${roboto}
  margin: ${spacing.one} 0;
  color: ${colors.contrast.regular};
`
