import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { IMPORTS_ACTIONS } from './actions'
import { IMPORTS } from './types'

const ASYNC_JOB_TYPES = 'AsyncJobTypes'
const IMPORT = 'importación'

const apiCalls = {
  getData: ({ data, token }) => (
    Axios.post('AsyncJob/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getJobTypes: token => (
    Axios('AsyncJob/types',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getJobStatuses: token => (
    Axios('AsyncJob/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  uploadFile: ({ jobTypeId, fileId, token }) => (
    Axios.post(`AsyncJob/import?jobTypeId=${jobTypeId}&fileId=${fileId}`, { jobTypeId, fileId },
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getImports ({ payload }) {
  try {
    const { token } = payload
    let objImports = {}
    const res = yield call(apiCalls.getData, payload)
    objImports = res.data
    let { data: jobTypes } = yield call(apiCalls.getJobTypes, token)
    jobTypes = jobTypes.filter(t => t.action === 1)
    objImports = {
      ...objImports,
      jobTypes
    }

    yield put(IMPORTS_ACTIONS.SUCCESS(objImports))
  } catch (error) {
    yield put(IMPORTS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    filtersObj.parameters.map(p => {
      if (p.name === ASYNC_JOB_TYPES) {
        p.content = p.content.filter(c => c.name.toLowerCase().includes(IMPORT))
        return p
      }
      return p
    })

    yield put(IMPORTS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(IMPORTS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* addImports ({ payload }) {
  try {
    const { data: status } = yield call(apiCalls.addPerson, payload)
    yield put(IMPORTS_ACTIONS.ADD_PERSON_INFORMATION(status))
  } catch (error) {
    yield put(IMPORTS_ACTIONS.ADD_PERSON_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(IMPORTS.GET, getImports)
  yield takeLatest(IMPORTS.GET_FILTER_DATA, FilterData)
}
