import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import MyPropTypes from '@global/propTypes'

import { GhostButton } from '@components/button'
import { TextInput } from '@components/inputs/inputs'

import { SearchBarContainer } from './styles'

export const SearchBar = ({ onChange, value, color, disabled, visible, id = 0 }) => {
  const [showInput, setShowInput] = useState(visible || null)
  const debounceOnChange = debounce(onChange, 1000)

  const inp = document.getElementById(`text_search_${id}`)

  useEffect(() => {
    if (showInput) {
      if (inp) inp.focus()
    } else if (inp && inp?.value) {
      inp.value = ''
      onChange()
    }
  }, [showInput])

  return (
    <SearchBarContainer show={showInput} name={`searchBar_${id}`}>
      <TextInput
        name={`search_${id}`}
        placeholder='Buscar'
        color={color || 'system'}
        height='small'
        bits={[{ icon: 'search', position: 'left', colorBit: (color || 'system') }]}
        onChange={debounceOnChange}
        disabled={disabled}
        value={value}
        removeValue
      />
      <GhostButton
        color={color || 'system'}
        size='small'
        icon='search'
        onClick={() => setShowInput(!showInput)}
        disabled={disabled}
      />
    </SearchBarContainer>
  )
}
SearchBar.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  visible: PropTypes.bool,
  color: MyPropTypes.color,
  id: PropTypes.number
}

export default SearchBar
