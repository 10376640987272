import { useState, useRef, useCallback, useEffect } from 'react'

export const useClickOutside = (ref, handler) => {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, []
  )
}

export const useSize = () => {
  const observer = useRef()
  const nullSize = { width: null, height: null }
  const [currentSize, setCurrentSize] = useState(nullSize)

  const getSize = elements => {
    const el = elements[0].target
    if (!el) return

    const width = el.offsetWidth ?? 0
    const height = el.offsetHeight ?? 0

    setCurrentSize({ width, height })
  }

  const target = useCallback(
    element => {
      if (element) {
        observer.current = new ResizeObserver(getSize).observe(element)
      }
    },
    []
  )

  useEffect(() => () => {
    if (observer.current) observer.current.disconnect()
  }, [])

  return [target, currentSize]
}

const useScreenWidth = () => {
  const size = document.documentElement.scrollWidth || 1200
  const [width, setWidth] = useState(size)
  useEffect(() => {
    const handleResize = () => {
      setWidth(document.documentElement.scrollWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return width
}

export default useScreenWidth
