import React, { useState, useEffect } from 'react'

import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  TOKEN_HELPERS,
  formatDateBasic,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  currencyFormatUY,
  strMonthYear
} from '@utils/helpers'
import { SITE } from '@routes/paths'
import {
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  TextContainer,
  WrapperBreadcrumbs,
  WrapperLoading,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  ButtonContainer,
  Column,
  WrapperErrorTextColumn
} from '@global/styles'
import {
  TRANSLATION_OF_ROUTES,
  TOAST_PROPERTIES,
  TOAST_POSITION,
  TYPE_PERSONAL_INFORMATION_MODAL,
  rowsPerPage,
  BREAKPOINTS,
  SUBTITLES_TABLES,
  MONTHS,
  DETAIL_TYPES,
  BMS_NAMES,
  NO_ORDER_COLUMN_BY,
  COLUMN_ERROR
} from '@global/constants'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Table from '@components/table'
import { useUser } from '@components/authentication/utils/hook'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import Toast from '@components/toast'
import Badge from '@components/badge'
import Modal from '@components/modal'
import Icon from '@components/icon'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import DeleteConfirmationModal from '../../components/modal/components/deleteConfirmationModal'

import {
  DataContainer,
  Tbody,
  Td,
  Tr,
  PersonalData,
  LinkContainer,
  SalaryContainer,
  CustomButtonLink,
  SbmContainer
} from './styles'

import FileDataModal from './components/fileModal'

import useRedux from './redux'
import { FRM_RECORD_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import FrmRecordProfileModal from './components/frmProfileModal'

import {
  COLUMN_DATA_CONTRIBUTIONS,
  COLUMN_DATA_PAYMENTS,
  COLUMN_FILE_DATA,
  FILTER_TYPE,
  COLUMN_CONTRIBUTION_PAYMENTS
} from './data'

const FrmRecord = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { recordId } = useParams()
  const { pathname, state } = useLocation()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [person, setPerson] = useState(null)
  const [frmRecord, setFrmRecord] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState(null)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [selectedOptionPayments, setSelectedOptionPayments] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [itemSelectedPayments, setItemSelectedPayments] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [filterData, setFilterData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [showFilterPayments, setShowFilterPayments] = useState(false)
  const [badges, setBadges] = useState([])
  const [badgesPayments, setBadgesPayments] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [open, setOpen] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [activeFiltersContributions, setActiveFiltersContributions] = useState(null)
  const [activeFiltersPayments, setActiveFiltersPayments] = useState(null)
  const [pagesPayments, setPagesPayments] = useState()
  const [totalItemsPayments, setTotalItemsPayments] = useState(0)
  const [tablelistPayments, setTablelistPayments] = useState([])
  const [columnsPayments, setColumnsPayments] = useState([])
  const [tablelistFiles, setTablelistFiles] = useState([])
  const [columnsFiles, setColumnsFiles] = useState([])
  const [editFrmProfileModal, setEditFrmProfileModal] = useState()
  const [modalTitle, setModalTitle] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [fileModal, setFileModal] = useState(false)
  const [removeItemModal, setRemoveItemModal] = useState(null)
  const [flag, setFlag] = useState(false)
  const [errorFlag, setErrorFlag] = useState({
    frm: false,
    contributions: false,
    payments: false
  })
  const [parametersCont, setParametersCont] = useState()
  const [parametersPayment, setParametersPayment] = useState()

  const [pagesCP, setPagesCP] = useState()
  const [totalItemsCP, setTotalItemsCP] = useState(0)
  const [tablelistCP, setTablelistCP] = useState([])
  const [columnsCP, setColumnsCP] = useState([])
  const [showTableCP, setShowTableCP] = useState(false)
  const [selectedOptionCP, setSelectedOptionCP] = useState(rowsPerPage[0])
  const [itemSelectedCP, setItemSelectedCP] = useState()
  const [activeFiltersCP, setActiveFiltersCP] = useState(null)
  const [showFilterCP, setShowFilterCP] = useState(false)
  const [badgesCP, setBadgesCP] = useState([])
  const [parametersCP, setParametersCP] = useState()
  const [errorColumnMessageContributions, setErrorColumnMessageContributions] = useState(COLUMN_ERROR)
  const [errorColumnMessagePayment, setErrorColumnMessagePayment] = useState(COLUMN_ERROR)

  const loading = useSelector(SELECTORS.LOADING)
  const FrmRecordData = useSelector(SELECTORS.FRM_RECORD_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const personData = useSelector(SELECTORS.PERSON_SUCCESS)
  const loadingProfile = useSelector(SELECTORS.LOADING_PROFILE)
  const contributions = useSelector(SELECTORS.CONTRIBUTIONS_SUCCESS)
  const itemsError = useSelector(SELECTORS.CONTRIBUTIONS_ERROR)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const contributionLoading = useSelector(SELECTORS.CONTRIBUTIONS_LOADING)

  const paymentsMade = useSelector(SELECTORS.PAYMENTS_MADE_SUCCESS)
  const paymentsMadeError = useSelector(SELECTORS.PAYMENTS_MADE_ERROR)
  const paymentsMadeLoading = useSelector(SELECTORS.PAYMENTS_MADE_LOADING)

  const loadingFile = useSelector(SELECTORS.FILE_LOADING)
  const filesData = useSelector(SELECTORS.FILES_SUCCESS)
  const fileMessage = useSelector(SELECTORS.FILE_MESSAGE)

  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterObjectError = useSelector(SELECTORS.ERROR_FILTER_OBJECT)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)

  const conPayLoading = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_LOADING)
  const conPaymentsData = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_SUCCESS)
  const conPaymentsDataError = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_ERROR)
  const origins = useSelector(SELECTORS.ORIGINS)

  const renderFrmRecordData = objFrmRecordData => (
    <>
      <Tr>
        <Td colorLight widthPerc>Fecha ingreso (F.R.M.):</Td>
        <Td largeSize>{formatDateBasic(objFrmRecordData.startDate, 'short') || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Fecha egreso (F.R.M.):</Td>
        <Td largeSize>{formatDateBasic(objFrmRecordData.endDate, 'short') || 'N/A'}</Td>
      </Tr>
      <Tr><Td colorLight widthPerc>Categoría:</Td> <Td largeSize>{objFrmRecordData.categoryName || 'N/A'}</Td></Tr>
      <Tr>
        <Td colorLight widthPerc>Estado:</Td>
        <Td largeSize>
          <Badge text={objFrmRecordData.statusName} backgroundColor='secondary' size='medium' color='contrast' />
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>{BMS_NAMES.BMS_DELTA}:</Td>
        <Td largeSize>{objFrmRecordData.bmsDelta?.toFixed(2) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>{BMS_NAMES.BMS_BONUS_COEFFICIENT}:</Td>
        <Td largeSize>{objFrmRecordData.bmsBonusCoefficient?.toFixed(2) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Aporte de no empleado:</Td>
        <Td largeSize>{currencyFormatUY(objFrmRecordData.nonEmployeeFee) || 'N/A'}</Td>
      </Tr>
      {!!objFrmRecordData.refundAmountPaid
        && (
          <Tr>
            <Td colorLight widthPerc>Devolución 10%:</Td>
            <Td largeSize>{currencyFormatUY(objFrmRecordData?.refundAmountPaid) || 'N/A'}</Td>
          </Tr>
        )}
      {!!objFrmRecordData?.totalAmountInbound
        && (
          <Tr>
            <Td colorLight widthPerc>Total Aportado:</Td>
            <Td largeSize>{currencyFormatUY(objFrmRecordData?.totalAmountInbound) || 'N/A'}</Td>
          </Tr>
        )}
      {!!objFrmRecordData?.totalAmountInbound
        && (
          <Tr>
            <Td colorLight widthPerc>Total Pagado:</Td>
            <Td largeSize>{currencyFormatUY(objFrmRecordData?.totalAmountOutbound) || 'N/A'}</Td>
          </Tr>
        )}
      {(objFrmRecordData?.statusId === 7 || objFrmRecordData?.statusId === 4)
        && (
          <Tr>
            <Td colorLight widthPerc>{
            `Fecha de ${objFrmRecordData?.statusId === 7 ? 'desafiliación' : 'retiro'} en proceso:`
            }
            </Td>
            <Td largeSize>{formatDateBasic(objFrmRecordData.endInProcessDate, 'short')}</Td>
          </Tr>
        )}
    </>
  )

  const getFrmRecordData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.GET_FRM_RECORD({ recordId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributions = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.GET_CONTRIBUTIONS({ recordId, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getPayments = body => {
    body.statuses = [1]
    body.frmRecordId = Number(recordId)
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.GET_PAYMENTS_MADE({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getContributionPayments = body => {
    body.statuses = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditFrmProfileModal(null)
    setFileModal(null)
    setRemoveItemModal(null)
  }

  const sendToLiquidationProfile = settlementId => {
    const win = window.open(`${SITE.LIQUIDATION_LOG}/${settlementId}`, '_blank')
    win.focus()
  }

  const sendToContributionPaymentClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const sendToContributionClick = contributionId => {
    const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
    win.focus()
  }

  const handleSendToContributionPaymentList = contributionId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${contributionId}/${DETAIL_TYPES.CONTRIBUTION_PAYMENT}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { contributionPayments } = item
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionClick(item.id)}
        >
          {`C - ${item.id}`}
        </CustomButtonLink>
      )

      const settlement = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToLiquidationProfile(item.settlementId)}
        >
          {strMonthYear(item.settlementMonth - 1, item.settlementYear)}
        </CustomButtonLink>
      )

      let link = 'N/A'
      contributionPayments = contributionPayments.filter(cp => cp.statusId === 1)
      if (contributionPayments?.length > 0) {
        link = contributionPayments?.length === 1
          ? (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => sendToContributionPaymentClick(contributionPayments[0].id)}
            >
              {`DI - ${contributionPayments[0].id}`}
            </CustomButtonLink>
          )
          : (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToContributionPaymentList(item.id)}
            >
              Ver lista de DI
            </CustomButtonLink>
          )
      }

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        settlement,
        total: currencyFormatUY(item.total),
        concept: item.concept,
        status: item.status,
        paymentDate: item.paymentDate,
        dueDate: item.dueDate,
        totalPaid,
        description: item.description,
        link
      }
      return newItem
    })
    return newData
  }

  // Payments table

  const sendToOutboundSettlementDetailClick = outboundSettlementDetailId => {
    if (outboundSettlementDetailId) {
      const win = window.open(`${SITE.DEBT_PROFILE}/${outboundSettlementDetailId}`, '_blank')
      win.focus()
    }
  }

  const sendToOutboundSettlementClick = outboundSettlementId => {
    if (outboundSettlementId) {
      const win = window.open(`${SITE.PAYMENT_SETTLEMENT_PROFILE}/${outboundSettlementId}`, '_blank')
      win.focus()
    }
  }

  const handleSendToPaymentDetailList = debtId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${debtId}/${DETAIL_TYPES.DEBT}`, '_blank')
    win.focus()
  }

  const handlePaymentDetailClick = detailId => {
    if (detailId) {
      const win = window.open(`${SITE.PAYMENT_DETAIL}/${detailId}`, '_blank')
      win.focus()
    }
  }

  const customOutboundSettlementDetailTable = itemsTable => {
    const newData = itemsTable.map(item => {
      let { payments } = item

      let link = 'N/A'
      payments = payments.filter(cp => cp.statusId === 1)
      if (payments?.length > 0) {
        link = payments?.length === 1
          ? (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handlePaymentDetailClick(payments[0].id)}
            >
              {`DP - ${payments[0].id}`}
            </CustomButtonLink>
          )
          : (
            <CustomButtonLink
              color='tertiary'
              underline
              marginRight
              onClick={() => handleSendToPaymentDetailList(item.id)}
            >
              Ver lista de DP
            </CustomButtonLink>
          )
      }

      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToOutboundSettlementDetailClick(item.id)}
        >
          {`A - ${item.id}`}
        </CustomButtonLink>
      )

      const liquidation = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToOutboundSettlementClick(item.outboundSettlementId)}
        >
          {strMonthYear(item.month - 1, item.year)}
        </CustomButtonLink>
      )

      let colorTotalPaid = 'system'
      if (item.totalPaid && item.total) {
        if (item.totalPaid > item.total) colorTotalPaid = 'warning'
        if (item.totalPaid < item.total) colorTotalPaid = 'error'
      }

      const totalPaid = (
        <Text
          size='medium'
          align='left'
          color={colorTotalPaid}
          weight={colorTotalPaid !== 'system' ? 'semibold' : 'regular'}
        >
          {currencyFormatUY(item.totalPaid) || 'N/A'}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        datecreated: item.dateCreated,
        liquidation,
        currency: item.currency,
        total: currencyFormatUY(item.total),
        status: item.statusName,
        dueDate: item.dueDate,
        link,
        totalPaid
      }
      return newItem
    })
    return newData
  }

  // contribution payment table
  const sendToContributionProfile = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const sendToContributionPaymentProfile = contributioPaymentId => {
    if (contributioPaymentId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributioPaymentId}`, '_blank')
      win.focus()
    }
  }

  const customDataTableCP = itemsTableCP => {
    const newData = itemsTableCP.map(item => {
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionPaymentProfile(item.id)}
        >
          {`DI - ${item.id}`}
        </CustomButtonLink>
      )

      const contribution = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionProfile(item.contributionId)}
        >
          {`C - ${item.contributionId}`}
        </CustomButtonLink>
      )

      const originName = item.originName || origins?.find(o => o.id === item.originId)?.name || 'N/A'

      const newItem = {
        id: item.id,
        code,
        concept: item.concept,
        originName,
        currency: item.currencyName,
        amount: currencyFormatUY(item.amount),
        bms: item.bmsAmount?.toFixed(2) || 'N/A',
        contribution,
        status: item.statusName,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month)?.label : 'N/A',
        year: item.year
      }
      return newItem
    })
    return newData
  }

  // file table
  const customHeaderDataFileTable = colData => {
    const deleteIcon = (<Icon name='delete' backgroundColor='primary' size='medium' color='grays' />)
    const customColumns = Object.values(colData).map(item => {
      if (item.name === 'Borrar') {
        let obj = item
        obj = {
          ...obj,
          name: deleteIcon
        }
        return obj
      }
      return item
    })
    return customColumns
  }

  const uploadFile = objFile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const objToSend = {
        data: objFile,
        recordId,
        token
      }
      dispatch(FRM_RECORD_ACTIONS.FRM_RECORD_UPLOAD_FILE(objToSend))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const downloadFile = fileItem => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const idDownload = fileItem.split('-')[1]
      dispatch(FRM_RECORD_ACTIONS.DOWNLOAD_FRM_RECORD_FILE({ fileId: idDownload, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const removeFile = fileItem => {
    const idRemove = fileItem.split('-')[0]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.REMOVE_FRM_RECORD_FILE({ recordId, fileId: idRemove, token }))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const openRemoveItemModal = (itemId, title, text, deleteFn, dataType = null) => {
    setModalTitle(title)
    setRemoveItemModal({
      close: handleCloseModal,
      text,
      itemId,
      removeItem: deleteFn,
      dataType
    })
    setOpen(true)
  }

  const customBodyFileTable = bodyData => {
    let newData = bodyData.map(item => {
      const currentDate = new Date(item.dateUpdated)
      const newObj = {
        id: `${item.id}-${item.fileId}`,
        date: item.dateUpdated,
        time: currentDate.toLocaleTimeString(),
        name: item.fileName,
        description: item.description
      }
      return newObj
    })

    const title = 'Borrar Archivo'
    const text = '¿Estás seguro que deseas borrar este archivo?'
    newData = newData.map(item => {
      let newItem = item
      const deleteIcon = (
        <Icon
          name='cancel'
          size='medium'
          color='grays'
          onClick={() => openRemoveItemModal(item.id, title, text, removeFile)}
        />
      )
      const downloadIcon = (
        <Icon name='archive' size='medium' color='primary' onClick={() => downloadFile(item.id)} />
      )

      newItem = {
        deleteFile: deleteIcon,
        ...newItem,
        downloadFile: downloadIcon
      }
      return newItem
    })
    return newData
  }

  // handlers contribution
  const handleDropdownChange = e => {
    let body = null
    if (activeFiltersContributions) {
      body = activeFiltersContributions
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributions(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleOrderChangeContribution = (nameColumn, typeOrder) => {
    const colNames = Object.entries(COLUMN_DATA_CONTRIBUTIONS).find(col => col[1].name === nameColumn)[1]

    if (!colNames.label) {
      setErrorColumnMessageContributions({
        columnName: colNames.name,
        error: true
      })
      return
    }
    setErrorColumnMessageContributions(COLUMN_ERROR)

    const propsForOrderBuilder = {
      filterState: activeFiltersPayments,
      setFilterState: setActiveFiltersPayments,
      columnName: colNames.label,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributions(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFiltersContributions.filterText

    let body = activeFiltersContributions ? { ...activeFiltersContributions } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersContributions({
        ...activeFiltersContributions,
        filterText: e.value
      })
    }
    getContributions(body)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(
      updateBadges, selectedOption.value, setActiveFiltersContributions, activeFiltersContributions, parametersCont
    )
    getContributions(body)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(
      filters, selectedOption.value, setActiveFiltersContributions, activeFiltersContributions, parametersCont
    )
    getContributions(body)
  }

  // handlers payments
  const handleDropdownChangePayments = e => {
    let body = null
    if (activeFiltersPayments) {
      body = { ...activeFiltersPayments }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getPayments(body)
    setSelectedOptionPayments(e.value)
    setItemSelectedPayments(0)
  }

  const handleInputChangePayments = e => {
    if (!e?.value) delete activeFiltersPayments.filterText

    let body = activeFiltersPayments ? { ...activeFiltersPayments } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersPayments({
        ...activeFiltersPayments,
        filterText: e.value
      })
    }
    getPayments(body)
  }

  const handleCloseBadgePayments = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadgesPayments(updateBadges)
    const body = filterBodyBuilder(
      updateBadges, selectedOptionPayments.value, setActiveFiltersPayments, activeFiltersPayments, parametersPayment
    )
    getPayments(body)
  }

  const handleApplyFilterPayments = filters => {
    setBadgesPayments(filters)
    const body = filterBodyBuilder(
      filters, selectedOptionPayments.value, setActiveFiltersPayments, activeFiltersPayments, parametersPayment
    )
    getPayments(body)
  }

  const handleOrderChangePayments = (nameColumn, typeOrder) => {
    const colNames = COLUMN_DATA_PAYMENTS.find(col => col.name === nameColumn)
    if (!colNames.label) {
      setErrorColumnMessagePayment({
        columnName: colNames.name,
        error: true
      })
      return
    }
    setErrorColumnMessagePayment(COLUMN_ERROR)

    const propsForOrderBuilder = {
      filterState: activeFiltersPayments,
      setFilterState: setActiveFiltersPayments,
      columnName: colNames.label,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionPayments.value,
      currentPageNumber: itemSelectedPayments,
      setNewItemSelected: setItemSelectedPayments
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getPayments(body)
  }

  // handler contribution payments
  const handleApplyFilterCP = filters => {
    setBadgesCP(filters)
    const body = filterBodyBuilder(filters, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP)
    getContributionPayments(body)
  }

  const handleInputChangeCP = e => {
    if (!e?.value) delete activeFiltersCP.filterText

    let body = activeFiltersCP ? { ...activeFiltersCP } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersCP({
        ...activeFiltersCP,
        filterText: e.value
      })
    }
    getContributionPayments(body)
  }

  const handleDropdownChangeCP = e => {
    let body = null
    if (activeFiltersCP) {
      body = activeFiltersCP
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributionPayments(body)
    setSelectedOptionCP(e.value)
    setItemSelectedCP(0)
  }

  const handleCloseBadgeCP = filterName => {
    const updateBadgesCP = badgesCP.filter(badge => badge.name !== filterName)
    setBadgesCP(updateBadgesCP)
    const body = filterBodyBuilder(
      updateBadgesCP, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP
    )
    getContributionPayments(body)
  }

  const handleOrderChangeCP = (nameColumn, typeOrder) => {
    const colNames = COLUMN_CONTRIBUTION_PAYMENTS.find(col => col.name === nameColumn).label
    const propsForOrderBuilder = {
      filterState: activeFiltersCP,
      setFilterState: setActiveFiltersCP,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionCP.value,
      currentPageNumber: itemSelectedCP,
      setNewItemSelected: setItemSelectedCP
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributionPayments(body)
  }

  // handler profile
  const handleAddDataFrmProfile = dataProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_RECORD_ACTIONS.EDIT_PROFILE({ recordId, data: dataProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleEditProfile = () => {
    setModalTitle('Editar registro F.R.M')
    setEditFrmProfileModal({
      oldData: frmRecord,
      close: handleCloseModal,
      saveData: handleAddDataFrmProfile,
      currentToken
    })
    setOpen(true)
  }

  // handlers file
  const handleFileClick = () => {
    setModalTitle('Agregar archivo')
    setFileModal(true)
    setOpen(true)
  }

  // shared handlers
  const handleClickShowFilters = type => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      let filterRequest = {}
      switch (type) {
        case FILTER_TYPE.CONTRIBUTIONS:
          filterRequest = { id: 10, token, type }
          break
        case FILTER_TYPE.PAYMENTS:
          filterRequest = { id: 16, token, type }
          break
        default:
          filterRequest = { id: 12, token, type }
          break
      }
      dispatch(FRM_RECORD_ACTIONS.GET_FILTER_DATA(filterRequest))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleClickCloseFilters = type => {
    dispatch(FRM_RECORD_ACTIONS.FILTER_DATA_CLEAR(type))
    switch (type) {
      case FILTER_TYPE.CONTRIBUTIONS:
        setShowFilter(!showFilter)
        break
      case FILTER_TYPE.PAYMENTS:
        setShowFilterPayments(!showFilterPayments)
        break
      default:
        setShowFilterCP(!showFilterCP)
        break
    }
  }

  const handleToastClose = () => setIsShowing(false)

  // renders tables
  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={
            n => handleClickPaginationItem(
              n, activeFiltersContributions, selectedOption.value, getContributions, setItemSelected
            )
          }
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  const renderPaymentTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pagesPayments}
          itemSelected={itemSelectedPayments}
          handleClickPaginationItem={
            n => handleClickPaginationItem(
              n, activeFiltersPayments, selectedOptionPayments.value, getPayments, setItemSelectedPayments
            )
          }
          options={rowsPerPage}
          selectedOption={selectedOptionPayments}
          handleDropdownChange={handleDropdownChangePayments}
          totalItems={totalItemsPayments}
          itemsPerPage={tablelistPayments.length}
        />
      </Table>
    )
  }

  const renderFileTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        error={error}
        showRowPointer={showRowPointer}
      />
    )
  }

  const renderTableCP = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pagesCP}
          itemSelected={itemSelectedCP}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFiltersCP, selectedOptionCP.value, getContributionPayments, setItemSelectedCP
          )}
          options={rowsPerPage}
          selectedOption={selectedOptionCP}
          handleDropdownChange={handleDropdownChangeCP}
          totalItems={totalItemsCP}
          itemsPerPage={tablelistCP.length}
        />
      </Table>
    )
  }

  const renderFilter = (type, arrFilters) => {
    let currentLoadBadges = null
    let currentShowFilter = null
    switch (type) {
      case FILTER_TYPE.CONTRIBUTIONS:
        currentLoadBadges = handleApplyFilter
        currentShowFilter = showFilter
        break
      case FILTER_TYPE.PAYMENTS:
        currentLoadBadges = handleApplyFilterPayments
        currentShowFilter = showFilterPayments
        break
      default:
        currentLoadBadges = handleApplyFilterCP
        currentShowFilter = showFilterCP
        break
    }

    return (
      <Filter
        close={() => handleClickCloseFilters(type)}
        loadBadges={currentLoadBadges}
        filterData={arrFilters}
        isShowing={currentShowFilter}
        device={device}
      />
    )
  }
  useEffect(() => {
    getFrmRecordData()
  }, [])

  useEffect(() => {
    if (FrmRecordData) {
      setFrmRecord(FrmRecordData)
      if (!flag) {
        const { personId } = FrmRecordData
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(FRM_RECORD_ACTIONS.GET_PERSON_DATA({ personId, token }))
          const body = {
            pageNumber: 1,
            pageSize: selectedOption.value,
            orderByDesc: true,
            orderByColumn: 'Id'
          }
          getContributions(body)
          setActiveFiltersContributions(body)
          const bodyCP = {
            pageNumber: 1,
            pageSize: selectedOption.value,
            orderByDesc: true,
            orderByColumn: 'Id',
            frmRecordId: recordId
          }
          getContributionPayments(bodyCP)
          setActiveFiltersCP(bodyCP)
          dispatch(FRM_RECORD_ACTIONS.GET_FRM_RECORD_FILES({ recordId, token }))
          setFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      }
    }
  }, [FrmRecordData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.frm) {
        getFrmRecordData()
        setErrorFlag({
          ...errorFlag,
          frm: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (itemsError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: itemsError.message || `Error ${itemsError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.contributions) {
        const body = { pageNumber: 1, pageSize: selectedOption.value }
        getContributions(body)
        setErrorFlag({
          ...errorFlag,
          contributions: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [itemsError])

  useEffect(() => {
    setPerson(personData)
  }, [personData])

  useEffect(() => {
    if (person) {
      if (state) {
        const lastCrumb = {
          text: TRANSLATION_OF_ROUTES.FRM_RECORD,
          link: false,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.PEOPLE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.PEOPLE,
          link: true,
          url
        }
        url += `${SITE.PERSON}/${person?.id}`
        const secondCrumb = {
          text: `${person?.billingNumber} ${person?.fullName}`,
          link: false,
          url
        }
        url += `${SITE.FRM_RECORD}/${recordId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.FRM_RECORD} - ${recordId}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [person])

  useEffect(() => {
    if (frmRecord && frmRecord.statusId === 3) {
      const bodyPayments = {
        frmRecordId: frmRecord.id,
        pageNumber: 1,
        pageSize: selectedOptionPayments.value
      }
      getPayments(bodyPayments)
      setActiveFiltersPayments(bodyPayments)
    }
  }, [frmRecord])

  useEffect(() => {
    // contribution data
    if (contributions) {
      setTablelist(customDataTable(contributions))
      setColumns(COLUMN_DATA_CONTRIBUTIONS)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
      setShowTable(true)
    }
  }, [contributions, totalPages])

  useEffect(() => {
    if (paymentsMade) {
      setTablelistPayments(customOutboundSettlementDetailTable(paymentsMade.items))
      setColumnsPayments(COLUMN_DATA_PAYMENTS)
      setPagesPayments(paymentsMade.totalPages)
      setItemSelectedPayments(paymentsMade.currentPage - 1)
      setTotalItemsPayments(paymentsMade.totalItems)
    }
  }, [paymentsMade])

  useEffect(() => {
    if (paymentsMadeError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: paymentsMadeError.message || `Error ${paymentsMadeError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.payments) {
        const body = { pageNumber: 1, pageSize: selectedOption.value }
        getPayments(body)
        setErrorFlag({
          ...errorFlag,
          payments: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [paymentsMadeError])

  useEffect(() => {
    if (filesData) {
      setTablelistFiles(customBodyFileTable(filesData))
      setColumnsFiles(customHeaderDataFileTable(COLUMN_FILE_DATA))
    }
  }, [filesData])

  useEffect(() => {
    if (conPaymentsData) {
      setTablelistCP(customDataTableCP(conPaymentsData.items))
      setColumnsCP(COLUMN_CONTRIBUTION_PAYMENTS)
      setPagesCP(conPaymentsData.totalPages)
      setItemSelectedCP(conPaymentsData.currentPage - 1)
      setTotalItemsCP(conPaymentsData.totalItems)
      if (conPaymentsData.items.length > 0) setShowTableCP(true)
    }
  }, [conPaymentsData])

  useEffect(() => {
    if (person) {
      if (!contributionLoading && !paymentsMadeLoading && !loadingFile && !conPayLoading) {
        if (loading) dispatch(FRM_RECORD_ACTIONS.DATA_UPLOAD_READY())
      }
    }
  }, [contributionLoading, paymentsMadeLoading, loadingFile, person, conPayLoading])

  useEffect(() => {
    if (filterObject) {
      const { type, parametersData } = filterObject
      const parametersMap = parametersData.map(p => p.name)
      setFilterData(parametersData)
      switch (type) {
        case FILTER_TYPE.CONTRIBUTIONS:
          setParametersCont(parametersMap)
          setShowFilter(!showFilter)
          break
        case FILTER_TYPE.PAYMENTS:
          setParametersPayment(parametersMap)
          setShowFilterPayments(!showFilterPayments)
          break
        default:
          setParametersCP(parametersMap)
          setShowFilterCP(!showFilterCP)
          break
      }
    }
  }, [filterObject])

  useEffect(() => {
    if (filterObjectError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: filterObjectError.message || `Error ${filterObjectError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }
  }, [filterObjectError])

  useEffect(() => {
    if (messageInformation) {
      let toastData = null
      if (messageInformation >= 200 || messageInformation <= 299) {
        toastData = {
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        }
      } else {
        toastData = {
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        }
      }
      setIsShowing(true)
      setMessageToast(toastData)
      dispatch(FRM_RECORD_ACTIONS.CLEAR_INFORMATION_MESSAGE())
      setTimeout(() => {
        setMessageToast({
          title: '',
          message: '',
          icon: '',
          color: ''
        })
      }, 5000)
    }
  }, [messageInformation])

  useEffect(() => {
    if (conPaymentsDataError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: conPaymentsDataError.message || `Error ${conPaymentsDataError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag.conontributionPayments) {
        const bodyCP = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByDesc: true,
          orderByColumn: 'Id',
          frmRecordId: recordId
        }
        getContributionPayments(bodyCP)
        setErrorFlag({
          ...errorFlag,
          conontributionPayments: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [conPaymentsDataError])

  useEffect(() => {
    if (!fileMessage) return
    if (fileMessage.status === 200 && fileMessage.data) {
      const { data, contentType } = fileMessage
      let { fileName } = fileMessage
      // remove the \&quot
      fileName = fileName.replace(/[^a-zA-Z0-9 .]/g, '')

      const blob = new Blob([data], { type: contentType })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
  }, [fileMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
                <WrapperBreadcrumbs columnStart={1} columnEnd={12} rowStart={1} rowEnd={1}>
                  <Breadcrumbs crumbs={breadcrumbs} />
                </WrapperBreadcrumbs>
              )}
            <>
              <WrapperHeading
                flex='2'
                device={device}
                columnStart={1}
                columnEnd={12}
                rowStart={2}
                rowEnd={2}
              >
                <Heading
                  weight='regular'
                  size='large'
                  text={`${TRANSLATION_OF_ROUTES.FRM_RECORD} - ${frmRecord?.id}`}
                  type='h2'
                  align='left'
                />
                <MyLink
                  color='primary'
                  to={{
                    pathname: `${SITE.HISTORY}${pathname}`,
                    state: { breadcrumbs }
                  }}
                  textDecoration='underline'
                >
                  Historial de Actividad
                </MyLink>
              </WrapperHeading>
              <DataContainer device={device}>
                {loadingProfile
                  ? (
                    <WrapperLoading>
                      <Loading color='primary' size='xsmall' weight='bold' />
                    </WrapperLoading>
                  )
                  : (
                    <>
                      <PersonalData device={device}>
                        <Tbody>
                          {frmRecord && renderFrmRecordData(frmRecord)}
                        </Tbody>
                      </PersonalData>
                      <LinkContainer device={device}>
                        <SalaryContainer>
                          <SbmContainer>
                            <Text size='medium' weight='regular' align='left'>
                              {BMS_NAMES.BMS_INBOUND}:
                            </Text>
                            {frmRecord
                              && (
                                <Text size='large' weight='regular' align='right'>
                                  {frmRecord?.bmsInbound?.toFixed(2) || 'N/A'}
                                </Text>
                              )}
                          </SbmContainer>
                          {frmRecord?.statusId === 3
                            && (
                              <SbmContainer>
                                <Text size='medium' weight='regular' align='left'>
                                  {BMS_NAMES.BMS_INBOUND_WITH_BONUS}:
                                </Text>
                                <Text size='large' weight='regular' align='right'>
                                  {frmRecord?.bmsInboundWithBonus?.toFixed(2) || 'N/A'}
                                </Text>
                              </SbmContainer>
                            )}
                          <SbmContainer>
                            <Text size='medium' weight='regular' align='left'>
                              {BMS_NAMES.BMS_OUTBOUND}:
                            </Text>
                            {frmRecord
                              && (
                                <Text size='large' weight='regular' align='right'>
                                  {frmRecord?.bmsOutbound?.toFixed(2) || 'N/A'}
                                </Text>
                              )}
                          </SbmContainer>
                        </SalaryContainer>
                        <Column
                          justifyContent='flex-end'
                          alignItems={device < BREAKPOINTS.TABLET ? 'flex-end' : 'flex-start'}
                        >
                          <MyButtonLink onClick={handleEditProfile} color='primary' underline>
                            Editar Perfil
                          </MyButtonLink>
                        </Column>
                      </LinkContainer>
                    </>
                  )}
              </DataContainer>
              {showTable
                && (
                  <>
                    <TextContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
                      <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTIONS}</Text>
                    </TextContainer>
                    <FilterContainer columnStart={1} columnEnd={12} rowStart={5} rowEnd={5} deviceName={deviceName}>
                      <FilterContainerLeftSide deviceName={deviceName}>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro Contribuciones'
                          icon='filter_alt'
                          onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTIONS)}
                          disabled={tablelist.length === 0}
                        />
                        {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                        {showFilter && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTIONS, filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                        {showTable && (
                          <SearchBar
                            onChange={handleInputChange}
                            visible={!!activeFiltersContributions?.filterText}
                            value={activeFiltersContributions?.filterText}
                            id={1}
                          />
                        )}
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableWrapper columnStart={1} columnEnd={12} rowStart={6} rowEnd={6}>
                      {errorColumnMessageContributions.error
                        && (
                          <WrapperErrorTextColumn>
                            <Text align='left' weight='bold' size='medium' color='error'>
                              {`${NO_ORDER_COLUMN_BY} "${errorColumnMessageContributions.columnName}"`}
                            </Text>
                          </WrapperErrorTextColumn>
                        )}
                      {renderTable({
                        currentList: tablelist,
                        columnsN: columns,
                        currentLoading: contributionLoading,
                        handleOrder: handleOrderChangeContribution,
                        error: itemsError
                      })}
                    </TableWrapper>
                  </>
                )}
              {showTableCP
                && (
                  <>
                    <TextContainer columnStart={1} columnEnd={12} rowStart={7} rowEnd={7}>
                      <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.CONTRIBUTION_PAYMENTS}</Text>
                    </TextContainer>
                    <FilterContainer
                      columnStart={1}
                      columnEnd={12}
                      rowStart='8'
                      rowEnd='8'
                      deviceName={deviceName}
                    >
                      <FilterContainerLeftSide deviceName={deviceName}>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro Detalle Ingreso'
                          icon='filter_alt'
                          onClick={() => handleClickShowFilters(FILTER_TYPE.CONTRIBUTION_PAYMENTS)}
                          disabled={tablelistCP.length === 0}
                        />
                        {badgesCP.length > 0 && <BadgeGroup badges={badgesCP} handleCloseBadge={handleCloseBadgeCP} />}
                        {showFilterCP
                          && (filterData.length > 0 && renderFilter(FILTER_TYPE.CONTRIBUTION_PAYMENTS, filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide deviceName={deviceName}>
                        <SearchBar
                          onChange={handleInputChangeCP}
                          disabled={!showTableCP}
                          value={activeFiltersCP?.filterText}
                          id={2}
                        />
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableWrapper columnStart={1} columnEnd={12} rowStart={9} rowEnd={9}>
                      {renderTableCP({
                        currentList: tablelistCP,
                        columnsN: columnsCP,
                        currentLoading: conPayLoading,
                        handleOrder: handleOrderChangeCP,
                        error: itemsError
                      })}
                    </TableWrapper>
                  </>
                )}
              {paymentsMade && showTable
                && (
                  <>
                    <TextContainer columnStart={1} columnEnd={12} rowStart={10} rowEnd={10}>
                      <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.PAYMENTS}</Text>
                    </TextContainer>
                    <FilterContainer columnStart={1} columnEnd={12} rowStart={11} rowEnd={11}>
                      <FilterContainerLeftSide>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro Pagos'
                          icon='filter_alt'
                          onClick={() => handleClickShowFilters(FILTER_TYPE.PAYMENTS)}
                          disabled={tablelistPayments.length === 0}
                        />
                        {badgesPayments.length > 0
                          && (<BadgeGroup badges={badgesPayments} handleCloseBadge={handleCloseBadgePayments} />)}
                        {showFilterPayments
                          && (filterData.length > 0 && renderFilter(FILTER_TYPE.PAYMENTS, filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide>
                        <SearchBar
                          onChange={handleInputChangePayments}
                          value={activeFiltersPayments?.filterText}
                          id={3}
                        />
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableWrapper columnStart={1} columnEnd={12} rowStart={12} rowEnd={12}>
                      {errorColumnMessagePayment.error
                        && (
                          <WrapperErrorTextColumn>
                            <Text align='left' weight='bold' size='medium' color='error'>
                              {`${NO_ORDER_COLUMN_BY} "${errorColumnMessagePayment.columnName}"`}
                            </Text>
                          </WrapperErrorTextColumn>
                        )}
                      {renderPaymentTable({
                        currentList: tablelistPayments,
                        columnsN: columnsPayments,
                        currentLoading: paymentsMadeLoading,
                        handleOrder: handleOrderChangePayments,
                        error: paymentsMadeError
                      })}
                    </TableWrapper>
                  </>
                )}
              <TextContainer columnStart={1} columnEnd={12} rowStart={13} rowEnd={13}>
                <Text size='large' weight='bold' align='left'>{SUBTITLES_TABLES.FILES}</Text>
              </TextContainer>
              <ButtonContainer
                justifyContent='flex-end'
                marginTop={spacing.three}
                marginBottom={spacing.three}
                buttonColor
                columnStart={1}
                columnEnd={12}
                rowStart={14}
                rowEnd={14}
              >
                <GhostButton
                  color='primary'
                  size='small'
                  icon='add'
                  text='Subir Archivo'
                  reverse
                  onClick={handleFileClick}
                />
              </ButtonContainer>
              <TableWrapper columnStart={1} columnEnd={12} rowStart={15} rowEnd={15}>
                {renderFileTable({
                  currentList: tablelistFiles,
                  columnsN: columnsFiles,
                  currentLoading: loadingFile,
                  handleOrder: () => {},
                  error: false
                })}
              </TableWrapper>
            </>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='380px'
            >
              {editFrmProfileModal && <FrmRecordProfileModal {...editFrmProfileModal} />}
              {fileModal
                && (
                  <FileDataModal
                    token={currentToken.token}
                    type={TYPE_PERSONAL_INFORMATION_MODAL.FILE.name}
                    close={handleCloseModal}
                    uploadFile={uploadFile}
                  />
                )}
              {removeItemModal
                && (
                  <DeleteConfirmationModal
                    {...removeItemModal}
                  />
                )}
            </Modal>
          </>
        )}
    </>
  )
}

export default FrmRecord
