import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectEmplymentRecordProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'loading', null)
)

const selectEmplymentRecordProfileSuccess = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'employmentRecordProfile', null)
)

const selectStatusesSuccess = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'employmentRecordProfileStatuses', null)
)

const selectError = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'error', null)
)

const selectLoadingEditEmplymentRecordProfile = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'editInformation', [])
)

const selectEmplymentRecordProfileModalData = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'modalDataInformation', [])
)

const selectContributionsLoading = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributionsLoading', false)
)

const selectContributionsSuccess = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributions', null)
)

const selectContributionsError = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributionError', null)
)

const selectColumns = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'totalItems', 0)
)

const selectCurrencies = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'currencies', 0)
)

const selectDataFilters = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'dataFiltersError', null)
)

const selectContributionPaymentsLoading = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributionPaymentsLoading', null)
)

const selectContributionPaymentsSuccess = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributionPayments', null)
)

const selectContributionPaymentsError = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'contributionPaymentsError', null)
)

const selectInboundPaymentsOrigins = createSelector(
  selectEmplymentRecordProfile, state => get(state, 'inboundPaymentsOrigins', null)
)

export default {
  EMPLOYMENT_RECORD_PROFILE_SUCCESS: selectEmplymentRecordProfileSuccess,
  EMPLOYMENT_RECORD_PROFILE_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_EMPLOYMENT_RECORD_PROFILE: selectLoadingEditEmplymentRecordProfile,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  EMPLOYMENT_RECORD_PROFILE_MODAL_DATA: selectEmplymentRecordProfileModalData,
  CONTRIBUTIONS_LOADING: selectContributionsLoading,

  CONTRIBUTIONS_SUCCESS: selectContributionsSuccess,
  CONTRIBUTIONS_ERROR: selectContributionsError,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  CURRENCIES_INFORMATION: selectCurrencies,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  CONTRIBUTION_PAYMENTS_LOADING: selectContributionPaymentsLoading,
  CONTRIBUTION_PAYMENTS_SUCCESS: selectContributionPaymentsSuccess,
  CONTRIBUTION_PAYMENTS_ERROR: selectContributionPaymentsError,

  ORIGINS: selectInboundPaymentsOrigins
}
