import produce from 'immer'

import { FRM_RECORD } from './types'

export const initialState = {
  loading: false,
  error: false,
  person: null,
  personLoading: null,
  activeFrmRecord: null,
  contributions: null,
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  contributionError: null,

  paymentsMade: null,
  paymentsMadeLoading: false,
  errorPaymentsMade: null,

  contributionsLoading: false,
  dataFilters: null,
  dataFiltersError: null,
  filterLoading: null,
  modalData: null,
  modalLoading: null,
  loadingProfile: false,
  files: [],
  loadingFile: false,
  fileMessage: null,
  messageInformation: null,

  contributionPaymentsLoading: null,
  contributionPayments: null,
  contributionPaymentsError: null,
  inboundPaymentsOrigins: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case FRM_RECORD.GET_FRM_RECORD:
      draft.loading = true
      break
    case FRM_RECORD.GET_FRM_RECORD_SUCCESS:
      draft.activeFrmRecord = payload
      break
    // PERSON
    case FRM_RECORD.GET_PERSON_DATA:
      draft.personLoading = true
      break
    case FRM_RECORD.GET_PERSON_DATA_SUCCESS:
      draft.personLoading = false
      draft.person = payload
      break
    case FRM_RECORD.GET_FRM_RECORD_ERROR:
      draft.error = payload
      draft.loading = false
      break
    case FRM_RECORD.CLEAR:
      return initialState
      //  CONTRIBUTIONS
    case FRM_RECORD.GET_CONTRIBUTIONS:
      draft.contributionsLoading = true
      break
    case FRM_RECORD.GET_CONTRIBUTIONS_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.contributions = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.contributionsLoading = false
      break
    }
    case FRM_RECORD.GET_CONTRIBUTIONS_ERROR:
      draft.contributionsLoading = false
      draft.contributionError = payload
      break
      //  PAYMENTS_MADE
    case FRM_RECORD.GET_PAYMENTS_MADE:
      draft.paymentsMadeLoading = true
      break
    case FRM_RECORD.GET_PAYMENTS_MADE_SUCCESS:
      draft.paymentsMadeLoading = false
      draft.paymentsMade = payload
      break
    case FRM_RECORD.GET_PAYMENTS_MADE_ERROR:
      draft.paymentsMadeLoading = false
      draft.errorPaymentsMade = payload
      break
    case FRM_RECORD.SEND_FILTER:
      draft.loading = true
      break
    case FRM_RECORD.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case FRM_RECORD.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case FRM_RECORD.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case FRM_RECORD.FILTER_DATA_CLEAR:
      draft.dataFilters = null
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // MODAL
    case FRM_RECORD.GET_MODAL_DATA:
      draft.modalLoading = true
      break
    case FRM_RECORD.MODAL_DATA_RESPONSE:
      draft.modalData = payload
      draft.modalLoading = false
      break
    // MODAL
    case FRM_RECORD.EDIT_PROFILE:
      draft.loadingProfile = true
      break
    case FRM_RECORD.UPDATE_PROFILE:
      draft.activeFrmRecord = payload
      break
    case FRM_RECORD.EDIT_PROFILE_RESPONSE:
      draft.loadingProfile = false
      draft.messageInformation = payload
      break
    // FILES
    case FRM_RECORD.FRM_RECORD_UPLOAD_FILE:
      draft.loadingFile = true
      break
    case FRM_RECORD.DOWNLOAD_FRM_RECORD_FILE:
      draft.loadingFile = true
      break
    case FRM_RECORD.REMOVE_FRM_RECORD_FILE:
      draft.loadingFile = true
      break
    case FRM_RECORD.GET_FRM_RECORD_FILES:
      draft.loadingFile = true
      break
    case FRM_RECORD.GET_FRM_RECORD_FILE_SUCCESS:
      draft.loadingFile = false
      draft.files = payload
      break
    case FRM_RECORD.MESSAGE_INFORMATION:
      draft.loadingFile = false
      draft.messageInformation = payload
      break
    case FRM_RECORD.FRM_RECORD_FILE_MESSAGE:
      draft.fileMessage = payload
      draft.loadingFile = false
      break
    case FRM_RECORD.CLEAR_INFORMATION_MESSAGE:
      draft.messageInformation = null
      break
    case FRM_RECORD.DATA_UPLOAD_READY:
      draft.loading = false
      break
    //  CONTRIBUTION PAYMENTS
    case FRM_RECORD.GET_CONTRIBUTION_PAYMENTS:
      draft.contributionPaymentsLoading = true
      break
    case FRM_RECORD.GET_CONTRIBUTION_PAYMENTS_SUCCESS: {
      const { contributionPaymentsData, originData } = payload
      draft.contributionPaymentsLoading = false
      draft.contributionPayments = contributionPaymentsData
      draft.inboundPaymentsOrigins = originData
      break
    }
    case FRM_RECORD.GET_CONTRIBUTION_PAYMENTS_ERROR:
      draft.contributionPaymentsLoading = false
      draft.contributionPaymentsError = payload
      break
    default:
      return initialState
  }
})

export default reducer
