import produce from 'immer'

import { DETAIL_TYPES } from '@global/constants'
import { DETAIL_LIST } from './types'

export const initialState = {
  loading: null,
  error: false,
  detailProfile: null,
  detailsLoading: null,
  details: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  detailsError: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  inboundPaymentsOrigins: []
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    // PROFILE
    case DETAIL_LIST.GET_DETAIL_LIST_PROFILE:
      draft.loading = true
      break
    case DETAIL_LIST.GET_DETAIL_LIST_PROFILE_SUCCESS: {
      draft.loading = false
      draft.detailProfile = payload
      break
    }
    case DETAIL_LIST.GET_DETAIL_LIST_PROFILE_ERROR:
      draft.loading = false
      draft.error = payload
      break
    // CONTRIBUTIONS
    case DETAIL_LIST.GET_DETAIL_LIST:
      draft.detailsLoading = true
      break
    case DETAIL_LIST.GET_DETAIL_LIST_SUCCESS: {
      const { detailType, details } = payload
      if (detailType === DETAIL_TYPES.CONTRIBUTION_PAYMENT) {
        const { contributions, originData } = details
        const { items, fields, totalItems, totalPages, currentPage } = contributions
        draft.details = items
        draft.columns = fields
        draft.totalItems = totalItems
        draft.pages = { totalPages, currentPage }
        draft.inboundPaymentsOrigins = originData
      } else {
        const { items, fields, totalItems, totalPages, currentPage } = details
        draft.details = items
        draft.columns = fields
        draft.totalItems = totalItems
        draft.pages = { totalPages, currentPage }
      }
      draft.detailsLoading = false
      break
    }
    case DETAIL_LIST.GET_DETAIL_LIST_ERROR:
      draft.detailsLoading = false
      draft.detailsError = payload
      break
    // FILTER
    case DETAIL_LIST.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case DETAIL_LIST.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case DETAIL_LIST.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case DETAIL_LIST.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case DETAIL_LIST.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
