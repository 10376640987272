import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { rowsPerPage, TOAST_POSITION, TRANSLATION_OF_ROUTES, TOAST_PROPERTIES, MONTHS } from '@global/constants'
import {
  TOKEN_HELPERS,
  sortByKey,
  filterBodyBuilder,
  filterOrderBuilder,
  handleClickPaginationItem,
  currencyFormatUY
} from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperHeading
} from '@global/styles'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import { Heading, Text } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import Modal from '@components/modal'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { INCOME_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapper, PaginationWrapper } from './styles'
import AddDepositModal from './components/addDepositModal'

const Income = () => {
  useRedux()
  const { key, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [filterData, setFilterData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [badges, setBadges] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [addDepositModal, setAddDepositModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [parameters, setParameters] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterError = useSelector(SELECTORS.ERROR_FILTER_OBJECT)
  const messageInformation = useSelector(SELECTORS.ADD_DEPOSIT_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getIncome = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(INCOME_ACTIONS.GET_INCOME({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      return col.name
    })

    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }
    return customColumns
  }

  const handleRowClick = objRow => {
    if (objRow) {
      const { id } = objRow
      history.push({
        pathname: `${pathname}${SITE.DEPOSIT_PROFILE}/${id}`,
        state: { breadcrumbs, historyRecordId: id }
      })
    }
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const status = item.statusId === 3
        ? (<Text color='error'>{item.statusName}</Text>)
        : item.statusName

      const newItem = {
        id: item.id,
        code: item.id,
        notificationDate: item.notificationDate,
        paymentMadeDate: item.paymentMadeDate,
        paymentConfirmedDate: item.paymentConfirmedDate,
        originName: item.originName,
        amount: currencyFormatUY(item.amount),
        status,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month).label : 'N/A',
        year: item.year,
        description: item.description
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getIncome(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getIncome(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      const filterRequest = { id: 11, token }
      dispatch(INCOME_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getIncome(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getIncome(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(INCOME_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getIncome(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const saveNewIncome = newIncome => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = {
        newIncome,
        body: {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
      }
      dispatch(INCOME_ACTIONS.ADD_DEPOSIT({ data, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setAddDepositModal(null)
  }

  const handleAddIncome = () => {
    setAddDepositModal({
      close: handleCloseModal,
      saveData: saveNewIncome,
      currentToken
    })
    setOpen(true)
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer
      />
    )
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
      device={device}
    />
  )

  useEffect(() => {
    const body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: true
    }
    setActiveFilters(body)
    getIncome(body)
    const fisrtCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.FINANCE,
      url: SITE.FINANCE
    }
    const lastCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.INCOME,
      url: pathname
    }
    setBreadcrumbs([fisrtCrumb, lastCrumb])

    return () => {
      setBreadcrumbs([])
    }
  }, [key])

  useEffect(() => {
    setColumns(customHeaderDataTable(fields, columnData))
    setTablelist(customBodyDataTable(items))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
  }, [items, totalPages])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        getIncome(body)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (filterError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: filterError.message,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }
  }, [filterError])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status >= 200 && status <= 299) {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.GENERIC_SUCCESSFUL_CREATION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {TRANSLATION_OF_ROUTES.INCOME}
              </Heading>
            </WrapperHeading>
            <FilterContainer columnStart={1} columnEnd={12} rowStart={3} rowEnd={3} deviceName={deviceName}>
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Ingreso +'
                  onClick={handleAddIncome}
                />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapper rowStart='4' rowEnd='4' columnStart='1' columnEnd='12'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                handleOrder: handleOrderChange,
                handleSelectedRow: handleRowClick,
                error: errorMessage
              })}
            </TableWrapper>
            <PaginationWrapper rowStart='5' rowEnd='5' columnStart='1' columnEnd='12'>
              {tablelist.length > 0
              && (
              <PaginationGroup
                deviceName={deviceName}
                pages={pages}
                itemSelected={itemSelected}
                handleClickPaginationItem={n => handleClickPaginationItem(
                  n, activeFilters, selectedOption.value, getIncome, setItemSelected
                )}
                options={rowsPerPage}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
                totalItems={totalItems}
                itemsPerPage={tablelist.length}
              />
              )}
            </PaginationWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Agregar Ingreso'
              minHeight='auto'
            >
              {addDepositModal
              && (
                <AddDepositModal
                  {...addDepositModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default Income
