import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import PaymentSettlements from '@containers/paymentSettlements'
import PaymentSettlementProfile from '@containers/paymentSettlementProfile'
import DebtProfile from '@containers/debtProfile'

export const PaymentSettlementRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={PaymentSettlements} />
      <PrivateRoute
        exact
        path={
              `${url}${SITE.PAYMENT_SETTLEMENT_PROFILE}/:paymentSettlementProfileId`
            }
        component={PaymentSettlementProfile}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.PAYMENT_SETTLEMENT_PROFILE}/:paymentSettlementProfileId${SITE.DEBT_PROFILE}/:debtProfileId`}
        component={DebtProfile}
      />
    </Switch>
  )
}
