import React, { useState, useEffect } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  TRANSLATION_OF_ROUTES,
  TYPE_PERSONAL_INFORMATION_MODAL,
  FUNCTION_TYPE,
  TOAST_PROPERTIES,
  TOAST_POSITION,
  ENTITY_COLUMNS_NAMES,
  HISTORY_TYPES,
  BREAKPOINTS,
  SUBTITLES_TABLES,
  rowsPerPage,
  MONTHS
} from '@global/constants'
import { SITE } from '@routes/paths'
import {
  TOKEN_HELPERS,
  formatDateBasic,
  changeNameForLabel,
  handleClickPaginationItem,
  filterOrderBuilder,
  filterBodyBuilder,
  currencyFormatUY
} from '@utils/helpers'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'
import {
  WrapperLoading,
  WrapperBreadcrumbs,
  WrapperHeading,
  TableWrapper,
  MyLink,
  MyButtonLink,
  BadgeContainer,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide
} from '@global/styles'

import { Text, Heading } from '@components/texts'
import { GhostButton, SolidButton } from '@components/button'
import Label from '@components/label'
import Icon from '@components/icon'
import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Badge from '@components/badge'
import Table from '@components/table'
import Modal from '@components/modal'
import TextArea from '@components/inputs/inputs/components/textArea'
import Toast from '@components/toast'
import SearchBar from '@components/searchBar'
import BadgeGroup from '@components/badgeGroup'
import PaginationGroup from '@components/paginationGroup'
import Filter from '@components/filter'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import PersonalDataModal from './components/personalDataModal'

import { COLUMN_CONTRIBUTION_PAYMENTS, columnData } from './data'

import {
  NotesContainer,
  HeaderContainer,
  BillingNumberContainer,
  DataContainer,
  PhoneContainer,
  AddressContainer,
  EmailContainer,
  BankAccountContainer,
  DataItem,
  ButtonContainerStyle,
  ButtonContainerAddress,
  ButtonContainerPhones,
  ButtonContainerEmails,
  ButtonContainerBanksAccounts,
  NoteItem,
  NoteItemHeader,
  DateContainer,
  AddNoteContainer,
  ListContainer,
  PersonalData,
  Tbody,
  Td,
  Tr,
  LinkContainer,
  TextContainer,
  AtaeContainer,
  FrmContainer,
  ButtonContainerFile,
  StatusDataContainer,
  StatusDataContainerFrm,
  ButtonContainerEmployee,
  NoteItemBody,
  Top,
  TextContainerCP,
  TableContainerAffRecCP,
  CustomButtonLink
} from './styles'

import useRedux from './redux'
import { PERSON_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import FileDataModal from './components/fileModal'
import DeleteConfirmationModal from '../../components/modal/components/deleteConfirmationModal'
import ProfileModal from './components/profileModal'
import CreateEmploymentRecord from './components/createEmploymentRecordModal'
import CreateAffiliateRecordModal from './components/createAffiliateRecordModal'
import CreateFrmRecord from './components/createFrmRecordModal'

const Person = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { personId } = useParams()
  const { pathname } = useLocation()
  const { device, deviceName } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [personalData, setPersonalData] = useState({})
  const [addresses, setAddresses] = useState()
  const [phones, setPhones] = useState()
  const [emails, setEmails] = useState()
  const [banksAccounts, setBanksAccounts] = useState()
  const [notes, setNotes] = useState([])
  const [employmentRecord, setEmploymentRecord] = useState(null)
  const [affiliateRecord, setAffiliateRecord] = useState(null)
  const [frmRecord, setFrmRecord] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [newNote, setNewNote] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [fileModal, setFileModal] = useState(false)
  const [removeItemModal, setRemoveItemModal] = useState(null)
  const [editProfileModal, setEditProfileModal] = useState()
  const [createEmploymentRecordModal, setCreateEmploymentRecordModal] = useState()
  const [createAffiliateRecordModal, setCreateAffiliateRecordModal] = useState()
  const [createFrmRecordModal, setCreateFrmRecordModal] = useState()
  const [modalTitle, setModalTitle] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorFlag, setErrorFlag] = useState(false)

  const [showTableCP, setShowTableCP] = useState(false)
  const [pagesCP, setPagesCP] = useState()
  const [totalItemsCP, setTotalItemsCP] = useState(0)
  const [tablelistCP, setTablelistCP] = useState([])
  const [columnsCP, setColumnsCP] = useState([])
  const [selectedOptionCP, setSelectedOptionCP] = useState(rowsPerPage[0])
  const [itemSelectedCP, setItemSelectedCP] = useState()
  const [activeFiltersCP, setActiveFiltersCP] = useState(null)
  const [showFilterCP, setShowFilterCP] = useState(false)
  const [badgesCP, setBadgesCP] = useState([])
  const [parametersCP, setParametersCP] = useState()
  const [filterData, setFilterData] = useState([])
  const [contributionPayments, setContributionPayments] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const personalInformation = useSelector(SELECTORS.SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const personalPhones = useSelector(SELECTORS.PHONES_SUCCESS)
  const personalAddresses = useSelector(SELECTORS.ADDRESSES_SUCCESS)
  const personalEmails = useSelector(SELECTORS.EMAILS_SUCCESS)
  const personalBanksAccounts = useSelector(SELECTORS.BANKS_ACCOUNTS_SUCCESS)
  const personalNotes = useSelector(SELECTORS.NOTES_SUCCESS)
  const personalFiles = useSelector(SELECTORS.FILES_SUCCESS)
  const fileMessage = useSelector(SELECTORS.FILE_MESSAGE) // downloaded file
  const informationMessage = useSelector(SELECTORS.INFORMATION_MESSAGE)
  const loadingAddress = useSelector(SELECTORS.LOADING_ADDRESSES)
  const loadingPhones = useSelector(SELECTORS.LOADING_PHONES)
  const loadingEmails = useSelector(SELECTORS.LOADING_EMAILS)
  const loadingBanksAccounts = useSelector(SELECTORS.LOADING_BANKS_ACCOUNTS)
  const loadingNotes = useSelector(SELECTORS.LOADING_NOTES)
  const loadingProfile = useSelector(SELECTORS.LOADING_PROFILE)
  const loadingFile = useSelector(SELECTORS.LOADING_FILE)
  const personalEmploymentRecord = useSelector(SELECTORS.EMPLOYMENT_RECORD)
  const personalAffiliateRecord = useSelector(SELECTORS.ACTIVE_AFFILIATE_RECORD)
  const personalFrmRecord = useSelector(SELECTORS.FRM_RECORD)
  const loadingEmploymentRecord = useSelector(SELECTORS.LOADING_EMPLOYMENT_RECORD)
  const loadingAffiliateRecord = useSelector(SELECTORS.LOADING_AFFILIATE_RECORD)
  const loadingFrmRecord = useSelector(SELECTORS.LOADING_FRM_RECORD)
  const personStatuses = useSelector(SELECTORS.PERSON_STATUS)

  const conPayLoading = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_LOADING)
  const conPaymentsData = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_SUCCESS)
  const conPaymentsDataError = useSelector(SELECTORS.CONTRIBUTION_PAYMENTS_ERROR)
  const origins = useSelector(SELECTORS.ORIGINS)

  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const filterObjectError = useSelector(SELECTORS.ERROR_FILTER_OBJECT)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setModalData(null)
    setFileModal(null)
    setRemoveItemModal(null)
    setEditProfileModal(null)
    setCreateEmploymentRecordModal(null)
    setCreateAffiliateRecordModal(null)
    setCreateFrmRecordModal(null)
  }

  const getContributionPayments = body => {
    body.statuses = [1]
    body.contributionId = 0
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.GET_CONTRIBUTION_PAYMENTS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataPerson = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.GET_PERSON({ personId, token }))
      dispatch(PERSON_ACTIONS.GET_EMPLOYMENT_RECORD({ personId, token }))
      const bodyCP = {
        pageNumber: 1,
        pageSize: selectedOptionCP.value,
        orderByDesc: true,
        orderByColumn: 'Id',
        personId
      }
      getContributionPayments(bodyCP)
      setActiveFiltersCP(bodyCP)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const saveProfiledata = dataProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.EDIT_PROFILE({ personId: personalData.id, data: dataProfile, token }))
      setBreadcrumbs([])
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleEditProfile = () => {
    const { ci, fullName, billingNumber, birthday, remarks, sexId, statusId } = personalData

    const oldData = {
      ci,
      fullName,
      billingNumber,
      birthday,
      remarks,
      sexId,
      statusId
    }
    setModalTitle('Editar perfil')
    setEditProfileModal({
      oldData,
      close: handleCloseModal,
      saveData: saveProfiledata,
      personStatuses: changeNameForLabel(personStatuses)
    })
    setOpen(true)
  }

  const handleRemoveFile = file => {
    const idRemove = file.split('-')[0]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.REMOVE_FILE({ personId, fileId: idRemove, token }))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const openRemoveItemModal = (itemId, title, text, deleteFn, dataType = null) => {
    setModalTitle(title)
    setRemoveItemModal({
      close: handleCloseModal,
      text,
      itemId,
      removeItem: deleteFn,
      dataType
    })
    setOpen(true)
  }

  const handleDownloadFile = idDownload => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const fileId = idDownload.split('-')[2]
      dispatch(PERSON_ACTIONS.DOWNLOAD_FILE({ fileId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const createEmploymentRecord = employmentRecordData => {
    employmentRecordData = {
      ...employmentRecordData,
      personId
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.CREATE_EMPLOYMENT_RECORD({ personId, data: employmentRecordData, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCreateEmploymentRecord = () => {
    setModalTitle('Crear Registro de Empleo')
    setCreateEmploymentRecordModal({
      close: handleCloseModal,
      saveData: createEmploymentRecord,
      data: personalData.billingNumber,
      currentToken
    })
    setOpen(true)
  }

  const createAffiliateRecord = affiliateRecordData => {
    affiliateRecordData = {
      ...affiliateRecordData,
      personId
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.CREATE_AFFILIATE_RECORD({ personId, data: affiliateRecordData, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCreateAffiliateRecord = () => {
    setModalTitle('Crear Registro de Afiliación')
    setCreateAffiliateRecordModal({
      close: handleCloseModal,
      saveData: createAffiliateRecord,
      currentToken
    })
    setOpen(true)
  }

  const createFrmRecord = frmRecordData => {
    frmRecordData = {
      ...frmRecordData,
      personId
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.CREATE_FRM_RECORD({ data: frmRecordData, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCreateFrmRecord = () => {
    setModalTitle('Crear Registro de F.R.M')
    setCreateFrmRecordModal({
      close: handleCloseModal,
      saveData: createFrmRecord
    })
    setOpen(true)
  }

  const handleToastClose = () => setIsShowing(false)

  const handleTextArea = val => setNewNote(val)

  const deleteNote = noteId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PERSON_ACTIONS.REMOVE_NOTE({ personId: personalData.id, data: noteId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSetNote = (type, noteId = null) => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      if (type === FUNCTION_TYPE.ADD) {
        const data = { description: newNote.value }
        dispatch(PERSON_ACTIONS.ADD_NOTE({ personId: personalData.id, data, token }))
        setNewNote({
          name: '',
          value: '',
          error: ''
        })
      } else {
        const data = { description: newNote.value } // ver de donde tomar este dato
        dispatch(PERSON_ACTIONS.EDIT_NOTE({ noteId, data, token }))
      }
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleModal = (type, functionType, itemId = null) => {
    let title = ''
    if (functionType === FUNCTION_TYPE.ADD) {
      setModalData({
        type,
        functionType,
        data: {}
      })
      switch (type) {
        case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name:
          title = 'Direcciones'
          break
        case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name:
          title = 'Teléfonos'
          break
        case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name:
          title = 'Emails'
          break
        default: title = 'Cuentas bancarias'
      }
    } else {
      let oldData = null
      switch (type) {
        case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
          oldData = addresses.find(address => address.id === itemId)
          title = 'Direcciones'
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
          oldData = phones.find(phone => phone.id === itemId)
          title = 'Teléfonos'
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
          oldData = emails.find(email => email.id === itemId)
          title = 'Emails'
          break
        }
        default: {
          oldData = banksAccounts.find(bankAccount => bankAccount.id === itemId)
          title = 'Cuentas bancarias'
        }
      }
      setModalData({
        type,
        functionType,
        oldData
      })
    }
    setModalTitle(title)
  }

  const handleDeleteItem = (itemId, type) => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      switch (type) {
        case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
          dispatch(PERSON_ACTIONS.REMOVE_ADDRESS({ personId: personalData.id, data: itemId, token }))
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
          dispatch(PERSON_ACTIONS.REMOVE_PHONE({ personId: personalData.id, data: itemId, token }))
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
          dispatch(PERSON_ACTIONS.REMOVE_EMAIL({ personId: personalData.id, data: itemId, token }))
          break
        }
        default: dispatch(PERSON_ACTIONS.REMOVE_BANK_ACCOUNT({ personId: personalData.id, data: itemId, token }))
      }
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleFileClick = () => {
    setModalTitle('Agregar archivo')
    setFileModal(true)
    setOpen(true)
  }

  const handleAffiliateRecord = () => {
    history.push({
      pathname: `${SITE.PEOPLE}${SITE.PERSON}/${personId}${SITE.AFFILIATE_RECORD}/${affiliateRecord.id}`,
      state: { breadcrumbs, personId }
    })
  }

  const handleFRMRecord = () => {
    history.push({
      pathname: `${SITE.PEOPLE}${SITE.PERSON}/${personId}${SITE.FRM_RECORD}/${frmRecord.id}`,
      state: { breadcrumbs, personId }
    })
  }

  const customHeaderDataTable = colData => {
    const deleteIcon = (<Icon name='delete' backgroundColor='primary' size='medium' color='grays' />)
    const customColumns = Object.values(colData).map(item => {
      if (item.name === 'Borrar') {
        let obj = item
        obj = {
          ...obj,
          name: deleteIcon
        }
        return obj
      }
      return item
    })
    return customColumns
  }

  const customBodyDataTable = bodyData => {
    let newData = bodyData.map(item => {
      const newObj = {
        id: `${item.id}-${item.personId}-${item.fileId}`,
        date: item.dateUpdated,
        name: item.fileName,
        description: item.description
      }
      return newObj
    })

    const title = 'Borrar Archivo'
    const text = '¿Estás seguro que deseas borrar este archivo?'
    newData = newData.map(item => {
      let newItem = item
      const deleteIcon = (
        <Icon
          name='cancel'
          size='medium'
          color='grays'
          onClick={() => openRemoveItemModal(item.id, title, text, handleRemoveFile)}
        />
      )
      const downloadIcon = (
        <Icon name='archive' size='medium' color='primary' onClick={() => handleDownloadFile(item.id)} />
      )

      newItem = {
        deleteFile: deleteIcon,
        ...newItem,
        downloadFile: downloadIcon
      }
      return newItem
    })
    return newData
  }

  const renderButtonContainer = (justifyContent, alignItems, dataType, itemId) => {
    let title = ''
    let text = ''
    switch (dataType) {
      case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
        title = 'Eliminar dirección'
        text = '¿Estás seguro que deseas borrar esta dirección?'
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
        title = 'Eliminar teléfono'
        text = '¿Estás seguro que deseas borrar este teléfono?'
        break
      }
      case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
        title = 'Eliminar email'
        text = '¿Estás seguro que deseas borrar este email?'
        break
      }
      default: {
        title = 'Eliminar Cuenta bancaria'
        text = '¿Estás seguro que deseas borrar esta cuenta?'
      }
    }

    return (
      <ButtonContainerStyle
        backgroundColor
        justifyContent={justifyContent}
        alignItems={alignItems}
        marginTop={spacing.one}
        marginRight={spacing.one}
      >
        <Icon
          color='system'
          size='small'
          name='delete_outline'
          onClick={() => openRemoveItemModal(itemId, title, text, handleDeleteItem, dataType)}
        />
        <MyButtonLink
          onClick={() => handleModal(dataType, FUNCTION_TYPE.EDIT, itemId)}
          color='primary'
        >
          Editar
        </MyButtonLink>
      </ButtonContainerStyle>
    )
  }

  const renderAddresses = arrAddresses => (arrAddresses.length > 0
    ? (
      arrAddresses.map(address => (
        <DataItem
          flexDirection='column'
          padding={spacing.two}
          width='min-content'
          minWidth='200px'
          marginRight
          id={address.id}
        >
          {address.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primaria' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Tipo: ${address.addressTypeName}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {address.streetAddress1}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Departamento: ${address.departmentName || 'N/A'}`}
          </Text>
          {renderButtonContainer('flex-end', 'strech', TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name, address.id)}
        </DataItem>
      )))
    : (
      <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_ADDRESSES}</Text>
    )
  )

  const renderPhones = arrPhones => (arrPhones.length > 0
    ? (arrPhones.map(phone => (
      <DataItem
        flexDirection='column'
        padding={spacing.two}
        width='min-content'
        minWidth='200px'
        marginRight
        id={phone.id}
      >
        {phone.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primario' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
        <Text
          size='medium'
          weight='regular'
          align='left'
        >
          {`Teléfono ${phone.phoneTypeName || 'N/A'}:`}
        </Text>
        <Text size='medium' weight='regular' align='left'>{phone.phoneNumber}</Text>
        {renderButtonContainer('flex-end', 'strech', TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name, phone.id)}
      </DataItem>
    )))
    : (
      <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_PHONES}</Text>
    )
  )

  const renderEmails = arrEmails => (
    arrEmails.length > 0
      ? (arrEmails.map(email => (
        <DataItem
          flexDirection='column'
          padding={spacing.two}
          width='min-content'
          minWidth='200px'
          marginRight
          id={email.id}
        >
          {email.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primario' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Email: ${email.emailTypeName || 'N/A'}`}
          </Text>
          <Text size='medium' weight='regular' align='left'>{email.emailAddress}</Text>
          {renderButtonContainer('flex-end', 'strech', TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name, email.id)}
        </DataItem>
      )))
      : (
        <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_EMAILS}</Text>
      )
  )

  const renderBankAccounts = arrBanksAccounts => (
    arrBanksAccounts.length > 0
      ? (arrBanksAccounts.map(bankAccount => (
        <DataItem
          flexDirection='column'
          padding={spacing.two}
          width='min-content'
          minWidth='200px'
          marginRight
          id={bankAccount.id}
        >
          {bankAccount.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primaria' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Banco: ${bankAccount.bankName || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Nro. Cuenta: ${bankAccount.accountNumber || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Moneda: ${bankAccount.currencyName || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Sucursal: ${bankAccount.branch || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Descripción: ${bankAccount.description || 'N/A'}`}
          </Text>
          {
          renderButtonContainer('flex-end', 'strech', TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name, bankAccount.id)
          }
        </DataItem>
      )))
      : (
        <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_BANK_ACCOUNT}</Text>
      )
  )

  const renderNoteList = arrNotes => {
    const title = 'Eliminar Nota'
    const text = '¿Estás seguro que deseas borrar esta nota?'
    return (
      arrNotes.length > 0 && arrNotes.map(note => (
        <NoteItem id={note.id}>
          <NoteItemHeader>
            <DateContainer>
              <Text size='medium' weight='regular' align='left'>{`Fecha: ${note.date}`}</Text>
              <Text size='medium' weight='regular' align='left'>{`Hora: ${note.hours}`}</Text>
            </DateContainer>
            {/*           <Icon
            color='system'
            size='medium'
            name='edit'
            onClick={() => handleSetNote(FUNCTION_TYPE.EDIT, note.id)}
          /> */}
            <Icon
              color='system'
              size='medium'
              name='delete'
              onClick={() => openRemoveItemModal(note.id, title, text, deleteNote)}
            />
          </NoteItemHeader>
          <NoteItemBody>
            <Text size='medium' weight='regular' align='left'>{note.description}</Text>
          </NoteItemBody>
        </NoteItem>
      ))
    )
  }
  const renderPersonalData = objPersonalData => (
    <>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.FullName.label}:</Td>
        <Td largeSize>{objPersonalData.fullName}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.Birthday.label}:</Td>
        <Td largeSize>{formatDateBasic(objPersonalData.birthday, 'short')}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.Age.label}:</Td>
        <Td largeSize>{objPersonalData.age}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.CI.label}:</Td>
        <Td largeSize>{objPersonalData.ci}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.Sex.label}:</Td>
        <Td largeSize>{objPersonalData.sexId === 1 ? 'Masculino' : 'Femenino'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>{ENTITY_COLUMNS_NAMES.PersonStatus.label}:</Td>
        <Td largeSize>{
            objPersonalData?.statusName
            || personStatuses?.find(ps => ps.id === objPersonalData?.statusId)?.name
            || 'N/A'
          }
        </Td>
      </Tr>
      <Tr>
        <Td weight>{ENTITY_COLUMNS_NAMES.Remarks.label}:</Td>
        <Td largeSize>{objPersonalData.remarks}</Td>
      </Tr>
    </>
  )

  const renderEmploymentRecordCard = employmentRecordObj => (
    <>
      <DataItem
        width='100%'
        minWidth='120px'
        backgroundWhite
        alignItems='center'
        flexDirection={device < BREAKPOINTS.TABLET ? 'column' : 'row'}
      >
        <HeaderContainer justifyContent='flex-start' alignItems='center' flex='3' device={device}>
          <TextContainer>
            <Text size='medium' weight='regular' align='left'>Registro de Empleado: </Text>
            <Text size='medium' weight='regular' align='left'>
              {employmentRecordObj?.billingNumber || 'N/A'}
            </Text>
          </TextContainer>
          <TextContainer>
            <Text size='medium' weight='regular' align='left'>Cargo:</Text>
            <Text size='medium' weight='regular' align='left'>
              {employmentRecordObj?.initialPosition || 'N/A'}
            </Text>
          </TextContainer>
          <TextContainer>
            <Text size='medium' weight='regular' align='left'>Fecha de inicio:</Text>
            <Text size='medium' weight='regular' align='left'>
              {formatDateBasic(employmentRecordObj?.startDate, 'short') || 'N/A'}
            </Text>
          </TextContainer>
          <TextContainer>
            <Text size='medium' weight='regular' align='left'>Fecha de egreso:</Text>
            <Text size='medium' weight='regular' align='left'>
              {formatDateBasic(employmentRecordObj?.endDate, 'short') || 'N/A'}
            </Text>
          </TextContainer>
          {employmentRecordObj?.statusName
           && (
           <TextContainer alignItems='center'>
             <Text size='medium' weight='regular' align='left'>Estado:</Text>
             <Badge text={employmentRecordObj?.statusName} backgroundColor='secondary' color='contrast' size='small' />
           </TextContainer>
           )}
          <TextContainer alignItems='center'>
            <Text size='medium' weight='regular' align='left'>Cobro PEG:</Text>
            <Badge
              text={employmentRecordObj?.pegAmountPaid > 0 ? 'Si' : 'No'}
              backgroundColor='secondary'
              color='contrast'
              size='small'
            />
          </TextContainer>
          {employmentRecordObj?.pegAmountPaid > 0
            && (
              <TextContainer alignItems='center'>
                <Text size='medium' weight='regular' align='left'>Monto:</Text>
                <Text size='medium' weight='regular' align='left'>
                  {currencyFormatUY(employmentRecordObj?.pegAmountPaid)}
                </Text>
              </TextContainer>
            )}
          {employmentRecordObj?.pegAmountPaid > 0
            && (
              <TextContainer alignItems='center'>
                <Text size='medium' weight='regular' align='left'>Fecha de pago:</Text>
                <Text size='medium' weight='regular' align='left'>
                  {formatDateBasic(employmentRecordObj?.pegDatePaid, 'short') || 'N/A'}
                </Text>
              </TextContainer>
            )}
        </HeaderContainer>
        {employmentRecord
         && (
         <LinkContainer device={device}>
           <MyLink
             to={{
               pathname: `${pathname}${SITE.EMPLOYMENT_RECORD}/${employmentRecordObj?.id}`,
               state: { breadcrumbs, id: personId }
             }}
             textDecoration='underline'
             marginBottom={spacing.one}
           >Ir a registro de empleo
           </MyLink>
           <MyLink
             to={{
               pathname: `${SITE.EMPLOYEE_HISTORY}${pathname}${SITE.EMPLOYMENT_HISTORY}`,
               state: { breadcrumbs, id: personId, historyType: HISTORY_TYPES.EMPLOYMENT, personId }
             }}
             textDecoration='underline'
           >Ir a historial de empleo
           </MyLink>
         </LinkContainer>
         )}
      </DataItem>
      {(!employmentRecord || employmentRecord.statusId === 2)
      && (
      <ButtonContainerStyle
        justifyContent={device === BREAKPOINTS.MOBILE ? 'center' : 'flex-start'}
        marginTop={(employmentRecord?.statusId === 2) ? 0 : spacing.three}
        marginBottom={(employmentRecord?.statusId === 2) ? spacing.three : spacing.two}
      >
        <GhostButton
          color='primary'
          icon='add'
          size='small'
          text='Crear nuevo registro de empleo'
          onClick={handleCreateEmploymentRecord}
          reverse
        />
      </ButtonContainerStyle>
      )}
    </>
  )

  const renderAffiliateRecordCard = (affiliateRecordObj, employmentRecordStatus) => {
    if (!affiliateRecordObj && employmentRecordStatus === 2) return
    return (
      <DataItem
        width='100%'
        minWidth='120px'
        padding={spacing.two}
        alignItems='stretch'
        flexDirection={device === BREAKPOINTS.MOBILE ? 'column' : 'row'}
      >
        {affiliateRecordObj
        && (
          <>
            <StatusDataContainer device={device}>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>F. de ingreso ATAE:</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={formatDateBasic(affiliateRecordObj.startDate, 'short')}
                  type='h3'
                  align='center'
                />
                <MyLink
                  to={{
                    pathname: `${SITE.EMPLOYEE_HISTORY}${pathname}${SITE.AFFILIATE_HISTORY}`,
                    state: { breadcrumbs, id: employmentRecord.id, historyType: HISTORY_TYPES.AFFILIATE, personId }
                  }}
                  textDecoration='underline'
                >Ir a historial de afiliación
                </MyLink>
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>F. egreso ATAE:</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={formatDateBasic(affiliateRecordObj.endDate, 'short')}
                  type='h3'
                  align='center'
                />
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>% Descuento</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={affiliateRecordObj.categoryContributionPerc || 'N/A'}
                  type='h3'
                  align='center'
                />
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>Categoría actual</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={affiliateRecordObj.categoryName}
                  type='h3'
                  align='center'
                />
              </DataItem>
              {affiliateRecordObj.statusId === 3
                && (
                <DataItem
                  flexDirection='column'
                  alignItems='center'
                  withoutBorder
                  marginRight={device !== BREAKPOINTS.MOBILE}
                >
                  <Text size='medium' weight='regular' align='left'>
                    Fecha de desafiliación en proceso
                  </Text>
                  <Heading
                    weight='regular'
                    size='medium'
                    text={formatDateBasic(affiliateRecordObj.endInProcessDate, 'short')}
                    type='h3'
                    align='center'
                  />
                </DataItem>
                )}
            </StatusDataContainer>
            <ButtonContainerEmployee
              flexDirection={device === BREAKPOINTS.MOBILE ? 'column' : 'row'}
              justifyContent={device === BREAKPOINTS.MOBILE ? 'center' : 'flex-end'}
              padding='8px'
              twoBadge={affiliateRecordObj.statusPayment}
              device={device}
            >
              <Badge text={affiliateRecordObj.statusName} backgroundColor='secondary' color='contrast' size='small' />
              {affiliateRecordObj.statusPayment
             && (
             <Badge
               text={affiliateRecordObj.statusPayment}
               backgroundColor='secondary'
               color='contrast'
               size='small'
             />
             )}
              <SolidButton
                size='small'
                color='primary'
                text='Ir a Registro de afiliación'
                onClick={handleAffiliateRecord}
              />
            </ButtonContainerEmployee>
          </>
        )}
        {(!affiliateRecordObj || (affiliateRecordObj?.statusId === 2 && employmentRecordStatus === 1))
         && (
         <ButtonContainerStyle
           justifyContent={affiliateRecordObj?.statusId === 2 ? 'flex-end' : 'flex-start'}
           marginTop={spacing.three}
           marginBottom={spacing.three}
         >
           <GhostButton
             color='primary'
             icon='add'
             size='small'
             text='Crear nuevo registro de afiliación'
             onClick={handleCreateAffiliateRecord}
             reverse
           />
         </ButtonContainerStyle>
         )}
      </DataItem>
    )
  }

  const renderEmployeeFrmCard = (objFrmRecord, employmentRecordStatus) => {
    if (!objFrmRecord && employmentRecordStatus === 2) return
    return (
      <FrmContainer device={device}>
        <DataItem
          width='100%'
          padding={spacing.two}
          alignItems='stretch'
          minWidth='120px'
          flexDirection={device === BREAKPOINTS.MOBILE ? 'column' : 'row'}
        >
          {objFrmRecord
        && (
          <>
            <StatusDataContainerFrm device={device}>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>F. de ingreso F.R.M:</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={formatDateBasic(objFrmRecord.startDate, 'short') || 'N/A'}
                  type='h3'
                  align='center'
                />
                <MyLink
                  to={{
                    pathname: `${SITE.EMPLOYEE_HISTORY}${pathname}${SITE.FRM_HISTORY}`,
                    state: { breadcrumbs, id: employmentRecord.id, historyType: HISTORY_TYPES.FRM, personId }
                  }}
                  textDecoration='underline'
                >Ir a historial de F.R.M
                </MyLink>
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                justifyContent='space-between'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>F. de egreso F.R.M</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={formatDateBasic(objFrmRecord.endDate, 'short') || 'N/A'}
                  type='h3'
                  align='center'
                />
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>Salario Básico</Text>
                <Text size='medium' weight='regular' align='left'>Médico Aportado</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={`${objFrmRecord?.bmsInbound?.toFixed(2) || 'N/A'}`}
                  type='h3'
                  align='center'
                />
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>Salario Básico</Text>
                <Text size='medium' weight='regular' align='left'>Médico Cobrado</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={`${objFrmRecord.bmsOutbound?.toFixed(2)}` || 'N/A'}
                  type='h3'
                  align='center'
                />
              </DataItem>
              <DataItem
                flexDirection='column'
                alignItems='center'
                justifyContent='space-between'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>Base de Cobro</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={objFrmRecord.bmsDelta || 'N/A'}
                  type='h3'
                  align='center'
                />
              </DataItem>
              {objFrmRecord?.statusId === 3
                && (
                <DataItem
                  flexDirection='column'
                  alignItems='center'
                  withoutBorder
                  marginRight={device !== BREAKPOINTS.MOBILE}
                >
                  <Text size='medium' weight='regular' align='left'>SBMs aportados con Plus</Text>
                  <Heading
                    weight='regular'
                    size='medium'
                    text={`${objFrmRecord?.bmsInboundWithBonus?.toFixed(2) || 'N/A'}`}
                    type='h3'
                    align='center'
                  />
                </DataItem>
                )}
              {objFrmRecord?.statusId === 6
              && (
              <DataItem
                flexDirection='column'
                alignItems='center'
                justifyContent='space-between'
                withoutBorder
                marginRight={device !== BREAKPOINTS.MOBILE}
              >
                <Text size='medium' weight='regular' align='left'>Devolución</Text>
                <Heading
                  weight='regular'
                  size='medium'
                  text={currencyFormatUY(objFrmRecord?.refundAmountPaid) || 'N/A'}
                  type='h3'
                  align='center'
                />
              </DataItem>
              )}
              {(objFrmRecord.statusId === 7 || objFrmRecord.statusId === 4)
                && (
                <DataItem
                  flexDirection='column'
                  alignItems='center'
                  withoutBorder
                  marginRight={device !== BREAKPOINTS.MOBILE}
                >
                  <Text size='medium' weight='regular' align='left'>
                    {`Fecha de ${objFrmRecord?.statusId === 7 ? 'desafiliación' : 'retiro'} en proceso`}
                  </Text>
                  <Heading
                    weight='regular'
                    size='medium'
                    text={formatDateBasic(objFrmRecord.endInProcessDate, 'short')}
                    type='h3'
                    align='center'
                  />
                </DataItem>
                )}
            </StatusDataContainerFrm>
            <ButtonContainerEmployee
              flexDirection={device === BREAKPOINTS.MOBILE ? 'column' : 'row'}
              justifyContent={device === BREAKPOINTS.MOBILE ? 'center' : 'flex-end'}
              padding='8px'
              device={device}
              twoBadge={objFrmRecord.statusPayment}
            >
              <Badge text={objFrmRecord.statusName} backgroundColor='secondary' color='contrast' size='small' />
              {objFrmRecord.statusPayment
              && (
                <Badge
                  text={objFrmRecord.statusPayment}
                  backgroundColor='secondary'
                  color='contrast'
                  size='small'
                />
              )}
              <SolidButton size='small' color='primary' text='Ir a Registro de FRM' onClick={handleFRMRecord} />
            </ButtonContainerEmployee>
          </>
        )}
          {(!objFrmRecord || (objFrmRecord?.statusId === 6 && employmentRecordStatus === 1))
         && (
         <ButtonContainerStyle
           justifyContent={!objFrmRecord ? 'flex-start' : 'flex-end'}
           marginTop={spacing.three}
           marginBottom={spacing.three}
           device={device}
         >
           <GhostButton
             color='primary'
             icon='add'
             size='small'
             text='Crear nuevo registro de FRM'
             onClick={handleCreateFrmRecord}
             reverse
           />
         </ButtonContainerStyle>
         )}
        </DataItem>
      </FrmContainer>
    )
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        error={error}
        showRowPointer={showRowPointer}
      />
    )
  }

  const customNotes = currentNotes => {
    const newNotes = currentNotes.map(note => {
      const currentDate = new Date(note.dateUpdated)
      return {
        id: note.id,
        description: note.description,
        date: formatDateBasic(currentDate, 'short'),
        hours: currentDate.toLocaleTimeString()
      }
    })
    return newNotes
  }
  const handleOrderChange = () => {}

  const handleSaveData = modalDataSend => {
    const { body, type, functionType } = modalDataSend
    const { token, tokenCreationDate } = currentToken

    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      switch (type) {
        case TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name: {
          if (functionType === FUNCTION_TYPE.ADD) {
            dispatch(PERSON_ACTIONS.ADD_ADDRESS({ personId: personalData.id, data: body, token }))
          } else {
            dispatch(PERSON_ACTIONS.EDIT_ADDRESS({
              personId: personalData.id,
              data: { addressId: modalDataSend.itemId, body },
              token
            }))
          }
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name: {
          if (functionType === FUNCTION_TYPE.ADD) {
            dispatch(PERSON_ACTIONS.ADD_PHONE({ personId: personalData.id, data: body, token }))
          } else {
            dispatch(PERSON_ACTIONS.EDIT_PHONE({
              personId: personalData.id,
              data: { phoneId: modalDataSend.itemId, body },
              token
            }))
          }
          break
        }
        case TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name: {
          if (functionType === FUNCTION_TYPE.ADD) {
            dispatch(PERSON_ACTIONS.ADD_EMAIL({ personId: personalData.id, data: body, token }))
          } else {
            dispatch(PERSON_ACTIONS.EDIT_EMAIL({
              personId: personalData.id,
              data: { emailId: modalDataSend.itemId, body },
              token
            }))
          }
          break
        }
        default: {
          if (functionType === FUNCTION_TYPE.ADD) {
            body.personId = personalData.id
            dispatch(PERSON_ACTIONS.ADD_BANK_ACCOUNT({ personId: personalData.id, data: body, token }))
          } else {
            dispatch(PERSON_ACTIONS.EDIT_BANK_ACCOUNT({
              personId: personalData.id,
              data: { bankAccountId: modalDataSend.itemId, body },
              token
            }))
          }
          break
        }
      }
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleUploadFile = objFile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const objToSend = {
        data: objFile,
        personId: personalData.id,
        token
      }
      dispatch(PERSON_ACTIONS.UPLOAD_FILE(objToSend))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const sendToContributionPaymentProfile = contributioPaymentId => {
    if (contributioPaymentId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributioPaymentId}`, '_blank')
      win.focus()
    }
  }

  const customDataTableCP = itemsTableCP => {
    const newData = itemsTableCP.map(item => {
      const code = (
        <CustomButtonLink
          color='tertiary'
          underline
          onClick={() => sendToContributionPaymentProfile(item.id)}
        >
          {`DI - ${item.id}`}
        </CustomButtonLink>
      )
      const originName = item.originName || origins?.find(o => o.id === item.originId)?.name || 'N/A'

      const newItem = {
        id: item.id,
        code,
        concept: item.concept,
        originName,
        currency: item.currencyName,
        amount: currencyFormatUY(item.amount),
        status: item.statusName,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month)?.label : 'N/A',
        year: item.year
      }
      return newItem
    })
    return newData
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      const filterRequest = { id: 12, token }
      dispatch(PERSON_ACTIONS.GET_FILTER_DATA(filterRequest))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadgeCP = filterName => {
    const updateBadgesCP = badgesCP.filter(badge => badge.name !== filterName)
    setBadgesCP(updateBadgesCP)
    const body = filterBodyBuilder(
      updateBadgesCP, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP
    )
    getContributionPayments(body)
  }

  const handleInputChangeCP = e => {
    if (!e?.value) delete activeFiltersCP.filterText

    let body = activeFiltersCP ? { ...activeFiltersCP } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFiltersCP({
        ...activeFiltersCP,
        filterText: e.value
      })
    }
    getContributionPayments(body)
  }

  const handleOrderChangeCP = (nameColumn, typeOrder) => {
    const colNames = COLUMN_CONTRIBUTION_PAYMENTS.find(col => col.name === nameColumn).label
    const propsForOrderBuilder = {
      filterState: activeFiltersCP,
      setFilterState: setActiveFiltersCP,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOptionCP.value,
      currentPageNumber: itemSelectedCP,
      setNewItemSelected: setItemSelectedCP
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributionPayments(body)
  }

  const handleDropdownChangeCP = e => {
    let body = null
    if (activeFiltersCP) {
      body = activeFiltersCP
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getContributionPayments(body)
    setSelectedOptionCP(e.value)
    setItemSelectedCP(0)
  }

  const handleClickCloseFiltersCP = () => {
    setShowFilterCP(!showFilterCP)
    dispatch(PERSON_ACTIONS.FILTER_DATA_CLEAR())
  }

  const handleApplyFilterCP = filters => {
    setBadgesCP(filters)
    const body = filterBodyBuilder(filters, selectedOptionCP.value, setActiveFiltersCP, activeFiltersCP, parametersCP)
    getContributionPayments(body)
  }

  const renderTableCP = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pagesCP}
          itemSelected={itemSelectedCP}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFiltersCP, selectedOptionCP.value, getContributionPayments, setItemSelectedCP
          )}
          options={rowsPerPage}
          selectedOption={selectedOptionCP}
          handleDropdownChange={handleDropdownChangeCP}
          totalItems={totalItemsCP}
          itemsPerPage={tablelistCP.length}
        />
      </Table>
    )
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFiltersCP}
      loadBadges={handleApplyFilterCP}
      filterData={arrFilters}
      isShowing={showFilterCP}
      device={device}
    />
  )

  useEffect(() => {
    getDataPerson()
  }, [])

  useEffect(() => {
    setPersonalData(personalInformation)
    setPhones(personalPhones)
    setAddresses(personalAddresses)
    setEmails(personalEmails)
    setBanksAccounts(personalBanksAccounts)
    setNotes(customNotes(personalNotes))
    setTablelist(customBodyDataTable(personalFiles))
    setColumns(customHeaderDataTable(columnData))
    setContributionPayments(conPaymentsData)
  }, [loading])

  useEffect(() => {
    if (contributionPayments) {
      setTablelistCP(customDataTableCP(contributionPayments.items))
      setColumnsCP(COLUMN_CONTRIBUTION_PAYMENTS)
      setPagesCP(contributionPayments.totalPages)
      setItemSelectedCP(contributionPayments.currentPage - 1)
      setTotalItemsCP(contributionPayments.totalItems)
      if (contributionPayments.items.length > 0) setShowTableCP(true)
    }
  }, [contributionPayments])

  useEffect(() => {
    if (filterObject) {
      if (filterObject?.length > 0) {
        setFilterData(filterObject)
        const parametersMap = filterObject.map(p => p.name)
        setParametersCP(parametersMap)
        setShowFilterCP(!showFilterCP)
      }
    }
  }, [filterObject])

  useEffect(() => {
    if (filterObjectError) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: filterObjectError.message || `Error ${filterObjectError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }
  }, [filterObjectError])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        getDataPerson()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (personalEmploymentRecord) {
      const { status, data } = personalEmploymentRecord
      if (status === 200) {
        dispatch(PERSON_ACTIONS.GET_ACTIVE_AFFILIATE_RECORD({ personId, token: currentToken.token }))
        dispatch(PERSON_ACTIONS.GET_FRM_RECORD({ personId, token: currentToken.token }))
        setEmploymentRecord(data)
      }
    }
  }, [personalEmploymentRecord])

  useEffect(() => {
    if (personalAffiliateRecord) {
      const { status, data } = personalAffiliateRecord
      if (status === 200) {
        setAffiliateRecord(data)
      }
    }
  }, [personalAffiliateRecord])

  useEffect(() => {
    if (personalFrmRecord) {
      const { status, data } = personalFrmRecord
      if (status === 200) {
        setFrmRecord(data)
      }
    }
  }, [personalFrmRecord])

  useEffect(() => {
    setPersonalData(personalInformation)
  }, [personalInformation])

  useEffect(() => {
    setAddresses(personalAddresses)
  }, [personalAddresses])

  useEffect(() => {
    setPhones(personalPhones)
  }, [personalPhones])

  useEffect(() => {
    setEmails(personalEmails)
  }, [personalEmails])

  useEffect(() => {
    setBanksAccounts(personalBanksAccounts)
  }, [personalBanksAccounts])

  useEffect(() => {
    setNotes(customNotes(personalNotes))
  }, [personalNotes])

  useEffect(() => {
    setTablelist(customBodyDataTable(personalFiles))
  }, [personalFiles])

  useEffect(() => {
    if (Object.keys(personalData).length !== 0) {
      const firstCrubm = {
        text: TRANSLATION_OF_ROUTES.PEOPLE,
        link: true,
        url: SITE.PEOPLE
      }
      const crumb = {
        text: `${personalData.billingNumber} ${personalData.fullName}`,
        link: false,
        url: pathname
      }
      setBreadcrumbs([firstCrubm, crumb])
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [personalData])

  useEffect(() => {
    if (modalData) setOpen(true)
  }, [modalData])

  useEffect(() => {
    if (informationMessage) {
      let toastData = null
      const { status, message } = informationMessage
      if (status >= 200 && status <= 299) {
        toastData = {
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        }
      } else {
        toastData = {
          title: TOAST_PROPERTIES.ERROR.title,
          message: message || `Error ${status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        }
      }
      setIsShowing(true)
      setMessageToast(toastData)
      setTimeout(() => {
        setMessageToast({
          title: '',
          message: '',
          icon: '',
          color: ''
        })
      }, 5000)
      dispatch(PERSON_ACTIONS.CLEAR_INFORMATION_MESSAGE())
    }
  }, [informationMessage])

  useEffect(() => {
    if (!fileMessage) return
    if (fileMessage.status === 200 && fileMessage.data) {
      const { data, contentType } = fileMessage
      let { fileName } = fileMessage
      // remove the \&quot
      fileName = fileName.replace(/[^a-zA-Z0-9 .]/g, '')

      const blob = new Blob([data], { type: contentType })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
  }, [fileMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs rowStart='1' rowEnd='1' columnStart='1' columnEnd='12'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <>
              <WrapperHeading
                justifyContent='space-between'
                rowStart='2'
                rowEnd='2'
                columnStart='1'
                columnEnd={device < BREAKPOINTS.TABLET ? '12' : '9'}
                device={device}
              >
                <BillingNumberContainer device={device}>
                  <Heading
                    weight='regular'
                    size='large'
                    text='No. Cobro:  '
                    type='h2'
                    align='left'
                  />
                  <Heading
                    weight='semibold'
                    size='large'
                    text={`${personalData.billingNumber}`}
                    type='h2'
                    align='left'
                  />
                </BillingNumberContainer>
                <MyLink
                  color='primary'
                  to={{
                    pathname: `${SITE.HISTORY}${pathname}`,
                    state: { breadcrumbs }
                  }}
                  textDecoration='underline'
                >
                  {TRANSLATION_OF_ROUTES.ACTIVITY_HISTORY}
                </MyLink>
              </WrapperHeading>
              <NotesContainer device={device}>
                <Top>
                  <Label name='notes' size='medium' align='left' text='Notas:' weight='bold' />
                  <ListContainer>
                    {loadingNotes
                      ? (
                        <WrapperLoading>
                          <Loading color='primary' size='xsmall' weight='bold' />
                        </WrapperLoading>
                      )
                      : notes && renderNoteList(notes)}
                  </ListContainer>
                </Top>
                <AddNoteContainer device={device}>
                  <Label name='notes' size='medium' align='left' text='Agregar Nota:' weight='bold' />
                  <TextArea size='small' color='primary' onChange={handleTextArea} value={newNote.value} />
                  <ButtonContainerStyle justifyContent='flex-end' padding='8px'>
                    <GhostButton
                      color='primary'
                      size='small'
                      text='Guardar'
                      onClick={() => handleSetNote(FUNCTION_TYPE.ADD)}
                      disabled={!newNote.value}
                      block={device === BREAKPOINTS.MOBILE}
                    />
                  </ButtonContainerStyle>
                </AddNoteContainer>
              </NotesContainer>
              <DataContainer device={device}>
                {loadingProfile
                  ? (
                    <WrapperLoading>
                      <Loading color='primary' size='xsmall' weight='bold' />
                    </WrapperLoading>
                  )
                  : (
                    <>
                      <PersonalData>
                        <Tbody>
                          {personalData && renderPersonalData(personalData)}
                        </Tbody>
                      </PersonalData>
                      <LinkContainer>
                        <MyButtonLink onClick={handleEditProfile} color='primary' underline>Editar Perfil</MyButtonLink>
                      </LinkContainer>
                    </>
                  )}
              </DataContainer>
              <ButtonContainerAddress
                justifyContent='flex-end'
                marginTop={spacing.three}
                marginBottom={spacing.three}
                buttonColor
                device={device}
              >
                <GhostButton
                  color='primary'
                  size='small'
                  icon='add'
                  text='Agregar Dirección'
                  reverse
                  onClick={() => handleModal(TYPE_PERSONAL_INFORMATION_MODAL.ADDRESS.name, FUNCTION_TYPE.ADD)}
                  block={device === BREAKPOINTS.MOBILE}
                />
              </ButtonContainerAddress>
              <AddressContainer device={device}>
                {loadingAddress
                  ? (
                    <WrapperLoading>
                      <Loading color='primary' size='xsmall' weight='bold' />
                    </WrapperLoading>
                  )
                  : addresses && renderAddresses(addresses)}
              </AddressContainer>
              <ButtonContainerPhones
                justifyContent='flex-end'
                marginTop={spacing.three}
                marginBottom={spacing.three}
                buttonColor
                device={device}
              >
                <GhostButton
                  color='primary'
                  size='small'
                  icon='add'
                  text='Agregar Teléfono'
                  reverse
                  onClick={() => handleModal(TYPE_PERSONAL_INFORMATION_MODAL.PHONE.name, FUNCTION_TYPE.ADD)}
                  block={device === BREAKPOINTS.MOBILE}
                />
              </ButtonContainerPhones>
              <PhoneContainer device={device}>
                {loadingPhones
                  ? (
                    <WrapperLoading>
                      <Loading color='primary' size='xsmall' weight='bold' />
                    </WrapperLoading>
                  )
                  : phones && renderPhones(phones)}
              </PhoneContainer>
            </>
            <ButtonContainerEmails
              justifyContent='flex-end'
              marginTop={spacing.three}
              marginBottom={spacing.three}
              buttonColor
              device={device}
            >
              <GhostButton
                color='primary'
                size='small'
                icon='add'
                text='Agregar Email'
                reverse
                onClick={() => handleModal(TYPE_PERSONAL_INFORMATION_MODAL.EMAIL.name, FUNCTION_TYPE.ADD)}
                block={device === BREAKPOINTS.MOBILE}
              />
            </ButtonContainerEmails>
            <EmailContainer device={device}>
              {loadingEmails
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : emails && renderEmails(emails)}
            </EmailContainer>
            <ButtonContainerBanksAccounts
              justifyContent='flex-end'
              marginTop={spacing.three}
              marginBottom={spacing.three}
              buttonColor
              device={device}
            >
              <GhostButton
                color='primary'
                size='small'
                icon='add'
                text='Agregar Cuenta bancaria'
                reverse
                onClick={() => handleModal(TYPE_PERSONAL_INFORMATION_MODAL.BANK_ACCOUNT.name, FUNCTION_TYPE.ADD)}
                block={device === BREAKPOINTS.MOBILE}
              />
            </ButtonContainerBanksAccounts>
            <BankAccountContainer device={device}>
              {loadingBanksAccounts
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : banksAccounts && renderBankAccounts(banksAccounts)}
            </BankAccountContainer>
            <AtaeContainer marginTop={spacing.three} marginBottom={spacing.two} device={device}>
              {loadingEmploymentRecord
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : renderEmploymentRecordCard(employmentRecord)}
              {loadingAffiliateRecord
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : employmentRecord && renderAffiliateRecordCard(affiliateRecord, employmentRecord?.statusId)}
            </AtaeContainer>
            {loadingFrmRecord
              ? (
                <WrapperLoading>
                  <Loading color='primary' size='xsmall' weight='bold' />
                </WrapperLoading>
              )
              : employmentRecord && renderEmployeeFrmCard(frmRecord, employmentRecord?.statusId)}
            {showTableCP
                && (
                  <>
                    <TextContainerCP device={device}>
                      <Text size='large' weight='bold' align='left'>
                        {SUBTITLES_TABLES.CONTRIBUTION_PAYMENTS_WITHOUT_CONTRIBUTION}
                      </Text>
                    </TextContainerCP>
                    <FilterContainer
                      columnStart={1}
                      columnEnd={12}
                      rowStart={device < BREAKPOINTS.TABLET ? '16' : '15'}
                      rowEnd={device < BREAKPOINTS.TABLET ? '16' : '15'}
                      deviceName={deviceName}
                    >
                      <FilterContainerLeftSide deviceName={deviceName}>
                        <GhostButton
                          color='tertiary'
                          size='small'
                          text='Agregar Filtro'
                          icon='filter_alt'
                          onClick={handleClickShowFilters}
                        />
                        {badgesCP.length > 0 && <BadgeGroup badges={badgesCP} handleCloseBadge={handleCloseBadgeCP} />}
                        {showFilterCP
                          && filterData
                          && (filterData.length > 0 && renderFilter(filterData))}
                      </FilterContainerLeftSide>
                      <FilterContainerRightSide searchBarNoExists={!showTableCP} deviceName={deviceName}>
                        {showTableCP && (
                          <SearchBar
                            onChange={handleInputChangeCP}
                            visible={activeFiltersCP?.filterText}
                          />
                        )}
                      </FilterContainerRightSide>
                    </FilterContainer>
                    <TableContainerAffRecCP device={device}>
                      {renderTableCP({
                        currentList: tablelistCP,
                        columnsN: columnsCP,
                        currentLoading: conPayLoading,
                        handleOrder: handleOrderChangeCP,
                        error: conPaymentsDataError
                      })}
                    </TableContainerAffRecCP>
                  </>
                )}
            <ButtonContainerFile
              justifyContent='flex-end'
              marginTop={spacing.three}
              marginBottom={spacing.three}
              buttonColor
              device={device}
            >
              <GhostButton
                color='primary'
                size='small'
                icon='add'
                text='Subir Archivo'
                reverse
                onClick={handleFileClick}
                block={device === BREAKPOINTS.MOBILE}
              />
            </ButtonContainerFile>
            <TableWrapper
              rowStart={device < BREAKPOINTS.TABLET ? 21 : 20}
              rowEnd={device < BREAKPOINTS.TABLET ? 21 : 20}
              columnStart='1'
              columnEnd='12'
            >
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingFile,
                handleOrder: handleOrderChange,
                error: false })}
            </TableWrapper>
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight={fileModal ? '380px' : '260px'}
              transparent={removeItemModal}
              padding={device === BREAKPOINTS.MOBILE ? spacing.one : spacing.four}
            >
              {fileModal
                && (
                  <FileDataModal
                    token={currentToken.token}
                    type={TYPE_PERSONAL_INFORMATION_MODAL.FILE.name}
                    close={handleCloseModal}
                    uploadFile={handleUploadFile}
                  />
                )}
              {modalData
                && (
                  <PersonalDataModal
                    {...modalData}
                    saveData={handleSaveData}
                    token={currentToken.token}
                  />
                )}
              {removeItemModal
              && (
                <DeleteConfirmationModal
                  {...removeItemModal}
                />
              )}
              {editProfileModal
              && (
                <ProfileModal
                  {...editProfileModal}
                />
              )}
              {createEmploymentRecordModal
              && (
                <CreateEmploymentRecord
                  {...createEmploymentRecordModal}
                />
              )}
              {createAffiliateRecordModal
              && (
                <CreateAffiliateRecordModal
                  {...createAffiliateRecordModal}
                />
              )}
              {createFrmRecordModal
              && (
                <CreateFrmRecord
                  {...createFrmRecordModal}
                />
              )}
            </Modal>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
              timeOut={messageToast.title === TOAST_PROPERTIES.ERROR.title ? 3000 : 2000}
            />
          </>
        )}
    </>
  )
}

export default Person
