import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { SERVICE_PROVIDER_PROFILE_ACTIONS } from './actions'
import { SERVICE_PROVIDER_PROFILE } from './types'

const apiCalls = {
  getServiceProviderProfile: ({ serviceProviderProfileId, token }) => (
    Axios(`ServiceProvider/${serviceProviderProfileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editServiceProvider: ({ serviceProviderProfileId, data, token }) => (
    Axios.post(`ServiceProvider/${serviceProviderProfileId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBankAccounts: ({ serviceProviderProfileId, token }) => (
    Axios(`BankAccount?serviceId=${serviceProviderProfileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createBankAccount: ({ data, token }) => (
    Axios.post('BankAccount/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editBankAccount: ({ bankAccountId, data, token }) => (
    Axios.post(`BankAccount/${bankAccountId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  deleteBankAccount: ({ bankAccountId, token }) => (
    Axios.delete(`BankAccount/${bankAccountId}/delete`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => (
    Axios.get('Currency',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBanks: token => (
    Axios.get('Banks',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getProviderPaymentsMade: ({ data, token }) => (
    Axios.post('OutboundSettlementDetail/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getServiceProviderProfile ({ payload }) {
  try {
    const { data: serviceProviderProfileData } = yield call(apiCalls.getServiceProviderProfile, payload)

    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.SUCCESS(serviceProviderProfileData))
  } catch (error) {
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editServiceProviderProfile ({ payload }) {
  try {
    const { serviceProviderProfileId, token } = payload
    const { status } = yield call(apiCalls.editServiceProvider, payload)
    const { data } = yield call(apiCalls.getServiceProviderProfile, { serviceProviderProfileId, token })

    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.SERVICE_PROVIDER_PROFILE_UPDATED(data))
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION({ status }))
  } catch (error) {
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getServiceProviderModalData ({ payload }) {
  try {
    const { data: banksData } = yield call(apiCalls.getBanks, payload)
    const { data: currenciesData } = yield call(apiCalls.getCurrencies, payload)
    const modalDataObj = {
      banksData,
      currenciesData
    }

    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.SERVICE_PROVIDER_GET_MODAL_DATA_INFORMATION(modalDataObj))
  } catch (error) {
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* setServiceProviderBankAccounts ({ payload }) {
  try {
    const { type, serviceProviderProfileId, bankAccountId, data, token } = payload
    switch (type) {
      case FUNCTION_TYPE.ADD:
        yield call(apiCalls.createBankAccount, { data, token })
        break
      case FUNCTION_TYPE.EDIT:
        yield call(apiCalls.editBankAccount, { bankAccountId, data, token })
        break
      default:
        yield call(apiCalls.deleteBankAccount, { bankAccountId, token })
    }
    const { status, data: bankAccountsUpdated } = yield call(apiCalls.getBankAccounts,
      { serviceProviderProfileId, token })

    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.BANK_ACCOUNTS_UPDATED(bankAccountsUpdated))
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION({ status }))
  } catch (error) {
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getDetailsProviders ({ payload }) {
  try {
    const { data: detailsProviderData } = yield call(apiCalls.getProviderPaymentsMade, payload)

    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.GET_DETAILS_SUCCESS(detailsProviderData))
  } catch (error) {
    yield put(SERVICE_PROVIDER_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(SERVICE_PROVIDER_PROFILE.GET_SERVICE_PROVIDER_PROFILE, getServiceProviderProfile)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_PROFILE, editServiceProviderProfile)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_GET_MODAL_DATA, getServiceProviderModalData)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.CREATE_SERVICE_PROVIDER_BANK_ACCOUNT, setServiceProviderBankAccounts)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_BANK_ACCOUNT, setServiceProviderBankAccounts)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.DELETE_SERVICE_PROVIDER_BANK_ACCOUNT, setServiceProviderBankAccounts)
  yield takeLatest(SERVICE_PROVIDER_PROFILE.GET_DETAILS, getDetailsProviders)
}
