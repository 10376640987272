import { colors, spacing } from '@global/theme'
import styled from 'styled-components'

export const ContainerProgressBar = styled.div`
    position: relative;
    margin-left: ${({ marginLeft }) => (marginLeft || spacing.one)};
    width: ${({ width }) => (width || '200px')};

    progress {
      align-self: center;
    }

    progress[value] {
        width: ${({ width }) => (width || '200px')};
    
        /* Reset the default appearance */
        -webkit-appearance: none;
        appearance: none;
    }

    progress[value]::-webkit-progress-bar {
        height: ${spacing.threeAndAHalf};
        background-color: ${({ backgroundColor }) => (backgroundColor
    ? colors[backgroundColor].regular : colors.secondary.regular)};
        border-radius: ${spacing.one};
        border: 1px solid ${colors.system.darker};
    }

    progress[value]::-webkit-progress-value {
        height: 26px;
        background-color: ${({ progressColor }) => (progressColor
    ? colors[progressColor].regular : colors.secondary.dark)};
        border-radius: ${`${spacing.one} ${spacing.one} ${spacing.one} ${spacing.one}`};
    }

`

export const DataContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 31%;
  display: flex;
  justify-content: center;
  align-items: center;
`
