import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold } from '@global/fonts'

export const PersonalData = styled.table`
${roboto};
${regular};
width: fit-content;
height: auto;
`
export const HistoryRecordData = styled(PersonalData)``

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '50%' : 'max-content')};  
  min-width: ${({ largeSize }) => (largeSize ? '200px' : 0)};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`

export const FileContainer = styled.div`
  height: 100%;   
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  & > span {
    cursor: pointer;
  }
  cursor: pointer;
`
