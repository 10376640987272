import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { ModalDataContainer, ButtonContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'

const CreateFrmRecord = ({ close, saveData }) => {
  useRedux()
  const { Logout } = useUser()
  const { device } = useWindowDimensions()

  const [startDate, setStartDate] = useState(new Date())
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleClick = () => {
    const newFrmRecord = {
      startDate,
      statusId: 1
    }
    saveData(newFrmRecord)
    close()
  }

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <CustomDatePicker
          name='expirationDate'
          title='Fecha de ingreso'
          color='primary'
          onChange={setStartDate}
          yearsToShow={100}
          justifyContentButton='space-between'
          block
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default CreateFrmRecord

CreateFrmRecord.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func
}
