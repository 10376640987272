export const key = 'FRM_CATEGORIES'

export const FRM_CATEGORIES = {
  GET: 'FRM_CATEGORIES/GET_LIST',
  SUCCESS: 'FRM_CATEGORIES/GET_LIST/SUCCESS',
  ERROR: 'FRM_CATEGORIES/GET_LIST/ERROR',
  CLEAR: 'FRM_CATEGORIES/CLEAR_LIST',

  SEND_FILTER: 'FRM_CATEGORIES/FILTER/SEND',
  GET_FILTER_DATA: 'FRM_CATEGORIES/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'FRM_CATEGORIES/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'FRM_CATEGORIES/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'FRM_CATEGORIES/FILTER/CLEAR',

  SET_FRM_CATEGORY: 'FRM_CATEGORIES/SET_FRM_CATEGORY',
  FRM_CATEGORY_INFORMATION: 'FRM_CATEGORIES/FRM_CATEGORY_INFORMATION',
  FRM_CATEGORY_INFORMATION_CLEAR: 'FRM_CATEGORIES/FRM_CATEGORY_INFORMATION_CLEAR'
}

export default key
