import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { rowsPerPage, TRANSLATION_OF_ROUTES, TOAST_POSITION, ACTIONS_TYPES, TOAST_PROPERTIES } from '@global/constants'
import {
  TOKEN_HELPERS,
  sortByKey,
  filterBodyBuilder,
  filterOrderBuilder,
  handleClickPaginationItem,
  createBreadcrumb
} from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  PaginationWrapper
} from '@global/styles'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { HISTORY_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData, typesEntities } from './data'
import { HistoryLink } from './styles'

const HistoryContainer = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [entity, setEntity] = useState(null)
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [filterData, setFilterData] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [badges, setBadges] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [parameters, setParameters] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getEntity = body => {
    if (entity) body = { ...body, entityType: entity.entityType, entityId: entity.entityId }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(HISTORY_ACTIONS.GET_HISTORY({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      return col.name
    })
    orderedColumns = [...orderedColumns, 'Data']
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }
    return customColumns
  }

  const handleRowClick = obj => {
    const { id } = obj
    if (id) {
      history.push({
        pathname: `${SITE.HISTORY_LOG}${pathname}/${id}`,
        state: { breadcrumbs, historyRecordId: id }
      })
    }
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const arrDescriminator = item.discriminator.split('.')
      const descriminator = arrDescriminator[arrDescriminator.length - 1]
      const entityType = Object.values(typesEntities).find(e => e.name === descriminator)?.label
      const link = (
        <HistoryLink
          color='tertiary'
          underline
          onClick={() => handleRowClick(item)}
        >
          Ver datos
        </HistoryLink>
      )
      const newItem = {
        id: item.id,
        code: item.id,
        date: item.createdAt,
        userName: item.user.fullName,
        entityType,
        entityId: item.entityId,
        description: ACTIONS_TYPES[item.description],
        link
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getEntity(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getEntity(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      // search id in endpoint  Filter/list
      const filterRequest = { id: 5, token }
      dispatch(HISTORY_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getEntity(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getEntity(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(HISTORY_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getEntity(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
      device={device}
    />
  )

  useEffect(() => {
    let url = ''
    if (state && (state.breadcrumbs.length > 0)) {
      url = state.breadcrumbs[state.breadcrumbs.length - 1]?.url
      // url can be string or object
      url = typeof url === 'string' ? url.split('/').slice(-2) : url.pathname.split('/').slice(-2)
    } else {
      url = pathname.split('/').slice(-2)
    }

    const entityType = typesEntities[url[0]]?.name
    const entityId = url[1]
    if (!entityType) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: MESSAGES.ENTITY_NO_EXIST,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    } else {
      setEntity({ entityType, entityId })
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        entityType,
        entityId,
        orderByColumn: 'Date',
        orderByDesc: true
      }
      setActiveFilters(body)
      getEntity(body)

      if (state) {
        const lastCrumb = {
          link: true,
          text: TRANSLATION_OF_ROUTES.ACTIVITY_HISTORY,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        setBreadcrumbs(createBreadcrumb(pathname))
      }
    }
  }, [])

  useEffect(() => {
    setTablelist(customBodyDataTable(items))
    if (fields) setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
  }, [items, totalPages])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      setTimeout(() => {
        history.goBack()
      }, 1500)
    }
  }, [errorMessage])

  useEffect(() => {
    if (filterObject?.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <FilterContainer columnStart={1} columnEnd={12} rowStart={2} rowEnd={2} deviceName={deviceName}>
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapper rowStart='4' rowEnd='4' columnStart='1' columnEnd='12'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapper>
            {tablelist.length > 0
             && (
             <PaginationWrapper rowStart='5' rowEnd='5' columnStart='1' columnEnd='12'>
               <PaginationGroup
                 deviceName={deviceName}
                 pages={pages}
                 itemSelected={itemSelected}
                 handleClickPaginationItem={n => handleClickPaginationItem(
                   n, activeFilters, selectedOption.value, getEntity, setItemSelected
                 )}
                 options={rowsPerPage}
                 selectedOption={selectedOption}
                 handleDropdownChange={handleDropdownChange}
                 totalItems={totalItems}
                 itemsPerPage={tablelist.length}
               />
             </PaginationWrapper>
             )}
          </>
        )}
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={handleToastClose}
      />
    </>
  )
}

export default HistoryContainer
