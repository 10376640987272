import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  MyButtonLink,
  LinkContainer,
  WrapperHeading,
  MyLink
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES
} from '@global/constants'
import { TOKEN_HELPERS, currencyFormatUY } from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { SITE } from '@routes/paths'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import Modal from '@components/modal'
import { Heading } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditPaymentDetail from './components/editPaymentDetailModal'

import useRedux from './redux'
import { PAYMENT_DETAIL_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, PaymentDetailData, Tbody, ItemLink } from './styles'

const PaymentDetail = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { paymentDetailId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [paymentDetail, setPaymentDetail] = useState(null)
  const [open, setOpen] = useState(false)
  const [editPaymentDetailModal, setEditPaymentDetailModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_PAYMENT_DETAIL)
  const editInformation = useSelector(SELECTORS.EDIT_INFORMATION)
  const paymentDetailData = useSelector(SELECTORS.PAYMENT_DETAIL_SUCCESS)
  const statusesData = useSelector(SELECTORS.PAYMENT_DETAIL_STATUSES_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const getPaymentDetailData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_DETAIL_ACTIONS.GET_PAYMENT_DETAIL(
        { paymentDetailId, token }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleDebtClick = detailId => {
    if (detailId) {
      const win = window.open(`${SITE.DEBT_PROFILE}/${detailId}`, '_blank')
      win.focus()
    }
  }

  const renderPaymentDetailData = objPaymentDetailData => (
    <>
      <Tr>
        <Td colorLight widthPer>Código:</Td>
        <Td largeSize>{objPaymentDetailData.id || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Concepto:</Td>
        <Td largeSize>{objPaymentDetailData.concept || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Descripción:</Td>
        <Td largeSize>{objPaymentDetailData.description || 'N/A'}</Td>
      </Tr>
      {objPaymentDetailData.personId
       && (
       <Tr>
         <Td colorLight widthPer>Persona:</Td>
         <Td largeSize>{objPaymentDetailData.personName || 'N/A'}</Td>
       </Tr>
       )}
      {objPaymentDetailData.detailServiceId
       && (
       <Tr>
         <Td colorLight widthPer>Servicio:</Td>
         <Td largeSize>{objPaymentDetailData.detailServiceName || 'N/A'}</Td>
       </Tr>
       )}
      <Tr>
        <Td colorLight widthPer>Adeudo:</Td>
        <Td largeSize>
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handleDebtClick(objPaymentDetailData.detailId)}
          >
            {`A - ${objPaymentDetailData.detailId}`}
          </ItemLink>
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Monto:</Td>
        <Td largeSize>{currencyFormatUY(objPaymentDetailData?.amount) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Cuenta bancaria:</Td>
        <Td largeSize>{
        `${objPaymentDetailData.bankName} - 
        ${objPaymentDetailData.bankAccount?.accountNumber} - 
        Sucursal: ${objPaymentDetailData.bankAccount?.branch || 'N/A'}` || 'N/A'
         }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Estado:</Td>
        <Td largeSize>{
          objPaymentDetailData.statusName
          || statusesData.find(s => s.id === objPaymentDetailData.statusId)
          || 'N/A'
          }
        </Td>
      </Tr>
    </>
  )

  const handleSavePaymentDetail = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_DETAIL_ACTIONS.EDIT_PAYMENT_DETAIL({
        paymentDetailId,
        data: newProfile,
        token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setEditPaymentDetailModal(null)
    setOpen(false)
  }

  const handleEditPaymentDetail = () => {
    setEditPaymentDetailModal({
      oldData: paymentDetail,
      statuses: statusesData,
      saveData: handleSavePaymentDetail,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  useEffect(() => {
    getPaymentDetailData()
  }, [])

  useEffect(() => {
    if (paymentDetailData) {
      setPaymentDetail(paymentDetailData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_DETAIL} - ${paymentDetailId}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.FINANCE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url
        }
        url += `${SITE.PAYMENTS}`
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENTS}`,
          link: true,
          url
        }
        url += `${SITE.PAYMENT_PROFILE}/${paymentDetailData.paymentId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_PROFILE} - ${paymentDetailData.paymentId}`,
          link: false,
          url
        }
        url += `${SITE.PAYMENT_DETAIL}/${paymentDetailId}`
        const lastCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_DETAIL} - ${paymentDetailId}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb, lastCrumb])
      }
    }
  }, [paymentDetailData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        getPaymentDetailData()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (editInformation) {
      if (editInformation.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: editInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [editInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
                <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                  <Breadcrumbs crumbs={breadcrumbs} />
                </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' device={device}>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {`${TRANSLATION_OF_ROUTES.PAYMENT_DETAIL} - ${paymentDetailId}`}
              </Heading>
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
            </WrapperHeading>

            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <PaymentDetailData>
                      <Tbody>
                        {paymentDetail && renderPaymentDetailData(paymentDetail)}
                      </Tbody>
                    </PaymentDetailData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditPaymentDetail} color='primary' underline>
                        Editar
                      </MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Editar Detalle de pago'
              minHeight='auto'
            >
              {editPaymentDetailModal
                && <EditPaymentDetail {...editPaymentDetailModal} />}
            </Modal>
          </>
        )}
    </>
  )
}

export default PaymentDetail
