import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'

import { Text } from '@components/texts'
import { SolidButton } from '@components/button'
import { TextInput, Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PEOPLE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { ModalDataContainer, ButtonContainer, DataContainer } from '../../styles'

const AddPersonModal = ({ close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const today = new Date()
  const [sexTypes, setSexTypes] = useState()
  const [flag, setFlag] = useState(false)
  const [selectedOptionSex, setSelectedOptionSex] = useState()
  const [showButton, setShowButton] = useState(true)
  const [ci, setCi] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [billingNumber, setBillingNumber] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [fullName, setFullName] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [birthday, setBirthday] = useState(today)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const types = useSelector(SELECTORS.INFORMATION_MODAL_TYPES)
  const confirmation = useSelector(SELECTORS.CREATE_PERSON_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((ci.value && !ci.error)
      && (billingNumber.value && !billingNumber.error)
      && (fullName.value && !fullName.error)
      && (selectedOptionSex)
      && (today > birthday)) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setCi({
      value: '',
      name: '',
      error: ''
    })
    setFullName({
      value: '',
      name: '',
      error: ''
    })
    setBillingNumber({
      value: '',
      name: '',
      error: ''
    })
    setBirthday(null)
    setSelectedOptionSex(null)
  }

  const handleDropdownChange = val => {
    setSelectedOptionSex(val.value)
  }

  const handleInputChange = val => {
    const { name, error } = val
    if ((name === 'ci' || name === 'billingNumber') && error) {
      val = {
        ...val,
        error: 'Debe ingresar un valor númerico'
      }
    }
    switch (name) {
      case 'ci':
        setCi(val)
        break
      case 'billingNumber':
        setBillingNumber(val)
        break
      default:
        setFullName(val)
        break
    }
  }

  const handleClick = () => {
    const newDataProfile = {
      ci: ci.value,
      billingNumber: billingNumber.value,
      fullName: fullName.value,
      birthday,
      sexId: selectedOptionSex.id
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PEOPLE_ACTIONS.CREATE_PERSON({ data: newDataProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    if (currentToken) dispatch(PEOPLE_ACTIONS.GET_MODAL_TYPES(currentToken.token))
  }, [])

  useEffect(() => {
    if (types) {
      setSexTypes(changeNameForLabel(types))
      setFlag(true)
    }
  }, [types])

  useEffect(() => {
    if (confirmation) {
      const { status } = confirmation
      if (status >= 200 && status <= 299) {
        cleanFields()
        saveData()
        close()
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: confirmation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setIsShowing(true)
      }
      dispatch(PEOPLE_ACTIONS.CLEAR_PERSON_INFORMATION())
    }
  }, [confirmation])

  useEffect(() => {
    errorControl()
  }, [ci, billingNumber, fullName, birthday, selectedOptionSex])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
     && (
     <>
       <ModalDataContainer flexDirection='column'>
         <DataContainer device={device}>
           <Dropdown
             name='sex'
             label='Género'
             placeholder='Seleccione'
             options={sexTypes}
             value={selectedOptionSex}
             onChange={handleDropdownChange}
             color='primary'
             height='small'
             block
           />
           <TextInput
             name='fullName'
             label='Nombre y apellido'
             placeholder='Ingrese nombre y apellido'
             color='primary'
             value={fullName.value}
             onChange={handleInputChange}
             height='small'
             required
             bits={[{ icon: 'person', position: 'left', colorBit: 'tertiary' }]}
           />
         </DataContainer>
         <Input
           type={INPUT_TYPES.NUMBER}
           showType={INPUT_TYPES.TEXT}
           name='ci'
           label='Número de cédula'
           placeholder='Ingrese número de cédula'
           color='primary'
           value={ci.value}
           onChange={handleInputChange}
           onBlur={() => {}}
           height='small'
           error={ci.error}
           required
           bits={[{ icon: 'badge', position: 'left', colorBit: 'tertiary' }]}
         />
         <Input
           type={INPUT_TYPES.NUMBER}
           showType={INPUT_TYPES.TEXT}
           name='billingNumber'
           label='Número de cobro'
           placeholder='Ingrese número de cobro'
           color='primary'
           value={billingNumber.value}
           onChange={handleInputChange}
           onBlur={() => {}}
           height='small'
           error={billingNumber.error}
           required
           bits={[{ icon: 'pin', position: 'left', colorBit: 'tertiary' }]}
         />
         <CustomDatePicker
           name='birthdy'
           title='Fecha de nacimiento'
           color='primary'
           onChange={setBirthday}
           yearsToShow={100}
           justifyContentButton='space-between'
           block
         />
         {birthday > today
        && (
        <Text size='medium' weight='regular' align='left' color='error'>
          La fecha de nacimiento no puede ser posterior a la actual
        </Text>
        )}
       </ModalDataContainer>
       <ButtonContainer justifyContent='flex-end' modal>
         <SolidButton
           size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
           color='primary'
           text='Guardar'
           onClick={handleClick}
           disabled={showButton}
           block={device === BREAKPOINTS.MOBILE}
         />
       </ButtonContainer>
       <Toast
         title={messageToast.title}
         message={messageToast.message}
         color={messageToast.color}
         icon={messageToast.icon}
         isShowing={isShowing}
         position={TOAST_POSITION.leftTop}
         onClick={() => setIsShowing(false)}
       />
     </>
     )}

    </>
  )
}

export default AddPersonModal

AddPersonModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.object
}
