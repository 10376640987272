export const key = 'EXPORT_PROFILE'

export const EXPORT_PROFILE = {
  GET_EXPORT: 'EXPORT_PROFILE/GET_EXPORT',
  GET_EXPORT_SUCCESS: 'EXPORT_PROFILE/GET_EXPORT_SUCCESS',
  GET_EXPORT_ERROR: 'EXPORT_PROFILE/GET_EXPORT_ERROR',
  CLEAR: 'EXPORT_PROFILE/CLEAR',
  DOWNLOAD_FILE: 'EXPORT_PROFILE/DOWNLOAD_FILE',
  FILE_DOWNLOADED: 'EXPORT_PROFILE/FILE_DOWNLOADED',
  GET_LOGS: 'EXPORT_PROFILE/GET_LOGS',
  GET_LOGS_SUCCESS: 'EXPORT_PROFILE/GET_LOGS_SUCCESS',
  GET_LOGS_ERROR: 'EXPORT_PROFILE/GET_LOGS_ERROR',
  SEND_FILTER: 'EXPORT_PROFILE/FILTER/SEND',
  GET_FILTER_DATA: 'EXPORT_PROFILE/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'EXPORT_PROFILE/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'EXPORT_PROFILE/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'EXPORT_PROFILE/FILTER/CLEAR',
  CLEAR_PENDING_PROCESS_INFO: 'EXPORT_PROFILE/CLEAR_PENDING_PROCESS_INFO'
}

export default key
