import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold, WEIGHT } from '@global/fonts'
import { MyButtonLink } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

export const PersonalData = styled.table`
 flex: 1;
`
export const PaymentProfileData = styled(PersonalData)`
${roboto};
${regular};
width: fit-content;
height: auto;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '50%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};
`

export const ItemLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  text-align: left;
  margin-right: ${({ marginRight }) => (marginRight ? spacing.half : 0)};
`

export const TableContainer = styled.div`
  width: ${({ device }) => (device < BREAKPOINTS.TABLET ? '100%' : '75%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 2;
  box-sizing: border-box;

  & > table {
    width: fit-content;
    overflow: auto;
    & > :last-child {
      & > div {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`

export const FormContainer = styled.div`
  width: 100%;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.two)};
  margin-top: ${({ device }) => (device < BREAKPOINTS.TABLET ? spacing.two : 0)};
  box-sizing: border-box;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & > :nth-child(1) {
    & > div > div > span {
      color: ${colors.tertiary.regular};
    }
    & > div > input {
      color: ${colors.primary.regular};
      font-weight: ${({ exist }) => exist && WEIGHT.BOLD};
    }
  }
  & > :nth-child(4) {
    width: 100%;
  }
`

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  margin: ${({ margin }) => margin || `${spacing.one} 0`};
`

export const CalculateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column-reverse' : 'row')};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin: ${({ margin }) => margin || `0 0 ${spacing.two} 0`};

  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};

  & > :nth-child(1) {
    margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)};
    width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : null)};
  }

  & > :nth-child(2) {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.three)};
    width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : null)};
  }

  & > :nth-child(3) {
    margin-top: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.oneAndAHalf)};
    margin-bottom: ${({ device }) => (device === BREAKPOINTS.MOBILE ? spacing.three : 0)};
    margin-left: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.two)};
    width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : null)};
    progress {
      width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : null)};
    }
  }
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'center' : 'flex-start')};
  margin-bottom: ${spacing.two};
  padding: ${({ padding }) => (padding || 0)};
  ${({ overflow }) => (`overflow: ${overflow}`)};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }
`

export const TextErrorContainer = styled.div`
  margin: ${spacing.two} ${spacing.half};
`

export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  margin-right: ${spacing.half};
`
