export const key = 'CURRENCIES'

export const CURRENCIES = {
  GET_CURRENCIES: 'CURRENCIES/GET_LIST',
  SUCCESS: 'CURRENCIES/GET_LIST/SUCCESS',
  ERROR: 'CURRENCIES/GET_LIST/ERROR',
  CLEAR: 'CURRENCIES/CLEAR_LIST'
}

export default key
