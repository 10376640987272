import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { LIQUIDATIONS_ACTIONS } from './actions'
import { LIQUIDATIONS } from './types'

const apiCalls = {
  getLiquidations: ({ data, token }) => (
    Axios.post('Settlement/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createLiquidation: ({ data, token }) => (
    Axios.post('Settlement/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editLiquidation: ({ liquidationId, data, token }) => (
    Axios.post(`Settlement/${liquidationId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getLiquidations ({ payload }) {
  try {
    const { token } = payload
    const { data: liquidations } = yield call(apiCalls.getLiquidations, payload)
    const { data: statuses } = yield call(apiCalls.getStatuses, token)
    yield put(LIQUIDATIONS_ACTIONS.SUCCESS({ liquidations, statuses }))
  } catch (error) {
    yield put(LIQUIDATIONS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(LIQUIDATIONS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(LIQUIDATIONS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getLiquidationStatuses ({ payload }) {
  try {
    const res = yield call(apiCalls.getStatuses, payload)
    const statuses = res.data
    yield put(LIQUIDATIONS_ACTIONS.STATUSES_INFORMATION({ statuses, status: 200 }))
  } catch (error) {
    yield put(LIQUIDATIONS_ACTIONS.STATUSES_INFORMATION(error))
  }
}

export function* addLiquidation ({ payload }) {
  try {
    const { token, data } = payload
    const { newLiquidation, activeFilters } = data

    const res = yield call(apiCalls.createLiquidation, { data: newLiquidation, token })
    const { status } = res

    const { data: liquidationsUpdated } = yield call(apiCalls.getLiquidations, { data: activeFilters, token })
    const { data: statuses } = yield call(apiCalls.getStatuses, token)

    yield put(LIQUIDATIONS_ACTIONS.ADD_LIQUIDATION_INFORMATION({ status }))
    yield put(LIQUIDATIONS_ACTIONS.SUCCESS({ liquidations: liquidationsUpdated, statuses }))
  } catch (error) {
    yield put(LIQUIDATIONS_ACTIONS.ADD_LIQUIDATION_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(LIQUIDATIONS.GET, getLiquidations)
  yield takeLatest(LIQUIDATIONS.GET_FILTER_DATA, FilterData)
  yield takeLatest(LIQUIDATIONS.GET_STATUSES, getLiquidationStatuses)
  yield takeLatest(LIQUIDATIONS.ADD_LIQUIDATION, addLiquidation)
}
