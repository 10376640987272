import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold } from '@global/fonts'
import { MyButtonLink } from '@global/styles'

export const PersonalData = styled.table`
${roboto};
${regular};
width: fit-content;
height: auto;
`
export const DebtProfileData = styled(PersonalData)`
  flex: 1;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ widthPerc }) => (widthPerc ? '30%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};
`

export const ItemLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  text-align: left;
  margin-right: ${spacing.half};
`
