import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { SolidButton } from '@components/button'
import { PasswordInput, PasswordRepeatInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { USER_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { ModalDataContainer, ButtonContainer } from '../../styles'

const EditUserPasswordModal = ({ close, userId, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [showButton, setShowButton] = useState(true)
  const [password, setPassword] = useState({
    value: '',
    name: '',
    error: ''
  })
  const [passwordRepeat, setPasswordRepeat] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const passwordInformation = useSelector(SELECTORS.PASSWORD_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((password.value && !password.error) && (passwordRepeat.value && !passwordRepeat.error)) setShowButton(false)
    else setShowButton(true)
  }

  const handleInputChange = val => {
    const { error } = val
    if (error) {
      val = {
        ...val,
        error: `Ingrese una contraseña válida. 
        Debe tener mínimo 8 carácteres, una letra mayúscula, una letra minúscula, un número y un caracter especial.`
      }
    }
    setPassword(val)
  }

  const cleanFields = () => {
    setPassword({
      value: '',
      name: '',
      error: ''
    })
    setPasswordRepeat({
      value: '',
      name: '',
      error: ''
    })
  }

  const handleChangePasswordRepeat = name => setPasswordRepeat(name)

  const handleClick = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(USER_PROFILE_ACTIONS.SET_PASSWORD({ userId, data: { password: password.value }, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    if (passwordInformation) {
      const { status } = passwordInformation
      if (status >= 200 && status <= 299) {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.PASSWORD_MODIFIED_SUCCESSFULLY,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
        cleanFields()
        setTimeout(() => {
          dispatch(USER_PROFILE_ACTIONS.CLEAR_PASSWORD_INFORMATION())
          close()
        }, 2100)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: passwordInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [passwordInformation])

  useEffect(() => {
    errorControl()
  }, [password, passwordRepeat])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <PasswordInput
          name='password'
          label='Contraseña'
          placeholder='Ingrese contraseña nueva.'
          value={password.value}
          onChange={handleInputChange}
          error={password.error}
          color='primary'
          height='small'
          bits={[{ icon: 'password', position: 'left', colorBit: 'tertiary' }]}
          required
        />
        <PasswordRepeatInput
          name='passwordRepaeat'
          label='Repetir contraseña'
          placeholder='Repita la contraseña.'
          pwRepeat={password.value}
          onChange={handleChangePasswordRepeat}
          color='primary'
          error={passwordRepeat.error}
          message={passwordRepeat.error}
          bits={[{ icon: 'password', position: 'left', colorBit: 'tertiary' }]}
          height='small'
          required
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
        timeOut={2000}
      />
    </>
  )
}

export default EditUserPasswordModal

EditUserPasswordModal.propTypes = {
  close: PropTypes.func,
  userId: PropTypes.string,
  currentToken: PropTypes.string
}
