import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPeople = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPeople, state => get(state, 'loading', null)
)

const selectStatuses = createSelector(
  selectPeople, state => get(state, 'statuses', null)
)

const selectSuccess = createSelector(
  selectPeople, state => get(state, 'usersList', [])
)

const selectColumns = createSelector(
  selectPeople, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectPeople, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectPeople, state => get(state, 'totalItems', 0)
)

const selectErrorStatuses = createSelector(
  selectPeople, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectPeople, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectPeople, state => get(state, 'dataFiltersError', null)
)

const selectAddUserInformation = createSelector(
  selectPeople, state => get(state, 'addUserInformation', null)
)

const selectUsersError = createSelector(
  selectPeople, state => get(state, 'usersError', null)
)

const selectUsersLoading = createSelector(
  selectPeople, state => get(state, 'usersLoading', null)
)

export default {
  LOADING: selectLoading,
  STATUSES: selectStatuses,
  ERROR_STATUSES: selectErrorStatuses,

  USERS_LOADING: selectUsersLoading,
  SUCCESS: selectSuccess,
  USERS_ERROR: selectUsersError,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,
  ADD_PERSON_INFORMATION: selectAddUserInformation
}
