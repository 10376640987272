import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  TextContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  TIMEOUT_PENDING_PROCESS,
  MONTHS,
  EXTRA_PAYMENT
} from '@global/constants'
import {
  TOKEN_HELPERS,
  formatDateBasic,
  sortByKey,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  currencyFormatUY
} from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Modal from '@components/modal'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'
import Filter from '@components/filter'
import Table from '@components/table'
import ProgressBar from '@components/progressBar'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditDepositProfileModal from './components/editDepositProfileModal'
import AddDepositDetailModal from './components/addDepositDetailModal'

import useRedux from './redux'
import { DEPOSIT_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, DepositProfileData, Tbody, ItemLink } from './styles'
import { columnData } from './data'

const DepositProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { depositId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { device, deviceName } = useWindowDimensions()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [depositProfile, setDepositProfile] = useState(null)
  const [open, setOpen] = useState(false)
  const [editStatusModal, setEditStatusModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [showTable, setShowTable] = useState(false)
  const [columns, setColumns] = useState([])
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [addDepositProfileModal, setAddDepositProfileModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)
  const [currencies, setCurrencies] = useState()
  const [parameters, setParameters] = useState()
  const [isChangingStatusState, setIsChangingStatusState] = useState(false)
  const [progressValue, setProgressValue] = useState(0)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEditProfile = useSelector(SELECTORS.LOADING_EDIT_PROFILE)
  const depositProfileData = useSelector(SELECTORS.SUCCESS)
  const depositProfileStatuses = useSelector(SELECTORS.DEPOSIT_PROFILE_STATUSES)
  const depositProfileOrigins = useSelector(SELECTORS.DEPOSIT_PROFILE_ORIGINS)
  const errorMessage = useSelector(SELECTORS.ERROR)

  const currenciesData = useSelector(SELECTORS.CURRENCIES_INFORMATION)

  const listLoading = useSelector(SELECTORS.LIST_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.CONTRIBUTIONS_PAYMENTS_SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const isChangingStatusInfo = useSelector(SELECTORS.IS_CHANGING_STATUS_INFO)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const setValueInput = text => {
    const inputSearch = document.getElementById('text_search')
    if (inputSearch) {
      inputSearch.value = text || ''
    }
  }

  const renderDepositProfileData = objDepositProfileData => (
    <>
      <Tr>
        <Td colorLight widthPerc>Código:</Td>
        <Td largeSize>
          {objDepositProfileData.id || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Fecha de Notificación:</Td>
        <Td largeSize>
          {objDepositProfileData.notificationDate
            ? formatDateBasic(objDepositProfileData.notificationDate, 'short')
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Fecha de pago:</Td>
        <Td largeSize>
          {objDepositProfileData.paymentMadeDate
            ? formatDateBasic(objDepositProfileData.paymentMadeDate, 'short')
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Fecha de confirmación de pago:</Td>
        <Td largeSize>
          {objDepositProfileData.paymentConfirmedDate
            ? formatDateBasic(objDepositProfileData.paymentConfirmedDate, 'short')
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Mes de Liquidación:</Td>
        <Td largeSize>
          {MONTHS?.find(m => m.id + 1 === objDepositProfileData.month).label || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Año de Liquidación:</Td>
        <Td largeSize>
          {objDepositProfileData.year
            ? objDepositProfileData.year
            : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Origen:</Td>
        <Td largeSize>{
        objDepositProfileData.originName
        || depositProfileOrigins
          ? depositProfileOrigins?.find(s => s.id === objDepositProfileData.originId)?.name
          : 'N/A'
        }
        </Td>
      </Tr>
      {objDepositProfileData?.isExtraPayment
        && (
        <Tr>
          <Td colorLight widthPer>Tipo:</Td>
          <Td largeSize>{EXTRA_PAYMENT}</Td>
        </Tr>
        )}
      <Tr>
        <Td colorLight widthPerc>Monto total:</Td>
        <Td largeSize>{currencyFormatUY(objDepositProfileData.amount) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Monto consolidado:</Td>
        <Td largeSize>{currencyFormatUY(objDepositProfileData.amountPaid) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Estado:</Td>
        <Td largeSize>{
          objDepositProfileData.statusName
          || depositProfileStatuses
            ? depositProfileStatuses?.find(s => s.id === objDepositProfileData.statusId)?.name
            : 'N/A'
          }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPerc>Descripción:</Td>
        <Td largeSize>{objDepositProfileData?.description || 'N/A'}</Td>
      </Tr>
      {isChangingStatusState
            && (
              <Tr>
                <ProgressBar value={progressValue} max={100} marginLeft={spacing.quarter} />
              </Tr>
            )}
    </>
  )

  const getDepositDetails = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.GET_CONTRIBUTIONS_PAYMENTS({ inboundPaymentId: depositId, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataInProcess = statusId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.GET_PROCESS_PENDING_INFO({ statusId, inboundPaymentId: depositId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleSaveDepositProfile = newStatus => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.EDIT_DEPOSIT({ inboundPaymentId: depositId, data: newStatus, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditStatusModal(null)
    setAddDepositProfileModal(null)
  }

  const handleEditStatus = () => {
    setModalTitle('Editar Ingreso')
    setEditStatusModal({
      currentToken,
      oldData: depositProfile,
      saveData: handleSaveDepositProfile,
      close: handleCloseModal
    })
    setOpen(true)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 12, token }
      dispatch(DEPOSIT_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDepositDetails(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    setActiveFilters(body)
    getDepositDetails(body)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getDepositDetails(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getDepositDetails(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickCloseFilters = () => {
    dispatch(DEPOSIT_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getDepositDetails(body)
  }

  const handleSaveNewContributionPayment = newContributionPayment => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const body = { pageNumber: 1, pageSize: selectedOption.value, orderByColumn: 'Id', orderByDesc: true }
      dispatch(DEPOSIT_PROFILE_ACTIONS.ADD_CONTRIBUTION_PAYMENT({
        inboundPaymentId: depositId, data: newContributionPayment, body, token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleAddDeposit = () => {
    setModalTitle('Agregar Detalle de Ingreso')
    setAddDepositProfileModal({
      saveData: handleSaveNewContributionPayment,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  const handleSendToContributionPayment = contributioPaymentId => {
    if (contributioPaymentId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributioPaymentId}`, '_blank')
      win.focus()
    }
  }

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleContributionClick = contributionId => {
    const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      const statusName = item.statusId === 3
        ? (<Text color='error'>{item.statusName}</Text>)
        : item.statusName

      const code = item.id
        ? (
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handleSendToContributionPayment(item.id)}
          >
            {`DI - ${item.id}`}
          </ItemLink>
        )
        : 'N/A'

      const person = item.personId && item.person
        ? (
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handlePersonClick(item.personId)}
          >
            {`${item.billingNumber} - ${item.person}`}
          </ItemLink>
        )
        : 'N/A'

      const contribution = item.contributionId
        ? (
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handleContributionClick(item.contributionId)}
          >
            {`C - ${item.contributionId}`}
          </ItemLink>
        ) : 'N/A'

      const originName = item.originName || depositProfileOrigins?.find(o => o.id === item.originId)?.name || 'N/A'

      return {
        id: item.id,
        code,
        concept: item.concept,
        person,
        currency: item.currencyName || currencies.find(c => c.id === item.currencyId).name,
        amount: currencyFormatUY(item.amount),
        contribution,
        statusName,
        month: item.month ? MONTHS?.find(m => m.id + 1 === item.month).label : 'N/A',
        year: item.year,
        originName,
        description: item.description
      }
    })
    return newData
  }

  const customHeaderDataTable = (headersData, colData) => {
    let orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      return col.name
    })

    orderedColumns = [...orderedColumns, 'Description']

    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getDepositDetails, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.GET_DEPOSIT_PROFILE({ inboundPaymentId: depositId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    setTablelist(customDataTable(items))
    setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
    setShowTable(true)
  }, [items, totalPages])

  useEffect(() => {
    if (currenciesData && currenciesData.status === 200) {
      setCurrencies(currenciesData.currenciesObj)
    }
  }, [currenciesData])

  useEffect(() => {
    if (depositProfileData) {
      setDepositProfile(depositProfileData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.DEPOSIT_PROFILE} - ${depositProfileData.id}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.FINANCE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url
        }
        url += `${SITE.INCOME}`
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.INCOME}`,
          link: true,
          url
        }
        url += `${SITE.DEPOSIT_PROFILE}/${depositProfileData.id}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.DEPOSIT_PROFILE} - ${depositProfileData.id}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }
    }
  }, [depositProfileData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        dispatch(DEPOSIT_PROFILE_ACTIONS.CLEAR())
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(DEPOSIT_PROFILE_ACTIONS.GET_DEPOSIT_PROFILE({ inboundPaymentId: depositId, token }))
          const body = activeFilters
          || { pageNumber: 1, pageSize: selectedOption.value, orderByColumn: 'Id', orderByDesc: true }
          getDepositDetails(body)
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (messageInformation) {
      if (messageInformation === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      handleCloseModal()
      setIsShowing(true)
      dispatch(DEPOSIT_PROFILE_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    let changingStatusTimeOut = null
    if (isChangingStatusInfo) {
      const { status, isChangingStatus, progressPercentage } = isChangingStatusInfo
      if (status === 200) {
        setIsChangingStatusState(isChangingStatus)
        setProgressValue(progressPercentage * 100)
        if (isChangingStatus) {
          changingStatusTimeOut = setTimeout(() => {
            getDataInProcess(depositProfile?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }

        if (!isChangingStatus && !changingStatusTimeOut) {
          clearTimeout(changingStatusTimeOut)
          if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
          const body = activeFilters
          || { pageNumber: 1, pageSize: selectedOption.value, orderByColumn: 'Id', orderByDesc: true }
          getDepositDetails(body)
        }
      }
    } else {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: isChangingStatusInfo?.message || `Error ${isChangingStatusInfo?.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }

    return () => {
      clearTimeout(changingStatusTimeOut)
    }
  }, [isChangingStatusInfo])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading
              columnStart='1'
              columnEnd='12'
              rowStart='2'
              rowEnd='2'
              device={device}
            >
              {depositProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`${TRANSLATION_OF_ROUTES.DEPOSIT_PROFILE} - ${depositProfile.id}`}
               </Heading>
               )}
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEditProfile
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <DepositProfileData>
                      <Tbody>
                        {depositProfile && renderDepositProfileData(depositProfile)}
                      </Tbody>
                    </DepositProfileData>
                    <LinkContainer flex={2}>
                      <MyButtonLink onClick={handleEditStatus} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}

            </DataContainer>
            {!showTable
              ? (
                <WrapperLoading>
                  <Loading color='primary' size='xsmall' weight='bold' />
                </WrapperLoading>
              )
              : (
                <>
                  <TextContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4}>
                    <Text size='large' weight='bold' align='left'>Detalles de ingreso</Text>
                  </TextContainer>
                  <FilterContainer columnStart={1} columnEnd={12} rowStart={5} rowEnd={5} deviceName={deviceName}>
                    <FilterContainerLeftSide deviceName={deviceName}>
                      {showTable
                 && (
                 <GhostButton
                   color='tertiary'
                   size='small'
                   text='Agregar Filtro'
                   icon='filter_alt'
                   onClick={handleClickShowFilters}
                 />
                 )}
                      {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                      {showFilter && (filterData.length > 0 && renderFilter(filterData))}
                    </FilterContainerLeftSide>
                    <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                      {showTable && (
                      <SearchBar
                        onChange={handleInputChange}
                        visible={activeFilters?.filterText}
                        value={activeFilters?.filterText}
                      />
                      )}
                      <GhostButton
                        color='tertiary'
                        size='small'
                        icon='add'
                        text='Agregar Detalle Ingreso'
                        onClick={handleAddDeposit}
                        disabled={depositProfile?.statusId !== 2}
                      />
                    </FilterContainerRightSide>
                  </FilterContainer>
                  <TableWrapper rowStart='6' rowEnd='6' columnStart='1' columnEnd='12'>
                    {renderTable({
                      currentList: tablelist,
                      columnsN: columns,
                      currentLoading: listLoading,
                      handleOrder: handleOrderChange,
                      error: !!errorMessage,
                      showRowPointer: true
                    })}
                  </TableWrapper>
                </>
              )}
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
              top={addDepositProfileModal ? '10%' : null}
              widthWrapper={addDepositProfileModal ? '95%' : null}
              widthArea={addDepositProfileModal && 'auto'}
              left={addDepositProfileModal ? '5%' : null}
              padding={addDepositProfileModal ? spacing.one : spacing.two}
            >
              {editStatusModal && (<EditDepositProfileModal {...editStatusModal} />)}
              {addDepositProfileModal && (<AddDepositDetailModal {...addDepositProfileModal} />)}
            </Modal>
          </>
        )}
    </>
  )
}

export default DepositProfile
