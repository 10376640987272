import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { changeNameForLabel, TOKEN_HELPERS, customBankAccounts } from '@utils/helpers'
import { TOAST_POSITION, BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PAYMENT_DETAIL_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const EditPaymentDetail = ({ oldData, statuses, saveData, close, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [amount, setAmount] = useState({
    name: '',
    value: oldData.amount,
    error: ''
  })
  const [description, setDescription] = useState({
    value: oldData.description,
    name: '',
    error: ''
  })
  const [optionSelectedBankAccount, setOptionSelectedBankAccount] = useState()
  const [optionsBankAccount, setOptionsBankAccount] = useState()
  const [options] = useState(changeNameForLabel(statuses))
  const [optionsSelected, setOptionsSelected] = useState()
  const [showButton, setShowButton] = useState(true)
  const [flag, setFlag] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const bankAccountsData = useSelector(SELECTORS.BANK_ACCOUNTS)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleChange = val => setAmount(val)
  const handleInputChange = val => setDescription(val)

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'status') setOptionsSelected(value)
    else setOptionSelectedBankAccount(value)
  }

  const clearStates = () => {
    setAmount({
      name: '',
      value: '',
      error: ''
    })
    setOptionsSelected(null)
  }

  const handleClick = () => {
    const contributionProfiledEdited = {
      amount: amount.value,
      statusId: optionsSelected.id,
      description: description.value,
      bankAccountId: optionSelectedBankAccount.id
    }
    saveData(contributionProfiledEdited)
    clearStates()
    close()
  }

  const errorControl = () => {
    if ((amount.value && !amount.error) && optionsSelected) setShowButton(false)
    else setShowButton(true)
  }

  const handleToastClose = () => setIsShowing(false)

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const { personId, detailServiceId } = oldData
      dispatch(PAYMENT_DETAIL_ACTIONS.GET_BANK_ACCOUNTS({ personId, detailServiceId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (options) {
      setOptionsSelected(options.find(o => o.id === oldData.statusId))
    }
  }, [options])

  useEffect(() => {
    errorControl()
  }, [amount, optionsSelected])

  useEffect(() => {
    if (bankAccountsData) {
      if (bankAccountsData.status === 200) {
        const { bankAccounts } = bankAccountsData
        setOptionsBankAccount(customBankAccounts(bankAccounts))
      }
    }
  }, [bankAccountsData])

  useEffect(() => {
    if (optionsBankAccount) {
      setOptionSelectedBankAccount(optionsBankAccount.find(ba => ba.id === oldData.bankAccountId))
      setFlag(true)
    }
  }, [optionsBankAccount])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <Input
            type={INPUT_TYPES.NUMBER}
            showType={INPUT_TYPES.TEXT}
            label='Monto'
            placeholder='Ingrese monto'
            name='amount'
            value={amount.value}
            error={amount.error}
            height='small'
            color='primary'
            onChange={handleChange}
            onBlur={() => {}}
            bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
            min={0}
          />
          <TextInput
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            value={description.value}
            height='small'
            color='primary'
            onChange={handleInputChange}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Estado'
            options={options}
            value={optionsSelected}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='bankAccounts'
            label='Cuentas bancarias'
            placeholder='Seleccione'
            options={optionsBankAccount}
            value={optionSelectedBankAccount}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
        </ModalDataContainer>
        <ButtonsContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={handleToastClose}
        />
      </>
      )}
    </>
  )
}

export default EditPaymentDetail

EditPaymentDetail.propTypes = {
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func,
  statuses: PropTypes.array,
  currentToken: PropTypes.object
}
