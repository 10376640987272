import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectIncome = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectIncome, state => get(state, 'loading', null)
)

const selectLoadingIncome = createSelector(
  selectIncome, state => get(state, 'incomeLoading', null)
)

const selectSuccess = createSelector(
  selectIncome, state => get(state, 'incomeList', [])
)

const selectColumns = createSelector(
  selectIncome, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectIncome, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectIncome, state => get(state, 'totalItems', 0)
)

const selectError = createSelector(
  selectIncome, state => get(state, 'error', null)
)

const selectDataFilters = createSelector(
  selectIncome, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectIncome, state => get(state, 'dataFiltersError', null)
)

const selectModaldata = createSelector(
  selectIncome, state => get(state, 'modalData', [])
)

const selectMessageInformation = createSelector(
  selectIncome, state => get(state, 'messageInformation', null)
)

export default {
  SUCCESS: selectSuccess,
  COLUMNS: selectColumns,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingIncome,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  MODAL_DATA_INFORMATION: selectModaldata,
  ADD_DEPOSIT_INFORMATION: selectMessageInformation
}
