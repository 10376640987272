import { USER_PROFILE } from './types'

export const getUser = payload => ({
  type: USER_PROFILE.GET_USER,
  payload
})

export const getUserSuccess = payload => ({
  type: USER_PROFILE.GET_USER_SUCCESS,
  payload
})

export const getUserError = payload => ({
  type: USER_PROFILE.GET_USER_ERROR,
  payload
})

export const clearUser = () => ({
  type: USER_PROFILE.CLEAR
})

export const setUserPassword = payload => ({
  type: USER_PROFILE.SET_PASSWORD,
  payload
})

export const setUserPasswordInformation = payload => ({
  type: USER_PROFILE.SET_PASSWORD_INFORMATION,
  payload
})

export const clearPasswordInformation = () => ({
  type: USER_PROFILE.CLEAR_PASSWORD_INFORMATION
})

export const editUserProfile = payload => ({
  type: USER_PROFILE.EDIT_USER_PROFILE,
  payload
})

export const setProfileInformation = payload => ({
  type: USER_PROFILE.SET_PROFILE_INFORMATION,
  payload
})

export const setNewdataProfile = payload => ({
  type: USER_PROFILE.SET_NEW_DATA_PROFILE,
  payload
})

export const clearProfileInformation = () => ({
  type: USER_PROFILE.CLEAR_PROFILE_INFORMATION
})

export const USER_PROFILE_ACTIONS = {
  GET_USER: getUser,
  GET_USER_SUCCESS: getUserSuccess,
  GET_USER_ERROR: getUserError,
  CLEAR: clearUser,
  SET_PASSWORD: setUserPassword,
  SET_PASSWORD_INFORMATION: setUserPasswordInformation,
  CLEAR_PASSWORD_INFORMATION: clearPasswordInformation,
  EDIT_USER_PROFILE: editUserProfile,
  SET_PROFILE_INFORMATION: setProfileInformation,
  SET_NEW_DATA_PROFILE: setNewdataProfile,
  CLEAR_PROFILE_INFORMATION: clearProfileInformation
}
