import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { ButtonsContainer, ModalDataContainer } from '@global/styles'
import { spacing } from '@global/theme'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { AFFILIATE_RECORD_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { AFFILIATE_RECORD_MODAL_TYPES } from '../../data'

const EditAffiliateRecordProfile = ({ oldData, close, saveData, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [optionsCategories, setOptionsCategories] = useState()
  const [optionSelectedCategories, setOptionSelectedCategories] = useState(null)
  const [optionsStatuses, setOptionsStatuses] = useState()
  const [optionSelectedStatuses, setOptionSelectedStatuses] = useState(null)
  const [startDate, setStartDate] = useState(oldData.startDate ? new Date(oldData.startDate) : null)
  const [endDate, setEndDate] = useState(oldData.endDate ? new Date(oldData.endDate) : null)
  const [endInProcessDate, setEndInProcessDate] = useState(
    oldData.endInProcessDate ? new Date(oldData.endInProcessDate) : new Date()
  )

  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const modalDataResponse = useSelector(SELECTORS.MODAL_DATA_RESPONSE)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'category') setOptionSelectedCategories(value)
    else setOptionSelectedStatuses(value)
  }

  const clearStates = () => {
    setStartDate(null)
    setEndDate(null)
    setOptionSelectedCategories(null)
    setOptionSelectedStatuses(null)
  }

  const handleClick = () => {
    const newDataProfile = {
      startDate: startDate.toISOString(),
      endDate: endDate ? endDate.toISOString() : null,
      statusId: optionSelectedStatuses.id,
      categoryId: optionSelectedCategories.id,
      endInProcessDate
    }
    saveData(newDataProfile)
    clearStates()
    close()
  }

  const errorControl = () => {
    if (flag) {
      if (optionSelectedCategories
          && optionSelectedStatuses
          && (endDate
            ? (endDate?.setHours(0, 0, 0, 0) >= startDate?.setHours(0, 0, 0, 0))
            : startDate)) setShowButton(false)
      else setShowButton(true)
    }
  }

  useEffect(() => {
    dispatch(AFFILIATE_RECORD_ACTIONS.GET_MODAL_DATA(
      {
        type: AFFILIATE_RECORD_MODAL_TYPES.EDIT_PROFILE,
        token: currentToken.token
      }
    ))
  }, [])

  useEffect(() => {
    if (modalDataResponse) {
      if (modalDataResponse.status === 200) {
        const { statuses, categories } = modalDataResponse
        setOptionsStatuses(changeNameForLabel(statuses))
        setOptionSelectedStatuses(changeNameForLabel(statuses).find(item => item.id === oldData.statusId))
        setOptionsCategories(changeNameForLabel(categories))
        setOptionSelectedCategories(changeNameForLabel(categories).find(item => item.id === oldData.categoryId))
        setFlag(true)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalDataResponse.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        setTimeout(() => close(), 5000)
      }
    }
  }, [modalDataResponse])

  useEffect(() => {
    errorControl()
  }, [startDate, endDate, optionSelectedCategories, optionSelectedStatuses])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <CustomDatePicker
            name='startDate'
            title='Fecha de ingreso'
            color='primary'
            date={startDate}
            onChange={setStartDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
          <CustomDatePicker
            name='endDate'
            title='Fecha de egreso'
            color='primary'
            date={endDate}
            onChange={setEndDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
            isClearable
            empty={!endDate}
          />
          <Dropdown
            name='category'
            label='Categoría'
            placeholder='seleccione una opción'
            options={optionsCategories}
            value={optionSelectedCategories}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='seleccione una opción'
            options={optionsStatuses}
            value={optionSelectedStatuses}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          {optionSelectedStatuses.id === 3
          && (
          <CustomDatePicker
            name='endInProcessDate'
            title='Fecha de desafiliación en proceso'
            color='primary'
            date={endInProcessDate}
            onChange={setEndInProcessDate}
            yearsToShow={100}
            justifyContentButton='space-between'
            block
          />
          )}
        </ModalDataContainer>
          {(endDate && (startDate?.setHours(0, 0, 0, 0) > endDate?.setHours(0, 0, 0, 0)))
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             La fecha de ingreso no puede ser posterior a la fecha de engreso.
           </Text>
           )}
        <ButtonsContainer justifyContent='flex-end' marginTop={spacing.two}>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.leftTop}
          onClick={() => setIsShowing(false)}
        />
      </>
      )}
    </>
  )
}

export default EditAffiliateRecordProfile

EditAffiliateRecordProfile.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  oldData: PropTypes.object,
  currentToken: PropTypes.object
}
