import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectUserProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectUserProfile, state => get(state, 'loading', false)
)

const selectUserSuccess = createSelector(
  selectUserProfile, state => get(state, 'userProfile', null)
)

const selectStausesSuccess = createSelector(
  selectUserProfile, state => get(state, 'statuses', null)
)

const selectUserError = createSelector(
  selectUserProfile, state => get(state, 'error', null)
)

const selectPasswordInformation = createSelector(
  selectUserProfile, state => get(state, 'passwordInformation', null)
)

const selectProfileInformation = createSelector(
  selectUserProfile, state => get(state, 'profileInformation', null)
)

const selectLoadingProfile = createSelector(
  selectUserProfile, state => get(state, 'loadingProfile', false)
)

export default {
  USER_SUCCESS: selectUserSuccess,
  USER_ERROR: selectUserError,
  STATUSES: selectStausesSuccess,
  LOADING: selectLoading,
  PASSWORD_INFORMATION: selectPasswordInformation,
  PROFILE_INFORMATION: selectProfileInformation,
  LOADING_PROFILE: selectLoadingProfile
}
