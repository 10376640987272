import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { DEPOSIT_PROFILE_ACTIONS } from './actions'
import { DEPOSIT_PROFILE } from './types'

const apiCalls = {
  getDepositProfile: ({ inboundPaymentId, token }) => (
    Axios.get(`InboundPayment/${inboundPaymentId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDepositProfileStatuses: token => (
    Axios.get('InboundPayment/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getDepositProfileSources: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ inboundPaymentId, data, token }) => (
    Axios.post(`InboundPayment/${inboundPaymentId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributionsPayments: ({ inboundPaymentId, data, token }) => (
    Axios.post(`InboundPayment/${inboundPaymentId}/contributionpayment/filter`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  addContributionPayment: ({ inboundPaymentId, data, token }) => (
    Axios.post(`InboundPayment/${inboundPaymentId}/contributionpayment/create`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributions: ({ data, token }) => (
    Axios.post('Contribution/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getPerson: ({ personId, token }) => (
    Axios.get(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getConcepts: ({ personId, token }) => (
    Axios.get(`Concept?personId=${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getDepositProfile ({ payload }) {
  try {
    const { token } = payload
    const { status, data: depositProfileObj } = yield call(apiCalls.getDepositProfile, payload)
    const { data: statuses } = yield call(apiCalls.getDepositProfileStatuses, token)
    const { data: sources } = yield call(apiCalls.getDepositProfileSources, token)
    const { isChangingStatus, progressPercentage } = depositProfileObj

    yield put(DEPOSIT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isChangingStatus, progressPercentage }
    ))

    yield put(DEPOSIT_PROFILE_ACTIONS.SUCCESS({ depositProfileObj, statuses, sources }))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editDepositProfile ({ payload }) {
  try {
    const { inboundPaymentId, data, token } = payload
    const { status, data: profileUpdated } = yield call(apiCalls.editProfile, payload)
    const { statusId } = data

    const { isChangingStatus, progressPercentage } = profileUpdated
    yield put(DEPOSIT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isChangingStatus, progressPercentage }
    ))
    yield put(DEPOSIT_PROFILE_ACTIONS.DEPOSIT_PROFILE_UPDATED(profileUpdated))
    yield put(DEPOSIT_PROFILE_ACTIONS.EDIT_DEPOSIT_INFORMATION(status))

    if (statusId === 1 && !isChangingStatus) {
      yield put(DEPOSIT_PROFILE_ACTIONS.CONTRIBUTIONS_PAYMENTS_UPDATE())
      const body = { pageNumber: 1, pageSize: 15 }
      const { data: contributionsPaymentUpdated } = yield call(apiCalls.getContributionsPayments,
        { inboundPaymentId, data: body, token })
      yield put(DEPOSIT_PROFILE_ACTIONS.CONTRIBUTIONS_PAYMENTS_SUCCESS(contributionsPaymentUpdated))
    }
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.EDIT_DEPOSIT_INFORMATION(error))
  }
}

export function* getContributionsPayments ({ payload }) {
  try {
    const { token } = payload
    const { data } = yield call(apiCalls.getContributionsPayments, payload)
    const { status, data: currenciesObj } = yield call(apiCalls.getCurrencies, token)

    yield put(DEPOSIT_PROFILE_ACTIONS.CONTRIBUTIONS_PAYMENTS_SUCCESS(data))
    yield put(DEPOSIT_PROFILE_ACTIONS.CURRENCIES_INFORMATION({ status, currenciesObj }))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.CONTRIBUTIONS_PAYMENTS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(DEPOSIT_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalDataDepositProfile ({ payload }) {
  try {
    const { data: statuses } = yield call(apiCalls.getDepositProfileStatuses, payload)
    const { data: origins } = yield call(apiCalls.getDepositProfileSources, payload)

    const modalDataObj = {
      statuses,
      origins
    }
    yield put(DEPOSIT_PROFILE_ACTIONS.MODAL_DATA_INFORMATION({ status: 200, modalDataObj }))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.MODAL_DATA_INFORMATION(error))
  }
}

export function* createContributionPayment ({ payload }) {
  try {
    const { inboundPaymentId, data, body, token } = payload
    const dataCreateCP = {
      inboundPaymentId,
      data,
      token
    }
    const dataContributionPayment = {
      inboundPaymentId,
      data: body,
      token
    }
    const { status } = yield call(apiCalls.addContributionPayment, dataCreateCP)
    const { data: contributionsUpdated } = yield call(apiCalls.getContributionsPayments, dataContributionPayment)

    yield put(DEPOSIT_PROFILE_ACTIONS.CONTRIBUTIONS_PAYMENTS_SUCCESS(contributionsUpdated))
    yield put(DEPOSIT_PROFILE_ACTIONS.ADD_CONTRIBUTIONS_PAYMENTS_INFORMATION(status))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.ADD_CONTRIBUTIONS_PAYMENTS_INFORMATION(error))
  }
}

export function* depositProfileGetContributions ({ payload }) {
  try {
    const { status, data: contributionData } = yield call(apiCalls.getContributions, payload)

    yield put(DEPOSIT_PROFILE_ACTIONS.DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION({ status, contributionData }))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION(error))
  }
}

export function* getPendingProcessInfo ({ payload }) {
  try {
    const { statusId, inboundPaymentId, token } = payload
    const { status, data: depositProfileObj } = yield call(apiCalls.getDepositProfile, { inboundPaymentId, token })
    const { isCalculating, isChangingStatus, progressPercentage } = depositProfileObj

    if (statusId !== depositProfileObj?.statusId && !isChangingStatus) {
      yield put(DEPOSIT_PROFILE_ACTIONS.DEPOSIT_PROFILE_UPDATED(depositProfileObj))
    }
    yield put(DEPOSIT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(error))
  }
}

export function* getPersonConceptsDPS ({ payload }) {
  try {
    const { personId, token } = payload
    const { data: conceptsData } = yield call(apiCalls.getConcepts, { personId, token })

    yield put(DEPOSIT_PROFILE_ACTIONS.PERSON_CONCEPT_DATA_INFORMATION({ status: 200, conceptsData }))
  } catch (error) {
    yield put(DEPOSIT_PROFILE_ACTIONS.PERSON_CONCEPT_DATA_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(DEPOSIT_PROFILE.GET_DEPOSIT_PROFILE, getDepositProfile)
  yield takeLatest(DEPOSIT_PROFILE.EDIT_DEPOSIT_PROFILE, editDepositProfile)
  yield takeLatest(DEPOSIT_PROFILE.GET_CONTRIBUTIONS_PAYMENTS, getContributionsPayments)
  yield takeLatest(DEPOSIT_PROFILE.GET_FILTER_DATA, filterData)
  yield takeLatest(DEPOSIT_PROFILE.GET_MODAL_DATA, getModalDataDepositProfile)
  yield takeLatest(DEPOSIT_PROFILE.ADD_CONTRIBUTION_PAYMENT, createContributionPayment)
  yield takeLatest(DEPOSIT_PROFILE.DEPOSIT_PROFILE_GET_CONTRIBUTION, depositProfileGetContributions)
  yield takeLatest(DEPOSIT_PROFILE.GET_PENDING_PROCESS_INFO, getPendingProcessInfo)
  yield takeLatest(DEPOSIT_PROFILE.GET_PERSON_CONCEPTS, getPersonConceptsDPS)
}
