import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { FUNCTION_TYPE, TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { ModalDataContainer, ButtonContainer } from '@global/styles'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput, CheckboxInput } from '@components/inputs/inputs'
import { Input } from '@components/inputs/inputs/baseInput/baseInput'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { ATAE_BANK_ACCOUNTS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'
import { MyLabel } from '../../styles'

const CreateAndEditBankAccountsModal = ({ oldData = null, close, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [flag, setFlag] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [optionsBanks, setOptionsBanks] = useState()
  const [selectedBank, setSelectedBank] = useState()
  const [optionsCurrencies, setOptionsCurrencies] = useState()
  const [selectedCurrency, setSelectedCurrency] = useState()
  const [checked, setChecked] = useState({
    name: 'checkbox',
    value: oldData?.isPrimary || false
  })
  const [isShowing, setIsShowing] = useState(false)
  const [accountNumber, setAccountNumber] = useState({
    name: '',
    value: oldData?.accountNumber || '',
    error: ''
  })
  const [branch, setBranch] = useState({
    name: '',
    value: oldData?.branch || '',
    error: ''
  })
  const [description, setDescription] = useState({
    name: '',
    value: oldData?.description || '',
    error: ''
  })
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const modalData = useSelector(SELECTORS.MODAL_DATA)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'banks') setSelectedBank(value)
    else setSelectedCurrency(value)
  }

  const handleInputChange = val => {
    const { name } = val
    switch (name) {
      case 'accountNumber':
        setAccountNumber(val)
        break
      case 'branch':
        setBranch(val)
        break
      default:
        setDescription(val)
    }
  }

  const handleCheckboxChange = val => setChecked(val)

  const cleanFields = () => {
    setAccountNumber({
      value: '',
      name: '',
      error: ''
    })
    setBranch({
      value: '',
      name: '',
      error: ''
    })
    setDescription({
      value: '',
      name: '',
      error: ''
    })
    setSelectedBank(null)
    setSelectedCurrency(null)
  }

  const errorControl = () => {
    if ((accountNumber.value && !accountNumber.error)
      && selectedBank
      && selectedCurrency) setShowButton(false)
    else setShowButton(true)
  }

  const handleClick = () => {
    const newBankAccount = {
      accountNumber: accountNumber.value,
      bankId: selectedBank.id,
      branch: branch.value,
      description: description.value,
      isPrimary: checked.value,
      currencyId: selectedCurrency.id
    }
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      if (oldData) {
        dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.EDIT_ATAE_BANK_ACCOUNT(
          {
            type: FUNCTION_TYPE.EDIT,
            bankAccountId: oldData?.id,
            data: newBankAccount,
            token
          }
        ))
      } else {
        dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.CREATE_ATAE_BANK_ACCOUNT(
          { type: FUNCTION_TYPE.ADD, data: newBankAccount, token }
        ))
      }
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.ATAE_GET_MODAL_DATA(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (modalData) {
      const { banksData, currenciesData } = modalData
      setOptionsBanks(changeNameForLabel(banksData))
      // id 3 is Basic Medic Salary and 4 is IU
      setOptionsCurrencies(changeNameForLabel(currenciesData.filter(c => (c.id !== 3 && c.id !== 4))))
      if (!oldData) setFlag(true)
    }
  }, [modalData])

  useEffect(() => {
    if (optionsBanks && optionsCurrencies && oldData) {
      setSelectedBank(optionsBanks.find(ba => ba.id === oldData.bankId))
      setSelectedCurrency(optionsCurrencies.find(c => c.id === oldData.currencyId))
      setFlag(true)
    }
  }, [optionsBanks, optionsCurrencies])

  useEffect(() => {
    errorControl()
  }, [accountNumber, selectedBank, selectedCurrency])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200 || !status) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
        setTimeout(() => {
          cleanFields()
          close()
        }, 1000)
      }
      dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.CLEAR_MESSAGE_INFORMATION())
      setIsShowing(true)
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {flag
    && (
    <ModalDataContainer flexDirection='column'>
      <Dropdown
        name='banks'
        label='Banco'
        placeholder='Seleccione'
        options={optionsBanks}
        value={selectedBank}
        onChange={handleDropdownChange}
        color='primary'
        height='small'
        block
      />
      <Input
        type={INPUT_TYPES.NUMBER}
        showType={INPUT_TYPES.TEXT}
        label='Número de cuenta'
        placeholder='Ingrese número de cuenta'
        name='accountNumber'
        value={accountNumber.value}
        error={accountNumber.error}
        height='small'
        color='primary'
        onChange={handleInputChange}
        onBlur={() => handleInputChange(accountNumber)}
        bits={[{ icon: 'pin', position: 'left', colorBit: 'tertiary' }]}
      />
      <Dropdown
        name='currencies'
        label='Divisa'
        placeholder='Seleccione'
        options={optionsCurrencies}
        value={selectedCurrency}
        onChange={handleDropdownChange}
        color='primary'
        height='small'
        block
      />
      <TextInput
        name='branch'
        label='Sucursal'
        placeholder='Ingrese sucursal'
        value={branch.value}
        height='small'
        color='primary'
        onChange={handleInputChange}
        bits={[{ icon: 'home', position: 'left', colorBit: 'tertiary' }]}
      />
      <TextInput
        name='description'
        label='Descripción'
        placeholder='Ingrese descripción'
        value={description.value}
        height='small'
        color='primary'
        onChange={handleInputChange}
        bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
      />
      <>
        <MyLabel>
          Cuenta
        </MyLabel>
        <CheckboxInput
          label='Primaria'
          name='checkbox'
          color='primary'
          value={checked.value}
          onChange={handleCheckboxChange}
        />
      </>
      <ButtonContainer justifyContent='flex-end' marginTop={spacing.two}>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
    </ModalDataContainer>
    )}
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default CreateAndEditBankAccountsModal

CreateAndEditBankAccountsModal.propTypes = {
  oldData: PropTypes.object,
  close: PropTypes.func,
  currentToken: PropTypes.string
}
