import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PAYMENT_PROFILE_ACTIONS } from './actions'
import { PAYMENT_PROFILE } from './types'

const apiCalls = {
  getPaymentProfile: ({ paymentId, token }) => (
    Axios.get(`OutboundPayment/${paymentId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentProfileStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ paymentId, data, token }) => (
    Axios.post(`OutboundPayment/${paymentId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentDetails: ({ data, token }) => (
    Axios.post('OutboundPaymentDetails/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createPaymentDetail: ({ data, token }) => (
    Axios.post('OutboundPaymentDetails/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getOutboundSettlementDatail: ({ data, token }) => (
    Axios.post('OutboundSettlementDetail/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBankAccounts: ({ personId, serviceId, currencyId, token }) => {
    let url = 'BankAccount'
    if (personId) url += `?personId=${personId}`
    if (personId && serviceId) {
      url += `&serviceId=${serviceId}`
    } else if (serviceId) url += `?serviceId=${serviceId}`

    if ((personId || serviceId) && currencyId) {
      url += `&currencyId=${currencyId}`
    } else if (currencyId) url += `?currencyId=${currencyId}`

    return (
      Axios.get(url,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  },
  getBanksList: token => Axios('Banks',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getConcepts: token => Axios('Concept',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  calculateDetails: ({ paymentId, data, token }) => Axios.post(`OutboundPayment/${paymentId}/calculateDetails`, data,
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getNoBankAccounts:
    props => Axios.post(`OutboundSettlement/${props?.outboundSettlementId}/getinvaliddetails`,
      props?.data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(props.token)
        }
      })
}

export function* getPaymentProfile ({ payload }) {
  try {
    const { token } = payload
    const { status, data: paymentProfileObj } = yield call(apiCalls.getPaymentProfile, payload)
    const { data: statuses } = yield call(apiCalls.getPaymentProfileStatuses, token)
    const { data: banks } = yield call(apiCalls.getBanksList, token)

    const { isCalculating, isChangingStatus, progressPercentage } = paymentProfileObj

    yield put(PAYMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_PROFILE_ACTIONS.SUCCESS({ paymentProfileObj, statuses, banks }))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editPaymentProfile ({ payload }) {
  try {
    const { paymentId, data, token } = payload
    const { status, data: profileUpdated } = yield call(apiCalls.editProfile, payload)
    const { statusId } = data

    const { isCalculating, isChangingStatus, progressPercentage } = profileUpdated

    yield put(PAYMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_PROFILE_UPDATED(profileUpdated))
    yield put(PAYMENT_PROFILE_ACTIONS.EDIT_PAYMENT_INFORMATION(status))

    if (statusId === 1 && !isChangingStatus) {
      yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_DETAILS_UPDATE())
      const body = {
        pageNumber: 1,
        pageSize: 15,
        outboundPaymentId: paymentId
      }
      const { data: paymentsDetailsUpdated } = yield call(apiCalls.getPaymentDetails, { data: body, token })
      yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_DETAILS_SUCCESS(paymentsDetailsUpdated))
    }
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.EDIT_PAYMENT_INFORMATION(error))
  }
}

export function* getPaymentDetails ({ payload }) {
  try {
    const { data: paymentsDetails } = yield call(apiCalls.getPaymentDetails, payload)

    yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_DETAILS_SUCCESS(paymentsDetails))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_DETAILS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(PAYMENT_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* addPaymentDetail ({ payload }) {
  try {
    const { token, data, body } = payload
    const { status } = yield call(apiCalls.createPaymentDetail, { data, token })

    const { data: paymentDetailsUpdated } = yield call(apiCalls.getPaymentDetails, { data: body, token })

    yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_DETAILS_SUCCESS(paymentDetailsUpdated))
    yield put(PAYMENT_PROFILE_ACTIONS.MESSAGE_INFORMATION(status))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getOutboundSettlementDatail ({ payload }) {
  try {
    const { status, data: outboundSettlementDatailObj } = yield call(apiCalls.getOutboundSettlementDatail, payload)

    yield put(PAYMENT_PROFILE_ACTIONS.OUTBOUND_SETTLEMENT_DETAIL_INFORMATION({ status, outboundSettlementDatailObj }))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.OUTBOUND_SETTLEMENT_DETAIL_INFORMATION(error))
  }
}

export function* getBankAccountsModal ({ payload }) {
  try {
    const { personId, serviceId, currencyId, token } = payload
    const { status, data: bankAccounts } = yield call(apiCalls.getBankAccounts,
      { personId, serviceId, currencyId, token })

    yield put(PAYMENT_PROFILE_ACTIONS.BANK_ACCOUNTS_INFORMATION({ status, bankAccounts }))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.BANK_ACCOUNTS_INFORMATION(error))
  }
}

export function* getOutboundPaymentDetailsConcepts ({ payload }) {
  try {
    const { data: concepts } = yield call(apiCalls.getConcepts, payload)

    yield put(PAYMENT_PROFILE_ACTIONS.CONCEPTS_INFORMATION(concepts))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* calculateOutboundPaymentDetails ({ payload }) {
  try {
    const { paymentId, token } = payload
    const { status: statusCalculate } = yield call(apiCalls.calculateDetails, payload)
    const { status, data: paymentProfileObj } = yield call(apiCalls.getPaymentProfile, { paymentId, token })

    const { isCalculating, isChangingStatus, progressPercentage } = paymentProfileObj

    yield put(PAYMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))

    yield put(PAYMENT_PROFILE_ACTIONS.CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION({ status: statusCalculate }))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION(error))
  }
}

export function* getPendingProcessInfo ({ payload }) {
  try {
    const { statusId, paymentId, token } = payload
    const { status, data: paymentProfileObj } = yield call(apiCalls.getPaymentProfile, { paymentId, token })
    const { isCalculating, isChangingStatus, progressPercentage } = paymentProfileObj

    if (statusId !== paymentProfileObj?.statusId && !isChangingStatus) {
      yield put(PAYMENT_PROFILE_ACTIONS.PAYMENT_PROFILE_UPDATED(paymentProfileObj))
    }
    yield put(PAYMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(
      { status, isCalculating, isChangingStatus, progressPercentage }
    ))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.PENDING_PROCESS_INFO(error))
  }
}

export function* getNotBankAccounts ({ payload }) {
  try {
    const { data: noBankAccounts } = yield call(apiCalls.getNoBankAccounts, payload)

    yield put(PAYMENT_PROFILE_ACTIONS.NO_BANK_ACCOUNTS_SUCCESS(noBankAccounts))
  } catch (error) {
    yield put(PAYMENT_PROFILE_ACTIONS.NO_BANK_ACCOUNTS_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(PAYMENT_PROFILE.GET_PAYMENT_PROFILE, getPaymentProfile)
  yield takeLatest(PAYMENT_PROFILE.EDIT_PAYMENT_PROFILE, editPaymentProfile)
  yield takeLatest(PAYMENT_PROFILE.GET_PAYMENT_DETAILS, getPaymentDetails)
  yield takeLatest(PAYMENT_PROFILE.GET_FILTER_DATA, filterData)
  yield takeLatest(PAYMENT_PROFILE.ADD_PAYMENT_DETAIL, addPaymentDetail)
  yield takeLatest(PAYMENT_PROFILE.GET_OUTBOUND_SETTLEMENT_DETAIL, getOutboundSettlementDatail)
  yield takeLatest(PAYMENT_PROFILE.GET_BANK_ACCOUNTS, getBankAccountsModal)
  yield takeLatest(PAYMENT_PROFILE.GET_CONCEPTS, getOutboundPaymentDetailsConcepts)
  yield takeLatest(PAYMENT_PROFILE.CALCULATE_OUTBOUND_PAYMENTS_DETAILS, calculateOutboundPaymentDetails)
  yield takeLatest(PAYMENT_PROFILE.GET_PENDING_PROCESS_INFO, getPendingProcessInfo)
  yield takeLatest(PAYMENT_PROFILE.GET_NOT_BANK_ACCOUNTS, getNotBankAccounts)
}
