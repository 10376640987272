import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular } from '@global/fonts'

export const PersonalData = styled.table`
${roboto};
${regular};
width: fit-content;
height: auto;
`

export const DataItem = styled.div`
  position: relative;
  width: ${({ width }) => width || 'fit-content'};
  min-width: ${({ minWidth }) => minWidth || 'min-content'};
  background-color: ${({ backgroundWhite }) => (
    backgroundWhite ? colors.background.regular : colors.background.secondary)};
  padding: ${({ padding }) => padding || 0};
  margin: ${spacing.one} 0;
  margin-right: ${({ marginRight }) => marginRight && spacing.three};
  border-radius: ${spacing.one};
  ${({ withoutBorder, backgroundWhite }) => (
    withoutBorder ? 'none' : `border: 1px solid ${backgroundWhite ? colors.background.regular : '#E8E8E8'}`)
};

  box-sizing: border-box;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  flex-wrap: ${({ flexWrap }) => (flexWrap || 'wrap')};

  & > p {
    margin-bottom: ${spacing.one};
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: ${({ marginTop }) => (marginTop || 0)};
  margin-right: ${({ marginRight }) => (marginRight || 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom || 0)};
  margin-left: ${({ marginLeft }) => (marginLeft || 0)};

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  flex: 1;
  grid-column-start: 1;
  grid-column-end: 12;
  ${({ buttonColor }) => buttonColor && (`> button { background-color: ${colors.background.regular}}`)}

  > span, a {
      margin-right: ${({ marginRight }) => marginRight || 0};
      cursor: pointer;
  }


  & > div {
    margin-right: ${spacing.twoAndAHalf};
    
  }

  ${({ marginRightLastChild }) => marginRightLastChild
  && (`& > :first-child {
    margin-right: ${spacing.two};
  }`
  )}
`

export const MyLabel = styled.label`
  ${roboto}
  margin: ${spacing.one} 0;
  color: ${colors.contrast.regular};
`
