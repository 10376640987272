import React from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import MyPropTyes from '@global/propTypes'

import { useWindowDimensions } from '@components/windowDimensions'
import { Text, Heading } from '@components/texts'
import Button from '@components/button'
import Icon from '@components/icon'

import { Overlay, Wrapper, Area, Close, IconContainer, ButtonContainer } from './style'

const Modal = props => {
  const {
    isShowing,
    close,
    children,
    icon,
    title,
    text,
    actions,
    minHeight,
    transparent,
    widthWrapper,
    widthArea,
    left,
    top,
    padding
  } = props
  const { device } = useWindowDimensions()

  return (
    createPortal(
      <Overlay
        tag='aside'
        role='dialog'
        tabIndex='-1'
        aria-modal='true'
        isShowing={isShowing}
      >
        <Wrapper isShowing={isShowing} widthWrapper={widthWrapper} left={left} device={device}>
          <Area
            device={device}
            minHeight={minHeight}
            transparent={transparent}
            widthArea={widthArea}
            top={top}
            padding={padding}
          >
            <Close>
              <Text text={title} size='large' align='center' weight='bold' />
              <Icon name='close' backgroundColor='primary' size='medium' color='grays' onClick={close} />
            </Close>
            {children || (
              <>
                {icon && (
                  <IconContainer>
                    <Icon {...icon} />
                  </IconContainer>
                )}
                {title && (
                  <Heading
                    text={title}
                    type='h3'
                    size='small'
                    weight='light'
                    marginBottom='one'
                  />
                )}
                {text && <Text text={text} size='large' align='center' />}
                {!!actions.length && (
                  <ButtonContainer>
                    {actions.map(el => <Button {...el} key={el.text} />)}
                  </ButtonContainer>
                )}
              </>
            )}
          </Area>
        </Wrapper>
      </Overlay>,
      document.body
    )
  )
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node,

  title: PropTypes.string,
  text: PropTypes.string,
  minHeight: PropTypes.string,
  transparent: PropTypes.bool,
  widthWrapper: PropTypes.string,
  top: PropTypes.string,
  icon: PropTypes.shape({
    name: PropTypes.string,
    color: MyPropTyes.color,
    size: MyPropTyes.size
  }),
  padding: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      size: PropTypes.oneOf([
        'small',
        'medium',
        'large'
      ]),
      color: MyPropTyes.color,
      type: PropTypes.oneOf(['solid', 'line', 'ghost']),
      block: PropTypes.bool,
      reverse: PropTypes.bool,
      fixWidth: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })
  )
}

Modal.defaultProps = {
  actions: []
}

export default Modal
