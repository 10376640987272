import styled from 'styled-components'
import { spacing, colors } from '@global/theme'

export const Wrapper = styled.div`
  height: auto;
  width: 100%;

  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: ${({ alignItems }) => alignItems || 'center'};

  padding: 8px;
  box-sizing: border-box;
  margin: 0 !important;
`
export const Item = styled.div`
  height: ${spacing.three};
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;
  margin: 0 !important;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: ${spacing.half};
  width: ${spacing.oneAndAHalf};
  height: ${spacing.oneAndAHalf};
  border-radius: 50%;
  background:  ${colors.background.primary};
  border: 1px solid ${colors.system.disabled};
`

export const RadioButton = styled.input`
margin: 0;
margin-right: ${spacing.half};
opacity: 0;
z-index: 1;
cursor: pointer;
width: ${spacing.three};
height: ${spacing.three};

&:hover ~ ${RadioButtonLabel} {
  border-color: ${colors.primary.hover};
  &::after {
    display: block;
    color: ${colors.background.primary};
    width: ${spacing.oneAndAHalf};
    height: ${spacing.oneAndAHalf};
    margin: ${spacing.half};
  }
}
&:checked + ${Item} {
  background: ${colors.primary.regular};
  border: 0 solid ${colors.primary.regular};
}
&:checked + ${RadioButtonLabel} {
  background: ${colors.primary.regular};
  border: 1px solid ${colors.primary.regular};
  &::after {
    display: block;
    color: ${colors.background.primary};
    width: ${spacing.oneAndAHalf};
    height: ${spacing.oneAndAHalf};
    margin: ${spacing.half};
  }
}
`
