import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { EMPLOYMENT_RECORD_PROFILE_ACTIONS } from './actions'
import { EMPLOYMENT_RECORD_PROFILE } from './types'

const apiCalls = {
  getEmploymentRecord: ({ employmentRecordId, token }) => (
    Axios(`EmploymentRecord/${employmentRecordId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getEmploymentRecordProfileStatuses: token => (
    Axios('EmploymentRecord/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributions: ({ data, token }) => (
    Axios.post('Contribution/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editEmploymentRecord: ({ recordId, data, token }) => (
    Axios.post(`EmploymentRecord/${recordId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordsConPay: ({ data, token }) => (
    Axios.post('InboundPayment/contributionpayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getInboundPaymentsOrigins: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getEmploymentRecordProfile ({ payload }) {
  try {
    const { token } = payload
    const { data: employmentRecordProfileData } = yield call(apiCalls.getEmploymentRecord, payload)
    const { data: statusesData } = yield call(apiCalls.getEmploymentRecordProfileStatuses, token)
    const objData = { employmentRecordProfileData, statusesData }
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.ERROR(error))
  }
}

export function* editEmploymentRecordProfile ({ payload }) {
  try {
    const { status, data: employmentRecordUpdated } = yield call(apiCalls.editEmploymentRecord, payload)

    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EMPLOYMENT_RECORD_PROFILE_UPDATED(employmentRecordUpdated))
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION({ status }))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION(error))
  }
}

export function* getModalDataInformation ({ payload }) {
  try {
    const { status, data: statuses } = yield call(apiCalls.getEmploymentRecordProfileStatuses, payload)

    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION({ status, statuses }))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION(error))
  }
}

export function* getEmploymentRecordContributions ({ payload }) {
  try {
    const { token } = payload
    const { data } = yield call(apiCalls.getContributions, payload)
    const { status, data: currenciesObj } = yield call(apiCalls.getCurrencies, token)

    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.CONTRIBUTIONS_EMPLOYMENT_RECORD_SUCCESS(data))
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.CURRENCIES_INFORMATION({ status, currenciesObj }))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.CONTRIBUTIONS_EMPLOYMENT_RECORD_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const { type } = payload
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = {
      parametersData: res.data.parameters,
      type
    }
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getContributionPayments ({ payload }) {
  try {
    const { token } = payload
    const { data: contributionPaymentsData } = yield call(apiCalls.getAffiliateRecordsConPay, payload)
    const { data: originData } = yield call(apiCalls.getInboundPaymentsOrigins, token)

    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_CONTRIBUTION_PAYMENTS_SUCCESS(
      { contributionPaymentsData, originData }
    ))
  } catch (error) {
    yield put(EMPLOYMENT_RECORD_PROFILE_ACTIONS.GET_CONTRIBUTION_PAYMENTS_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.GET_EMPLOYMENT_RECORD_PROFILE, getEmploymentRecordProfile)
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.EDIT_EMPLOYMENT_RECORD_PROFILE, editEmploymentRecordProfile)
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.GET_EMPLOYMENT_RECORD_PROFILE_MODAL_DATA, getModalDataInformation)
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTIONS_EMPLOYMENT_RECORD, getEmploymentRecordContributions)
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.GET_FILTER_DATA, filterData)
  yield takeLatest(EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS, getContributionPayments)
}
