import React from 'react'
import PropTypes from 'prop-types'

import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import { BreadcrumbsStyle, Crumb, MyLink } from './styles'

const Breadcrumbs = ({ crumbs }) => {
  const { deviceName } = useWindowDimensions()

  return (
    <BreadcrumbsStyle deviceName={deviceName}>
      {crumbs.map((crumb, i) => (
        <Crumb key={i + 1}>
          {
            i !== crumbs.length - 1
              ? <MyLink to={crumb.url || ''}>{crumb.text}</MyLink>
              : <Text size='large' align='center' weight='bold'>{crumb.text}</Text>
          }
          {i < crumbs.length - 1 && '>'}
        </Crumb>
      ))}
    </BreadcrumbsStyle>
  )
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array
}
export default Breadcrumbs
