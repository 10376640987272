import { EXPORTS } from './types'

export const getExport = payload => ({
  type: EXPORTS.GET,
  payload
})

export const getExportSuccess = payload => ({
  type: EXPORTS.SUCCESS,
  payload
})

export const getExportError = payload => ({
  type: EXPORTS.ERROR,
  payload
})

export const ClearExportList = payload => ({
  type: EXPORTS.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: EXPORTS.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: EXPORTS.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: EXPORTS.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: EXPORTS.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: EXPORTS.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: EXPORTS.GET_MODAL_DATA,
  payload
})

export const modalDataInformation = payload => ({
  type: EXPORTS.MODAL_DATA_INFORMATION,
  payload
})

export const messageInformation = payload => ({
  type: EXPORTS.MESSAGE_INFORMATION,
  payload
})

export const clearMessageInformation = payload => ({
  type: EXPORTS.CLEAR_MESSAGE_INFORMATION,
  payload
})

export const createExport = payload => ({
  type: EXPORTS.CREATE_EXPORT,
  payload
})

export const exportSuccess = payload => ({
  type: EXPORTS.EXPORT_SUCCESS,
  payload
})

export const getPaymentExports = payload => ({
  type: EXPORTS.GET_PAYMENTS_EXPORTS,
  payload
})

export const paymentExportsInformation = payload => ({
  type: EXPORTS.PAYMENTS_EXPORTS_INFORMATION,
  payload
})

export const clearData = () => ({
  type: EXPORTS.CLEAR_DATA
})

export const getConcepts = payload => ({
  type: EXPORTS.GET_CONCEPTS,
  payload
})

export const getConceptsSuccess = payload => ({
  type: EXPORTS.CONCEPTS_SUCCESS,
  payload
})

export const EXPORTS_ACTIONS = {
  GET: getExport,
  SUCCESS: getExportSuccess,
  ERROR: getExportError,
  CLEAR: ClearExportList,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_INFORMATION: modalDataInformation,

  MESSAGE_INFORMATION: messageInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  CREATE_EXPORT: createExport,
  EXPORT_SUCCESS: exportSuccess,

  GET_PAYMENTS_EXPORTS: getPaymentExports,
  PAYMENTS_EXPORTS_INFORMATION: paymentExportsInformation,

  CLEAR_DATA: clearData,

  GET_CONCEPTS: getConcepts,
  CONCEPTS_SUCCESS: getConceptsSuccess
}
