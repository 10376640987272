import React, { useState, useEffect } from 'react'

import { INPUT_TYPES } from '@components/inputs/utils/constants'

import Input from '../../baseInput/baseInput'
import propTypes from '../../propTypes'

export const Password = props => {
  const { bits, error } = props

  const [hidden, setHidden] = useState(true)
  const [newBits, setNewBits] = useState([])

  useEffect(() => {
    const passwordBit = {
      name: 'showHide',
      position: 'right',
      icon: hidden ? 'visibility' : 'visibility_off',
      onClick: () => { setHidden(!hidden) }
    }
    if (error) passwordBit.color = 'error'

    setNewBits([...bits, passwordBit])
  }, [hidden, error, bits])

  return (
    <Input
      {...props}
      type={INPUT_TYPES.PASSWORD}
      showType={hidden ? INPUT_TYPES.PASSWORD : INPUT_TYPES.TEXT}
      bits={newBits}
    />
  )
}

Password.propTypes = propTypes

Password.defaultProps = {
  bits: []
}

export default Password
