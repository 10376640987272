import { EMPLOYEE_HISTORY } from './types'

export const getEmployeeHistory = payload => ({
  type: EMPLOYEE_HISTORY.GET_EMPLOYEE_HISTORY,
  payload
})

export const getSuccess = payload => ({
  type: EMPLOYEE_HISTORY.SUCCESS,
  payload
})

export const getError = payload => ({
  type: EMPLOYEE_HISTORY.ERROR,
  payload
})

export const ClearList = payload => ({
  type: EMPLOYEE_HISTORY.CLEAR,
  payload
})

export const EMPLOYEE_HISTORY_ACTIONS = {
  GET_EMPLOYEE_HISTORY: getEmployeeHistory,
  SUCCESS: getSuccess,
  ERROR: getError,
  CLEAR: ClearList
}
