import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import { SITE } from '@routes/paths'

import { useUser } from '@components/authentication/utils/hook'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { GetUser } = useUser()
  const user = GetUser()

  return (
    <Route {...rest}>
      { user ? <Component /> : <Redirect to={SITE.SIGN_IN} />}
    </Route>
  )
}
export default PrivateRoute

PrivateRoute.propTypes = {
  component: PropTypes.any
}
