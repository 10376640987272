import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { SITE } from '@routes/paths'
import {
  TOKEN_HELPERS,
  formatDateBasic,
  sortByKey,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder
} from '@utils/helpers'
import {
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperLoading,
  WrapperBreadcrumbs
} from '@global/styles'
import {
  TOAST_POSITION,
  TRANSLATION_OF_ROUTES,
  fileTypeLogos,
  rowsPerPage,
  TOAST_PROPERTIES,
  TIMEOUT_PENDING_PROCESS
} from '@global/constants'
import { MESSAGES } from '@global/message'
import { spacing } from '@global/theme'

import Breadcrumbs from '@components/breadcrums'
import { Heading, Text } from '@components/texts'
import Loading from '@components/loading'
import Toast from '@components/toast'
import Table from '@components/table'
import Filter from '@components/filter'
import { GhostButton } from '@components/button'
import { useUser } from '@components/authentication/utils/hook'
import BadgeGroup from '@components/badgeGroup'
import PaginationGroup from '@components/paginationGroup'
import SearchBar from '@components/searchBar'
import Icon from '@components/icon'
import ProgressBar from '@components/progressBar'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import {
  Wrapper,
  Tbody,
  Td,
  Tr,
  ExportData,
  DataContainer,
  FileContainer,
  ButtonDownloadFile,
  StyledImg,
  WrapperLogActivity,
  LogContainer,
  LogItem
} from './styles'

import useRedux from './redux'
import { EXPORT_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { columnData } from './data'

const ExportProfile = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { jobId } = useParams()
  const history = useHistory()
  const { deviceName, device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [exportFile, setExportFile] = useState()
  const [severities, setSeverities] = useState()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [badges, setBadges] = useState([])
  const [filterData, setFilterData] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorFlag, setErrorFlag] = useState({
    profile: false,
    logs: false
  })
  const [parameters, setParameters] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const exportData = useSelector(SELECTORS.EXPORT_SUCCESS)
  const exportProfileLoading = useSelector(SELECTORS.EXPORT_PROFILE_LOADING)
  const fileDownloaded = useSelector(SELECTORS.FILE_DOWNLOADED)
  const loadingDownload = useSelector(SELECTORS.LOADING_DOWNLOAD)
  const loadingTableLogs = useSelector(SELECTORS.LOADING_TABLE_LOGS)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.GET_LOGS_SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorLog = useSelector(SELECTORS.EXPORT_ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const pendingProcessInfo = useSelector(SELECTORS.PENDING_PROCESS_INFO)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const handleDownloadFile = fileId => {
    dispatch(EXPORT_PROFILE_ACTIONS.DOWNLOAD_FILE({ fileId, token: currentToken.token }))
  }

  const renderImporData = objExportData => {
    const { logCount, errorCount, infoCount, warningCount } = objExportData
    const error = (
      <LogItem>
        <Text size='large' weight='semibold' align='left'>{errorCount}</Text>
        <Icon name='dangerous' color='error' size='medium' />
      </LogItem>
    )
    const warning = (
      <LogItem>
        <Text size='large' weight='semibold' align='left'>{warningCount}</Text>
        <Icon name='warning' color='warning' size='medium' />
      </LogItem>
    )
    const info = (
      <LogItem>
        <Text size='large' weight='semibold' align='left'>{infoCount}</Text>
        <Icon name='info' color='info' size='medium' />
      </LogItem>
    )

    const logs = (
      <LogContainer>
        {info}
        {warning}
        {error}
      </LogContainer>
    )

    return (
      <>
        <Tr><Td colorLight widthPer>Tipo de exportación:</Td> <Td largeSize>{objExportData.typeName || 'N/A'}</Td></Tr>
        <Tr>
          <Td colorLight widthPer>Fecha de incio:</Td>
          <Td largeSize>{formatDateBasic(objExportData.dateCreated, 'short', false, false, true)}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Fecha de fin:</Td>
          <Td largeSize>{formatDateBasic(objExportData.dateFinished, 'short', false, false, true)}</Td>
        </Tr>
        <Tr><Td colorLight widthPer>Estado:</Td>
          <Td largeSize>{objExportData?.statusName || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>{`Mensajes logs (${logCount}):`}</Td>
          <Td largeSize>{logs}</Td>
        </Tr>
        {objExportData?.statusId === 2
            && (
              <Tr>
                <ProgressBar
                  value={(objExportData?.progressPercentage?.toFixed(2) * 100)}
                  max={100}
                  marginLeft={spacing.quarter}
                />
              </Tr>
            )}
      </>
    )
  }
  const getLogs = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EXPORT_PROFILE_ACTIONS.GET_LOGS({ jobId, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getLogs(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleRowClick = () => {}

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)

    getLogs(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(EXPORT_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getLogs(body)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 3, token }
      dispatch(EXPORT_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const { id, dateCreated, message, severity } = item
      return {
        id,
        dateCreated,
        message,
        severity
      }
    })
    return newData
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => col.name)
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer={showRowPointer}
        timeDate
      >
        {currentList.length === 0
        && (
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={
            n => handleClickPaginationItem(n, activeFilters, selectedOption.value, getLogs, setItemSelected)
          }
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
        )}
      </Table>
    )
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getLogs(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getLogs(body)
  }

  const renderFilter = arrFilters => (
    <Filter
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
      device={device}
    />
  )

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(EXPORT_PROFILE_ACTIONS.GET_EXPORT({ jobId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.profile) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(EXPORT_PROFILE_ACTIONS.GET_EXPORT({ jobId, token }))
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
        setErrorFlag({
          ...errorFlag,
          profile: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    let timeoutPending = null
    if (pendingProcessInfo === 2) {
      timeoutPending = setTimeout(() => {
        dispatch(EXPORT_PROFILE_ACTIONS.GET_EXPORT({ jobId, token: currentToken.token }))
      }, TIMEOUT_PENDING_PROCESS)
    }

    return () => {
      clearTimeout(timeoutPending)
    }
  }, [pendingProcessInfo])

  useEffect(() => {
    if (severities) {
      const body = activeFilters || { severities, pageNumber: 1, pageSize: 15 }
      getLogs(body)
      setActiveFilters(body)
    }
  }, [severities])

  useEffect(() => {
    if (errorLog) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorLog.message || `Error ${errorLog.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.logs) {
        const body = { severities, pageNumber: 1, pageSize: 15 }
        getLogs(body)
        setErrorFlag({
          ...errorFlag,
          logs: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorLog])

  useEffect(() => {
    setTablelist(customBodyDataTable(items))
    setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
  }, [items, totalPages])

  useEffect(() => {
    if (exportData) {
      if (Object.keys(exportData).length !== 0) {
        const firstCrubm = {
          text: TRANSLATION_OF_ROUTES.SETTING,
          link: true,
          url: SITE.SETTING
        }
        const secondCrumb = {
          text: TRANSLATION_OF_ROUTES.EXPORTS,
          link: true,
          url: `${SITE.SETTING}${SITE.EXPORTS}`
        }

        setBreadcrumbs([firstCrubm, secondCrumb])
        setExportFile(exportData)
        setSeverities([1, 2, 3])
      }
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [exportData])

  useEffect(() => {
    if (exportFile) {
      const userCrumb = {
        text: `${exportFile.id} - ${exportFile.typeName || 'N/A'}`,
        link: false,
        url: ''
      }
      setBreadcrumbs([...breadcrumbs, userCrumb])
    }

    return () => {
      setBreadcrumbs([])
    }
  }, [exportFile])

  useEffect(() => {
    if (!fileDownloaded) return
    if (fileDownloaded.status === 200 && fileDownloaded.data) {
      const { data, contentType } = fileDownloaded
      let { fileName } = fileDownloaded
      // remove the \&quot
      fileName = fileName.replace(/[^a-zA-Z0-9 .]/g, '')

      const blob = new Blob([data], { type: contentType })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
  }, [fileDownloaded])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(MESSAGES.EXPIRED_TOKEN, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <Wrapper>
            {breadcrumbs
             && (
             <WrapperBreadcrumbs>
               <Breadcrumbs crumbs={breadcrumbs} />
             </WrapperBreadcrumbs>
             )}
            {exportFile
             && (
             <Heading
               weight='regular'
               size='large'
               text={`${exportFile.id} - ${exportFile.typeName || 'N/A'}`}
               type='h2'
               align='left'
             />
             )}
            <DataContainer>
              {exportProfileLoading
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <ExportData device={device}>
                      <Tbody>
                        {exportFile && renderImporData(exportFile)}
                      </Tbody>
                    </ExportData>
                    <FileContainer device={device}>
                      {exportFile?.fileId && exportFile?.statusId !== 4
                && (
                <ButtonDownloadFile
                  onClick={() => handleDownloadFile(exportFile.fileId)}
                  color='tertiary'
                  underline
                  table
                >
                  {loadingDownload
                    ? (
                      <WrapperLoading>
                        <Loading color='primary' size='xsmall' weight='bold' />
                      </WrapperLoading>
                    )
                    : (
                      <>
                        <StyledImg src={fileTypeLogos.find(logo => logo.type === exportFile.fileType)?.logo} />
                        <Text size='medium' weight='regular' align='left'>{exportFile?.fileName || 'N/A'}</Text>
                      </>
                    )}
                </ButtonDownloadFile>
                )}
                    </FileContainer>
                  </>
                )}
            </DataContainer>
            <WrapperLogActivity>
              <Text size='large' weight='bold' align='left'>Logs</Text>
              <FilterContainer columnStart={1} columnEnd={12} rowStart={5} rowEnd={5} deviceName={deviceName}>
                <FilterContainerLeftSide deviceName={deviceName}>
                  <GhostButton
                    color='tertiary'
                    size='small'
                    text='Agregar Filtro'
                    icon='filter_alt'
                    onClick={handleClickShowFilters}
                  />
                  {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                  {showFilter && (filterData.length > 0 && renderFilter(filterData))}
                </FilterContainerLeftSide>
                <FilterContainerRightSide deviceName={deviceName}>
                  <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                </FilterContainerRightSide>
              </FilterContainer>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingTableLogs,
                handleOrder: handleOrderChange,
                handleSelectedRow: handleRowClick,
                error: !!errorLog,
                showRowPointer: false
              })}
            </WrapperLogActivity>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </Wrapper>
        )}
    </>
  )
}

export default ExportProfile
