import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Person from '@containers/person'
import AffiliateRecord from '@containers/affiliateRecord'
import FrmRecord from '@containers/frmRecord'
import EmploymentRecordProfile from '@containers/employmentRecord'

export const PersonRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={`${url}/:personId`}
        component={Person}
      />
      <PrivateRoute
        exact
        path={`${url}/:personId${SITE.AFFILIATE_RECORD}/:recordId`}
        component={AffiliateRecord}
      />
      <PrivateRoute
        exact
        path={`${url}/:personId${SITE.FRM_RECORD}/:recordId`}
        component={FrmRecord}
      />
      <PrivateRoute
        exact
        path={`${url}/:personId${SITE.EMPLOYMENT_RECORD}/:employmentRecordId`}
        component={EmploymentRecordProfile}
      />
    </Switch>
  )
}
