export const key = 'ATAE_BANK_ACCOUNTS'

export const ATAE_BANK_ACCOUNTS = {
  GET_ATAE_BANK_ACCOUNTS: 'ATAE_BANK_ACCOUNTS/GET_DATA',
  SUCCESS: 'ATAE_BANK_ACCOUNTS/GET_SUCCESS',
  ERROR: 'ATAE_BANK_ACCOUNTS/GET_ERROR',
  CLEAR: 'ATAE_BANK_ACCOUNTS/CLEAR',

  CREATE_ATAE_BANK_ACCOUNT: 'ATAE_BANK_ACCOUNTS/CREATE/BANK_ACCOUNT',
  EDIT_ATAE_BANK_ACCOUNT: 'ATAE_BANK_ACCOUNTS/EDIT/BANK_ACCOUNT',
  DELETE_ATAE_BANK_ACCOUNT: 'ATAE_BANK_ACCOUNTS/DELETE/BANK_ACCOUNT',

  ATAE_GET_MODAL_DATA: 'ATAE_BANK_ACCOUNTS/GET_MODAL_DATA',
  ATAE_MODAL_DATA_INFORMATION: 'ATAE_BANK_ACCOUNTS/GET_MODAL_DATA/INFORMATION',

  MESSAGE_INFORMATION: 'ATAE_BANK_ACCOUNTS/MESSAGE_INFORMATION',
  CLEAR_MESSAGE_INFORMATION: 'ATAE_BANK_ACCOUNTS/EDIT_PROFILE/CLEAR_MESSAGE_INFORMATION'
}

export default key
