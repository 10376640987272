import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Finance from '@containers/finance'

import { LiquidationsRouter } from './LiquidationsRouter'
import { PaymentsRouter } from './PaymentsRouter'
import { IncomeRouter } from './IncomeRouter'
import { PaymentSettlementRouter } from './PaymentSettlementRouter'

export const FinanceRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={Finance} />

      <PrivateRoute path={`${url}${SITE.LIQUIDATIONS}`} component={LiquidationsRouter} />
      <PrivateRoute path={`${url}${SITE.INCOME}`} component={IncomeRouter} />

      <PrivateRoute path={`${url}${SITE.PAYMENT_SETTLEMENTS}`} component={PaymentSettlementRouter} />
      <PrivateRoute path={`${url}${SITE.PAYMENTS}`} component={PaymentsRouter} />
    </Switch>
  )
}
