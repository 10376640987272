import produce from 'immer'

import { USER } from './types'

export const initialState = {
  loading: false,
  error: false,
  user: null,
  responsePasswordConfirm: { status: 0 },
  errorRefresh: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case USER.GET:
      draft.loading = true
      break
    case USER.SUCCESS:
      draft.loading = false
      draft.user = payload
      break
    case USER.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case USER.ERROR_USER_REFRESH: {
      draft.errorRefresh = payload
      break
    }
    case USER.MESSAGE_PASSWORD_CONFIRM: {
      const { status } = payload
      draft.responsePasswordConfirm = { status }
      break
    }
    case USER.CLEAR:
      return initialState
    default:
      break
  }
})

export default reducer
