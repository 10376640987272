import styled from 'styled-components'
import { spacing, colors } from '@global/theme'

export const Container = styled.div`
  width: 100%;   
  min-height: ${({ minHeight }) => minHeight || 'auto'}; 
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  background-color: ${colors.background.regular};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  padding: ${spacing.oneAndAHalf};
  margin: ${({ margin }) => margin || 0}
  
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
`

export const MenuOptions = styled.div`
 width: auto;
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 justify-content: center;

 div {
    margin-bottom: ${spacing.threeQuarters};
  }
`
