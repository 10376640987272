import styled, { css } from 'styled-components'

import { NavLink } from 'react-router-dom'
import backgroundLogo from '@assets/background-logo.png'
import { colors } from '@global/theme'
import { roboto, bold } from '@global/fonts'
import { BREAKPOINTS } from '@global/constants'
import { NAV_WIDTH, PADDING_PAGE_MOBILE, PADDING_PAGE_REGULAR } from './constants'

export const BackgroundCustom = css`
  background-image: url(${backgroundLogo});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
`

export const Container = styled.div`
  width: auto;
  height: 100vh;
  background-color: ${colors.background.primary}!important;
  ${({ background }) => (background && BackgroundCustom)};
  
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({ device }) => {
    if (device === BREAKPOINTS.MOBILE) return '0 auto'
    if (device >= BREAKPOINTS.BIG_SCREEN) return `${NAV_WIDTH.DESKTOP}px auto`
    if (device < BREAKPOINTS.TABLET || device > BREAKPOINTS.TABLET_PORTRAIT) return `${NAV_WIDTH.MOBILE}px auto`
  }};

  grid-column-gap: 24px;

  grid-template-areas:
  'nav page page page page page page page page page page page';

  overflow-y: hidden;

`

export const Page = styled.div`
  height: auto;
  grid-area: page;
  grid-column-start: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 1 : 2)};
  grid-column-end: 13;

  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 24px;
  align-items: start;

  padding: ${({ device }) => (device === BREAKPOINTS.MOBILE ? PADDING_PAGE_MOBILE : PADDING_PAGE_REGULAR)};
  
 
  background-color: ${colors.background.regular};
  overflow-y: ${({ scroll }) => (scroll ? 'auto' : null)};
  background-color: ${colors.background.primary};
  ${({ background }) => (background && BackgroundCustom)};
`
export const MyLink = styled(NavLink)`
  ${roboto};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  font-size: 14px;
  ${bold};
  letter-spacing: 0.5px;
  color: ${({ color }) => (color ? colors[color].regular : colors.system.regular)};
`
