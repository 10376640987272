import produce from 'immer'

import { PEOPLE } from './types'

export const initialState = {
  loading: true,
  peopleLoading: false,
  error: false,
  peopleList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  modalTypes: null,
  createPersonInformation: null,
  modalLoding: false,
  personStatus: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PEOPLE.GET:
      draft.peopleLoading = true
      break
    case PEOPLE.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.loading = false
      draft.peopleLoading = false
      draft.peopleList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case PEOPLE.ERROR:
      draft.loading = false
      draft.peopleLoading = false
      draft.error = payload
      break
    case PEOPLE.CLEAR:
      return initialState
    case PEOPLE.SEND_FILTER:
      draft.loading = true
      break
    case PEOPLE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PEOPLE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PEOPLE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PEOPLE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case PEOPLE.GET_MODAL_TYPES:
      draft.modalLoding = true
      break
    case PEOPLE.INFORMATION_MODAL_TYPES:
      draft.modalLoding = false
      draft.modalTypes = payload
      break
    case PEOPLE.CREATE_PERSON:
      draft.peopleLoading = true
      break
    case PEOPLE.CREATE_PERSON_INFORMATION:
      draft.createPersonInformation = payload
      draft.peopleLoading = false
      break
    case PEOPLE.CLEAR_PERSON_INFORMATION:
      draft.createPersonInformation = null
      break
    case PEOPLE.PERSON_STATUS:
      draft.personStatus = payload
      break
    default:
      return initialState
  }
})

export default reducer
