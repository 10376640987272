import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { MONTHS, TOAST_POSITION, TOAST_PROPERTIES, rowsPerPage, BREAKPOINTS } from '@global/constants'
import {
  TOKEN_HELPERS,
  handleClickPaginationItem,
  filterOrderBuilder,
  changeNameForLabel,
  currencyFormatUY
} from '@utils/helpers'
import { MESSAGES } from '@global/message'
import { ButtonContainer, TextContainerModal } from '@global/styles'
import { spacing } from '@global/theme'

import { SolidButton } from '@components/button'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import PaginationGroup from '@components/paginationGroup'
import Table from '@components/table'
import SearchBar from '@components/searchBar'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { DEPOSIT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import {
  ModalDataContainer,
  TableContainer,
  FormContainer,
  InputContainer,
  FilterContainer,
  DatePickerContainer
} from '../../styles'
import { COLUMN_DATA_MODAL, SELECT_ROW, INICIAL_STATE_SELECTED_ROW } from '../../data'
import SearchPersonAndConceptAsync from './components/SearchPersonAndConceptAsync'
import ContributionForm from './components/contributionForm'

const AddDepositDetailModal = ({ close, saveData, currentToken, oldData, edit, setMessageToastExternal }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { deviceName, device } = useWindowDimensions()

  const [amount, setAmount] = useState({
    value: oldData ? oldData.contributionPayment.amount : '',
    name: oldData ? 'inputamount' : '',
    error: ''
  })
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [errorMessage, setErrorMessage] = useState()
  const [selectedRow, setSelectedRow] = useState(oldData?.contributionPayment?.contributionId
    ? {
      selected: true,
      contributionId: oldData?.contributionPayment?.contributionId,
      settlement: '-'
    }
    : INICIAL_STATE_SELECTED_ROW)
  const [dueDateFrom, setDueDateFrom] = useState(null)
  const [paymentDateFrom, setPaymentDateFrom] = useState()
  const [dueDateTo, setDueDateTo] = useState()
  const [paymentDateTo, setPaymentDateTo] = useState()
  const [errorColumnMessage, setErrorColumnMessage] = useState(false)
  const [description, setDescription] = useState({
    name: oldData ? 'description' : '',
    value: oldData ? oldData?.contributionPayment?.description : '',
    error: ''
  })
  const [personData, setPersonData] = useState(
    oldData
      ? {
        billingNumber: oldData?.contributionPayment?.personBillingNumber,
        label: oldData?.contributionPayment?.personFullName,
        personId: oldData?.contributionPayment?.personId,
        conceptId: oldData?.contributionPayment?.conceptId
      }
      : null
  )

  const [statusSelected, setStatusSelected] = useState(
    oldData
      ? changeNameForLabel(oldData?.statusesData).find(o => o.id === oldData?.contributionPayment?.statusId)
      : null
  )

  const depositProfileContributionsLoading = useSelector(SELECTORS.DEPOSIT_PROFILE_CONTRIBUTION_LOADING)
  const depositProfileContributionsData = useSelector(SELECTORS.DEPOSIT_PROFILE_CONTRIBUTION_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getContributions = body => {
    body = {
      ...body,
      pageNumber: 1,
      pageSize: selectedOption.value,
      statuses: [1],
      orderByDesc: true,
      orderByColumn: 'Id'
    }

    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(DEPOSIT_PROFILE_ACTIONS.DEPOSIT_PROFILE_GET_CONTRIBUTION({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const errorControl = () => {
    if ((amount.value && !amount.error)) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    if (!edit) {
      setAmount({
        value: '',
        name: '',
        error: ''
      })
      setDescription({
        value: '',
        name: '',
        error: ''
      })
      setStatusSelected(null)
    }
    setSelectedRow(INICIAL_STATE_SELECTED_ROW)
  }

  const handleInputChange = val => {
    const { name, error } = val
    if (name === 'amount') {
      if (error) {
        val = {
          ...val,
          error: 'Debe ingresar un valor númerico'
        }
      }
      setAmount(val)
    } else {
      setDescription(val)
    }
  }

  const handleClick = () => {
    if (edit) delete personData.label

    const newContributionPayment = {
      ...personData,
      amount: amount.value || null,
      contributionId: selectedRow.contributionId === SELECT_ROW ? null : selectedRow.contributionId,
      statusId: statusSelected?.id || null,
      description: description.value
    }
    saveData(newContributionPayment)
    cleanFields()
    close()
  }

  // INPUT
  const handleInputSearchChange = e => {
    let body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: false
    }
    if (e.value) {
      if (activeFilters && Object.prototype.hasOwnProperty.call(activeFilters, 'filterText')) {
        delete activeFilters.filterText
      }
      body = {
        ...body,
        filterText: e.value,
        ...activeFilters
      }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    } else {
      delete activeFilters.filterText
      body = {
        ...body,
        ...activeFilters
      }
    }

    getContributions(body)
  }

  // FILTER
  const filterDate = (type, date) => {
    if (!activeFilters && !date) return
    let body = null
    if (date) {
      body = {
        ...activeFilters,
        [type]: date
      }
      setActiveFilters({
        ...activeFilters,
        [type]: date
      })
    } else {
      delete activeFilters[type]
      body = {
        ...activeFilters
      }
    }
    getContributions(body)
  }
  // TABLE
  const handleDropdownChange = e => setSelectedOption(e.value)

  const handleOrderChange = (nameColumn, typeOrder) => {
    if (nameColumn !== 'Persona' && nameColumn !== 'Concepto') {
      setErrorColumnMessage(true)
      return
    }
    setErrorColumnMessage(false)
    const colNames = nameColumn === 'Persona' ? 'Person' : 'Concept'
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getContributions(body)
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      const newItem = {
        id: item.id,
        code: `C-${item.id}`,
        settlement: `L-${item.settlementId} - ${MONTHS[item.settlementMonth - 1]?.label} - ${item.settlementYear}`,
        personName: item.person,
        concept: item.concept,
        total: currencyFormatUY(item.total),
        dueDate: item.dueDate,
        paymentDate: item.paymentDate,
        totalPaid: currencyFormatUY(item.totalPaid)
      }
      return newItem
    })
    return newData
  }

  const selectInputText = () => {
    const input = document.getElementById('number_amount')
    input.value = ''
    input.focus()
  }

  const handleRowClic = detailObj => {
    setSelectedRow({
      selected: true,
      contributionId: detailObj.id,
      settlement: detailObj.settlement
    })

    const currentAmount = ((detailObj.total === 'N/A' || oldData?.contributionPayment?.amount) && !amount.error)
      ? amount?.value
      : detailObj.total.replace(/[^\d,]/g, '').replace(',', '.')

    setAmount({
      ...amount,
      value: currentAmount
    })
    selectInputText()
  }

  const handleStatusDropdownChange = e => setStatusSelected(e.value)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer={showRowPointer}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getContributions, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    if (depositProfileContributionsData) {
      const { status } = depositProfileContributionsData
      if (status === 200) {
        const { contributionData } = depositProfileContributionsData
        const { items, totalPages, currentPage } = contributionData
        setTablelist(customDataTable(items))
        setColumns(COLUMN_DATA_MODAL)
        setPages(totalPages)
        setItemSelected(currentPage - 1)
        setTotalItems(contributionData.totalItems)
      } else {
        setErrorMessage(MESSAGES.BASIC_ERROR)
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: MESSAGES.BASIC_ERROR,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [depositProfileContributionsData])

  useEffect(() => {
    errorControl()
  }, [amount])

  useEffect(() => {
    filterDate('dueDateFrom', dueDateFrom)
  }, [dueDateFrom])

  useEffect(() => {
    filterDate('dueDateTo', dueDateTo)
  }, [dueDateTo])

  useEffect(() => {
    filterDate('paymentDateFrom', paymentDateFrom)
  }, [paymentDateFrom])

  useEffect(() => {
    filterDate('paymentDateTo', paymentDateTo)
  }, [paymentDateTo])

  useEffect(() => {
    if (personData) {
      const { conceptId, personId } = personData
      const body = {
        concepts: [conceptId],
        personId
      }

      getContributions(body)
      setActiveFilters(body)
    }
  }, [personData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer
        flexDirection='row'
        overflow='auto'
        padding={`0 ${spacing.one}`}
        device={device}
      >
        <TableContainer device={device}>
          <TextContainerModal margin='8px 0'>
            <Text align='left' weight='bold' size='medium' marginBottom='two'>Filtros</Text>
          </TextContainerModal>
          <FilterContainer flexDirection='column'>
            <SearchBar
              onChange={handleInputSearchChange}
              visible
              disabled={!personData}
              value={activeFilters?.filterText}
            />
            <FilterContainer>
              <FilterContainer flexDirection='column' alignItems='strech' flex='1'>
                <TextContainerModal margin='8px 0 0 0'>
                  <Text align='left' weight='bold' size='medium' marginBottom='two'>Fecha vencimiento</Text>
                </TextContainerModal>
                <DatePickerContainer marginRight={spacing.one} device={device}>
                  <CustomDatePicker
                    name='dueDateFrom'
                    title='Desde'
                    color='primary'
                    onChange={setDueDateFrom}
                    yearsToShow={100}
                    justifyContentButton='space-between'
                    block
                    isClearable
                    empty
                    dateType='short'
                    margin='0'
                    disabled={!personData}
                  />
                  <CustomDatePicker
                    name='dueDateTo'
                    title='Hasta'
                    color='primary'
                    onChange={setDueDateTo}
                    yearsToShow={100}
                    justifyContentButton='space-between'
                    block
                    isClearable
                    empty
                    dateType='short'
                    margin='0'
                    disabled={!personData}
                  />
                </DatePickerContainer>
              </FilterContainer>
              <FilterContainer flexDirection='column' alignItems='strech' flex='1'>
                <TextContainerModal margin='8px 0 0 0'>
                  <Text align='left' weight='bold' size='medium' marginBottom='two'>Fecha pago</Text>
                </TextContainerModal>
                <DatePickerContainer marginLeft={spacing.one} device={device}>
                  <CustomDatePicker
                    name='duePaymentFrom'
                    title='Desde'
                    color='primary'
                    onChange={setPaymentDateFrom}
                    yearsToShow={100}
                    justifyContentButton='space-between'
                    block
                    isClearable
                    empty
                    dateType='short'
                    margin='0'
                    disabled={!personData}
                  />
                  <CustomDatePicker
                    name='duePaymentTo'
                    title='Hasta'
                    color='primary'
                    onChange={setPaymentDateTo}
                    yearsToShow={100}
                    justifyContentButton='space-between'
                    block
                    isClearable
                    empty
                    dateType='short'
                    margin='0'
                    disabled={!personData}
                  />
                </DatePickerContainer>
              </FilterContainer>
            </FilterContainer>
          </FilterContainer>
          <FilterContainer>
            <TextContainerModal margin='4px 0'>
              <Text align='left' weight='bold' size='medium'>Contribuciones</Text>
              {errorColumnMessage
                 && (
                 <Text align='center' weight='bold' size='medium' color='error'>
                   Solo se puede ordenar por columna Persona o Concepto
                 </Text>
                 )}
            </TextContainerModal>
          </FilterContainer>
          {personData
            && renderTable({
              currentList: tablelist,
              columnsN: columns,
              currentLoading: depositProfileContributionsLoading,
              handleOrder: handleOrderChange,
              handleSelectedRow: handleRowClic,
              error: !!errorMessage,
              showRowPointer: true
            })}
        </TableContainer>
        <FormContainer device={device}>
          <InputContainer exist={selectedRow.selected}>
            <SearchPersonAndConceptAsync
              action={DEPOSIT_PROFILE_ACTIONS.GET_PERSON_CONCEPTS}
              savePersonData={setPersonData}
              personData={personData}
              close={close}
              setMessageToast={edit ? setMessageToastExternal : setMessageToast}
              currentToken={currentToken}
              edit={edit}
              clearFields={cleanFields}
              setTablelist={setTablelist}
            />
            <ContributionForm inputState={selectedRow} setState={setSelectedRow} />
            <Input
              type={INPUT_TYPES.NUMBER}
              showType={INPUT_TYPES.TEXT}
              id='inputamount'
              name='amount'
              label='Monto'
              placeholder='Ingrese monto'
              color='primary'
              value={amount.value}
              onChange={handleInputChange}
              onBlur={() => {}}
              height='small'
              error={amount.error}
              bits={[{ icon: 'attach_money', position: 'left', colorBit: 'tertiary' }]}
              disabled={!personData}
            />
            {oldData
             && (
             <Dropdown
               name='status'
               label='Estado'
               placeholder='Seleccione'
               options={changeNameForLabel(oldData?.statusesData)}
               value={statusSelected}
               onChange={handleStatusDropdownChange}
               color='primary'
               height='small'
               block
             />
             )}
            <TextInput
              name='description'
              label='Descripción'
              placeholder='Ingrese descripción'
              value={description.value}
              height='small'
              color='primary'
              onChange={handleInputChange}
              bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
              disabled={!personData}
            />
          </InputContainer>
          <ButtonContainer justifyContent='flex-end' modal marginTop={spacing.four}>
            <SolidButton
              size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
              color='primary'
              text='Guardar'
              onClick={handleClick}
              disabled={showButton}
              block={device === BREAKPOINTS.MOBILE}
            />
          </ButtonContainer>
        </FormContainer>
      </ModalDataContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddDepositDetailModal

AddDepositDetailModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  currentToken: PropTypes.object,
  oldData: PropTypes.object,
  edit: PropTypes.bool,
  setMessageToastExternal: PropTypes.func
}
