import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ModalDataContainer, ButtonsContainer } from '@global/styles'
import { FUNCTION_TYPE, BREAKPOINTS, TOAST_PROPERTIES } from '@global/constants'
import { INPUT_TYPES } from '@components/inputs/utils/constants'

import { SolidButton } from '@components/button'
import { TextInput, TextAreaInput, Dropdown } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import { useWindowDimensions } from '@components/windowDimensions'

import { changeNameForLabel } from '@utils/helpers'
import useRedux from '../../redux'
import { BENEFITS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddAndEditBenefit = ({ functionType, oldData, saveData, currentToken, close, setMessageToast, showToast }) => {
  useRedux()
  const { device } = useWindowDimensions()
  const dispatch = useDispatch()

  const [nameBenefit, setNameBenefit] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [costBenefit, setCostBenefit] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [descriptionBenefit, setDescriptionBenefit] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [codeBenefit, setCodeBenefit] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [showButton, setShowButton] = useState(true)
  const [flag, setFlag] = useState(false)
  const [benefitTypes, setBenefitTypes] = useState(null)
  const [selectedOptionTypes, setSelectedOptionTypes] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)

  const benefitTypesData = useSelector(SELECTORS.BENEFIT_TYPES_SUCCESS)
  const benefitTypesError = useSelector(SELECTORS.BENEFIT_TYPES_ERROR)

  const getBenefitTypes = () => {
    const { token } = currentToken
    dispatch(BENEFITS_ACTIONS.GET_BENEFIT_TYPES(token))
  }

  const handleChange = val => {
    const { name } = val
    switch (name) {
      case 'nameBenefit':
        setNameBenefit(val)
        break
      case 'cost':
        setCostBenefit(val)
        break
      case 'codeBenefit':
        setCodeBenefit(val)
        break
      default:
        setDescriptionBenefit(val)
    }
  }

  const handleDropdownChange = val => setSelectedOptionTypes(val.value)

  const clearStates = () => {
    setNameBenefit({
      name: '',
      value: '',
      error: ''
    })
    setCostBenefit({
      name: '',
      value: '',
      error: ''
    })
    setDescriptionBenefit({
      name: '',
      value: '',
      error: ''
    })
    setBenefitTypes(null)
    setCodeBenefit({
      name: '',
      value: '',
      error: ''
    })
  }

  const handleClick = () => {
    const benefit = {
      cost: costBenefit.value,
      name: nameBenefit.value,
      description: descriptionBenefit.value,
      id: oldData?.id,
      frequencyId: selectedOptionTypes?.id,
      code: codeBenefit.value
    }

    saveData(benefit, functionType)
    clearStates()
  }

  const errorControl = () => {
    if (flag) {
      if ((nameBenefit.value && !nameBenefit.error)
          && (costBenefit.value && !costBenefit.error)
          && (descriptionBenefit.value && !descriptionBenefit.error)
          && (codeBenefit.value && !codeBenefit.error)
          && selectedOptionTypes) setShowButton(false)
      else setShowButton(true)
    }
  }

  useEffect(() => {
    getBenefitTypes()
  }, [])

  useEffect(() => {
    if (benefitTypesData) {
      setBenefitTypes(changeNameForLabel(benefitTypesData))
    }
  }, [benefitTypesData])

  useEffect(() => {
    if (benefitTypes) {
      if (functionType === FUNCTION_TYPE.EDIT) {
        setNameBenefit({
          ...nameBenefit,
          value: oldData.name
        })
        setCostBenefit({
          ...costBenefit,
          value: oldData.cost
        })
        setDescriptionBenefit({
          ...descriptionBenefit,
          value: oldData.description
        })
        setCodeBenefit({
          ...codeBenefit,
          value: oldData.code
        })
        setSelectedOptionTypes(benefitTypes.find(bt => bt.id === oldData.frequencyId))
      }
      setFlag(true)
    }
  }, [benefitTypes])

  useEffect(() => {
    if (benefitTypesError) {
      if (!errorFlag) {
        getBenefitTypes()
        setErrorFlag(true)
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: benefitTypesError.message || `Error ${benefitTypesError.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        showToast(true)
        close()
      }
    }
  }, [benefitTypesError])

  useEffect(() => {
    errorControl()
  }, [nameBenefit, descriptionBenefit, costBenefit, selectedOptionTypes, codeBenefit])

  return (
    <>
      {flag
      && (
      <ModalDataContainer flexDirection='column'>
        <TextInput
          name='nameBenefit'
          label='Beneficio'
          placeholder='Ingrese beneficio'
          value={nameBenefit.value}
          height='small'
          color='primary'
          onChange={handleChange}
          bits={[{ icon: 'playlist_add_check', position: 'left', colorBit: 'tertiary' }]}
        />
        <Input
          type={INPUT_TYPES.NUMBER}
          showType={INPUT_TYPES.TEXT}
          label='Costo'
          placeholder='Ingrese costo'
          name='cost'
          value={costBenefit.value}
          error={costBenefit.error}
          height='small'
          color='primary'
          onChange={handleChange}
          onBlur={() => {}}
          bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
        />
        <Dropdown
          name='types'
          label='Frecuencia de pago'
          placeholder='Seleccione'
          options={benefitTypes}
          value={selectedOptionTypes}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        <Input
          type={INPUT_TYPES.NUMBER}
          showType={INPUT_TYPES.TEXT}
          label='Casa comercial'
          placeholder='Ingrese número'
          name='codeBenefit'
          value={codeBenefit.value}
          error={codeBenefit.error}
          height='small'
          color='primary'
          onChange={handleChange}
          onBlur={() => {}}
          bits={[{ icon: 'paid', position: 'left', colorBit: 'tertiary' }]}
        />
        <TextAreaInput
          name='descriptionBenefit'
          label='Descripción'
          placeholder='Ingrese descripción del beneficio'
          size='small'
          color='primary'
          onChange={handleChange}
          value={descriptionBenefit.value}
          resize='none'
        />
        <ButtonsContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            disabled={showButton}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonsContainer>
      </ModalDataContainer>
      )}
    </>
  )
}

export default AddAndEditBenefit

AddAndEditBenefit.propTypes = {
  functionType: PropTypes.string,
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  currentToken: PropTypes.object,
  close: PropTypes.func,
  setMessageToast: PropTypes.func,
  showToast: PropTypes.func
}
