import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { INPUT_TYPES } from '@components/inputs/utils/constants'
import { TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { ModalDataContainer, ButtonContainer } from '@global/styles'

import { SolidButton } from '@components/button'
import { TextInput } from '@components/inputs/inputs'
import Input from '@components/inputs/inputs/baseInput/baseInput'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'

const CreateEmploymentRecord = ({ close, saveData, data }) => {
  useRedux()
  const { Logout } = useUser()
  const { device } = useWindowDimensions()

  const [initialPosition, setInitialPosition] = useState({
    name: '',
    value: '',
    error: ''
  })
  const [billingNumber] = useState(data)
  const [startDate, setStartDate] = useState(new Date())
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if ((initialPosition.value && !initialPosition.error)) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setInitialPosition({
      name: '',
      value: '',
      error: ''
    })
    setStartDate(null)
  }

  const handleInputChange = val => setInitialPosition(val)

  const handleClick = () => {
    const newAffiliateRecord = {
      ...data,
      billingNumber,
      initialPosition: initialPosition.value,
      startDate,
      endDate: null,
      statusId: 1
    }
    saveData(newAffiliateRecord)
    cleanFields()
    close()
  }

  useEffect(() => {
    errorControl()
  }, [initialPosition, startDate])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <Input
          type={INPUT_TYPES.NUMBER}
          showType={INPUT_TYPES.TEXT}
          name='billingNumber'
          label='Número de cobro'
          placeholder='Ingrese número de cobro'
          color='primary'
          value={billingNumber}
          onChange={handleInputChange}
          onBlur={() => {}}
          height='small'
          bits={[{ icon: 'pin', position: 'left', colorBit: 'tertiary' }]}
          disabled
        />
        <TextInput
          label='Cargo'
          placeholder='Ingrese cargo'
          name='initialPosition'
          value={initialPosition.value}
          height='small'
          color='primary'
          onChange={handleInputChange}
          bits={[{ icon: 'engineering', position: 'left', colorBit: 'tertiary' }]}
        />
        <CustomDatePicker
          name='expirationDate'
          title='Fecha de ingreso'
          color='primary'
          onChange={setStartDate}
          yearsToShow={100}
          justifyContentButton='space-between'
          block
        />
      </ModalDataContainer>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default CreateEmploymentRecord

CreateEmploymentRecord.propTypes = {
  close: PropTypes.func,
  data: PropTypes.object,
  saveData: PropTypes.func
}
