import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, bold, medium, large } from '@global/fonts'

export const Wrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
`
export const WrapperBreadcrumbs = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-bottom: ${spacing.three};
`
export const WrapperLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 12;
`

export const DataContainer = styled.div`
  width: 100%;
  background-color: ${colors.background.regular};
  padding: ${spacing.two};
  border-radius: ${spacing.one};
  margin-top: ${spacing.three};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 3;
`

export const UserData = styled.table`
  ${roboto};
  ${regular};
  width: 100%;
  height: auto;
  flex: 2;
`
export const LinkContainer = styled.div`
  height: 100%;   
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  align-self: end;
  flex: 1;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '35%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`

export const Tr = styled.tr`
  width: available;
`

export const MyButtonLink = styled.button`
  background: none!important;
  border: none;
  padding: 0!important;
  ${roboto};
  font-size: 14px;
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: pointer;
  ${({ table }) => (table ? regular : bold)};
  letter-spacing: 0.5px;
  color: ${({ color }) => (color ? colors[color].regular : colors.system.regular)};

  &:hover {
    color: ${colors.primary.hover};
  }
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDirection }) => (flexDirection || 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ alignItems }) => (alignItems || 'flex-start')};
  margin-bottom: ${spacing.two};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }

  & > :last-child {
    width: 100%;
  }
`
