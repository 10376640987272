import styled from 'styled-components'

import { BREAKPOINTS, DEVICE_NAME } from '@global/constants'
import { spacing } from '@global/theme'

export const FormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: ${({ deviceName }) => (deviceName === DEVICE_NAME.MOBILE
  || deviceName === DEVICE_NAME.TABLET_PORTRAIT
    ? '1fr' : '1fr 1fr 2fr')};
  grid-gap: ${spacing.one};
  justify-items: start;
  align-items: end;

  & > :nth-child(2) {
    margin-bottom: -8px;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-end'};
  margin: ${({ device }) => (device <= BREAKPOINTS.TABLET_PORTRAIT ? 0 : `0 0 ${spacing.one} 0`)};
  flex: 3;
  & > :first-child {
    flex: 1;
  }
  & > button {
    flex: 1;
    margin: ${({ device }) => {
    if (device <= BREAKPOINTS.TABLET_PORTRAIT) return `0 ${spacing.one}`
    return `0 0 0 ${spacing.one}`
  }};
  }
`
