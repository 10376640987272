import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyButtonLink,
  BadgeContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  FUNCTION_TYPE,
  BREAKPOINTS
} from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import Modal from '@components/modal'
import Icon from '@components/icon'
import { GhostButton } from '@components/button'
import Badge from '@components/badge'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import CreateAndEditBankAccountsModal from './components/createAndEditBankAccountsModal'
import DeleteConfirmationModal from '../../components/modal/components/deleteConfirmationModal'

import useRedux from './redux'
import { ATAE_BANK_ACCOUNTS_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { DataItem, ButtonContainer } from './styles'

const AtaeBankAccountsManagment = () => {
  useRedux()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [open, setOpen] = useState(false)
  const [bankAccountsModal, setBankAccountsModal] = useState(false)
  const [banksAccounts, setBanksAccounts] = useState()

  const [modalTitle, setModalTitle] = useState(null)
  const [removeItemModal, setRemoveItemModal] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const loadingBanksAccounts = useSelector(SELECTORS.LOADING_BANK_ACCOUNTS)
  const banksAccountsData = useSelector(SELECTORS.ATAE_BANK_ACCOUNTS_SUCCESS)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const handleCloseModal = () => {
    setBankAccountsModal(null)
    setRemoveItemModal(null)
    setModalTitle(null)
    setOpen(false)
  }

  const handleRemoveBankAccount = bankAccountId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.DELETE_ATAE_BANK_ACCOUNT(
        { bankAccountId, token }
      ))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleBankAccountsModal = (type, id) => {
    switch (type) {
      case FUNCTION_TYPE.EDIT:
        setModalTitle('Editar cuenta bancaria')
        setBankAccountsModal({
          oldData: banksAccounts.find(ba => ba.id === id),
          close: handleCloseModal,
          currentToken
        })
        break
      case FUNCTION_TYPE.ADD:
        setModalTitle('Agregar nueva cuenta bancaria')
        setBankAccountsModal({
          close: handleCloseModal,
          currentToken
        })
        break
      default:
        setModalTitle('')
        setRemoveItemModal({
          close: handleCloseModal,
          text: '¿Estás seguro que deseas borrar esta cuenta bancaria?',
          itemId: id,
          removeItem: handleRemoveBankAccount
        })
    }
    setOpen(true)
  }

  const renderBanksAccounts = arrBanksAccounts => (
    arrBanksAccounts.length > 0
      ? (arrBanksAccounts.map(bankAccount => (
        <DataItem
          flexDirection='column'
          padding={spacing.two}
          width='min-content'
          minWidth='200px'
          marginRight
          id={bankAccount.id}
        >
          {bankAccount.isPrimary
           && (
           <BadgeContainer>
             <Badge text='Primaria' backgroundColor='primary' color='background' size='small' />
           </BadgeContainer>
           )}
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Banco: ${bankAccount.bankName || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Cuenta: ${bankAccount.accountNumber || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Divisa: ${bankAccount.currencyName || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Sucursal: ${bankAccount.branch || 'N/A'}`}
          </Text>
          <Text
            size='medium'
            weight='regular'
            align='left'
          >
            {`Descripción: ${bankAccount.description || 'N/A'}`}
          </Text>
          <ButtonContainer
            backgroundColor
            justifyContent='flex-end'
            alignItems='strech'
            marginTop={spacing.one}
            marginRight={spacing.one}
          >
            <Icon
              color='system'
              size='small'
              name='delete_outline'
              onClick={() => handleBankAccountsModal(FUNCTION_TYPE.DELETE, bankAccount.id)}
            />
            <MyButtonLink
              onClick={() => handleBankAccountsModal(FUNCTION_TYPE.EDIT, bankAccount.id)}
              color='primary'
            >
              Editar
            </MyButtonLink>
          </ButtonContainer>
        </DataItem>
      )))
      : (
        <Text size='medium' weight='regular' align='left'>{MESSAGES.NO_BANK_ACCOUNT}</Text>
      )
  )

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.GET_ATAE_BANK_ACCOUNTS(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.CLEAR())
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.GET_ATAE_BANK_ACCOUNTS(token))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (banksAccountsData) {
      setBanksAccounts(banksAccountsData)
      const firstCrumb = {
        link: true,
        text: TRANSLATION_OF_ROUTES.SETTING,
        url: SITE.SETTING
      }
      const lastCrumb = {
        link: true,
        text: TRANSLATION_OF_ROUTES.ATAE_BANK_ACCOUNTS,
        url: ''
      }
      setBreadcrumbs([firstCrumb, lastCrumb])
    }
  }, [banksAccountsData])

  useEffect(() => {
    if (messageInformation) {
      const { status } = messageInformation
      if (status !== 200 || !status) {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      }
      dispatch(ATAE_BANK_ACCOUNTS_ACTIONS.CLEAR_MESSAGE_INFORMATION())
      setIsShowing(true)
    }
  }, [messageInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' marginBottom={spacing.three}>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                Cuentas bancarias ATAE/FRATAE
              </Heading>
            </WrapperHeading>
            <ButtonContainer
              justifyContent='flex-end'
              marginTop={spacing.three}
              marginBottom={spacing.three}
              buttonColor
            >
              <GhostButton
                color='primary'
                size='small'
                icon='add'
                text='Agregar Cuenta bancaria'
                reverse
                onClick={() => handleBankAccountsModal(FUNCTION_TYPE.ADD)}
                block={device < BREAKPOINTS.TABLET_PORTRAIT}
              />
            </ButtonContainer>
            <DataContainer columnStart='1' columnEnd='12' rowStart='5' rowEnd='5'>
              {loadingBanksAccounts
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : banksAccounts && renderBanksAccounts(banksAccounts)}
            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {bankAccountsModal
                && <CreateAndEditBankAccountsModal {...bankAccountsModal} />}
              {removeItemModal
              && (
                <DeleteConfirmationModal
                  {...removeItemModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default AtaeBankAccountsManagment
