import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

import { BREAKPOINTS } from '@global/constants'
import avatar from '@assets/male-avatar.jpg'
import { spacing } from '@global/theme'

import { useUser } from '@components/authentication/utils/hook'
import Icon from '@components/icon'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import { MyNav, MyLink, MenuContainer, MenuItem, LogoContainer, Tooltip, Img, ImgContainer } from './styles'
import { navItems } from './data'

export const Nav = ({ user }, ref) => {
  const { Logout, GetUser } = useUser()
  const userControl = GetUser()

  const { device } = useWindowDimensions()
  const { pathname } = useLocation()

  const [sidebar, setSidebar] = useState(false)

  useImperativeHandle(ref, () => ({
    showNavFromParent: () => setSidebar(true)
  }))

  const closeNav = () => {
    if (device === BREAKPOINTS.MOBILE) setSidebar(false)
  }

  useEffect(() => {
    if (device >= BREAKPOINTS.BIG_SCREEN) setSidebar(true)
    else setSidebar(false)
  }, [device])

  if (!user || !userControl) return null

  return (
    <MyNav device={device} sidebar={sidebar}>
      <LogoContainer device={device}>
        <Text size='large' weight='bold' align='center' color='primary' marginBottom='quarter'>
          ATAE
        </Text>
        {sidebar && device === BREAKPOINTS.MOBILE
         && (
         <Icon
           name='close'
           size='large'
           color='system'
           onClick={() => setSidebar(false)}
         />
         )}
      </LogoContainer>
      <ImgContainer device={device}>
        <Img src={avatar} alt='user' width={spacing.four} height={spacing.four} borderRadius='50%' device={device} />
        { sidebar
          ? <Text size='large' weight='bold' align='center' color='system'>{user?.fullName}</Text>
          : <Tooltip>{user?.fullName}</Tooltip>}
      </ImgContainer>
      <MenuContainer device={device}>
        {navItems.map(({ label, icon, to }) => (
          <MenuItem device={device} isActive={pathname.includes(to)} key={label} onClick={closeNav}>
            <MyLink to={to} device={device}>
              <Icon name={icon} size={device === BREAKPOINTS.DESKTOP ? 'xxlarge' : 'xlarge'} color='system' />
              {sidebar && label}
            </MyLink>
            {!sidebar
              && (<Tooltip>{label}</Tooltip>)}
          </MenuItem>
        ))}
        <MenuItem onClick={() => Logout()} device={device}>
          <Icon name='logout' size='xxlarge' color='system' />
          { sidebar
            ? <Text size='large' weight='bold' align='center' color='system'>Salir</Text>
            : <Tooltip>Salir</Tooltip>}
        </MenuItem>
      </MenuContainer>
    </MyNav>
  )
}

Nav.propTypes = {
  user: PropTypes.object
}
export default forwardRef(Nav)
