import React, { useState, useEffect } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  TOAST_POSITION,
  TRANSLATION_OF_ROUTES,
  FUNCTION_TYPE,
  TOAST_PROPERTIES,
  BREAKPOINTS,
  rowsPerPage
} from '@global/constants'
import {
  TOKEN_HELPERS,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  currencyFormatUY
} from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperHeading,
  PaginationWrapper
} from '@global/styles'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import Icon from '@components/icon'
import Modal from '@components/modal'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import { Heading } from '@components/texts'
import Filter from '@components/filter'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { CURRENCY_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapperHistory } from './styles'
import AddAndEditCurrencyValue from './components/addAndEditCurrencyValue'

const CurrencyProfile = () => {
  useRedux()
  const { pathname, state } = useLocation()
  const { currencyId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device, deviceName } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [currencyProfile, setCurrencyProfile] = useState()
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [removeCurrencyValueModal, setRemoveCurrencyValueModal] = useState(null)
  const [addCurrencyValueModal, setAddCurrencyValueModal] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [activeFilters, setActiveFilters] = useState(null)
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [itemSelected, setItemSelected] = useState()
  const [parameters, setParameters] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const currencyProfileData = useSelector(SELECTORS.CURRENCY_PROFILE_SUCCESS)
  const items = useSelector(SELECTORS.CURRENCY_VALUES_SUCCESS)
  const totalPages = useSelector(SELECTORS.PAGES)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const loadingTableValues = useSelector(SELECTORS.LOADING_TABLE_VALUES)
  const errorMessage = useSelector(SELECTORS.GET_ERROR)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setRemoveCurrencyValueModal(null)
    setAddCurrencyValueModal(null)
  }

  const getProfile = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CURRENCY_PROFILE_ACTIONS.GET_CURRENCY_PROFILE({ currencyId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getCurrencyValues = body => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CURRENCY_PROFILE_ACTIONS.GET_CURRENCY_VALUES({ currencyId, data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const addOrEditCurrencyValue = (newCurrencyValue, functionType) => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      const obj = {
        type: functionType,
        currencyId,
        data: newCurrencyValue,
        currencyValueId: functionType === FUNCTION_TYPE.EDIT ? newCurrencyValue.id : null,
        token,
        body
      }
      dispatch(CURRENCY_PROFILE_ACTIONS.SET_CURRENCY_VALUE(obj))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    handleCloseModal()
  }

  const handleSetCurrencyValue = (currency, functionType) => {
    if (functionType === FUNCTION_TYPE.ADD) {
      setModalTitle('Agregar un valor')
      setAddCurrencyValueModal({
        functionType: FUNCTION_TYPE.ADD,
        oldData: null,
        saveData: addOrEditCurrencyValue
      })
    } else {
      setModalTitle('Editar una valor')
      setAddCurrencyValueModal({
        functionType: FUNCTION_TYPE.EDIT,
        oldData: currency,
        saveData: addOrEditCurrencyValue
      })
    }
    setOpen(true)
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const editIcon = (
        <Icon
          name='edit'
          size='medium'
          color='grays'
          onClick={() => handleSetCurrencyValue(item, FUNCTION_TYPE.EDIT)}
        />
      )

      const newItem = {
        code: item.id,
        date: item.date,
        value: currencyFormatUY(item.value),
        edit: editIcon
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    if (nameColumn === 'Editar') return

    const colNames = columnData.find(col => col.name === nameColumn).label

    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getCurrencyValues(body)
  }

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        notConversion
      />
    )
  }
  // FILTER
  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 18, token }
      dispatch(CURRENCY_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleClickCloseFilters = () => {
    dispatch(CURRENCY_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getCurrencyValues(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getCurrencyValues(body)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getCurrencyValues(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
      setActiveFilters(body)
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }

    getCurrencyValues(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  useEffect(() => {
    getProfile()
    const body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: true
    }
    getCurrencyValues(body)
  }, [])

  useEffect(() => {
    if (items) {
      setTablelist(customBodyDataTable(items))
      setColumns(columnData)
      setPages(totalPages.totalPages)
      setItemSelected(totalPages.currentPage - 1)
      setTotalItems(allItems)
    }
  }, [items, totalPages])

  useEffect(() => {
    if (currencyProfileData) {
      if (state) {
        const lastCrumb = {
          link: true,
          text: TRANSLATION_OF_ROUTES.CURRENCY_PROFILE,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      }
    }
    setCurrencyProfile(currencyProfileData)
    return () => {
      setBreadcrumbs([])
    }
  }, [currencyProfileData])

  useEffect(() => {
    if (items && currencyProfileData) {
      dispatch(CURRENCY_PROFILE_ACTIONS.DATA_READY())
    }
  }, [items, currencyProfileData])

  useEffect(() => {
    if (errorMessage) {
      setIsShowing(true)
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      if (!errorFlag) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(CURRENCY_PROFILE_ACTIONS.GET_CURRENCY_PROFILE({ currencyId, token }))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (messageInformation) {
      if (messageInformation.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
      setTimeout(() => dispatch(CURRENCY_PROFILE_ACTIONS.MESSAGE_INFORMATION_CLEAR()), 5000)
    }
  }, [messageInformation])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              {currencyProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
                 color='system'
               >
                 {currencyProfile?.name}
               </Heading>
               )}
            </WrapperHeading>
            <FilterContainer
              columnStart={1}
              columnEnd={12}
              rowStart={3}
              rowEnd={3}
              deviceName={deviceName}
            >
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                  disabled={tablelist.length === 0}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Valor'
                  icon='add'
                  onClick={() => handleSetCurrencyValue(null, FUNCTION_TYPE.ADD)}
                  block={device === BREAKPOINTS.MOBILE}
                />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapperHistory>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingTableValues,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapperHistory>
            <PaginationWrapper
              rowStart='5'
              rowEnd='5'
              columnStart='1'
              columnEnd='12'
            >
              {tablelist.length > 0
              && (
              <PaginationGroup
                deviceName={deviceName}
                pages={pages}
                itemSelected={itemSelected}
                handleClickPaginationItem={n => handleClickPaginationItem(
                  n, activeFilters, selectedOption.value, getCurrencyValues, setItemSelected
                )}
                options={rowsPerPage}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
                totalItems={totalItems}
                itemsPerPage={tablelist.length}
              />
              )}
            </PaginationWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {removeCurrencyValueModal
              && (
                <DeleteConfirmationModal
                  {...removeCurrencyValueModal}
                />
              )}
              {addCurrencyValueModal
              && (
                <AddAndEditCurrencyValue
                  {...addCurrencyValueModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default CurrencyProfile
