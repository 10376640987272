import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectContributionPaymentList = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectContributionPaymentList, state => get(state, 'loading', null)
)

const selectDetailProfileSuccess = createSelector(
  selectContributionPaymentList, state => get(state, 'detailProfile', null)
)

const selectDetailProfileError = createSelector(
  selectContributionPaymentList, state => get(state, 'error', null)
)

const selectDetailsLoading = createSelector(
  selectContributionPaymentList, state => get(state, 'detailsLoading', null)
)

const selectDetailsSuccess = createSelector(
  selectContributionPaymentList, state => get(state, 'details', null)
)

const selectDetailsError = createSelector(
  selectContributionPaymentList, state => get(state, 'detailsError', null)
)

const selectColumns = createSelector(
  selectContributionPaymentList, state => get(state, 'columns', null)
)

const selectPages = createSelector(
  selectContributionPaymentList, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectContributionPaymentList, state => get(state, 'totalItems', null)
)

const selectDataFilters = createSelector(
  selectContributionPaymentList, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectContributionPaymentList, state => get(state, 'dataFiltersError', [])
)

const selectInboundPaymentsOrigins = createSelector(
  selectContributionPaymentList, state => get(state, 'inboundPaymentsOrigins', [])
)

export default {
  LOADING: selectLoading,
  DETAIL_LIST_PROFILE_SUCCESS: selectDetailProfileSuccess,
  DETAIL_LIST_PROFILE_ERROR: selectDetailProfileError,

  DETAILS_LOADING: selectDetailsLoading,
  DETAILS_SUCCESS: selectDetailsSuccess,
  DETAILS_ERROR: selectDetailsError,
  COLUMNS: selectColumns,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,

  FILTER_OBJECT: selectDataFilters,
  FILTER_OBJECT_ERROR: selectDataFiltersError,

  ORIGINS: selectInboundPaymentsOrigins
}
