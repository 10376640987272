import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import { colors, spacing } from '@global/theme'
import { roboto, bold, large, medium, regular } from '@global/fonts'
import { MyButtonLink } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

export const WraperBreadcrumbs = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 1;
  margin-bottom: ${spacing.three};
`
export const WrapperLoading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-start: 1;
  grid-column-end: 12;
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  align-items: baseline;
  flex: ${({ flex }) => flex || 0};
  grid-column-start: 1;
  grid-column-end: 9; 
  grid-row-start: 2;
  grid-row-end: 2;

  & > p {
    margin-right: 16px;
  }
`
export const BillingNumberContainer = styled.div`
  width: 100%;
  margin-bottom: ${spacing.three};

  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex: 2;

  & > :first-child {
    color: #4F4F4F;
  }
  
  & > :last-child {
    margin-left: 8px !important;
  }
`

export const MyLink = styled(NavLink)`
  ${roboto};
  text-decoration: ${({ textDecoration }) => textDecoration || 'none'};
  font-size: 14px;
  ${bold};
  letter-spacing: 0.5px;
  color: ${({ color }) => (color ? colors[color].regular : colors.system.regular)};
`

export const BenefitsContainer = styled.div`
  width: 100%;
  max-height: 360px;
  background-color: ${colors.background.regular};
  border-radius: ${spacing.one};
  padding: ${spacing.three} ${spacing.two};
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-self: stretch;
  grid-column-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 1 : 9)};
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 5 : 3)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 5 : 3)};
`

export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  margin-right: ${spacing.half};
`

export const DataContainer = styled.div`
  ${roboto};
  width: 100%;
  height: 100%;
  min-height: 260px;
  background-color: ${colors.background.regular};
  padding: ${spacing.two};
  border-radius: ${spacing.one};
  margin-bottom: ${spacing.three};
  
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 'column' : 'row')};
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 2px 2px 2px 2px rgba(108, 108, 108, 0.17);
  grid-column-start: 1;
  grid-column-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 12 : 9)};
  grid-row-start: 3;
  grid-row-end: 3;
`

export const BenefitsTopContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${spacing.two};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Tbody = styled.tbody`
  width: 100%;
  height: fill;
  flex: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? 2 : 1)};
`

export const Tr = styled.tr`
  width: available;
`

export const Td = styled.td` 
  width: ${({ widthPerc }) => (widthPerc ? '35%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};

`

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: ${spacing.oneAndAHalf} 0;
  flex: 2;
  overflow: auto;
`

export const BenefitItem = styled.div`
  width: 95%;
  background-color: ${colors.background.secondary};
  margin-bottom: ${spacing.oneAndAHalf};
  border-radius: 8px;
  border: 1px solid ${colors.background.primary};
  padding: ${spacing.oneAndAHalf};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    &:hover {
      color: ${colors.error.regular};
    }
  }
`
export const TextContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 6 : 4)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 6 : 4)};
  margin-top: ${spacing.three};
`

export const TextContainerCP = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 9 : 7)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 9 : 7)};
  margin-top: ${spacing.three};
`

export const TableContainerAffRec = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 8 : 6)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 8 : 6)};
`

export const TableContainerAffRecCP = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: ${({ device }) => (device < BREAKPOINTS.TABLET ? 11 : 9)};
  grid-row-end: ${({ device }) => (device < BREAKPOINTS.TABLET ? 11 : 9)};
`

export const TextErrorContainer = styled.div`
  margin: ${spacing.two} ${spacing.half};
`

export const LinkContainer = styled.div`
  height: 100%;
  width: ${({ device }) => (device < BREAKPOINTS.TABLET_PORTRAIT ? '100%' : null)};  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  flex: 1;

  & > :last-child {
    align-self: end;
  }
`
