import styled from 'styled-components'

import { colors, spacing } from '@global/theme'

import { Label, Message } from '@components/inputs/inputs/styles'

export const Wrapper = styled.div`
  margin-right: auto;
`

export const Container = styled.div`
  display: grid;
  grid-template-areas: 'checkbox';

  > * {
    grid-area: checkbox;
  }
`

export const Check = styled.div`
  position: relative;

  width: ${spacing.twoAndAQuarter};
  height: ${spacing.twoAndAQuarter};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${spacing.half};
  border: 1px solid ${({ checked, color, disabled }) => {
    if (checked) return colors[color]?.regular
    if (disabled) return colors[color]?.disabled
  }
};
  background-color:${({ checked, color }) => (checked && colors[color]?.regular)};
  
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:active{
    outline: ${spacing.quarter} solid ${({ color, disabled }) => {
  if (disabled) return colors.background.regular
  return colors[color]?.regular
}
};
    outline-offset: ${spacing.quarter};
  }

  transition: background-color .2s;
`

export const StyledCheckbox = styled.input`
  width: ${spacing.two};
  height: ${spacing.two};
  margin: 0;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const MyLabel = styled(Label)`
  display: flex;
  align-items: center;

  cursor: pointer;

  gap: ${spacing.oneAndAHalf};
  
  margin: 0;
  
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`

export const MyMessage = styled(Message)`
  margin-left: ${spacing.threeAndAHalf};
  margin-top: ${spacing.one};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`
