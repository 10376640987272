import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FUNCTION_TYPE } from '@global/constants'

import { AFFILIATE_RECORD_ACTIONS } from './actions'
import { AFFILIATE_RECORD } from './types'
import { AFFILIATE_RECORD_MODAL_TYPES } from '../data'

const apiCalls = {
  getPersonData: ({ personId, token }) => (
    Axios(`Person/${personId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordsBenefits: ({ recordId, token }) => (
    Axios(`AffiliateRecord/${recordId}/benefit`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordStatus: token => (
    Axios('AffiliateRecord/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordCategories: token => (
    Axios('AffiliateRecord/categories',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordsContributions: ({ recordId, data, token }) => (
    Axios.post(`AffiliateRecord/${recordId}/contribution`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecord: ({ recordId, token }) => (
    Axios(`AffiliateRecord/${recordId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ recordId, data, token }) => (
    Axios.post(`AffiliateRecord/${recordId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAffiliateRecordsConPay: ({ data, token }) => (
    Axios.post('InboundPayment/contributionpayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getInboundPaymentsOrigins: token => (
    Axios.get('InboundPayment/origins',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  benefitsCalls: {
    getAllBenefits: token => (
      Axios.post('Benefit/filter', { pageSize: 50, PageNumber: 1 },
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    addBenefit: ({ recordId, benefitId, token }) => (
      Axios.post(`AffiliateRecord/${recordId}/benefit/add?benefitId=${benefitId}`, { benefitId },
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        })),
    removeBenefit: ({ recordId, benefitId, token }) => (
      Axios.delete(`AffiliateRecord/${recordId}/benefit/${benefitId}/remove`,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  }
}

export function* getAffiliateRecordData ({ payload }) {
  try {
    const { data: affiliateRecord } = yield call(apiCalls.getAffiliateRecord, payload)

    yield put(AFFILIATE_RECORD_ACTIONS.GET_AFFILIATE_RECORD_SUCCESS(affiliateRecord))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.GET_AFFILIATE_RECORD_ERROR(error))
  }
}

export function* getPersonInfo ({ payload }) {
  try {
    const { personId, recordId, token } = payload

    const { data: person } = yield call(apiCalls.getPersonData, { personId, token })
    const { data: benefits } = yield call(apiCalls.getAffiliateRecordsBenefits, { recordId, token })

    const personDataObj = {
      person,
      benefits
    }
    yield put(AFFILIATE_RECORD_ACTIONS.GET_PERSON_DATA_SUCCESS(personDataObj))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.GET_PERSON_DATA_ERROR(error))
  }
}

export function* getContributions ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getAffiliateRecordsContributions, payload)

    yield put(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTIONS_SUCCESS(data))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTIONS_ERROR(error))
  }
}

export function* filterData ({ payload }) {
  try {
    const { type } = payload
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = {
      parametersData: res.data.parameters,
      type
    }
    yield put(AFFILIATE_RECORD_ACTIONS.FILTER_DATA_SUCCESS(filtersObj))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* modalData ({ payload }) {
  try {
    const { type, token } = payload
    let objData = null
    if (type === AFFILIATE_RECORD_MODAL_TYPES.BENEFITS) {
      const { benefitsCalls } = apiCalls
      const { data } = yield call(benefitsCalls.getAllBenefits, token)
      const benefits = data.items
      objData = {
        status: 200,
        benefits
      }
    } else {
      const { data: statuses } = yield call(apiCalls.getAffiliateRecordStatus, token)
      const { data: categories } = yield call(apiCalls.getAffiliateRecordCategories, token)
      objData = {
        status: 200,
        statuses,
        categories
      }
    }

    yield put(AFFILIATE_RECORD_ACTIONS.MODAL_DATA_RESPONSE(objData))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.MODAL_DATA_RESPONSE(error))
  }
}

export function* setBenefit ({ payload }) {
  try {
    const { benefitsCalls } = apiCalls
    const { functionType, recordId, benefitId, token } = payload

    const { status } = functionType === FUNCTION_TYPE.ADD
      ? yield call(benefitsCalls.addBenefit, { recordId, benefitId, token })
      : yield call(benefitsCalls.removeBenefit, { recordId, benefitId, token })

    const { data: benefitsUpdated } = yield call(apiCalls.getAffiliateRecordsBenefits, { recordId, token })

    yield put(AFFILIATE_RECORD_ACTIONS.UPDATE_BENEFITS(benefitsUpdated))
    yield put(AFFILIATE_RECORD_ACTIONS.BENEFITS_RESPONSE(status))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.BENEFITS_RESPONSE(error))
  }
}

export function* editProfile ({ payload }) {
  try {
    const { recordId, data, token } = payload
    const { status } = yield call(apiCalls.editProfile, { recordId, data, token })
    const { data: affiliateRecord } = yield call(apiCalls.getAffiliateRecord, { recordId, token })

    yield put(AFFILIATE_RECORD_ACTIONS.UPDATE_PROFILE(affiliateRecord))
    yield put(AFFILIATE_RECORD_ACTIONS.EDIT_PROFILE_RESPONSE(status))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.EDIT_PROFILE_RESPONSE(error))
  }
}

export function* getContributionPayments ({ payload }) {
  try {
    const { token } = payload
    const { data: contributionPaymentsData } = yield call(apiCalls.getAffiliateRecordsConPay, payload)
    const { data: originData } = yield call(apiCalls.getInboundPaymentsOrigins, token)

    yield put(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS_SUCCESS({ contributionPaymentsData, originData }))
  } catch (error) {
    yield put(AFFILIATE_RECORD_ACTIONS.GET_CONTRIBUTION_PAYMENTS_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(AFFILIATE_RECORD.GET_AFFILIATE_RECORD, getAffiliateRecordData)
  yield takeLatest(AFFILIATE_RECORD.GET_CONTRIBUTIONS, getContributions)
  yield takeLatest(AFFILIATE_RECORD.SEND_FILTER, getContributions)
  yield takeLatest(AFFILIATE_RECORD.GET_FILTER_DATA, filterData)
  yield takeLatest(AFFILIATE_RECORD.GET_MODAL_DATA, modalData)
  yield takeLatest(AFFILIATE_RECORD.SET_BENEFIT, setBenefit)
  yield takeLatest(AFFILIATE_RECORD.EDIT_PROFILE, editProfile)
  yield takeLatest(AFFILIATE_RECORD.GET_PERSON_DATA, getPersonInfo)
  yield takeLatest(AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS, getContributionPayments)
}
