import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BREAKPOINTS } from '@global/constants'
import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import { useWindowDimensions } from '@components/windowDimensions'
import { Heading, Text } from '@components/texts'
import Loading from '@components/loading'

import { USER_ACTIONS } from '../../redux/action'
import useRedux from '../../redux'
import SELECTORS from '../../redux/selectors'

import { Wrapper, Header, Body, Footer, StyledNavLink, LoadingContainer, Container } from '../../styles'
import Form from './form'

const Login = () => {
  useRedux()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    error: ''
  })
  const [messageCredentials, setMessageCredentials] = useState(false)
  const [errorCredentials, setErrorCredentials] = useState('')

  const loading = useSelector(SELECTORS.LOADING)
  const user = useSelector(SELECTORS.SUCCESS)
  const errorRequest = useSelector(SELECTORS.ERROR)

  const handleInputChange = ({ name, value, error }) => setCredentials({
    ...credentials,
    [name]: value,
    error: name === 'email' ? error : ''
  })

  const handleBlur = () => (credentials.error ? setErrorCredentials(credentials.error) : setErrorCredentials(''))

  const onSubmit = () => {
    if (!credentials.email || !credentials.password) {
      setTimeout(() => setMessageCredentials(''), 2500)
      return setMessageCredentials(MESSAGES.FIELD_EMPTY)
    }
    if (credentials.error) {
      setTimeout(() => setMessageCredentials(''), 2500)
      return setMessageCredentials(MESSAGES.BASIC_ERROR)
    }

    dispatch(USER_ACTIONS.GET(credentials))
  }

  const handleKeyDown = e => {
    if (e.keyCode === 10 || e.keyCode === 13) onSubmit()
  }

  useEffect(() => {
    if (user) history.push(SITE.HOME)
    if (errorRequest) {
      setMessageCredentials(MESSAGES.INVALID_CREDENTIALS)
      dispatch(USER_ACTIONS.CLEAR())
    }
    setTimeout(() => {
      setMessageCredentials('')
    }, 4500)
  }, [loading, user])

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <Loading color='primary' text='Home' size='xxlarge' weight='bold' />
        </LoadingContainer>
      )
        : (
          <Wrapper device={device} mobileHeight='540px' desktopHeight='460px' mobileWidth='310px' desktopWidth='430px'>
            <Header device={device} width='80%'>
              <Heading
                size={device === BREAKPOINTS.MOBILE ? 'medium' : 'xlarge'}
                type='h2'
                marginBottom={device === BREAKPOINTS.MOBILE ? 'half' : 'one'}
                weight='bold'
                color='primary'
                align='center'
              >
                Bienvenido a A.T.A.E.
              </Heading>
            </Header>
            <Body device={device} paddingTopBot={spacing.one} paddingLefRig={spacing.six}>
              <Form
                value={credentials}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onSubmit={onSubmit}
                error={errorCredentials}
                device={device}
                onKeyDown={handleKeyDown}
              />
            </Body>
            <Footer>
              { messageCredentials
            && (
            <Text
              size={device === BREAKPOINTS.MOBILE ? 'medium' : 'large'}
              weight='bold'
              color='error'
            >
              {messageCredentials.toUpperCase()}
            </Text>
            )}
              <StyledNavLink to={SITE.PASSWORD_RECOVERY}>¿Olvidaste tu contrase&ntilde;a?</StyledNavLink>
            </Footer>
          </Wrapper>
        )}

    </Container>
  )
}

export default Login
