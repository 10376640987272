import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto } from '@global/fonts'

export const StyledTextArea = styled.textarea`
  ${roboto};
  width: 100%;
  outline: 1px solid ${colors.contrast.disabled};
  
  border-radius: ${spacing.threeQuarters};
  border-style: none;
  box-shadow: 0 1px ${spacing.quarter} 0 rgb(0 0 0 / 0.05);
  padding: ${spacing.one};
  resize: ${({ resize }) => resize || 'vertical'};
  min-height: 100px;

  &:focus {
  outline: ${spacing.quarter} solid ${({ color, error }) => {
  if (error) return colors.error.regular
  if (color) return colors[color]?.regular
  return colors.system.regular
}
};
    outline-offset: 1px;
  }
`

export const TextContainer = styled.div`
  margin-left: auto;
  padding-right: ${spacing.half};
  color: ${({ color }) => (color && colors[color]?.regular)}
`
