import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { TOAST_POSITION, TRANSLATION_OF_ROUTES, FUNCTION_TYPE, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import {
  WrapperBreadcrumbs,
  WrapperLoading,
  WrapperHeading
} from '@global/styles'
import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Table from '@components/table'
import { GhostButton } from '@components/button'
import Toast from '@components/toast'
import Icon from '@components/icon'
import Modal from '@components/modal'
import DeleteConfirmationModal from '@components/modal/components/deleteConfirmationModal'
import { Heading } from '@components/texts'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { FRM_CATEGORIES_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import { columnData } from './data'
import { TableWrapperHistory, FilterContainer } from './styles'
import AddAndEditFrmCategory from './components/addAndEditFrmCategory'

const FrmCategories = () => {
  useRedux()
  const { key, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [removeFrmCategoryModal, setRemoveFrmCategoryModal] = useState(null)
  const [dataFrmCategoryModal, setDataFrmCategoryModal] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const loadingList = useSelector(SELECTORS.LOADING_LIST)
  const items = useSelector(SELECTORS.SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const frmCategoryInformation = useSelector(SELECTORS.SET_FRM_CATEGORY_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const customHeaderDataTable = colData => {
    const deleteIcon = (<Icon name='delete' backgroundColor='primary' size='medium' color='grays' />)
    const orderedColumns = Object.values(colData).map(item => {
      if (item.name === 'Eliminar') {
        let obj = item
        obj = {
          ...obj,
          name: deleteIcon
        }
        return obj
      }
      return item
    })
    return orderedColumns
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setRemoveFrmCategoryModal(null)
    setDataFrmCategoryModal(null)
  }

  const removeFrmCategory = frmCategoryId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_CATEGORIES_ACTIONS.SET_FRM_CATEGORY({
        type: FUNCTION_TYPE.DELETE, frmCategoryId, token }))
      handleCloseModal()
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const addOrEditFrmCategory = (frmCategory, functionType) => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const obj = {
        type: functionType,
        frmCategoryId: frmCategory.id,
        data: frmCategory,
        token
      }
      dispatch(FRM_CATEGORIES_ACTIONS.SET_FRM_CATEGORY(obj))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    handleCloseModal()
  }

  const handleSetFrmCategory = (frmCategory, functionType) => {
    switch (functionType) {
      case FUNCTION_TYPE.ADD: {
        setModalTitle('Agregar un categoría de FRM')
        setDataFrmCategoryModal({
          functionType: FUNCTION_TYPE.ADD,
          oldData: null,
          saveData: addOrEditFrmCategory
        })
        break
      }
      case FUNCTION_TYPE.DELETE: {
        const text = '¿Estás seguro que deseas borrar esta categoría?'
        setModalTitle('Eliminar categoría')
        setRemoveFrmCategoryModal({
          close: handleCloseModal,
          text,
          itemId: frmCategory.id,
          removeItem: removeFrmCategory,
          dataType: functionType
        })
        break
      }
      default: {
        setModalTitle('Editar una categoría')
        setDataFrmCategoryModal({
          functionType: FUNCTION_TYPE.EDIT,
          oldData: frmCategory,
          saveData: addOrEditFrmCategory
        })
        break
      }
    }
    setOpen(true)
  }

  const customBodyDataTable = bodyData => {
    const newData = bodyData.map(item => {
      const deleteIcon = (
        <Icon
          name='cancel'
          size='medium'
          color='grays'
          onClick={() => handleSetFrmCategory(item, FUNCTION_TYPE.DELETE)}
        />
      )
      const editIcon = (
        <Icon name='edit' size='medium' color='grays' onClick={() => handleSetFrmCategory(item, FUNCTION_TYPE.EDIT)} />
      )

      const newItem = {
        id: item.id,
        deleteFile: deleteIcon,
        name: item.name,
        description: item.description,
        contributionPerc: `%${item.contributionPerc}`,
        fromAge: `${item.fromAge}`,
        toAge: item.toAge,
        edit: editIcon
      }
      return newItem
    })
    return newData
  }

  const handleOrderChange = () => {}

  const handleToastClose = () => setIsShowing(false)

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      />
    )
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(FRM_CATEGORIES_ACTIONS.GET_FRM_CATEGORIES(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)

    const fisrtCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.SETTING,
      url: SITE.SETTING
    }
    const secondCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.ENTITY_MANAGMENT,
      url: `${SITE.SETTING}${SITE.ENTITY_MANAGMENT}`
    }
    const thirdCrumb = {
      link: true,
      text: TRANSLATION_OF_ROUTES.FRM_CATEGORIES,
      url: pathname
    }
    setBreadcrumbs([fisrtCrumb, secondCrumb, thirdCrumb])

    return () => {
      setBreadcrumbs([])
    }
  }, [key])

  useEffect(() => {
    if (items.length > 0) {
      setColumns(customHeaderDataTable(columnData))
      setTablelist(customBodyDataTable(items))
    }
  }, [items])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(FRM_CATEGORIES_ACTIONS.GET_FRM_CATEGORIES(token))
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (frmCategoryInformation) {
      if (frmCategoryInformation === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: frmCategoryInformation.message || `Error ${frmCategoryInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
      setTimeout(() => dispatch(FRM_CATEGORIES_ACTIONS.FRM_CATEGORY_INFORMATION_CLEAR()), 5000)
    }
  }, [frmCategoryInformation])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              <Heading
                type='h2'
                weight='bold'
                align='left'
                color='system'
              >
                {TRANSLATION_OF_ROUTES.FRM_CATEGORIES}
              </Heading>
            </WrapperHeading>

            <FilterContainer columnStart={1} columnEnd={12} rowStart={3} rowEnd={3}>
              <GhostButton
                color='tertiary'
                size='small'
                text='Agregar Categoría'
                icon='add'
                onClick={() => handleSetFrmCategory(null, FUNCTION_TYPE.ADD)}
                block={device === BREAKPOINTS.MOBILE}
              />
            </FilterContainer>
            <TableWrapperHistory>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: loadingList,
                handleOrder: handleOrderChange,
                error: errorMessage
              })}
            </TableWrapperHistory>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
            >
              {removeFrmCategoryModal
              && (
                <DeleteConfirmationModal
                  {...removeFrmCategoryModal}
                />
              )}
              {dataFrmCategoryModal
              && (
                <AddAndEditFrmCategory
                  {...dataFrmCategoryModal}
                />
              )}
            </Modal>
          </>
        )}
    </>
  )
}

export default FrmCategories
