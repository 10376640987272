import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { USER_PROFILE_ACTIONS } from './actions'
import { USER_PROFILE } from './types'

const apiCalls = {
  getUserProfile: ({ userId, token }) => (
    Axios(`Account/${userId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  setPassword: ({ userId, data, token }) => (
    Axios.post(`Account/${userId}/setpassword`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editProfile: ({ userId, data, token }) => (
    Axios.post(`Account/${userId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getUsersStatuses: token => (
    Axios('AffiliateRecord/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getUserData ({ payload }) {
  try {
    const { token } = payload
    const { data: userProfile } = yield call(apiCalls.getUserProfile, payload)
    let { data: statuses } = yield call(apiCalls.getUsersStatuses, token)
    statuses = statuses.filter(s => s.id !== 3)
    const dataObj = {
      userProfile,
      statuses
    }
    yield put(USER_PROFILE_ACTIONS.GET_USER_SUCCESS(dataObj))
  } catch (error) {
    yield put(USER_PROFILE_ACTIONS.GET_USER_ERROR(error))
  }
}

export function* setUserPassword ({ payload }) {
  try {
    const res = yield call(apiCalls.setPassword, payload)
    yield put(USER_PROFILE_ACTIONS.SET_PASSWORD_INFORMATION(res))
  } catch (error) {
    yield put(USER_PROFILE_ACTIONS.SET_PASSWORD_INFORMATION(error))
  }
}

export function* editUserProfile ({ payload }) {
  try {
    const { userId, token } = payload
    const { status } = yield call(apiCalls.editProfile, payload)
    const { data: userProfileUpdated } = yield call(apiCalls.getUserProfile, { userId, token })

    yield put(USER_PROFILE_ACTIONS.SET_PROFILE_INFORMATION({ status, data: userProfileUpdated }))
  } catch (error) {
    yield put(USER_PROFILE_ACTIONS.SET_PROFILE_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(USER_PROFILE.GET_USER, getUserData)
  yield takeLatest(USER_PROFILE.SET_PASSWORD, setUserPassword)
  yield takeLatest(USER_PROFILE.EDIT_USER_PROFILE, editUserProfile)
}
