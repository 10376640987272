import { HISTORY } from './types'

export const getHistory = payload => ({
  type: HISTORY.GET_HISTORY,
  payload
})

export const getHistorySuccess = payload => ({
  type: HISTORY.SUCCESS,
  payload
})

export const getHistoryError = payload => ({
  type: HISTORY.ERROR,
  payload
})

export const ClearList = payload => ({
  type: HISTORY.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: HISTORY.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: HISTORY.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: HISTORY.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: HISTORY.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: HISTORY.FILTER_DATA_CLEAR
})

export const HISTORY_ACTIONS = {
  GET_HISTORY: getHistory,
  SUCCESS: getHistorySuccess,
  ERROR: getHistoryError,
  CLEAR: ClearList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData
}
