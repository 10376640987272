import React from 'react'
import PropTypes from 'prop-types'
import MyPropTyes from '@global/propTypes'

import { FILTER_TYPES } from '@global/constants'
import { formatDateBasic } from '@utils/helpers'

import Badge from '@components/badge'
import { BadgeContainer, DivBadge } from './styles'

const BadgeGroup = ({ badges, handleCloseBadge }) => {
  const renderBadges = arrBadges => (
    arrBadges.map((badge, index) => {
      let itemsSelected = ''
      switch (badge.type) {
        case FILTER_TYPES.CHECKBOX:
          itemsSelected = badge.items.length > 1
            ? badge.items.reduce((item, selected) => `${item.name || item} - ${selected.name || selected}`)
            : badge.items[0].name
          break
        case FILTER_TYPES.DATE_PICKER:
          itemsSelected = badge.items
            .reduce((item, selected) => `${formatDateBasic(item.value, 'short') || item} 
            - ${formatDateBasic(selected.value, 'short') || selected}`)
          break
        default: {
          const value = badge.items[0]
          itemsSelected = value
        }
      }
      return (
        <DivBadge key={index}>
          <Badge
            id={index}
            text={`${badge.text} ${itemsSelected}`}
            icon='close'
            color='primary'
            backgroundColor='secondary'
            size='small'
            type='solid'
            reverse
            onClick={() => handleCloseBadge(badge.name)}
          />
        </DivBadge>
      )
    })
  )

  return (
    <BadgeContainer>
      {badges.length > 0 && renderBadges(badges)}
    </BadgeContainer>

  )
}

BadgeGroup.propTypes = {
  badges: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      size: PropTypes.string,
      color: MyPropTyes.color,
      backgroundColor: MyPropTyes.color,
      onClick: PropTypes.func
    })
  ),
  handleCloseBadge: PropTypes.func
}

export default BadgeGroup
