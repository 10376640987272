export const columnData = {
  delete: { name: 'Borrar', widthCol: '5%' },
  date: { name: 'Fecha y Hora', widthCol: '20%' },
  name: { name: 'Nombre de archivo', widthCol: '25%' },
  description: { name: 'Descripción', widthCol: '35%' },
  download: { name: 'Descargar', widthCol: '10%' }
}

export const TYPES_PERSON_DATA = {
  ADDRESS: 'address',
  PHONE: 'phones',
  NOTE: 'notes',
  EMAIL: 'emails',
  BANK_ACCOUNT: 'bankAccount',
  PROFILE: 'profile'
}

export const SEX = [
  { id: 1, name: 'male', label: 'Masculino' },
  { id: 2, name: 'female', label: 'Femenino' }
]

export const typeAccept = ['.xlsx', '.docx', '.pdf', '.jpeg', '.png', '.txt']

export const COLUMN_CONTRIBUTION_PAYMENTS = [
  { name: 'Código', widthCol: '15%', label: 'Code' },
  { name: 'Concepto', widthCol: '20%', label: 'Concept' },
  { name: 'Origen', widthCol: '15%', label: 'OriginName' },
  { name: 'Divisa', widthCol: '15%', label: 'Currency' },
  { name: 'Monto', widthCol: '15%', label: 'Amount' },
  { name: 'Estado', widthCol: '15%', label: 'Status' },
  { name: 'Mes', widthCol: '15%', label: 'Month' },
  { name: 'Año', widthCol: '15%', label: 'Year' }
]
