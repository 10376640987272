import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Liquidations from '@containers/liquidations'
import LiquidationProfile from '@containers/liquidationProfile'
import ContributionProfile from '@containers/contributionProfile'

export const LiquidationsRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={Liquidations} />
      <PrivateRoute
        exact
        path={`${url}${SITE.LIQUIDATION_LOG}/:liquidationId`}
        component={LiquidationProfile}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.LIQUIDATION_LOG}/:liquidationId${SITE.CONTRIBUTION_PROFILE}/:contributionProfileId`}
        component={ContributionProfile}
      />
    </Switch>
  )
}
