import { PERSON } from './types'

export const getPerson = payload => ({
  type: PERSON.GET_PERSON,
  payload
})

export const getPersonSuccess = payload => ({
  type: PERSON.GET_PERSON_SUCCESS,
  payload
})

export const getPersonError = payload => ({
  type: PERSON.GET_PERSON_ERROR,
  payload
})

export const personClear = payload => ({
  type: PERSON.CLEAR,
  payload
})

export const updateAddresses = payload => ({
  type: PERSON.UPDATE_ADDRESSES,
  payload
})

export const addAddress = payload => ({
  type: PERSON.ADD_ADDRESS,
  payload
})

export const editAddress = payload => ({
  type: PERSON.EDIT_ADDRESS,
  payload
})

export const removeAddress = payload => ({
  type: PERSON.REMOVE_ADDRESS,
  payload
})

export const updatePhones = payload => ({
  type: PERSON.UPDATE_PHONES,
  payload
})

export const addPhone = payload => ({
  type: PERSON.ADD_PHONE,
  payload
})

export const editPhone = payload => ({
  type: PERSON.EDIT_PHONE,
  payload
})

export const removePhone = payload => ({
  type: PERSON.REMOVE_PHONE,
  payload
})

export const updateNotes = payload => ({
  type: PERSON.UPDATE_NOTES,
  payload
})

export const addNote = payload => ({
  type: PERSON.ADD_NOTE,
  payload
})

export const editNote = payload => ({
  type: PERSON.EDIT_NOTE,
  payload
})

export const removeNote = payload => ({
  type: PERSON.REMOVE_NOTE,
  payload
})

export const updateEmails = payload => ({
  type: PERSON.UPDATE_EMAILS,
  payload
})

export const addEmail = payload => ({
  type: PERSON.ADD_EMAIL,
  payload
})

export const editEmail = payload => ({
  type: PERSON.EDIT_EMAIL,
  payload
})

export const removeEmail = payload => ({
  type: PERSON.REMOVE_EMAIL,
  payload
})

export const updateBanksAccounts = payload => ({
  type: PERSON.UPDATE_BANKS_ACCOUNTS,
  payload
})

export const addBankAccount = payload => ({
  type: PERSON.ADD_BANK_ACCOUNT,
  payload
})

export const editBankAccount = payload => ({
  type: PERSON.EDIT_BANK_ACCOUNT,
  payload
})

export const removeBankAccount = payload => ({
  type: PERSON.REMOVE_BANK_ACCOUNT,
  payload
})

export const informationMessage = payload => ({
  type: PERSON.INFORMATION_MESSAGE,
  payload
})

export const getTypes = payload => ({
  type: PERSON.GET_TYPES,
  payload
})

export const getTypesSuccess = payload => ({
  type: PERSON.GET_TYPES_SUCCESS,
  payload
})

export const getTypesError = payload => ({
  type: PERSON.GET_TYPES_ERROR,
  payload
})

export const getDepartments = payload => ({
  type: PERSON.GET_DEPARTMENTS,
  payload
})

export const getDepartmentsSuccess = payload => ({
  type: PERSON.GET_DEPARTMENTS_SUCCESS,
  payload
})

export const getDepartmentsError = payload => ({
  type: PERSON.GET_DEPARTMENTS_ERROR,
  payload
})

export const getBanks = payload => ({
  type: PERSON.GET_PERSON_BANKS,
  payload
})

export const banksInformation = payload => ({
  type: PERSON.BANKS_INFORMATION,
  payload
})

export const uploadFile = payload => ({
  type: PERSON.UPLOAD_FILE,
  payload
})

export const getFileSuccess = payload => ({
  type: PERSON.GET_FILE_SUCCESS,
  payload
})

export const getFileError = payload => ({
  type: PERSON.GET_FILE_ERROR,
  payload
})

export const fileMessage = payload => ({
  type: PERSON.FILE_MESSAGE,
  payload
})

export const downloadFile = payload => ({
  type: PERSON.DOWNLOAD_FILE,
  payload
})

export const removeFile = payload => ({
  type: PERSON.REMOVE_FILE,
  payload
})

export const editProfile = payload => ({
  type: PERSON.EDIT_PROFILE,
  payload
})

export const updateProfile = payload => ({
  type: PERSON.UPDATE_PROFILE,
  payload
})

export const getEmploymentRecord = payload => ({
  type: PERSON.GET_EMPLOYMENT_RECORD,
  payload
})

export const employmentRecord = payload => ({
  type: PERSON.EMPLOYMENT_RECORD,
  payload
})

export const getActiveAffiliateRecord = payload => ({
  type: PERSON.GET_ACTIVE_AFFILIATE_RECORD,
  payload
})

export const activeAffiliateRecord = payload => ({
  type: PERSON.ACTIVE_AFFILIATE_RECORD,
  payload
})

export const getFRMRecord = payload => ({
  type: PERSON.GET_FRM_RECORD,
  payload
})

export const frmRecord = payload => ({
  type: PERSON.FRM_RECORD,
  payload
})

export const createEmploymentRecord = payload => ({
  type: PERSON.CREATE_EMPLOYMENT_RECORD,
  payload
})

export const employmentRecordInformation = payload => ({
  type: PERSON.EMPLOYMENT_RECORD_INFORMATION,
  payload
})

export const getAffiliateRecordModalData = payload => ({
  type: PERSON.GET_AFFILIATE_RECORD_MODAL_DATA,
  payload
})

export const affiliateRecordModalDataInformation = payload => ({
  type: PERSON.AFFILIATE_RECORD_MODAL_DATA_INFORMATION,
  payload
})

export const createAffiliateRecord = payload => ({
  type: PERSON.CREATE_AFFILIATE_RECORD,
  payload
})

export const affiliateRecordInformation = payload => ({
  type: PERSON.AFFILIATE_RECORD_INFORMATION,
  payload
})

export const createFrmRecord = payload => ({
  type: PERSON.CREATE_FRM_RECORD,
  payload
})

export const frmRecordInformation = payload => ({
  type: PERSON.FRM_RECORD_INFORMATION,
  payload
})

export const clearInformationMessage = payload => ({
  type: PERSON.CLEAR_INFORMATION_MESSAGE,
  payload
})

export const getContributionPayments = payload => ({
  type: PERSON.GET_CONTRIBUTION_PAYMENTS,
  payload
})

export const getContributionPaymentsSuccess = payload => ({
  type: PERSON.GET_CONTRIBUTION_PAYMENTS_SUCCESS,
  payload
})

export const getContributionPaymentsError = payload => ({
  type: PERSON.GET_CONTRIBUTION_PAYMENTS_ERROR,
  payload
})

export const getFilterData = payload => ({
  type: PERSON.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PERSON.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PERSON.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PERSON.FILTER_DATA_CLEAR
})

export const PERSON_ACTIONS = {
  GET_PERSON: getPerson,
  GET_PERSON_SUCCESS: getPersonSuccess,
  GET_PERSON_ERROR: getPersonError,
  CLEAR: personClear,

  UPDATE_ADDRESSES: updateAddresses,
  ADD_ADDRESS: addAddress,
  EDIT_ADDRESS: editAddress,
  REMOVE_ADDRESS: removeAddress,

  UPDATE_PHONES: updatePhones,
  ADD_PHONE: addPhone,
  EDIT_PHONE: editPhone,
  REMOVE_PHONE: removePhone,

  UPDATE_NOTES: updateNotes,
  ADD_NOTE: addNote,
  EDIT_NOTE: editNote,
  REMOVE_NOTE: removeNote,

  UPDATE_EMAILS: updateEmails,
  ADD_EMAIL: addEmail,
  EDIT_EMAIL: editEmail,
  REMOVE_EMAIL: removeEmail,

  UPDATE_BANKS_ACCOUNTS: updateBanksAccounts,
  ADD_BANK_ACCOUNT: addBankAccount,
  EDIT_BANK_ACCOUNT: editBankAccount,
  REMOVE_BANK_ACCOUNT: removeBankAccount,

  GET_TYPES: getTypes,
  GET_TYPES_SUCCESS: getTypesSuccess,
  GET_TYPES_ERROR: getTypesError,

  GET_DEPARTMENTS: getDepartments,
  GET_DEPARTMENTS_SUCCESS: getDepartmentsSuccess,
  GET_DEPARTMENTS_ERROR: getDepartmentsError,

  GET_PERSON_BANKS: getBanks,
  BANKS_INFORMATION: banksInformation,

  SET_INFORMATION_MESSAGE: informationMessage,

  UPLOAD_FILE: uploadFile,
  GET_FILE_SUCCESS: getFileSuccess,
  GET_FILE_ERROR: getFileError,
  FILE_MESSAGE: fileMessage,
  DOWNLOAD_FILE: downloadFile,
  REMOVE_FILE: removeFile,

  EDIT_PROFILE: editProfile,
  UPDATE_PROFILE: updateProfile,

  GET_EMPLOYMENT_RECORD: getEmploymentRecord,
  EMPLOYMENT_RECORD: employmentRecord,

  GET_ACTIVE_AFFILIATE_RECORD: getActiveAffiliateRecord,
  ACTIVE_AFFILIATE_RECORD: activeAffiliateRecord,

  GET_FRM_RECORD: getFRMRecord,
  FRM_RECORD: frmRecord,

  CREATE_EMPLOYMENT_RECORD: createEmploymentRecord,
  EMPLOYMENT_RECORD_INFORMATION: employmentRecordInformation,

  GET_AFFILIATE_RECORD_MODAL_DATA: getAffiliateRecordModalData,
  AFFILIATE_RECORD_MODAL_DATA_INFORMATION: affiliateRecordModalDataInformation,
  CREATE_AFFILIATE_RECORD: createAffiliateRecord,
  AFFILIATE_RECORD_INFORMATION: affiliateRecordInformation,

  CREATE_FRM_RECORD: createFrmRecord,
  FRM_RECORD_INFORMATION: frmRecordInformation,

  CLEAR_INFORMATION_MESSAGE: clearInformationMessage,

  GET_CONTRIBUTION_PAYMENTS: getContributionPayments,
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: getContributionPaymentsSuccess,
  GET_CONTRIBUTION_PAYMENTS_ERROR: getContributionPaymentsError,

  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData
}
