import React from 'react'
import PropTypes from 'prop-types'

import MyPropTyes from '@global/propTypes'

import Icon from '@components/icon'
import { Text } from '@components/texts'

import { Wrapper } from './styles'

const Loading = ({ text, color, size, weight }) => (
  <Wrapper>
    <Icon name='loop' color={color} size='big' spin />
    {text && <Text text={`Cargando ${text}...`} align='center' weight={weight} color={color} size={size} />}
  </Wrapper>
)

export default Loading

Loading.propTypes = {
  text: PropTypes.string,
  color: MyPropTyes.color,
  size: MyPropTyes.size,
  weight: MyPropTyes.weight
}
