import { FRM_RECORD } from './types'

export const getFrmRecord = payload => ({
  type: FRM_RECORD.GET_FRM_RECORD,
  payload
})

export const getFrmRecordSuccess = payload => ({
  type: FRM_RECORD.GET_FRM_RECORD_SUCCESS,
  payload
})

export const getFrmRecordError = payload => ({
  type: FRM_RECORD.GET_FRM_RECORD_ERROR,
  payload
})

export const frmRecordClear = payload => ({
  type: FRM_RECORD.CLEAR,
  payload
})

export const getContributions = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTIONS,
  payload
})

export const getContributionsSuccess = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTIONS_SUCCESS,
  payload
})

export const getContributionsError = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTIONS_ERROR,
  payload
})

export const getPaymentsMade = payload => ({
  type: FRM_RECORD.GET_PAYMENTS_MADE,
  payload
})

export const getPaymentsMadeSuccess = payload => ({
  type: FRM_RECORD.GET_PAYMENTS_MADE_SUCCESS,
  payload
})

export const getPaymentsMadeError = payload => ({
  type: FRM_RECORD.GET_PAYMENTS_MADE_ERROR,
  payload
})

export const applyFilter = payload => ({
  type: FRM_RECORD.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: FRM_RECORD.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: FRM_RECORD.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: FRM_RECORD.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: FRM_RECORD.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: FRM_RECORD.GET_MODAL_DATA,
  payload
})

export const modalDataResponse = payload => ({
  type: FRM_RECORD.MODAL_DATA_RESPONSE,
  payload
})

export const editProfile = payload => ({
  type: FRM_RECORD.EDIT_PROFILE,
  payload
})

export const updateProfile = payload => ({
  type: FRM_RECORD.UPDATE_PROFILE,
  payload
})

export const editProfileResponse = payload => ({
  type: FRM_RECORD.EDIT_PROFILE_RESPONSE,
  payload
})

export const uploadFrmRecordFile = payload => ({
  type: FRM_RECORD.FRM_RECORD_UPLOAD_FILE,
  payload
})

export const getFrmRecordFiles = payload => ({
  type: FRM_RECORD.GET_FRM_RECORD_FILES,
  payload
})

export const getFrmRecordFileSuccess = payload => ({
  type: FRM_RECORD.GET_FRM_RECORD_FILE_SUCCESS,
  payload
})

export const messageInformation = payload => ({
  type: FRM_RECORD.MESSAGE_INFORMATION,
  payload
})

export const frmRecordFileMessage = payload => ({
  type: FRM_RECORD.FRM_RECORD_FILE_MESSAGE,
  payload
})

export const downloadFrmRecordFile = payload => ({
  type: FRM_RECORD.DOWNLOAD_FRM_RECORD_FILE,
  payload
})

export const removeFrmRecordFile = payload => ({
  type: FRM_RECORD.REMOVE_FRM_RECORD_FILE,
  payload
})

export const clearInformationMessage = () => ({
  type: FRM_RECORD.CLEAR_INFORMATION_MESSAGE
})

export const getPersonData = payload => ({
  type: FRM_RECORD.GET_PERSON_DATA,
  payload
})

export const getPersonDataSuccess = payload => ({
  type: FRM_RECORD.GET_PERSON_DATA_SUCCESS,
  payload
})

export const dataUploadReady = () => ({
  type: FRM_RECORD.DATA_UPLOAD_READY
})

export const getContributionPayments = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTION_PAYMENTS,
  payload
})

export const getContributionPaymentsSuccess = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTION_PAYMENTS_SUCCESS,
  payload
})

export const getContributionPaymentsError = payload => ({
  type: FRM_RECORD.GET_CONTRIBUTION_PAYMENTS_ERROR,
  payload
})

export const FRM_RECORD_ACTIONS = {
  GET_FRM_RECORD: getFrmRecord,
  GET_FRM_RECORD_SUCCESS: getFrmRecordSuccess,
  GET_FRM_RECORD_ERROR: getFrmRecordError,
  CLEAR: frmRecordClear,

  GET_CONTRIBUTIONS: getContributions,
  GET_CONTRIBUTIONS_SUCCESS: getContributionsSuccess,
  GET_CONTRIBUTIONS_ERROR: getContributionsError,

  GET_PAYMENTS_MADE: getPaymentsMade,
  GET_PAYMENTS_MADE_SUCCESS: getPaymentsMadeSuccess,
  GET_PAYMENTS_MADE_ERROR: getPaymentsMadeError,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_RESPONSE: modalDataResponse,

  EDIT_PROFILE: editProfile,
  UPDATE_PROFILE: updateProfile,
  EDIT_PROFILE_RESPONSE: editProfileResponse,

  FRM_RECORD_UPLOAD_FILE: uploadFrmRecordFile,
  GET_FRM_RECORD_FILES: getFrmRecordFiles,
  GET_FRM_RECORD_FILES_SUCCESS: getFrmRecordFileSuccess,

  FRM_RECORD_FILE_MESSAGE: frmRecordFileMessage,
  DOWNLOAD_FRM_RECORD_FILE: downloadFrmRecordFile,
  REMOVE_FRM_RECORD_FILE: removeFrmRecordFile,

  MESSAGE_INFORMATION: messageInformation,
  CLEAR_INFORMATION_MESSAGE: clearInformationMessage,

  GET_PERSON_DATA: getPersonData,
  GET_PERSON_DATA_SUCCESS: getPersonDataSuccess,

  DATA_UPLOAD_READY: dataUploadReady,

  GET_CONTRIBUTION_PAYMENTS: getContributionPayments,
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: getContributionPaymentsSuccess,
  GET_CONTRIBUTION_PAYMENTS_ERROR: getContributionPaymentsError
}
