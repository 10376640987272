import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'
import { TOKEN_HELPERS } from '@utils/helpers'

import { USER_ACTIONS } from './action'
import { USER } from './types'

const apiCalls = {
  loadUser: credentials => Axios.post('Account/signin', credentials),
  sendPasswordRecovery: email => Axios.post(`Account/passwordrecovery?email=${email}`),
  sendPasswordConfirm: data => Axios.post('Account/passwordconfirm', data),
  refreshUser: token => Axios('Account', {
    headers: {
      Authorization: TOKEN_HELPERS.SET_BEARER(token)
    }
  })
}

export function* getUser ({ payload }) {
  try {
    const { email, password } = payload
    const credentials = { email, password }

    const res = yield call(apiCalls.loadUser, credentials)
    let user = res.data
    const tokenCreationDate = yield TOKEN_HELPERS.SET_TOKEN_DATE(user.tokenMinutes)

    user = {
      ...user,
      tokenCreationDate: tokenCreationDate.toISOString()
    }
    localStorage.setItem('user', JSON.stringify(user))

    yield put(USER_ACTIONS.SUCCESS({ ...user, tokenCreationDate }))
  } catch (error) {
    // interim solution until the error sending system is changed
    yield put(USER_ACTIONS.ERROR(error || 'error'))
  }
}

export function* passwordRecovery ({ payload }) {
  try {
    const { email } = payload
    const res = yield call(apiCalls.sendPasswordRecovery, email)
    return res
  } catch (error) {
    const { message } = error
    return message
  }
}

export function* passwordConfirm ({ payload }) {
  try {
    const res = yield call(apiCalls.sendPasswordConfirm, payload)
    const { status } = res
    yield put(USER_ACTIONS.RESPONSE_PASSWORD_CONFIRM(status))
  } catch (error) {
    yield put(USER_ACTIONS.RESPONSE_PASSWORD_CONFIRM(error.response))
  }
}

export function* refreshUser ({ payload }) {
  try {
    const { data } = yield call(apiCalls.refreshUser, payload)
    const userLocalStorage = JSON.parse(localStorage.getItem('user'))
    const user = {
      email: data.email,
      fullName: data.fullName,
      userId: data.userId,
      token: payload,
      tokenMinutes: userLocalStorage.tokenMinutes,
      tokenCreationDate: new Date(userLocalStorage.tokenCreationDate)
    }

    yield put(USER_ACTIONS.SUCCESS(user))
  } catch (error) {
    yield put(USER_ACTIONS.ERROR_USER_REFRESH(error))
  }
}

export default function* saga () {
  yield takeLatest(USER.GET, getUser)
  yield takeLatest(USER.PASSWORD_RECOVERY, passwordRecovery)
  yield takeLatest(USER.PASSWORD_CONFIRM, passwordConfirm)
  yield takeLatest(USER.REFRESH_USER, refreshUser)
}
