import produce from 'immer'

import { CONTRIBUTION_PAYMENT } from './types'

export const initialState = {
  loading: null,
  error: false,
  contributionPayment: null,
  contributionPaymentStatuses: null,
  loadingEditProfile: false,
  editInformation: null,
  errorMessage: null,
  currencies: null,
  concepts: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case CONTRIBUTION_PAYMENT.GET_CONTRIBUTION_PAYMENT:
      draft.loading = true
      break
    case CONTRIBUTION_PAYMENT.SUCCESS: {
      const { contributionPaymentData, statusesData, currencies, concepts } = payload
      draft.loading = false
      draft.contributionPayment = contributionPaymentData
      draft.contributionPaymentStatuses = statusesData
      draft.currencies = currencies
      draft.concepts = concepts
      break
    }
    case CONTRIBUTION_PAYMENT.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case CONTRIBUTION_PAYMENT.EDIT_CONTRIBUTION_PAYMENT:
      draft.loadingEditProfile = true
      break
    case CONTRIBUTION_PAYMENT.EDIT_CONTRIBUTION_PAYMENT_INFORMATION:
      draft.editInformation = payload
      draft.loadingEditProfile = false
      break
    case CONTRIBUTION_PAYMENT.CONTRIBUTION_PAYMENT_UPDATED:
      draft.loadingEditProfile = false
      draft.contributionPayment = payload
      break
    case CONTRIBUTION_PAYMENT.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
