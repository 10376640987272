// https://www.color-hex.com/color/8293ee

export const colors = {
  primary: {
    regular: '#FF6900',
    dark: '#FCBD8A',
    darker: '#FFEDDE',
    hover: '#C75404',
    focus: '#FF6900',
    selected: '#C75404',
    disabled: '#DAD8D6'
  },
  secondary: {
    regular: '#FFE7D3',
    dark: '#FCBD8A',
    darker: '#FFEDDE',
    hover: '#FDDBBF',
    focus: '#333333',
    selected: '#28398F',
    disabled: '#DAD8D6'
  },
  tertiary: {
    regular: '#4F4F4F',
    dark: '#DAD8D6',
    darker: '#F2F6FF',
    hover: '#F2F6FF',
    focus: '#333333',
    selected: '#F2F6FF',
    disabled: '#DAD8D6'
  },
  variant: {
    regular: '#4158D0',
    dark: '#283EB3',
    darker: '#17277A',
    hover: '#141C46',
    focus: '#4158D0',
    selected: '#1F5D67',
    disabled: '#DAD8D6'
  },
  error: {
    regular: '#CF2E2E',
    dark: '#F5F4F3',
    darker: '#F3CFD2',
    hover: '#FFE6EC',
    focus: '#CF2E2E',
    selected: '#FFE6EC',
    disabled: '#DAD8D6'
  },
  success: {
    regular: '#37A05B',
    dark: '#F5F4F3',
    darker: '#E5FFEE',
    hover: '#1B7B3D',
    focus: '#37A05B',
    selected: '#1B7B3D',
    disabled: '#DAD8D6'
  },
  warning: {
    regular: '#F8C753',
    dark: '#F5F4F3',
    darker: '#FDF1F5',
    hover: '#CEA441',
    focus: '#F8C753',
    selected: '#CEA441',
    disabled: '#DAD8D6'
  },
  info: {
    regular: '#007DBE',
    dark: '#F5F4F3',
    darker: '#C6F1F1',
    hover: '#1F5D67',
    focus: '#007DBE',
    selected: '#1F5D67',
    disabled: '#DAD8D6'
  },
  contrast: {
    regular: '#3A3A3A',
    disabled: '#E4E4E4'
  },
  system: {
    regular: '#4F4F4F',
    dark: '#F5F4F3',
    darker: '#DAD8D6',
    hover: '#4F4F4F',
    focus: '#696969',
    selected: '#4F4F4F',
    disabled: '#DAD8D6'
  },
  background: {
    primary: '#F2F6F8',
    secondary: '#F6F5F5',
    regular: '#FFFFFF',
    dark: '#565656',
    hover: '#F1F1F1',
    disabled: '#FAFAFA'
  },
  events: {
    inprogress: '#37A05B',
    next: '#00769D',
    finished: '#696969',
    suspended: '#F8C753',
    cancelled: '#C43D48',
    white: '#FFFFFF'
  },
  grays: {
    ultralight: '#FAFAFA',
    light: '#E8E8E8',
    regular: '#828282',
    dark: '#333333'
  }
}

export const spacing = {
  quarter: '2px',
  half: '4px',
  threeQuarters: '6px',
  one: '8px',
  oneAndAQuarter: '10px',
  oneAndAHalf: '12px',
  two: '16px',
  twoAndAQuarter: '18px',
  twoAndAHalf: '20px',
  three: '24px',
  threeAndAHalf: '28px',
  four: '32px',
  fourAndAHalf: '36px',
  five: '40px',
  fiveAndAHalf: '44px',
  six: '48px',
  sixAndAHalf: '52px',
  seven: '56px',
  sevenAndAHalf: '60px',
  eight: '64px',
  eightAndAHalf: '68px',
  nine: '72px',
  nineAndAHalf: '76px',
  ten: '80px',
  tenAndAHalf: '84px',
  eleven: '88px',
  elevenAndAHalf: '92px',
  twelve: '96px',
  twelveAndAHalf: '100px',
  thirteen: '104px',
  fourteen: '112x',
  fifteen: '120px'
}

export const page = {
  width: '1200px'
}
