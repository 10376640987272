import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS, DEVICE_NAME } from '@global/constants'

export const WindowDimensionCtx = createContext(null)

const getDevice = () => {
  const width = window.innerWidth
  if (width <= BREAKPOINTS.MOBILE) return BREAKPOINTS.MOBILE
  if (width <= BREAKPOINTS.TABLET_PORTRAIT) return BREAKPOINTS.TABLET_PORTRAIT
  if (width <= BREAKPOINTS.TABLET) return BREAKPOINTS.TABLET
  if (width >= BREAKPOINTS.BIG_SCREEN) return BREAKPOINTS.BIG_SCREEN
  return BREAKPOINTS.DESKTOP
}

const getDeviceName = () => {
  switch (getDevice()) {
    case BREAKPOINTS.BIG_SCREEN:
      return DEVICE_NAME.BIG_SCREEN
    case BREAKPOINTS.DESKTOP:
      return DEVICE_NAME.DESKTOP
    case BREAKPOINTS.TABLET:
      return DEVICE_NAME.TABLET
    case BREAKPOINTS.TABLET_PORTRAIT:
      return DEVICE_NAME.TABLET_PORTRAIT
    default:
      return DEVICE_NAME.MOBILE
  }
}
const WinodwDimensionsProvider = ({ children }) => {
  const [device, setDevice] = useState(getDevice())
  const [deviceName, setDeviceName] = useState(getDeviceName())

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDevice())
      setDeviceName(getDeviceName())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <WindowDimensionCtx.Provider value={{ device, deviceName }}>
      {children}
    </WindowDimensionCtx.Provider>
  )
}

WinodwDimensionsProvider.propTypes = {
  children: PropTypes.any
}

export default WinodwDimensionsProvider

export const useWindowDimensions = () => useContext(WindowDimensionCtx)
