export const key = 'HISTORY'

export const HISTORY = {
  GET_HISTORY: 'HISTORY/GET_LIST',
  SUCCESS: 'HISTORY/GET_LIST/SUCCESS',
  ERROR: 'HISTORY/GET_LIST/ERROR',
  CLEAR: 'HISTORY/CLEAR_LIST',
  SEND_FILTER: 'HISTORY/FILTER/SEND',
  GET_FILTER_DATA: 'HISTORY/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'HISTORY/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'HISTORY/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'HISTORY/FILTER/CLEAR'
}

export default key
