import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { CONTRIBUTION_PAYMENT_ACTIONS } from './actions'
import { CONTRIBUTION_PAYMENT } from './types'

const apiCalls = {
  getContributionPayment: ({ contributionPaymentId, token }) => (
    Axios(`InboundPayment/contributionpayment/${contributionPaymentId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getContributionPaymentStatuses: token => (
    Axios('InboundPayment/contributionpayment/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editContributionPayment: ({ inboundPaymentId, contributionPaymentId, data, token }) => (
    Axios.post(`InboundPayment/${inboundPaymentId}/contributionpayment/${contributionPaymentId}/edit`,
      data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getCurrencies: token => Axios('Currency',
    {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      }
    }),
  getConcepts: token => (
    Axios.get('Concept',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getContributionPayment ({ payload }) {
  try {
    const { token } = payload
    const { data: contributionPaymentData } = yield call(apiCalls.getContributionPayment, payload)
    const { data: statusesData } = yield call(apiCalls.getContributionPaymentStatuses, token)
    const { data: currencies } = yield call(apiCalls.getCurrencies, token)
    const { data: concepts } = yield call(apiCalls.getConcepts, token)

    const objData = { contributionPaymentData, statusesData, currencies, concepts }
    yield put(CONTRIBUTION_PAYMENT_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(CONTRIBUTION_PAYMENT_ACTIONS.ERROR(error))
  }
}

export function* editContributionPayment ({ payload }) {
  try {
    const { status, data: ContributionPaymentUpdated } = yield call(apiCalls.editContributionPayment, payload)

    yield put(CONTRIBUTION_PAYMENT_ACTIONS.CONTRIBUTION_PAYMENT_UPDATED(ContributionPaymentUpdated))
    yield put(CONTRIBUTION_PAYMENT_ACTIONS.EDIT_CONTRIBUTION_PAYMENT_INFORMATION({ status }))
  } catch (error) {
    yield put(CONTRIBUTION_PAYMENT_ACTIONS.EDIT_CONTRIBUTION_PAYMENT_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(CONTRIBUTION_PAYMENT.GET_CONTRIBUTION_PAYMENT, getContributionPayment)
  yield takeLatest(CONTRIBUTION_PAYMENT.EDIT_CONTRIBUTION_PAYMENT, editContributionPayment)
}
