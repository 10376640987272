export const SITE = {
  SIGN_IN: '/sign-in',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_CONFIRM: '/password-confirm',
  HOME: '/home',
  PEOPLE: '/people',
  FINANCE: '/finance',
  LOADING: '/loading',
  PERSON: '/person',
  SETTING: '/setting',
  IMPORTS: '/imports',
  USERS: '/users',
  USER: '/user',
  AFFILIATE_RECORD: '/affiliateRecord',
  IMPORT: '/import',
  FRM_RECORD: '/frmRecord',
  HISTORY: '/history',
  HISTORY_LOG: '/historyLog',
  EMPLOYEE_HISTORY: '/employeeHistory',
  AFFILIATE_HISTORY: '/affiliateHistory',
  EMPLOYMENT_HISTORY: '/employmentHistory',
  FRM_HISTORY: '/frmHistory',
  BENEFITS: '/benefits',
  ERROR_LOG: '/errorLog',
  EXPORTS: '/exports',
  ACTIVITY_LOG: '/activityLog',
  LIQUIDATIONS: '/liquidations',
  LIQUIDATION_LOG: '/liquidationProfile',
  FRM_CATEGORIES: '/frmCategories',
  ENTITY_MANAGMENT: '/entityManagment',
  CONTRIBUTION_PROFILE: '/contributionProfile',
  INCOME: '/income',
  DEPOSIT_PROFILE: '/inboundPayment',
  EMPLOYMENT_RECORD: '/employmentRecord',
  DEPOSIT_DETAIL: '/depositDetail',
  CONTRIBUTION_PAYMENT: '/contributionPayment',
  PAYMENTS: '/payments',
  PAYMENT_PROFILE: '/paymentProfile',
  PAYMENT_DETAIL: '/paymentDetail',
  PAYMENT_SETTLEMENTS: '/paymentSettlements',
  PAYMENT_SETTLEMENT_PROFILE: '/paymentSettlementProfile',
  DEBT_PROFILE: '/debtProfile',
  SERVICES: '/serviceProvider',
  SERVICE_PROVIDER_PROFILE: '/serviceProviderProfile',
  EXPORT_PROFILE: '/exportProfile',
  ATAE_BANK_ACCOUNTS: '/ataeBankAccounts',
  AFFILIATE_CATEGORIES: '/affiliateCategory',
  CURRENCIES: '/currencies',
  CURRENCY_PROFILE: '/currencyProfile',
  DETAILS_LIST: '/detailsList',
  NO_EMPLOYEE_FEE: '/noEmployeeFee'
}
