import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { ACTIONS_TYPES } from '@global/constants'

import { ACTIVITY_LOG_ACTIONS } from './actions'
import { ACTIVITY_LOG } from './types'

const apiCalls = {
  getActivityLog: ({ data, token }) => (
    Axios.post('History', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  // getActivityLogDescriptionTypes: token => (
  //   Axios.get('History/DescriptionTypes',
  //     {
  //       headers: {
  //         Authorization: TOKEN_HELPERS.SET_BEARER(token)
  //       }
  //     })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getActivityLog ({ payload }) {
  try {
    // const { token } = payload
    const { data: activities } = yield call(apiCalls.getActivityLog, payload)
    // const { data } = yield call(apiCalls.getActivityLogDescriptionTypes, token)
    // const { data: entitiesNames } = yield call(apiCalls.getActivityLog, payload)
    const objData = {
      activities,
      descriptionTypes: ACTIONS_TYPES
      // entitiesNames
    }
    yield put(ACTIVITY_LOG_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(ACTIVITY_LOG_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(ACTIVITY_LOG_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(ACTIVITY_LOG_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(ACTIVITY_LOG.GET, getActivityLog)
  yield takeLatest(ACTIVITY_LOG.GET_FILTER_DATA, FilterData)
}
