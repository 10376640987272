import produce from 'immer'

import { EMPLOYMENT_RECORD_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  employmentRecordProfile: null,
  employmentRecordProfileStatuses: null,
  loadingEmploymentProfile: false,
  loadingEditProfile: false,
  editInformation: null,
  errorMessage: null,
  modalDataInformation: null,
  contributions: null,
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  contributionError: null,
  contributionsLoading: false,
  currencies: null,
  contributionPaymentsLoading: false,
  contributionPayments: null,
  contributionPaymentsError: null,
  inboundPaymentsOrigins: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case EMPLOYMENT_RECORD_PROFILE.GET_EMPLOYMENT_RECORD_PROFILE:
      draft.loading = true
      break
    case EMPLOYMENT_RECORD_PROFILE.SUCCESS: {
      const { employmentRecordProfileData, statusesData } = payload
      draft.loadingEmploymentProfile = false
      draft.employmentRecordProfile = employmentRecordProfileData
      draft.employmentRecordProfileStatuses = statusesData
      break
    }
    case EMPLOYMENT_RECORD_PROFILE.ERROR:
      draft.loadingEmploymentProfile = false
      draft.loading = false
      draft.error = payload
      break
    case EMPLOYMENT_RECORD_PROFILE.EDIT_EMPLOYMENT_RECORD_PROFILE:
      draft.loadingEditProfile = true
      break
    case EMPLOYMENT_RECORD_PROFILE.EDIT_EMPLOYMENT_RECORD_PROFILE_INFORMATION:
      draft.editInformation = payload
      draft.loadingEditProfile = false
      break
    case EMPLOYMENT_RECORD_PROFILE.EMPLOYMENT_RECORD_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.employmentRecordProfile = payload
      break
    case EMPLOYMENT_RECORD_PROFILE.EMPLOYMENT_RECORD_PROFILE_MODAL_DATA_INFORMATION:
      draft.modalDataInformation = payload
      break
    case EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTIONS_EMPLOYMENT_RECORD:
      draft.contributionsLoading = true
      break
    case EMPLOYMENT_RECORD_PROFILE.CONTRIBUTIONS_EMPLOYMENT_RECORD_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.contributionsLoading = false
      draft.contributions = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      break
    }
    case EMPLOYMENT_RECORD_PROFILE.CONTRIBUTIONS_EMPLOYMENT_RECORD_ERROR:
      draft.contributionsLoading = false
      draft.contributionError = payload
      break
    case EMPLOYMENT_RECORD_PROFILE.CURRENCIES_INFORMATION:
      draft.currencies = payload
      break
    // FILTER
    case EMPLOYMENT_RECORD_PROFILE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case EMPLOYMENT_RECORD_PROFILE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    //  CONTRIBUTION PAYMENTS
    case EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS:
      draft.contributionPaymentsLoading = true
      break
    case EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS_SUCCESS: {
      const { contributionPaymentsData, originData } = payload
      draft.contributionPaymentsLoading = false
      draft.contributionPayments = contributionPaymentsData
      draft.inboundPaymentsOrigins = originData
      break
    }
    case EMPLOYMENT_RECORD_PROFILE.GET_CONTRIBUTION_PAYMENTS_ERROR:
      draft.contributionPaymentsLoading = false
      draft.contributionPaymentsError = payload
      break
    case EMPLOYMENT_RECORD_PROFILE.DATA_UPLOAD_READY:
      draft.loading = false
      break
    case EMPLOYMENT_RECORD_PROFILE.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
