import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Payments from '@containers/payments'
import PaymentProfile from '@containers/paymentProfile'
import PaymentDetail from '@containers/paymentDetail'

export const PaymentsRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={Payments} />
      <PrivateRoute
        exact
        path={`${url}${SITE.PAYMENT_PROFILE}/:paymentId`}
        component={PaymentProfile}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.PAYMENT_PROFILE}/:paymentId${SITE.PAYMENT_DETAIL}/:paymentDetailId`}
        component={PaymentDetail}
      />
    </Switch>
  )
}
