import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { ModalDataContainer, ButtonModalContainer } from '@global/styles'
import { changeNameForLabel, TOKEN_HELPERS, customBankAccounts } from '@utils/helpers'
import { TOAST_POSITION, BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import CustomDatePicker from '@components/datePicker'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { PAYMENT_PROFILE_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const EditPaymentProfileModal = ({ statuses, oldData, saveData, close, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [optionsStatuses] = useState(changeNameForLabel(statuses))
  const [optionSelectedStatus, setOptionSelectedStatus] = useState()
  const [optionsBankAccounts, setOptionsBankAccounts] = useState()
  const [optionSelectedBankAccount, setOptionSelectedBankAccount] = useState(null)
  const [date, setDate] = useState(new Date(oldData.paymentDate))
  const [flag, setFlag] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [description, setDescription] = useState({
    value: oldData?.description,
    name: '',
    error: ''
  })

  const bankAccountsData = useSelector(SELECTORS.BANK_ACCOUNTS)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const cleanFields = () => {
    setOptionsBankAccounts(null)
    setOptionSelectedStatus(null)
  }

  const handleDropdownChange = e => {
    const { name, value } = e
    if (name === 'status') setOptionSelectedStatus(value)
    else setOptionSelectedBankAccount(value)
  }

  const handleInputChange = val => setDescription(val)

  const handleClick = () => {
    const paymentProfile = {
      statusId: optionSelectedStatus.id,
      bankAccountId: optionSelectedBankAccount.id,
      paymentDate: date,
      description: description.value
    }
    saveData(paymentProfile)
    cleanFields()
    setFlag(false)
    close(false)
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = {
        personId: '',
        serviceId: '',
        currencyId: '',
        token
      }
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_BANK_ACCOUNTS(data))
      setOptionSelectedStatus(optionsStatuses.find(s => s.id === oldData.statusId))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)

    return () => {
      setOptionSelectedStatus(null)
      setOptionsBankAccounts(null)
    }
  }, [])

  useEffect(() => {
    if (bankAccountsData) {
      if (bankAccountsData.status === 200) {
        const { bankAccounts } = bankAccountsData
        setOptionsBankAccounts(customBankAccounts(bankAccounts))
      }
    }
  }, [bankAccountsData])

  useEffect(() => {
    if (optionsBankAccounts) {
      setOptionSelectedBankAccount(optionsBankAccounts.find(ba => ba.id === oldData.bankAccountId))
    }
  }, [optionsBankAccounts])

  useEffect(() => {
    if (optionSelectedBankAccount) {
      setFlag(true)
    }
  }, [optionSelectedBankAccount])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])
  return (
    <>
      {flag
      && (
      <>
        <ModalDataContainer flexDirection='column'>
          <CustomDatePicker
            name='date'
            title='Fecha de pago'
            color='primary'
            onChange={setDate}
            date={date}
            justifyContentButton='space-between'
            block
            yearsToShow={1000}
            minDate={date}
          />
          <Dropdown
            name='bankAccounts'
            label='Cuentas bancarias'
            placeholder='Seleccionar'
            options={optionsBankAccounts}
            value={optionSelectedBankAccount}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <Dropdown
            name='status'
            label='Estado'
            placeholder='Seleccionar'
            options={optionsStatuses}
            value={optionSelectedStatus}
            onChange={handleDropdownChange}
            color='primary'
            height='small'
            block
          />
          <TextInput
            name='description'
            label='Descripción'
            placeholder='Ingrese descripción'
            value={description.value}
            height='small'
            color='primary'
            onChange={handleInputChange}
            bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          />
        </ModalDataContainer>
        <ButtonModalContainer justifyContent='flex-end'>
          <SolidButton
            size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
            color='primary'
            text='Guardar'
            onClick={handleClick}
            block={device === BREAKPOINTS.MOBILE}
          />
        </ButtonModalContainer>
        <Toast
          title={messageToast.title}
          message={messageToast.message}
          color={messageToast.color}
          icon={messageToast.icon}
          isShowing={isShowing}
          position={TOAST_POSITION.rightTop}
          onClick={() => setIsShowing(false)}
        />
      </>
      )}
    </>
  )
}

export default EditPaymentProfileModal

EditPaymentProfileModal.propTypes = {
  statuses: PropTypes.array,
  oldData: PropTypes.object,
  saveData: PropTypes.func,
  close: PropTypes.func,
  currentToken: PropTypes.object
}
