import { CONTRIBUTION_PAYMENT } from './types'

export const getContributionPayment = payload => ({
  type: CONTRIBUTION_PAYMENT.GET_CONTRIBUTION_PAYMENT,
  payload
})

export const getContributionPaymentSuccess = payload => ({
  type: CONTRIBUTION_PAYMENT.SUCCESS,
  payload
})

export const getContributionPaymentError = payload => ({
  type: CONTRIBUTION_PAYMENT.ERROR,
  payload
})

export const editContributionPayment = payload => ({
  type: CONTRIBUTION_PAYMENT.EDIT_CONTRIBUTION_PAYMENT,
  payload
})

export const editContributionPaymentInformation = payload => ({
  type: CONTRIBUTION_PAYMENT.EDIT_CONTRIBUTION_PAYMENT_INFORMATION,
  payload
})

export const contributionPaymentUpdated = payload => ({
  type: CONTRIBUTION_PAYMENT.CONTRIBUTION_PAYMENT_UPDATED,
  payload
})

export const contributionPaymentClear = () => ({
  type: CONTRIBUTION_PAYMENT.CLEAR
})

export const CONTRIBUTION_PAYMENT_ACTIONS = {
  GET_CONTRIBUTION_PAYMENT: getContributionPayment,
  SUCCESS: getContributionPaymentSuccess,
  ERROR: getContributionPaymentError,
  CLEAR: contributionPaymentClear,

  EDIT_CONTRIBUTION_PAYMENT: editContributionPayment,
  EDIT_CONTRIBUTION_PAYMENT_INFORMATION: editContributionPaymentInformation,
  CONTRIBUTION_PAYMENT_UPDATED: contributionPaymentUpdated
}
