export const HISTORY_FIELDS = [
  {
    name: 'AffiliateRecord',
    fields: [
      'DateCreated',
      'DateUpdated',
      'StartDate',
      'EndDate',
      'Category',
      'Status',
      'EmploymentRecord',
      'Person'
    ]
  },
  {
    name: 'Contribution',
    fields: [
      'DateCreated',
      'DateUpdated',
      'DueDate',
      'Total',
      'TotalPaid',
      'Status',
      'Settlement',
      'Currency',
      'Description',
      'PaymentDate'
    ]
  },
  {
    name: 'InboundPayment',
    fields: [
      'Amount',
      'AmountPaid',
      'NotificationDate',
      'Origin',
      'PaymentConfirmedDate',
      'PaymentMadeDate',
      'Status'
    ]
  },
  {
    name: 'FRMRecord',
    fields: [
      'BMSAvailable',
      'BMSBonusCoefficient',
      'BMSDelta',
      'BMSInbound',
      'BMSInboundWithBonus',
      'BMSOutbound',
      'Category',
      'StartDate',
      'EndDate',
      'Person',
      'NonEmployeeFee',
      'Status'
    ]
  },
  {
    name: 'Settlement',
    fields: [
      'DateCreated',
      'DateUpdated',
      'DueDate',
      'Month',
      'TotalAmount',
      'Year',
      'Status'
    ]
  },
  {
    name: 'OutboundPayment',
    fields: [
      'DateCreated',
      'DateUpdated',
      'BankAccount',
      'Description',
      'OutboundSettlementId',
      'PaymentDate',
      'Status',
      'Amount'
    ]
  },
  {
    name: 'OutboundSettlement',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Month',
      'Year',
      'Status'
    ]
  },
  {
    name: 'Person',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Birthday',
      'CI',
      'BillingNumber',
      'FullName',
      'Sex',
      'Remarks'
    ]
  },
  {
    name: 'Service',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Name',
      'Description'
    ]
  },
  {
    name: 'Address',
    fields: [
      'DateCreated',
      'DateUpdated',
      'City',
      'Country',
      'Department',
      'PostalCode',
      'StreetAddress1',
      'StreetAddress2',
      'Person',
      'IsPrimary',
      'AddressType'
    ]
  },
  {
    name: 'Phone',
    fields: [
      'DateCreated',
      'DateUpdated',
      'PhoneNumber',
      'PhoneType',
      'Person',
      'IsPrimary',
      'PhoneType'
    ]
  },
  {
    name: 'Note',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Description',
      'Deleted'
    ]
  },
  {
    name: 'Email',
    fields: [
      'DateCreated',
      'DateUpdated',
      'EmailAddress',
      'EmailType',
      'Person',
      'IsPrimary',
      'Deleted'
    ]
  },
  {
    name: 'EmploymentRecord',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Person',
      'BillingNumber',
      'InitialPosition',
      'StartDate',
      'EndDate',
      'Status'
    ]
  },
  {
    name: 'BankAccount',
    fields: [
      'DateCreated',
      'DateUpdated',
      'AccountNumber',
      'Bank',
      'Branch',
      'Currency',
      'Description',
      'IsPrimary',
      'Person',
      'Service',
      'Deleted'
    ]
  },
  {
    name: 'OutboundSettlementDetail',
    fields: [
      'DateCreated',
      'DateUpdated',
      'AffiliateRecord',
      'Concept',
      'Currency',
      'Description',
      'FRMRecord',
      'OutboundSettlementId',
      'Person',
      'Service',
      'Status',
      'Total',
      'TotalPaid',
      'Entity'
    ]
  },
  {
    name: 'ErrorLog',
    fields: [
      'DateCreated',
      'DateUpdated',
      'ErrorMessage',
      'RequestMethod',
      'RequestPath'
    ]
  },
  {
    name: 'ContributionPayment',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Amount',
      'Status',
      'ContributionId',
      'Description'
    ]
  },
  {
    name: 'AsyncJob',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Status',
      'Type'
    ]
  },
  {
    name: 'PersonFile',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Description',
      'Deleted',
      'File'
    ]
  },
  {
    name: 'Benefit',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Description',
      'Name',
      'Deleted',
      'Cost'
    ]
  },
  {
    name: 'FRMRecordFile',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Description',
      'Deleted',
      'File'
    ]
  },
  {
    name: 'CurrencyValue',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Date',
      'Value',
      'CurrencyId'
    ]
  },
  {
    name: 'File',
    fields: [
      'DateCreated',
      'DateUpdated',
      'ContentType',
      'Deleted',
      'FileType',
      'Name',
      'Url'
    ]
  },
  {
    name: 'OutboundPaymentDetail',
    fields: [
      'DateCreated',
      'DateUpdated',
      'Amount',
      'Description',
      'Detail',
      'BankAccount'
    ]
  }
]
