import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Text } from '@components/texts'
import { BASIC_INPUT_TYPES } from '@components/inputs/utils/constants'

import { DOUGHNUT_OPTION_NAMES } from '@containers/home/data'
import { Wrapper, Item, RadioButton, RadioButtonLabel } from './styles'

const RadioInputs = ({ name, title, options, onChange }) => {
  const [select, setSelect] = useState(DOUGHNUT_OPTION_NAMES.ACTIVES)

  const handleSelectChange = ({ target }) => {
    const { value } = target
    setSelect(value)
    onChange(value)
  }

  return (
    <>
      {options?.length > 0
      && (
      <Wrapper flexDirection='column' alignItems='flex-star' name={name}>
        {title && <Text size='large' weight='bold' align='center'>{title}</Text>}
        {options.map(op => (
          <Item key={op.id}>
            <RadioButton
              type={BASIC_INPUT_TYPES.RADIO}
              id={op.id}
              name={`radio_group_${name}`}
              value={op.name}
              checked={select === op.name}
              onChange={handleSelectChange}
            />
            <RadioButtonLabel />
            <Text size='medium' weight='regular'>{op.text}</Text>
          </Item>
        ))}
      </Wrapper>
      )}
    </>
  )
}

RadioInputs.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
}

export default RadioInputs
