import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import Axios from '@axios/'
import { debounce } from 'lodash'

import { TextContainerModal } from '@global/styles'
import { TOKEN_HELPERS, changeNameForLabel } from '@utils/helpers'
import { TOAST_PROPERTIES } from '@global/constants'
import { MESSAGES } from '@global/message'

import SelectInput from '@components/select'
import { Dropdown } from '@components/inputs/inputs'
import { Text } from '@components/texts'

import SELECTORS from '../../../redux/selectors'

const SearchPersonAndConceptAsync = props => {
  const {
    action,
    personData,
    savePersonData,
    close,
    setMessageToast,
    currentToken,
    edit,
    clearFields,
    setTablelist
  } = props
  const dispatch = useDispatch()

  const [person, setPerson] = useState(personData)
  const [concepts, setConcepts] = useState([])
  const [errorMessage, setErrorMessage] = useState()
  const [selectedOptionConcept, setSelectedOptionConcept] = useState(null)
  const [selectedData, setSelectedData] = useState()

  const dataConceptObj = useSelector(SELECTORS.PERSON_CONCEPT_DATA_INFORMATION)

  // FUNCTIONS
  const debounced = debounce((value, callback) => {
    const empty = []

    if (!value) {
      callback(empty)
      return
    }

    const data = {
      filterText: value,
      pageNumber: 1,
      pageSize: 10000
    }

    const results = Axios.post('/Person/filter', data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(currentToken.token)
      }
    })

    results
      .then(res => {
        const options = res.data.items.map(p => ({
          personId: p.id,
          label: p.fullName,
          billingNumber: p.billingNumber
        }))
        callback(options)
        setTablelist([])
        setSelectedData(null)
      })
      .catch(error => {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: error.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
        callback(empty)
      })
  }, 300)

  const customDropdownData = arrConcepts => {
    const arrDropdown = [...arrConcepts]

    if (arrDropdown.length === 0) {
      setErrorMessage(MESSAGES.NO_ACTIVE_RECORDS)
    }

    return changeNameForLabel(arrDropdown)
  }

  const handleClick = () => {
    const personConcepts = {
      billingNumber: person.billingNumber,
      personId: person.personId,
      conceptId: selectedData
    }

    savePersonData(personConcepts)
  }

  // HANDLERS
  const handleSelectChange = value => {
    setErrorMessage(null)
    setSelectedOptionConcept(null)
    setPerson(value)
    clearFields()
  }

  const handleDropdownChange = val => {
    setSelectedOptionConcept(val.value)
    setSelectedData(val.value.id)
    clearFields()
  }

  // USE EFFECTS
  useEffect(() => {
    if (person) {
      dispatch(action({ personId: person.personId, token: currentToken.token }))
    }
  }, [person])

  useEffect(() => {
    if (dataConceptObj) {
      const { status } = dataConceptObj
      if (status === 200) {
        const { conceptsData } = dataConceptObj
        setConcepts(customDropdownData(conceptsData))
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: dataConceptObj.message || dataConceptObj.title || dataConceptObj.status,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [dataConceptObj])

  useEffect(() => {
    if (selectedData) handleClick()
  }, [selectedData])

  useEffect(() => {
    if (edit) {
      const oldConcept = concepts.find(c => c.id === personData?.conceptId)
      setSelectedOptionConcept(oldConcept)
      setSelectedData(oldConcept?.id)
    }
  }, [concepts])

  useEffect(() => {
    if (edit && selectedOptionConcept) {
      setSelectedData(selectedOptionConcept.data)
    }
  }, [selectedOptionConcept])

  return (
    <>
      <SelectInput
        label='Persona'
        placeholder='Ingrese un nombre o número de cobro'
        value={person}
        loadOptions={debounced}
        onChange={handleSelectChange}
        color='primary'
        asyncSelect
        loadingMessage={() => <Text color='primary' weight='bold'>Cargando...</Text>}
        noOptionsMessage={() => <Text color='primary' weight='bold' align='center'>Sin opciones</Text>}
      />
      <Dropdown
        name='concept'
        label='Concepto'
        placeholder={(person && concepts.length <= 0) ? 'No hay datos' : 'Seleccione'}
        options={concepts}
        value={selectedOptionConcept}
        onChange={handleDropdownChange}
        color='primary'
        height='small'
        block
        disabled={!person || concepts.length <= 0}
      />
      {errorMessage
           && (
           <TextContainerModal>
             <Text size='medium' weight='regular' align='left' color='error'>
               {errorMessage}
             </Text>
           </TextContainerModal>
           )}
    </>
  )
}

SearchPersonAndConceptAsync.propTypes = {
  personData: PropTypes.object,
  savePersonData: PropTypes.func,
  action: PropTypes.func,
  close: PropTypes.func,
  currentToken: PropTypes.object,
  setMessageToast: PropTypes.func,
  edit: PropTypes.bool,
  clearFields: PropTypes.func,
  setTablelist: PropTypes.func
}

export default SearchPersonAndConceptAsync
