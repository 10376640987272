export const key = 'INCOME'

export const INCOME = {
  GET: 'INCOME/GET_LIST',
  SUCCESS: 'INCOME/GET_LIST/SUCCESS',
  ERROR: 'INCOME/GET_LIST/ERROR',
  CLEAR: 'INCOME/CLEAR_LIST',

  SEND_FILTER: 'INCOME/FILTER/SEND',
  GET_FILTER_DATA: 'INCOME/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'INCOME/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'INCOME/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'INCOME/FILTER/CLEAR',

  ADD_DEPOSIT: 'INCOME/DEPOSIT/ADD',
  ADD_DEPOSIT_INFORMATION: 'INCOME/DEPOSIT/ADD_INFORMATION',
  GET_MODAL_DATA: 'INCOME/MODAL/GET_MODAL_DATA',
  MODAL_DATA_INFORMATION: 'INCOME/MODAL/MODAL_DATA_INFORMATION'
}

export default key
