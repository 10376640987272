import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { TYPE_CHARTS, TOAST_POSITION, MONTHS, TOAST_PROPERTIES } from '@global/constants'

import image from '@assets/illustration.png'

import { WrapperLoading, Wrapper } from '@global/styles'
import { useWindowDimensions } from '@components/windowDimensions'
import { TOKEN_HELPERS, setGreeting } from '@utils/helpers'
import { colors } from '@global/theme'
import { SITE } from '@routes/paths'

import Card from '@components/cards'
import Chart from '@components/charts'
import RadioInputs from '@components/inputs/inputs/components/radio'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import Loading from '@components/loading'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { HOME_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { doughnutOptions, LAYOUT_DATA, DOUGHNUT_OPTION_NAMES } from './data'

const Home = () => {
  useRedux()
  const dispatch = useDispatch()
  const { GetUser, GetToken, Logout } = useUser()
  const user = GetUser()
  const { deviceName, device } = useWindowDimensions()
  const today = new Date()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [doughnutChart, setDoughnutChart] = useState([])
  const [doughnutValues, setDoughnutValues] = useState()
  const [dataRadio] = useState(doughnutOptions)
  const [horizontalBarChart, setHorizontalBarChart] = useState()
  const [lineChart, setLineChart] = useState()
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const homeData = useSelector(SELECTORS.SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getHomeData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const year = today.getFullYear()
      dispatch(HOME_ACTIONS.GET_DATA_HOME({ year, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const changeValues = value => {
    const selected = doughnutOptions.find(e => e.name === value)
    const selectedPercentage = doughnutValues[selected.name]
    const rest = 100 - selectedPercentage
    setDoughnutChart([rest, selectedPercentage])
  }

  const handleToastClose = () => setIsShowing(false)

  const customDataLineChart = (arrStats, color, label) => {
    const monthsWithAmount = arrStats.map(m => ({
      ...m,
      amount: m.amount === 0 ? null : m.amount
    }))

    const dataset = {
      label,
      data: monthsWithAmount?.length > 0 ? monthsWithAmount.map(s => s.amount) : [],
      borderColor: colors[color].regular,
      pointBackgroundColor: colors[color].regular,
      pointBorderColor: colors[color].regular,
      pointBorderWidth: 5,
      hoverOffset: 10,
      barPercentage: 0.5
    }

    return dataset
  }

  const customDoughnutChart = frmStats => {
    const { activeCount, inactiveCount, retiredCount, retirementInProcessCount, totalCount } = frmStats

    const actives = Math.round((activeCount * 100) / totalCount)
    const inactives = Math.round((inactiveCount * 100) / totalCount)
    const retirees = Math.round((retiredCount * 100) / totalCount)
    const inProcess = Math.round((retirementInProcessCount * 100) / totalCount)

    setDoughnutValues({
      actives,
      inactives,
      retirees,
      inProcess,
      total: totalCount
    })
  }

  useEffect(() => {
    getHomeData()
  }, [])

  useEffect(() => {
    if (doughnutValues) {
      changeValues(DOUGHNUT_OPTION_NAMES.ACTIVES)
    }
  }, [doughnutValues])

  useEffect(() => {
    if (homeData && homeData.status === 200) {
      const { peopleData, inboundPaymentsList, outboundPaymentsList } = homeData

      const { frmStats, affiliateStats, totalPeopleCount } = peopleData
      const { activeCount: totalActiveAffiliates } = affiliateStats
      const { activeCount: totalActiveFrms } = frmStats

      setHorizontalBarChart({ totalPeopleCount, totalActiveAffiliates, totalActiveFrms })

      const dataSet1 = customDataLineChart(inboundPaymentsList, 'primary', 'Ingresos')
      const dataSet2 = customDataLineChart(outboundPaymentsList, 'variant', 'Egresos')

      setLineChart({
        labels: MONTHS.map(m => m.label),
        datasets: [dataSet1, dataSet2]
      })
      customDoughnutChart(frmStats)
    }
  }, [homeData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        getHomeData()
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.push(SITE.SIGN_IN)
          Logout()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            <Wrapper {...LAYOUT_DATA?.CARD[deviceName]}>
              <Card
                title={`${setGreeting(today)} ${user?.fullName}`}
                content=''
                image={image}
                imgPosition='after'
                type='vertical'
                size='xxlarge'
                containerDirection='column'
                height='auto'
                maxWidth='957px'
                device={device}
              />
            </Wrapper>
            <Wrapper {...LAYOUT_DATA?.DOUGHNUT[deviceName]}>
              <Chart
                chartType={TYPE_CHARTS.DOUGHNUT}
                values={doughnutChart}
                minHeight='353px'
              >
                <RadioInputs
                  name='frmRecords'
                  title={`Registros de FRM (${doughnutValues?.total})`}
                  options={dataRadio}
                  onChange={changeValues}
                />
              </Chart>
            </Wrapper>
            <Wrapper {...LAYOUT_DATA?.HORIZONTAL_BAR_CHART[deviceName]}>
              {horizontalBarChart
              && (
              <Chart
                title={`Personas (${horizontalBarChart?.totalPeopleCount})`}
                chartType={TYPE_CHARTS.HORIZONTAL_BAR_CHART}
                minHeight='353px'
                values={horizontalBarChart}
              />
              )}
            </Wrapper>
            <Wrapper {...LAYOUT_DATA?.LINE_CHART[deviceName]}>
              {lineChart
               && (
               <Chart
                 title={`Actividad de A.T.A.E ${today.getFullYear()}`}
                 chartType={TYPE_CHARTS.LINE}
                 values={lineChart}
                 minHeight='353px'
               />
               )}
            </Wrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </>

        )}
    </>
  )
}

export default Home
