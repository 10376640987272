import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { NO_EMPLOYEE_FEE_ACTIONS } from './actions'
import { NO_EMPLOYEE_FEE } from './types'

const apiCalls = {
  createNewFeeApi: ({ token, data }) => (
    Axios.post('FRMRecord/nonemployeefee/applyadjustment', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFeeHistory: ({ token, data }) => (
    Axios.post('FRMRecord/nonemployeefee/listadjustments', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* CreateNewFee ({ payload }) {
  try {
    const { status } = yield call(apiCalls.createNewFeeApi, payload)

    yield put(NO_EMPLOYEE_FEE_ACTIONS.MESSAGE_INFORMATION({ status, message: MESSAGES.FEE_CREATED }))
  } catch (error) {
    yield put(NO_EMPLOYEE_FEE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export function* getFeeHistory ({ payload }) {
  try {
    const { data: feeHistory } = yield call(apiCalls.getFeeHistory, payload)

    yield put(NO_EMPLOYEE_FEE_ACTIONS.FEE_HISTORY_SUCCESS(feeHistory))
  } catch (error) {
    yield put(NO_EMPLOYEE_FEE_ACTIONS.ERROR_MESSAGE(error))
  }
}

export default function* saga () {
  yield takeLatest(NO_EMPLOYEE_FEE.CREATE_NEW_FEE, CreateNewFee)
  yield takeLatest(NO_EMPLOYEE_FEE.GET_FEE_HISTORY, getFeeHistory)
}
