export const columnData = {
  Code: { name: 'Código', widthCol: '10%' },
  Concept: { name: 'Concepto', widthCol: '20%' },
  ServiceName: { name: 'Proveedor', widthCol: '20%' },
  PersonName: { name: 'Persona', widthCol: '20%' },
  Description: { name: 'Descripción', widthCol: '20%' },
  DetailId: { name: 'Adeudo', widthCol: '5%' },
  Amount: { name: 'Monto', widthCol: '5%' },
  BankAccount: { name: 'Cuenta bancaria', widthCol: '20%' },
  Status: { name: 'Estado', widthCol: '10%' }
}

export const columnDataModal = {
  Code: { name: 'Código', widthCol: '5%' },
  PersonName: { name: 'Persona', widthCol: '35%' },
  ServiceName: { name: 'Proveedor', widthCol: '10%' },
  Concept: { name: 'Concepto', widthCol: '35%' },
  Total: { name: 'Monto', widthCol: '10%' },
  Currency: { name: 'Divisa', widthCol: '10%' },
  DueDate: { name: 'Fec. vencimiento', widthCol: '30%' },
  Status: { name: 'Estado', widthCol: '10%' }
}
