import produce from 'immer'

import { ACTIVITY_LOG } from './types'

export const initialState = {
  loading: true,
  error: false,
  activityLogLoading: false,
  activityLogList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  descriptionTypes: null,
  dataFilters: [],
  dataFiltersError: null,
  entitiesNames: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case ACTIVITY_LOG.GET:
      draft.activityLogLoading = true
      break
    case ACTIVITY_LOG.SUCCESS: {
      const { activities, descriptionTypes } = payload
      const { items, fields, totalItems, totalPages, currentPage } = activities

      draft.loading = false
      draft.activityLogLoading = false
      draft.activityLogList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.descriptionTypes = descriptionTypes
      // draft.entitiesNames = entitiesNames
    }
      break
    case ACTIVITY_LOG.ERROR:
      draft.loading = false
      draft.activityLogLoading = false
      draft.error = payload
      break
    case ACTIVITY_LOG.CLEAR:
      return initialState
    // FILTER
    case ACTIVITY_LOG.SEND_FILTER:
      draft.loading = true
      break
    case ACTIVITY_LOG.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case ACTIVITY_LOG.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case ACTIVITY_LOG.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case ACTIVITY_LOG.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    default:
      return initialState
  }
})

export default reducer
