import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PAYMENTS_ACTIONS } from './actions'
import { PAYMENTS } from './types'

const apiCalls = {
  getPayments: ({ data, token }) => (
    Axios.post('OutboundPayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBankAccounts: token => (
    Axios.get('BankAccount',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getStatuses: token => (
    Axios.get('Settlement/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createPayment: ({ data, token }) => (
    Axios.post('OutboundPayment/create', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editPayment: ({ liquidationId, data, token }) => (
    Axios.post(`Settlement/${liquidationId}/edit`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getOutboundSettlement: ({ data, token }) => (
    Axios.post('OutboundSettlement/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))

}

export function* getPayments ({ payload }) {
  try {
    const { token } = payload
    const { data: payments } = yield call(apiCalls.getPayments, payload)
    const { data: statuses } = yield call(apiCalls.getStatuses, token)
    const obj = {
      payments,
      statuses
    }
    yield put(PAYMENTS_ACTIONS.SUCCESS(obj))
  } catch (error) {
    yield put(PAYMENTS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(PAYMENTS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(PAYMENTS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getPaymentBanks ({ payload }) {
  try {
    const { status, data: bankAccountsData } = yield call(apiCalls.getBankAccounts, payload)

    yield put(PAYMENTS_ACTIONS.BANK_ACCOUNTS_RESPONSE({ status, bankAccountsData }))
  } catch (error) {
    yield put(PAYMENTS_ACTIONS.BANK_ACCOUNTS_RESPONSE(error))
  }
}

export function* addPayment ({ payload }) {
  try {
    const { token } = payload
    const res = yield call(apiCalls.createPayment, payload)
    const { status } = res
    const data = { pageNumber: 1, pageSize: 15, orderByColumn: 'Id', orderByDesc: true }
    const { data: payments } = yield call(apiCalls.getPayments, { data, token })
    const { data: statuses } = yield call(apiCalls.getStatuses, token)
    const obj = {
      payments,
      statuses
    }

    yield put(PAYMENTS_ACTIONS.ADD_PAYMENT_INFORMATION({ status }))
    yield put(PAYMENTS_ACTIONS.SUCCESS(obj))
  } catch (error) {
    yield put(PAYMENTS_ACTIONS.ADD_PAYMENT_INFORMATION(error))
  }
}

export function* getOutboundSettlement ({ payload }) {
  try {
    const { status, data } = yield call(apiCalls.getOutboundSettlement, payload)
    const outboundSettlementsObj = data.items
    yield put(PAYMENTS_ACTIONS.OUTBOUND_SETTLEMENT_RESPONSE({ status, outboundSettlementsObj }))
  } catch (error) {
    yield put(PAYMENTS_ACTIONS.OUTBOUND_SETTLEMENT_RESPONSE(error))
  }
}

export default function* saga () {
  yield takeLatest(PAYMENTS.GET, getPayments)
  yield takeLatest(PAYMENTS.GET_FILTER_DATA, FilterData)
  yield takeLatest(PAYMENTS.GET_BANK_ACCOUNTS, getPaymentBanks)
  yield takeLatest(PAYMENTS.ADD_PAYMENT, addPayment)
  yield takeLatest(PAYMENTS.GET_OUTBOUND_SETTLEMENT, getOutboundSettlement)
}
