import produce from 'immer'

import { ATAE_BANK_ACCOUNTS } from './types'

export const initialState = {
  loading: null,
  error: false,
  messageInformation: null,
  loadingBankAccounts: false,
  loadingModal: null,
  modalData: null,
  ataeBankAccounts: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case ATAE_BANK_ACCOUNTS.GET_ATAE_BANK_ACCOUNTS:
      draft.loading = true
      break
    case ATAE_BANK_ACCOUNTS.SUCCESS:
      draft.loading = false
      draft.ataeBankAccounts = payload
      break
    case ATAE_BANK_ACCOUNTS.ERROR:
      draft.loading = false
      draft.error = payload
      break
    // MODAL DATA
    case ATAE_BANK_ACCOUNTS.ATAE_GET_MODAL_DATA:
      draft.loadingModal = true
      break
    case ATAE_BANK_ACCOUNTS.ATAE_MODAL_DATA_INFORMATION:
      draft.loadingModal = false
      draft.modalData = payload
      break
    // BANK ACCOUNTS
    case ATAE_BANK_ACCOUNTS.CREATE_ATAE_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      break
    case ATAE_BANK_ACCOUNTS.EDIT_ATAE_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      break
    case ATAE_BANK_ACCOUNTS.DELETE_ATAE_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      break
    case ATAE_BANK_ACCOUNTS.MESSAGE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingBankAccounts = false
      draft.loadingEditProfile = false
      break
    case ATAE_BANK_ACCOUNTS.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
