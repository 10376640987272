import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectserviceProviderProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectserviceProviderProfile, state => get(state, 'loading', null)
)

const selectServiceProviderProfileSuccess = createSelector(
  selectserviceProviderProfile, state => get(state, 'serviceProviderProfile', null)
)

const selectStatusesSuccess = createSelector(
  selectserviceProviderProfile, state => get(state, 'serviceProviderProfileStatuses', null)
)

const selectError = createSelector(
  selectserviceProviderProfile, state => get(state, 'error', null)
)

const selectLoadingEditServiceProviderProfile = createSelector(
  selectserviceProviderProfile, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectserviceProviderProfile, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectserviceProviderProfile, state => get(state, 'editInformation', [])
)

const selectMessageInformation = createSelector(
  selectserviceProviderProfile, state => get(state, 'messageInformation', null)
)

const selectLoadingBankAccounts = createSelector(
  selectserviceProviderProfile, state => get(state, 'loadingBankAccounts', [])
)

const selectModalData = createSelector(
  selectserviceProviderProfile, state => get(state, 'modalData', [])
)

const selectBankAccounts = createSelector(
  selectserviceProviderProfile, state => get(state, 'bankAccounts', [])
)

const selecDetailsProvider = createSelector(
  selectserviceProviderProfile, state => get(state, 'detailsProvider', [])
)

const selecPages = createSelector(
  selectserviceProviderProfile, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectserviceProviderProfile, state => get(state, 'totalItems', null)
)

const selectDetailsLoading = createSelector(
  selectserviceProviderProfile, state => get(state, 'detailsProviderLoading', null)
)

const selectDetailsProviderError = createSelector(
  selectserviceProviderProfile, state => get(state, 'detailsProviderError', null)
)

export default {
  SERVICE_PROVIDER_PROFILE_SUCCESS: selectServiceProviderProfileSuccess,
  SERVICE_PROVIDER_PROFILE_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_SERVICE_PROVIDER_PROFILE: selectLoadingEditServiceProviderProfile,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  MESSAGE_INFORMATION: selectMessageInformation,
  LOADING_BANK_ACCOUNTS: selectLoadingBankAccounts,
  MODAL_DATA: selectModalData,
  BANK_ACCOUNTS: selectBankAccounts,

  DETAILS_SUCCESS: selecDetailsProvider,
  PAGES: selecPages,
  TOTAL_ITEMS: selectTotalItems,
  DETAILS_LOADING: selectDetailsLoading,
  DETAILS_ERROR: selectDetailsProviderError
}
