import styled from 'styled-components'
import { spacing } from '@global/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: ${spacing.two}
`
