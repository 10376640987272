import { PAYMENT_SETTLEMENT_PROFILE } from './types'

export const getPaymentSettletmentProfile = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE,
  payload
})

export const getPaymentSettletmentProfileSuccess = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.SUCCESS,
  payload
})

export const getPaymentSettletmentProfileError = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.ERROR,
  payload
})

export const editPaymentSettletmentProfile = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.EDIT_PAYMENT_SETTLEMENT_PROFILE,
  payload
})

export const editPaymentSettletmentProfileInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION,
  payload
})

export const paymentSettletmentProfileUpdated = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.PAYMENT_SETTLEMENT_PROFILE_UPDATED,
  payload
})

export const getDebits = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE_DEBITS,
  payload
})

export const getDebitsSuccess = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.DEBITS_SUCCESS,
  payload
})

export const getDebitsError = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.DEBITS_ERROR,
  payload
})

export const ClearDebitsList = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.DEBITS_CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_MODAL_DATA,
  payload
})

export const modalDataInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.MODAL_DATA_INFORMATION,
  payload
})

export const addDebt = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.ADD_DEBT,
  payload
})

export const addDebtInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.ADD_DEBT_INFORMATION,
  payload
})

export const clearMessageInformation = () => ({
  type: PAYMENT_SETTLEMENT_PROFILE.MESSAGE_INFORMATION_CLEAR
})

export const getPaymentSettletmentProfilePersonConcepts = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_PERSON_CONCEPTS,
  payload
})

export const getEntitiesData = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_ENTITIES_DATA,
  payload
})

export const entitiesDataInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.ENTITIES_INFORMATION,
  payload
})

export const getServicesData = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_SERVICES_DATA,
  payload
})

export const servicesDataInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.SERVICES_INFORMATION,
  payload
})

export const clearServicesDataInformation = () => ({
  type: PAYMENT_SETTLEMENT_PROFILE.CLEAR_SERVICES_INFORMATION
})

export const getPersonData = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_PERSON_DATA,
  payload
})

export const personDataInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.PERSON_INFORMATION,
  payload
})

export const getCurrencies = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_CURRENCIES,
  payload
})

export const currenciesInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.CURRENCIES_INFORMATION,
  payload
})

export const calculateDetails = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.CALCULATE_DETAILS,
  payload
})

export const calculateDetailsInformation = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.CALCULATE_DETAILS_INFORMATION,
  payload
})

export const debitsUpdate = () => ({
  type: PAYMENT_SETTLEMENT_PROFILE.DEBITS_UPDATE
})

export const pendingProcessInfo = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.PENDING_PROCESS_INFO,
  payload
})

export const getProcessPendingInfo = payload => ({
  type: PAYMENT_SETTLEMENT_PROFILE.GET_PENDING_PROCESS_INFO,
  payload
})

export const PAYMENT_SETTLEMENT_PROFILE_ACTIONS = {
  GET_PAYMENT_SETTLEMENT_PROFILE: getPaymentSettletmentProfile,
  SUCCESS: getPaymentSettletmentProfileSuccess,
  ERROR: getPaymentSettletmentProfileError,
  EDIT_PAYMENT_SETTLEMENT_PROFILE: editPaymentSettletmentProfile,
  EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION: editPaymentSettletmentProfileInformation,
  PAYMENT_SETTLEMENT_PROFILE_UPDATED: paymentSettletmentProfileUpdated,

  GET_DEBITS: getDebits,
  DEBITS_SUCCESS: getDebitsSuccess,
  DEBITS_ERROR: getDebitsError,
  CLEAR: ClearDebitsList,
  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_INFORMATION: modalDataInformation,

  ADD_DEBT: addDebt,
  ADD_DEBT_INFORMATION: addDebtInformation,
  CLEAR_MESSAGE_INFORMATION: clearMessageInformation,

  PAYMENT_SETTLEMENT_PROFILE_GET_PERSON_CONCEPTS: getPaymentSettletmentProfilePersonConcepts,

  GET_ENTITIES_DATA: getEntitiesData,
  ENTITIES_INFORMATION: entitiesDataInformation,
  GET_SERVICES_DATA: getServicesData,
  SERVICES_INFORMATION: servicesDataInformation,
  CLEAR_SERVICES_INFORMATION: clearServicesDataInformation,
  GET_PERSON_DATA: getPersonData,
  PERSON_INFORMATION: personDataInformation,
  GET_CURRENCIES: getCurrencies,
  CURRENCIES_INFORMATION: currenciesInformation,

  CALCULATE_DETAILS: calculateDetails,
  CALCULATE_DETAILS_INFORMATION: calculateDetailsInformation,
  DEBITS_UPDATE: debitsUpdate,

  PENDING_PROCESS_INFO: pendingProcessInfo,
  GET_PROCESS_PENDING_INFO: getProcessPendingInfo
}
