import styled from 'styled-components'
import { spacing } from '@global/theme'

export const TableWrapperCurrencies = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 4;
  margin-bottom: ${spacing.three};
`
