import React from 'react'
import PropTypes from 'prop-types'

import { Dropdown } from '@components/inputs/inputs'
import Pagination from '@components/pagination'
import { Text } from '@components/texts'

import { PaginationWrapper, RowsContainer } from './styles'

const PaginationGroup = props => {
  const {
    pages,
    itemSelected,
    handleClickPaginationItem,
    options,
    selectedOption,
    handleDropdownChange,
    totalItems,
    itemsPerPage,
    deviceName
  } = props

  return (
    <PaginationWrapper deviceName={deviceName}>
      <Pagination
        pages={pages}
        color='primary'
        selected={itemSelected}
        goToPage={handleClickPaginationItem}
        size='small'
      />
      <RowsContainer deviceName={deviceName}>
        <Dropdown
          name='dropdown'
          options={options}
          value={selectedOption}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        <Text size='medium' align='right' weight='regular'>
          {`${itemSelected * selectedOption.value + 1}-
              ${itemSelected * selectedOption.value + itemsPerPage}
              de ${totalItems}`}
        </Text>
      </RowsContainer>
    </PaginationWrapper>
  )
}

PaginationGroup.propTypes = {
  pages: PropTypes.number,
  itemSelected: PropTypes.number,
  handleClickPaginationItem: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.number
    })
  ),
  selectedOption: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.number
  }),
  handleDropdownChange: PropTypes.func,
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  deviceName: PropTypes.string
}

export default PaginationGroup
