import produce from 'immer'

import { SERVICE_PROVIDER_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  serviceProviderProfile: null,
  loadingEditProfile: false,
  errorMessage: null,
  messageInformation: null,
  loadingBankAccounts: false,
  loadingModal: null,
  modalData: null,
  bankAccounts: null,

  detailsProvider: null,
  totalItems: null,
  pages: null,
  detailsProviderLoading: null,
  detailsProviderError: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case SERVICE_PROVIDER_PROFILE.GET_SERVICE_PROVIDER_PROFILE:
      draft.loading = true
      break
    case SERVICE_PROVIDER_PROFILE.SUCCESS:
      draft.loading = false
      draft.serviceProviderProfile = payload
      break
    case SERVICE_PROVIDER_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_PROFILE:
      draft.loadingEditProfile = true
      break
    case SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.serviceProviderProfile = payload
      break
    // MODAL DATA
    case SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_GET_MODAL_DATA:
      draft.loadingModal = true
      break
    case SERVICE_PROVIDER_PROFILE.SERVICE_PROVIDER_GET_MODAL_DATA_INFORMATION:
      draft.loadingModal = false
      draft.modalData = payload
      break
    // BANK ACCOUNTS
    case SERVICE_PROVIDER_PROFILE.CREATE_SERVICE_PROVIDER_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      draft.bankAccounts = payload
      break
    case SERVICE_PROVIDER_PROFILE.EDIT_SERVICE_PROVIDER_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      draft.bankAccounts = payload
      break
    case SERVICE_PROVIDER_PROFILE.DELETE_SERVICE_PROVIDER_BANK_ACCOUNT:
      draft.loadingBankAccounts = true
      draft.bankAccounts = payload
      break
    case SERVICE_PROVIDER_PROFILE.BANK_ACCOUNTS_UPDATED:
      draft.loadingModal = false
      draft.loadingBankAccounts = false
      draft.bankAccounts = payload
      break
    case SERVICE_PROVIDER_PROFILE.MESSAGE_INFORMATION:
      draft.loadingBankAccounts = false
      draft.loadingEditProfile = false
      draft.messageInformation = payload
      break
      //  CONTRIBUTIONS
    case SERVICE_PROVIDER_PROFILE.GET_DETAILS:
      draft.detailsProviderLoading = true
      break
    case SERVICE_PROVIDER_PROFILE.GET_DETAILS_SUCCESS: {
      const { items, totalItems, totalPages, currentPage } = payload
      draft.detailsProvider = items
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
      draft.detailsProviderLoading = false
      break
    }
    case SERVICE_PROVIDER_PROFILE.GET_DETAILS_ERROR:
      draft.detailsProviderError = payload
      draft.detailsProviderLoading = false
      break
    case SERVICE_PROVIDER_PROFILE.CLEAR:
      return initialState
    default:
      return initialState
  }
})

export default reducer
