import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Users from '@containers/users'
import UserProfile from '@containers/user'

export const UsersRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute
        exact
        path={url}
        component={Users}
      />
      <PrivateRoute
        exact
        path={`${url}${SITE.USER}/:userId`}
        component={UserProfile}
      />
    </Switch>
  )
}
