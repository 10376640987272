export const key = 'EMPLOYEE_HISTORY'

export const EMPLOYEE_HISTORY = {
  GET_EMPLOYEE_HISTORY: 'EMPLOYEE_HISTORY/GET_LIST',
  SUCCESS: 'EMPLOYEE_HISTORY/GET_LIST/SUCCESS',
  ERROR: 'EMPLOYEE_HISTORY/GET_LIST/ERROR',
  CLEAR: 'EMPLOYEE_HISTORY/CLEAR_LIST'
}

export default key
