import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Axios from '@axios/'
import { CancelToken, isCancel } from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import { changeNameForLabel, TOKEN_HELPERS } from '@utils/helpers'
import { TOAST_PROPERTIES, TOAST_POSITION, BREAKPOINTS } from '@global/constants'
import { MESSAGES } from '@global/message'
import {
  ModalDataContainer,
  ButtonModalContainer,
  DataFileContainer,
  FileUploaderContainer,
  LoaderContainer
} from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown } from '@components/inputs/inputs'
import FileUploader from '@components/fileUploader'
import Icon from '@components/icon'
import CircularProgressBar from '@components/circularProgressBar'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from '../../redux'
import { IMPORTS_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

import { typeAccept } from '../../data'

const AddFileImportModal = ({ types, token, close, uploadFile }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [uploaded, setUploaded] = useState()
  const [selectedOption, setSelectedOption] = useState()
  const [disabledButton, setDisabledButton] = useState(true)
  const [fileToSend, setFileToSend] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [errorFileUpload, setErrorFileUpload] = useState(false)

  const cancelFileUpload = useRef(null)
  const childRef = useRef()

  const uploadFileInfo = useSelector(SELECTORS.UPLOAD_FILE_INFO)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleDropdownChange = val => {
    setSelectedOption(val.value)
  }

  const errorControl = objFile => {
    if (!objFile || !selectedOption) setDisabledButton(true)
    else setDisabledButton(false)
  }

  const cleanData = () => {
    setUploaded(null)
    setSelectedOption(null)
    setDisabledButton(true)
    setFileToSend(null)
    setShowLoader(false)
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current) {
      setErrorFileUpload('LA CARGA FUE CANCELADA.')
      cancelFileUpload.current('LA CARGA FUE CANCELADA.')
      setShowLoader(false)
      childRef.current.cancelFileaUpload()
      setTimeout(() => setErrorFileUpload(false), 4000)
    }
  }

  const handleClick = () => {
    setShowLoader(true)

    Axios.post('File/upload', fileToSend, {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: TOKEN_HELPERS.SET_BEARER(token)
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent
        const percentCompleted = Math.round((loaded / total) * 100)
        setUploaded(percentCompleted)
      },
      cancelToken: new CancelToken(cancel => {
        cancelFileUpload.current = cancel
      })
    })
      .then(resUpload => {
        const { data } = resUpload
        return Axios.post(`AsyncJob/import?jobTypeId=${selectedOption?.id}&fileId=${data.id}`,
          { jobTypeId: selectedOption?.id, fileId: data.id },
          {
            headers: {
              Authorization: TOKEN_HELPERS.SET_BEARER(token)
            }
          })
      })
      .then(resImportData => {
        const { status, data } = resImportData
        dispatch(IMPORTS_ACTIONS.UPLOAD_FILE_INFO({ status, id: data.id }))
      })
      .catch(error => {
        if (!isCancel(error)) {
          dispatch(IMPORTS_ACTIONS.UPLOAD_FILE_INFO(error))
          childRef.current?.cancelFileaUpload()
          setShowLoader(false)
        }
      })
  }

  useEffect(() => {
    errorControl(fileToSend)
  }, [fileToSend, selectedOption])

  useEffect(() => {
    if (uploadFileInfo) {
      const { status, message, id } = uploadFileInfo
      if (status === 200) {
        uploadFile(id)
        cleanData()
        close()
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: message || MESSAGES.UPLOAD_FILE_ERROR,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      dispatch(IMPORTS_ACTIONS.UPLOAD_FILE_INFO_CLEAR())
    }
  }, [uploadFileInfo])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column' marginFirstChild>
        <Dropdown
          name='types'
          label='Tipos de importación'
          placeholder='Seleccione'
          options={changeNameForLabel(types)}
          value={selectedOption}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
        />
        <DataFileContainer direction={errorFileUpload}>
          <FileUploaderContainer>
            {showLoader
             && (
             <Icon
               name='close'
               backgroundColor='background'
               size='medium'
               color='grays'
               onClick={cancelUpload}
             />
             )}
            {!errorFileUpload
            && (
            <FileUploader
              ref={childRef}
              text='Subir archivo o arrastrar. Tipos de archivos: .pdf, .doc, .xlsx'
              typeFile='file'
              maxSizeInMB={100}
              sendFile={setFileToSend}
              showDeleteIcon={!showLoader}
              disabled={showLoader}
              fileAccept={typeAccept}
            />
            )}
          </FileUploaderContainer>
          {showLoader && (
            <LoaderContainer>
              <CircularProgressBar progress={uploaded} width='70' height='70' />
            </LoaderContainer>
          )}
          {errorFileUpload
            && (
              <Text color='error' size='large' align='left' weight='bold'>{errorFileUpload}</Text>
            )}
        </DataFileContainer>
      </ModalDataContainer>
      <ButtonModalContainer oneButton>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={disabledButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonModalContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.leftTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddFileImportModal

AddFileImportModal.propTypes = {
  types: PropTypes.array,
  token: PropTypes.string,
  close: PropTypes.func,
  uploadFile: PropTypes.func
}
