import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

import {
  TOKEN_HELPERS,
  formatDateBasic,
  sortByKey,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder
} from '@utils/helpers'

import { TOAST_PROPERTIES, TOAST_POSITION, TRANSLATION_OF_ROUTES, rowsPerPage } from '@global/constants'
import { MESSAGES } from '@global/message'
import { SITE } from '@routes/paths'
import {
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  WrapperLoading,
  WrapperHeading,
  WrapperBreadcrumbs,
  TableWrapper,
  PaginationWrapper
} from '@global/styles'

import { Heading } from '@components/texts'
import { GhostButton } from '@components/button'
import { useWindowDimensions } from '@components/windowDimensions'
import Breadcrumbs from '@components/breadcrums'
import { useUser } from '@components/authentication/utils/hook'
import Toast from '@components/toast'
import Table from '@components/table'
import Filter from '@components/filter'
import Modal from '@components/modal'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import Loading from '@components/loading'
import PaginationGroup from '@components/paginationGroup'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import useRedux from './redux'
import { IMPORTS_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'
import AddFileImportModal from './components/addImportModal'

import { Wrapper } from './styles'

import { columnData } from './data'

const Imports = () => {
  useRedux()
  const { device, deviceName } = useWindowDimensions()
  const dispatch = useDispatch()
  const history = useHistory()
  const { GetToken, Logout } = useUser()
  const { key } = useLocation()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [isShowing, setIsShowing] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [badges, setBadges] = useState([])
  const [filterData, setFilterData] = useState([])
  const [open, setOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState(null)
  const [addImportModal, setAddImportModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [parameters, setParameters] = useState()

  const loading = useSelector(SELECTORS.LOADING)
  const listLoading = useSelector(SELECTORS.LIST_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.SUCCESS)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const jobTypes = useSelector(SELECTORS.JOB_TYPES)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const getImports = body => {
    body.asyncJobActions = [1]
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(IMPORTS_ACTIONS.GET({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getImports(body)
  }

  const handleClickCloseFilters = () => {
    dispatch(IMPORTS_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 2, token }
      dispatch(IMPORTS_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => ({
      id: item.id,
      code: item.id,
      jobType: item.jobType,
      dateStarted: formatDateBasic(item.dateStarted, 'short', false, false, true),
      dateFinished: formatDateBasic(item.dateFinished, 'short', false, false, true),
      status: item.status
    }))
    return newData
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => col.name)
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getImports(body)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getImports(body)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getImports(body)
  }

  const getImport = id => {
    history.push(`${SITE.SETTING}${SITE.IMPORTS}${SITE.IMPORT}/${id}`)
  }
  const handleRowClick = rowObj => getImport(rowObj.id)

  const handleToastClose = () => setIsShowing(false)

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getImports(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleUploadFile = id => {
    setMessageToast({
      title: TOAST_PROPERTIES.SUCCESS.title,
      message: MESSAGES.UPLOAD_FILE_SUCCESS,
      icon: TOAST_PROPERTIES.SUCCESS.icon,
      color: TOAST_PROPERTIES.SUCCESS.color
    })
    setIsShowing(true)
    setTimeout(() => {
      getImport(id)
    }, 2000)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setAddImportModal(null)
  }

  const handleCreateImport = () => {
    setAddImportModal({
      close: handleCloseModal,
      uploadFile: handleUploadFile,
      token: currentToken.token,
      types: jobTypes
    })
    setOpen(true)
  }

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error, showRowPointer } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
        showRowPointer={showRowPointer}
        timeDate
      />
    )
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  useEffect(() => {
    dispatch(IMPORTS_ACTIONS.CLEAR())
    const body = {
      pageNumber: 1,
      pageSize: selectedOption.value,
      orderByColumn: 'Id',
      orderByDesc: true
    }
    setActiveFilters(body)
    getImports(body)
  }, [key])

  useEffect(() => {
    setTablelist(customDataTable(items))
    setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
    const firtCrumb = { text: TRANSLATION_OF_ROUTES.SETTING, link: true, url: SITE.SETTING }
    const secondCrumb = { text: TRANSLATION_OF_ROUTES.IMPORTS, link: false, url: SITE.SETTING }
    setBreadcrumbs([firtCrumb, secondCrumb])
  }, [items, totalPages])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          orderByColumn: 'Id',
          orderByDesc: true
        }
        getImports(body)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(MESSAGES.EXPIRED_TOKEN, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <Wrapper>
            {breadcrumbs
              && (
              <WrapperBreadcrumbs>
                <Breadcrumbs crumbs={breadcrumbs} />
              </WrapperBreadcrumbs>
              )}
            <WrapperHeading columnStart='1' columnEnd='12' rowStart='2' rowEnd='2'>
              <Heading
                type='h2'
                weight='bold'
                align='left'
              >
                {TRANSLATION_OF_ROUTES.IMPORTS}
              </Heading>
            </WrapperHeading>
            <FilterContainer columnStart={1} columnEnd={12} rowStart={5} rowEnd={5} deviceName={deviceName}>
              <FilterContainerLeftSide deviceName={deviceName}>
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Filtro'
                  icon='filter_alt'
                  onClick={handleClickShowFilters}
                />
                {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                {showFilter && (filterData.length > 0 && renderFilter(filterData))}
              </FilterContainerLeftSide>
              <FilterContainerRightSide deviceName={deviceName}>
                <SearchBar onChange={handleInputChange} value={activeFilters?.filterText} />
                <GhostButton
                  color='tertiary'
                  size='small'
                  text='Agregar Importación +'
                  onClick={handleCreateImport}
                />
              </FilterContainerRightSide>
            </FilterContainer>
            <TableWrapper rowStart='4' rowEnd='4' columnStart='1' columnEnd='12'>
              {renderTable({
                currentList: tablelist,
                columnsN: columns,
                currentLoading: listLoading,
                handleOrder: handleOrderChange,
                handleSelectedRow: handleRowClick,
                error: !!errorMessage,
                showRowPointer: true
              })}
            </TableWrapper>
            <PaginationWrapper rowStart='5' rowEnd='5' columnStart='1' columnEnd='12'>
              {tablelist.length > 0
              && (
              <PaginationGroup
                deviceName={deviceName}
                pages={pages}
                itemSelected={itemSelected}
                handleClickPaginationItem={n => handleClickPaginationItem(
                  n, activeFilters, selectedOption.value, getImports, setItemSelected
                )}
                options={rowsPerPage}
                selectedOption={selectedOption}
                handleDropdownChange={handleDropdownChange}
                totalItems={totalItems}
                itemsPerPage={tablelist.length}
              />
              )}
            </PaginationWrapper>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
              timeOut={messageToast.title === TOAST_PROPERTIES.ERROR.title ? 3000 : 2000}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Agregar Importación'
              minHeight='380px'
            >
              {addImportModal && (<AddFileImportModal {...addImportModal} />)}
            </Modal>
          </Wrapper>
        )}
    </>
  )
}

export default Imports
