export const key = 'HOME'

export const HOME = {
  GET_DATA_HOME: 'HOME/GET/DATA',
  SUCCESS: 'HOME/GET_LIST/SUCCESS',
  ERROR: 'HOME/GET_LIST/ERROR',
  CLEAR: 'HOME/CLEAR_LIST'
}

export default key
