export const columnData = [
  { name: 'Código', widthCol: '15%', label: 'Id' },
  { name: 'Persona', widthCol: '35%', label: 'Person' },
  { name: 'Concepto', widthCol: '20%', label: 'Concept' },
  { name: 'Entidad', widthCol: '20%', label: 'Entity' },
  { name: 'Divisa', widthCol: '5%', label: 'Currency' },
  { name: 'Monto', widthCol: '10%', label: 'Total' },
  { name: 'Estado', widthCol: '10%', label: 'Status' },
  { name: 'F. de vencimiento', widthCol: '25%', label: 'DueDate' },
  { name: 'Descripción', widthCol: '15%', label: 'Description' },
  { name: 'Detalle de ingreso', widthCol: '20%', label: 'ContributionPayment' },
  { name: 'Monto cobrado', widthCol: '15%', label: 'TotalPaid' }
]
