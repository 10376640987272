import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold } from '@global/fonts'
import { MyButtonLink } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

export const PersonalData = styled.table`
`
export const PaymentSettlementProfileData = styled(PersonalData)`
${roboto};
${regular};
width: fit-content;
height: auto;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ widthPer }) => (widthPer ? '60%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};
`

export const ItemLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  text-align: left;
  margin-right: ${({ marginRight }) => (marginRight ? spacing.half : 0)};
`
export const StatusConatainer = styled.div`
  display: flex;
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'column' : 'row')};
  justify-content: center;
  align-items: baseline;
  & > :first-child {
    margin-right: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.one)};
    p > {
      color: 'red';
    }
  }

  & > :last-child {
    margin-left: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 0 : spacing.one)};
  }
`

export const StatuRecordContainer = styled.div`
  display: flex;
  align-items: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'center' : 'flex-start')};
  flex-direction: ${({ device }) => (device === BREAKPOINTS.MOBILE ? 'row' : 'column')};
  padding: ${spacing.one} 0;
  width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : 'min-content')};

  & > :last-child {
    width: ${({ device }) => (device === BREAKPOINTS.MOBILE ? '100%' : 'auto')};
  }
`
