export const key = 'AFFILIATE_RECORD'

export const AFFILIATE_RECORD = {
  GET_AFFILIATE_RECORD: 'AFFILIATE_RECORD/GET_AFFILIATE_RECORD',
  GET_AFFILIATE_RECORD_SUCCESS: 'AFFILIATE_RECORD/GET_AFFILIATE_RECORD_SUCCESS',
  GET_AFFILIATE_RECORD_ERROR: 'AFFILIATE_RECORD/GET_AFFILIATE_RECORD_ERROR',
  CLEAR: 'AFFILIATE_RECORD/CLEAR',

  UPDATE_BENEFITS: 'AFFILIATE_RECORD/UPDATE_BENEFITS',
  SET_BENEFIT: 'AFFILIATE_RECORD/SET_BENEFIT',
  BENEFITS_RESPONSE: 'AFFILIATE_RECORD/BENEFITS_RESPONSE',

  GET_CONTRIBUTIONS: 'AFFILIATE_RECORD/GET_CONTRIBUTIONS',
  GET_CONTRIBUTIONS_SUCCESS: 'AFFILIATE_RECORD/GET_CONTRIBUTIONS_SUCCESS',
  GET_CONTRIBUTIONS_ERROR: 'AFFILIATE_RECORD/GET_CONTRIBUTIONS_ERROR',

  SEND_FILTER: 'AFFILIATE_RECORD/FILTER/SEND',
  GET_FILTER_DATA: 'AFFILIATE_RECORD/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'AFFILIATE_RECORD/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'AFFILIATE_RECORD/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'AFFILIATE_RECORD/FILTER/CLEAR',

  GET_MODAL_DATA: 'AFFILIATE_RECORD/MODAL/GET_MODAL_DATA',
  MODAL_DATA_RESPONSE: 'AFFILIATE_RECORD/MODAL/DATA_RESPONSE',
  GET_DATA_EDIT_PROFILE: 'AFFILIATE_RECORD/MODAL/GET_DATA_EDIT_PROFILE',
  CLEAR_DATA_MODAL: 'AFFILIATE_RECORD/MODAL/CLEAR_DATA_MODAL',

  EDIT_PROFILE: 'AFFILIATE_RECORD/PROFILE/EDIT',
  UPDATE_PROFILE: 'AFFILIATE_RECORD/PROFILE/UPDATE',
  EDIT_PROFILE_RESPONSE: 'AFFILIATE_RECORD/PROFILE/EDIT_RESPONSE',

  GET_PERSON_DATA: 'AFFILIATE_RECORD/GET_PERSON_DATA',
  GET_PERSON_DATA_SUCCESS: 'AFFILIATE_RECORD/GET_PERSON_DATA_SUCCESS',
  GET_PERSON_DATA_ERROR: 'AFFILIATE_RECORD/GET_PERSON_DATA_ERROR',
  DATA_UPLOAD_READY: 'AFFILIATE_RECORD/DATA_UPLOAD_READY',

  GET_CONTRIBUTION_PAYMENTS: 'AFFILIATE_RECORD/GET_CONTRIBUTION_PAYMENTS',
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: 'AFFILIATE_RECORD/GET_CONTRIBUTION_PAYMENTS_SUCCESS',
  GET_CONTRIBUTION_PAYMENTS_ERROR: 'AFFILIATE_RECORD/GET_CONTRIBUTION_PAYMENTS_ERROR'
}

export default key
