import React from 'react'
import PropTypes from 'prop-types'

import { BREAKPOINTS } from '@global/constants'

import { SolidButton } from '@components/button'
import { EmailInput, PasswordInput } from '@components/inputs/inputs'

const Form = ({ value, onChange, onBlur, onSubmit, error, device, onKeyDown }) => (
  <>
    <EmailInput
      value={value.email}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      name='email'
      label='Correo electrónico'
      placeholder='Ingrese su electrónico'
      color='primary'
      bits={[{ icon: 'mail', position: 'left', colorBit: 'primary' }]}
      error={error}
      required
    />
    <PasswordInput
      name='password'
      label='Contraseña'
      placeholder='Ingrese su contraseña'
      value={value.password}
      onChange={onChange}
      onKeyDown={onKeyDown}
      color='primary'
      bits={[{ icon: 'password', position: 'left', colorBit: 'primary' }]}
      required
    />
    <SolidButton
      block
      color='primary'
      size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
      text='Login'
      onClick={onSubmit}
    />
  </>
)

export default Form

Form.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  device: PropTypes.number
}
