import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@routes/utils/privateRoute'
import { SITE } from '@routes/paths'

import Income from '@containers/income'
import DepositProfile from '@containers/depositProfile'
import ContributionPayment from '@containers/contributionPayment'

export const IncomeRouter = () => {
  const { url } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={url} component={Income} />
      <PrivateRoute
        exact
        path={`${url}${SITE.DEPOSIT_PROFILE}/:depositId`}
        component={DepositProfile}
      />

      <PrivateRoute
        exact
        path={`${url}${SITE.DEPOSIT_PROFILE}/:depositId${SITE.CONTRIBUTION_PAYMENT}/:contributionPaymentId`}
        component={ContributionPayment}
      />
    </Switch>
  )
}
