import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectLiquidationLog = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectLiquidationLog, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectLiquidationLog, state => get(state, 'liquidationLog', null)
)

const selectLiquidationLogStatuses = createSelector(
  selectLiquidationLog, state => get(state, 'liquidationLogStatuses', null)
)

const selectError = createSelector(
  selectLiquidationLog, state => get(state, 'error', null)
)

const selectLoadingProfile = createSelector(
  selectLiquidationLog, state => get(state, 'loadingEditProfile', [])
)

const selectListLoading = createSelector(
  selectLiquidationLog, state => get(state, 'listLoading', null)
)

const selectContributionsSuccess = createSelector(
  selectLiquidationLog, state => get(state, 'contributionsList', [])
)

const selectColumns = createSelector(
  selectLiquidationLog, state => get(state, 'columns', [])
)

const selectPages = createSelector(
  selectLiquidationLog, state => get(state, 'pages', null)
)

const selectTotalItems = createSelector(
  selectLiquidationLog, state => get(state, 'totalItems', 0)
)

const selectDataFilters = createSelector(
  selectLiquidationLog, state => get(state, 'dataFilters', [])
)

const selectDataFiltersError = createSelector(
  selectLiquidationLog, state => get(state, 'dataFiltersError', null)
)

const selectShowTableLoading = createSelector(
  selectLiquidationLog, state => get(state, 'showTableLoading', null)
)

const selectModalData = createSelector(
  selectLiquidationLog, state => get(state, 'modalData', null)
)

const selectMessageInformation = createSelector(
  selectLiquidationLog, state => get(state, 'messageInformation', null)
)

const selectLoadingCalculateContributions = createSelector(
  selectLiquidationLog, state => get(state, 'loadingCalculateContributions', null)
)

const selectCalculateInformation = createSelector(
  selectLiquidationLog, state => get(state, 'calculateInformation', null)
)

const selectCurrencies = createSelector(
  selectLiquidationLog, state => get(state, 'currencies', null)
)

const selectContributionsError = createSelector(
  selectLiquidationLog, state => get(state, 'contributionsError', null)
)

const selectIsCalculatingInfo = createSelector(
  selectLiquidationLog, state => get(state, 'isCalculatingInfo', null)
)

const selectIsChangingStatusInfo = createSelector(
  selectLiquidationLog, state => get(state, 'isChangingStatusInfo', null)
)

export default {
  SUCCESS: selectSuccess,
  LIQUIDATION_LOG_STATUSES: selectLiquidationLogStatuses,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_PROFILE: selectLoadingProfile,

  LOADING_CALCULATE_CONTRIBUTIONS: selectLoadingCalculateContributions,
  CALCULATE_CONTRIBUTIONS_INFORMATION: selectCalculateInformation,

  CONTRIBUTIONS_SUCCESS: selectContributionsSuccess,
  CONTRIBUTIONS_ERROR: selectContributionsError,
  COLUMNS: selectColumns,
  LIST_LOADING: selectListLoading,
  PAGES: selectPages,
  TOTAL_ITEMS: selectTotalItems,
  FILTER_OBJECT: selectDataFilters,
  ERROR_FILTER_OBJECT: selectDataFiltersError,

  SHOW_TABLE_LOADING: selectShowTableLoading,
  MODAL_DATA_INFORMATION: selectModalData,
  MESSAGE_INFORMATION: selectMessageInformation,
  CURRENCIES_INFORMATION: selectCurrencies,

  IS_CALCULATING_INFO: selectIsCalculatingInfo,
  IS_CHANGING_STATUS_INFO: selectIsChangingStatusInfo
}
