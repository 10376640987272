import styled from 'styled-components'

import { colors } from '@global/theme'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  pointer-events: ${({ disabled }) => disabled && 'pointer-events-none'};
  cursor: ${({ onClick }) => onClick && 'cursor-pointer'};

  > * {
    color: ${({ color, disabled }) => (disabled ? colors[color]?.disabled : colors[color]?.regular)};
  }

`
export const Img = styled.img`
  width: ${({ size }) => (size || '20px')};
  height: ${({ size }) => (size || '20px')};
  ${({ disabled }) => disabled && 'filter:  grayscale(100%) opacity(25%)'};
`
