import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { WrapperBreadcrumbs, WrapperLoading, DataContainer, ItemLink } from '@global/styles'
import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  ACTIONS_TYPES,
  ENTITIES_TYPES,
  ENTITY_COLUMNS_NAMES
} from '@global/constants'
import {
  TOKEN_HELPERS,
  formatDateBasic,
  customEntityPayload,
  compareHistoryArrays,
  createBreadcrumb
} from '@utils/helpers'
import { SITE } from '@routes/paths'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import Icon from '@components/icon'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import Badge from '@components/badge'
import useRedux from './redux'
import { HISTORY_RECORD_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, HistoryRecordData, PersonalData, Tbody, FileContainer } from './styles'

const HistoryLog = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [historyRecord, setHistoryRecord] = useState(null)
  const [errorFlag, setErrorFlag] = useState(false)

  const loading = useSelector(SELECTORS.LOADING)
  const historyRecordData = useSelector(SELECTORS.SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const fileMessage = useSelector(SELECTORS.FILE_MESSAGE) // downloaded file
  const loadingFile = useSelector(SELECTORS.LOADING_FILE)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const renderHistoryRecordData = objHistoryRecordData => (
    <>
      <Tr>
        <Td colorLight widthPer>Código:</Td>
        <Td largeSize>{objHistoryRecordData.id || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Fecha y Hora:</Td>
        <Td largeSize>{formatDateBasic(objHistoryRecordData.date, 'short', false, false, true) || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Usuario:</Td>
        <Td largeSize>{objHistoryRecordData.user || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Tipo de entidad:</Td>
        <Td largeSize weight='bold'>{objHistoryRecordData.entityType
          ? ENTITIES_TYPES[objHistoryRecordData.entityType]?.label.toUpperCase()
          : 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Código de entidad:</Td>
        <Td largeSize>{objHistoryRecordData.entityId || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td>Descripción:</Td>
        <Td largeSize>{objHistoryRecordData.description
          ? (
            <Badge
              text={ACTIONS_TYPES[objHistoryRecordData.description]}
              color='system'
              backgroundColor='secondary'
              size='medium'
            />
          )
          : 'N/A'}
        </Td>
      </Tr>
    </>
  )

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleEmplymentRecordClick = emplymentRecordId => {
    if (emplymentRecordId) {
      const win = window.open(`${SITE.EMPLOYMENT_RECORD}/${emplymentRecordId}`, '_blank')
      win.focus()
    }
  }

  const handleSettlementClick = settlementId => {
    if (settlementId) {
      const win = window.open(`${SITE.LIQUIDATION_LOG}/${settlementId}`, '_blank')
      win.focus()
    }
  }

  const handleOutboundSettlementClick = outboundSettlementId => {
    if (outboundSettlementId) {
      const win = window.open(`${SITE.PAYMENT_SETTLEMENT_PROFILE}/${outboundSettlementId}`, '_blank')
      win.focus()
    }
  }

  const handleContributionClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PROFILE}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const bmsFixed = (itemId, itemValue) => {
    if (itemId === ENTITY_COLUMNS_NAMES.BMSBonusCoefficient.name
        || itemId === ENTITY_COLUMNS_NAMES.BMSDelta.name
        || itemId === ENTITY_COLUMNS_NAMES.BMSInbound.name
        || itemId === ENTITY_COLUMNS_NAMES.BMSAvailable.name
        || itemId === ENTITY_COLUMNS_NAMES.BMSOutbound.name
        || itemId === ENTITY_COLUMNS_NAMES.BMSInboundWithBonus.name) {
      return Number(itemValue).toFixed(2)
    }
    return itemValue
  }

  const handleDownloadFile = fileId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(HISTORY_RECORD_ACTIONS.DOWNLOAD_FILE({ fileId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const renderEntitylData = (currentObjEntityData, discriminatorValue, oldObjEntityData) => {
    if (currentObjEntityData) {
      let differentValues = null
      let arrOldPersonalData = null

      const arrCurrentPersonalData = customEntityPayload(currentObjEntityData, discriminatorValue)
      if (oldObjEntityData) {
        arrOldPersonalData = customEntityPayload(oldObjEntityData, discriminatorValue)
        differentValues = compareHistoryArrays(arrCurrentPersonalData, arrOldPersonalData)
      }

      return arrCurrentPersonalData.map(item => {
        const { label, id } = item
        let { value } = item

        // NUMBER 0
        if ((!value && value === 0 && !Number.isNaN(value))) value = value.toString()

        if (!value && id !== ENTITY_COLUMNS_NAMES.IsPrimary.name && id !== ENTITY_COLUMNS_NAMES.Deleted.name) return
        if (label === 'Id') return

        if (Array.isArray(value)) {
          value = value.length > 0 ? value : null
        }

        const weight = false
        const colorLight = true

        if (id === ENTITY_COLUMNS_NAMES.Person.name && value) {
          const { BillingNumber, FullName, Id } = value
          value = (
            <ItemLink
              color='tertiary'
              underline
              onClick={() => handlePersonClick(Id)}
            >
              {`${BillingNumber} - ${FullName}`}
            </ItemLink>
          )
        }

        if (id === ENTITY_COLUMNS_NAMES.EmploymentRecord.name && value) {
          const empRecId = value
          value = (
            <ItemLink
              color='tertiary'
              underline
              onClick={() => handleEmplymentRecordClick(empRecId)}
            >
              {`${value}`}
            </ItemLink>
          )
        }

        if (id === 19 && id === 37 && typeof value === 'object') {
          value = null
        }

        if (id === ENTITY_COLUMNS_NAMES.Settlement.name && value) {
          const settlementId = value
          value = (
            <ItemLink
              color='tertiary'
              underline
              onClick={() => handleSettlementClick(settlementId)}
            >
              {`LI - ${settlementId}`}
            </ItemLink>
          )
        }

        if (id === ENTITY_COLUMNS_NAMES.OutboundSettlementId.name && value) {
          const outboundSettlementId = value
          value = (
            <ItemLink
              color='tertiary'
              underline
              onClick={() => handleOutboundSettlementClick(outboundSettlementId)}
            >
              {`LP - ${outboundSettlementId}`}
            </ItemLink>
          )
        }

        if (id === ENTITY_COLUMNS_NAMES.Contribution.name && value) {
          const contributionId = value
          value = (
            <ItemLink
              color='tertiary'
              underline
              onClick={() => handleContributionClick(contributionId)}
            >
              {`C - ${contributionId}`}
            </ItemLink>
          )
        }
        // probar esto con address o file
        if (id === ENTITY_COLUMNS_NAMES.IsPrimary.name
          || id === ENTITY_COLUMNS_NAMES.Deleted.name
          || typeof value === 'boolean') {
          value = value ? 'Si' : 'No'
        }

        let previousValue = differentValues ? differentValues.find(pv => pv.id === item.id) : null

        if (id === ENTITY_COLUMNS_NAMES.IsPrimary.name
          || id === ENTITY_COLUMNS_NAMES.Deleted.name
          || typeof value === 'boolean') {
          previousValue = {
            ...previousValue,
            value: previousValue?.value ? 'Si' : 'No'
          }
        }

        value = bmsFixed(id, value)
        previousValue = {
          ...previousValue,
          value: bmsFixed(previousValue?.id, previousValue?.value)
        }

        let discriminatorFile = {
          exist: false
        }
        if (id === ENTITIES_TYPES.PersonFile.name
          || id === ENTITIES_TYPES.FRMRecordFile.name
          || id === ENTITIES_TYPES.File.name) {
          discriminatorFile = {
            exist: true,
            id: value.Id
          }
          value = value.Name
        }

        return (
          <Tr key={item.id}>
            <Td weight={weight} colorLight={colorLight}>{item.label}:</Td>
            <Td largeSize>{value || 'N/A'}</Td>
            {discriminatorFile?.exist
              && (
                <Td largeSize>
                  <FileContainer onClick={() => handleDownloadFile(discriminatorFile?.id)}>
                    <Icon
                      name={loadingFile ? 'sync' : 'archive'}
                      size='large'
                      color='primary'
                      spin={loadingFile}
                    />
                    <Text size='large' weight='regular' align='left'>Descargar</Text>
                  </FileContainer>
                </Td>
              )}
            {previousValue?.id === item.id
             && (
             <Td largeSize>
               <Badge
                 text={`Valor anterior: ${previousValue?.value === null ? 'N/A' : previousValue?.value}`}
                 color='system'
                 backgroundColor='secondary'
                 size='small'
               />
             </Td>
             )}
          </Tr>
        )
      })
    }
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      let historyId = null
      if (state) historyId = state.historyRecordId
      else {
        const pathnameArr = pathname.split('/')
        historyId = pathnameArr[pathnameArr.length - 1]
      }
      dispatch(HISTORY_RECORD_ACTIONS.GET_HISTORY_RECORD({ historyId, token }))
      if (state) {
        const lastCrumb = {
          link: true,
          text: TRANSLATION_OF_ROUTES.HISTORY_LOG,
          url: ''
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        setBreadcrumbs(createBreadcrumb(pathname))
      }
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (historyRecordData) {
      setHistoryRecord(historyRecordData)
    }
  }, [historyRecordData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  useEffect(() => {
    if (!fileMessage) return
    if (fileMessage.status === 200 && fileMessage.data) {
      const { data, contentType } = fileMessage
      let { fileName } = fileMessage
      // remove the \&quot
      fileName = fileName.replace(/[^a-zA-Z0-9 .]/g, '')

      const blob = new Blob([data], { type: contentType })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
  }, [fileMessage])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          let historyId = null
          if (state) historyId = state.historyRecordId
          else {
            const pathnameArr = pathname.split('/')
            historyId = pathnameArr[pathnameArr.length - 1]
          }
          dispatch(HISTORY_RECORD_ACTIONS.GET_HISTORY_RECORD({ historyId, token }))
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
        setErrorFlag(true)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <DataContainer columnStart='1' columnEnd='12' rowStart='2' rowEnd='2' marginBottom='24px'>
              <HistoryRecordData>
                <Tbody>
                  {historyRecord && renderHistoryRecordData(historyRecord.historyRecord)}
                </Tbody>
              </HistoryRecordData>
            </DataContainer>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px'>
              <PersonalData>
                <Tbody>
                  {historyRecord
                    && renderEntitylData(
                      historyRecord.entityData, historyRecord.historyRecord.discriminator, historyRecord.original
                    )}
                </Tbody>
              </PersonalData>
            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
          </>
        )}
    </>
  )
}

export default HistoryLog
