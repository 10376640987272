import produce from 'immer'

import { PAYMENT_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  paymentProfile: null,
  paymentProfileStatuses: null,
  banks: null,
  loadingEditProfile: false,
  paymentDetailsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  listLoading: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  errorPaymentsDetails: null,
  modalDataLoading: false,
  messageInformation: null,
  bankAccounts: null,
  bankAccountLoading: null,
  outboundSettlementDetailInformation: null,
  outboundSettlementDetailLoading: null,
  concepts: null,
  loadingCalculate: null,
  calculateInformation: null,
  isCalculatingInfo: null,
  isChangingStatusInfo: null,
  noBankAccountListLoading: false,
  noBankAccountList: null,
  noBankAccountListError: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PAYMENT_PROFILE.GET_PAYMENT_PROFILE:
      draft.loading = true
      break
    case PAYMENT_PROFILE.SUCCESS: {
      const { paymentProfileObj, statuses, banks } = payload
      draft.loading = false
      draft.paymentProfile = paymentProfileObj
      draft.paymentProfileStatuses = statuses
      draft.banks = banks
      break
    }
    case PAYMENT_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case PAYMENT_PROFILE.EDIT_PAYMENT_PROFILE:
      draft.loadingEditProfile = true
      break
    case PAYMENT_PROFILE.EDIT_PAYMENT_PROFILE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingEditProfile = false
      break
    case PAYMENT_PROFILE.PAYMENT_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.paymentProfile = payload
      break
    case PAYMENT_PROFILE.CLEAR:
      return initialState
    case PAYMENT_PROFILE.GET_PAYMENT_DETAILS:
      draft.listLoading = true
      break
    case PAYMENT_PROFILE.PAYMENT_DETAILS_SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage } = payload
      draft.listLoading = false
      draft.paymentDetailsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.pages = { totalPages, currentPage }
    }
      break
    case PAYMENT_PROFILE.PAYMENT_DETAILS_ERROR:
      draft.listLoading = false
      draft.errorPaymentsDetails = payload
      break
    case PAYMENT_PROFILE.PAYMENT_DETAILS_UPDATE:
      draft.listLoading = true
      break
    case PAYMENT_PROFILE.PAYMENT_DETAILS_CLEAR:
      return initialState
    case PAYMENT_PROFILE.SEND_FILTER:
      draft.loading = true
      break
    case PAYMENT_PROFILE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PAYMENT_PROFILE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PAYMENT_PROFILE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PAYMENT_PROFILE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // BANK ACCOUNTS
    case PAYMENT_PROFILE.GET_BANK_ACCOUNTS:
      draft.bankAccountLoading = true
      break
    case PAYMENT_PROFILE.BANK_ACCOUNTS_INFORMATION:
      draft.bankAccountLoading = false
      draft.bankAccounts = payload
      break
    case PAYMENT_PROFILE.CLEAR_BANK_ACCOUNTS:
      draft.bankAccounts = null
      break
    // MODAL
    case PAYMENT_PROFILE.GET_OUTBOUND_SETTLEMENT_DETAIL:
      draft.modalDataLoading = true
      draft.outboundSettlementDetailLoading = true
      break
    case PAYMENT_PROFILE.OUTBOUND_SETTLEMENT_DETAIL_INFORMATION:
      draft.modalDataLoading = false
      draft.outboundSettlementDetailLoading = false
      draft.outboundSettlementDetailInformation = payload
      break
    // PAYMENT DETAIL
    case PAYMENT_PROFILE.ADD_PAYMENT_DETAIL:
      draft.listLoading = true
      break
    case PAYMENT_PROFILE.MESSAGE_INFORMATION:
      draft.messageInformation = payload
      break
    // CONCEPTS
    case PAYMENT_PROFILE.CONCEPTS_INFORMATION:
      draft.concepts = payload
      break
    // CALCULATE
    case PAYMENT_PROFILE.CALCULATE_OUTBOUND_PAYMENTS_DETAILS:
      draft.loadingCalculate = true
      break
    case PAYMENT_PROFILE.CALCULATE_OUTBOUND_PAYMENTS_DETAILS_INFORMATION:
      draft.loadingCalculate = false
      draft.calculateInformation = payload
      break
    case PAYMENT_PROFILE.MESSAGE_INFORMATION_CLEAR:
      draft.messageInformation = null
      break
    // PENDING PROCESS
    case PAYMENT_PROFILE.PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    case PAYMENT_PROFILE.GET_PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    case PAYMENT_PROFILE.GET_NOT_BANK_ACCOUNTS:
      draft.noBankAccountListLoading = true
      break
    case PAYMENT_PROFILE.NO_BANK_ACCOUNTS_SUCCESS:
      draft.noBankAccountListLoading = false
      draft.noBankAccountList = payload
      break
    case PAYMENT_PROFILE.NO_BANK_ACCOUNTS_ERROR:
      draft.noBankAccountListLoading = false
      draft.noBankAccountListError = payload
      break
    case PAYMENT_PROFILE.CLEAR_NO_BANK_ACCOUNT_ERROR:
      draft.noBankAccountListError = null
      break
    default:
      return initialState
  }
})

export default reducer
