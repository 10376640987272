import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectEmployeeHistory = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectEmployeeHistory, state => get(state, 'loading', null)
)

const selectSuccess = createSelector(
  selectEmployeeHistory, state => get(state, 'list', null)
)

const selectError = createSelector(
  selectEmployeeHistory, state => get(state, 'error', null)
)

const selectLoadingList = createSelector(
  selectEmployeeHistory, state => get(state, 'loadingList', null)
)

export default {
  SUCCESS: selectSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingList
}
