import produce from 'immer'

import { IMPORTS } from './types'

export const initialState = {
  loading: true,
  listLoading: false,
  error: false,
  importsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  jobTypes: [],
  uploadFileInfo: null,
  addPersonInformation: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case IMPORTS.GET:
      draft.listLoading = true
      break
    case IMPORTS.SUCCESS: {
      const { items, fields, totalItems, totalPages, currentPage, jobTypes } = payload
      draft.loading = false
      draft.listLoading = false
      draft.importsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.jobTypes = jobTypes
      draft.pages = { totalPages, currentPage }
    }
      break
    case IMPORTS.ERROR:
      draft.loading = false
      draft.listLoading = false
      draft.error = payload
      break
    case IMPORTS.CLEAR:
      return initialState
    case IMPORTS.SEND_FILTER:
      draft.loading = true
      break
    case IMPORTS.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case IMPORTS.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case IMPORTS.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case IMPORTS.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    case IMPORTS.UPLOAD_FILE_INFO:
      draft.loading = false
      draft.uploadFileInfo = payload
      break
    case IMPORTS.UPLOAD_FILE_INFO_CLEAR:
      draft.uploadFileInfo = null
      break
    default:
      return initialState
  }
})

export default reducer
