import React from 'react'
import PropTypes from 'prop-types'

import { TextInput } from '@components/inputs/inputs'
import Icon from '@components/icon'

import { WrapperInput } from '../../../styles'

const SELECT_ROW = 'Seleccione una fila de la tabla'

const ContributionForm = ({ inputState, setState }) => {
  const handleClick = () => {
    setState({
      contributionId: SELECT_ROW,
      settlement: SELECT_ROW
    })
  }
  return (
    <>
      <WrapperInput>
        <TextInput
          name='contribution'
          label='Contribución'
          value={inputState?.contributionId}
          height='small'
          color='primary'
          onChange={() => {}}
          bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          disabled
          isClearable
        />
        {inputState?.contributionId !== SELECT_ROW
        && <Icon name='close' color='tertiary' size='large' onClick={handleClick} />}
      </WrapperInput>
      <TextInput
        name='settlement'
        label='Liquidación'
        value={inputState?.settlement
          ? `${inputState?.settlement}`
          : ''}
        height='small'
        color='primary'
        onChange={() => {}}
        bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
        disabled
      />
    </>
  )
}

ContributionForm.propTypes = {
  inputState: PropTypes.object,
  setState: PropTypes.func
}

export default ContributionForm
