import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectNoEmployeeFee = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectNoEmployeeFee, state => get(state, 'loading', null)
)

const selectFeeHistorySuccess = createSelector(
  selectNoEmployeeFee, state => get(state, 'historyFee', null)
)

const selectMessageInfo = createSelector(
  selectNoEmployeeFee, state => get(state, 'messageInformation', null)
)

const selectMessageError = createSelector(
  selectNoEmployeeFee, state => get(state, 'erroMessage', null)
)

const selectLoadingList = createSelector(
  selectNoEmployeeFee, state => get(state, 'loadingList', null)
)

export default {
  FEE_HISTORY_SUCCESS: selectFeeHistorySuccess,
  MESSAGE_INFORMATION: selectMessageInfo,
  MESSAGE_ERROR: selectMessageError,
  LOADING: selectLoading,
  LOADING_LIST: selectLoadingList
}
