import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold } from '@global/fonts'
import { MyButtonLink } from '@global/styles'

export const PersonalData = styled.table`
${roboto};
${regular};
width: fit-content;
height: auto;
`
export const EmploymentRecordProfileData = styled(PersonalData)`
  flex: 1;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ width }) => (width ? '50%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};
`

export const CustomButtonLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
`
export const FilterContainer = styled.div`
  grid-column-start: ${({ columnStart }) => (columnStart || 0)};
  grid-column-end: ${({ columnEnd }) => (columnEnd || 0)};
  grid-row-start: ${({ rowStart }) => (rowStart || 0)};
  grid-row-end: ${({ rowEnd }) => (rowEnd || 0)};
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin: ${({ margin }) => margin || `${spacing.three} 0`} ;
`

export const TableWrapperHistory = styled.div`
  grid-column-start: 1;
  grid-column-end: 12;
  grid-row-start: 4;
  grid-row-end: 4;
  margin-bottom: ${spacing.three};
`
