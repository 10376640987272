import produce from 'immer'

import { PAYMENT_SETTLEMENT_PROFILE } from './types'

export const initialState = {
  loading: null,
  error: false,
  paymentSettlementProfile: null,
  paymentSettlementProfileStatuses: null,
  loadingEditProfile: false,
  debitsList: [],
  columns: [],
  pages: { totalPages: 0, currentPage: 0 },
  totalItems: 0,
  debitsStatuses: null,
  dataFilters: [],
  dataFiltersError: null,
  filterLoading: null,
  errorMessage: null,
  modalDataLoading: false,
  modalData: null,
  messageInformation: null,
  entitiesInformation: null,
  servicesInformation: null,
  personInformation: null,
  currencies: null,
  currenciesLoading: null,
  loadingCalculateDebits: false,
  calculateInformation: null,
  isCalculatingInfo: null,
  isChangingStatusInfo: null
}

const reducer = (state = initialState, { type, payload }) => produce(state, draft => {
  switch (type) {
    case PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE:
      draft.loading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.SUCCESS: {
      const { paymentSettlementProfileObj, statuses } = payload
      draft.loading = false
      draft.paymentSettlementProfile = paymentSettlementProfileObj
      draft.paymentSettlementProfileStatuses = statuses
      break
    }
    case PAYMENT_SETTLEMENT_PROFILE.ERROR:
      draft.loading = false
      draft.error = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.EDIT_PAYMENT_SETTLEMENT_PROFILE:
      draft.loadingEditProfile = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.EDIT_PAYMENT_SETTLEMENT_PROFILE_INFORMATION:
      draft.messageInformation = payload
      draft.loadingEditProfile = false
      break
    case PAYMENT_SETTLEMENT_PROFILE.PAYMENT_SETTLEMENT_PROFILE_UPDATED:
      draft.loadingEditProfile = false
      draft.paymentSettlementProfile = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.CLEAR:
      return initialState
    case PAYMENT_SETTLEMENT_PROFILE.GET_PAYMENT_SETTLEMENT_PROFILE_DEBITS:
      draft.listLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.DEBITS_SUCCESS: {
      const { debitsObj, statuses } = payload
      const { items, fields, totalItems, totalPages, currentPage } = debitsObj
      draft.listLoading = false
      draft.loadingCalculateDebits = false
      draft.debitsList = items
      draft.columns = fields
      draft.totalItems = totalItems
      draft.debitsStatuses = statuses
      draft.pages = { totalPages, currentPage }
    }
      break
    case PAYMENT_SETTLEMENT_PROFILE.DEBITS_ERROR:
      draft.listLoading = false
      draft.messageInformation = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.DEBITS_UPDATE:
      draft.listLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.DEBITS_CLEAR:
      return initialState
    // FILTER
    case PAYMENT_SETTLEMENT_PROFILE.SEND_FILTER:
      draft.loading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.GET_FILTER_DATA:
      draft.filterLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_SUCCESS:
      draft.dataFilters = payload
      draft.filterLoading = false
      break
    case PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_ERROR:
      draft.dataFiltersError = payload
      draft.filterLoading = false
      break
    case PAYMENT_SETTLEMENT_PROFILE.FILTER_DATA_CLEAR:
      draft.dataFilters = []
      draft.dataFiltersError = null
      draft.filterLoading = false
      break
    // MODAL
    case PAYMENT_SETTLEMENT_PROFILE.GET_ENTITIES_DATA:
      draft.modalDataLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.ENTITIES_INFORMATION:
      draft.modalDataLoading = false
      draft.entitiesInformation = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.GET_SERVICES_DATA:
      draft.modalDataLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.SERVICES_INFORMATION:
      draft.modalDataLoading = false
      draft.servicesInformation = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.CLEAR_SERVICES_INFORMATION:
      draft.servicesInformation = null
      break
    case PAYMENT_SETTLEMENT_PROFILE.GET_PERSON_DATA:
      draft.modalDataLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.PERSON_INFORMATION:
      draft.modalDataLoading = false
      draft.personInformation = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.GET_CURRENCIES:
      draft.currenciesLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.CURRENCIES_INFORMATION:
      draft.currenciesLoading = false
      draft.currencies = payload
      break
    // DEBT
    case PAYMENT_SETTLEMENT_PROFILE.ADD_DEBT:
      draft.listLoading = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.ADD_DEBT_INFORMATION:
      draft.messageInformation = payload
      break
    case PAYMENT_SETTLEMENT_PROFILE.MESSAGE_INFORMATION_CLEAR:
      draft.messageInformation = null
      break
    // CALCULATE DEBITS
    case PAYMENT_SETTLEMENT_PROFILE.CALCULATE_DETAILS:
      draft.loadingCalculateDebits = true
      break
    case PAYMENT_SETTLEMENT_PROFILE.CALCULATE_DETAILS_INFORMATION:
      draft.calculateInformation = payload
      draft.loadingCalculateDebits = false
      break
    // PENDING PROCESS
    case PAYMENT_SETTLEMENT_PROFILE.PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    case PAYMENT_SETTLEMENT_PROFILE.GET_PENDING_PROCESS_INFO: {
      const { status, isCalculating, isChangingStatus, progressPercentage } = payload
      draft.isCalculatingInfo = { status, isCalculating, progressPercentage }
      draft.isChangingStatusInfo = { status, isChangingStatus, progressPercentage }
    }
      break
    default:
      return initialState
  }
})

export default reducer
