import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'
import { FILE_NOT_ACCEPTED } from '@global/constants'

import { ACCEPTED_FILE_TYPES } from '@components/fileUploader/data'
import { IMPORT_PROFILE_ACTIONS } from './actions'
import { IMPORT_PROFILE } from './types'

const apiCalls = {
  getImportProfile: ({ jobId, token }) => (
    Axios(`AsyncJob/${jobId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getAsyncJobStatuses: token => (
    Axios('AsyncJob/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFile: ({ fileId, token }) => (
    Axios(`File/${fileId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  downloadFile: ({ fileId, token }) => (
    Axios(`File/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getLogs: ({ jobId, data, token }) => (
    Axios.post(`AsyncJob/${jobId}/logs`, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getImportData ({ payload }) {
  try {
    const { token } = payload
    const res = yield call(apiCalls.getImportProfile, payload)
    const { data: statuses } = yield call(apiCalls.getAsyncJobStatuses, token)
    const { data: file } = yield call(apiCalls.getFile, { fileId: res.data.fileId, token })

    const fileName = file?.name
    const extention = fileName && `.${fileName.split('.')[1]}`
    const fileType = ACCEPTED_FILE_TYPES.find(t => t.name === extention)?.type || FILE_NOT_ACCEPTED

    let importProfile = res.data

    yield put(IMPORT_PROFILE_ACTIONS.CLEAR_PENDING_PROCESS_INFO())

    importProfile = {
      ...importProfile,
      fileType,
      fileName
    }

    const dataObj = {
      importProfile,
      statuses
    }
    yield put(IMPORT_PROFILE_ACTIONS.GET_IMPORT_SUCCESS(dataObj))
  } catch (error) {
    yield put(IMPORT_PROFILE_ACTIONS.GET_IMPORT_ERROR(error))
  }
}

export function* downloadImportFile ({ payload }) {
  try {
    const res = yield call(apiCalls.downloadFile, payload)
    const fileName = res.headers['content-disposition'].split(';')[1].split('=')[1]

    const data = {
      status: res.status,
      data: res.data,
      contentType: res.headers['content-type'],
      fileName
    }

    yield put(IMPORT_PROFILE_ACTIONS.FILE_DOWNLOADED(data))
  } catch (error) {
    yield put(IMPORT_PROFILE_ACTIONS.FILE_DOWNLOADED(error))
  }
}

export function* getLogs ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getLogs, payload)

    yield put(IMPORT_PROFILE_ACTIONS.GET_LOGS_SUCCESS(data))
  } catch (error) {
    yield put(IMPORT_PROFILE_ACTIONS.GET_LOGS_ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(IMPORT_PROFILE_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(IMPORT_PROFILE_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(IMPORT_PROFILE.GET_IMPORT, getImportData)
  yield takeLatest(IMPORT_PROFILE.DOWNLOAD_FILE, downloadImportFile)
  yield takeLatest(IMPORT_PROFILE.GET_LOGS, getLogs)
  yield takeLatest(IMPORT_PROFILE.GET_FILTER_DATA, FilterData)
}
