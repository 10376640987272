import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { PAYMENT_DETAIL_ACTIONS } from './actions'
import { PAYMENT_DETAIL } from './types'

const apiCalls = {
  getPaymentDetail: ({ paymentDetailId, token }) => (
    Axios(`OutboundPaymentDetails/${paymentDetailId}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getPaymentDetailStatuses: token => (
    Axios('InboundPayment/contributionpayment/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  editPaymentDetail: ({ paymentDetailId, data, token }) => (
    Axios.post(`OutboundPaymentDetails/${paymentDetailId}/edit`,
      data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBankAccounts: ({ personId, detailServiceId, token }) => {
    let url = 'BankAccount'
    if (personId) url += `?personId=${personId}`
    if (detailServiceId) url += `?serviceId=${detailServiceId}`
    return (
      Axios.get(url,
        {
          headers: {
            Authorization: TOKEN_HELPERS.SET_BEARER(token)
          }
        }))
  }
}

export function* getPaymentDetail ({ payload }) {
  try {
    const { token } = payload
    const { data: paymentDetailData } = yield call(apiCalls.getPaymentDetail, payload)
    const { data: statusesData } = yield call(apiCalls.getPaymentDetailStatuses, token)
    const objData = { paymentDetailData, statusesData }
    yield put(PAYMENT_DETAIL_ACTIONS.SUCCESS(objData))
  } catch (error) {
    yield put(PAYMENT_DETAIL_ACTIONS.ERROR(error))
  }
}

export function* editPaymentDetail ({ payload }) {
  try {
    const { paymentDetailId, token } = payload

    const { status } = yield call(apiCalls.editPaymentDetail, payload)
    const { data: paymentDetailUpdated } = yield call(apiCalls.getPaymentDetail, { paymentDetailId, token })

    yield put(PAYMENT_DETAIL_ACTIONS.PAYMENT_DETAIL_UPDATED(paymentDetailUpdated))
    yield put(PAYMENT_DETAIL_ACTIONS.EDIT_PAYMENT_DETAIL_INFORMATION({ status }))
  } catch (error) {
    yield put(PAYMENT_DETAIL_ACTIONS.EDIT_PAYMENT_DETAIL_INFORMATION(error))
  }
}

export function* getBankAccountsModal ({ payload }) {
  try {
    const { status, data: bankAccounts } = yield call(apiCalls.getBankAccounts, payload)

    yield put(PAYMENT_DETAIL_ACTIONS.BANK_ACCOUNTS_INFORMATION({ status, bankAccounts }))
  } catch (error) {
    yield put(PAYMENT_DETAIL_ACTIONS.BANK_ACCOUNTS_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(PAYMENT_DETAIL.GET_PAYMENT_DETAIL, getPaymentDetail)
  yield takeLatest(PAYMENT_DETAIL.EDIT_PAYMENT_DETAIL, editPaymentDetail)
  yield takeLatest(PAYMENT_DETAIL.GET_BANK_ACCOUNTS, getBankAccountsModal)
}
