import styled from 'styled-components'

import { colors, spacing } from '@global/theme'
import { roboto, regular, medium, large, bold, WEIGHT } from '@global/fonts'
import { MyButtonLink } from '@global/styles'
import { BREAKPOINTS } from '@global/constants'

export const PersonalData = styled.table`
`
export const DepositProfileData = styled(PersonalData)`
${roboto};
${regular};
width: fit-content;
height: auto;
flex: 1;
`

export const Tbody = styled.tbody`
  width: auto;
`

export const Tr = styled.tr`
  width: fit-content;
`

export const Td = styled.td` 
  width: ${({ widthPerc }) => (widthPerc ? '70%' : 'max-content')};  
  text-align: left;
  padding: ${spacing.half} ${spacing.one};
  color: ${({ colorLight }) => (colorLight ? '#828282' : colors.system.selected)};
  ${({ largeSize }) => (largeSize ? large : medium)};
  ${({ weight }) => (weight && bold)};
`

export const ItemLink = styled(MyButtonLink)`
  ${regular};
  ${medium};
  text-align: left;
`

export const ModalDataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'column' : 'row')};
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-start')};
  align-items: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'center' : 'flex-start')};
  margin-bottom: ${spacing.two};
  padding: ${({ padding }) => (padding || 0)};
  ${({ overflow }) => (`overflow: ${overflow}`)};

  * > p, label {
    ${regular};
    ${medium};
    margin-left: 0;
    letter-spacing: 0;
  }
`

export const TableContainer = styled.div`
  width: ${({ device }) => (device < BREAKPOINTS.TABLET ? '100%' : '75%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: ${({ device }) => (device < BREAKPOINTS.TABLET ? 1 : 2)};
  box-sizing: border-box;

  & > table {
    width: fit-content;
    overflow: auto;
    & > :last-child {
      & > div {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
`

export const FormContainer = styled.div`
  width: 100%;
  min-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.two)};
  margin-top: ${({ device }) => (device < BREAKPOINTS.TABLET ? spacing.two : 0)};
  box-sizing: border-box;
`

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  div > div > span:nth-child(n) {
    color: ${({ exist }) => exist && colors.tertiary.regular};
  }

  div > input:nth-child(n) {
    color: ${({ exist }) => exist && colors.primary.regular};
    font-weight: ${({ exist }) => exist && WEIGHT.BOLD};
  }

  & > :nth-child(6) {
    div > input {
      color: ${({ exist }) => exist && colors.system.regular}!important;
      font-weight: ${({ exist }) => exist && WEIGHT.REGULAR}!important;
    }
  }

  & > :last-child {
    div > input {
      color: ${({ exist }) => exist && colors.system.regular}!important;
      font-weight: ${({ exist }) => exist && WEIGHT.REGULAR}!important;
    }
  }

`
export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'row' : 'column')};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  margin-bottom: ${spacing.quarter};
  flex: ${({ flex }) => flex || 0};
`

export const DatePickerContainer = styled.div`
  display: flex;
  flex-direction: ${({ device }) => (device < BREAKPOINTS.TABLET ? 'column' : 'row')};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};

  margin-right: ${({ marginRight }) => marginRight || 0};
  margin-left: ${({ marginLeft }) => marginLeft || 0};

  flex: 1;

  & > :first-child {
    margin-right: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.one)};
  }
  & > :last-child {
    margin-left: ${({ device }) => (device < BREAKPOINTS.TABLET ? 0 : spacing.one)};
  }
`
export const WrapperInput = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  > span {
    position: relative;
    bottom: ${spacing.oneAndAQuarter};
  } 
`
