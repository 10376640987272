import { INCOME } from './types'

export const getIncome = payload => ({
  type: INCOME.GET,
  payload
})

export const getIncomeSuccess = payload => ({
  type: INCOME.SUCCESS,
  payload
})

export const getIncomeError = payload => ({
  type: INCOME.ERROR,
  payload
})

export const ClearIncomeList = payload => ({
  type: INCOME.CLEAR,
  payload
})

export const applyFilter = payload => ({
  type: INCOME.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: INCOME.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: INCOME.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: INCOME.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: INCOME.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: INCOME.GET_MODAL_DATA,
  payload
})

export const getModalDataInformation = payload => ({
  type: INCOME.MODAL_DATA_INFORMATION,
  payload
})

export const addDepositInformation = payload => ({
  type: INCOME.ADD_DEPOSIT_INFORMATION,
  payload
})

export const addDeposit = payload => ({
  type: INCOME.ADD_DEPOSIT,
  payload
})

export const INCOME_ACTIONS = {
  GET_INCOME: getIncome,
  SUCCESS: getIncomeSuccess,
  ERROR: getIncomeError,
  ACTIVITY: getIncomeError,
  CLEAR: ClearIncomeList,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  MODAL_DATA_INFORMATION: getModalDataInformation,
  ADD_DEPOSIT: addDeposit,
  ADD_DEPOSIT_INFORMATION: addDepositInformation
}
