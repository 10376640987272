import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { HISTORY_ACTIONS } from './actions'
import { HISTORY } from './types'

const apiCalls = {
  getEntity: ({ data, token }) => (
    Axios.post('History/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      }))
}

export function* getHistory ({ payload }) {
  try {
    const { data } = yield call(apiCalls.getEntity, payload)

    yield put(HISTORY_ACTIONS.SUCCESS(data))
  } catch (error) {
    yield put(HISTORY_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    yield put(HISTORY_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(HISTORY_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export default function* saga () {
  yield takeLatest(HISTORY.GET_HISTORY, getHistory)
  yield takeLatest(HISTORY.SEND_FILTER, getHistory)
  yield takeLatest(HISTORY.GET_FILTER_DATA, FilterData)
}
