import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

import Axios from '@axios/'
import { TOAST_POSITION, TOAST_PROPERTIES, BREAKPOINTS } from '@global/constants'
import { TOKEN_HELPERS } from '@utils/helpers'
import { MESSAGES } from '@global/message'

import { ModalDataContainer, ButtonContainer, TextContainerModal } from '@global/styles'

import { SolidButton } from '@components/button'
import { Dropdown, TextInput } from '@components/inputs/inputs'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Text } from '@components/texts'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'

import SelectInput from '@components/select'
import useRedux from '../../redux'
import { LIQUIDATION_LOG_ACTIONS } from '../../redux/actions'
import SELECTORS from '../../redux/selectors'

const AddContributionModal = ({ close, saveData, settlementId, currentToken }) => {
  useRedux()
  const { Logout } = useUser()
  const dispatch = useDispatch()
  const { device } = useWindowDimensions()

  const [person, setPerson] = useState(null)
  const [concepts, setConcepts] = useState([])
  const [selectedOptionConcept, setSelectedOptionConcept] = useState()
  const [showButton, setShowButton] = useState(true)
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [selectedData, setSelectedData] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [description, setDescription] = useState({
    name: '',
    value: '',
    error: ''
  })

  const modalData = useSelector(SELECTORS.MODAL_DATA_INFORMATION)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const errorControl = () => {
    if (selectedOptionConcept
      && person) setShowButton(false)
    else setShowButton(true)
  }

  const cleanFields = () => {
    setSelectedOptionConcept(null)
    setPerson(null)
    setDescription({
      value: '',
      name: '',
      error: ''
    })
  }

  const handleDropdownChange = val => {
    setSelectedOptionConcept(val.value)
    setSelectedData(val.value.data)
  }

  const handleInputChange = val => setDescription(val)

  const handleClick = () => {
    const { employmentRecordId, affiliateRecordId, frmRecordId, benefitId } = selectedData
    const newContribution = {
      employmentRecordId,
      affiliateRecordId,
      frmRecordId,
      benefitId,
      description: description.value
    }
    saveData(newContribution)
    cleanFields()
    close()
  }

  const customDropdownData = (conceptsObj, arrContributions) => {
    const { employmentRecord, affiliateRecord, frmRecord, benefits } = conceptsObj
    let id = 0
    let arrDropdown = []

    if (employmentRecord) {
      if (!arrContributions.find(c => c.entity === 'EmploymentRecord')) {
        id += 1
        arrDropdown = [
          ...arrDropdown,
          {
            id,
            label: 'Registro Empleo',
            data: {
              employmentRecordId: employmentRecord.id,
              affiliateRecordId: null,
              frmRecordId: null,
              benefitId: null
            }
          }
        ]
      }
    }
    if (affiliateRecord) {
      if (!arrContributions.find(c => c.entity === 'AffiliateRecord')) {
        id += 1
        arrDropdown = [
          ...arrDropdown,
          {
            id,
            label: 'Afiliación',
            data: {
              employmentRecordId: null,
              affiliateRecordId: affiliateRecord.id,
              frmRecordId: null,
              benefitId: null
            }
          }
        ]
      }
    }

    if (frmRecord) {
      if (!arrContributions.find(c => c.entity === 'FRMRecord')) {
        id += 1
        arrDropdown = [
          ...arrDropdown,
          {
            id,
            label: 'FRM',
            data: {
              employmentRecordId: null,
              affiliateRecordId: null,
              frmRecordId: frmRecord.id,
              benefitId: null
            }
          }
        ]
      }
    }

    if (benefits.length > 0) {
      const contributionsBenefits = arrContributions.filter(c => c.entity === 'Benefit')
      for (let i = 0; i < benefits.length; i++) {
        if (contributionsBenefits.length > 0) {
          if (!contributionsBenefits.find(c => c.benefitId === benefits[i].id)) {
            id += 1
            arrDropdown = [
              ...arrDropdown,
              {
                id,
                label: `Afiliación - ${benefits[i].name}`,
                data: {
                  employmentRecordId: null,
                  affiliateRecordId: affiliateRecord.id,
                  frmRecordId: null,
                  benefitId: benefits[i].id
                }
              }
            ]
          }
        } else {
          id += 1
          arrDropdown = [
            ...arrDropdown,
            {
              id,
              label: `Afiliación - ${benefits[i].name}`,
              data: {
                employmentRecordId: null,
                affiliateRecordId: affiliateRecord.id,
                frmRecordId: null,
                benefitId: benefits[i].id
              }
            }
          ]
        }
      }
    }

    if (arrDropdown.length === 0) {
      if (!employmentRecord && !affiliateRecord && !frmRecord && benefits.length === 0) {
        setErrorMessage(MESSAGES.NO_ACTIVE_RECORDS)
      } else setErrorMessage(MESSAGES.ALL_CONTRIBTION_MADE)
    }
    return arrDropdown
  }

  const handleSelectChange = value => {
    setErrorMessage(null)
    setSelectedOptionConcept(null)
    setPerson(value)
  }

  const debounced = debounce((value, callback) => {
    const empty = []

    if (!value) {
      callback(empty)
      return
    }

    const data = {
      filterText: value,
      pageNumber: 1,
      pageSize: 10000
    }

    const results = Axios.post('/Person/filter', data, {
      headers: {
        Authorization: TOKEN_HELPERS.SET_BEARER(currentToken.token)
      }
    })

    results
      .then(res => {
        const options = res.data.items.map(p => ({
          value: p.id,
          label: p.fullName
        }))
        callback(options)
      })
      .catch(error => {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: error.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
        callback(empty)
      })
  }, 300)

  useEffect(() => {
    if (modalData) {
      const { status } = modalData
      if (status === 200) {
        const { modalDataObj } = modalData
        const { conceptsData, contributionsData } = modalDataObj
        setConcepts(customDropdownData(conceptsData, contributionsData))
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: modalData.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        close()
      }
    }
  }, [modalData])

  useEffect(() => {
    errorControl()
  }, [person, selectedOptionConcept])

  useEffect(() => {
    if (person) {
      const { token, tokenCreationDate } = currentToken
      const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
      if (isValid) {
        const data = {
          settlementId,
          personId: person.value,
          statuses: [1, 2],
          pageNumber: 1
        }
        dispatch(LIQUIDATION_LOG_ACTIONS.LIQUIDATION_LOG_GET_PERSON_CONCEPTS({ personId: person.value, data, token }))
      } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
    }
  }, [person])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      <ModalDataContainer flexDirection='column'>
        <SelectInput
          label='Persona'
          placeholder='Ingrese un nombre o número de cobro'
          value={person}
          loadOptions={debounced}
          onChange={handleSelectChange}
          color='primary'
          asyncSelect
          loadingMessage={() => <Text color='primary' weight='bold'>Cargando...</Text>}
          noOptionsMessage={() => <Text color='primary' weight='bold' align='center'>Sin opciones</Text>}
        />
        <Dropdown
          name='concept'
          label='Concepto'
          placeholder={(person && concepts.length <= 0) ? 'No hay datos' : 'Seleccione'}
          options={concepts}
          value={selectedOptionConcept}
          onChange={handleDropdownChange}
          color='primary'
          height='small'
          block
          disabled={!person || concepts.length <= 0}
        />
        <TextInput
          name='description'
          label='Descripción'
          placeholder='Ingrese descripción'
          value={description.value}
          height='small'
          color='primary'
          onChange={handleInputChange}
          bits={[{ icon: 'description', position: 'left', colorBit: 'tertiary' }]}
          disabled={!person || concepts.length <= 0}
        />
      </ModalDataContainer>
      <TextContainerModal>
        {errorMessage
           && (
           <Text size='medium' weight='regular' align='left' color='error'>
             {errorMessage}
           </Text>
           )}
      </TextContainerModal>
      <ButtonContainer justifyContent='flex-end' modal>
        <SolidButton
          size={device === BREAKPOINTS.MOBILE ? 'small' : 'medium'}
          color='primary'
          text='Guardar'
          onClick={handleClick}
          disabled={showButton}
          block={device === BREAKPOINTS.MOBILE}
        />
      </ButtonContainer>
      <Toast
        title={messageToast.title}
        message={messageToast.message}
        color={messageToast.color}
        icon={messageToast.icon}
        isShowing={isShowing}
        position={TOAST_POSITION.rightTop}
        onClick={() => setIsShowing(false)}
      />
    </>
  )
}

export default AddContributionModal

AddContributionModal.propTypes = {
  close: PropTypes.func,
  saveData: PropTypes.func,
  settlementId: PropTypes.string,
  currentToken: PropTypes.string
}
