import { AFFILIATE_RECORD } from './types'

export const getAffiliateRecord = payload => ({
  type: AFFILIATE_RECORD.GET_AFFILIATE_RECORD,
  payload
})

export const getAffiliateRecordSuccess = payload => ({
  type: AFFILIATE_RECORD.GET_AFFILIATE_RECORD_SUCCESS,
  payload
})

export const getAffiliateRecordError = payload => ({
  type: AFFILIATE_RECORD.GET_AFFILIATE_RECORD_ERROR,
  payload
})

export const affiliateRecordClear = payload => ({
  type: AFFILIATE_RECORD.CLEAR,
  payload
})

export const updateBenefits = payload => ({
  type: AFFILIATE_RECORD.UPDATE_BENEFITS,
  payload
})

export const setBenefit = payload => ({
  type: AFFILIATE_RECORD.SET_BENEFIT,
  payload
})

export const getContributions = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTIONS,
  payload
})

export const getContributionsSuccess = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTIONS_SUCCESS,
  payload
})

export const getContributionsError = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTIONS_ERROR,
  payload
})

export const applyFilter = payload => ({
  type: AFFILIATE_RECORD.SEND_FILTER,
  payload
})

export const getFilterData = payload => ({
  type: AFFILIATE_RECORD.GET_FILTER_DATA,
  payload
})

export const filterDataSuccess = payload => ({
  type: AFFILIATE_RECORD.FILTER_DATA_SUCCESS,
  payload
})

export const filterDataError = payload => ({
  type: AFFILIATE_RECORD.FILTER_DATA_ERROR,
  payload
})

export const clearFilterData = () => ({
  type: AFFILIATE_RECORD.FILTER_DATA_CLEAR
})

export const getModalData = payload => ({
  type: AFFILIATE_RECORD.GET_MODAL_DATA,
  payload
})

export const modalDataResponse = payload => ({
  type: AFFILIATE_RECORD.MODAL_DATA_RESPONSE,
  payload
})

export const benefitsResponse = payload => ({
  type: AFFILIATE_RECORD.BENEFITS_RESPONSE,
  payload
})

export const getDataEditProfile = payload => ({
  type: AFFILIATE_RECORD.GET_DATA_EDIT_PROFILE,
  payload
})

export const clearDataModal = () => ({
  type: AFFILIATE_RECORD.CLEAR_DATA_MODAL
})

export const editProfile = payload => ({
  type: AFFILIATE_RECORD.EDIT_PROFILE,
  payload
})

export const updateProfile = payload => ({
  type: AFFILIATE_RECORD.UPDATE_PROFILE,
  payload
})

export const editProfileResponse = payload => ({
  type: AFFILIATE_RECORD.EDIT_PROFILE_RESPONSE,
  payload
})

export const getPersonData = payload => ({
  type: AFFILIATE_RECORD.GET_PERSON_DATA,
  payload
})

export const getPersonDataSuccess = payload => ({
  type: AFFILIATE_RECORD.GET_PERSON_DATA_SUCCESS,
  payload
})

export const getPersonDataError = payload => ({
  type: AFFILIATE_RECORD.GET_PERSON_DATA_ERROR,
  payload
})

export const dataUploadReady = () => ({
  type: AFFILIATE_RECORD.DATA_UPLOAD_READY
})

export const getContributionPayments = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS,
  payload
})

export const getContributionPaymentsSuccess = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS_SUCCESS,
  payload
})

export const getContributionPaymentsError = payload => ({
  type: AFFILIATE_RECORD.GET_CONTRIBUTION_PAYMENTS_ERROR,
  payload
})

export const AFFILIATE_RECORD_ACTIONS = {
  GET_AFFILIATE_RECORD: getAffiliateRecord,
  GET_AFFILIATE_RECORD_SUCCESS: getAffiliateRecordSuccess,
  GET_AFFILIATE_RECORD_ERROR: getAffiliateRecordError,
  CLEAR: affiliateRecordClear,

  UPDATE_BENEFITS: updateBenefits,
  SET_BENEFIT: setBenefit,
  BENEFITS_RESPONSE: benefitsResponse,

  GET_CONTRIBUTIONS: getContributions,
  GET_CONTRIBUTIONS_SUCCESS: getContributionsSuccess,
  GET_CONTRIBUTIONS_ERROR: getContributionsError,

  APPLY_FILTER: applyFilter,
  GET_FILTER_DATA: getFilterData,
  FILTER_DATA_SUCCESS: filterDataSuccess,
  FILTER_DATA_ERROR: filterDataError,
  FILTER_DATA_CLEAR: clearFilterData,

  GET_MODAL_DATA: getModalData,
  GET_DATA_EDIT_PROFILE: getDataEditProfile,
  MODAL_DATA_RESPONSE: modalDataResponse,
  CLEAR_DATA_MODAL: clearDataModal,

  EDIT_PROFILE: editProfile,
  UPDATE_PROFILE: updateProfile,
  EDIT_PROFILE_RESPONSE: editProfileResponse,

  GET_PERSON_DATA: getPersonData,
  GET_PERSON_DATA_SUCCESS: getPersonDataSuccess,
  GET_PERSON_DATA_ERROR: getPersonDataError,

  DATA_UPLOAD_READY: dataUploadReady,

  GET_CONTRIBUTION_PAYMENTS: getContributionPayments,
  GET_CONTRIBUTION_PAYMENTS_SUCCESS: getContributionPaymentsSuccess,
  GET_CONTRIBUTION_PAYMENTS_ERROR: getContributionPaymentsError
}
