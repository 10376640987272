import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer,
  FilterContainer,
  FilterContainerLeftSide,
  FilterContainerRightSide,
  TableWrapper,
  TextContainer
} from '@global/styles'

import {
  TRANSLATION_OF_ROUTES,
  TOAST_POSITION,
  TOAST_PROPERTIES,
  rowsPerPage,
  TIMEOUT_PENDING_PROCESS,
  BREAKPOINTS
} from '@global/constants'
import {
  TOKEN_HELPERS,
  sortByKey,
  filterBodyBuilder,
  handleClickPaginationItem,
  filterOrderBuilder,
  formatDateBasic,
  currencyFormatUY,
  strMonthYear
} from '@utils/helpers'

import { SITE } from '@routes/paths'
import { spacing } from '@global/theme'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading, Text } from '@components/texts'
import { GhostButton } from '@components/button'
import Modal from '@components/modal'
import BadgeGroup from '@components/badgeGroup'
import SearchBar from '@components/searchBar'
import PaginationGroup from '@components/paginationGroup'
import Filter from '@components/filter'
import Table from '@components/table'
import { useWindowDimensions } from '@components/windowDimensions'
import { Dropdown } from '@components/inputs/inputs'
import ProgressBar from '@components/progressBar'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditPaymentProfileModal from './components/editPaymenProfileModalModal'
import AddPaymentDetailModal from './components/addPaymentDetailModal'
import ConfirmCalculateModal from './components/confirmCalculateModal'

import useRedux from './redux'
import { PAYMENT_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, PaymentProfileData, Tbody, ItemLink, CalculateContainer } from './styles'
import { columnData } from './data'

const PaymentProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { paymentId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const { device, deviceName } = useWindowDimensions()
  const history = useHistory()
  const dropdownConceptsRef = useRef()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [paymentProfile, setPaymentProfile] = useState(null)
  const [open, setOpen] = useState(false)
  const [editPaymenProfileModal, setEditPaymenProfileModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(rowsPerPage[0])
  const [itemSelected, setItemSelected] = useState()
  const [pages, setPages] = useState()
  const [totalItems, setTotalItems] = useState(0)
  const [tablelist, setTablelist] = useState([])
  const [columns, setColumns] = useState([])
  const [badges, setBadges] = useState([])
  const [showFilter, setShowFilter] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [activeFilters, setActiveFilters] = useState(null)
  const [showTable, setShowTable] = useState(false)
  const [addPaymentDetailModal, setAddPaymentDetailModal] = useState(false)
  const [modalTitle, setModalTitle] = useState(null)
  const [optionsConcepts, setOptionsConcepts] = useState([])
  const [errorFlag, setErrorFlag] = useState({
    profile: false,
    paymentDetail: false
  })
  const [optionSelectedConcept, setOptionSelectedConcept] = useState()
  const [loading, setLoading] = useState(true)
  const [parameters, setParameters] = useState()
  const [isCalculatingState, setIsCalculatingState] = useState(false)
  const [isChangingStatusState, setIsChangingStatusState] = useState(false)
  const [progressValue, setProgressValue] = useState(0)
  const [calculateDataModal, setCalculateDataModal] = useState(null)

  const loadingEditProfile = useSelector(SELECTORS.LOADING_EDIT_PROFILE)
  const paymentProfileData = useSelector(SELECTORS.SUCCESS)
  const paymentProfileStatuses = useSelector(SELECTORS.PAYMENT_PROFILE_STATUSES)
  const banks = useSelector(SELECTORS.BANKS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const listLoading = useSelector(SELECTORS.LIST_LOADING)
  const totalPages = useSelector(SELECTORS.PAGES)
  const items = useSelector(SELECTORS.PAYMENT_DETAILS_SUCCESS)
  const paymentsDetailError = useSelector(SELECTORS.PAYMENT_DETAILS_ERROR)
  const fields = useSelector(SELECTORS.COLUMNS)
  const allItems = useSelector(SELECTORS.TOTAL_ITEMS)
  const filterObject = useSelector(SELECTORS.FILTER_OBJECT)
  const messageInformation = useSelector(SELECTORS.MESSAGE_INFORMATION)
  const conceptsData = useSelector(SELECTORS.CONCEPTS_INFORMATION)
  const calculationLoading = useSelector(SELECTORS.CALCULATION_LOADING)
  const calculationInformation = useSelector(SELECTORS.CALCULATION_INFORMATION)
  const isCalculatingInfo = useSelector(SELECTORS.IS_CALCULATING_INFO)
  const isChangingStatusInfo = useSelector(SELECTORS.IS_CHANGING_STATUS_INFO)

  const noBankAccountListLoading = useSelector(SELECTORS.NO_BANK_ACCOUNT_LOADING)
  const noBankAccountList = useSelector(SELECTORS.NO_BANK_ACCOUNT_LIST)
  const noBankAccountListError = useSelector(SELECTORS.NO_BANK_ACCOUNT_ERROR)

  const handleToastClose = () => setIsShowing(false)

  const setValueInput = text => {
    const inputSearch = document.getElementById('text_search')
    if (inputSearch) inputSearch.value = text || ''
  }

  const customDataDropdown = arrConcepts => arrConcepts.map(c => ({
    id: c.id,
    label: c.name
  }))

  const handleOutboundSettlementClick = OutboundSettlementId => {
    const win = window.open(`${SITE.PAYMENT_SETTLEMENT_PROFILE}/${OutboundSettlementId}`, '_blank')
    win.focus()
  }

  const renderPaymentProfileData = objPaymentProfileData => (
    <>
      <Tr>
        <Td colorLight widthPer>Código:</Td>
        <Td largeSize>
          {objPaymentProfileData.id || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Fecha Creación:</Td>
        <Td largeSize>{
          formatDateBasic(objPaymentProfileData.dateCreated, 'short') || 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Fecha Pago:</Td>
        <Td largeSize>{
          formatDateBasic(objPaymentProfileData.paymentDate, 'short') || 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Banco:</Td>
        <Td largeSize>{
          objPaymentProfileData.bankName
          || banks.find(b => b.id === objPaymentProfileData.bankId)?.name
          || 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Número de cuenta:</Td>
        <Td largeSize>{objPaymentProfileData.bankAccountNumber || 'N/A'}
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Monto:</Td>
        <Td largeSize>{
          objPaymentProfileData?.currencyId === 1
            ? `${currencyFormatUY(objPaymentProfileData?.amount)}`
            : `${objPaymentProfileData.currencySymbol} ${objPaymentProfileData.amount}` || 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Divisa:</Td>
        <Td largeSize>{`${objPaymentProfileData?.currencyName}` || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Estado:</Td>
        <Td largeSize>{
          objPaymentProfileData?.statusId && paymentProfileStatuses
            ? paymentProfileStatuses.find(s => s.id === objPaymentProfileData?.statusId).name
            : 'N/A'
        }
        </Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Descripción:</Td>
        <Td largeSize>{`${objPaymentProfileData?.description}` || 'N/A'}</Td>
      </Tr>
      <Tr>
        <Td colorLight widthPer>Liquidación de pago:</Td>
        <Td largeSize>
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handleOutboundSettlementClick(objPaymentProfileData.outboundSettlementId)}
          >
            {`${strMonthYear(objPaymentProfileData?.month - 1, objPaymentProfileData?.year)}`}
          </ItemLink>
        </Td>
      </Tr>
      {isChangingStatusState
        && (
          <Tr>
            <ProgressBar value={progressValue} max={100} marginLeft={spacing.quarter} />
          </Tr>
        )}
    </>
  )

  const getPaymentProfileData = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_PAYMENT_PROFILE({ paymentId, token }))
      const data = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        outboundPaymentId: paymentId,
        orderByColumn: 'Id',
        orderByDesc: true
      }
      setActiveFilters(data)
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_PAYMENT_DETAILS({ data, token }))
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_CONCEPTS(token))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getPaymentDetail = body => {
    body.outboundPaymentId = paymentId
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_PAYMENT_DETAILS({ data: body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const getDataInProcess = statusId => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_PROCESS_PENDING_INFO(
        { statusId, paymentId, token }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleDropdownConceptChange = e => {
    const { value } = e
    setOptionSelectedConcept(value)
  }

  const handleSavePaymentProfile = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(PAYMENT_PROFILE_ACTIONS.EDIT_PAYMENT_PROFILE({ paymentId, data: newProfile, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setModalTitle(null)
    setEditPaymenProfileModal(null)
    setAddPaymentDetailModal(null)
    setCalculateDataModal(null)
    dispatch(PAYMENT_PROFILE_ACTIONS.CLEAR_BANK_ACCOUNTS())
  }

  const handleCalculate = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = { concepts: optionSelectedConcept ? optionSelectedConcept.map(c => c.id) : [] }
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_NOT_BANK_ACCOUNTS(
        {
          outboundSettlementId: paymentProfile?.outboundSettlementId,
          data,
          token
        }
      ))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleEditPayment = () => {
    setModalTitle('Editar Pago')
    setEditPaymenProfileModal({
      statuses: paymentProfileStatuses,
      oldData: paymentProfile,
      saveData: handleSavePaymentProfile,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  const handleClickShowFilters = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const filterRequest = { id: 16, token }
      dispatch(PAYMENT_PROFILE_ACTIONS.GET_FILTER_DATA(filterRequest))
      setShowFilter(!showFilter)
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseBadge = filterName => {
    const updateBadges = badges.filter(badge => badge.name !== filterName)
    setBadges(updateBadges)
    const body = filterBodyBuilder(updateBadges, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getPaymentDetail(body)
  }

  const handleInputChange = e => {
    if (!e?.value) delete activeFilters.filterText

    let body = activeFilters ? { ...activeFilters } : {}

    if (e?.value) {
      body = { ...body, filterText: e.value }
      setActiveFilters({
        ...activeFilters,
        filterText: e.value
      })
    }
    getPaymentDetail(body)
  }

  const handleOrderChange = (nameColumn, typeOrder) => {
    const colNames = Object.entries(columnData).find(col => col[1].name === nameColumn)[0]
    const propsForOrderBuilder = {
      filterState: activeFilters,
      setFilterState: setActiveFilters,
      columnName: colNames,
      orderByDesc: typeOrder,
      currentPageSize: selectedOption.value,
      currentPageNumber: itemSelected,
      setNewItemSelected: setItemSelected
    }
    const body = filterOrderBuilder(propsForOrderBuilder)
    getPaymentDetail(body)
  }

  const handleDropdownChange = e => {
    let body = null
    if (activeFilters) {
      body = { ...activeFilters }
      body = {
        ...body,
        pageNumber: 1,
        pageSize: e.value.value
      }
    } else {
      body = {
        pageNumber: 1,
        pageSize: e.value.value
      }
    }
    getPaymentDetail(body)
    setSelectedOption(e.value)
    setItemSelected(0)
  }

  const handleClickCloseFilters = () => {
    dispatch(PAYMENT_PROFILE_ACTIONS.FILTER_DATA_CLEAR())
    setShowFilter(!showFilter)
  }

  const handleApplyFilter = filters => {
    setBadges(filters)
    const body = filterBodyBuilder(filters, selectedOption.value, setActiveFilters, activeFilters, parameters)
    getPaymentDetail(body)
  }

  const handleSaveNewPayment = newPayment => {
    newPayment.paymentId = Number(paymentId)
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const body = {
        pageNumber: 1,
        pageSize: selectedOption.value,
        outboundPaymentId: paymentId
      }
      dispatch(PAYMENT_PROFILE_ACTIONS.ADD_PAYMENT_DETAIL({ data: newPayment, body, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleAddPayment = () => {
    const { currencyId, outboundSettlementId } = paymentProfile
    setModalTitle('Agregar Detalle de Pago')
    setAddPaymentDetailModal({
      saveData: handleSaveNewPayment,
      close: handleCloseModal,
      currencyId,
      outboundSettlementId,
      currentToken
    })
    setOpen(true)
  }

  const handleCalculateOutboundPaymentDetails = () => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      const data = {
        outboundPaymentId: paymentId,
        concepts: optionSelectedConcept ? optionSelectedConcept.map(c => c.id) : []
      }
      dispatch(PAYMENT_PROFILE_ACTIONS.CALCULATE_OUTBOUND_PAYMENTS_DETAILS({ paymentId, data, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handlePaymentDetailClick = paymentDetailId => {
    if (paymentDetailId) {
      const win = window.open(`${SITE.PAYMENT_DETAIL}/${paymentDetailId}`, '_blank')
      win.focus()
    }
  }

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleDebtClick = debtProfileId => {
    const win = window.open(`${SITE.DEBT_PROFILE}/${debtProfileId}`, '_blank')
    win.focus()
  }

  const customDataTable = itemsTable => {
    const newData = itemsTable.map(item => {
      const code = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handlePaymentDetailClick(item.id)}
        >
          {`DP - ${item.id}`}
        </ItemLink>
      )

      const person = item.personId !== null
        ? (
          <ItemLink
            color='tertiary'
            underline
            onClick={() => handlePersonClick(item.personId)}
          >
            {`${item.billingNumber} - ${item.personName}`}
          </ItemLink>
        )
        : 'N/A'

      const detail = (
        <ItemLink
          color='tertiary'
          underline
          onClick={() => handleDebtClick(item.detailId)}
        >
          {`A - ${item.detailId}`}
        </ItemLink>
      )

      const status = (
        <Text color={item.statusId === 3 ? 'error' : null}>
          {paymentProfileStatuses?.find(s => s.id === item.statusId).name || item.statusName}
        </Text>
      )

      const newItem = {
        id: item.id,
        code,
        concept: item.concept,
        service: item.detailServiceName || 'N/A',
        person,
        description: item.description,
        detail,
        amount: currencyFormatUY(item.amount),
        bankAccount: `${item.bankAccount.bankName} - ${item.bankAccount.accountNumber}`,
        status
      }
      return newItem
    })
    return newData
  }

  const customHeaderDataTable = (headersData, colData) => {
    const orderedColumns = sortByKey(headersData, 'order').map(col => {
      // Id comes from the BE, the problem is that the table ignore the field id
      if (col.name === 'Id') return 'Code'
      return col.name
    })
    let customColumns = []
    for (let i = 0; i < orderedColumns.length; i++) {
      const wanted = Object.entries(colData).find(item => item[0] === orderedColumns[i])
      customColumns = [
        ...customColumns,
        wanted[1]
      ]
    }

    return customColumns
  }

  const renderFilter = arrFilters => (
    <Filter
      device={device}
      close={handleClickCloseFilters}
      loadBadges={handleApplyFilter}
      filterData={arrFilters}
      isShowing={showFilter}
    />
  )

  const renderTable = values => {
    const { currentList, columnsN, currentLoading, handleOrder, handleSelectedRow, error } = values
    return (
      <Table
        list={currentList}
        columns={columnsN}
        loading={currentLoading}
        handleOrder={handleOrder}
        rowClick={handleSelectedRow}
        error={error}
      >
        <PaginationGroup
          deviceName={deviceName}
          pages={pages}
          itemSelected={itemSelected}
          handleClickPaginationItem={n => handleClickPaginationItem(
            n, activeFilters, selectedOption.value, getPaymentDetail, setItemSelected
          )}
          options={rowsPerPage}
          selectedOption={selectedOption}
          handleDropdownChange={handleDropdownChange}
          totalItems={totalItems}
          itemsPerPage={tablelist.length}
        />
      </Table>
    )
  }

  useEffect(() => {
    getPaymentProfileData()
  }, [])

  useEffect(() => {
    setTablelist(customDataTable(items))
    setColumns(customHeaderDataTable(fields, columnData))
    setPages(totalPages.totalPages)
    setItemSelected(totalPages.currentPage - 1)
    setTotalItems(allItems)
    if (items.length > 0) setShowTable(true)
  }, [items, totalPages])

  useEffect(() => {
    if (paymentProfileData) {
      setPaymentProfile(paymentProfileData)
      if (paymentProfileData) {
        if (paymentProfileData?.statusId === 1) setValueInput()
      }
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_PROFILE} - ${paymentId}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url: `${SITE.FINANCE}`
        }
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENTS}`,
          link: true,
          url: `${SITE.FINANCE}${SITE.PAYMENTS}`
        }
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.PAYMENT_PROFILE} - ${paymentId}`,
          link: false,
          url: `${SITE.FINANCE}${SITE.PAYMENTS}${SITE.PAYMENT_PROFILE}/${paymentId}`
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb])
      }
    }
  }, [paymentProfileData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.profile) {
        getPaymentProfileData()
        setErrorFlag({
          ...errorFlag,
          profile: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (conceptsData && paymentProfileData && items) {
      setLoading(false)
    }
  }, [conceptsData, paymentProfileData, items])

  useEffect(() => {
    if (calculationInformation) {
      const { status } = calculationInformation
      if (status === 200) {
        if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
      } else {
        setIsShowing(true)
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: calculationInformation.message,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }
  }, [calculationInformation])

  useEffect(() => {
    if (paymentsDetailError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: paymentsDetailError.message || `Error ${paymentsDetailError.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag.paymentDetail) {
        const data = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          outboundPaymentId: paymentId
        }
        dispatch(PAYMENT_PROFILE_ACTIONS.GET_PAYMENT_DETAILS({ data, token: currentToken.token }))
        setErrorFlag({
          ...errorFlag,
          paymentDetail: true
        })
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [paymentsDetailError])

  useEffect(() => {
    if (conceptsData) {
      const conceptsFiltered = conceptsData.filter(c => (c.code === 80 || c.code === 0 || c.code === 283))
      setOptionsConcepts(customDataDropdown(conceptsFiltered))
    }
  }, [conceptsData])

  useEffect(() => {
    if (filterObject.length > 0) {
      setFilterData(filterObject)
      setParameters(filterObject.map(p => p.name))
    }
  }, [filterObject])

  useEffect(() => {
    if (messageInformation) {
      if (messageInformation === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: messageInformation.message || `Error ${messageInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
        const body = {
          pageNumber: 1,
          pageSize: selectedOption.value,
          outboundPaymentId: paymentId
        }
        getPaymentDetail(body)
      }
      handleCloseModal()
      setIsShowing(true)
      dispatch(PAYMENT_PROFILE_ACTIONS.CLEAR_MESSAGE_INFORMATION())
    }
  }, [messageInformation])

  useEffect(() => {
    let calculatingTimeOut = null
    if (isCalculatingInfo) {
      const { status, isCalculating, progressPercentage } = isCalculatingInfo
      if (status === 200) {
        setIsCalculatingState(isCalculating)
        setProgressValue(progressPercentage * 100)

        if (isCalculating) {
          calculatingTimeOut = setTimeout(() => {
            getDataInProcess(paymentProfile?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }
        if (!isCalculating && !calculatingTimeOut) {
          clearTimeout(calculatingTimeOut)
          const data = activeFilters
            ? { ...activeFilters }
            : {
              pageNumber: 1,
              pageSize: selectedOption.value,
              outboundPaymentId: paymentId
            }
          dispatch(PAYMENT_PROFILE_ACTIONS.GET_PAYMENT_DETAILS(
            { data, token: currentToken.token }
          ))
        }
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: isCalculatingInfo?.message || `Error ${isCalculating?.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
    }

    return () => {
      clearTimeout(calculatingTimeOut)
    }
  }, [isCalculatingInfo])

  useEffect(() => {
    let changingStatusTimeOut = null
    if (isChangingStatusInfo) {
      const { status, isChangingStatus, progressPercentage } = isChangingStatusInfo
      if (status === 200) {
        setIsChangingStatusState(isChangingStatus)
        setProgressValue(progressPercentage * 100)
        if (isChangingStatus) {
          changingStatusTimeOut = setTimeout(() => {
            getDataInProcess(paymentProfile?.statusId)
          }, TIMEOUT_PENDING_PROCESS)
        }

        if (!isChangingStatus && !changingStatusTimeOut) {
          clearTimeout(changingStatusTimeOut)
          if (activeFilters?.filterText) setValueInput(activeFilters?.filterText)
        }
      }
    } else {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: isChangingStatusInfo?.message || `Error ${isChangingStatusInfo?.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
    }

    return () => {
      clearTimeout(changingStatusTimeOut)
    }
  }, [isChangingStatusInfo])

  useEffect(() => {
    if (noBankAccountList) {
      if (noBankAccountList.length > 0) {
        setModalTitle('Confirmar cálculo de Detalles de Pago')
        setCalculateDataModal({
          onClick: handleCalculateOutboundPaymentDetails,
          list: noBankAccountList,
          close: handleCloseModal
        })
        setOpen(true)
      } else {
        handleCalculateOutboundPaymentDetails()
      }
    }
  }, [noBankAccountList])

  useEffect(() => {
    if (noBankAccountListError) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: noBankAccountListError?.message || `Error ${noBankAccountListError?.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      setTimeout(() => {
        dispatch(PAYMENT_PROFILE_ACTIONS.CLEAR_NO_BANK_ACCOUNT_ERROR())
      }, 100)
    }
  }, [noBankAccountListError])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
              && (
                <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
                  <Breadcrumbs crumbs={breadcrumbs} />
                </WrapperBreadcrumbs>
              )}
            <WrapperHeading
              columnStart='1'
              columnEnd='12'
              rowStart='2'
              rowEnd='2'
              device={device}
            >
              {paymentProfile
                && (
                  <Heading
                    type='h2'
                    weight='bold'
                    align='left'
                  >
                    {`${TRANSLATION_OF_ROUTES.PAYMENT_PROFILE} - ${paymentProfile.id}`}
                  </Heading>
                )}
              {breadcrumbs
                && (
                  <MyLink
                    color='primary'
                    to={{
                      pathname: `${SITE.HISTORY}${pathname}`,
                      state: { breadcrumbs }
                    }}
                    textDecoration='underline'
                  >
                    Historial de Actividad
                  </MyLink>
                )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEditProfile
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <PaymentProfileData>
                      <Tbody>
                        {paymentProfile && renderPaymentProfileData(paymentProfile)}
                      </Tbody>
                    </PaymentProfileData>
                    <LinkContainer flex='2'>
                      <MyButtonLink onClick={handleEditPayment} color='primary' underline>Editar Pago</MyButtonLink>
                    </LinkContainer>
                  </>
                )}
            </DataContainer>
            <CalculateContainer columnStart={1} columnEnd={12} rowStart={4} rowEnd={4} device={device}>
              <Dropdown
                name='concepts'
                label='Conceptos'
                placeholder='Seleccione'
                options={optionsConcepts}
                value={optionSelectedConcept}
                onChange={handleDropdownConceptChange}
                color='primary'
                height='small'
                block
                disabled={optionsConcepts.length === 0}
                multiSelect
                ref={dropdownConceptsRef}
              />
              <GhostButton
                color='primary'
                size='small'
                text='Cálcular Detalles de Pago'
                icon={noBankAccountListLoading ? 'sync' : 'request_quote'}
                spin={noBankAccountListLoading}
                onClick={() => handleCalculate()}
                disabled={!optionSelectedConcept || optionSelectedConcept?.length === 0}
                block={device === BREAKPOINTS.MOBILE}
              />
              {isCalculatingState
                && (
                  <ProgressBar value={progressValue} max={100} />
                )}
            </CalculateContainer>
            {calculationLoading
              ? (
                <WrapperLoading>
                  <Loading color='primary' size='xsmall' weight='bold' />
                </WrapperLoading>
              )
              : (
                <>
                  <TextContainer columnStart={1} columnEnd={12} rowStart={5} rowEnd={5}>
                    <Text size='large' weight='bold' align='left'>Detalles de pago</Text>
                  </TextContainer>
                  <FilterContainer columnStart={1} columnEnd={12} rowStart={6} rowEnd={6} deviceName={deviceName}>
                    <FilterContainerLeftSide deviceName={deviceName}>
                      {showTable
                        && (
                          <GhostButton
                            color='tertiary'
                            size='small'
                            text='Agregar Filtro'
                            icon='filter_alt'
                            onClick={handleClickShowFilters}
                          />
                        )}
                      {badges.length > 0 && <BadgeGroup badges={badges} handleCloseBadge={handleCloseBadge} />}
                      {showFilter && (filterData.length > 0 && renderFilter(filterData))}
                    </FilterContainerLeftSide>
                    <FilterContainerRightSide searchBarNoExists={!showTable} deviceName={deviceName}>
                      {showTable && (
                        <SearchBar
                          onChange={handleInputChange}
                          visible={activeFilters?.filterText}
                          value={activeFilters?.filterText}
                        />
                      )}
                      <GhostButton
                        color='tertiary'
                        size='small'
                        text='Agregar Detalle de pago +'
                        onClick={handleAddPayment}
                        disabled={paymentProfile?.statusId !== 2}
                        block={device === BREAKPOINTS.MOBILE}
                      />
                    </FilterContainerRightSide>
                  </FilterContainer>
                  <TableWrapper rowStart='7' rowEnd='7' columnStart='1' columnEnd='12'>
                    {renderTable({
                      currentList: tablelist,
                      columnsN: columns,
                      currentLoading: listLoading,
                      handleOrder: handleOrderChange,
                      error: !!errorMessage,
                      showRowPointer: true
                    })}
                  </TableWrapper>
                </>
              )}
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title={modalTitle}
              minHeight='auto'
              top={addPaymentDetailModal ? '10%' : null}
              widthWrapper={addPaymentDetailModal ? '95%' : null}
              widthArea={addPaymentDetailModal && 'auto'}
              left={addPaymentDetailModal ? '5%' : null}
              padding={addPaymentDetailModal ? spacing.one : spacing.two}
            >
              {editPaymenProfileModal && (<EditPaymentProfileModal {...editPaymenProfileModal} />)}
              {addPaymentDetailModal && (<AddPaymentDetailModal {...addPaymentDetailModal} />)}
              {calculateDataModal && (<ConfirmCalculateModal {...calculateDataModal} />)}
            </Modal>
          </>
        )}
    </>
  )
}

export default PaymentProfile
