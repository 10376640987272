import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectPaymentDetail = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectPaymentDetail, state => get(state, 'loading', null)
)

const selectPaymentDetailSuccess = createSelector(
  selectPaymentDetail, state => get(state, 'paymentDetail', null)
)

const selectStatusesSuccess = createSelector(
  selectPaymentDetail, state => get(state, 'paymentDetailStatuses', null)
)

const selectError = createSelector(
  selectPaymentDetail, state => get(state, 'error', null)
)

const selectLoadingEditPaymentDetail = createSelector(
  selectPaymentDetail, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectPaymentDetail, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectPaymentDetail, state => get(state, 'editInformation', [])
)

const selectBankAccounts = createSelector(
  selectPaymentDetail, state => get(state, 'bankAccounts', [])
)

export default {
  PAYMENT_DETAIL_SUCCESS: selectPaymentDetailSuccess,
  PAYMENT_DETAIL_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_PAYMENT_DETAIL: selectLoadingEditPaymentDetail,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  BANK_ACCOUNTS: selectBankAccounts
}
