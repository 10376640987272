import { createSelector } from 'reselect'
import { get } from 'lodash'

import key from './types'
import { initialState } from './reducer'

const selectContributionProfile = state => get(state, key, initialState)

const selectLoading = createSelector(
  selectContributionProfile, state => get(state, 'loading', null)
)

const selectContributionProfileSuccess = createSelector(
  selectContributionProfile, state => get(state, 'contributionProfile', null)
)

const selectStatusesSuccess = createSelector(
  selectContributionProfile, state => get(state, 'contributionProfileStatuses', null)
)

const selectError = createSelector(
  selectContributionProfile, state => get(state, 'error', null)
)

const selectLoadingEditContributionProfile = createSelector(
  selectContributionProfile, state => get(state, 'loadingEditProfile', [])
)

const selectErrorMessage = createSelector(
  selectContributionProfile, state => get(state, 'errorMessage', null)
)

const selectEditInformation = createSelector(
  selectContributionProfile, state => get(state, 'editInformation', [])
)

const selectContributionProfileModalData = createSelector(
  selectContributionProfile, state => get(state, 'modalDataInformation', [])
)

const selectCurrencies = createSelector(
  selectContributionProfile, state => get(state, 'currencies', [])
)

const selectConcepts = createSelector(
  selectContributionProfile, state => get(state, 'concepts', [])
)

export default {
  CONTRIBUTION_PROFILE_SUCCESS: selectContributionProfileSuccess,
  CONTRIBUTION_PROFILE_STATUSES_SUCCESS: selectStatusesSuccess,
  ERROR: selectError,
  LOADING: selectLoading,
  LOADING_EDIT_CONTRIBUTION_PROFILE: selectLoadingEditContributionProfile,
  ERROR_MESSAGE: selectErrorMessage,
  EDIT_INFORMATION: selectEditInformation,
  CONTRIBUTION_PROFILE_MODAL_DATA: selectContributionProfileModalData,
  CURRENCIES: selectCurrencies,
  CONCEPTS: selectConcepts
}
