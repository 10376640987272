import React, { useState, useEffect } from 'react'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  WrapperBreadcrumbs,
  WrapperLoading,
  DataContainer,
  WrapperHeading,
  MyLink,
  MyButtonLink,
  LinkContainer
} from '@global/styles'

import { TRANSLATION_OF_ROUTES, TOAST_POSITION, TOAST_PROPERTIES, DETAIL_TYPES } from '@global/constants'
import { TOKEN_HELPERS, currencyFormatUY, formatDateBasic } from '@utils/helpers'

import { SITE } from '@routes/paths'
import { MESSAGES } from '@global/message'

import Breadcrumbs from '@components/breadcrums'
import Loading from '@components/loading'
import Toast from '@components/toast'
import { useUser } from '@components/authentication/utils/hook'
import { Heading } from '@components/texts'
import Modal from '@components/modal'
import { useWindowDimensions } from '@components/windowDimensions'

import SELECTORS_USER from '@components/authentication/redux/selectors'
import EditContributionProfile from './components/editContributionProfileModal'

import useRedux from './redux'
import { CONTRIBUTION_PROFILE_ACTIONS } from './redux/actions'
import SELECTORS from './redux/selectors'

import { Td, Tr, ContributionProfileData, Tbody, ItemLink } from './styles'

const ContributionProfile = () => {
  useRedux()
  const { state, pathname } = useLocation()
  const { contributionProfileId } = useParams()
  const { GetToken, Logout } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()
  const { device } = useWindowDimensions()

  const [currentToken] = useState(GetToken())
  const [breadcrumbs, setBreadcrumbs] = useState()
  const [isShowing, setIsShowing] = useState(false)
  const [messageToast, setMessageToast] = useState({
    title: '',
    message: '',
    icon: '',
    color: ''
  })
  const [contributionProfile, setContributionProfile] = useState(null)
  const [statuses, setStatuses] = useState(null)
  const [open, setOpen] = useState(false)
  const [editContributionModal, setEditContributionModal] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [concepts, setConcepts] = useState([])

  const loading = useSelector(SELECTORS.LOADING)
  const loadingEdit = useSelector(SELECTORS.LOADING_EDIT_CONTRIBUTION_PROFILE)
  const editInformation = useSelector(SELECTORS.EDIT_INFORMATION)
  const contributionProfileData = useSelector(SELECTORS.CONTRIBUTION_PROFILE_SUCCESS)
  const statusesData = useSelector(SELECTORS.CONTRIBUTION_PROFILE_STATUSES_SUCCESS)
  const errorMessage = useSelector(SELECTORS.ERROR)
  const currencies = useSelector(SELECTORS.CURRENCIES)
  const conceptsData = useSelector(SELECTORS.CONCEPTS)

  const errorRefresh = useSelector(SELECTORS_USER.ERROR_USER_REFRESH)

  const handleToastClose = () => setIsShowing(false)

  const handlePersonClick = personId => {
    if (personId) {
      const win = window.open(`${SITE.PERSON}/${personId}`, '_blank')
      win.focus()
    }
  }

  const handleContributionPaymentClick = contributionId => {
    if (contributionId) {
      const win = window.open(`${SITE.CONTRIBUTION_PAYMENT}/${contributionId}`, '_blank')
      win.focus()
    }
  }

  const handleSendToContributionPaymentList = contributionId => {
    const win = window.open(`${SITE.DETAILS_LIST}/${contributionId}/${DETAIL_TYPES.CONTRIBUTION_PAYMENT}`, '_blank')
    win.focus()
  }

  const renderLiquidationLogData = objContributionProfileData => {
    const { total, totalPaid, id } = objContributionProfileData
    let { contributionPayments } = objContributionProfileData
    let link = 'N/A'
    contributionPayments = contributionPayments.filter(cp => cp.statusId === 1)
    if (contributionPayments?.length > 0) {
      link = contributionPayments?.length === 1
        ? (
          <ItemLink
            color='tertiary'
            underline
            marginRight
            onClick={() => handleContributionPaymentClick(contributionPayments[0].id)}
          >
            {`DI - ${contributionPayments[0].id}`}
          </ItemLink>
        )
        : (
          <ItemLink
            color='tertiary'
            underline
            marginRight
            onClick={() => handleSendToContributionPaymentList(id)}
          >
            Ver lista de DI
          </ItemLink>
        )
    }
    return (
      <>
        <Tr>
          <Td colorLight widthPer>Código:</Td>
          <Td largeSize>{`C - ${objContributionProfileData.id}` || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Persona:</Td>
          <Td largeSize>{objContributionProfileData.person
            ? (
              <ItemLink
                color='tertiary'
                underline
                onClick={() => handlePersonClick(objContributionProfileData.personId)}
              >
                {`${objContributionProfileData.personId} - ${objContributionProfileData.person}`}
              </ItemLink>
            )
            : 'N/A'}
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Concepto:</Td>
          <Td largeSize>{
            objContributionProfileData?.conceptId
              ? concepts?.find(c => c.id === objContributionProfileData?.conceptId)?.name
              : 'N/A'
            }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Entidad:</Td>
          <Td largeSize>{objContributionProfileData.entity || 'N/A'}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Moneda:</Td>
          <Td largeSize>{
            objContributionProfileData.currencyName
            || currencies.find(c => c.id === objContributionProfileData.currencyId).name
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Monto:</Td>
          <Td largeSize>{
          !Number.isNaN(total) && (typeof total === 'number')
            ? currencyFormatUY(objContributionProfileData?.total)
            : 'N/A'
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Monto Cobrado:</Td>
          <Td largeSize>{
          !Number.isNaN(totalPaid) && (typeof totalPaid === 'number')
            ? currencyFormatUY(objContributionProfileData?.totalPaid)
            : 'N/A'
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Registro de ingreso:</Td>
          <Td largeSize>{link}</Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Estado:</Td>
          <Td largeSize>{
          objContributionProfileData.statusName
          || statuses.find(s => s.id === objContributionProfileData.statusId).name
          }
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Fecha de Vencimiento:</Td>
          <Td largeSize>
            {formatDateBasic(objContributionProfileData.dueDate, 'short') || 'N/A'}
          </Td>
        </Tr>
        <Tr>
          <Td colorLight widthPer>Descripción:</Td>
          <Td largeSize>
            {objContributionProfileData.description || 'N/A'}
          </Td>
        </Tr>
      </>
    )
  }
  const handleSaveContribution = newProfile => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CONTRIBUTION_PROFILE_ACTIONS.EDIT_CONTRIBUTION_PROFILE({
        contributionId: contributionProfileId, data: newProfile, token
      }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }

  const handleCloseModal = () => {
    setEditContributionModal(null)
    setOpen(false)
  }

  const handleEditContribution = () => {
    setEditContributionModal({
      oldData: contributionProfile,
      saveData: handleSaveContribution,
      close: handleCloseModal,
      currentToken
    })
    setOpen(true)
  }

  useEffect(() => {
    const { token, tokenCreationDate } = currentToken
    const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
    if (isValid) {
      dispatch(CONTRIBUTION_PROFILE_ACTIONS.GET_CONTRIBUTION_PROFILE({ contributionId: contributionProfileId, token }))
    } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
  }, [])

  useEffect(() => {
    if (contributionProfileData) {
      setContributionProfile(contributionProfileData)
      if (state) {
        const lastCrumb = {
          link: true,
          text: `${TRANSLATION_OF_ROUTES.CONTRIBUTION_PROFILE} - ${contributionProfileId}`,
          url: {
            pathname,
            state: { breadcrumbs: [...state.breadcrumbs] }
          }
        }
        setBreadcrumbs([...state.breadcrumbs, lastCrumb])
      } else {
        let url = `${SITE.FINANCE}`
        const firstCrumb = {
          text: TRANSLATION_OF_ROUTES.FINANCE,
          link: true,
          url
        }
        url += SITE.LIQUIDATIONS
        const secondCrumb = {
          text: `${TRANSLATION_OF_ROUTES.LIQUIDATIONS}`,
          link: true,
          url
        }
        url += `${SITE.LIQUIDATION_LOG}/${contributionProfileData.settlementId}`
        const thirtCrumb = {
          text: `${TRANSLATION_OF_ROUTES.LIQUIDATION_LOG} - ${contributionProfileData.settlementId}`,
          link: false,
          url
        }
        url += `${SITE.CONTRIBUTION_PROFILE}/${contributionProfileId}`
        const lastCrumb = {
          text: `${TRANSLATION_OF_ROUTES.CONTRIBUTION_PROFILE} - ${contributionProfileId}`,
          link: false,
          url
        }
        setBreadcrumbs([firstCrumb, secondCrumb, thirtCrumb, lastCrumb])
      }
    }
  }, [contributionProfileData])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData)
    }
  }, [statusesData])

  useEffect(() => {
    if (errorMessage) {
      setMessageToast({
        title: TOAST_PROPERTIES.ERROR.title,
        message: errorMessage.message || `Error ${errorMessage.status}`,
        icon: TOAST_PROPERTIES.ERROR.icon,
        color: TOAST_PROPERTIES.ERROR.color
      })
      setIsShowing(true)
      if (!errorFlag) {
        const { token, tokenCreationDate } = currentToken
        const { isValid, error } = TOKEN_HELPERS.IS_VALID_TOKEN(tokenCreationDate)
        if (isValid) {
          dispatch(CONTRIBUTION_PROFILE_ACTIONS.GET_CONTRIBUTION_PROFILE(
            { contributionId: contributionProfileId, token }
          ))
          setErrorFlag(true)
        } else TOKEN_HELPERS.EXPIRED_TOKEN(error, setIsShowing, setMessageToast, Logout)
      } else {
        setTimeout(() => {
          history.goBack()
        }, 1500)
      }
    }
  }, [errorMessage])

  useEffect(() => {
    if (editInformation) {
      if (editInformation.status === 200) {
        setMessageToast({
          title: TOAST_PROPERTIES.SUCCESS.title,
          message: MESSAGES.SUCCESSFUL_ACTION,
          icon: TOAST_PROPERTIES.SUCCESS.icon,
          color: TOAST_PROPERTIES.SUCCESS.color
        })
      } else {
        setMessageToast({
          title: TOAST_PROPERTIES.ERROR.title,
          message: editInformation.message || `Error ${editInformation.status}`,
          icon: TOAST_PROPERTIES.ERROR.icon,
          color: TOAST_PROPERTIES.ERROR.color
        })
      }
      setIsShowing(true)
    }
  }, [editInformation])

  useEffect(() => {
    if (conceptsData) setConcepts(conceptsData)
  }, [conceptsData])

  useEffect(() => {
    if (errorRefresh) {
      TOKEN_HELPERS.EXPIRED_TOKEN(errorRefresh.message, setIsShowing, setMessageToast, Logout)
    }
  }, [errorRefresh])

  return (
    <>
      {loading
        ? (
          <WrapperLoading>
            <Loading color='primary' size='xsmall' weight='bold' />
          </WrapperLoading>
        )
        : (
          <>
            {breadcrumbs
            && (
            <WrapperBreadcrumbs columnStart='1' columnEnd='12' rowStart='1' rowEnd='1'>
              <Breadcrumbs crumbs={breadcrumbs} />
            </WrapperBreadcrumbs>
            )}
            <WrapperHeading
              device={device}
              columnStart='1'
              columnEnd='12'
              rowStart='2'
              rowEnd='2'
            >
              {contributionProfile
               && (
               <Heading
                 type='h2'
                 weight='bold'
                 align='left'
               >
                 {`${TRANSLATION_OF_ROUTES.CONTRIBUTION_PROFILE} - ${contributionProfile.id}`}
               </Heading>
               )}
              {breadcrumbs
              && (
              <MyLink
                color='primary'
                to={{
                  pathname: `${SITE.HISTORY}${pathname}`,
                  state: { breadcrumbs }
                }}
                textDecoration='underline'
              >
                Historial de Actividad
              </MyLink>
              )}
            </WrapperHeading>
            <DataContainer columnStart='1' columnEnd='12' rowStart='3' rowEnd='3' marginBottom='24px' device={device}>
              {loadingEdit
                ? (
                  <WrapperLoading>
                    <Loading color='primary' size='xsmall' weight='bold' />
                  </WrapperLoading>
                )
                : (
                  <>
                    <ContributionProfileData>
                      <Tbody>
                        {contributionProfile && renderLiquidationLogData(contributionProfile)}
                      </Tbody>
                    </ContributionProfileData>
                    <LinkContainer>
                      <MyButtonLink onClick={handleEditContribution} color='primary' underline>Editar</MyButtonLink>
                    </LinkContainer>
                  </>
                )}

            </DataContainer>
            <Toast
              title={messageToast.title}
              message={messageToast.message}
              color={messageToast.color}
              icon={messageToast.icon}
              isShowing={isShowing}
              position={TOAST_POSITION.rightTop}
              onClick={handleToastClose}
            />
            <Modal
              isShowing={open}
              close={handleCloseModal}
              title='Editar Contribución'
              minHeight='auto'
            >
              {editContributionModal
                && <EditContributionProfile {...editContributionModal} />}
            </Modal>
          </>
        )}
    </>
  )
}

export default ContributionProfile
