import { put, call, takeLatest } from 'redux-saga/effects'

import Axios from '@axios/'

import { TOKEN_HELPERS } from '@utils/helpers'

import { EXPORTS_ACTIONS } from './actions'
import { EXPORTS } from './types'

const ASYNC_JOB_TYPES = 'AsyncJobTypes'
const EXPORT = 'exportación'

const apiCalls = {
  getData: ({ data, token }) => (
    Axios.post('AsyncJob/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getFilterData: ({ id, token }) => (
    Axios.get(`Filter/${id}`,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getJobTypes: token => (
    Axios('AsyncJob/types',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getJobStatuses: token => (
    Axios('AsyncJob/statuses',
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getSettlementsExports: ({ data, token }) => (
    Axios.post('Settlement/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getBenefitsExports: ({ data, token }) => (
    Axios.post('Benefit/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  createExport: ({ url, data, token }) => (
    Axios.post(url, data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getOutboundPayment: ({ data, token }) => (
    Axios.post('OutboundPayment/filter', data,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })),
  getConcepts: ({ token, personId }) => {
    const url = personId ? `Concept?personId=${personId}&includeInactive=true` : 'Concept'
    return Axios.get(url,
      {
        headers: {
          Authorization: TOKEN_HELPERS.SET_BEARER(token)
        }
      })
  }
}

export function* getExports ({ payload }) {
  try {
    const { token } = payload
    let objExports = {}
    const res = yield call(apiCalls.getData, payload)
    objExports = res.data
    let { data: jobTypes } = yield call(apiCalls.getJobTypes, token)
    jobTypes = jobTypes.filter(t => t.action === 0)

    objExports = {
      ...objExports,
      jobTypes
    }

    yield put(EXPORTS_ACTIONS.SUCCESS(objExports))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.ERROR(error))
  }
}

export function* FilterData ({ payload }) {
  try {
    const res = yield call(apiCalls.getFilterData, payload)
    const filtersObj = res.data
    filtersObj.parameters.map(p => {
      if (p.name === ASYNC_JOB_TYPES) {
        p.content = p.content.filter(c => c.name.toLowerCase().includes(EXPORT))
        return p
      }
      return p
    })
    yield put(EXPORTS_ACTIONS.FILTER_DATA_SUCCESS(filtersObj.parameters))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.FILTER_DATA_ERROR(error))
  }
}

export function* getModalDataExports ({ payload }) {
  try {
    const { type, dataSettlement, dataBenefits, token } = payload
    const { data: { items: settlementData } } = yield call(apiCalls.getSettlementsExports,
      { data: dataSettlement, token })

    // 6 - export contributions
    let benefitsData = []
    if (type === 6) {
      const { data: { items } } = yield call(apiCalls.getBenefitsExports, { data: dataBenefits, token })
      benefitsData = items
    }

    yield put(EXPORTS_ACTIONS.MODAL_DATA_INFORMATION({ settlementData, benefitsData }))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* createExport ({ payload }) {
  try {
    const { typeId, data, token } = payload
    let url = null
    switch (typeId) {
      case 4:
        url = 'Report/affiliateregistrations'
        break
      case 5:
        url = 'Report/frmregistrations'
        break
      case 6:
        url = 'Report/contributions'
        break
      case 7:
        url = 'Report/frmoutboundtransfers'
        break
      case 8:
        url = 'Report/redpagos'
        break
      case 14:
        url = 'Report/contributiondebt'
        break
      case 20:
        url = 'Report/contributionpaymentsreport'
        break
      case 21:
        url = 'Report/pegpeoplereport'
        break
      case 22:
        url = 'Report/persondatareport'
        break
      case 23:
        url = 'Report/pegreceiptreport'
        break
      case 24:
        url = 'Report/retiredranking'
        break
      case 25:
        url = 'Report/fullfrmsummary'
        break
      case 26:
        url = 'Report/contributionpaymentstotals'
        break
      default:
        url = '/Report/padronapp'
    }

    const { data: newExport } = yield call(apiCalls.createExport, { url, data, token })

    yield put(EXPORTS_ACTIONS.EXPORT_SUCCESS(newExport))
    yield put(EXPORTS_ACTIONS.MESSAGE_INFORMATION({ status: 200 }))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getPaymentsExports ({ payload }) {
  try {
    const { data: { items: outboundPaymentData } } = yield call(apiCalls.getOutboundPayment, payload)

    yield put(EXPORTS_ACTIONS.PAYMENTS_EXPORTS_INFORMATION(outboundPaymentData))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export function* getConcepts ({ payload }) {
  try {
    const { data: conceptsData } = yield call(apiCalls.getConcepts, payload)

    yield put(EXPORTS_ACTIONS.CONCEPTS_SUCCESS(conceptsData))
  } catch (error) {
    yield put(EXPORTS_ACTIONS.MESSAGE_INFORMATION(error))
  }
}

export default function* saga () {
  yield takeLatest(EXPORTS.GET, getExports)
  yield takeLatest(EXPORTS.GET_FILTER_DATA, FilterData)
  yield takeLatest(EXPORTS.GET_MODAL_DATA, getModalDataExports)
  yield takeLatest(EXPORTS.CREATE_EXPORT, createExport)
  yield takeLatest(EXPORTS.GET_PAYMENTS_EXPORTS, getPaymentsExports)
  yield takeLatest(EXPORTS.GET_CONCEPTS, getConcepts)
}
