export const key = 'ACTIVITY_LOG'

export const ACTIVITY_LOG = {
  GET: 'ACTIVITY_LOG/GET_LIST',
  SUCCESS: 'ACTIVITY_LOG/GET_LIST/SUCCESS',
  ERROR: 'ACTIVITY_LOG/GET_LIST/ACTIVITY',
  CLEAR: 'ACTIVITY_LOG/CLEAR_LIST',

  SEND_FILTER: 'ACTIVITY_LOG/FILTER/SEND',
  GET_FILTER_DATA: 'ACTIVITY_LOG/FILTER/GET_DATA',
  FILTER_DATA_SUCCESS: 'ACTIVITY_LOG/FILTER/DATA_SUCCESS',
  FILTER_DATA_ERROR: 'ACTIVITY_LOG/FILTER/DATA_ERROR',
  FILTER_DATA_CLEAR: 'ACTIVITY_LOG/FILTER/CLEAR'
}

export default key
